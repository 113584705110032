import styled from 'styled-components';
import { rem } from 'polished';

const CloseButton = styled.button`
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.75;
    padding: 0;
    position: absolute;
    right: ${rem(12)};
    top: ${rem(12)};
    transition: opacity 0.15s ease-in-out;
    z-index: 3;

    :hover {
        opacity: 1;
    }
`;

export default CloseButton;
