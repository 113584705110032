import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { lighten, rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import loaderTheme from '../../styles/loaderTheme';

const DashContainer = styled.span`
    background-color: ${({ theme }) =>
        get(
            theme,
            'loader.container.backgroundColor',
            loaderTheme.loader.container.backgroundColor,
        )};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'loader.height', loaderTheme.loader.height) / 2)};
    height: ${({ theme }) => rem(get(theme, 'loader.height', loaderTheme.loader.height))};
    overflow: hidden;
    position: relative;
    width: ${({ theme, isFullScreen }) =>
        isFullScreen
            ? get(theme, 'loader.width.isFullScreen', loaderTheme.loader.width.isFullScreen)
            : get(theme, 'loader.width.default', loaderTheme.loader.width.default)}em;

    ${({ theme, skin }) =>
        skin &&
        css`
            background-color: ${lighten(
                0.5,
                get(theme, `base.colors.${skin}`, baseTheme.base.colors[skin]),
            )};
        `}
`;

export default DashContainer;
