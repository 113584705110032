import styled, { css } from 'styled-components';

const Item = styled.div`
    ${({ isFullHeight }) =>
        isFullHeight &&
        css`
            height: 100%;
        `}

    ${({ isFullWidth }) =>
        isFullWidth &&
        css`
            width: 100%;
        `}
`;

export default Item;
