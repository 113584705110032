const placementTypeData = [
    'top',
    'bottom',
    'left',
    'right',
    'top start',
    'top end',
    'bottom start',
    'bottom end',
    'left start',
    'left end',
    'right start',
    'right end',
];

export default placementTypeData;
