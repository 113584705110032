import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { baseTheme } from 'baseline-ui/base-theme';
import PlaceholderContainer from './PlaceholderContainer';

const Placeholder = ({ height, width, fill, strokeColor, strokeSize, imageFluid }) => {
    const theme = useContext(ThemeContext);
    const defaultFill = get(theme, 'base.grays.grayLightest', baseTheme.base.grays.grayLightest);
    const svgWidth = imageFluid ? null : width;
    const svgHeight = imageFluid ? null : height;

    return (
        <PlaceholderContainer>
            <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${width} ${height}`}>
                <rect
                    fill={fill || defaultFill}
                    stroke={strokeColor}
                    strokeWidth={strokeSize}
                    width={width}
                    height={height}
                />
            </svg>
        </PlaceholderContainer>
    );
};

Placeholder.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    strokeColor: PropTypes.string,
    strokeSize: PropTypes.number,
    imageFluid: PropTypes.bool,
};

Placeholder.defaultProps = {
    fill: undefined,
    strokeColor: null,
    strokeSize: 0,
    imageFluid: true,
};

export default Placeholder;
