/* eslint-disable no-template-curly-in-string */
const validationMessages = {
    // Generic
    mixed: {
        required: 'validation.mixed.required',
        notType: 'validation.mixed.notType__${type}',
    },
    boolean: {
        isTrue: 'validation.boolean.isTrue',
    },
    string: {
        min: 'validation.string.min__${min}',
        max: 'validation.string.max__${max}',
        equalTo: 'validation.string.equalTo',
        matches: 'validation.string.matches',
    },
    number: {
        min: 'validation.number.min__${min}',
        max: 'validation.number.max__${max}',
    },
    email: {
        email: 'validation.email.email',
    },
    // Custom
    confirmEmail: {
        sameAs: 'validation.confirmEmail.sameAs',
    },
    password: {
        matches: 'validation.password.matches',
    },
    passwordCode: {
        matches: 'validation.passwordCode.matches',
        length: 'validation.passwordCode.length__${length}',
    },
    confirmPassword: {
        sameAs: 'validation.confirmPassword.sameAs',
    },
    username: {
        matches: 'validation.username.matches',
    },
    nickname: {
        matches: 'validation.nickname.matches',
    },
    fullName: {
        matches: 'validation.fullName.matches',
    },
    firstName: {
        matches: 'validation.firstName.matches',
    },
    middleName: {
        matches: 'validation.middleName.matches',
    },
    lastName: {
        matches: 'validation.lastName.matches',
    },
    address: {
        matches: 'validation.address.matches',
    },
    city: {
        matches: 'validation.city.matches',
    },
    postalCode: {
        matches: 'validation.postalCode.matches',
    },
    phoneNumber: {
        matches: 'validation.phoneNumber.matches',
    },
    ssnLast4: {
        matches: 'validation.ssnLast4.matches',
    },
    min: 'validation.number.min__${min}.',
    max: 'validation.number.min__${max}.',
    day: {
        required: 'validation.day.required',
        min: 'validation.day.min',
        max: 'validation.day.max',
    },
    month: {
        required: 'validation.month.required',
        min: 'validation.month.min',
        max: 'validation.month.max',
    },
    year: {
        required: 'validation.year.required',
        min: 'validation.year.min',
        max: 'validation.year.max',
    },
    url: {
        matches: 'validation.url.matches',
    },
};
/* eslint-enable no-template-curly-in-string */

export default validationMessages;
