import { HOME_PATH } from 'common/pathnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
    const isAdmin = useSelector(({ AuthenticationReducer }) => AuthenticationReducer.is_admin);

    if (!isAdmin) {
        return <Navigate to={HOME_PATH} />;
    }

    return children;
};

export default AdminRoute;
