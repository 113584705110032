import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { helpersUtils } from 'baseline-ui/helpers';
import Collapse from './Collapse';
import Trigger from './Trigger';

const Collapsable = ({
    title,
    customTitle,
    icon,
    iconColor,
    triggerColor,
    children,
    toggleItemCallback,
    initialStateOpen,
}) => {
    const [isOpen, setOpen] = useState(initialStateOpen);
    const [itemId] = useState(helpersUtils.generateUniqueId());

    const toggleItem = () => {
        setOpen(!isOpen);
        toggleItemCallback();
    };

    return (
        <>
            <Trigger
                toggleItem={toggleItem}
                icon={icon}
                iconColor={iconColor}
                title={title}
                itemId={itemId}
                isOpen={isOpen}
                triggerColor={triggerColor}
                customTitle={customTitle}
            />
            <Collapse isOpen={isOpen} itemId={itemId}>
                {children}
            </Collapse>
        </>
    );
};

Collapsable.propTypes = {
    title: PropTypes.string,
    customTitle: PropTypes.node,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    triggerColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    toggleItemCallback: PropTypes.func,
    initialStateOpen: PropTypes.bool,
};

Collapsable.defaultProps = {
    customTitle: undefined,
    title: 'View more',
    icon: 'arrow-down',
    triggerColor: 'inherit',
    iconColor: undefined,
    toggleItemCallback: () => {},
    initialStateOpen: false,
};

export default Collapsable;
