import { get } from 'lodash';
import layoutTheme from '../../styles/layoutTheme';

const xxsView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.xxs', layoutTheme.layout.media.xxs) - 1;
    return width <= breakpoint;
};

const xsView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.xs', layoutTheme.layout.media.xs) - 1;
    return width <= breakpoint;
};

const smView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.sm', layoutTheme.layout.media.sm) - 1;
    return width <= breakpoint;
};

const mdView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.md', layoutTheme.layout.media.md) - 1;
    return width <= breakpoint;
};

const lgView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.lg', layoutTheme.layout.media.lg) - 1;
    return width <= breakpoint;
};

const xlView = ({ width }, { theme }) => {
    const breakpoint = get(theme, 'layout.media.xl', layoutTheme.layout.media.xl) - 1;
    return width <= breakpoint;
};

export default { xxsView, xsView, smView, mdView, lgView, xlView };
