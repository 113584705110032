import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { cssHelpers } from 'baseline-ui/helpers';
import formTheme from '../../styles/formTheme';

const TextAreaContainer = styled.div`
    ${cssHelpers.clearfix}

    background-color: ${({ skin, theme }) =>
        get(
            theme,
            `form.input.${skin}.backgroundColor`,
            formTheme.form.input[skin].backgroundColor,
        )};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'form.input.borderRadius', formTheme.form.input.borderRadius))};
         border-color: ${({ theme, skin }) =>
    get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)};
    border-style: solid;
    border-width: 1px;
    position: relative;
    transition: background 0.3s ease-in 0.1s;

    ${({ focused, disabled, skin, theme }) =>
        (focused || disabled) &&
        css`
             border-color: ${({ theme }) =>
                get(theme, `form.input.${skin}.borderColorFocus`, formTheme.form.input[skin].borderColorFocus)};  
            background-color: ${get(
                theme,
                `form.input.${skin}.backgroundColorFocus`,
                formTheme.form.input[skin].backgroundColorFocus,
            )};
        `}

    ${(props) =>
        !props.readOnly &&
        css`
            &:focus {
                background-color: ${get(
                    props,
                    `theme.form.input[${props.skin}].backgroundColorFocus`,
                    formTheme.form.input[props.skin].backgroundColorFocus,
                )};
            }
        `}

    ${(props) =>
        props.disabledValue &&
        css`
            background-color: transparent;
            cursor: not-allowed;
        `}
`;

export default TextAreaContainer;
