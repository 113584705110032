import {
    BUSINESS_LINKS_HTTPS_PROTOCOL,
    BUSINESS_LINKS_HTTP_PROTOCOL,
    BUSINESS_LINKS_MAX_COUNT,
    BUSINESS_LINKS_REGEX_PROTOCOL,
} from 'common/consts';
import { LINK_OTHER } from 'common/linksConstants';

const businessLinksUtils = {
    linkTypeDefaults: (intl) => {
        return [
            {
                value: LINK_OTHER,
                url: intl.formatMessage({
                    id: 'addBusiness.page.sections.links.form.type.other.url',
                }),
                caption: intl.formatMessage({
                    id: 'addBusiness.page.sections.links.form.type.other.caption',
                }),
            },
        ];
    },
    disableAddLinksByLinksCount: (count) => count === BUSINESS_LINKS_MAX_COUNT,
    urlDomainOnly: (url) => {
        if (
            url.startsWith(BUSINESS_LINKS_HTTPS_PROTOCOL) ||
            url.startsWith(BUSINESS_LINKS_HTTP_PROTOCOL)
        ) {
            return url.replace(BUSINESS_LINKS_REGEX_PROTOCOL, '');
        }
        return url;
    },
    addProtocolFormikLinkValue: (values) => {
        const { url } = values;
        return { ...values, url: `${BUSINESS_LINKS_HTTPS_PROTOCOL}${url}` };
    },
};

export default businessLinksUtils;
