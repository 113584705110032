import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { BUTTON_GROUP_LABEL_MARGIN } from '../../constants/formConstants';

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: ${({ flexWrap }) => flexWrap};

    ${({ buttonType }) =>
        buttonType === 'pointer' &&
        css`
            flex-wrap: nowrap;
            justify-content: center;
        `}
    ${({ buttonType }) =>
        (buttonType === 'pills' || buttonType === 'bubble') &&
        css`
            margin: 0 -${rem(BUTTON_GROUP_LABEL_MARGIN)};
        `}
`;

export default ButtonGroup;
