import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'baseline-ui/layout';
import { Button } from 'baseline-ui/button';
import { useIntl } from 'react-intl';
import businessLinksUtils from 'utils/businessLinksUtils';
import AddBusinessLinksListItemContainer from './AddBusinessLinksListItemContainer';
import AddBusinessLinksListItemButtonContainer from './AddBusinessLinksListItemButtonContainer';
import AddBusinessLinksListItemLabelContainer from './AddBusinessLinksListItemLabelContainer';
import AddBusinessLinksListItemLabel from './AddBusinessLinksListItemLabel';

const AddBusinessLinksListItem = forwardRef(
    ({ url, caption, onClick, isFirst, allowDrag }, ref) => {
        const intl = useIntl();

        return (
            <AddBusinessLinksListItemContainer isFirst={isFirst} ref={ref} allowDrag={allowDrag}>
                <Row alignItems="center">
                    <Col xxs={30} xl={20}>
                        <AddBusinessLinksListItemLabel>
                            <AddBusinessLinksListItemLabelContainer>
                                {businessLinksUtils.urlDomainOnly(url)}
                            </AddBusinessLinksListItemLabelContainer>
                        </AddBusinessLinksListItemLabel>
                    </Col>
                    <Col xxs={30} xl={10}>
                        <AddBusinessLinksListItemLabel>
                            <AddBusinessLinksListItemLabelContainer>
                                <strong>{caption}</strong>
                            </AddBusinessLinksListItemLabelContainer>
                        </AddBusinessLinksListItemLabel>
                    </Col>
                </Row>
                <AddBusinessLinksListItemButtonContainer>
                    <Button
                        btnType="link"
                        icon="x"
                        aria-label={intl.formatMessage({
                            id: 'addBusiness.page.sections.links.form.removeLink.accessibilityLabel',
                        })}
                        onClick={onClick}
                    />
                </AddBusinessLinksListItemButtonContainer>
            </AddBusinessLinksListItemContainer>
        );
    },
);

AddBusinessLinksListItem.propTypes = {
    url: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isFirst: PropTypes.bool,
    allowDrag: PropTypes.bool,
};

AddBusinessLinksListItem.defaultProps = {
    isFirst: false,
    allowDrag: false,
};

export default AddBusinessLinksListItem;
