import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const VideoContainer = styled.div`
  width: 100%;
  height: ${rem(300)};
  position: relative;
  z-index: 1;
  text-align: center;
   ${media.xs`
        width: 100%;
        height: ${rem(400)};
    `};
  ${media.md`
        width: 50%;
        height: ${rem(400)};
    `};
`;

export default VideoContainer;