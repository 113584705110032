import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize, rem } from 'polished';
import horizontalScrollTheme from '../styles/horizontalScrollTheme';

const Fade = styled.div`
    height: ${({ theme }) => `calc(
                    100% -
                        ${rem(
                            get(
                                theme,
                                'horizontalScroll.scrollbar.height',
                                horizontalScrollTheme.horizontalScroll.scrollbar.height,
                            ),
                        )}
                )`};
    pointer-events: none;
    position: absolute;
    top: 0;
    width: ${({ theme, fadeWidth }) =>
        rem(
            fadeWidth ||
                get(
                    theme,
                    'horizontalScroll.fadeWidth',
                    horizontalScrollTheme.horizontalScroll.fadeWidth,
                ),
        )};
    z-index: 3;

    ${({ showEnd }) =>
        showEnd &&
        css`
            right: 0;
        `}

    ${({ showStart }) =>
        showStart &&
        css`
            left: 0;
        `}

    ${({ fadeColor, showStart }) =>
        fadeColor &&
        showStart &&
        css`
            background: linear-gradient(to left, ${transparentize(100, fadeColor)}, ${fadeColor});
        `}

    ${({ fadeColor, showEnd }) =>
        fadeColor &&
        showEnd &&
        css`
            background: linear-gradient(to right, ${transparentize(100, fadeColor)}, ${fadeColor});
        `}

    ${({ fadeColor, showStart, theme }) =>
        !fadeColor &&
        showStart &&
        css`
            background: linear-gradient(
                to left,
                ${transparentize(
                    100,
                    get(
                        theme,
                        'horizontalScroll.fadeColor',
                        horizontalScrollTheme.horizontalScroll.fadeColor,
                    ),
                )},
                ${get(
                    theme,
                    'horizontalScroll.fadeColor',
                    horizontalScrollTheme.horizontalScroll.fadeColor,
                )}
            );
        `}

    ${({ fadeColor, showEnd, theme }) =>
        !fadeColor &&
        showEnd &&
        css`
            background: linear-gradient(
                to right,
                ${transparentize(
                    100,
                    get(
                        theme,
                        'horizontalScroll.fadeColor',
                        horizontalScrollTheme.horizontalScroll.fadeColor,
                    ),
                )},
                ${get(
                    theme,
                    'horizontalScroll.fadeColor',
                    horizontalScrollTheme.horizontalScroll.fadeColor,
                )}
            );
        `}
`;
export default Fade;
