import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { H1, P } from 'baseline-ui/typography';
import ManagePhotos from 'components/ManagePhotos';
import { CONTEXT_ENTITY } from 'common/consts';
import { SET_BUSINESS_FORM_DATA_START } from '../../redux/actions/BusinessFormActions';

const Photos = () => {
    const dispatch = useDispatch();
    const photos = useSelector(({ BusinessFormReducer }) => BusinessFormReducer.photos);

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.photos.title" />
            </H1>
            <P>
                <FormattedMessage id="addBusiness.page.sections.photos.subtitle" />
            </P>
            <ManagePhotos
                context={CONTEXT_ENTITY}
                photos={photos}
                setPhotos={(photoList) => {
                    dispatch({
                        type: SET_BUSINESS_FORM_DATA_START,
                        payload: {
                            photos: photoList,
                            hasUnsavedChanges: true,
                        },
                    });
                }}
            />
        </>
    );
};

export default Photos;
