import styled, { css } from 'styled-components';
import { rem, transparentize } from 'polished';
import { get } from 'lodash';
import { baseTheme } from 'baseline-ui/base-theme';
import { media } from 'baseline-ui/layout';
import headerTheme from '../../styles/headerTheme';

const NavigationListItem = styled.li`
    display: ${({ isListVertical }) => (isListVertical ? 'block' : 'inline-block')};
    margin: 0 ${rem(8)};
    position: relative;

    &:after {
        background-color: ${({ isActive, theme }) =>
            isActive
                ? transparentize(0.25, get(theme, 'base.colors.dark', baseTheme.base.colors.dark))
                : 'transparent'};
        border-radius: ${({ theme }) =>
            rem(
                get(
                    theme,
                    'header.navigation.active.height',
                    headerTheme.header.navigation.active.height,
                ),
            )};
        content: '';
        position: absolute;
    }
    
    a[aria-label="settings"] span{
      padding: 1rem 0.15rem 1rem 0rem;
    }
     @media (max-width: 1024px) {
       a[aria-label="settings"] span{
            padding: 0.15rem 0.35rem;
        }
    }
    @media (min-width: 1024px) and (max-width: 1170px) {
       margin: 0;
        a span {
            padding-right: 0.35rem;
            padding-left: 0.35rem;
        }
    }
    
    ${({ isModal }) =>
        isModal &&
        css`
            margin: 0 ${rem(8)};

            ${media.md`
                margin: 0 ${rem(16)};
            `}
        `}

    ${({ isModal, theme, isListVertical, isVertical }) =>
        (isModal || isListVertical || isVertical) &&
        css`
            &:after {
                bottom: ${`calc(
                    50% -
                    ${rem(
                        get(
                            theme,
                            'header.navigation.active.width',
                            headerTheme.header.navigation.active.width,
                        ) / 2,
                    )}
                            )`};
                height: ${rem(
                    get(
                        theme,
                        'header.navigation.active.width',
                        headerTheme.header.navigation.active.width,
                    ),
                )};
                left: 0;
                width: ${rem(
                    get(
                        theme,
                        'header.navigation.active.height',
                        headerTheme.header.navigation.active.height,
                    ),
                )};
            }
        `}

    ${({ isModal, theme, isListVertical, isVertical }) =>
        !isModal &&
        !isListVertical &&
        !isVertical &&
        css`
            &:after {
                bottom: 0;
                height: ${rem(
                    get(
                        theme,
                        'header.navigation.active.height',
                        headerTheme.header.navigation.active.height,
                    ),
                )};
                left: ${`calc(
                50% -
                    ${rem(
                        get(
                            theme,
                            'header.navigation.active.width',
                            headerTheme.header.navigation.active.width,
                        ) / 2,
                    )}
            )`};
                width: ${rem(
                    get(
                        theme,
                        'header.navigation.active.width',
                        headerTheme.header.navigation.active.width,
                    ),
                )};
            }
        `}
`;

export default NavigationListItem;
