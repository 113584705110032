import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const ContentWrapper = styled.div`
    padding-top: ${rem(32)};
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    
   ${media.sm`
        padding-top: ${rem(16)};
        gap: ${rem(32)};
    `};
    
   ${media.lg`
        padding-top: ${rem(32)};
        gap: ${rem(64)};
   `};
   ${media.xl`
        padding-top: ${rem(32)};
        gap: ${rem(80)};
   `};
`;

export default ContentWrapper;
