import styled from 'styled-components';

const ProductsAndServicesItemListTabItemHintContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    user-select: none;
`;

export default ProductsAndServicesItemListTabItemHintContainer;
