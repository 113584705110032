import { keyframes } from 'styled-components';

const pulse = keyframes`
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.2;
        }
    `;

export default pulse;
