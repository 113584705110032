export const POST_REQUEST_PASSWORD_RESET_CODE_PROCESS = 'POST_REQUEST_PASSWORD_RESET_CODE_PROCESS';
export const POST_REQUEST_PASSWORD_RESET_CODE_START = 'POST_REQUEST_PASSWORD_RESET_CODE_START';
export const POST_REQUEST_PASSWORD_RESET_CODE_SUCCESS = 'POST_REQUEST_PASSWORD_RESET_CODE_SUCCESS';

export const POST_RESET_PASSWORD_PROCESS = 'POST_RESET_PASSWORD_PROCESS';
export const POST_RESET_PASSWORD_PROCESS_RESET = 'POST_RESET_PASSWORD_PROCESS_RESET';
export const POST_RESET_PASSWORD_START = 'POST_RESET_PASSWORD_START';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';

export const SET_RESET_PASSWORD_IDENTIFIER_PROCESS = 'SET_RESET_PASSWORD_IDENTIFIER_PROCESS';
export const SET_RESET_PASSWORD_IDENTIFIER_PROCESS_RESET =
    'SET_RESET_PASSWORD_IDENTIFIER_PROCESS_RESET';
export const SET_RESET_PASSWORD_IDENTIFIER_START = 'SET_RESET_PASSWORD_IDENTIFIER_START';

export const SET_RESET_PASSWORD_CODE_PROCESS = 'SET_RESET_PASSWORD_CODE_PROCESS';
export const SET_RESET_PASSWORD_CODE_PROCESS_RESET = 'SET_RESET_PASSWORD_CODE_PROCESS_RESET';
export const SET_RESET_PASSWORD_CODE_START = 'SET_RESET_PASSWORD_CODE_START';
export const SET_RESET_PASSWORD_CODE_SUCCESS = 'SET_RESET_PASSWORD_CODE_SUCCESS';
