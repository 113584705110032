import { filter } from "lodash";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { GetClaims, PutClaims } from "../../api";
import {
  GET_CLAIMS_PROCESS,
  GET_CLAIMS_START,
  GET_CLAIMS_SUCCESS,
  PUT_APPROVE_CLAIM_STATE_PROCESS,
  PUT_APPROVE_CLAIM_STATE_START,
  PUT_REJECT_CLAIM_STATE_PROCESS,
  PUT_REJECT_CLAIM_STATE_START,
  RESET_CLAIM_FILTERS_START,
  SET_CLAIM_FILTERS_START,
} from "../actions/ClaimsActions";
import { DEFAULT_FILTERS } from "../reducers/ClaimsReducer";
import genericSagaHandler from "./CommonSaga";

function* getClaimsSaga() {
  yield genericSagaHandler(GET_CLAIMS_PROCESS, function* () {
    const { filters } = yield select(({ ClaimsReducer }) => ClaimsReducer);
    const claims = yield GetClaims({
      ...filters,
    });
    yield put({
      type: GET_CLAIMS_SUCCESS,
      payload: claims,
    });
  });
}

function* putRejectClaimSaga({ payload }) {
  yield genericSagaHandler(PUT_REJECT_CLAIM_STATE_PROCESS, function* () {
    yield PutClaims({ ...payload, claimstate: "REJECTED" });
    yield call(getClaimsSaga);
  });
}

function* putApproveClaimSaga({ payload }) {
  yield genericSagaHandler(PUT_APPROVE_CLAIM_STATE_PROCESS, function* () {
    yield PutClaims({ ...payload, claimstate: "APPROVED" });
    yield call(getClaimsSaga);
  });
}

function* resetClaimFiltersStart({ payload }) {
  yield genericSagaHandler(RESET_CLAIM_FILTERS_START, function* () {
    yield put({ type: SET_CLAIM_FILTERS_START, payload: DEFAULT_FILTERS });
    yield call(getClaimsSaga);
  });
}

export default function* ClaimsSagas() {
  yield takeLatest(GET_CLAIMS_START, getClaimsSaga);

  yield takeEvery(PUT_REJECT_CLAIM_STATE_START, putRejectClaimSaga);
  yield takeEvery(PUT_APPROVE_CLAIM_STATE_START, putApproveClaimSaga);
  yield takeEvery(RESET_CLAIM_FILTERS_START, resetClaimFiltersStart);
}
