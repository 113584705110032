import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { H3 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { productToEditSelector } from 'redux/selectors/productSelectors';
import { get } from 'lodash';
import ModalEditProductContentContainer from './ModalEditProductContentContainer';
import ModalEditProductContentIntroContainer from './ModalEditProductContentIntroContainer';
import ModalEditProductManageSections from './ModalEditProductManageSections';
import ModalEditProductSectionsList from './ModalEditProductSectionsList';

const ModalEditProductSections = () => {
    const { showModal } = useContext(ModalContext);
    const product = useSelector(productToEditSelector);
    const productId = get(product, '_id');

    if (!productId) {
        return null;
    }

    return (
        <ModalEditProductContentContainer>
            <ModalEditProductContentIntroContainer>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.modal.editProduct.section.sections.title" />
                </H3>
                <Button
                    onClick={() => {
                        showModal(ModalEditProductManageSections);
                    }}
                >
                    <FormattedMessage id="addBusiness.modal.editProduct.section.manage.button.label" />
                </Button>
            </ModalEditProductContentIntroContainer>
            <ModalEditProductSectionsList />
        </ModalEditProductContentContainer>
    );
};

export default ModalEditProductSections;
