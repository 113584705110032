import styled, { css } from 'styled-components';
import { rem } from 'polished';

const Main = styled.div`
    position: relative;

    ${({ scrollButton, btnWidth }) =>
        scrollButton &&
        css`
            padding: 0 ${rem(btnWidth)};
        `};
`;

export default Main;
