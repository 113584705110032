import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Small } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_PRODUCTS } from 'common/addBusinessViewIds';
import FavePagePreviewEntityTypesEmptyListContainer from './FavePagePreviewEntityTypesEmptyListContainer';

const FavePagePreviewEntityTypesEmptyList = ({ containerHeight, label, addToSection }) => {
    const { hideModal } = useContext(ModalContext);

    return (
        <FavePagePreviewEntityTypesEmptyListContainer containerHeight={containerHeight}>
            <Small color="muted">{label}</Small>
            {addToSection && (
                <Button
                    btnType="link"
                    size="sm"
                    icon="plus"
                    onClick={() => {
                        hideModal();
                        addToSection(ADD_BUSINESS_PRODUCTS);
                    }}
                >
                    Add Products/Services
                </Button>
            )}
        </FavePagePreviewEntityTypesEmptyListContainer>
    );
};

FavePagePreviewEntityTypesEmptyList.propTypes = {
    containerHeight: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    addToSection: PropTypes.func,
};

FavePagePreviewEntityTypesEmptyList.defaultProps = {
    addToSection: null,
};

export default FavePagePreviewEntityTypesEmptyList;
