import { rem } from 'polished';
import { get } from 'lodash';
import styled from 'styled-components';
import { buttonTheme } from 'baseline-ui/button';

const HeaderMenuContainer = styled.div`
    margin-left: -${({ theme, sizeValue }) => rem(get(theme, `button.padding[${sizeValue}].h`, buttonTheme.button.padding[sizeValue].h))};
    margin-right: -${({ theme, sizeValue }) => rem(get(theme, `button.padding[${sizeValue}].h`, buttonTheme.button.padding[sizeValue].h))};
`;

export default HeaderMenuContainer;
