import { merge } from 'lodash';
import { headerDarkTheme, headerTheme } from 'baseline-ui/header';
import { baselineTheme } from 'baseline-ui/theme-provider';
import { buttonDarkTheme, buttonTheme } from 'baseline-ui/button';
import { carouselDarkTheme, carouselTheme } from 'baseline-ui/carousel';
import { typographyDarkTheme, typographyTheme } from 'baseline-ui/typography';
import { formDarkTheme, formTheme } from 'baseline-ui/form';
import { baseDarkTheme, baseTheme } from 'baseline-ui/base-theme';
import { modalDarkTheme, modalTheme } from 'baseline-ui/modal';
import { overlayDarkTheme, overlayTheme } from 'baseline-ui/overlay';
import { layoutTheme } from 'baseline-ui/layout';
import { alertDarkTheme, alertTheme } from 'baseline-ui/alert';
import { tooltipDarkTheme, tooltipTheme } from 'baseline-ui/tooltip';
import { authDarkTheme, authTheme } from 'baseline-ui/auth';
import { tabsDarkTheme, tabsTheme } from 'baseline-ui/tabs';
import siteBaseTheme from './siteBaseTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteHeaderTheme from './siteHeaderTheme';
import siteHeaderDarkTheme from './siteHeaderDarkTheme';
import siteButtonTheme from './siteButtonTheme';
import siteButtonDarkTheme from './siteButtonDarkTheme';
import siteCarouselTheme from './siteCarouselTheme';
import siteCarouselDarkTheme from './siteCarouselDarkTheme';
import siteTypographyTheme from './siteTypographyTheme';
import siteTypographyDarkTheme from './siteTypographyDarkTheme';
import siteFormTheme from './siteFormTheme';
import siteFormDarkTheme from './siteFormDarkTheme';
import siteLayoutTheme from './siteLayoutTheme';
import siteLayoutDarkTheme from './siteLayoutDarkTheme';
import siteModalTheme from './siteModalTheme';
import siteModalDarkTheme from './siteModalDarkTheme';
import siteOverlayTheme from './siteOverlayTheme';
import siteOverlayDarkTheme from './siteOverlayDarkTheme';
import siteAlertTheme from './siteAlertTheme';
import siteAlertDarkTheme from './siteAlertDarkTheme';
import siteTooltipTheme from './siteTooltipTheme';
import siteTooltipDarkTheme from './siteTooltipDarkTheme';
import siteAuthTheme from './siteAuthTheme';
import siteAuthDarkTheme from './siteAuthDarkTheme';
import siteTabsTheme from './siteTabsTheme';
import siteTabsDarkTheme from './siteTabsDarkTheme';
import siteTheme from './siteTheme';

const theme = {
    lightMode: merge(baselineTheme.lightMode, {
        alert: merge(alertTheme, {
            ...siteAlertTheme.alert,
        }),
        auth: merge(authTheme, {
            ...siteAuthTheme.auth,
        }),
        base: merge(baseTheme.base, {
            ...siteBaseTheme.base,
        }),
        button: merge(buttonTheme.button, {
            ...siteButtonTheme.button,
        }),
        carousel: merge(carouselTheme.carousel, {
            ...siteCarouselTheme.carousel,
        }),
        form: merge(formTheme.form, {
            ...siteFormTheme.form,
        }),
        header: merge(headerTheme.header, {
            ...siteHeaderTheme.header,
        }),
        layout: merge(layoutTheme.layout, {
            ...siteLayoutTheme.layout,
        }),
        modal: merge(modalTheme.modal, {
            ...siteModalTheme.modal,
        }),
        overlay: merge(overlayTheme.overlay, {
            ...siteOverlayTheme.overlay,
        }),
        tabs: merge(tabsTheme.tabs, {
            ...siteTabsTheme.tabs,
        }),
        tooltip: merge(tooltipTheme.tooltip, {
            ...siteTooltipTheme.tooltip,
        }),
        typography: merge(typographyTheme.typography, {
            ...siteTypographyTheme.typography,
        }),
        ...siteTheme.lightMode,
    }),
    darkMode: merge(baselineTheme.darkMode, {
        alert: merge(alertDarkTheme, {
            ...siteAlertDarkTheme.alert,
        }),
        auth: merge(authDarkTheme, {
            ...siteAuthDarkTheme.auth,
        }),
        base: merge(baseDarkTheme.base, {
            ...siteBaseDarkTheme.base,
        }),
        button: merge(buttonDarkTheme.button, {
            ...siteButtonDarkTheme.button,
        }),
        carousel: merge(carouselDarkTheme.carousel, {
            ...siteCarouselDarkTheme.carousel,
        }),
        form: merge(formDarkTheme.form, {
            ...siteFormDarkTheme.form,
        }),
        header: merge(headerDarkTheme.header, {
            ...siteHeaderDarkTheme.header,
        }),
        layout: merge(layoutTheme.layout, {
            ...siteLayoutDarkTheme.layout,
        }),
        modal: merge(modalDarkTheme.modal, {
            ...siteModalDarkTheme.modal,
        }),
        overlay: merge(overlayDarkTheme.overlay, {
            ...siteOverlayDarkTheme.overlay,
        }),
        tabs: merge(tabsDarkTheme.tabs, {
            ...siteTabsDarkTheme.tabs,
        }),
        tooltip: merge(tooltipDarkTheme.tooltip, {
            ...siteTooltipDarkTheme.tooltip,
        }),
        typography: merge(typographyDarkTheme.typography, {
            ...siteTypographyDarkTheme.typography,
        }),
        ...siteTheme.darkMode,
    }),
};

export default theme;
