import {
  Avatar,
  Button,
  Fab,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import arrayMove from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_PRODUCT_PHOTO_START,
  SET_PRODUCT_PHOTO_START,
  SET_PRODUCT_TO_EDIT_PROPERTY_START,
} from "../redux/actions/EditProductActions";
import { FileUploader } from "react-drag-drop-files";
import { FileUpload } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  // Image editor
  openEditor,
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_crop,
  plugin_finetune,
  plugin_annotate,

  // The main UI and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default properties
  markup_editor_defaults,
  plugin_finetune_defaults,
} from "@pqina/pintura";
import { useState } from "react";
import { COLORS } from "../common/consts";

const ProductPhotoEditor = () => {
  const { productToEdit } = useSelector(
    ({ EditProductReducer }) => EditProductReducer,
  );

  const [localStateFiles, setLocalStateFiles] = useState([]);

  const { photos } = productToEdit;
  const dispatch = useDispatch();

  const onSortEnd = (oldIndex, newIndex) => {
    const newArray = arrayMove(photos, oldIndex, newIndex);
    dispatch({
      type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
      payload: {
        photos: newArray,
      },
    });
  };

  const handleFileUpload = (file) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    editImage(file, (output) => {
      if (file.preview) URL.revokeObjectURL(file.preview);
      setLocalStateFiles([...localStateFiles, output]);
      dispatch({
        type: SET_PRODUCT_PHOTO_START,
        payload: {
          ...output,
          url: URL.createObjectURL(output),
          state: "new",
        },
      });
    });
  };

  const editImage = (image, doneCallbackFunc) => {
    const imageFile = image.pintura ? image.pintura.file : image;
    const imageState = image.pintura ? image.pintura.data : {};

    const editor = openEditor({
      src: imageFile,
      imageState,
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter({
        targetSize: {
          width: 1170,
          height: 1170,
          fit: "cover",
        },
      }),

      // Set Markup Editor defaults
      ...markup_editor_defaults,
      // Set Finetune plugin defaults
      ...plugin_finetune_defaults,
      // This handles complex shapes like arrows / frames
      shapePreprocessor: createDefaultShapePreprocessor(),
      // This will set a square crop aspect ratio
      imageCropAspectRatio: 1,
      // Set locale
      locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...markup_editor_locale_en_gb,
      },
    });

    editor.on("close", () => {
      // the user cancelled editing the image
    });

    editor.on("process", ({ dest, imageState }) => {
      Object.assign(dest, {
        pintura: { file: imageFile, data: imageState },
      });
      doneCallbackFunc(dest);
    });
  };

  return (
    <Grid item container xs={12} columnSpacing={1} rowSpacing={1}>
      <Grid marginBottom={2} marginTop={2} item container xs={12}>
        <FileUploader
          classes="margin-auto dndArea"
          handleChange={handleFileUpload}
          name="file"
        />
      </Grid>
      <Grid item xs={12}>
        {photos?.length > 0 && (
          <Grid
            container
            item
            xs={12}
            sx={{
              borderWidth: "1px",
              borderColor: COLORS.INPUT_BORDER,
              borderRadius: "20px",
              borderStyle: "solid",
              padding: "16px",
            }}
          >
            <SortableList
              onSortEnd={onSortEnd}
              className="list"
              draggedItemClassName="dragged"
            >
              <ImageList cols={3}>
                {photos &&
                  photos.map((photo, idx) => (
                    <div
                      key={`sortable_item_${photo.name}_${photo.order}`}
                      style={{
                        zIndex: 10,
                        position: "relative",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <SortableItem
                        key={`sortable_item_${photo.name}_${photo.order}`}
                      >
                        <ImageListItem
                          key={`image_list_item_${photo.name}_${photo.order}`}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              flexShrink: 0,
                              display: "flex",
                              margin: 8,
                              cursor: "grab",
                              userSelect: "none",
                              // boxShadow: 12,
                              margin: 2,
                              padding: 2,
                              alignItems: "center",
                              width: 200,
                              height: 200,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{ width: 150, height: 150 }}
                              src={photo.preview ? photo.preview : photo.url}
                              imgProps={{ draggable: false }}
                              variant="rounded"
                              fullwidth={"true"}
                            />
                            <Fab
                              color="primary"
                              size="small"
                              sx={{
                                position: "absolute",
                                bottom: 10,
                                right: 10,
                              }}
                              aria-label="like"
                              onClick={(e) => {
                                // editImage(photo.preview, (output) => {
                                //   const updatedPhotos = [...photos];
                                //   // replace original image with new image
                                //   updatedPhotos[index] = output;
                                //   // revoke preview URL for old image
                                //   //if (file.preview) URL.revokeObjectURL(file.preview);
                                //   // set new preview URL
                                //   Object.assign(output, {
                                //     preview: URL.createObjectURL(output),
                                //     status: "updated",
                                //   });
                                //   // update view
                                //   setPhotos(updatedPhotos);
                                // });
                              }}
                            >
                              <EditIcon />
                            </Fab>
                            <Fab
                              color="secondary"
                              size="small"
                              sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                              aria-label="like"
                              onClick={(e) => {
                                dispatch({
                                  type: DELETE_PRODUCT_PHOTO_START,
                                  payload: idx,
                                });
                              }}
                            >
                              <DeleteIcon />
                            </Fab>
                          </Box>
                        </ImageListItem>
                      </SortableItem>
                    </div>
                  ))}
              </ImageList>
            </SortableList>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default ProductPhotoEditor;
