import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H5, Small } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { allActiveProductsSelector } from 'redux/selectors/sectionSelectors';
import { Button } from 'baseline-ui/button';
import { SET_EMPTY_PRODUCT_START } from 'redux/actions/EditProductActions';
import { OverlayContext } from 'baseline-ui/overlay';
import ProductsAndServicesListHeaderContainer from './ProductsAndServicesListHeaderContainer';
import ModalAddProduct from '../modalProduct/ModalAddProduct';
import ProductsAndServicesFilteredList from './ProductsAndServicesFilteredList';

const ProductsAndServicesList = () => {
    const allActiveProducts = useSelector(allActiveProductsSelector);
    const { showOverlay } = useContext(OverlayContext);
    const dispatch = useDispatch();

    return (
        <>
            <ProductsAndServicesListHeaderContainer>
                <H5>
                    <FormattedMessage id="addBusiness.page.sections.productsAndServices.list.title" />
                </H5>
                <Small color="muted">
                    <FormattedMessage
                        id="addBusiness.page.sections.productsAndServices.list.count.label"
                        values={{ count: allActiveProducts.length }}
                    />
                </Small>
                <Button
                    btnType="link"
                    size="sm"
                    onClick={() => {
                        dispatch({ type: SET_EMPTY_PRODUCT_START });
                        showOverlay(ModalAddProduct);
                    }}
                >
                    <FormattedMessage id="addBusiness.page.sections.productsAndServices.list.addProduct" />
                </Button>
            </ProductsAndServicesListHeaderContainer>
            <ProductsAndServicesFilteredList />
        </>
    );
};

export default ProductsAndServicesList;
