import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GaIcon } from 'baseline-ui/icon';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { ThemeContext } from 'styled-components';
import ProductsAndServicesItemImageContainer from './ProductsAndServicesItemImageContainer';

const ProductsAndServicesItemImage = ({ image }) => {
    const theme = useContext(ThemeContext);

    return (
        <ProductsAndServicesItemImageContainer>
            {image ? (
                <ProgressiveBg image={image} bgColor={theme.base.colors.light} />
            ) : (
                <GaIcon icon="favepage-heart" size={24} color={theme.base.colors.muted} />
            )}
        </ProductsAndServicesItemImageContainer>
    );
};

ProductsAndServicesItemImage.propTypes = {
    image: PropTypes.string,
};

ProductsAndServicesItemImage.defaultProps = {
    image: undefined,
};

export default ProductsAndServicesItemImage;
