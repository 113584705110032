import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import Container from './Label/Container';
import Label from './Label/Label';
import buttonPropTypes from '../prop-types/buttonPropTypes';
import buttonStyles from '../styles/buttonStyles';

const AnchorButtonBase = styled.a`
    ${buttonStyles}
`;

const AnchorButton = ({
    path,
    icon,
    size,
    block,
    skin,
    btnType,
    iconSize,
    iconColor,
    children,
    target,
    isActive,
    ...buttonProps
}) => {
    const { themeMode } = useContext(ThemeModeContext);

    const buttonContent = icon ? (
        <Container>
            <Label icon={icon} size={iconSize} color={iconColor} btnSize={size} skin={skin}>
                {children}
            </Label>
        </Container>
    ) : (
        children
    );

    return (
        <AnchorButtonBase
            href={path}
            sizeValue={size}
            blockValue={block}
            skin={skin}
            btnSize={size}
            btnType={btnType}
            target={target}
            rel={target === '_blank' ? 'noopener' : null}
            isActive={isActive}
            themeMode={themeMode}
            {...buttonProps}
        >
            {buttonContent}
        </AnchorButtonBase>
    );
};

AnchorButton.propTypes = {
    path: PropTypes.string.isRequired,
    skin: buttonPropTypes.buttonSkin,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    block: PropTypes.bool,
    size: buttonPropTypes.buttonSize,
    btnType: buttonPropTypes.buttonBtnType,
    target: buttonPropTypes.buttonTarget,
    children: PropTypes.node,
    isActive: PropTypes.bool,
};

AnchorButton.defaultProps = {
    skin: 'primary',
    icon: undefined,
    iconColor: 'currentColor',
    iconSize: undefined,
    block: false,
    size: 'default',
    btnType: 'default',
    target: '_blank',
    children: undefined,
    isActive: false,
};

export default AnchorButton;
