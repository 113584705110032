import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem, lighten } from 'polished';
import tooltipTheme from '../styles/tooltipTheme';

const Container = styled.div`
    background-color: ${({ theme }) =>
        lighten(0.1, get(theme, 'tooltip.backgroundColor', tooltipTheme.tooltip.backgroundColor))};
    border-color: ${({ theme }) =>
        get(theme, 'tooltip.borderColor', tooltipTheme.tooltip.borderColor)};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'tooltip.borderRadius', tooltipTheme.tooltip.borderRadius))};
    border-style: solid;
    border-width: ${rem(1)};
    box-shadow: ${({ theme }) => get(theme, 'tooltip.boxShadow', tooltipTheme.tooltip.boxShadow)};
    max-width: ${({ maxWidth }) => (maxWidth ? rem(maxWidth) : 'none')};
    min-width: ${({ minWidth }) => (minWidth ? rem(minWidth) : 'none')};
    z-index: ${({ theme }) => get(theme, 'tooltip.zIndex', tooltipTheme.tooltip.zIndex)};

    ${({ showPointer, theme, placement, triggerHeight, triggerWidth, centerPointer }) =>
        showPointer &&
        css`
            &:before,
            &:after {
                border-color: transparent;
                border-style: solid;

                border-width: ${rem(
                    get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size),
                )};

                content: '';
                margin: 0;
                position: absolute;

                ${(placement === 'top' || placement === 'bottom') &&
                css`
                    left: 50%;
                    transform: translateX(-50%);
                `}

                ${(placement === 'left' || placement === 'right') &&
                css`
                    top: 50%;
                    transform: translateY(-50%);
                `}

                ${(placement === 'top start' || placement === 'bottom start') &&
                css`
                    left: ${centerPointer
                        ? rem(
                              triggerWidth / 2 -
                                  get(
                                      theme,
                                      'tooltip.pointer.size',
                                      tooltipTheme.tooltip.pointer.size,
                                  ),
                          )
                        : rem(
                              get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size),
                          )};
                `}

                ${(placement === 'top end' || placement === 'bottom end') &&
                css`
                    left: auto;
                    right: ${centerPointer
                        ? rem(
                              triggerWidth / 2 -
                                  get(
                                      theme,
                                      'tooltip.pointer.size',
                                      tooltipTheme.tooltip.pointer.size,
                                  ),
                          )
                        : rem(
                              get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size),
                          )};
                `}

                ${(placement === 'left start' || placement === 'right start') &&
                css`
                    top: ${centerPointer
                        ? rem(
                              triggerHeight / 2 -
                                  get(
                                      theme,
                                      'tooltip.pointer.size',
                                      tooltipTheme.tooltip.pointer.size,
                                  ) /
                                      2,
                          )
                        : rem(
                              get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size),
                          )};
                `}
                
                ${(placement === 'left end' || placement === 'right end') &&
                css`
                    bottom: ${centerPointer
                        ? rem(
                              triggerHeight / 2 -
                                  get(
                                      theme,
                                      'tooltip.pointer.size',
                                      tooltipTheme.tooltip.pointer.size,
                                  ) /
                                      2,
                          )
                        : rem(
                              get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size),
                          )};
                `}
            }

            ${placement.includes('top') &&
            css`
                &:before,
                &:after {
                    border-bottom: 0;
                }

                &:before {
                    bottom: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size) + 1)};

                    border-top-color: ${get(
                        theme,
                        'tooltip.borderColor',
                        tooltipTheme.tooltip.borderColor,
                    )};
                }

                &:after {
                    bottom: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size))};

                    border-top-color: ${lighten(
                        0.1,
                        get(theme, 'tooltip.backgroundColor', tooltipTheme.tooltip.backgroundColor),
                    )};
                }
            `}

            ${placement.includes('bottom') &&
            css`
                &:before,
                &:after {
                    border-top: 0;
                }

                &:before {
                    top: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size) + 1)};

                    border-bottom-color: ${get(
                        theme,
                        'tooltip.borderColor',
                        tooltipTheme.tooltip.borderColor,
                    )};
                }

                &:after {
                    top: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size))};

                    border-bottom-color: ${lighten(
                        0.1,
                        get(theme, 'tooltip.backgroundColor', tooltipTheme.tooltip.backgroundColor),
                    )};
                }
            `}

            ${placement.includes('left') &&
            css`
                &:before,
                &:after {
                    border-right: 0;
                }

                &:before {
                    right: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size) + 1)};

                    border-left-color: ${get(
                        theme,
                        'tooltip.borderColor',
                        tooltipTheme.tooltip.borderColor,
                    )};
                }
                &:after {
                    right: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size))};

                    border-left-color: ${lighten(
                        0.1,
                        get(theme, 'tooltip.backgroundColor', tooltipTheme.tooltip.backgroundColor),
                    )};
                }
            `}

            ${placement.includes('right') &&
            css`
                &:before,
                &:after {
                    border-left: 0;
                }

                &:before {
                    left: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size) + 1)};

                    border-right-color: ${lighten(
                        0.1,
                        get(theme, 'tooltip.borderColor', tooltipTheme.tooltip.borderColor),
                    )};
                }

                &:after {
                    left: -${rem(get(theme, 'tooltip.pointer.size', tooltipTheme.tooltip.pointer.size))};

                    border-right-color: ${lighten(
                        0.1,
                        get(theme, 'tooltip.backgroundColor', tooltipTheme.tooltip.backgroundColor),
                    )};
                }
            `}
        `}
`;

export default Container;
