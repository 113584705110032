import PropTypes from 'prop-types';
import { buttonSkinData, buttonBtnTypeData, buttonSizeData } from 'baseline-ui/button';
import modalSizeData from '../data/modalSizeData';

const modalSize = PropTypes.oneOf(modalSizeData);

const modalFooterAction = PropTypes.shape({
    onClick: PropTypes.func,
    isExternal: PropTypes.bool,
    path: PropTypes.string,
    skin: PropTypes.oneOf(buttonSkinData),
    icon: PropTypes.string,
    btnType: PropTypes.oneOf(buttonBtnTypeData),
    size: PropTypes.oneOf(buttonSizeData),
    label: PropTypes.string,
    onClickDismiss: PropTypes.bool,
    disabled: PropTypes.bool,
});

const modalFooterActions = PropTypes.arrayOf(modalFooterAction);

export default { modalSize, modalFooterActions };
