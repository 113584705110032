import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SrLabel } from 'baseline-ui/helpers';
import carouselPropTypes from '../prop-types/carouselPropTypes';
import DefaultSlider from './DefaultSlider';
import CarouselArrow from './CarouselArrow';
import CarouselDot from './CarouselDot';
import CarouselDotList from './CarouselDotList';

const Carousel = forwardRef(
    (
        {
            prevArrow,
            prevLabelId,
            nextArrow,
            nextLabelId,
            customPaging,
            appendDots,
            arrows,
            dotsType,
            dotsPosition,
            dotsAlignment,
            dotsOrientation,
            children,
            dots,
            vertical,
            arrowSize,
            arrowType,
            arrowCustomColor,
            ...props
        },
        ref,
    ) => {
        const [isVertical, setIsVertical] = useState(vertical);
        const [hasDots, setHasDots] = useState(dots);
        const [hasArrows, setHasArrows] = useState(arrows);
        const [hasDotsPosition, setHasDotsPosition] = useState(dotsPosition);
        const [hasDotsAlignment, setHasDotsAlignment] = useState(dotsAlignment);
        const [hasDotsOrientation, setHasDotsOrientation] = useState(dotsOrientation);

        const carouselArrowNext = nextArrow || (
            <CarouselArrow
                slide="next"
                labelId={nextLabelId}
                vertical={isVertical}
                arrowSize={arrowSize}
                arrowType={arrowType}
                arrowCustomColor={arrowCustomColor}
                {...props}
            />
        );

        const carouselArrowPrev = prevArrow || (
            <CarouselArrow
                slide="prev"
                labelId={prevLabelId}
                vertical={isVertical}
                arrowSize={arrowSize}
                arrowType={arrowType}
                arrowCustomColor={arrowCustomColor}
                {...props}
            />
        );

        const carouseCustomPaging =
            customPaging ||
            ((i) => (
                <CarouselDot dotsType={dotsType}>
                    <SrLabel as="span">{i + 1}</SrLabel>
                </CarouselDot>
            ));

        const customAppendDots =
            appendDots ||
            ((renderDots) => (
                <CarouselDotList
                    dotsPosition={hasDotsPosition}
                    dotsAlignment={hasDotsAlignment}
                    dotsOrientation={hasDotsOrientation}
                    {...props}
                >
                    {renderDots}
                </CarouselDotList>
            ));

        const checkResponsive = () => {
            const width = window.innerWidth;
            let currResponsive;

            if (props.responsive) {
                currResponsive = props.responsive
                    .filter((item) => item.breakpoint >= width)
                    .reduce((bestMatch, currMatch) => {
                        if (!bestMatch || bestMatch.breakpoint > currMatch.breakpoint) {
                            return currMatch;
                        }
                        return bestMatch;
                    }, null);
            }

            setIsVertical(get(currResponsive, 'settings.vertical', vertical));
            setHasDots(get(currResponsive, 'settings.dots', dots));
            setHasArrows(get(currResponsive, 'settings.arrows', arrows));
            setHasDotsPosition(get(currResponsive, 'settings.dotsPosition', dotsPosition));
            setHasDotsAlignment(get(currResponsive, 'settings.dotsAlignment', dotsAlignment));
            setHasDotsOrientation(get(currResponsive, 'settings.dotsOrientation', dotsOrientation));
        };

        useEffect(() => {
            checkResponsive();
            window.addEventListener('resize', checkResponsive);
            return () => window.removeEventListener('resize', checkResponsive);
        });

        return (
            <DefaultSlider
                {...props}
                ref={ref}
                nextArrow={carouselArrowNext}
                prevArrow={carouselArrowPrev}
                customPaging={carouseCustomPaging}
                appendDots={customAppendDots}
                arrows={hasArrows}
                arrowSize={arrowSize}
                arrowType={arrowType}
                dotsPosition={hasDotsPosition}
                dotsAlignment={hasDotsAlignment}
                dotsOrientation={hasDotsOrientation}
                dots={hasDots}
                dotsType={dotsType}
                vertical={isVertical}
            >
                {children}
            </DefaultSlider>
        );
    },
);

Carousel.propTypes = {
    /** Equal height for the slides */
    eqHeight: PropTypes.bool,
    /** Children can include any other component */
    children: PropTypes.node,
    /** Size of the arrows */
    arrowSize: carouselPropTypes.carouselArrowSize,
    /** Type of arrow */
    arrowType: carouselPropTypes.carouselArrowType,
    /** Custom color of arrow  */
    arrowCustomColor: PropTypes.string,
    /** Type of navigational dots */
    dotsType: carouselPropTypes.carouselDotsType,
    /** Position dots inside or outside the slide container */
    dotsPosition: carouselPropTypes.carouselDotsPosition,
    /** Align dots to the left, center or right of the slide container */
    dotsAlignment: carouselPropTypes.carouselDotsAlignment,
    /** Orientation of the dots: vertical or horizontal */
    dotsOrientation: carouselPropTypes.carouselDotsOrientation,
    /** String ID of text for the label of the previous arrow button, for screen reader only */
    prevLabelId: PropTypes.string,
    /** String ID of text for the label of the next arrow button, for screen reader only */
    nextLabelId: PropTypes.string,
    /** Enable tabbing and arrow key navigation */
    accessibility: PropTypes.bool,
    /** Infinitely wrap around contents */
    infinite: PropTypes.bool,
    /** Index of first slide */
    initialSlide: PropTypes.number,
    /** Animation speed in milliseconds */
    speed: PropTypes.number,
    /** Enable centered view with partial prev/next slides. Use with odd numbered slidesToShow counts. */
    centerMode: PropTypes.bool,
    /** Side padding when in center mode (px or %) */
    centerPadding: PropTypes.string,
    /** Number of slides to show */
    slidesToShow: PropTypes.number,
    /** Number of slides to scroll */
    slidesToScroll: PropTypes.number,
    /** Show dot indicators */
    dots: PropTypes.bool,
    /** Prev/Next Arrows */
    arrows: PropTypes.bool,
    /** Enable Autoplay */
    autoplay: PropTypes.bool,
    /** Pause Autoplay On Hover */
    pauseOnHover: PropTypes.bool,
    /** Enable adaptive height for single slide horizontal carousels. */
    adaptiveHeight: PropTypes.bool,
    /** Enable swiping */
    swipe: PropTypes.bool,
    /** Enable slide motion with touch */
    touchMove: PropTypes.bool,
    /** Vertical content */
    vertical: PropTypes.bool,
    /** Vertical swipe mode */
    verticalSwiping: PropTypes.bool,
    /** Allow a node or customize the HTML for the "Next" arrow. */
    nextArrow: PropTypes.element,
    /** Allow a node or customize the HTML for the "Previous" arrow. */
    prevArrow: PropTypes.element,
    /** Custom dots templates. Default: dots => <ul>{dots}</ul> */
    appendDots: PropTypes.func,
    /** Custom paging templates */
    customPaging: PropTypes.func,
    /** Change the slider's direction to become right-to-left */
    rtl: PropTypes.bool,
    /** Load images or render components on demand or progressively */
    lazyLoad: PropTypes.oneOf(['ondemand', 'progressive']),
    /** Index change callback. `(oldIndex, newIndex) => ...` */
    beforeChange: PropTypes.func,
    /** Index change callback. `index => ...` */
    afterChange: PropTypes.func,
    /** Enable settings sets at given screen width.  */
    responsive: PropTypes.arrayOf(
        PropTypes.shape({
            breakpoint: PropTypes.number.isRequired,
            settings: PropTypes.oneOfType([
                PropTypes.oneOf(['unslick']),
                PropTypes.shape({
                    centerMode: PropTypes.bool,
                    centerPadding: PropTypes.string,
                    slidesToShow: PropTypes.number,
                    slidesToScroll: PropTypes.number,
                    infinite: PropTypes.bool,
                    dots: PropTypes.bool,
                    dotsPosition: carouselPropTypes.carouselDotsPosition,
                    dotsAlignment: carouselPropTypes.carouselDotsAlignment,
                    dotsOrientation: carouselPropTypes.carouselDotsOrientation,
                    arrows: PropTypes.bool,
                    arrowSize: carouselPropTypes.carouselArrowSize,
                    vertical: PropTypes.bool,
                    verticalSwiping: PropTypes.bool,
                }),
            ]),
        }),
    ),
};

Carousel.defaultProps = {
    eqHeight: false,
    children: null,
    dotsType: 'default',
    dotsPosition: 'outside bottom',
    dotsAlignment: 'center',
    dotsOrientation: 'horizontal',
    arrowSize: 'default',
    prevLabelId: 'carousel.arrow.prev.label',
    nextLabelId: 'carousel.arrow.next.label',
    accessibility: true,
    dots: true,
    arrows: true,
    arrowType: 'default',
    arrowCustomColor: null,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    centerMode: false,
    centerPadding: '0',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    adaptiveHeight: false,
    swipe: true,
    touchMove: true,
    vertical: false,
    verticalSwiping: false,
    nextArrow: null,
    prevArrow: null,
    appendDots: null,
    customPaging: null,
    rtl: false,
    responsive: null,
    lazyLoad: null,
    beforeChange: null,
    afterChange: null,
};

export default Carousel;
