import React from 'react';
import PropTypes from 'prop-types';
import { H5, H6 } from 'baseline-ui/typography';
import businessAddressUtils from 'utils/businessAddressUtils';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { ENTITY_TYPE_PEOPLE } from 'common/consts';
import FavePagePreviewContentSectionContainer from './favePagePreviewContent/FavePagePreviewContentSectionContainer';

const FavePagePreviewDetails = ({ phone, address }) => {
    const { streetAddress, cityAddress, stateCodeAddress } =
        businessAddressUtils.formatBusinessAddress(address);
    const { type } = useSelector(businessFormSelector);

    /**
     * Do not show Address on entity type of PEOPLE
     */
    if (type === ENTITY_TYPE_PEOPLE) {
        return null;
    }

    return (
        <FavePagePreviewContentSectionContainer>
            <H5>Contact</H5>
            {phone && <H6 color="muted">{phone}</H6>}
            {address && (
                <>
                    <H6 color="muted">{streetAddress}</H6>
                    <H6 color="muted">
                        {cityAddress}
                        {stateCodeAddress}
                    </H6>
                </>
            )}
        </FavePagePreviewContentSectionContainer>
    );
};

FavePagePreviewDetails.propTypes = {
    phone: PropTypes.string,
    address: PropTypes.string,
};

FavePagePreviewDetails.defaultProps = {
    phone: undefined,
    address: undefined,
};

export default FavePagePreviewDetails;
