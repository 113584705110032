import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import typographyTheme from '../styles/typographyTheme';

const GridList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'typography.base.fontSize', typographyTheme.typography.base.fontSize))};
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
`;

export default GridList;
