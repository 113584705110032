import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormCheckbox, FormInput } from 'baseline-ui/form';
import AdminBusinessAdvertisingOrderContainer from './AdminBusinessAdvertisingOrderContainer';

const AdminBusinessAdvertisingCategoryOrder = ({ index }) => {
    const renderCheckboxId = `categoriesAndFilters.${index}.categoryOrderEnabled`;
    const renderId = `categoriesAndFilters.${index}.categoryOrder`;

    const { values, setFieldValue } = useFormikContext();

    return (
        <AdminBusinessAdvertisingOrderContainer>
            <Field
                component={FormCheckbox}
                skin="dark"
                id={renderCheckboxId}
                name={renderCheckboxId}
                onChange={() => {
                    if (values.categoriesAndFilters[index].categoryRotate) {
                        setFieldValue(`categoriesAndFilters.${index}.categoryRotate`, false);
                    }
                }}
            >
                Order
            </Field>
            <Field
                component={FormInput}
                label="Number"
                name={renderId}
                id={renderId}
                prependAddon="#"
                type="number"
                min="0"
            />
        </AdminBusinessAdvertisingOrderContainer>
    );
};

AdminBusinessAdvertisingCategoryOrder.propTypes = {
    index: PropTypes.number.isRequired,
};

AdminBusinessAdvertisingCategoryOrder.defaultProps = {};

export default AdminBusinessAdvertisingCategoryOrder;
