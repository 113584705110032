import styled from 'styled-components';
import { rem } from 'polished';

const ResendRecoveryEmailButtonContainer = styled.div`
    padding-top: ${rem(16)};
    text-align: right;
    width: 100%;
`;

export default ResendRecoveryEmailButtonContainer;
