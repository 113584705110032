import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from './HeaderContext';

const initialState = {
    headerTheme: undefined,
    setHeaderTheme: () => {},
};

const actions = {
    SET_HEADER_THEME: 'SET_HEADER_THEME',
};

function reducer(state, action) {
    switch (action.type) {
        case actions.SET_HEADER_THEME:
            return {
                ...state,
                headerTheme: action.headerTheme,
            };
        default:
            return state;
    }
}

const HeaderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            headerTheme: state.headerTheme,
            setHeaderTheme: (headerTheme) => {
                dispatch({ type: actions.SET_HEADER_THEME, headerTheme });
            },
        }),
        [state],
    );

    return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

HeaderProvider.propTypes = {
    children: PropTypes.node,
};

HeaderProvider.defaultProps = {
    children: undefined,
};

export default HeaderProvider;
