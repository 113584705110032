import { h6Styles } from 'baseline-ui/typography';
import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesItemPrice = styled.div`
    ${h6Styles}
    margin-left: ${rem(16)};
    margin-top: ${({ theme }) =>
        rem(theme.typography.headings.fontSize.h5 / theme.typography.headings.paddingRatio)};
`;

export default ProductsAndServicesItemPrice;
