import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { ContentRenderer } from 'baseline-ui/helpers';
import { useIntl } from 'react-intl';

const ModalAccessRightAddUser = ({
    onRequestClose,
    code,
    errorCode,
    processName,
    emailAddress,
    ...props
}) => {
    const intl = useIntl();
    return (
        <Modal
            title={intl.formatMessage({
                id: `process.error.${processName}.${errorCode}.code.${code}.modal.title`,
            })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: `process.error.${processName}.${errorCode}.code.${code}.modal.footerAction.ok.label`,
                    }),
                    onClick: () => onRequestClose(),
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage(
                    {
                        id: `process.error.${processName}.${errorCode}.code.${code}.modal.content.md`,
                    },
                    { emailAddress },
                )}
            />
        </Modal>
    );
};

ModalAccessRightAddUser.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    processName: PropTypes.string.isRequired,
    errorCode: PropTypes.number.isRequired,
    emailAddress: PropTypes.string.isRequired,
};

export default ModalAccessRightAddUser;
