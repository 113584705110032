import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const formAccountSchema = yup.object().shape({
    emailAddress: fieldSchema.email.required(),
    username: fieldSchema.username.required(),
    newPassword: fieldSchema.password,
});

export default formAccountSchema;
