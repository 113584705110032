import { baseTheme, baseDarkTheme } from 'baseline-ui/base-theme';

const formTheme = {
    form: {
        input: {
            light: {
                backgroundColor: '#3D3D3D',
                backgroundColorFocus: '#424242',
                borderColor: baseTheme.base.grays.grayLightest,
                borderColorFocus: baseDarkTheme.base.colors.primary,
                color: baseTheme.base.grays.grayLightest,
                colorDisabled: baseTheme.base.grays.gray,
            },
            dark: {
                backgroundColor: '#F2F2F2',
                backgroundColorFocus: '#ECECEC',
                borderColor: baseTheme.base.grays.grayLightest,
                borderColorFocus: baseTheme.base.colors.primary,
                color: baseTheme.base.grays.grayDarkest,
                colorDisabled: baseTheme.base.grays.gray,
            },
            height: 54,
            heightNoLabel: 48,
            fontSize: 16,
            borderRadius: 25,
        },
        label: {
            light: {
                color: baseTheme.base.grays.grayLight,
                colorFilled: baseTheme.base.grays.gray,
                colorFocus: baseDarkTheme.base.colors.primary,
                colorDisabled: baseTheme.base.grays.gray,
            },
            dark: {
                color: baseTheme.base.grays.grayDark,
                colorFilled: baseTheme.base.grays.gray,
                colorFocus: baseTheme.base.colors.primary,
                colorDisabled: baseTheme.base.grays.gray,
            },
            fontSize: 16,
            fontSizeFocus: 11,
        },
        passwordInput: {
            revealPassword: { icon: { show: 'eye', hide: 'eye-off' } },
        },
        select: {
            light: {
                optionColor: baseTheme.base.grays.grayLightest,
            },
            dark: {
                optionColor: baseTheme.base.grays.grayDarkest,
            },
        },
        checkbox: {
            light: {
                backgroundColor: 'transparent',
                backgroundColorChecked: 'transparent',
                borderColor: '#ffffff',
                borderColorChecked: '#ffffff',
                colorChecked: '#ffffff',
                colorDisabled: baseTheme.base.grays.grayDark,
                colorDisabledChecked: baseTheme.base.grays.grayDark,
                indicatorColor: '#ffffff',
                indicatorDisabledColor: baseTheme.base.grays.gray,
            },
            dark: {
                backgroundColor: 'transparent',
                backgroundColorChecked: baseTheme.base.colors.primary,
                borderColor: baseTheme.base.grays.grayDark,
                borderColorChecked: baseTheme.base.colors.primary,
                colorChecked: baseTheme.base.colors.primary,
                colorDisabled: baseTheme.base.grays.grayLighter,
                colorDisabledChecked: baseTheme.base.grays.grayLighter,
                indicatorColor: '#ffffff',
                indicatorDisabledColor: baseTheme.base.grays.grayLightest,
            },
            muted: {
                backgroundColor: 'transparent',
                backgroundColorChecked: baseTheme.base.colors.muted,
                borderColor: baseDarkTheme.base.grays.grayDark,
                borderColorChecked: baseTheme.base.colors.muted,
                colorChecked: baseTheme.base.colors.muted,
                colorDisabled: baseTheme.base.grays.grayDark,
                colorDisabledChecked: baseTheme.base.grays.grayDark,
                indicatorColor: baseTheme.base.colors.light,
                indicatorDisabledColor: baseTheme.base.grays.gray,
            },
            size: {
                default: 16,
                lg: 22,
            },
            label: {
                fontSize: {
                    default: 14,
                    lg: 16,
                },
                color: {
                    light: {
                        default: '#ffffff',
                        disabled: baseTheme.base.grays.grayDark,
                    },
                    dark: {
                        default: 'inherit',
                        disabled: baseTheme.base.grays.grayLighter,
                    },
                    muted: {
                        default: 'inherit',
                        disabled: baseTheme.base.colors.muted,
                    },
                },
            },
        },
        switch: {
            backgroundColorOn: '#6dbe45',
            backgroundColorOff: '#ffffff',
            borderColor: '#CCCCCC',
            colorOn: '#ffffff',
            colorOff: '#bbbbbb',
            backgroundColorToggle: '#f7f7f7',
            borderColorToggle: '#ececec',
            size: {
                default: '2.5rem',
                sm: '1.9375rem',
                xs: '1.5625rem',
            },
            label: {
                light: {
                    color: '#ffffff',
                },
                dark: {
                    color: 'inherit',
                },
            },
        },
        legend: {
            light: {
                color: baseTheme.base.colors.light,
            },
            dark: {
                color: baseTheme.base.colors.dark,
            },
        },
        error: {
            light: {
                backgroundColor: 'transparent',
            },
            dark: {
                backgroundColor: 'transparent',
            },
            color: baseTheme.base.colors.danger,
        },
        fieldsGrouped: {
            marginH: 8,
        },
    },
};

export default formTheme;
