import styled from 'styled-components';
import siteBaseTheme from "styles/siteBaseTheme";
import {media} from "baseline-ui/layout";

const TitleWrapper = styled.div`
    position: relative;
    &::before {
        content: "";
        position: absolute;
        height: 5px;
        border-bottom: 1px solid ${siteBaseTheme.base.colors.primary};
        top: 30%;
        width: 15%;
        left: 0;
        ${media.xs`
            top: 40%;
            width: 20%;
        `};
        ${media.sm`
            top: 40%;
            width: 27%;
        `};
        ${media.xl`
            width: 40%;
        `};
    };
    &::after {
        content: "";
        position: absolute;
        height: 5px;
        border-bottom: 1px solid ${siteBaseTheme.base.colors.primary};
        top: 30%;
        width: 15%;
        right: 0;
        ${media.xs`
            top: 40%;
            width: 20%;
        `};
        ${media.sm`
            top: 40%;
            width: 27%;
        `};
        ${media.xl`
            width: 40%;
        `};
    }
`;

export default TitleWrapper;