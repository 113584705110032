import {
    GET_ALL_LINK_NOTIFICATIONS_SUCCESS,
    GET_LINK_NOTIFICATIONS_SUCCESS,
    RESET_LINK_NOTIFICATIONS_START,
    SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
} from "../actions/LinkNotificationActions";

const DEFAULT_STATE = {
    linkNotifications: undefined,
    total: 0,
    hasNext: true,
    page: 0,
};
const LinkNotificationsReducer = (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS:
        case GET_ALL_LINK_NOTIFICATIONS_SUCCESS:
        case GET_LINK_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }
        case RESET_LINK_NOTIFICATIONS_START: {
            return { ...DEFAULT_STATE };
        }
        default: {
            return { ...state };
        }
    }
};

export default LinkNotificationsReducer;