import { defaultTo, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';

const SplitAsidePageContentWrapper = styled.div`
    min-height: 100%;
    width: 100%;

    ${({ isMobile, bgStart, bgEnd }) => {
        if (isMobile) {
            return css`
                background-color: ${bgStart};
                background-image: none;
            `;
        }
        if (bgStart && bgEnd) {
            return css`
                background-image: ${`linear-gradient(90deg, ${bgStart} 50%, ${bgEnd} 50%)`};
            `;
        }

        return css`
            background-color: ${bgStart};
        `;
    }};

    ${({ bgImage }) => {
        if (!isEmpty(bgImage)) {
            const { image, position, size, repeat, opacity } = bgImage;
            const { width, height } = size;

            return css`
                &:after {
                    background-image: ${`url(${image})`};
                    background-position: ${defaultTo(position, 'center')};
                    background-repeat: ${repeat ? 'repeat' : 'no-repeat'};
                    background-size: ${height} ${width};
                    bottom: 0;
                    content: '';
                    left: 0;
                    opacity: ${opacity};
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            `;
        }
        return css``;
    }};
`;

export default SplitAsidePageContentWrapper;
