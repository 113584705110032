import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewAppHeaderSafeArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: ${rem(50)};
    padding-top: ${rem(15)};
    position: relative;
    width: 100%;

    &:after {
        background-color: #000;
        border-radius: ${rem(32)};
        content: '';
        height: ${rem(30)};
        left: calc(50% - ${rem(55)});
        position: absolute;
        top: ${rem(15)};
        width: ${rem(110)};
    }
`;

export default FavePagePreviewAppHeaderSafeArea;
