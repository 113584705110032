import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    POST_SAVE_PRODUCT_PROCESS,
    POST_SAVE_PRODUCT_START,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
} from 'redux/actions/EditProductActions';
import { productToEditSelector } from 'redux/selectors/productSelectors';
import { Overlay, OverlayContext } from 'baseline-ui/overlay';
import overlayPropTypes from 'baseline-ui/overlay/src/prop-types/overlayPropTypes';
import useIsFormDirtyFeedback from 'utils/useIsFormDirtyFeedback';
import { useIntl } from 'react-intl';
import useProcessLoader from 'utils/useProcessLoader';
import { ModalContext } from 'baseline-ui/modal';
import ModalUnsavedChanges from 'screens/BusinessForm/ModalUnsavedChanges';
import ModalEditProductInformation from './ModalEditProductInformation';
import ModalEditProductPhotos from './ModalEditProductPhotos';
import ModalEditProductCategories from './ModalEditProductCategories';
import ModalEditProductSections from './ModalEditProductSections';

const ModalEditProductForm = ({ title, footerActions, onRequestClose, ...props }) => {
    const intl = useIntl();
    const { hideOverlay } = useContext(OverlayContext);
    const { showModal, hideModal } = useContext(ModalContext);
    const product = useSelector(productToEditSelector);

    const { values, dirty, handleSubmit, isValid } = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
            payload: values,
        });
    }, [values]);

    useIsFormDirtyFeedback({
        isDirty: dirty,
        dependencies: [values],
        processName: SET_PRODUCT_TO_EDIT_PROPERTY_START,
    });

    const isLoading = useProcessLoader({
        processNames: [POST_SAVE_PRODUCT_PROCESS],
        withOverlay: true,
    });

    if (!product) {
        return null;
    }

    const { hasUnsavedChanges } = product;

    const handleOnClickSaveCallback = () => {
        if (hasUnsavedChanges) {
            return showModal(ModalUnsavedChanges, {
                onSaveCallback: () => {
                    if (isValid) {
                        dispatch({ type: POST_SAVE_PRODUCT_START });
                    } else {
                        hideModal();
                        handleSubmit();
                    }
                },
                onSaveCompleteCallback: () => {
                    hideOverlay();
                },
                processNames: [POST_SAVE_PRODUCT_PROCESS],
                onLeaveCallback: () => {
                    hideOverlay();
                },
            });
        }

        return hideOverlay();
    };

    return (
        <Overlay
            title={title}
            size="lg"
            handleOnDismiss={handleOnClickSaveCallback}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.cancel.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => {
                        handleOnClickSaveCallback();
                    },
                    type: 'button',
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.confirm.label',
                    }),
                    skin: 'secondary',
                    onClick: () => {
                        handleSubmit();
                    },
                    loading: isLoading,
                    loadingMessage: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.confirming.label',
                    }),
                    type: 'submit',
                },
            ]}
            {...props}
        >
            <Form noValidate>
                <ModalEditProductInformation />
                <ModalEditProductPhotos />
            </Form>
            <ModalEditProductCategories />
            <ModalEditProductSections />
        </Overlay>
    );
};

ModalEditProductForm.propTypes = {
    title: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    footerActions: overlayPropTypes.overlayFooterActions,
};

ModalEditProductForm.defaultProps = {
    title: undefined,
    footerActions: undefined,
};

export default ModalEditProductForm;
