import { baseDarkTheme } from 'baseline-ui/base-theme';
import loaderTheme from './loaderTheme';

const loaderDarkTheme = {
    loader: {
        ...loaderTheme.loader,
        container: {
            backgroundColor: baseDarkTheme.base.grays.grayLighter,
        },
        dash: {
            backgroundColor: baseDarkTheme.base.grays.gray,
        },
    },
};

export default loaderDarkTheme;
