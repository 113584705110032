import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { stripUnit, darken } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const SwitchIndicator = styled.label`
    background-color: ${(props) =>
        get(
            props,
            `theme.form.switch.backgroundColorOff`,
            formTheme.form.switch.backgroundColorOff,
        )};
    border-color: ${(props) =>
        get(props, `theme.form.switch.borderColor`, formTheme.form.switch.borderColor)};
    border-radius: ${(props) =>
        get(
            props,
            `theme.form.switch.size[${props.sizeValue}]`,
            formTheme.form.switch.size[props.sizeValue],
        )};
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${(props) =>
        stripUnit(
            get(
                props,
                `theme.form.switch.size[${props.sizeValue}]`,
                formTheme.form.switch.size[props.sizeValue],
            ),
        ) / 2.6}rem;
    height: ${(props) =>
        get(
            props,
            `theme.form.switch.size[${props.sizeValue}]`,
            formTheme.form.switch.size[props.sizeValue],
        )};
    line-height: ${(props) =>
        get(
            props,
            `theme.form.switch.size[${props.sizeValue}]`,
            formTheme.form.switch.size[props.sizeValue],
        )};
    margin-bottom: 0.3125rem;
    max-width: 100%;
    outline: none;
    padding: 1px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: ${(props) =>
        stripUnit(
            get(
                props,
                `theme.form.switch.size[${props.sizeValue}]`,
                formTheme.form.switch.size[props.sizeValue],
            ),
        ) * 2.25}rem;

    :before,
    :after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
    }

    :before {
        background-color: ${(props) =>
            get(
                props,
                `theme.form.switch.backgroundColorOff`,
                formTheme.form.switch.backgroundColorOff,
            )};
        border-radius: ${(props) =>
            get(
                props,
                `theme.form.switch.size[${props.sizeValue}]`,
                formTheme.form.switch.size[props.sizeValue],
            )};
        color: ${(props) =>
            get(props, `theme.form.switch.colorOff`, formTheme.form.switch.colorOff)};
        content: attr(data-switch-off);
        right: 0;
        text-indent: ${(props) =>
            stripUnit(
                get(
                    props,
                    `theme.form.switch.size[${props.sizeValue}]`,
                    formTheme.form.switch.size[props.sizeValue],
                ),
            ) * 1.25}rem;
        text-shadow: 1px 1px 1px #fff;
        transition: background 0.4s;
    }

    :after {
        background-color: ${(props) =>
            get(
                props,
                `theme.form.switch.backgroundColorToggle`,
                formTheme.form.switch.backgroundColorToggle,
            )};
        border-color: ${(props) =>
            get(
                props,
                `theme.form.switch.borderColorToggle`,
                formTheme.form.switch.borderColorToggle,
            )};
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        box-shadow:
            inset 0px 0px 0px 5px #fff,
            0 2px 5px rgba(0, 0, 0, 0.3);
        transition: margin 0.3s;
        width: ${(props) =>
            get(
                props,
                `theme.form.switch.size[${props.sizeValue}]`,
                formTheme.form.switch.size[props.sizeValue],
            )};
    }

    ${(props) =>
        props.checked &&
        css`
            :before {
                background-color: ${get(
                    props,
                    `theme.form.switch.backgroundColorOn`,
                    formTheme.form.switch.backgroundColorOn,
                )};
                color: ${get(props, `theme.form.switch.colorOn`, formTheme.form.switch.colorOn)};
                content: attr(data-switch-on);
                text-indent: ${stripUnit(
                    get(
                        props,
                        `theme.form.switch.size[${props.sizeValue}]`,
                        formTheme.form.switch.size[props.sizeValue],
                    ),
                ) * 0.4}rem;
                text-shadow: 1px 1px 1px
                    ${darken(
                        0.1,
                        get(
                            props,
                            `theme.form.switch.backgroundColorOn`,
                            formTheme.form.switch.backgroundColorOn,
                        ),
                    )};
            }

            :after {
                margin-left: ${stripUnit(
                    get(
                        props,
                        `theme.form.switch.size[${props.sizeValue}]`,
                        formTheme.form.switch.size[props.sizeValue],
                    ),
                ) * 1.2}rem;
            }
        `}

    ${(props) =>
        props.focused &&
        css`
            box-shadow: 0 0 0 2px rgba(102, 175, 233, 0.4);
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
        `}
`;

export default SwitchIndicator;
