import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, AnchorButton } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_LINKS } from 'common/addBusinessViewIds';
import FavePagePreviewLinksContainer from './FavePagePreviewLinksContainer';

const FavePagePreviewLinks = ({ links, addToSection }) => {
    const { hideModal } = useContext(ModalContext);

    const renderContent = useMemo(() => {
        if (isEmpty(links)) {
            return (
                <Button
                    btnType="link"
                    size="sm"
                    icon="plus"
                    onClick={() => {
                        hideModal();
                        addToSection(ADD_BUSINESS_LINKS);
                    }}
                >
                    Add Links
                </Button>
            );
        }
        return links.map((link, index) => {
            const { caption, url } = link;

            return (
                <div style={{ paddingBottom: 4, paddingTop: 4 }} key={`${url}-${index}`}>
                    <AnchorButton block path={url} target="_blank" rel="noreferrer">
                        <span style={{ whiteSpace: 'normal' }}>{caption}</span>
                    </AnchorButton>
                </div>
            );
        });
    }, [links]);

    return <FavePagePreviewLinksContainer>{renderContent}</FavePagePreviewLinksContainer>;
};

FavePagePreviewLinks.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            caption: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    addToSection: PropTypes.func,
};

FavePagePreviewLinks.defaultProps = {
    links: undefined,
    addToSection: () => {},
};

export default FavePagePreviewLinks;
