const siteHeaderDarkTheme = {
    header: {
        backgroundColor: '#482655',
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.125)',
            verticalShadow: '4px 0 8px rgba(0,0,0,0.125)',
        },
        navigation: {
            label: {
                uppercase: false,
                fontWeight: 400,
            },
            active: {
                width: 4,
                height: 4,
            },
        },
        zIndex: 5,
    },
};

export default siteHeaderDarkTheme;
