import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewEntityTypesContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    margin-left: -${rem(16)};
    margin-right: -${rem(16)};
    margin-top: ${rem(16)};
    padding: ${rem(4)} ${rem(16)};
`;

export default FavePagePreviewEntityTypesContainer;
