import styled from 'styled-components';
import {rem} from "polished";
import {media} from "baseline-ui/layout";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${rem(32)};
  padding: ${rem(32)} ${rem(32)};
  
  ${media.md`
     flex-direction: row;
  `};
`;

export default ListContainer;