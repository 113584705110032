import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { TypographyGlobalStyles } from 'baseline-ui/typography';
import ThemeModeContext from './context/ThemeModeContext';
import GlobalStyle from './GlobalStyle';
import baselineTheme from '../styles/baselineTheme';

const BaselineThemeMode = ({ theme, children }) => {
    const { themeMode } = useContext(ThemeModeContext);
    const currentTheme = theme[themeMode];

    return (
        <ThemeProvider theme={currentTheme}>
            <GlobalStyle theme={currentTheme} themeMode={themeMode} />
            <TypographyGlobalStyles />
            {children}
        </ThemeProvider>
    );
};

BaselineThemeMode.propTypes = {
    theme: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};

BaselineThemeMode.defaultProps = {
    theme: baselineTheme,
};

export default BaselineThemeMode;
