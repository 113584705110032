import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useIsFormDirtyFeedback = ({ isDirty, dependencies = [], processName }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isDirty) {
            dispatch({
                type: processName,
                payload: {
                    hasUnsavedChanges: true,
                },
            });
        }
    }, [isDirty, ...dependencies]);
};

export default useIsFormDirtyFeedback;
