import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import SplitAsideMainTemplate from 'components/pageLayout/templates/SplitAsideMainTemplate';
import splitAsideMainTemplateBgImage from 'components/pageLayout/templates/splitAsideMainTemplateBgImage';
import { AuthFormContainer } from 'baseline-ui/auth';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import WelcomeMainHeader from './Welcome/WelcomeMainHeader';
import CommonWrapper from './CommonWrapper';
import WelcomeTermsAndConditions from './Welcome/WelcomeTermsAndConditions';

const TermsAndConditionsRoute = () => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();

    return (
        <CommonWrapper>
            <SplitAsideMainTemplate
                bgStart={theme.site.welcome.bgEnd}
                bgEnd={theme.site.welcome.bgEnd}
                bgImage={splitAsideMainTemplateBgImage}
                aside={<WelcomeTermsAndConditions />}
                main={
                    <WelcomeMainContainer>
                        <WelcomeMainHeader
                            title={intl.formatMessage({ id: 'termsAndConditions.page.title' })}
                        />
                        <AuthFormContainer>
                            <ContentRenderer
                                textAlign="center"
                                source={intl.formatMessage({
                                    id: 'termsAndConditions.page.content.md',
                                })}
                            />
                        </AuthFormContainer>
                    </WelcomeMainContainer>
                }
            />
        </CommonWrapper>
    );
};

export default TermsAndConditionsRoute;
