import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import loaderTheme from '../../styles/loaderTheme';

const Container = styled.span`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ isFullScreen, backgroundColor, theme }) =>
        isFullScreen &&
        css`
            background-color: ${backgroundColor ||
            get(theme, 'base.colors.light', baseTheme.base.colors.light)};
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: ${get(theme, 'loader.container.zIndex', loaderTheme.loader.container.zIndex)};
        `}

    ${({ offsetH }) =>
        offsetH &&
        css`
            left: ${rem(offsetH)};
            width: calc(100% - ${rem(offsetH)});
        `}


    ${({ size }) =>
        size &&
        css`
            font-size: ${rem(size)};
        `}
`;

export default Container;
