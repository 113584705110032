import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormCheckbox, FormInput } from 'baseline-ui/form';
import AdminBusinessAdvertisingOrderContainer from './AdminBusinessAdvertisingOrderContainer';

const AdminBusinessAdvertisingOrder = ({ id }) => {
    const renderCheckboxId = `${id}OrderEnabled`;
    const renderId = `${id}Order`;

    const { values, setFieldValue } = useFormikContext();

    return (
        <AdminBusinessAdvertisingOrderContainer>
            <Field
                component={FormCheckbox}
                skin="dark"
                id={renderCheckboxId}
                name={renderCheckboxId}
                onChange={() => {
                    if (values[`${id}Rotate`]) {
                        setFieldValue(`${id}Rotate`, false);
                    }
                }}
            >
                Order
            </Field>
            <Field
                component={FormInput}
                label="Number"
                name={renderId}
                id={renderId}
                prependAddon="#"
                type="number"
                min="0"
            />
        </AdminBusinessAdvertisingOrderContainer>
    );
};

AdminBusinessAdvertisingOrder.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AdminBusinessAdvertisingOrder;
