import siteBaseTheme from './siteBaseTheme';

const siteFormTheme = {
    form: {
        input: {
            dark: {
                backgroundColor: '#ffffff',
                backgroundColorFocus: '#ffffff',
                borderColor: siteBaseTheme.base.grays.grayLightest,
                borderColorFocus: siteBaseTheme.base.colors.primary,
                color: siteBaseTheme.base.grays.grayDarkest,
                colorDisabled: siteBaseTheme.base.grays.gray,
            },
        },
        label: {
            dark: {
                color: siteBaseTheme.base.grays.grayDark,
                colorFilled: siteBaseTheme.base.grays.gray,
                colorFocus: siteBaseTheme.base.colors.primary,
                colorDisabled: siteBaseTheme.base.grays.gray,
            },
            fontSize: 16,
            fontSizeFocus: 11,
        },

        checkbox: {
            dark: {
                backgroundColor: 'transparent',
                backgroundColorChecked: siteBaseTheme.base.colors.primary,
                borderColor: siteBaseTheme.base.grays.grayDark,
                borderColorChecked: siteBaseTheme.base.colors.primary,
                colorChecked: siteBaseTheme.base.colors.dark,
                colorDisabled: siteBaseTheme.base.grays.grayLighter,
                colorDisabledChecked: siteBaseTheme.base.grays.grayLighter,
                indicatorColor: '#ffffff',
                indicatorDisabledColor: siteBaseTheme.base.grays.grayLightest,
            },
        },
    },
};

export default siteFormTheme;
