import { baseDarkTheme } from 'baseline-ui/base-theme';
import authTheme from './authTheme';

const authDarkTheme = {
    auth: {
        ...authTheme.auth,
        divider: {
            color: baseDarkTheme.base.grays.grayLighter,
        },
    },
};

export default authDarkTheme;
