import React from 'react';
import { Field, Form } from 'formik';
import { Col, Row } from 'baseline-ui/layout';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'baseline-ui/button';
import useProcessLoader from 'utils/useProcessLoader';
import { ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS } from 'redux/actions/BusinessAccessRightsActions';

const AccessRightsForm = () => {
    const intl = useIntl();

    const isLoading = useProcessLoader({
        processNames: [ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withOverlay: true,
    });


    return (
        <Form noValidate>
            <Row>
                <Col xxs={30} lg={30} xl={20}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({
                                id: 'addBusiness.page.sections.accessRights.form.emailAddress.label',
                            })}
                            name="accessRightsEmailAddress"
                            id="accessRightsEmailAddress"
                            type="email"
                        />
                    </FieldContainer>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        type="submit"
                        loading={isLoading}
                        loadingMessage={intl.formatMessage({
                            id: 'addBusiness.page.sections.accessRights.form.addingUser.label',
                        })}
                    >
                        <FormattedMessage id="addBusiness.page.sections.accessRights.form.addUser.label" />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AccessRightsForm;
