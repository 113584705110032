import React from 'react';
import PropTypes from 'prop-types';
import { H3, H6 } from 'baseline-ui/typography';
import fileUploaderUtils from 'utils/fileUploaderUtils';
import { useSelector } from 'react-redux';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { ENTITY_TYPE_PEOPLE } from 'common/consts';
import FavePagePreviewPhotos from './FavePagePreviewPhotos';
import FavePagePreviewScreenContainer from './FavePagePreviewScreenContainer';
import FavePagePreviewAppHeader from './FavePagePreviewAppHeader';
import FavePagePreviewContentContainer from './favePagePreviewContent/FavePagePreviewContentContainer';
import FavePagePreviewFaves from './favePagePreviewContent/FavePagePreviewFaves';
import FavePagePreviewContentSectionContainer from './favePagePreviewContent/FavePagePreviewContentSectionContainer';
import FavePagePreviewContentWrapper from './favePagePreviewContent/FavePagePreviewContentWrapper';
import FavePagePreviewContentHeader from './favePagePreviewContent/FavePagePreviewContentHeader';
import FavePagePreviewLinks from './FavePagePreviewLinks';
import FavePagePreviewDescription from './FavePagePreviewDescription';
import FavePagePreviewDetails from './FavePagePreviewDetails';
import FavePagePreviewModalHeader from './FavePagePreviewModalHeader';
import FavePagePreviewWrapper from './FavePagePreviewWrapper';
import FavePagePreviewEntityTypes from './FavePagePreviewEntityTypes/FavePagePreviewEntityTypes';

const FavePagePreview = ({ addToSection }) => {
    const { links, photos, name, description, phone, formattedAddress, position, type } =
        useSelector(businessFormSelector);

    const renderPosition = position && type === ENTITY_TYPE_PEOPLE;

    return (
        <FavePagePreviewWrapper>
            <FavePagePreviewModalHeader />
            <FavePagePreviewScreenContainer>
                <FavePagePreviewAppHeader />
                <FavePagePreviewContentWrapper>
                    <FavePagePreviewPhotos
                        addToSection={addToSection}
                        photos={fileUploaderUtils.currentPhotoList(photos)}
                    />
                    <FavePagePreviewContentContainer>
                        <FavePagePreviewFaves />
                        <FavePagePreviewContentSectionContainer>
                            <FavePagePreviewContentHeader>
                                <H3 textAlign="center">{name}</H3>
                                {renderPosition && <H6 textAlign="center">{position}</H6>}
                                <FavePagePreviewDescription
                                    description={description}
                                    addToSection={addToSection}
                                />
                            </FavePagePreviewContentHeader>
                        </FavePagePreviewContentSectionContainer>
                        <FavePagePreviewDetails phone={phone} address={formattedAddress} />
                        <FavePagePreviewLinks links={links} addToSection={addToSection} />
                        <FavePagePreviewEntityTypes addToSection={addToSection} />
                    </FavePagePreviewContentContainer>
                </FavePagePreviewContentWrapper>
            </FavePagePreviewScreenContainer>
        </FavePagePreviewWrapper>
    );
};

FavePagePreview.propTypes = {
    addToSection: PropTypes.func,
};

FavePagePreview.defaultProps = {
    addToSection: () => {},
};

export default FavePagePreview;
