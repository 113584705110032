import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const Select = styled.select`
    appearance: none;
    background-color: ${({ theme, skin }) =>
        get(
            theme,
            `form.input.${skin}.backgroundColor`,
            formTheme.form.input[skin].backgroundColor,
        )};
    background-image: ${({ theme, skin }) =>
        `url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201792%201792%22%3E%0A%20%20%3Cpath%20fill%3D%22${get(
            theme,
            `form.label.${skin}.color`,
            formTheme.form.label[skin].color,
        ).replace(
            '#',
            '%23',
        )}%22%20d%3D%22M1408%20704c0%2017.3-6.3%2032.3-19%2045l-448%20448c-12.7%2012.7-27.7%2019-45%2019s-32.3-6.3-45-19L403%20749c-12.7-12.7-19-27.7-19-45s6.3-32.3%2019-45%2027.7-19%2045-19h896c17.3%200%2032.3%206.3%2045%2019s19%2027.7%2019%2045z%22%2F%3E%0A%3C%2Fsvg%3E")`};
    background-position: ${`calc(100% - ${rem(12)}) 58%`};
    background-repeat: no-repeat;
    background-size: ${rem(16)};
    border-color: ${({ theme, skin }) =>
        get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)};
    border-style: solid;
    border-radius: ${({ theme }) =>
    rem(get(theme, 'form.input.borderRadius', formTheme.form.input.borderRadius))};;
    border-width: 1px;
    color: ${({ theme, skin }) =>
        get(theme, `form.label.${skin}.color`, formTheme.form.label[skin].color)};
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.input.fontSize', formTheme.form.input.fontSize))};
    height: ${({ theme }) => rem(get(theme, 'form.input.height', formTheme.form.input.height))};
    padding: ${rem(12)} ${rem(12)} ${rem(7)};
    transition: background 0.3s ease-in 0.1s;
    width: 100%;

    &:focus {
        border-color: ${({ theme, skin }) =>
            get(theme, `form.input.${skin}.borderColorFocus`, formTheme.form.input[skin].borderColorFocus)};
        background-color: ${({ theme, skin }) =>
            get(
                theme,
                `form.input.${skin}.backgroundColorFocus`,
                formTheme.form.input[skin].backgroundColorFocus,
            )};
        color: transparent;
        outline: 0;
    }

    ${({ theme, skin, selected }) =>
        selected &&
        css`
            color: ${get(theme, `form.input.${skin}.color`, formTheme.form.input[skin].color)};
            padding-top: ${rem(24)};

            &:focus {
                color: ${get(theme, `form.label.${skin}.color`, formTheme.form.label[skin].color)};
            }
        `}

    ${({ disabled, skin, theme }) =>
        disabled &&
        css`
            background-image: ${`url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201792%201792%22%3E%0A%20%20%3Cpath%20fill%3D%22${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            ).replace(
                '#',
                '%23',
            )}%22%20d%3D%22M1408%20704c0%2017.3-6.3%2032.3-19%2045l-448%20448c-12.7%2012.7-27.7%2019-45%2019s-32.3-6.3-45-19L403%20749c-12.7-12.7-19-27.7-19-45s6.3-32.3%2019-45%2027.7-19%2045-19h896c17.3%200%2032.3%206.3%2045%2019s19%2027.7%2019%2045z%22%2F%3E%0A%3C%2Fsvg%3E")`};
            border-style: dotted;
            color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
            cursor: not-allowed;
        `}

    &::-ms-expand {
        display: none;
    }

    &:focus::-ms-value {
        background: transparent;
        color: ${({ theme, skin }) =>
            get(theme, `form.input.${skin}.color`, formTheme.form.input[skin].color)};
    }

    ${({ hideLabel, theme }) =>
        hideLabel &&
        css`
            background-position-y: 55%;
            height: ${rem(
                get(theme, 'form.input.heightNoLabel', formTheme.form.input.heightNoLabel),
            )};
            padding-top: ${rem(12)};
        `}
`;

export default Select;
