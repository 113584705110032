import styled from 'styled-components';
import { rem } from 'polished';

const PhotoListItemOuter = styled.div`
    display: flex;
    flex-basis: 33%;
    justify-content: center;
    padding-bottom: ${rem(8)};
    padding-top: ${rem(8)};
    padding-right: ${rem(15)};
`;

export default PhotoListItemOuter;
