import styled from 'styled-components';

const SliderDotList = styled.ul`
    margin: 0;
    padding: 0;

    li {
        cursor: pointer;
        display: inline-block;
        margin: 0 0.3125rem;
        padding: 0;
        position: relative;
    }
`;

export default SliderDotList;
