import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewEntityTypesTabItem = styled.div`
    margin-left: -${rem(16)};
    margin-right: -${rem(16)};
    padding: ${rem(4)} ${rem(16)};
`;

export default FavePagePreviewEntityTypesTabItem;
