import siteBaseTheme from './siteBaseTheme';

const siteCarouselTheme = {
    carousel: {
        dots: {
            activeBackground: {
                default: siteBaseTheme.base.colors.primary,
            },
        },
    },
};

export default siteCarouselTheme;
