import BusinessesReducer from './BusinessesReducer';
import ErrorReducer from './ErrorReducer';
import ProcessReducer from './ProcessReducer';
import OwnABusinessReducer from './OwnABusinessReducer';
import SectionReducer from './SectionReducer';
import EditProductReducer from './EditProductReducer';
import CategoriesReducer from './CategoriesReducer';
import ItemReducer from './ItemReducer';
import BusinessAccessRightsReducer from './BusinessAccessRightsReducer';
import ClaimsReducer from './ClaimsReducer';
import SystemNotificationsReducer from './SystemNotificationsReducer';
import PromotionNotificationsReducer from './PromotionNotificationsReducer';
import SuggestReducer from './SuggestReducer';
import AuthenticationReducer from './AuthenticationReducer';
import BusinessFormReducer from './BusinessFormReducer';
import BetaSignupReducer from './BetaSignupReducer';
import ProfileReducer from './ProfileReducer';
import ResetPasswordReducer from './ResetPasswordReducer';
import EntityPickerReducer from './EntityPickerReducer';
import RequestToLinkReducer from './RequestToLinkReducer';
import LinkNotificationsReducer from './LinkNotificationsReducer';
import EventReducer from './EventReducer';
export default {
    BusinessesReducer,
    ErrorReducer,
    ProcessReducer,
    OwnABusinessReducer,
    SectionReducer,
    EditProductReducer,
    CategoriesReducer,
    ItemReducer,
    BusinessAccessRightsReducer,
    ClaimsReducer,
    SystemNotificationsReducer,
    PromotionNotificationsReducer,
    SuggestReducer,
    AuthenticationReducer,
    BusinessFormReducer,
    BetaSignupReducer,
    ProfileReducer,
    ResetPasswordReducer,
    EntityPickerReducer,
    RequestToLinkReducer,
    LinkNotificationsReducer,
    EventReducer
};
