import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Flex } from '@rebass/grid';
import { ThemeContext } from 'styled-components';
import layoutTheme from '../../styles/layoutTheme';

const Container = ({ children, isFullHeight, ...props }) => {
    const theme = useContext(ThemeContext);
    return (
        <Flex
            px={get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter)}
            height={isFullHeight ? '100%' : 'auto'}
            {...props}
        >
            {children}
        </Flex>
    );
};

Container.propTypes = {
    children: PropTypes.node,
    isFullHeight: PropTypes.bool,
};

Container.defaultProps = {
    children: null,
    isFullHeight: true,
};

export default Container;
