import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
    DROP_PRODUCT_TO_SECTION_START,
    UPDATE_SECTION_PRODUCTS_ORDER_START,
} from 'redux/actions/EditProductActions';
import { FeedbackContext } from 'baseline-ui/feedback';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { editedProductsSelector } from 'redux/selectors/productSelectors';
import productsAndServicesUtils from 'utils/productsAndServicesUtils';
import { useIntl } from 'react-intl';
import {
    allSectionProductsSelector,
    selectedSectionIdBySectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import { isEmpty } from 'lodash';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import ProductsAndServicesItemListTabItemContainer from './ProductsAndServicesItemListTabItemContainer';
import ProductsAndServicesItemListTabItemDrop from './ProductsAndServicesItemListTabItemDrop';
import ProductsAndServicesItem from './ProductsAndServicesItem';
import ProductsAndServicesItemListTabItemHint from './ProductsAndServicesItemListTabItemHint';

const ProductsAndServicesItemListTabItem = memo(({ sectionContainer }) => {
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const editedProducts = useSelector(editedProductsSelector);
    const intl = useIntl();
    const allSectionProducts = useSelector(allSectionProductsSelector);

    const selectedSectionId = useSelector(selectedSectionIdBySectionNameSelector);

    const dispatch = useDispatch();

    const { sectionName, sectionId, listItems } = sectionContainer;

    const [{ canDrop }, drop] = useDrop(
        {
            accept: 'items',
            drop: ({ id }) => {
                const item = productsAndServicesUtils.getItemById({
                    products: allSectionProducts,
                    id,
                });

                if (
                    productsAndServicesUtils.isItemInSection({
                        itemId: item._id,
                        sectionItems: listItems,
                    })
                ) {
                    setFeedbackOnStage({
                        label: intl.formatMessage(
                            {
                                id: 'addBusiness.page.sections.productsAndServices.items.itemAlreadyInsection.alert.label',
                            },
                            { itemName: item.name, sectionName },
                        ),
                        type: 'warning',
                        show: true,
                        timeout: 3200,
                    });
                } else {
                    dispatch({
                        type: DROP_PRODUCT_TO_SECTION_START,
                        payload: { item, sectionId },
                    });
                }
            },
            collect: (monitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                };
            },
        },
        [sectionContainer, editedProducts, allSectionProducts],
    );

    const onSortEnd = (oldIndex, newIndex) => {
        const products = productsAndServicesUtils.getProductsByIds({
            products: allSectionProducts,
            ids: listItems,
        });
        const sortedListItems = arrayMove(products, oldIndex, newIndex);
        dispatch({
            type: UPDATE_SECTION_PRODUCTS_ORDER_START,
            payload: sortedListItems,
        });
        dispatch({ type: SET_BUSINESS_FORM_DATA_START, payload: { hasUnsavedChanges: true } });
    };

    const allowDrag = listItems?.length > 1;

    if (selectedSectionId !== sectionId) {
        return null;
    }

    return (
        <ProductsAndServicesItemListTabItemDrop ref={drop}>
            <ProductsAndServicesItemListTabItemHint />
            <ProductsAndServicesItemListTabItemContainer style={{ opacity: canDrop ? 0.6 : 1 }}>
                <SortableList allowDrag={allowDrag} onSortEnd={onSortEnd} lockAxis="y">
                    {!isEmpty(listItems) &&
                        listItems.map((listItem) => {
                            const item = productsAndServicesUtils.getItemById({
                                products: allSectionProducts,
                                id: listItem,
                            });

                            const { name, _id: id } = item;
                            return (
                                <SortableItem key={`${name}-${id}`}>
                                    <ProductsAndServicesItem
                                        ref={sectionId}
                                        item={item}
                                        sectionId={sectionId}
                                        sectionName={sectionName}
                                        allowDrag={allowDrag}
                                    />
                                </SortableItem>
                            );
                        })}
                </SortableList>
            </ProductsAndServicesItemListTabItemContainer>
        </ProductsAndServicesItemListTabItemDrop>
    );
});

ProductsAndServicesItemListTabItem.propTypes = {
    sectionContainer: PropTypes.shape({
        sectionName: PropTypes.string,
        sectionId: PropTypes.string,
        listItems: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default ProductsAndServicesItemListTabItem;
