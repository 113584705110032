import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import Paragraph from './Paragraph';

const P = ({ color, lead, className, textAlign, children }) => {
    return (
        <Paragraph className={className} colorValue={color} lead={lead} textAlign={textAlign}>
            {children}
        </Paragraph>
    );
};

P.propTypes = {
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    lead: PropTypes.bool,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    children: PropTypes.node,
};

P.defaultProps = {
    color: 'dark',
    className: null,
    lead: false,
    textAlign: undefined,
    children: null,
};

export default P;
