import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Fragment, memo, useMemo } from "react";
import { Link } from "react-router-dom";

const LinksPanel = ({ links = "", setLinks, error }) => {
  const parsedLinks = useMemo(() => (links ? JSON.parse(links) : []), [links]);

  return (
    <Grid item xs={12}>
      <Grid style={{ marginBottom: 22 }}>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>

            {error.map((err) => {
              if (err) {
                for (let prop in err) {
                  return err[prop];
                }
              }
            })}
          </Alert>
        )}
      </Grid>
      <Grid container>
        {parsedLinks.map((value, index) => {
          const { url, caption, type, id } = value;
          return (
            <Fragment key={`link_item_${id}`}>
              <Grid item xs={12} md={2} container>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    key={`key_${id}`}
                    value={type}
                    onChange={(e) => {
                      const newLinks = [...parsedLinks];
                      newLinks[index].type = e.target.value;
                      setLinks(JSON.stringify(newLinks));
                    }}
                  >
                    <MenuItem value={"facebook"}>Facebook</MenuItem>
                    <MenuItem value={"instagram"}>Instagram</MenuItem>
                    <MenuItem value={"twitter"}>Twitter</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} container>
                <TextField
                  key={`links_url_${id}`}
                  name="url"
                  label="URL"
                  defaultValue={url}
                  onBlur={(e) => {
                    const newLinks = [...parsedLinks];
                    let protocolPrefix = "";
                    if (e.target.value.indexOf("http") !== 0) {
                      protocolPrefix = "https://";
                    }
                    newLinks[index].url = protocolPrefix + e.target.value;
                    setLinks(JSON.stringify(newLinks));
                  }}
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
                />
              </Grid>
              <Grid item xs={12} md={4} container>
                <TextField
                  key={`links_caption_${url}`}
                  label="Caption"
                  name="caption"
                  defaultValue={caption}
                  onBlur={(e) => {
                    const newLinks = [...parsedLinks];
                    newLinks[index].caption = e.target.value;
                    setLinks(JSON.stringify(newLinks));
                  }}
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
                />
              </Grid>

              <Grid item xs={12} md={2} container alignItems="center">
                <Button
                  key={`links_remove_${id}`}
                  variant="contained"
                  sx={{ height: 40 }}
                  onClick={(e) => {
                    const newLinks = [...parsedLinks];
                    newLinks.splice(index, 1);
                    setLinks(JSON.stringify(newLinks));
                  }}
                  style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
                >
                  Remove
                </Button>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default memo(LinksPanel, (prevProps, props) => {
  return prevProps.links === props.links && prevProps.error === props.error;
});
