import PropTypes from 'prop-types';
import arrowSizeData from '../data/arrowSizeData';
import arrowTypeData from '../data/arrowTypeData';
import dotsTypeData from '../data/dotsTypeData';
import dotsPositionData from '../data/dotsPositionData';
import dotsAlignmentData from '../data/dotsAlignmentData';
import dotsOrientationData from '../data/dotsOrientationData';

const carouselArrowDirection = PropTypes.oneOf(['prev', 'next']);
const carouselArrowSize = PropTypes.oneOf(arrowSizeData);
const carouselDotsType = PropTypes.oneOf(dotsTypeData);
const carouselDotsPosition = PropTypes.oneOf(dotsPositionData);
const carouselDotsAlignment = PropTypes.oneOf(dotsAlignmentData);
const carouselDotsOrientation = PropTypes.oneOf(dotsOrientationData);
const carouselArrowType = PropTypes.oneOf(arrowTypeData);

export default {
    carouselArrowDirection,
    carouselArrowSize,
    carouselDotsType,
    carouselDotsPosition,
    carouselDotsAlignment,
    carouselDotsOrientation,
    carouselArrowType,
};
