import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Modal} from 'baseline-ui/modal';
import {ContentRenderer} from 'baseline-ui/helpers';
import React from 'react';


const InfoModal = ({ onRequestClose, onClickOkCallback, message, title, ...props }) => {
    const intl = useIntl();
    const footerActionButtons = [
        {
            label: intl.formatMessage({
                id: 'fileUpload.modal.submit.footerAction.ok.button.label',
            }),
            loadingMessage: intl.formatMessage({
                id: 'fileUpload.modal.submit.footerAction.ok.button.label',
            }),
            skin: 'primary',
            onClick: () => {
                onRequestClose();
            },
        },

    ];

    return (
        <Modal
            title={title}
            size="sm"
            showCloseButton={false}
            handleOnDismiss={onRequestClose}
            footerActions={footerActionButtons}
            {...props}
        >

            <ContentRenderer
                source={message}
            />

        </Modal>
    );
};

InfoModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onClickOkCallback: PropTypes.func,
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

InfoModal.defaultProps = {
    onClickOkCallback: null,
};

export default InfoModal;
