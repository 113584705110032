import {
  GET_CLAIMS_SUCCESS,
  SET_CLAIM_FILTERS_START,
} from "../actions/ClaimsActions";

export const DEFAULT_FILTERS = {
  sortFields: "LASTNAME",
  sortOrders: "ASC",
  claimState: "SUBMITTED",
};

const ClaimsReducer = (
  state = {
    filters: DEFAULT_FILTERS,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CLAIM_FILTERS_START: {
      const newFilters = { ...state.filters, ...payload };
      if (payload.ticket === undefined) {
        delete newFilters.ticket;
      }
      return { ...state, filters: newFilters };
    }
    case GET_CLAIMS_SUCCESS: {
      return {
        ...state,
        claims: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ClaimsReducer;
