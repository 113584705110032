import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import OrderedList from './OrderedList';

const OL = ({ list, className, ...props }) => {
    return (
        <OrderedList className={className}>
            <List list={list} {...props} />
        </OrderedList>
    );
};

OL.propTypes = {
    list: PropTypes.arrayOf(PropTypes.node).isRequired,
    className: PropTypes.string,
};

OL.defaultProps = {
    className: null,
};

export default OL;
