import { GetProfile } from 'api';

const { takeLatest, put } = require('redux-saga/effects');
const {
    GET_USER_PROFILE_START,
    GET_USER_PROFILE_PROCESS,
    GET_USER_PROFILE_SUCCESS,
} = require('redux/actions/ProfileActions');
const { default: genericSagaHandler } = require('./CommonSaga');

function* getUserProfileSaga() {
    yield genericSagaHandler(GET_USER_PROFILE_PROCESS, function* getUserProfileSagaHandler() {
        const data = yield GetProfile();
        yield put({
            type: GET_USER_PROFILE_SUCCESS,
            payload: {
                ...data,
            },
        });
    });
}

export default function* ProfileSagas() {
    yield takeLatest(GET_USER_PROFILE_START, getUserProfileSaga);
}
