import React, { useState, useEffect, useRef, Fragment, memo } from "react";
import { Alert, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  deserializeOption,
  serializeOption,
  getFlatSubcatOptionsWithHeaders,
} from "./helpers/features";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import RequestSupportModal from "./components/RequestSupportModal";

function CatSubCat({
  mainSubCatMapJson,
  fieldMapJson,
  setFieldMap,
  error,
  formParameters,
}) {
  const mainSubCatMap = mainSubCatMapJson ? JSON.parse(mainSubCatMapJson) : [];
  const fieldMap = fieldMapJson ? JSON.parse(fieldMapJson) : [];

  const createCategories = () => {
    setFieldMap([...fieldMap, { id: "", name: "", subcategories: [] }]);
  };

  const removeTagRow = (idx) => {
    let newMap = fieldMap.filter((v, index) => idx != index);
    setFieldMap(newMap);
  };

  const categoryOptions = mainSubCatMap.map(serializeOption);
  const mapFeatures = (value, index) => {
    const [categorySelected, subCategoryOptions] =
      getFlatSubcatOptionsWithHeaders(value.name, mainSubCatMap);
    const subCatPlaceholder = categorySelected
      ? "Select a subcategory"
      : "First, select a category";
    const categoryValue = serializeOption(fieldMap[index]);
    const subCategoryValue = fieldMap[index].subcategories.map(serializeOption);

    const handleSubcatChange = (e, val) => {
      // we only care about the id and name of the tag
      const optionIds = val.map(deserializeOption);
      fieldMap[index].subcategories = optionIds;

      setFieldMap([...fieldMap]);
    };
    const handleCategoryChange = (e, val) => {
      // we only care about the id and name of the tag
      fieldMap[index] = deserializeOption(val);
      setFieldMap([...fieldMap]);

      // reset subcategories
      handleSubcatChange(null, []);
    };

    return (
      <Fragment key={`category_fragment_${value.id}`}>
        <Grid item xs={12} sm={4} container>
          <FormControl fullWidth>
            <Autocomplete
              key={`categories[${index}]`}
              name={`categories[${index}]`}
              sx={{ height: 40 }}
              label={"Category"}
              value={categoryValue}
              options={categoryOptions}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="standard" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} container>
          <FormControl fullWidth>
            <Autocomplete
              key={`categories[${index}].subcategories`}
              multiple
              name={`categories[${index}].subcategories`}
              options={subCategoryOptions}
              label="Subcategory"
              value={subCategoryValue}
              onChange={handleSubcatChange}
              getOptionLabel={(option) => option.label}
              groupBy={(option) => option.typeName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Subcategory" variant="standard" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} container alignItems="center">
          <Button
            key={`remove${index}`}
            variant="contained"
            sx={{ height: 40 }}
            onClick={(e) => {
              removeTagRow(index);
            }}
          >
            Remove
          </Button>
        </Grid>
      </Fragment>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={"h3"}>Categories</Typography>
        <Typography variant={"body1"}>
          Select categories and sub categories to help users find your business.
          Pick a category that best describes your business, you can change this
          later too.
        </Typography>
        <Grid
          item
          xs={12}
          style={{ marginTop: 12, display: "flex", alignItems: "center" }}
        >
          Cannot find a suitable category?{" "}
          <RequestSupportModal
            caption={"Request support"}
            defaultType="CATEGORIES"
            defaultValue="I cannot find a suitable category"
          />
        </Grid>
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      {fieldMap.map(mapFeatures)}
      <Grid item xs={12} container>
        <Button variant="contained" onClick={createCategories}>
          Add Category+
        </Button>
      </Grid>
    </>
  );
}

export default memo(CatSubCat, (prevProps, props) => {
  return (
    prevProps.fieldMapJson === props.fieldMapJson &&
    prevProps.error === props.error
  );
});
