import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom';
// import Invest from './Invest';
// import Opportunities from './MarketingOpportunities';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import SignUpRoute from 'screens/SignUpRoute';
import AccountRoute from 'screens/AccountRoute';
import InquireRoute from 'screens/InquireRoute';
import ReferAFriendRoute from 'screens/ReferAFriendRoute';
import CreateFavePage from 'screens/CreateFavePage';
import RouteElementWrapper from 'routes/RouteElementWrapper';
import RecoverPasswordRoute from 'screens/RecoverPasswordRoute';
import AuthRedirectRouteWrapper from 'screens/AuthRedirectRouteWrapper';
import TermsAndConditionsRoute from 'screens/TermsAndConditionsRoute';
import AddFavePage from 'screens/AddFavePage';
import {
    ADD_BUSINESS_PATH,
    ADMIN_AREA_PATH,
    BETA_SIGNUPS,
    HOME_PATH,
    INQUIRE_PATH,
    LOGIN_PATH,
    MY_FAVEPAGE_PATH,
    ACCOUNT_PATH,
    REFER_A_FRIEND_PATH,
    CREATE_FAVEPAGE,
    FORGOT_PASSWORD_PATH,
    EDIT_BUSINESS_PATH,
    TERMS_AND_CONDITIONS_PATH,
    SYSTEM_PUSH,
    TAG_MANAGER_PATH,
    CATEGORYMANAGER_PATH,
    ADD_FAVEPAGE_PATH,
    EDIT_FAVEPAGE_PATH,
    LINK_NOTIFICATIONS,
    BUSINESS_PATH,
    ABOUT_PATH,
    UPDATES_PATH,
} from 'common/pathnames';
import TagManager from './CreateTags';
import PageWithNavbar from './PageWithNavbar';
import MySubmissions from './MySubmissions';
import OwnABusiness from './OwnABusiness';
import ProductsSearch from './ProductsSearch';
import reportWebVitals from './reportWebVitals';
// import './index.css';
import RecommendABusiness from './RecommendBusiness';
import { UserProvider } from './stores/LoginStore';
import reducers from './redux/reducers';
import sagas from './redux/sagas';
import OwnABusinessRedux from './routes/BusinessForm/OwnABusinessRedux';
import CreateBusinessRedux from './routes/BusinessForm/CreateBusinessRedux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FavePage from './routes/FavePage';
import ItemPage from './routes/ItemPage';
import Claims from './routes/Claims';
import Suggestions from './routes/Suggestions';
import LoginRoute from './screens/LoginRoute';
// import InquireForm from './screens/InquireForm';
import MyBusinesses from './screens/MyBusinesses';
import AddBusiness from './screens/AddBusiness';
import EditBusiness from './screens/EditBusiness';
import AddProduct from './screens/ProductForm/AddProduct';
import EditProduct from './screens/ProductForm/EditProduct';
import App from 'App';
import AdminArea from './screens/superadmin';
import BetaSignups from './screens/superadmin/BetaSignUps';
import TiersManager from './screens/superadmin/categories';
import SystemPush from './screens/superadmin/systempush';
import LinkNotifications from './screens/Notifications/LinkNotifications';
import BusinessesPageRoute from './screens/Welcome/BusinessPage/BusinessesPageRoute';
import AboutPageRoute from './screens/Welcome/About/AboutPageRoute';
import UpdatesPageRoute from './screens/Welcome/Updates/UpdatesPageRoute';
import AdminRoute from 'routes/AdminRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<RouteElementWrapper />}>
            <Route
                path={HOME_PATH}
                element={
                    <AuthRedirectRouteWrapper>
                        <SignUpRoute />
                    </AuthRedirectRouteWrapper>
                }
            />
            <Route
                path={LOGIN_PATH}
                element={
                    <AuthRedirectRouteWrapper>
                        <LoginRoute />
                    </AuthRedirectRouteWrapper>
                }
            />
            <Route
                path={FORGOT_PASSWORD_PATH}
                element={
                    <AuthRedirectRouteWrapper>
                        <RecoverPasswordRoute />
                    </AuthRedirectRouteWrapper>
                }
            />
            <Route path={BUSINESS_PATH} element={<BusinessesPageRoute />} />
            <Route path={ABOUT_PATH} element={<AboutPageRoute />} />
            <Route path={UPDATES_PATH} element={<UpdatesPageRoute />} />
            {/* <Route path="/Inquire/:type" element={<InquireForm />} /> */}
            <Route path={INQUIRE_PATH} element={<InquireRoute />} />
            <Route path={LINK_NOTIFICATIONS} element={<LinkNotifications />} />
            <Route path={ACCOUNT_PATH} element={<AccountRoute />} />
            <Route path={MY_FAVEPAGE_PATH} element={<MyBusinesses />} />
            <Route path={CREATE_FAVEPAGE} element={<CreateFavePage />} />
            <Route path={ADD_BUSINESS_PATH} element={<AddBusiness />} />
            <Route path={`${ADD_FAVEPAGE_PATH}/:entityType?`} element={<AddFavePage />} />
            <Route path={REFER_A_FRIEND_PATH} element={<ReferAFriendRoute />} />
            <Route path={TERMS_AND_CONDITIONS_PATH} element={<TermsAndConditionsRoute />} />
            <Route path={`${EDIT_BUSINESS_PATH}/:id`} element={<EditBusiness />} />
            <Route path={`${EDIT_FAVEPAGE_PATH}/:id`} element={<EditBusiness />} />
            <Route path="/AddProduct/:businessId" element={<AddProduct />} />
            <Route path="/EditProduct/:productId" element={<EditProduct />} />
            {/* <Route path="investment-opportunities" element={<PageWithNavbar loginRequired element={<Invest />} />} />
<Route path="employment-marketing-opportunities" element={<PageWithNavbar loginRequired element={<Opportunities />} />} /> */}
            <Route
                path="own-a-business/products-search"
                element={<PageWithNavbar loginRequired element={<ProductsSearch />} />}
            />
            <Route
                path="own-a-business/create"
                element={<PageWithNavbar loginRequired element={<CreateBusinessRedux />} />}
            />
            <Route
                path="own-a-business/edit"
                element={<PageWithNavbar loginRequired element={<OwnABusiness isEditing />} />}
            />
            <Route
                path="own-a-business/edit/:business_id"
                element={<PageWithNavbar loginRequired element={<OwnABusinessRedux isEditing />} />}
            />
            <Route
                path="recommend-a-business"
                element={<PageWithNavbar loginRequired element={<RecommendABusiness />} />}
            />
            <Route
                path="my-submissions"
                element={<PageWithNavbar loginRequired element={<MySubmissions />} />}
            />
            <Route
                path="suggestions"
                element={<PageWithNavbar loginRequired element={<Suggestions />} />}
            />
            <Route path="claims" element={<PageWithNavbar adminRequired element={<Claims />} />} />
            <Route path="favepage/:id" element={<FavePage />} />
            <Route path="favepage" element={<FavePage />} />
            <Route path="user/:id" element={<FavePage />} />
            <Route path="user" element={<FavePage />} />
            <Route path="entity/:id/:whoShares" element={<ItemPage />} />
            <Route path="entity/:id" element={<ItemPage />} />
            <Route path="entity" element={<FavePage />} />
            {/* Admin Routes*/}
            <Route
                path={ADMIN_AREA_PATH}
                element={
                    <AdminRoute>
                        <AdminArea />
                    </AdminRoute>
                }
            />
            <Route
                path={BETA_SIGNUPS}
                element={
                    <AdminRoute>
                        <BetaSignups />
                    </AdminRoute>
                }
            />
            <Route
                path={TAG_MANAGER_PATH}
                element={
                    <AdminRoute>
                        <TagManager />
                    </AdminRoute>
                }
            />
            <Route
                path={CATEGORYMANAGER_PATH}
                element={
                    <AdminRoute>
                        <TiersManager />{' '}
                    </AdminRoute>
                }
            />
            <Route
                path={SYSTEM_PUSH}
                element={
                    <AdminRoute>
                        <SystemPush />
                    </AdminRoute>
                }
            />
        </Route>,
    ),
);

const sagaMiddleware = createSagaMiddleware({
    context: {
        router,
    },
});

const store = configureStore({
    reducer: reducers,
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(sagas);

root.render(
    <Provider store={store}>
        <App>
            <UserProvider>
                <RouterProvider router={router} />
            </UserProvider>
        </App>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
