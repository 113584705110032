import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { H3 } from 'baseline-ui/typography';

const Title = styled(H3)`
    padding: 0 ${rem(20)};
    text-align: ${({ isVertical }) => (isVertical ? 'center' : 'left')};
    transform: translateY(-${rem(2)});
    ${({ hasLogo }) =>
        !hasLogo &&
        css`
            padding: 0;
        `}
`;

export default Title;
