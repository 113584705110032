import { rem } from 'polished';
import styled from 'styled-components';

const CreateFavePageContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: calc(
        100vh - ${({ theme }) => rem(theme.header.height + theme.site.page.header.height)}
    );
    padding-bottom: ${rem(160)};
`;

export default CreateFavePageContainer;
