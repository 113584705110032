import PropTypes from 'prop-types';

const authErrorMessage = PropTypes.shape({
    errorType: PropTypes.string,
    errorDescription: PropTypes.string,
});

const authOneMoreStepMessage = PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
});

export default { authErrorMessage, authOneMoreStepMessage };
