import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import Placeholder from './Placeholder';
import Image from './Image';

const ProgressiveImg = ({
    image,
    placeholder,
    imageFluid,
    alt,
    delay,
    className,
    height,
    width,
    fadeIn,
}) => {
    return (
        <ProgressiveImage src={image} placeholder={placeholder} delay={delay}>
            {(src, loading) => {
                if (!placeholder) {
                    return loading ? (
                        <Placeholder imageFluid={imageFluid} height={height} width={width} />
                    ) : (
                        <Image
                            src={src}
                            alt={alt}
                            imageFluid={imageFluid}
                            className={className}
                            fadeIn={fadeIn}
                            height={height}
                            width={width}
                        />
                    );
                }
                return (
                    <Image
                        src={src}
                        alt={alt}
                        isLoading={loading}
                        imageFluid={imageFluid}
                        className={className}
                        fadeIn={fadeIn}
                        height={height}
                        width={width}
                    />
                );
            }}
        </ProgressiveImage>
    );
};

ProgressiveImg.propTypes = {
    image: PropTypes.string,
    placeholder: PropTypes.string,
    alt: PropTypes.string,
    imageFluid: PropTypes.bool,
    delay: PropTypes.number,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    fadeIn: PropTypes.bool,
};

ProgressiveImg.defaultProps = {
    image: null,
    placeholder: null,
    alt: null,
    imageFluid: true,
    delay: null,
    className: null,
    height: undefined,
    width: undefined,
    fadeIn: false,
};

export default ProgressiveImg;
