import {useSelector} from 'react-redux';
import {eventFormRequiredFieldSelector} from 'redux/selectors/eventSelectors';
import addEventUtils from './addEventUtils';

const useAddEventValid = () => {
    const eventFormRequiredFields = useSelector(eventFormRequiredFieldSelector);

    return addEventUtils.addEventHasAllRequiredFields(
        eventFormRequiredFields,
    );
};

export default useAddEventValid;
