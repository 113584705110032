import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DefaultAlert } from 'baseline-ui/alert';
import { Button } from 'baseline-ui/button';
import { OverlayContext } from 'baseline-ui/overlay';
import { Small } from 'baseline-ui/typography';
import { ModalContext } from 'baseline-ui/modal';
import { touch } from 'baseline-ui/layout';
import ModalEditSection from 'components/productsAndServices/modalSection/ModalEditSection';
import {
    allActiveProductsSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import ModalBrowseProducts from 'components/productsAndServices/modalSection/ModalBrowseProducts';
import { isEmpty } from 'lodash';
import ProductsAndServicesItemListTabItemHintContainer from './ProductsAndServicesItemListTabItemHintContainer';
import ProductsAndServicesItemListTabItemDragLabel from './ProductsAndServicesItemListTabItemDragLabel';

const ProductsAndServicesItemListTabItemHint = () => {
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const { showOverlay } = useContext(OverlayContext);
    const { showModal } = useContext(ModalContext);
    const allActiveProducts = useSelector(allActiveProductsSelector);

    return (
        <DefaultAlert inProp appear={false} alertType="primaryMuted">
            <ProductsAndServicesItemListTabItemHintContainer>
                <Button
                    btnType="link"
                    icon="edit"
                    iconSize={12}
                    size="sm"
                    onClick={() => {
                        showOverlay(ModalEditSection);
                    }}
                >
                    <FormattedMessage
                        id="addBusiness.page.sections.productsAndServices.items.alert.editSection.button.label"
                        values={{ sectionName: selectedSectionName }}
                    />
                </Button>
                <ProductsAndServicesItemListTabItemDragLabel>
                    <Small color="muted">
                        <FormattedMessage
                            id={
                                touch
                                    ? 'addBusiness.page.sections.productsAndServices.items.alert.add.hint.label'
                                    : 'addBusiness.page.sections.productsAndServices.items.alert.dragAndDrop.hint.label'
                            }
                        />
                    </Small>

                    <Button
                        btnType="link"
                        icon="folder"
                        iconSize={12}
                        size="sm"
                        onClick={() => showModal(ModalBrowseProducts)}
                        disabled={isEmpty(allActiveProducts)}
                    >
                        <FormattedMessage id="addBusiness.page.sections.productsAndServices.items.alert.browse.button.label" />
                    </Button>
                </ProductsAndServicesItemListTabItemDragLabel>
            </ProductsAndServicesItemListTabItemHintContainer>
        </DefaultAlert>
    );
};

export default ProductsAndServicesItemListTabItemHint;
