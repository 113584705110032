import styled from 'styled-components';
import { rem } from 'polished';
import {media} from "baseline-ui/layout";

const SignUpIntro = styled.div`
    background-color: transparent;
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    margin-top: ${rem(4)};
    min-height: 55vh;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    ${media.xxs`
        padding-left: ${rem(24)};
        padding-right: ${rem(24)};
    `};
     ${media.md`
        width: 50%;
        padding-left: ${rem(16)};
        padding-right: ${rem(16)};
    `};
`;

export default SignUpIntro;
