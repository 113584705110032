import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const formResetSchema = yup.object().shape({
    passwordCode: fieldSchema.passwordCode.required(),
    password: fieldSchema.password.required(),
    confirmPassword: fieldSchema.passwordConfirm.required(),
});

export default formResetSchema;
