import React from 'react';
import PropTypes from 'prop-types';
import { Small } from 'baseline-ui/typography';
import FootnoteContainer from './FootnoteContainer';

const AuthFootnote = ({ footnote }) => {
    if (!footnote) {
        return null;
    }

    return (
        <FootnoteContainer>
            <Small color="muted">{footnote}</Small>
        </FootnoteContainer>
    );
};

AuthFootnote.propTypes = {
    footnote: PropTypes.node,
};

AuthFootnote.defaultProps = {
    footnote: undefined,
};

export default AuthFootnote;
