import { rem } from 'polished';
import styled from 'styled-components';

const CreateFavePageComingSoonContainer = styled.span`
    background-color: ${({ theme }) => theme.typography.colors.success};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    padding: ${rem(4)} ${rem(16)};
    position: absolute;
    right: ${rem(16)};
    top: -${rem(12)};
    z-index: 1;
`;

export default CreateFavePageComingSoonContainer;
