import React from 'react';
import ModalConsumer from './ModalConsumer';

const ModalRoot = () => {
    return (
        <ModalConsumer>
            {({ component: Component, isOpen, modalProps, removeModal, hideModal, showModal }) => {
                const renderComponent = Component ? (
                    <Component
                        {...modalProps}
                        onDismiss={removeModal}
                        isOpen={isOpen}
                        onRequestClose={hideModal}
                        showModal={showModal}
                    />
                ) : null;
                return renderComponent;
            }}
        </ModalConsumer>
    );
};

export default ModalRoot;
