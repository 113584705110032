import React from 'react';
import { Form, Field, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInput } from 'baseline-ui/form';
import { Button } from 'baseline-ui/button';
import { SEARCH_ALL_PRODUCTS_MAX_LENGTH } from 'common/consts';

const ProductsAndServicesFilteredListForm = () => {
    const { resetForm } = useFormikContext();
    const intl = useIntl();

    return (
        <Form noValidate>
            <Field
                component={FormInput}
                label={intl.formatMessage({
                    id: 'addBusiness.page.sections.productsAndServices.form.searchProducts.label',
                })}
                name="filterProducts"
                id="filterProducts"
                type="text"
                maxLength={SEARCH_ALL_PRODUCTS_MAX_LENGTH.toString()}
            />
            <Button
                skin="muted"
                btnType="link"
                icon="x-circle"
                type="button"
                size="sm"
                onClick={() => resetForm()}
            >
                <FormattedMessage id="addBusiness.page.sections.productsAndServices.list.clearSearch.label" />
            </Button>
        </Form>
    );
};

export default ProductsAndServicesFilteredListForm;
