import React, { useContext } from 'react';
import { TransitionItem } from 'baseline-ui/helpers';
import Container from './Container';
import ProcessOverlayContext from '../ProcessOverlayContext';

const ProcessOverlayContainer = () => {
    const { processOverlayOnStage } = useContext(ProcessOverlayContext);
    const { show } = processOverlayOnStage;

    return (
        <TransitionItem inProp={show} appear isFullHeight isFullWidth transitionTimeout={150}>
            <Container />
        </TransitionItem>
    );
};

export default ProcessOverlayContainer;
