import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductTotalFavesContainer = styled.div`
    align-items: center;
    align-self: flex-start;
    display: flex;
    margin-left: ${rem(16)};
`;

export default ModalEditProductTotalFavesContainer;
