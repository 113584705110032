import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import typographyTheme from './typographyTheme';

const paragraphLeadStyles = () => css`
    font-size: ${({ theme }) =>
        rem(
            get(theme, 'typography.base.fontSize', typographyTheme.typography.base.fontSize) * 1.15,
        )};
    font-weight: ${({ theme }) =>
        get(
            theme,
            'typography.paragraph.lead.fontWeight',
            typographyTheme.typography.paragraph.lead.fontWeight,
        )};
`;

export default paragraphLeadStyles;
