import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import h1Styles from '../styles/h1Styles';
import typographyTheme from '../styles/typographyTheme';

const LargeH1 = styled.span`
    ${h1Styles}

    display: block;
    font-size: ${({ theme }) =>
        rem(
            get(
                theme,
                'typography.largeHeadings.fontSize.h1',
                typographyTheme.typography.largeHeadings.fontSize.h1,
            ),
        )};
    font-weight: ${({ theme }) =>
        get(
            theme,
            'typography.largeHeadings.fontWeight.h1',
            typographyTheme.typography.largeHeadings.fontWeight.h1,
        )};
    line-height: ${({ theme }) =>
        get(
            theme,
            'typography.largeHeadings.lineHeight.h1',
            typographyTheme.typography.largeHeadings.lineHeight.h1,
        )};
    padding: ${({ theme }) =>
            rem(
                get(
                    theme,
                    'typography.largeHeadings.paddingV.h1',
                    typographyTheme.typography.largeHeadings.paddingV.h1,
                ),
            )}
        0;
`;

export default LargeH1;
