import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import { useLocation, useNavigate } from 'react-router-dom';

const ModalSaveBusinessConfirmed = ({ onRequestClose, ...props }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <Modal
            title={intl.formatMessage({ id: 'saveBusinessConfirmed.modal.submit.title' })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'saveBusinessConfirmed.modal.submit.footerAction.continue.button.label',
                    }),
                    skin: 'primary',
                    onClick: () => {
                        onRequestClose();
                        /**
                         * Navigate to current location in order to reset the state
                         * This is necessary because we use the location state to handle the process complete feedback
                         */
                        navigate(pathname, {});
                    },
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: 'saveBusinessConfirmed.modal.submit.content.md',
                })}
            />
        </Modal>
    );
};

ModalSaveBusinessConfirmed.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
};

export default ModalSaveBusinessConfirmed;
