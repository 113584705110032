import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import tabsTheme from '../../styles/tabsTheme';

const TabGroup = styled.div`
    background-color: ${({ theme }) =>
        get(theme, 'tabs.tab.backgroundColor', tabsTheme.tabs.tab.backgroundColor)};
    position: relative;
    z-index: 2;

    ${({ tabsPosition }) =>
        tabsPosition === 'top' &&
        css`
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
        `}

    ${({ tabsPosition }) =>
        tabsPosition === 'bottom' &&
        css`
            box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
        `}
   
   ${({ tabsPosition }) =>
        tabsPosition === 'left' &&
        css`
            box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
        `}

    ${({ justified, scrollBtnWidth }) =>
        justified &&
        css`
            margin: 0 -${rem(scrollBtnWidth)};
        `}
`;

export default TabGroup;
