const siteLayoutTheme = {
    layout: {
        aside: {
            width: {
                md: 320,
                lg: 320,
                xl: 320,
            },
        },
    },
};

export default siteLayoutTheme;
