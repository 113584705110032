import styled,{css} from 'styled-components';
import { get } from 'lodash';
import { typographyTheme } from 'baseline-ui/typography';

const Label = styled.span`
    color: ${({skin}) =>
    get(
        skin,
        `typography.colors[${skin}]`,
        typographyTheme.typography.colors[skin],
    )};
    font-family: ${({ theme }) =>
    get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
   ${({underline}) =>
    underline &&
    css`
        text-decoration: underline;
    `}
    ${({fontWeight}) =>
    fontWeight &&
    css`
       font-weight: ${fontWeight};
    `}
`;

export default Label;
