import * as yup from 'yup';
import validationMessages from './validationMessages';

// ------------------------------------------
// locale configuration
// ------------------------------------------

yup.setLocale({
    mixed: {
        required: validationMessages.mixed.required,
        notType: validationMessages.mixed.notType,
    },
    string: {
        min: validationMessages.string.min,
        max: validationMessages.string.max,
        equalTo: validationMessages.string.equalTo,
        matches: validationMessages.string.matches,
        email: validationMessages.email.email,
    },
    number: {
        min: validationMessages.number.min,
        max: validationMessages.number.max,
    },
});

// ------------------------------------------
// validators
// ------------------------------------------

/**
 * Validated checkbox value
 * @param {String} [message] - Optional error message
 */
yup.addMethod(
    yup.boolean,
    'isTrue',
    function validateIsTrue(message = validationMessages.boolean.isTrue) {
        return this.test('isTrue', message, (value) => value === true);
    },
);

// ------------------------------------------
// transformers
// ------------------------------------------
/**
 * Converts empty strings to null to disable validators working only on existing values.
 */
yup.addMethod(yup.string, 'emptyToNull', function validateIsTrue() {
    return this.transform(function emptyToNull(value) {
        return value === '' ? undefined : value;
    });
});

yup.addMethod(yup.string, 'integer', function validateInteger() {
    return this.matches(/^\d+$/, 'The field should have digits only');
});
