import styled, { css } from 'styled-components';
import spinKeyframes from '../../styles/spinKeyframes';

const IconContainer = styled.span`
    display: flex;

    ${({ spin }) =>
        spin &&
        css`
            animation: ${spinKeyframes} 1.5s infinite linear;
        `}
        
    ${({ iconRotate }) =>
    iconRotate &&
        css`
            transform: rotateX(180deg);
        `}
`;

export default IconContainer;
