import React from 'react';
import PropTypes from 'prop-types';
import { TierRootComponent } from './components';
import { TierItem, TierNoItems } from './tieritem';
import { WithTiers, getValidChildTypes } from './helpers';
import { TierAddButtons, TierControls } from './tiercontrols';

export function TierRoot({ tiers, withTiers, showHidden }) {
    if (tiers?.expanded !== true) {
        return null;
    }
    const hasTiers = !!tiers?.resources?.length;
    return (
        <TierRootComponent>
            {hasTiers ? (
                <>
                    {tiers.resources.map((tier, idx) => (
                        <TierItem
                            key={tier._id ?? idx}
                            tier={tier}
                            withTiers={withTiers}
                            showHidden={showHidden}
                        />
                    ))}
                    <TierAddButtons
                        tier={tiers}
                        validChildTypes={getValidChildTypes(tiers.type)}
                        onAdd={() => {
                            withTiers.addGroup({});
                        }}
                    />
                </>
            ) : (
                <TierNoItems />
            )}
        </TierRootComponent>
    );
}
TierRoot.propTypes = {
    tiers: PropTypes.shape({
        resources: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
            }),
        ),
        expanded: PropTypes.bool,
    }),
    withTiers: PropTypes.instanceOf(WithTiers).isRequired,
};
TierRoot.defaultProps = {
    tiers: undefined,
};
