import styled from 'styled-components';

export const NotificationCount = styled.span`
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 10px;
    position: absolute;
    top: 1px;
    right: 15px;
    
    @media (max-width: 768px) {
        right: 15px;
        top: -10px;
        font-size: 8px;
    }
`;