import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import Tooltip from './Tooltip';
import TooltipSelect from './TooltipSelect';
import TooltipContainer from './TooltipContainer';
import TooltipInfoButton from './tooltipInfo/TooltipInfoButton';
import tooltipTheme from '../styles/tooltipTheme';

const TooltipInfo = ({ intl, triggerAriaLabel, children, ...props }) => {
    const theme = useContext(ThemeContext);
    const icon = get(theme, 'tooltip.info.icon', tooltipTheme.tooltip.info.icon);
    const iconSize = get(theme, 'tooltip.info.size', tooltipTheme.tooltip.info.size);

    return (
        <Tooltip role="tooltip" {...props}>
            <TooltipSelect>
                <TooltipInfoButton
                    type="button"
                    aria-label={
                        triggerAriaLabel || intl.formatMessage({ id: 'tooltip.info.label' })
                    }
                >
                    <GaIcon icon={icon} color="currentColor" size={iconSize} />
                </TooltipInfoButton>
            </TooltipSelect>
            <TooltipContainer>{children}</TooltipContainer>
        </Tooltip>
    );
};

TooltipInfo.propTypes = {
    intl: PropTypes.shape().isRequired,
    triggerAriaLabel: PropTypes.string,
    children: PropTypes.node.isRequired,
};

TooltipInfo.defaultProps = {
    triggerAriaLabel: null,
};

export default injectIntl(TooltipInfo);
