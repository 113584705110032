import styled, { css } from 'styled-components';
import { get } from 'lodash';
import Slider from 'react-slick';
import { rem } from 'polished';
import { cssHelpers } from 'baseline-ui/helpers';
import carouselTheme from '../styles/carouselTheme';

const DefaultSlider = styled(Slider)`
    display: block;
    position: relative;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    /* slick-list */

    .slick-list {
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        transform: translate3d(0, 0, 0);

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    /* slick-track */

    .slick-track {
        ${cssHelpers.clearfix}
        display: block;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        transform: translate3d(0, 0, 0);
        top: 0;
    }

    &.slick-loading {
        .slick-track {
            visibility: hidden;
        }
    }

    /* slide */

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;

        ${(props) =>
            props.rtl &&
            css`
                float: right;
            `}

        img {
            display: block;
        }

        &.slick-loading {
            img {
                display: none;
            }
        }

        &.dragging {
            img {
                pointer-events: none;
            }
        }
    }

    /* slide based on slick-slider states */

    &.slick-initialized {
        .slick-slide {
            display: block;
        }
    }

    &.slick-loading {
        .slick-slide {
            visibility: hidden;
        }
    }

    &.slick-vertical {
        .slick-slide {
            display: block;
            height: auto;
        }
    }

    ${(props) =>
        props.eqHeight &&
        css`
            .slick-track {
                display: flex;
            }
            .slick-slide {
                display: flex;
                height: auto;
            }

            &.slick-initialized {
                .slick-slide {
                    display: flex;
                    /* [> div] slick carousel renders and extra div that wraps the slide children */
                    > div {
                        display: flex;
                        flex: 1 1 100%;
                        flex-flow: wrap column;
                    }
                }
            }
        `}

    ${({ theme, dots, dotsType, dotsPosition }) =>
        dots &&
        dotsPosition.includes('outside') &&
        css`
            margin-bottom: ${rem(
                get(
                    theme,
                    `carousel.dots.size[${dotsType}]`,
                    carouselTheme.carousel.dots.size[dotsType],
                ) * 2.8,
            )}}
        `}

    ${(props) =>
        props.arrows &&
        props.arrowType === 'default' &&
        css`
            margin-left: ${rem(
                get(
                    props,
                    `theme.carousel.arrows[${props.arrowSize}].size`,
                    carouselTheme.carousel.arrows[props.arrowSize].size,
                ),
            )};

            margin-right: ${rem(
                get(
                    props,
                    `theme.carousel.arrows[${props.arrowSize}].size`,
                    carouselTheme.carousel.arrows[props.arrowSize].size,
                ),
            )};

            ${props.vertical &&
            css`
                margin-bottom: ${rem(
                    get(
                        props,
                        `theme.carousel.arrows[${props.arrowSize}].size`,
                        carouselTheme.carousel.arrows[props.arrowSize].size,
                    ),
                )};
                margin-top: ${rem(
                    get(
                        props,
                        `theme.carousel.arrows[${props.arrowSize}].size`,
                        carouselTheme.carousel.arrows[props.arrowSize].size,
                    ),
                )};
                margin-left: 0;
                margin-right: 0;
            `}
        `}
`;

export default DefaultSlider;
