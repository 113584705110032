import React, { Fragment, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import Title from './Title';

const TooltipContainer = ({ title, showCloseButton, children, hideTooltip, isFullWidth }) => {
    const tooltipContent = Children.toArray(children);
    return (
        <Body isFullWidth={isFullWidth} className="ignore-react-onclickoutside">
            {title && <Title showCloseButton={showCloseButton}>{title}</Title>}
            {tooltipContent.map((content, index) => (
                <Fragment key={index}>
                    {cloneElement(content, {
                        hideTooltip,
                    })}
                </Fragment>
            ))}
        </Body>
    );
};

TooltipContainer.propTypes = {
    title: PropTypes.string,
    showCloseButton: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    children: PropTypes.node.isRequired,
    hideTooltip: PropTypes.func,
};

TooltipContainer.defaultProps = {
    title: null,
    showCloseButton: false,
    isFullWidth: false,
    hideTooltip: () => {},
};

export default TooltipContainer;
