import { rem } from 'polished';
import styled, { css } from 'styled-components';

const AdminAside = styled.aside`
    ${({ theme, hasBanner }) =>
        hasBanner &&
        css`
            padding-top: ${rem(theme.site.page.header.height)};
        `}
`;

export default AdminAside;
