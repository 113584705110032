import { TrendingUp } from "@mui/icons-material";
import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const ReduxLoaderComponent = ({ processName }) => {
  const processStep = useSelector(
    ({ ProcessReducer }) => ProcessReducer[processName],
  );
  if (processStep === "START") {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return null;
};

export default ReduxLoaderComponent;
