import { put, select, takeEvery } from "redux-saga/effects";
import genericSagaHandler from "./CommonSaga";
import {
  GET_SUGGESTIONS_PROCESS,
  GET_SUGGESTIONS_START,
  GET_SUGGESTIONS_SUCCESS,
  SET_SUGGESTIONS_NEXT_PAGE,
  SET_SUGGESTIONS_PREV_PAGE,
  SET_SUGGESTIONS_SORT_DIRECTION_FILTER,
  SET_SUGGESTIONS_SORT_FIELD_FILTER,
  SET_SUGGESTIONS_STATE_FILTER,
} from "../actions/SuggestActions";
import { GetSuggestions } from "../../api";

function* getSuggestionsStart({ payload }) {
  yield genericSagaHandler(GET_SUGGESTIONS_PROCESS, function* () {
    const { page, stateFilter, sortField, sortDirection } = yield select(
      ({ SuggestReducer }) => SuggestReducer,
    );
    const data = yield GetSuggestions({
      page,
      stateFilter,
      sortField,
      sortDirection,
    });

    yield put({
      type: GET_SUGGESTIONS_SUCCESS,
      payload: data,
    });
  });
}

export default function* SuggestionSagas() {
  yield takeEvery(GET_SUGGESTIONS_START, getSuggestionsStart);
  yield takeEvery(SET_SUGGESTIONS_NEXT_PAGE, getSuggestionsStart);
  yield takeEvery(SET_SUGGESTIONS_PREV_PAGE, getSuggestionsStart);
  yield takeEvery(SET_SUGGESTIONS_STATE_FILTER, getSuggestionsStart);
  yield takeEvery(SET_SUGGESTIONS_SORT_FIELD_FILTER, getSuggestionsStart);
  yield takeEvery(SET_SUGGESTIONS_SORT_DIRECTION_FILTER, getSuggestionsStart);
}
