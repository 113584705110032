import styled from 'styled-components';
import {rem} from 'polished';

const FlexWrapper = styled.div`
    position: relative;
    padding: ${rem(32)} ${rem(32)};
    display: flex;
    flex-direction: ${({row}) =>
    row
        ? 'row'
        : 'column'
    };
    gap:${({gap}) =>
    gap
        ? rem(gap)
        : rem(40)
    };
`;

export default FlexWrapper;