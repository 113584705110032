import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { FormInput, FieldContainer } from 'baseline-ui/form';
import { H2, P } from 'baseline-ui/typography';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import AuthFormContainer from './AuthFormContainer';
import AuthFormSignError from './AuthFormSignError';
import AuthHeadingContainer from './AuthHeadingContainer';
import OneMoreStepMessage from './OneMoreStepMessage';
import formRecoverPasswordSchema from '../schema/formRecoverPasswordSchema';
import authPropTypes from '../prop-types/authPropTypes';

const FormRecoverPassword = ({
    onSubmitRecoverPassword,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    isRecoverValid,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    oneMoreStep,
    oneMoreStepMessage,
    appear,
}) => {
    const intl = useIntl();

    const submitRecoverPassword = (values) => {
        onSubmitRecoverPassword(values);
    };

    const initialValues = {
        emailAddress: '',
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage
                    id={
                        isRecoverValid
                            ? 'auth.recoverPassword.heading.recoverValid.title'
                            : 'auth.recoverPassword.heading.recoverInvalid.title'
                    }
                />
            </H2>
            <P>
                <FormattedMessage
                    id={
                        isRecoverValid
                            ? 'auth.recoverPassword.heading.recoverValid.content'
                            : 'auth.recoverPassword.heading.recoverInvalid.content'
                    }
                />
            </P>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <OneMoreStepMessage oneMoreStep={oneMoreStep} oneMoreStepMessage={oneMoreStepMessage} />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formRecoverPasswordSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        submitRecoverPassword(values);
                    }}
                >
                    {() => {
                        return (
                            <Form noValidate>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.emailAddress.label',
                                        })}
                                        name="emailAddress"
                                        id="emailAddress"
                                        type="email"
                                        disabled={isLoading}
                                        required
                                        autoComplete="off"
                                    />
                                </FieldContainer>
                                <Button
                                    skin={buttonSkin}
                                    btnType={buttonBtnType}
                                    block
                                    type="submit"
                                    loading={isLoading}
                                    loadingMessage={intl.formatMessage({
                                        id: 'auth.recoverPassword.sendRecoveryEmail.loading.label',
                                    })}
                                >
                                    <FormattedMessage id="auth.recoverPassword.sendRecoveryEmail.label" />
                                </Button>
                                <AuthFormSignError errorMessage={errorMessage} />
                            </Form>
                        );
                    }}
                </Formik>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormRecoverPassword.propTypes = {
    onSubmitRecoverPassword: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    showHeading: PropTypes.bool,
    isRecoverValid: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    oneMoreStep: PropTypes.bool,
    oneMoreStepMessage: authPropTypes.authOneMoreStepMessage,
    appear: PropTypes.bool,
};

FormRecoverPassword.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    showHeading: true,
    isRecoverValid: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    oneMoreStep: false,
    oneMoreStepMessage: undefined,
    appear: true,
};

export default FormRecoverPassword;
