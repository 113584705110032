import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesListItemSectionTagContainer = styled.div`
    background-color: ${({ theme }) => theme.base.colors.primaryMuted};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    margin-bottom: ${rem(4)};
    margin-right: ${rem(4)};
    padding: 0 ${rem(8)};
    whiteSpace: 'nowrap';
`;

export default ProductsAndServicesListItemSectionTagContainer;
