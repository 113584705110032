import PropTypes from 'prop-types';

const colSizeProps = PropTypes.shape({
    xxs: PropTypes.number,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
});

const themeProps = PropTypes.shape({
    layout: PropTypes.shape({
        media: {
            xs: PropTypes.string,
            sm: PropTypes.string,
            md: PropTypes.string,
            lg: PropTypes.string,
        },
        grid: {
            columns: PropTypes.number,
            gutter: PropTypes.number,
        },
        maxSiteWidth: PropTypes.string,
        header: {
            mainHeight: PropTypes.string,
            mobileMainHeight: PropTypes.string,
            accountHeight: PropTypes.string,
        },
        page: {
            backgroundColor: PropTypes.string,
        },
    }),
});

export default { themeProps, colSizeProps };
