import React, { useContext } from 'react';
import { P } from 'baseline-ui/typography';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'baseline-ui/layout';
import {
    addedUsersBusinessAccessRightsSelector,
    allUsersBusinessAccessRightsSelector,
} from 'redux/selectors/businessAccessRightsSelectors';
import {
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
} from 'redux/actions/BusinessAccessRightsActions';
import { businessIdSelector } from 'redux/selectors/businessFormSelectors';
import useProcessLoader from 'utils/useProcessLoader';
import useProcessComplete from 'utils/useProcessComplete';
import { ModalContext } from 'baseline-ui/modal';
import AccessRightsUserCreator from '../AccessRightsUserCreator';
import AccessRightsUsersListContainer from './AccessRightsUsersListContainer';
import AccessRightsUsersListItem from './AccessRightsUsersListItem';
import ModalAccessRightsRemoveUser from './ModalAccessRightsRemoveUser';

const AccessRightsUsersList = () => {
    const dispatch = useDispatch();
    const users = useSelector(addedUsersBusinessAccessRightsSelector);
    const allUsers = useSelector(allUsersBusinessAccessRightsSelector);

    const businessId = useSelector(businessIdSelector);
    const { showModal } = useContext(ModalContext);

    const handleOnRemoveLink = (id) => {
        dispatch({
            type: REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
            payload: {
                entityid: businessId,
                userId: id,
            },
        });
    };

    useProcessLoader({
        processNames: [REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withOverlay: true,
    });

    useProcessComplete({
        processNames: [REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withFeedback: true,
    });

    if (isEmpty(allUsers)) {
        return null;
    }

    return (
        <AccessRightsUsersListContainer>
            <Row justifyContent="space-between">
                <Col>
                    <P lead>
                        <FormattedMessage id="addBusiness.page.sections.accessRights.list.title" />
                    </P>
                </Col>
            </Row>
            <AccessRightsUserCreator />
            {users.map((user, index) => {
                const { email, id } = user;
                return (
                    <AccessRightsUsersListItem
                        isFirst={index === 0}
                        key={`${email}-${index}`}
                        userEmailAddress={email}
                        onClick={() =>
                            showModal(ModalAccessRightsRemoveUser, {
                                emailAddress: email,
                                confirmDeleteCallback: () => handleOnRemoveLink(id),
                            })
                        }
                    />
                );
            })}
        </AccessRightsUsersListContainer>
    );
};

export default AccessRightsUsersList;
