import { all } from '@redux-saga/core/effects';
import BusinessAccessRightsSagas from './BusinessAccessRightsSagas';
import BussinesesSagas from './BussinessesSagas';
import CategoriesSagas from './CategoriesSagas';
import ClaimsSagas from './ClaimsSagas';
import EditProductSagas from './EditProductSagas';
import ItemSagas from './ItemSagas';
import OwnABusinessSagas from './OwnABusinessSagas';
import PushSagas from './PushSagas';
import SectionSagas from './SectionSagas';
import SupportSagas from './SupportSagas';
import SuggestionSagas from './SuggestionSagas';
import AuthenticationSagas from './AuthenticationSagas';
import BusinessFormSagas from './BusinessFormSagas';
import BetaSignupSagas from './BetaSignupSagas';
import ProfileSagas from './ProfileSagas';
import ResetPasswordSagas from './ResetPasswordSagas';
import InquireSagas from './InquireSagas';
import ReferFriendSagas from './ReferFriendSagas';
import EntityPickerSaga from './EntityPickerSaga';
import LinkNotificationSagas from './LinkNotificationSagas';
import EventSagas from './EventSagas';
import EntityLinkToEntitySagas from './EntityLinkToEntitySagas';

export default function* saga() {
    yield all([
        BussinesesSagas(),
        OwnABusinessSagas(),
        SupportSagas(),
        PushSagas(),
        SectionSagas(),
        EditProductSagas(),
        CategoriesSagas(),
        ItemSagas(),
        BusinessAccessRightsSagas(),
        ClaimsSagas(),
        SuggestionSagas(),
        AuthenticationSagas(),
        BusinessFormSagas(),
        BetaSignupSagas(),
        ProfileSagas(),
        ResetPasswordSagas(),
        InquireSagas(),
        ReferFriendSagas(),
        EntityPickerSaga(),
        LinkNotificationSagas(),
        EventSagas(),
        EntityLinkToEntitySagas()
    ]);
}
