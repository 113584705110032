import React from 'react';
import PropTypes from 'prop-types';
import { H1 } from 'baseline-ui/typography';
import { LOGO_ONLY_EMPTY } from 'images';
import { ContentRenderer, Image } from 'baseline-ui/helpers';
import WelcomeMainHeaderContainer from './WelcomeMainHeaderContainer';
import WelcomeMainHeaderLogoContainer from './WelcomeMainHeaderLogoContainer';

const WelcomeMainHeader = ({ title, content }) => {
    return (
        <WelcomeMainHeaderContainer>
            <WelcomeMainHeaderLogoContainer>
                <Image src={LOGO_ONLY_EMPTY} width={102} height={82} imageFluid={false} />
            </WelcomeMainHeaderLogoContainer>
            {title && <H1 color="secondary">{title}</H1>}
            <ContentRenderer color="secondary" source={content} />
        </WelcomeMainHeaderContainer>
    );
};

WelcomeMainHeader.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
};

WelcomeMainHeader.defaultProps = {
    content: undefined,
    title: undefined,
};

export default WelcomeMainHeader;
