import * as yup from 'yup';
import { EDIT_SECTION_NAME_MAX_LENGTH } from 'common/consts';

const itemsEditSectionSchema = (sections) =>
    yup.object().shape({
        sectionName: yup
            .string()
            .min(1)
            .max(EDIT_SECTION_NAME_MAX_LENGTH)
            .test({
                name: 'alreadyExists',
                test: (value, context) => {
                    const { createError } = context;

                    if (!sections.find((section) => section.name === value)) {
                        return true;
                    }

                    return createError({ message: 'error.items.section.name.alreadyExists.label' });
                },
            })
            .required(),
    });

// eslint-disable-next-line import/prefer-default-export
export { itemsEditSectionSchema };
