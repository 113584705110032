import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getIn } from 'formik';
import { SrLabel } from 'baseline-ui/helpers';
import FormInputLabel from './input/FormInputLabel';
import Container from './Container';
import FormInputFeedback from './feedback/FormInputFeedback';
import InputDay from './dob/InputDay';
import InputMonth from './dob/InputMonth';
import InputYear from './dob/InputYear';
import DopInputContainer from './dob/DobInputContainer';
import DobDivider from './dob/DobDivider';
import formPropTypes from '../prop-types/formPropTypes';

class FormDateOfBirth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            filled: false,
        };
        this.inputMonthRef = createRef(null);
        this.inputDayRef = createRef(null);
        this.inputYearRef = createRef(null);
    }

    componentDidMount() {
        if (this.hasValue()) {
            this.setState({ filled: true });
        }
    }

    hasValue = () => {
        const { field, form } = this.props;
        const valueDay = getIn(form.values, `${field.name}Day`);
        const valueMonth = getIn(form.values, `${field.name}Month`);
        const valueYear = getIn(form.values, `${field.name}Year`);
        return !!(valueDay || valueMonth || valueYear);
    };

    handleFocus = () => {
        const { readOnly, field, form } = this.props;

        if (!readOnly) {
            const getMonthValue = getIn(form.values, `${field.name}Month`);
            const getDayValue = getIn(form.values, `${field.name}Day`);
            const getYearValue = getIn(form.values, `${field.name}Year`);

            /** With fields empty, force focus on the month field */
            if (!getMonthValue && !getDayValue && !getYearValue) {
                this.inputMonthRef.current.focus();
            }

            this.setState({ focused: true });
        }
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
        this.setState({ filled: this.hasValue() });
    };

    handleChange = (event) => {
        const { field } = this.props;
        field.onChange(event);
        this.setState({ filled: this.hasValue() });
    };

    handleOnKeyUp = (event) => {
        const { field, form } = this.props;

        const valueDay = getIn(form.values, `${field.name}Day`);
        const valueMonth = getIn(form.values, `${field.name}Month`);
        const valueYear = getIn(form.values, `${field.name}Year`);

        const dobList = [
            { name: `${field.name}Month`, ref: this.inputMonthRef, value: valueMonth },
            { name: `${field.name}Day`, ref: this.inputDayRef, value: valueDay },
            { name: `${field.name}Year`, ref: this.inputYearRef, value: valueYear },
        ];

        const currentIndex = dobList.findIndex(
            (dobListItem) => dobListItem.name === event.target.name,
        );

        const nextFieldRef = dobList[currentIndex + 1]?.ref;

        if (nextFieldRef && Number(event.key)) {
            if (dobList[currentIndex].value.length === event.target.maxLength) {
                nextFieldRef.current.focus();
            }
        }
    };

    getError = () => {
        const { field, form } = this.props;
        const errorDay = getIn(form.errors, `${field.name}Day`);
        const errorMonth = getIn(form.errors, `${field.name}Month`);
        const errorYear = getIn(form.errors, `${field.name}Year`);
        const touchDay = getIn(form.touched, `${field.name}Day`);
        const touchMonth = getIn(form.touched, `${field.name}Month`);
        const touchYear = getIn(form.touched, `${field.name}Year`);
        const isSubmitted = form.submitCount > 0;

        if (errorMonth && (touchMonth || isSubmitted)) {
            return errorMonth;
        }
        if (errorDay && (touchDay || isSubmitted)) {
            return errorDay;
        }
        if (errorYear && (touchYear || isSubmitted)) {
            return errorYear;
        }
        return false;
    };

    render() {
        const { id, disabled, readOnly, field, form, required, label, skin, showIsRequired } =
            this.props;
        const { focused, filled, selected } = this.state;
        const error = this.getError();
        const valueDay = getIn(form.values, `${field.name}Day`);
        const valueMonth = getIn(form.values, `${field.name}Month`);
        const valueYear = getIn(form.values, `${field.name}Year`);
        const showPlaceholder = !!(focused || (!focused && filled));
        const placeholderDay = showPlaceholder && !valueDay ? 'DD' : null;
        const placeholderMonth = showPlaceholder && !valueMonth ? 'MM' : null;
        const placeholderYear = showPlaceholder && !valueYear ? 'YYYY' : null;

        return (
            <Container>
                <DopInputContainer
                    active={focused}
                    disabledValue={disabled}
                    readOnlyValue={readOnly}
                    skin={skin}
                >
                    <SrLabel htmlFor={`${id}-month`}>
                        <FormattedMessage id="dob.numericMonthOfBirth" />
                    </SrLabel>
                    <InputMonth
                        id={`${id}-month`}
                        name={`${field.name}Month`}
                        type="tel"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyUp={this.handleOnKeyUp}
                        onChange={this.handleChange}
                        value={valueMonth}
                        placeholder={placeholderMonth}
                        maxLength="2"
                        disabled={disabled}
                        readOnly={readOnly}
                        skin={skin}
                        ref={this.inputMonthRef}
                    />
                    {showPlaceholder && <DobDivider disabledValue={disabled} skin={skin} />}
                    <SrLabel htmlFor={`${id}-day`}>
                        <FormattedMessage id="dob.numericDayOfBirth" />
                    </SrLabel>
                    <InputDay
                        id={`${id}-day`}
                        name={`${field.name}Day`}
                        type="tel"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyUp={this.handleOnKeyUp}
                        onChange={this.handleChange}
                        value={valueDay}
                        placeholder={placeholderDay}
                        maxLength="2"
                        disabled={disabled}
                        readOnly={readOnly}
                        skin={skin}
                        ref={this.inputDayRef}
                    />
                    {showPlaceholder && <DobDivider disabledValue={disabled} skin={skin} />}
                    <SrLabel htmlFor={`${id}-year`}>
                        <FormattedMessage id="dob.yearOfBirth" />
                    </SrLabel>
                    <InputYear
                        id={`${id}-year`}
                        name={`${field.name}Year`}
                        type="tel"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyUp={this.handleOnKeyUp}
                        onChange={this.handleChange}
                        value={valueYear}
                        placeholder={placeholderYear}
                        maxLength="4"
                        disabled={disabled}
                        readOnly={readOnly}
                        skin={skin}
                        ref={this.inputYearRef}
                    />
                </DopInputContainer>
                <FormInputLabel
                    requiredValue={required}
                    showIsRequired={showIsRequired}
                    focused={focused}
                    filled={filled}
                    selected={selected}
                    disabledValue={disabled}
                    aria-hidden="true"
                    skin={skin}
                    error={error}
                >
                    {label}
                </FormInputLabel>

                {error && (
                    <FormInputFeedback feedbackType="error" id={id} message={error} skin={skin} />
                )}
            </Container>
        );
    }
}

FormDateOfBirth.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    required: PropTypes.bool,
    showIsRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    skin: formPropTypes.formInputSkin,
};

FormDateOfBirth.defaultProps = {
    required: false,
    showIsRequired: true,
    disabled: false,
    readOnly: false,
    skin: 'dark',
};

export default FormDateOfBirth;
