import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_PRODUCT_TO_EDIT_PROPERTY_START } from "../../redux/actions/EditProductActions";

const ProductRequiredInformationForm = () => {
  const dispatch = useDispatch();
  const { productToEdit = {}, originalProduct } = useSelector(
    ({ EditProductReducer }) => EditProductReducer,
  );

  return (
    <Grid item container xs={12} columnSpacing={1} rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4" color={"primary"}>
          Required Information
        </Typography>
        <Typography variant="body2">
          Please provide basic information about your product/service
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={"Product name"}
          value={productToEdit.name}
          fullWidth
          onChange={(e) => {
            dispatch({
              type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
              payload: {
                name: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {" "}
        <TextField
          label={"Price"}
          value={productToEdit.price}
          fullWidth
          onChange={(e) => {
            dispatch({
              type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
              payload: {
                price: e.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={"Product description"}
          multiline
          rows={10}
          value={productToEdit.description}
          onChange={(e) => {
            dispatch({
              type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
              payload: {
                description: e.target.value,
              },
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductRequiredInformationForm;
