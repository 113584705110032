import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import AdminBusinessAdvertisingAddCityField from './AdminBusinessAdvertisingAddCityField';

const AdminBusinessAdvertisingCategoryAddCity = ({ id, index }) => {
    return (
        <FieldArray
            name={`categoriesAndFilters.${index}.categoryCityList`}
            render={(arrayHelpers) => (
                <AdminBusinessAdvertisingAddCityField
                    id={id}
                    arrayHelpers={arrayHelpers}
                    index={index}
                />
            )}
        />
    );
};

AdminBusinessAdvertisingCategoryAddCity.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default AdminBusinessAdvertisingCategoryAddCity;
