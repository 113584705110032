import { TextField } from "@mui/material";
import { FastField } from "formik";
import React from "react";
import ReactInputMask from "react-input-mask";

const FastFieldTextInput = ({
  name,
  multiline,
  label,
  variant,
  rows,
  type,
  fullWidth,
}) => {
  return (
    <FastField name={name}>
      {({ field, meta }) => {
        const { touched, value, error } = meta;
        if (type === "phone") {
          return (
            <div style={{ width: "100%", marginTop: -22 }}>
              <span
                style={{
                  position: "relative",
                  top: 10,
                  zIndex: 1,
                  left: 10,
                  backgroundColor: "white",
                  paddingLeft: 5,
                  paddingRight: 5,
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.6)",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 400,
                  lineHeight: "1.4375em",
                  letterSpacing: "0.00938em",
                }}
              >
                {label}
              </span>
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="Phone number"
                maskChar="_"
                {...field}
                label={label}
                rows={rows}
                variant={variant}
                multiline={multiline}
                style={{
                  borderColor: touched && error ? "#d32f2f" : "rgba(0,0,0,0.2)",
                  borderWidth: 1,
                  borderRadius: 4,
                  width: "100%",
                  height: 56,
                  fontSize: 16,
                  fontFamily: "Roboto",
                  paddingLeft: 12,
                }}
              />
              {touched && error && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    marginLeft: 14,
                    fontFamily: "Roboto",
                  }}
                >
                  {error}
                </span>
              )}
            </div>
          );
        }
        return (
          <TextField
            {...field}
            label={label}
            rows={rows}
            touched={touched ? value : undefined}
            // error={touched && !!error}
            // helperText={touched && error ? error : ""}
            variant={variant}
            multiline={multiline}
            fullWidth={fullWidth}
          />
        );
      }}
    </FastField>
  );
};

export default FastFieldTextInput;
