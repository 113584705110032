import React, { useEffect } from "react";
import "./App.css";
import "./PageWithNavbar.css";
import { useUserStore } from "./stores/LoginStore";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import logo from "./images/logo.png";
import {
  AppBar,
  Button,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

function PageWithNavbar(props) {
  const [user] = useUserStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.loginRequired && !user?.email && user?.loading === false) {
      return navigate("/", { replace: true, state: { needLogin: true } });
    }
    if (props.adminRequired && !user?.is_admin && user?.loading === false) {
      return navigate("/", { replace: true, state: { needAdmin: true } });
    }
  }, [user, navigate, props.loginRequired, props.adminRequired]);
  return (
    <Box
      sx={{ display: "flex", height: "100%", marginBottom: 10, flexGrow: 1 }}
    >
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar component="nav" sx={{ backgroundColor: "#482655" }}>
          <Toolbar sx={{ height: 150 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <img
                width="605"
                height="114"
                src={logo}
                className="custom-logo"
                alt="FavePage"
                sizes="(max-width: 605px) 100vw, 605px"
              />
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button sx={{ color: "#fff" }} onClick={() => navigate("/")}>
                Home
              </Button>
              <Button
                sx={{ color: "#fff" }}
                onClick={() => navigate("/my-submissions")}
              >
                My Businesses
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav"></Box>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar sx={{ height: 150 }} />
          <Grid xs={12} item>
            {props.element}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
  return (
    <div className="page-wrap">
      <header id="masthead" className="site-header">
        <div className="site-branding-container container">
          <div className="flex wrap row space-between">
            <div className="hleft logo">
              <a href="/" className="custom-logo-link" rel="home">
                <img
                  width="605"
                  height="114"
                  src={logo}
                  className="custom-logo"
                  alt="FavePage"
                  sizes="(max-width: 605px) 100vw, 605px"
                />
              </a>
            </div>
            <div className="hright">
              <div className="navigation-top">
                <div className="container-fluid wrap">
                  <nav id="site-navigation" className="main-navigation">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/my-submissions">My Businesses</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {props.element}
      <div className="footer"></div>
    </div>
  );
}

export default PageWithNavbar;
