import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const formInquireSchema = yup.object().shape({
    firstName: fieldSchema.firstName.required(),
    lastName: fieldSchema.lastName.required(),
    businessName: yup.string().emptyToNull().required(),
    emailAddress: fieldSchema.email.required(),
    phoneNumber: fieldSchema.phoneNumber.required(),
    inquire: yup.string().emptyToNull().min(1).max(2000),
    inquireTypeOptions: yup.string().emptyToNull().required(),
});

export default formInquireSchema;
