import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import { SrLabel } from 'baseline-ui/helpers';
import { GaIcon } from 'baseline-ui/icon';
import carouselPropTypes from '../prop-types/carouselPropTypes';
import Arrow from './Arrow';
import carouselTheme from '../styles/carouselTheme';
import carouselDarkTheme from '../styles/carouselDarkTheme';

const ArrowIcon = ({
    arrowSize,
    arrowType,
    arrowCustomColor,
    slide,
    rtl,
    label,
    icon,
    vertical,
    theme,
}) => {
    const { themeMode } = useContext(ThemeModeContext);
    let iconValue;

    const iconHorizontalPrev = get(
        theme,
        'carousel.arrows.horizontal.iconPrev',
        carouselTheme.carousel.arrows.horizontal.iconPrev,
    );

    const iconVerticalPrev = get(
        theme,
        'carousel.arrows.vertical.iconPrev',
        carouselTheme.carousel.arrows.vertical.iconPrev,
    );

    const iconHorizontalNext = get(
        theme,
        'carousel.arrows.horizontal.iconNext',
        carouselTheme.carousel.arrows.horizontal.iconNext,
    );

    const iconVerticalNext = get(
        theme,
        'carousel.arrows.vertical.iconNext',
        carouselTheme.carousel.arrows.vertical.iconNext,
    );

    const arrowSizeValue = get(
        theme,
        `carousel.arrows[${arrowSize}].iconSize`,
        carouselTheme.carousel.arrows[arrowSize].iconSize,
    );

    const arrowColor =
        themeMode === 'darkMode'
            ? get(
                  theme,
                  `carousel.arrows.color[${arrowType}]`,
                  carouselDarkTheme.carousel.arrows.color[arrowType],
              )
            : get(
                  theme,
                  `carousel.arrows.color[${arrowType}]`,
                  carouselTheme.carousel.arrows.color[arrowType],
              );

    const renderArrowColor = arrowCustomColor || arrowColor;

    const renderIconPrev = vertical ? iconVerticalPrev : iconHorizontalPrev;
    const renderIconNext = vertical ? iconVerticalNext : iconHorizontalNext;

    if (slide === 'prev') {
        if (rtl) {
            iconValue = iconHorizontalNext;
        }
        iconValue = renderIconPrev;
    }

    if (slide === 'next') {
        if (rtl) {
            iconValue = iconHorizontalPrev;
        }
        iconValue = renderIconNext;
    }

    return (
        <>
            {icon && (
                <Arrow
                    vertical={vertical}
                    arrowSize={arrowSize}
                    arrowType={arrowType}
                    slide={slide}
                    rtl={rtl}
                >
                    <GaIcon icon={iconValue} size={arrowSizeValue} color={renderArrowColor} />
                </Arrow>
            )}
            {label && <SrLabel as="span">{label}</SrLabel>}
        </>
    );
};

ArrowIcon.propTypes = {
    /** Show arrow icon */
    icon: PropTypes.bool,
    /** Type of navigational arrow, whether previous or next button  */
    slide: carouselPropTypes.carouselArrowDirection.isRequired,
    /** Size of the arrows */
    arrowSize: carouselPropTypes.carouselArrowSize,
    /** Type of arrow */
    arrowType: carouselPropTypes.carouselArrowType,
    /** Custom color of arrow  */
    arrowCustomColor: PropTypes.string,
    /** Change the slider's direction to become right-to-left */
    rtl: PropTypes.bool,
    /** Arrow label for screen readers */
    label: PropTypes.node,
    /** Vertical content */
    vertical: PropTypes.bool,
    theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ArrowIcon.defaultProps = {
    icon: true,
    arrowSize: 'default',
    arrowType: 'default',
    arrowCustomColor: null,
    rtl: false,
    label: null,
    vertical: false,
    theme: {},
};

export default ArrowIcon;
