import { rem } from 'polished';
import { layoutTheme } from 'baseline-ui/layout';

const breakpoints = [
    `${rem(layoutTheme.layout.media.xxs)}`,
    `${rem(layoutTheme.layout.media.xs)}`,
    `${rem(layoutTheme.layout.media.sm)}`,
    `${rem(layoutTheme.layout.media.md)}`,
    `${rem(layoutTheme.layout.media.lg)}`,
    `${rem(layoutTheme.layout.media.xl)}`,
];

const baselineTheme = {
    lightMode: {
        breakpoints,
    },
    darkMode: {
        breakpoints,
    },
};

export default baselineTheme;
