import React, { useEffect } from 'react';
import {H1, H3} from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    productAllCategoriesAndFiltersSelector,
    productToEditCategoriesSelector,
} from 'redux/selectors/productSelectors';
import {
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START, GET_ITEM_CATEGORIES_START,
} from 'redux/actions/CategoriesActions';
import {
    SET_PRODUCT_CATEGORY_START,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
} from 'redux/actions/EditProductActions';
import categoriesAndFiltersUtils from 'utils/categoriesAndFiltersUtils';
import CategoriesForm from "../../../screens/BusinessForm/CategoriesForm";
import {
    getSubcategoryById,
    getSubcategoryFilters
} from "../../../redux/selectors/businessFormSelectors";
import {useState} from "react";
import DataTable from "../../../screens/BusinessForm/DataTable";

const ModalEditProductCategories = () => {
    const dispatch = useDispatch();
    const selectedCategories = useSelector(productToEditCategoriesSelector);
    const categories = useSelector(productAllCategoriesAndFiltersSelector);
    const [categoryId, setCategoryId] = useState(null);
    const [subcategoryId, setSubcategoryId] = useState(null);
    const subcategoryFilters = useSelector((state) =>
        getSubcategoryFilters(state, subcategoryId || categoryId),
    );
    const subcategories = useSelector((state) => getSubcategoryById(state, categoryId));

    const tableData = categoriesAndFiltersUtils.getOrganizedSelectedTableData(selectedCategories);

    useEffect(() => {
        dispatch({
            type: GET_ITEM_CATEGORIES_START,
        });
    }, []);

    const handleUnsavedChanges = () => {
        dispatch({
            type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
            payload: {
                hasUnsavedChanges: true,
            },
        });
    };
    const handleCategoryChange = (e, setFieldValue) => {
        const categoryId = e.target.value;
        setCategoryId(categoryId);
        setFieldValue('category', categoryId);
        setSubcategoryId('');
        setFieldValue('subcategory', '');
        setFieldValue('filter', []);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });
    };

    const handleSubcategoryChange = (e, setFieldValue) => {
        const subcategoryId = e.target.value;
        setSubcategoryId(subcategoryId);
        setFieldValue('subcategory', subcategoryId);
        setFieldValue('filter', {});
        if(subcategoryId !== '') {
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: {subcategoryId, categoryId},
            });
        }
    };
    const handleAddFilterClick = (categoryId, subcategoryId) => {
        setCategoryId(categoryId);
        setSubcategoryId(subcategoryId);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });

        const scrollPosition = window.innerWidth >= 600 ? 300 : 500;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };
    const onSubmit = (values) => {
        const onSubmitSelectedCategories =
            categoriesAndFiltersUtils.handleOnSubmitFormikAddCategoryAndFilters({
                values,
                categories,
                subcategories,
                subcategoryFilters,
                selectedCategories,
            });

        dispatch({
            type: SET_PRODUCT_CATEGORY_START,
            payload:  onSubmitSelectedCategories ,
        });

        handleUnsavedChanges();

    };

    return (
        <div>
            <H3 color="primary">
                <FormattedMessage id="addBusiness.modal.editProduct.section.categories.title" />
            </H3>
            <div>
                <CategoriesForm
                    onSubmit={onSubmit}
                    categories={categories}
                    subcategories={subcategories}
                    selectedCategories={selectedCategories}
                    handleCategoryChange={handleCategoryChange}
                    handleSubcategoryChange={handleSubcategoryChange}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    subcategoryFilters={subcategoryFilters}
                />
            </div>

            <div>
                    <H1 color="secondary">
                        <FormattedMessage id="addBusiness.page.addedCategoryAndFilters.table.title"/>
                    </H1>

                    <DataTable
                    data={tableData}
                    onRemoveFilter={(filterId) => {
                        dispatch({
                            type: SET_PRODUCT_CATEGORY_START,
                            payload: categoriesAndFiltersUtils.removeFilterFromSelected(selectedCategories, filterId)
                        });
                        handleUnsavedChanges();
                    }}
                    onRemoveSubcategory={(subcategoryId) => {
                        dispatch({
                            type: SET_PRODUCT_CATEGORY_START,
                            payload: categoriesAndFiltersUtils.removeSubcategoryFromSelected(selectedCategories, subcategoryId),
                        });
                        handleUnsavedChanges();
                    }}
                    onRemoveCategory={(categoryId) => {
                        dispatch({
                            type: SET_PRODUCT_CATEGORY_START,
                            payload: categoriesAndFiltersUtils.removeCategoryFromSelected(selectedCategories, categoryId),
                        });
                        handleUnsavedChanges();
                    }}
                    onAddFilter={handleAddFilterClick}
                    />
                </div>

        </div>
    );
};

export default ModalEditProductCategories;
