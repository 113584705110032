const ADD_BUSINESS_REQUIRED_INFORMATION = 'REQUIRED_INFORMATION';
const ADD_BUSINESS_CATEGORIES_FILTERS = 'CATEGORIES_FILTERS';
const ADD_BUSINESS_ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION';
const ADD_BUSINESS_LINKS = 'LINKS';
const ADD_BUSINESS_PHOTOS = 'PHOTOS';
const ADD_BUSINESS_PEOPLE = 'PEOPLE';
const ADD_BUSINESS_ACCESS_RIGHTS = 'ACCESS_RIGHTS';
const ADD_BUSINESS_PRODUCTS = 'PRODUCTS';
const ADD_BUSINESS_REQUEST_LINK = 'REQUEST_TO_LINK';
const ADD_BUSINESS_EVENTS = 'EVENTS';
const ADD_BUSINESS_BUSINESSES = 'BUSINESSES';
const ADD_BUSINESS_DESTINATION = 'DESTINATION';
const ADD_BUSINESS_REQUEST_LINK_DESTINATION = 'REQUEST_TO_LINK_A_DESTINATION';
const ADD_DESTINATION_REQUEST_LINK_BUSINESS = 'REQUEST_TO_LINK_A_BUSINESS';

export {
    ADD_BUSINESS_REQUIRED_INFORMATION,
    ADD_BUSINESS_CATEGORIES_FILTERS,
    ADD_BUSINESS_ADDITIONAL_INFORMATION,
    ADD_BUSINESS_LINKS,
    ADD_BUSINESS_PHOTOS,
    ADD_BUSINESS_ACCESS_RIGHTS,
    ADD_BUSINESS_PRODUCTS,
    ADD_BUSINESS_PEOPLE,
    ADD_BUSINESS_REQUEST_LINK,
    ADD_BUSINESS_EVENTS,
    ADD_BUSINESS_BUSINESSES,
    ADD_BUSINESS_DESTINATION,
    ADD_BUSINESS_REQUEST_LINK_DESTINATION,
    ADD_DESTINATION_REQUEST_LINK_BUSINESS
};
