import React from 'react';
import PropTypes from 'prop-types';
import TitleItem from './TitleItem';

const Title = ({ title, customTitle, triggerColor }) => {
    if (title && !customTitle) {
        return <TitleItem triggerColor={triggerColor}>{title}</TitleItem>;
    }
    return customTitle;
};

Title.propTypes = {
    title: PropTypes.string,
    customTitle: PropTypes.node,
    triggerColor: PropTypes.string.isRequired,
};

Title.defaultProps = {
    title: null,
    customTitle: null,
};

export default Title;
