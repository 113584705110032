export const ADD_EVENT_START = 'ADD_EVENT_START';
export const SET_EVENT_START = 'SET_EVENT_START';
export const ADD_EVENT_PROCESS = 'ADD_EVENT_PROCESS';
export const ADD_EVENT_FORM_SUCCESS = 'ADD_EVENT_FORM_SUCCESS';
export const ADD_EVENT_FORM_ERROR = 'ADD_EVENT_FORM_ERROR';
export const EVENT = 'EVENT';
export const GET_EVENT_LIST_START = 'GET_EVENT_LIST_START';
export const EVENT_FORM_PROCESS_RESET = 'EVENT_FORM_PROCESS_RESET';
export const ADD_EVENT_FORM_COMPLETE = 'ADD_EVENT_FORM_COMPLETE';
export const GET_EVENT_PROCESS = 'GET_EVENT_PROCESS';
export const GET_EVENT_START = 'GET_EVENT_START';

export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILURE = 'GET_EVENT_LIST_FAILURE';

export const SET_EVENT_FILTER_PROCESS = 'SET_EVENT_FILTER_PROCESS';
export const SET_EVENT_FILTER_START = 'SET_EVENT_FILTER_START';
export const SET_EVENT_FILTER_SUCCESS = 'SET_EVENT_FILTER_SUCCESS';
