import styled, { css } from 'styled-components';
import { get } from 'lodash';
import CheckboxIndicator from '../checkbox/CheckboxIndicator';
import formTheme from '../../styles/formTheme';

const RadioIndicator = styled(CheckboxIndicator)`
    border-radius: 50%;
    border-width: 2px;

    ${({ checked, skin, theme }) =>
        checked &&
        css`
            border-width: 4px;
            background-image: ${`url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%22-4%20-4%208%208%22%3E%3Ccircle%20r=%223%22%20fill=%22${get(
                theme,
                `theme.form.checkbox.${skin}.indicatorColor`,
                formTheme.form.checkbox[skin].indicatorColor,
            ).replace('#', '%23')}%22/%3E%3C/svg%3E')`};
        `}
`;

export default RadioIndicator;
