import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import SmallNote from './SmallNote';

const Small = ({ color, className, children, textAlign }) => {
    return (
        <SmallNote className={className} colorValue={color} textAlign={textAlign}>
            {children}
        </SmallNote>
    );
};

Small.propTypes = {
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    children: PropTypes.node,
};

Small.defaultProps = {
    color: 'dark',
    className: null,
    textAlign: undefined,
    children: null,
};

export default Small;
