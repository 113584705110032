import { h5Styles } from 'baseline-ui/typography';
import styled from 'styled-components';

const ModalEditProductTotalFavesCountLabel = styled.span.attrs(() => ({
    colorValue: 'primary',
}))`
    ${h5Styles}
    margin: 0
`;

export default ModalEditProductTotalFavesCountLabel;
