import React from 'react';
import PropTypes from 'prop-types';
import { H6 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import ModalEditProductSectionsListItemContainer from './ModalEditProductSectionsListItemContainer';

const ModalEditProductSectionsListItem = ({ name, onClickRemoveSection }) => {
    return (
        <ModalEditProductSectionsListItemContainer>
            <H6 color="primary">{name}</H6>
            <Button icon="x" btnType="link" onClick={onClickRemoveSection} size="sm" />
        </ModalEditProductSectionsListItemContainer>
    );
};

ModalEditProductSectionsListItem.propTypes = {
    name: PropTypes.string.isRequired,
    onClickRemoveSection: PropTypes.func.isRequired,
};

export default ModalEditProductSectionsListItem;
