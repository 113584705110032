import siteBaseTheme from './siteBaseTheme';

const siteHeaderTheme = {
    header: {
        backgroundColor: '#482655',
        height: 80,
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.125)',
            verticalShadow: '4px 0 8px rgba(0,0,0,0.125)',
        },
        navigation: {
            label: {
                uppercase: false,
                fontWeight: 400,
            },
            active: {
                width: 4,
                height: 4,
            },
        },
        menuModal: {
            backgroundColor: siteBaseTheme.base.colors.secondary,
            backgroundLightColor: siteBaseTheme.base.colors.light,
            titleTypographyColor: 'light',
            titleTypographyDarkColor: 'secondary',
            menuIcon: {
                color: siteBaseTheme.base.colors.light,
                darkColor: siteBaseTheme.base.colors.primary
            },
        },
        zIndex: 5,
    },
};

export default siteHeaderTheme;
