const helpersUtils = {
    convertFromCents: (value) => value / 100,
    generateUniqueId: () => Math.random().toString(36).substring(2, 16),
    generateUniqueNumber: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
    preventWhiteSpaces: (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    },
};

export default helpersUtils;
