import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize } from 'polished';
import formTheme from '../../styles/formTheme';

const DobDivider = styled.div`
    &:after {
        color: ${(props) =>
            transparentize(
                0.4,
                get(
                    props,
                    `theme.form.input[${props.skin}].color`,
                    formTheme.form.input[props.skin].color,
                ),
            )};
        content: '/';
        display: inline-block;
        transform: translateY(-2px);
        transition: opacity 0.3s ease-in;
    }
    ${(props) =>
        props.disabledValue &&
        css`
            &:after {
                color: ${get(
                    props,
                    `theme.form.input[${props.skin}].colorDisabled`,
                    formTheme.form.input[props.skin].colorDisabled,
                )};
            }
        `}
`;

export default DobDivider;
