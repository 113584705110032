import { rem } from 'polished';
import styled from 'styled-components';
import { media } from 'baseline-ui/layout';

const WelcomeAsideContentContainer = styled.div`
    position: relative;
    z-index: 1;

    ${media.md` 
        margin-left: ${rem(72)};
        margin-right: ${rem(72)};
    `}
`;

export default WelcomeAsideContentContainer;
