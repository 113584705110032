import { GET_ITEM_DETAILS_SUCCESS } from "../actions/ItemActions";

const ItemReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        itemDetails: payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default ItemReducer;
