import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import overlayTheme from '../../../styles/overlayTheme';

const OverlayCloseIcon = ({ color }) => {
    const theme = useContext(ThemeContext);
    const sizeValue = get(theme, 'overlay.closeIcon.size', overlayTheme.overlay.closeIcon.size);

    return <GaIcon icon="x" color={color} size={sizeValue} />;
};

OverlayCloseIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

export default OverlayCloseIcon;
