import React from 'react';
import { H6 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import CreateFavePageComingSoonContainer from './CreateFavePageComingSoonContainer';

/** Used over the entity button if not available */

const CreateFavePageComingSoon = () => {
    return (
        <CreateFavePageComingSoonContainer>
            <H6 color="light">
                <FormattedMessage id="createFavePage.entityType.comingSoon.label" />
            </H6>
        </CreateFavePageComingSoonContainer>
    );
};

export default CreateFavePageComingSoon;
