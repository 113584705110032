import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import {GetProductItems, GetBusinessMapping} from "./api";
import cloneDeep from "lodash/cloneDeep";
import ItemForm from "./ItemForm";
import { v4 as uuidv4 } from "uuid";
import { deserializeMapping } from "./helpers/features";
import { useFormik } from "formik";
import { useUserStore } from "./stores/LoginStore";

import ProductItem from "./ProductItem";
// import ManagePhotos from './ManagePhotos';
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { shadows } from "@mui/system";
import { useNavigate } from "react-router-dom";

function ProductSearch() {
  const [itemProducts, setItemProducts] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [featureMapping, setFeatureMapping] = useState([]);

  let params = new URLSearchParams(window.location.search);
  let business_id = params.get("business_id");
  const navigate = useNavigate();

  const useStyles = makeStyles({
    mainGrid: {
      marginLeft: 20,
      marginRight: 40,
      paddingRight: 10,
      paddingLeft: 10,
    },
  });

  useEffect(() => {
    GetProductItems(business_id, searchText).then((items) => {
      setItemProducts(items);
      setLoading(false);
    });

    // Only show categories of item.
    // Is this right?????
    // Can't tag something that is of interest?
    GetBusinessMapping().then((mapping) => {
      setFeatureMapping(mapping);
    });
  }, []);

  const goBack = (e) => {
    // Go back to business edit
    navigate(`/own-a-business/edit?business_id=${business_id}`);
  };

  const search = (e) => {
    setSearchText(e.target.value);

    GetProductItems(business_id, e.target.value).then((items) => {
      setItemProducts(items);
      setLoading(false);
    });
  };
  const classes = useStyles();

  const addProduct = () => {
    setItemProducts([{ item: { _id: "NEW" }, mapping: [] }, ...itemProducts]);
  };

  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <Grid container spacing={2} className={classes.mainGrid}>
        <Grid item xs={4}>
          <Button variant="contained" onClick={goBack}>
            Return to Business Page
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={4}>
          <TextField
            id="search"
            label="Search for Products"
            variant="standard"
            value={searchText}
            onChange={search}
          />
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <br></br>
      <Grid container spacing={2} className={classes.mainGrid}>
        <Grid item xs={6} container>
          <Button variant="contained" onClick={addProduct}>
            Add Product
          </Button>
        </Grid>
      </Grid>
      <br></br>
      <br></br>

      {itemProducts.map((itemProduct, index) => {
        const id = itemProduct.item._id;

        const productMapping = deserializeMapping(itemProduct.mapping);

        let flatProduct = itemProduct.item;
        flatProduct.mapping = productMapping;

        return (
          <>
            <Grid key={`${id}.mainGrid`} container className={classes.mainGrid}>
              <ProductItem
                key={`${id}.productItem`}
                itemProduct={flatProduct}
                business_id={business_id}
                catSubCats={featureMapping}
              />
            </Grid>
            <br></br>
            <br></br>
          </>
        );
      })}
    </>
  );
}

export default ProductSearch;
