import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_PHOTOS } from 'common/addBusinessViewIds';
import { useSelector } from 'react-redux';
import { ENTITY_TYPE_ICONS } from 'common/consts';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import FavePagePreviewPhotoPlaceholderContainer from './FavePagePreviewPhotoPlaceholderContainer';

const FavePagePreviewPhotoPlaceholder = ({ addToSection }) => {
    const theme = useContext(ThemeContext);
    const { hideModal } = useContext(ModalContext);
    const { type } = useSelector(businessFormSelector);

    return (
        <FavePagePreviewPhotoPlaceholderContainer>
            <GaIcon icon={ENTITY_TYPE_ICONS[type]} size={48} color={theme.base.colors.primary} />
            <Button
                icon="plus"
                btnType="link"
                onClick={() => {
                    hideModal();
                    addToSection(ADD_BUSINESS_PHOTOS);
                }}
            >
                Add Photos
            </Button>
        </FavePagePreviewPhotoPlaceholderContainer>
    );
};

FavePagePreviewPhotoPlaceholder.propTypes = {
    addToSection: PropTypes.func,
};

FavePagePreviewPhotoPlaceholder.defaultProps = {
    addToSection: () => {},
};

export default FavePagePreviewPhotoPlaceholder;
