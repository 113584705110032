import styled from 'styled-components';
import { animated } from 'react-spring';

const Container = styled(animated.div)`
    overflow: hidden;
    text-align: left;
    will-change: height;
`;

export default Container;
