import React, { useContext } from 'react';
import { ModalCloseButton, ModalCloseIcon, ModalContext } from 'baseline-ui/modal';
import FavePagePreviewModalCloseButtonContainer from './FavePagePreviewModalCloseButtonContainer';

const FavePagePreviewModalCloseButton = () => {
    const { hideModal } = useContext(ModalContext);

    return (
        <FavePagePreviewModalCloseButtonContainer>
            <ModalCloseButton showCloseButton onClick={() => hideModal()}>
                <ModalCloseIcon color="#fff" />
            </ModalCloseButton>
        </FavePagePreviewModalCloseButtonContainer>
    );
};

export default FavePagePreviewModalCloseButton;
