import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SET_PRODUCT_TO_EDIT_START } from "../../redux/actions/EditProductActions";
import { get } from "lodash";
import ProductForm from "./ProductForm";
import CommonWrapper from "../CommonWrapper";

const EditProduct = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { sectionProducts, selectedSection } = useSelector(
    ({ SectionReducer }) => SectionReducer
  );

  useEffect(() => {
    if (productId && sectionProducts) {
      console.log("pull in the right prouduct");
      const products = get(sectionProducts, [
        selectedSection?.replace(/ /g, "_"),
      ]);
      const productToEdit = products?.find(
        (product) => product._id === productId
      );
      dispatch({
        type: SET_PRODUCT_TO_EDIT_START,
        payload: productToEdit._id,
      });
    }
  }, [productId, sectionProducts]);

  if (!productId) {
    return (
      <div>
        <h1>Product Id must be defined</h1>
      </div>
    );
  }

  return (
    <CommonWrapper title="Edit product">
      <ProductForm />
    </CommonWrapper>
  );
};

export default EditProduct;
