import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button } from 'baseline-ui/button';
import { Small } from 'baseline-ui/typography';
import ProductsAndServicesFilteredListEmptyResultsContainer from './ProductsAndServicesFilteredListEmptyResultsContainer';
import ProductsAndServicesFilteredListEmptyResultsContent from './ProductsAndServicesFilteredListEmptyResultsContent';
import ProductsAndServicesFilteredListEmptyResultsTitle from './ProductsAndServicesFilteredListEmptyResultsTitle';
import ProductsAndServicesFilteredListEmptyResultsButton from './ProductsAndServicesFilteredListEmptyResultsButton';
import ProductsAndServicesFilteredListEmptyResultsSearchValue from './ProductsAndServicesFilteredListEmptyResultsSearchValue';

const ProductsAndServicesFilteredListEmptyResults = () => {
    const { resetForm, values } = useFormikContext();
    const { filterProducts } = values;

    return (
        <ProductsAndServicesFilteredListEmptyResultsContainer>
            <ProductsAndServicesFilteredListEmptyResultsContent>
                <ProductsAndServicesFilteredListEmptyResultsTitle>
                    <Small color="muted" textAlign="center">
                        <FormattedMessage
                            id="addBusiness.page.sections.productsAndServices.list.noResults.label"
                            values={{
                                searchValue: (
                                    <ProductsAndServicesFilteredListEmptyResultsSearchValue>
                                        {filterProducts}
                                    </ProductsAndServicesFilteredListEmptyResultsSearchValue>
                                ),
                            }}
                        />
                    </Small>
                </ProductsAndServicesFilteredListEmptyResultsTitle>
                <ProductsAndServicesFilteredListEmptyResultsButton>
                    <Button
                        onClick={() => resetForm()}
                        btnType="outlined"
                        icon="x-circle"
                        type="button"
                        size="sm"
                        skin="muted"
                    >
                        <FormattedMessage id="addBusiness.page.sections.productsAndServices.list.clearSearch.button.label" />
                    </Button>
                </ProductsAndServicesFilteredListEmptyResultsButton>
            </ProductsAndServicesFilteredListEmptyResultsContent>
        </ProductsAndServicesFilteredListEmptyResultsContainer>
    );
};

export default ProductsAndServicesFilteredListEmptyResults;
