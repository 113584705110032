import styled, { css } from 'styled-components';

const PageContentWrapper = styled.div`
    min-height: 100%;
    width: 100%;

    ${({ bgStart }) =>
        bgStart &&
        css`
            background-color: ${bgStart};
        `}

    ${({ bgStart, bgEnd }) =>
        bgStart &&
        bgEnd &&
        css`
            background-image: ${`linear-gradient(to bottom, ${bgStart} 0, ${bgEnd} 100%)`};
        `};

    ${({ customBg }) =>
        customBg &&
        css`
            position: relative;
            z-index: 1;
        `}
`;

export default PageContentWrapper;
