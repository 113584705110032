import React, { useState, useEffect } from "react";
import { useUserStore } from "./stores/LoginStore";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Avatar, Fab } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  GET_BUSINESESS_PROCESS,
  GET_BUSINESSES_START,
  SET_BUSINESS_FILTER_START,
} from "./redux/actions/BusinessesActions";
import { useSelector } from "react-redux";
import ErrorAlertComponent from "./components/ErrorAlertComponent";
import {
  POST_BUSINESS_PROCESS_RESET,
  RESET_BUSINESS_START,
} from "./redux/actions/OwnABussinessActions";

function MySubmissions() {
  const [userStore] = useUserStore();
  const { ownedBusinesses, searchText } = useSelector(
    ({ BusinessesReducer }) => BusinessesReducer,
  );
  const navigate = useNavigate();

  const useStyles = makeStyles({
    mainGrid: {
      marginLeft: 20,
      marginRight: 40,
      paddingRight: 10,
      paddingLeft: 10,
    },
    boxContainer: {
      marginLeft: 20,
      marginRight: 20,
      paddingRight: 20,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_BUSINESSES_START,
    });
  }, []);

  const search = (e) => {
    dispatch({
      type: SET_BUSINESS_FILTER_START,
      payload: e.target.value,
    });
  };

  const newBusiness = (e) => {
    // Go back to business edit
    navigate(`/own-a-business/create`);
  };

  const goBack = (e) => {
    navigate(`/`);
  };

  const editBusiness = (business_id) => {
    // Edit the business
    dispatch({
      type: RESET_BUSINESS_START,
    });
    dispatch({
      type: POST_BUSINESS_PROCESS_RESET,
    });
    navigate(`/own-a-business/edit/${encodeURIComponent(business_id)}`);
  };

  return (
    <>
      <ErrorAlertComponent processName={GET_BUSINESESS_PROCESS} />
      <br></br>
      <br></br>
      <br></br>
      <Grid container spacing={2} className={classes.mainGrid}>
        <Grid item xs={12} align="center" justify="center">
          <Typography variant="h2" display="inline" align="center">
            {userStore?.is_admin ? "Businesses" : "Your Businesses"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button variant="contained" onClick={goBack}>
            Back
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="search"
            autoComplete="off"
            label="Search for a Business"
            variant="standard"
            value={searchText || ""}
            onChange={search}
          />
        </Grid>
        <Grid item xs={12} sm={12} container alignItems="center">
          <Button sx={{ height: 40 }} variant="contained" onClick={newBusiness}>
            Add a Business
          </Button>
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <br></br>
      <br></br>
      <br></br>
      {ownedBusinesses.map((payload, index) => {
        const {
          business: { _id },
        } = payload;
        return (
          <div key={`ownedBusinessItem_${_id}`}>
            <Grid
              key={`${index}.mainGrid`}
              container
              className={classes.mainGrid}
            >
              <Box
                component={Grid}
                container
                spacing={2}
                boxShadow={3}
                className={classes.boxContainer}
              >
                <Grid item xs={12} sm={4} md={3} container alignItems="center">
                  <Avatar
                    sx={{ width: 150, height: 150 }}
                    src={
                      payload.business &&
                      payload.business.photos &&
                      payload.business.photos.length > 0
                        ? payload.business?.photos[0]?.url
                        : ""
                    }
                    variant="rounded"
                  />
                </Grid>
                <Grid item xs={10} sm={8} md={9} container>
                  <Grid item xs={12} sm={4} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Business Name:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.name}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Contact Name:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.ownerName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} container></Grid>
                  <Grid item xs={12} sm={4} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Email:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.email}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Address:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.formattedAddress}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Phone:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.phone}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} container alignItems="center">
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      align="center"
                    >
                      Website:&nbsp;
                    </Typography>
                    <Typography variant="body2" display="inline" align="center">
                      {`${payload.business.website}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={12} container alignItems="center">
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    align="center"
                  >
                    Approval Status:&nbsp;
                  </Typography>
                  <Typography variant="body2" display="inline" align="center">
                    {`${payload.business.approval_status}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} container alignItems="center">
                  <Button
                    sx={{ height: 40 }}
                    variant="contained"
                    onClick={(e) => {
                      editBusiness(payload.business._id);
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
                {userStore?.is_admin && (
                  <>
                    <Grid item xs={12} container>
                      <b>Admin Only:</b>
                    </Grid>
                    <Grid item xs={12} sm={2} container alignItems="center">
                      <Typography
                        variant="subtitle2"
                        display="inline"
                        align="center"
                      >
                        Followup Status:&nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        display="inline"
                        align="center"
                      >
                        {`${
                          payload.business?.adminOnly?.followupStatus
                            ? payload.business?.adminOnly?.followupStatus
                            : "NONE"
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} container alignItems="center">
                      <Typography
                        variant="subtitle2"
                        display="inline"
                        align="center"
                      >
                        Private Cell:&nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        display="inline"
                        align="center"
                      >
                        {`${
                          payload.business?.adminOnly?.privateCell
                            ? payload.business?.adminOnly?.privateCell
                            : ""
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}></Grid>
                  </>
                )}
              </Box>
            </Grid>
            <br></br>
            <br></br>
          </div>
        );
      })}
    </>
  );
}

export default MySubmissions;
