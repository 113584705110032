import { useSelector } from 'react-redux';
import {
    businessFormRequiredFieldSelector,
    businessFormSelector,
} from 'redux/selectors/businessFormSelectors';
import addBusinessSectionsUtils from './addBusinessSectionsUtils';

const useAddBusinessValid = () => {
    const { type } = useSelector(businessFormSelector);
    const businessFormRequiredFields = useSelector(businessFormRequiredFieldSelector);

    const isSubmitValid = addBusinessSectionsUtils.addBusinessHasAllRequiredFields(
        businessFormRequiredFields,
        type,
    );

    return isSubmitValid;
};

export default useAddBusinessValid;
