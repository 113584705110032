import { isEmpty, map } from 'lodash';

const productsAndServicesUtils = {
    getSectionItemPhoto: (photos) => {
        if (isEmpty(photos)) {
            return null;
        }
        return photos.find((photo) => photo.order === 0)?.url;
    },
    getSectionKey: (sectionName) => {
        if (!sectionName) {
            return 'undefined';
        }
        return sectionName.replace(/ /g, '_');
    },

    isItemInSection: ({ itemId, sectionItems }) => {
        return sectionItems.find((sectionItem) => sectionItem === itemId) !== undefined;
    },

    getItemById: ({ products, id }) => {
        return products.find((product) => product._id === id);
    },
    getProductsByIds: ({ products, ids }) => {
        return map(ids, (id) => {
            return products.find((product) => product._id === id);
        });
    },
    getProductIds: (products) => {
        return map(products, '_id');
    },
    initialValuesSelectedProductIdsFormik: (productIds) => {
        if (isEmpty(productIds)) {
            return null;
        }
        return Object.assign({}, ...productIds.map((productId) => ({ [productId]: true })));
    },
    formatProductPrice: (intl, price) => {
        return intl.formatNumber(price.replace(/\D/g, '') / 100, {
            style: 'currency',
            currency: 'USD',
        });
    },
};

export default productsAndServicesUtils;
