import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const formReferAFriendSchema = yup.object().shape({
    firstName: fieldSchema.firstName.required(),
    lastName: fieldSchema.lastName.required(),
    phoneNumber: fieldSchema.phoneNumber.required(),
    friendFirstName: fieldSchema.firstName.required(),
    friendLastName: fieldSchema.lastName.required(),
    friendPhoneNumber: fieldSchema.phoneNumber.required(),
    friendEmailAddress: fieldSchema.email.required(),
});

export default formReferAFriendSchema;
