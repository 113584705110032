import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { H1, H3, H4, P } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { ownABusinessSelector } from 'redux/selectors/ownABusinessSelectors';
import { EntityLinkAdmin } from 'components/EntityLink/EntityLinkAdmin';
import {
    GET_ENTITY_LINKS_TO_ENTITY_CALL,
    POST_ENTITY_LINKS_TO_ENTITY_CALL,
} from 'redux/actions/OwnABussinessActions';
import { ENTITY_TYPE_PEOPLE, ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_DESTINATION } from 'common/consts';
import {ENTITY_LINK_STATUS_APPROVED, ENTITY_LINK_STATUS_DENIED, ENTITY_LINK_STATUS_PENDING} from 'common/entityLinkStatus';

const EntityLinksToEntity = ({ entityType } = props) => {
    const entityTypeLowerCase = entityType.toLowerCase()
    const dispatch = useDispatch();
    const { _id: businessId } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer)
    useEffect(() => {
        if(businessId && entityType) {
            dispatch({
                type: GET_ENTITY_LINKS_TO_ENTITY_CALL,
                payload: {
                    entityIdRequested: businessId,
                    entityRequestingType: entityType
                }
            });
        }
    }, [entityType]);
    const { people, businesses, destinations } = useSelector(ownABusinessSelector);
    const isAdmin = useSelector(isAdminSelector);
    const { pending, accepted, denied } = useMemo(() => {
        let data = []

        switch (entityType) {
            case ENTITY_TYPE_PEOPLE:
                data = people;
                break;
            case ENTITY_TYPE_BUSSINESS:
                data = businesses;
                break;
            case ENTITY_TYPE_DESTINATION:
                data = destinations;
                break;
            default:
                break;
        }

        const pending = data.filter((req) => req.approvalstatus === ENTITY_LINK_STATUS_PENDING);
        const accepted = data.filter((req) => req.approvalstatus === ENTITY_LINK_STATUS_APPROVED);
        const denied = data.filter((req) => req.approvalstatus === ENTITY_LINK_STATUS_DENIED);

        return {
            pending,
            accepted,
            denied,
        };
    }, [people, businesses, destinations, entityTypeLowerCase]);
    const [openComment, setOpenComment] = useState(() => {
        return pending[0]?.id ?? accepted[0]?.id;
    });
    const addRequest = (id) => {
        dispatch({
            type: POST_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                requestedEntityType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_APPROVED,
            },
        });
    };
    const removeRequest = (id) => {
        dispatch({
            type: POST_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                requestedEntityType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_DENIED,
            },
        });
    };
    return (
        <>
            <H1 color="primary">
                <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.title`} />
            </H1>
            <P>
                <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.subtitle`} />
            </P>
            {isAdmin && <EntityLinkAdmin entityType={entityType} />}
            <SubsectionContainer>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.pending.title`} />
                    </SubsectionTitle>
                    {pending.length ? (
                        pending.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.name ? req.name : `${req.firstName} ${req.lastName}`}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showAdd
                                        showExpand
                                        showRemove
                                        onExpand={() => setOpenComment(req._id)}
                                        onAdd={() => addRequest(req._id)}
                                        onRemove={() => removeRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.pending.empty`} />
                            </small>
                        </P>
                    )}
                </Subsection>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.accepted.title`} />
                    </SubsectionTitle>
                    {accepted.length ? (
                        accepted.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.name ? req.name : `${req.firstName} ${req.lastName}`}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showExpand
                                        showRemove
                                        onExpand={() => setOpenComment(req._id)}
                                        onRemove={() => removeRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.accepted.empty`} />
                            </small>
                        </P>
                    )}
                </Subsection>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.denied.title`} />
                    </SubsectionTitle>
                    {denied.length ? (
                        denied.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.name ? req.name : `${req.firstName} ${req.lastName}`}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showAdd
                                        showExpand
                                        onExpand={() => setOpenComment(req._id)}
                                        onAdd={() => addRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id={`addBusiness.page.sections.${ entityTypeLowerCase }.denied.empty`} />
                            </small>
                        </P>
                    )}
                </Subsection>
            </SubsectionContainer>
        </>
    );
};

/**
 * Subsection component.
 */
const Subsection = styled.section`
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 3rem;
`;

/**
 * Subsection Container component.
 */
const SubsectionContainer = styled.div``;

/**
 * Subsection Title component.
 */
const SubsectionTitle = styled(H3)``;
SubsectionTitle.defaultProps = {
    color: 'secondary',
};

/**
 * Request Button component.
 */
const RequestButton = styled(Button)`
    margin: 0.2rem;
`;
RequestButton.defaultProps = {
    type: 'button',
};

/**
 * LinkRequest component.
 */
const LinkRequest = ({
    name,
    date,
    comment,
    showAdd,
    showRemove,
    showExpand,
    expand,
    onExpand,
    onAdd,
    onRemove,
}) => {
    const text = comment.trim();
    const hidden = expand ? '' : 'hidden';
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                    <H4>{name ?? '-'}</H4>
                    <p>{date.toLocaleDateString()}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                    {showAdd && (
                        <RequestButton onClick={onAdd}>
                            <FormattedMessage id="addBusiness.page.sections.people.requests.approve.button.label" />
                        </RequestButton>
                    )}
                    {showRemove && (
                        <RequestButton onClick={onRemove}>
                            <FormattedMessage id="addBusiness.page.sections.people.requests.reject.button.label" />
                        </RequestButton>
                    )}
                    {showExpand && (
                        <RequestButton
                            btnType="link"
                            icon={expand ? 'chevron-down' : 'chevron-up'}
                            onClick={onExpand}
                        />
                    )}
                </div>
            </div>
            <p hidden={hidden}>
                {text || (
                    <small>
                        <FormattedMessage id="addBusiness.page.sections.people.requests.nocomment" />
                    </small>
                )}
            </p>
        </div>
    );
};
LinkRequest.propTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    comment: PropTypes.string,
    showAdd: PropTypes.bool,
    showRemove: PropTypes.bool,
    showExpand: PropTypes.bool,
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
};
LinkRequest.defaultProps = {
    comment: '',
    showAdd: false,
    showRemove: false,
    showExpand: false,
    expand: false,
    onExpand: () => {},
};

export default EntityLinksToEntity;
