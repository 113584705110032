import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/700-italic.css';
import siteBaseTheme from './siteBaseTheme';

const siteTypographyTheme = {
    typography: {
        base: {
            fontFamily: 'Poppins, sans-serif',
            fontSizeMin: 14,
        },
        headings: {
            fontFamily: 'Poppins, sans-serif',
            lineHeight: 1.25,
            fontSize: {
                h1: 28,
                h2: 24,
                h3: 21,
                H4: 19,
                h5: 16,
                h6: 14,
            },
            fontWeight: {
                h1: 700,
                h2: 700,
                h3: 700,
                h4: 400,
                h5: 700,
                h6: 400,
            },
            // lineHeight: 1.15,
        },
        largeHeadings: {
            fontSize: {
                h1: 32,
                h2: 28,
            },
            lineHeight: {
                h1: 1.1,
                h2: 1.2,
            },
            paddingV: {
                h1: 8,
                h2: 8,
            },
        },
        colors: {
            primary: siteBaseTheme.base.colors.primary,
            primaryMuted: siteBaseTheme.base.colors.primaryMuted,
            secondary: siteBaseTheme.base.colors.secondary,
            info: siteBaseTheme.base.colors.info,
            light: siteBaseTheme.base.colors.light,
            dark: siteBaseTheme.base.colors.dark,
        },
        link: {
            color: siteBaseTheme.base.colors.primary,
        },
    },
};

export default siteTypographyTheme;
