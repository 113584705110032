import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import headingStyles from './headingStyles';
import typographyTheme from './typographyTheme';
import typographyUtils from '../utils/typographyUtils';

const h2Styles = () => css`
    ${headingStyles}

    font-size: ${({ theme }) =>
        rem(
            get(
                theme,
                'typography.headings.fontSize.h2',
                typographyTheme.typography.headings.fontSize.h2,
            ),
        )};
    font-weight: ${({ theme }) =>
        get(
            theme,
            'typography.headings.fontWeight.h2',
            typographyTheme.typography.headings.fontWeight.h2,
        )};
    margin: ${({ theme }) => typographyUtils.headingMarginByPaddingRatio({ theme, heading: 'h2' })}
        0;
`;

export default h2Styles;
