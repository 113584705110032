import styled from 'styled-components';
import { get } from 'lodash';
import { media } from 'baseline-ui/layout';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const FormSwitchLabel = styled.label`
    color: ${(props) =>
        get(
            props,
            `theme.form.switch.label[${props.skin}].color`,
            formTheme.form.switch.label[props.skin].color,
        )};
    display: inline-block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: 0.9375rem;
    margin: 0;
    padding-right: 1rem;

    ${media.xs`
        padding-right: 0;
    `}

    ${media.lg`
        font-size: 1.125rem;
    `}
`;

export default FormSwitchLabel;
