import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { H1, P } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import { Button } from 'baseline-ui/button';
import ProductsAndServicesList from 'components/productsAndServices/ProductsAndServicesList';
import ProductsAndServicesItemList from 'components/productsAndServices/ProductsAndServicesItemList';
import { useDispatch, useSelector } from 'react-redux';
import { allSectionsActiveProductsSelector } from 'redux/selectors/sectionSelectors';
import { OverlayContext } from 'baseline-ui/overlay';
import {
    SAVE_PRODUCTS_BULK_PROCESS,
    SET_EMPTY_PRODUCT_START,
} from 'redux/actions/EditProductActions';
import ModalAddProduct from 'components/productsAndServices/modalProduct/ModalAddProduct';
import useProcessLoader from 'utils/useProcessLoader';
import { ModalContext } from 'baseline-ui/modal';
import ModalAddSection from 'components/productsAndServices/modalSection/ModalAddSection';
import ProductsAndServicesIntroActionContainer from 'components/productsAndServices/ProductsAndServicesIntroActionContainer';
import ProductsAndServicesIntroActionsContainer from 'components/productsAndServices/ProductsAndServicesIntroActionsContainer';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';

const ProductsAndServices = () => {
    const dispatch = useDispatch();
    const { showModal } = useContext(ModalContext);
    const { showOverlay } = useContext(OverlayContext);
    const allSectionsActiveProducts = useSelector(allSectionsActiveProductsSelector);
    const { type } = useSelector(businessFormSelector);

    useProcessLoader({
        processNames: [SAVE_PRODUCTS_BULK_PROCESS],
        withOverlay: true,
    });

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.productsAndServices.title" />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.productsAndServices.subtitle`} />
            </P>
            <ProductsAndServicesIntroActionsContainer>
                <ProductsAndServicesIntroActionContainer>
                    <Button
                        onClick={() => {
                            dispatch({ type: SET_EMPTY_PRODUCT_START });
                            showOverlay(ModalAddProduct);
                        }}
                    >
                        <FormattedMessage id="addBusiness.page.sections.productsAndServices.addProductsAndServices" />
                    </Button>
                </ProductsAndServicesIntroActionContainer>
                <ProductsAndServicesIntroActionContainer>
                    <Button onClick={() => showModal(ModalAddSection)}>
                        <FormattedMessage id="addBusiness.page.sections.productsAndServices.addSection" />
                    </Button>
                </ProductsAndServicesIntroActionContainer>
            </ProductsAndServicesIntroActionsContainer>
            <DndProvider backend={HTML5Backend}>
                <Row justifyContent="center">
                    {/* xxs={29} - temporary workaround tabs issue at 100% */}
                    <Col xxs={29} md={10}>
                        <ProductsAndServicesList />
                    </Col>
                    {/* xxs={29} - temporary workaround tabs issue at 100% */}
                    <Col xxs={29} md={20}>
                        <ProductsAndServicesItemList
                            sectionContainers={allSectionsActiveProducts}
                        />
                    </Col>
                </Row>
            </DndProvider>
        </>
    );
};

export default ProductsAndServices;
