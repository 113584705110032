import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'baseline-ui/layout';
import { H5, Small } from 'baseline-ui/typography';
import { TabItem, Tabs } from 'baseline-ui/tabs';
import { Button } from 'baseline-ui/button';
import ProductsAndServicesListHeaderContainer from 'components/productsAndServices/ProductsAndServicesList/ProductsAndServicesListHeaderContainer';
import { FormattedMessage } from 'react-intl';
import { ModalContext } from 'baseline-ui/modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_SECTION_PRODUCTS_START,
    SET_SELECTED_SECTION_START,
} from 'redux/actions/SectionActions';
import {
    initialSectionIdSelector,
    sectionProductsBySectionNameSelector,
    selectedSectionIdBySectionNameSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import { businessIdSelector } from 'redux/selectors/businessFormSelectors';
import ProductsAndServicesItemListTabItem from './ProductsAndServicesItemListTabItem';
import ProductsAndServicesItemListTabs from './ProductsAndServicesItemListTabs';
import ProductsAndServicesListHeaderInner from './ProductsAndServicesListHeaderInner';
import ModalAddSection from '../modalSection/ModalAddSection';
import ModalReoderSections from '../modalSection/ModalReoderSections';

const ProductsAndServicesItemList = ({ sectionContainers }) => {
    const { showModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const selectedSectionId = useSelector(selectedSectionIdBySectionNameSelector);
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const selectedSectionExists = useSelector(
        sectionProductsBySectionNameSelector(selectedSectionName),
    );
    const initialSectionId = useSelector(initialSectionIdSelector);
    const businessId = useSelector(businessIdSelector);

    const allowReorderSections = useMemo(() => {
        if (!sectionContainers) {
            return false;
        }
        return sectionContainers.length > 1;
    }, [sectionContainers]);

    useEffect(() => {
        if (!selectedSectionExists) {
            dispatch({
                type: GET_SECTION_PRODUCTS_START,
                payload: {
                    businessId,
                    selectedSection: selectedSectionName,
                },
            });
        }
    }, [selectedSectionName, selectedSectionExists]);

    return (
        <>
            <ProductsAndServicesListHeaderContainer>
                <ProductsAndServicesListHeaderInner>
                    <Row justifyContent="space-between" alignItems="flex-end">
                        <Col>
                            <H5>
                                <FormattedMessage id="addBusiness.page.sections.productsAndServices.items.title.label" />
                            </H5>
                            <Small>
                                <FormattedMessage
                                    id="addBusiness.page.sections.productsAndServices.items.subtitle.label"
                                    values={{ count: sectionContainers?.length }}
                                />
                            </Small>
                            <Button
                                btnType="link"
                                size="sm"
                                onClick={() => showModal(ModalAddSection)}
                            >
                                <FormattedMessage id="addBusiness.page.sections.productsAndServices.items.addSection.label" />
                            </Button>
                        </Col>
                        {allowReorderSections && (
                            <Col>
                                <Button
                                    btnType="link"
                                    size="sm"
                                    onClick={() => showModal(ModalReoderSections)}
                                >
                                    <FormattedMessage id="addBusiness.page.sections.productsAndServices.items.reorderSection.label" />
                                </Button>
                            </Col>
                        )}
                    </Row>
                </ProductsAndServicesListHeaderInner>
            </ProductsAndServicesListHeaderContainer>
            <ProductsAndServicesItemListTabs>
                {sectionContainers && (
                    <Tabs
                        showScrollbar={false}
                        setActiveTabIndexCallback={(activeItemId) => {
                            dispatch({
                                type: SET_SELECTED_SECTION_START,
                                payload: sectionContainers.find(
                                    (sectionContainer) =>
                                        sectionContainer.sectionId === activeItemId,
                                )?.sectionName,
                            });
                        }}
                        initialActiveItemId={initialSectionId}
                        activeItemIdImperatively={selectedSectionId}
                        tabsMaxWidth={0}
                    >
                        {sectionContainers.map((sectionContainer, sectionContainerIndex) => {
                            const { sectionName, sectionId } = sectionContainer;

                            return (
                                <TabItem
                                    key={`${sectionId}-${sectionContainerIndex}`}
                                    itemId={sectionId}
                                    title={sectionName}
                                >
                                    <ProductsAndServicesItemListTabItem
                                        key={sectionName}
                                        sectionContainer={sectionContainer}
                                        index={sectionContainerIndex}
                                    />
                                </TabItem>
                            );
                        })}
                    </Tabs>
                )}
            </ProductsAndServicesItemListTabs>
        </>
    );
};

ProductsAndServicesItemList.propTypes = {
    sectionContainers: PropTypes.arrayOf(
        PropTypes.shape({
            accepts: PropTypes.arrayOf(PropTypes.string),
            listItems: PropTypes.arrayOf(PropTypes.string),
            sectionName: PropTypes.string,
        }),
    ),
};

ProductsAndServicesItemList.defaultProps = {
    sectionContainers: undefined,
};

export default ProductsAndServicesItemList;
