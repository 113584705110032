import { darken, rem } from 'polished';
import styled from 'styled-components';

const AdminBusinessAdvertisingCityButtonContainer = styled.div`
    align-items: center;
    justify-content: space-between;
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    border: 1px solid ${({ theme }) => darken(0.2, theme.typography.colors.primaryMuted)};
    display: flex;
    margin-bottom: ${rem(4)};
    margin-top: ${rem(4)};
    padding-left: ${rem(8)};
    user-select: none;
`;

export default AdminBusinessAdvertisingCityButtonContainer;
