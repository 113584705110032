import React from 'react';
import PropTypes from 'prop-types';
import { typographyPropTypes } from 'baseline-ui/typography';
import Container from './Container';
import ModalTitle from './ModalTitle';

const ModalHeader = ({ title, $titleTypographyColor }) => {
    return (
        <Container>
            <ModalTitle color={$titleTypographyColor}>{title}</ModalTitle>
        </Container>
    );
};

ModalHeader.propTypes = {
    title: PropTypes.node.isRequired,
    $titleTypographyColor: typographyPropTypes.typographyColor,
};

ModalHeader.defaultProps = {
    $titleTypographyColor: undefined,
};

export default ModalHeader;
