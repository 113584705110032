import CommonWrapper from "../../CommonWrapper";
import HeroWrapper from "../BusinessPage/HeroWrapper";
import {ContentRenderer, Image} from "baseline-ui/helpers";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ButtonWrapper from "baseline-ui/button/src/components/ButtonWrapper";
import {LinkButton} from "baseline-ui/button";
import {HOME_PATH} from "common/pathnames";
import aboutImage from "images/about_img.png";
import AboutImageWrapper from "./AboutImageWrapper";
import leftArrow from "images/orange_left_arrow.svg";
import rightArrow from "images/orange_right_arrow.svg";
import {H3} from "baseline-ui/typography";
import {LOGO_ONLY_EMPTY} from "images";
import LaunchWrapper from "../HomePage/LaunchWrapper";
import Container from "../../../components/Container";
import Separator from "../../../components/Separator";
import ListContainer from "./ListContainer";
import {get} from "lodash";
import {useContext} from "react";
import {ThemeContext} from "styled-components";
import siteBaseTheme from "../../../styles/siteBaseTheme";
import FlexWrapper from "./FlexWrapper";
import TitleWrapper from "./TitleWrapper";
import upTriangle from "../../../images/triangle_up_left.svg";
import AboutContentWrapper from "./AboutContentWrapper";
import AboutImage from "./AboutImage";

const AboutPageRoute = () => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();
    const backgroundColor =  get(theme, 'base.colors.secondary', siteBaseTheme.base.colors.secondary);
    return(
        <CommonWrapper>
            <HeroWrapper bgWhite beforeImage={upTriangle}>
                <ContentRenderer
                    textAlign="center"
                    color="primary"
                    source={intl.formatMessage({
                        id: 'auth.about.hero.title',
                    })}
                />
            </HeroWrapper>
            <AboutContentWrapper bgWhite>
                <Container>
                    <ContentRenderer
                        textAlign="left"
                        color="secondary"
                        source={intl.formatMessage({
                            id: 'auth.about.hero.content',
                        })}
                    />
                </Container>
                <AboutImageWrapper>
                    <AboutImage src={aboutImage} />
                </AboutImageWrapper>
            </AboutContentWrapper>
            <Separator />
            <Container bgColor={backgroundColor}>
                <ContentRenderer
                    textAlign="center"
                    color="light"
                    source={intl.formatMessage({
                        id: 'auth.about.intro.title',
                    })}
                />
                <ListContainer>
                    <ContentRenderer
                        textAlign="left"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.about.intro.list.first',
                        })}
                    />
                    <ContentRenderer
                        textAlign="left"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.about.intro.list.second',
                        })}
                    />
                </ListContainer>
                <FlexWrapper gap={15}>
                    <ContentRenderer
                        textAlign="center"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.about.intro.text',
                        })}
                    />
                    <ButtonWrapper width={310} >
                        <LinkButton to={HOME_PATH} >
                            <FormattedMessage id="auth.about.intro.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                    <ContentRenderer
                        textAlign="center"
                        color="light"
                        source={intl.formatMessage({
                                id: 'auth.about.intro.note',
                            },
                        )}
                    />
                </FlexWrapper>

                <FlexWrapper>
                    <TitleWrapper>
                        <ContentRenderer
                            textAlign="center"
                            color="light"
                            source={intl.formatMessage({
                                id: 'auth.about.ourMission.title',
                            })}
                        />
                    </TitleWrapper>

                    <ContentRenderer
                        textAlign="center"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.about.ourMission.content',
                        })}
                    />
                </FlexWrapper>
            </Container>
            <Separator />
            <LaunchWrapper bgWhite beforeImage={leftArrow} afterImage={rightArrow} width={196}>
                <ContentRenderer
                    textAlign="center"
                    color="secondary"
                    source={intl.formatMessage({
                        id: 'auth.signUp.launch.title',
                    })}
                />
                <ContentRenderer
                    textAlign="center"
                    color="secondary"
                    source={intl.formatMessage({
                        id: 'auth.signUp.launch.content',
                    })}
                />
                <br/>
                <H3 color="secondary" fontWeight={700} textAlign="center">
                    <Image src={LOGO_ONLY_EMPTY} width={32} height={32} imageFluid={false} />
                    {' '}
                    <FormattedMessage id='auth.signUp.launch.logo' />
                </H3>
                <ButtonWrapper width={310} marginY={32} marginX={1}>
                    <LinkButton to={"/"} >
                        <FormattedMessage id="auth.signUp.launch.button" />
                    </LinkButton>
                </ButtonWrapper>
            </LaunchWrapper>
        </CommonWrapper>
    )
}

export default AboutPageRoute;