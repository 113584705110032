import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDraggable } from 'react-use-draggable-scroll';
import { usePrevious } from 'baseline-ui/helpers';
import Fade from './Fade';
import Main from './Main';
import Inner from './Inner';
import Wrap from './Wrap';
import ScrollAction from './ScrollAction';
import Content from './Content';

const HorizontalScroll = ({
    children,
    fade,
    fadeColor,
    fadeWidth,
    scrollButton,
    scrollBtnWidth,
    scrollButtonColor,
    scrollIconColor,
    scrollIconSize,
    forceScrollTo,
    currentContentWidth,
    showScrollbar,
}) => {
    const containerEl = useRef();
    const scrollableEl = useRef();
    const childWrapEl = useRef();
    const contentWrapEl = useRef();
    const { events } = useDraggable(scrollableEl);

    const prevCurrentContentWidth = usePrevious(currentContentWidth);
    const prevForceScrollTo = usePrevious(forceScrollTo);

    const [scrollStart, setScrollStart] = useState(true);
    const [scrollEnd, setScrollEnd] = useState(false);
    const [showEndButton, setShowEndButton] = useState(false);
    const [contentWidth, setContentWidth] = useState();

    const handleScrollStart = () => {
        let scrollableBack = 0;

        if (scrollableEl && scrollableEl.current) {
            scrollableBack =
                -scrollableEl.current.scrollLeft + containerEl.current.scrollWidth / 1.5;
            scrollableEl.current.scrollTo({
                top: 0,
                left: -scrollableBack,
                behavior: 'smooth',
            });
        }
    };

    const handleScrollEnd = () => {
        let scrollableForward = 0;

        if (scrollableEl && scrollableEl.current) {
            scrollableForward =
                scrollableEl.current.scrollLeft + containerEl.current.scrollWidth / 1.5;
            scrollableEl.current.scrollTo({
                top: 0,
                left: scrollableForward,
                behavior: 'smooth',
            });
        }
    };

    const checkEndButton = (scrollBtnWidthSize, contentWidthSize) => {
        const containerLeft = containerEl.current.getBoundingClientRect().left;
        const parentWidth = childWrapEl.current.getBoundingClientRect().width;

        const childLeft = Math.floor(childWrapEl.current.firstChild.getBoundingClientRect().left);
        const childWidth = Math.floor(childWrapEl.current.firstChild.getBoundingClientRect().width);

        const childEnd =
            childWrapEl.current.offsetWidth < childWrapEl.current.firstChild.offsetWidth &&
            parentWidth - childWidth + scrollBtnWidthSize < childLeft - containerLeft - 1 &&
            contentWidthSize > parentWidth;

        return childEnd;
    };

    const updateDimensions = () => {
        const initialContentWidth = contentWrapEl.current.getBoundingClientRect().width;
        if (childWrapEl && childWrapEl.current && childWrapEl.current.firstChild) {
            setShowEndButton(
                checkEndButton(scrollBtnWidth, currentContentWidth || initialContentWidth),
            );
        }
    };

    useEffect(() => {
        if (contentWrapEl && contentWrapEl.current) {
            const initialContentWidth = contentWrapEl.current.getBoundingClientRect().width;
            setContentWidth(initialContentWidth);
            setShowEndButton(checkEndButton(scrollBtnWidth, initialContentWidth));
        }
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        if (prevCurrentContentWidth !== currentContentWidth) {
            setContentWidth(currentContentWidth);
            setShowEndButton(checkEndButton(scrollBtnWidth, currentContentWidth));
        }

        if (prevForceScrollTo !== forceScrollTo) {
            scrollableEl.current.scrollTo({
                top: 0,
                left: forceScrollTo,
                behavior: 'smooth',
            });
        }
    }, [prevCurrentContentWidth, currentContentWidth, prevForceScrollTo, forceScrollTo]);

    const handleScroll = (e) => {
        const start = e.target.scrollLeft === 0;
        const end = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;

        setScrollStart(start);
        setScrollEnd(end);
        setShowEndButton(checkEndButton(scrollBtnWidth, currentContentWidth || contentWidth));
    };

    return (
        <Main scrollButton={scrollButton} btnWidth={scrollBtnWidth} ref={containerEl}>
            {fade && !scrollStart && <Fade showStart fadeColor={fadeColor} fadeWidth={fadeWidth} />}
            {fade && !scrollEnd && <Fade showEnd fadeColor={fadeColor} fadeWidth={fadeWidth} />}
            {scrollButton && !scrollStart && (
                <ScrollAction
                    showStart
                    btnWidth={scrollBtnWidth}
                    handleClick={handleScrollStart}
                    btnColor={scrollButtonColor}
                    iconColor={scrollIconColor}
                    iconSize={scrollIconSize}
                />
            )}
            {scrollButton && showEndButton && (
                <ScrollAction
                    showEnd
                    btnWidth={scrollBtnWidth}
                    handleClick={handleScrollEnd}
                    btnColor={scrollButtonColor}
                    iconColor={scrollIconColor}
                    iconSize={scrollIconSize}
                />
            )}
            <Inner
                onScroll={handleScroll}
                ref={scrollableEl}
                forceScrollTo={forceScrollTo}
                showScrollbar={showScrollbar}
                {...events}
            >
                <Wrap ref={childWrapEl}>
                    <Content ref={contentWrapEl}>{children}</Content>
                </Wrap>
            </Inner>
        </Main>
    );
};

HorizontalScroll.propTypes = {
    children: PropTypes.node.isRequired,
    fade: PropTypes.bool,
    fadeColor: PropTypes.string,
    fadeWidth: PropTypes.number,
    scrollButton: PropTypes.bool,
    scrollBtnWidth: PropTypes.number,
    scrollButtonColor: PropTypes.string,
    scrollIconColor: PropTypes.string,
    scrollIconSize: PropTypes.number,
    forceScrollTo: PropTypes.number,
    currentContentWidth: PropTypes.number,
    showScrollbar: PropTypes.bool,
};

HorizontalScroll.defaultProps = {
    fade: true,
    fadeColor: undefined,
    fadeWidth: undefined,
    scrollButton: false,
    scrollBtnWidth: 40,
    scrollButtonColor: undefined,
    scrollIconColor: undefined,
    scrollIconSize: undefined,
    forceScrollTo: 0,
    currentContentWidth: undefined,
    showScrollbar: true,
};

export default HorizontalScroll;
