import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewDescriptionContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: ${rem(8)};
    width: 100%;
`;

export default FavePagePreviewDescriptionContainer;
