import styled from 'styled-components';
import collapsableButtonStyles from '../../styles/collapsableButtonStyles';

const Item = styled.button.attrs(() => ({
    type: 'button',
}))`
    ${collapsableButtonStyles}

    padding: 1rem;
    width: 100%;

    &:focus {
        outline: 0;
    }
`;

export default Item;
