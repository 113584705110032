import React from 'react';
import { isEmpty } from 'lodash';
import { filterAllActiveProductsSelector } from 'redux/selectors/sectionSelectors';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import ProductsAndServicesListItem from './ProductsAndServicesListItem';
import ProductsAndServicesFilteredListEmptyResults from './ProductsAndServicesFilteredListEmptyResults';

const ProductsAndServicesFilteredListResults = () => {
    const { values } = useFormikContext();
    const { filterProducts } = values;
    const filterAllActiveProducts = useSelector(filterAllActiveProductsSelector(filterProducts));

    if (!isEmpty(filterProducts) && isEmpty(filterAllActiveProducts)) {
        return <ProductsAndServicesFilteredListEmptyResults />;
    }

    return (
        !isEmpty(filterAllActiveProducts) &&
        filterAllActiveProducts.map((product) => {
            const { _id: id } = product;
            return <ProductsAndServicesListItem key={id} type="items" product={product} />;
        })
    );
};

export default ProductsAndServicesFilteredListResults;
