import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../baseline-ui/modal';
import { useIntl } from 'react-intl';
import AdminBusinessAdvertisingCategories
    from '../../favePageMain/admin/AdminBusinessStatus/AdminBusinessAdvertising/AdminBusinessAdvertisingCategories';

// eslint-disable-next-line react/prop-types
const ModalAddCategory = ({ categories, onSubmit, onRequestClose, ...props }) => {
    const intl = useIntl();

    const [selectedCategories, setSelectedCategories] = useState(categories || []);

    return (
        <Modal
            title="TEST"
            size="lg"
            handleOnDismiss={onRequestClose}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.addedFilters.footerAction.addFilters.notNow.button.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => onRequestClose(),
                    type: 'button',
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.addedFilters.footerAction.addFilters.done.button.label',
                    }),
                    onClick: () => {
                        onSubmit(selectedCategories);
                        onRequestClose();
                    },
                    type: 'submit',
                },
            ]}
            showCloseButton={false}
            {...props}
        >
            <AdminBusinessAdvertisingCategories
                selectedCategories={selectedCategories}
                onSubmit={data => setSelectedCategories(data)}
            />
        </Modal>
    );
};

ModalAddCategory.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default ModalAddCategory;
