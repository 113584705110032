import styled from 'styled-components';

const FavePageAsideMyBusinessHorizontalScrollContainer = styled.div`
    background-color: ${({ theme }) => theme.site.admin.bgStart};
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    left: 0;
    right: 0;
    position: fixed;
    width: 100vw;
    z-index: ${({ theme }) => theme.header.zIndex - 2};
`;

export default FavePageAsideMyBusinessHorizontalScrollContainer;
