import { baseTheme } from 'baseline-ui/base-theme';

const alertTheme = {
    alert: {
        borderRadius: baseTheme.base.borderRadius.default,
        alertFeedback: {
            icon: {
                size: 40,
            },
            backgroundColor: baseTheme.base.colors.light,
        },
    },
};

export default alertTheme;
