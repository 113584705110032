import { rem } from 'polished';
import styled from 'styled-components';

const PhotoListItemImageContainer = styled.div`
    cursor: grab;
    user-select: none;
    height: ${rem(160)};
    width: ${rem(160)};
`;

export default PhotoListItemImageContainer;
