import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import Heading6 from './Heading6';

function H6({ color, className, children, isUppercase, isBaseFont, textAlign, as }) {
    return (
        <Heading6
            className={className}
            colorValue={color}
            isUppercase={isUppercase}
            isBaseFont={isBaseFont}
            textAlign={textAlign}
            as={as}
        >
            {children}
        </Heading6>
    );
}

H6.propTypes = {
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    isUppercase: PropTypes.bool,
    children: PropTypes.node,
    isBaseFont: PropTypes.bool,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    as: PropTypes.string,
};

H6.defaultProps = {
    color: 'dark',
    className: null,
    isUppercase: false,
    children: null,
    isBaseFont: false,
    textAlign: undefined,
    as: undefined,
};

export default H6;
