import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { SET_PRODUCT_TO_EDIT_START } from 'redux/actions/EditProductActions';
import { OverlayContext } from 'baseline-ui/overlay';
import ProductsAndServicesListItemContainer from './ProductsAndServicesListItemContainer';
import ModalEditProduct from '../../modalProduct/ModalEditProduct';
import ProductsAndServicesListItemSectionTags from './ProductsAndServicesListItemSectionTags';
import ProductsAndServicesListItemHeader from './ProductsAndServicesListItemHeader';

const ProductsAndServicesListItem = ({ type, product }) => {
    const { showOverlay } = useContext(OverlayContext);
    const { name, _id: id, sectionsFoundIn, totalFaves } = product;
    const dispatch = useDispatch();

    const [{ opacity }, drag] = useDrag(() => {
        return {
            type,
            item: { id },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.6 : 1,
            }),
        };
    }, [product]);

    return (
        <ProductsAndServicesListItemContainer
            key={name}
            ref={drag}
            style={{ opacity }}
            onClick={() => {
                dispatch({
                    type: SET_PRODUCT_TO_EDIT_START,
                    payload: id,
                });
                showOverlay(ModalEditProduct);
            }}
        >
            <ProductsAndServicesListItemHeader name={name} totalFaves={totalFaves} />
            <ProductsAndServicesListItemSectionTags product={product} sections={sectionsFoundIn} />
        </ProductsAndServicesListItemContainer>
    );
};

ProductsAndServicesListItem.propTypes = {
    type: PropTypes.string.isRequired,
    product: PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
        totalFaves: PropTypes.number,
        sectionsFoundIn: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
            }),
        ),
    }).isRequired,
};

export default ProductsAndServicesListItem;
