/* eslint-disable react/prop-types */
import React, { useState, memo, useContext, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import SortableList from 'react-easy-sort';
import arrayMove from 'array-move';
import '@pqina/pintura/pintura.css';
import { Button } from 'baseline-ui/button';
import PhotoListSortableList from 'components/photoList/PhotoListSortableList';
import { Col, Row } from 'baseline-ui/layout';
import { P, Small } from 'baseline-ui/typography';
import {FormattedMessage, useIntl} from 'react-intl';
import PhotoListContainer from 'components/photoList/PhotoListContainer';
import PhotoListSortable from 'components/photoList/PhotoListSortable';
import { FeedbackContext } from 'baseline-ui/feedback';
import { ModalContext } from 'baseline-ui/modal';
import ModalPhotoConfirmDelete from 'components/photoList/ModalPhotoConfirmDelete';
import fileUploaderUtils from 'utils/fileUploaderUtils';
import { isEmpty } from 'lodash';
import {
    BUSINESS_PHOTOS_FILE_TYPES,
    BUSINESS_PHOTOS_MAX_COUNT,
    CONTEXT_EVENT,
    EVENT_PHOTOS_MAX_COUNT,
    PHOTO_STATE_DELETED,
} from 'common/consts';
import FileUploaderContainer from './FileUploaderContainer';
import InfoModal from '../InfoModal';

/**
 * This registers the plugins with Pintura Image Editor
 */
fileUploaderUtils.setPlugins();

const ManagePhotos = ({ photos, setPhotos, error, isInOverlay, context }) => {
    const intl = useIntl();
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const { showModal } = useContext(ModalContext);
    const [deletePhoto, setDeletePhoto] = useState();
    const disableFileUploader =
        fileUploaderUtils.currentPhotosCount(photos) === (context === CONTEXT_EVENT ? EVENT_PHOTOS_MAX_COUNT : BUSINESS_PHOTOS_MAX_COUNT) ;

    useEffect(() => {
        if (deletePhoto != null) {
            deletePhoto.state = PHOTO_STATE_DELETED;

            // Find this photo, move to the end
            let idx = -1;
            for (let i = 0; i < photos.length; i += 1) {
                if (photos[i].name === deletePhoto.name) {
                    idx = i;
                    break;
                }
            }

            if (idx !== -1) {
                setPhotos(arrayMove(photos, idx, photos.length + 1));
            }
        }
    }, [deletePhoto]);

    useEffect(() => {
        if (error) {
            setFeedbackOnStage({ label: error, type: 'danger', show: true, timeout: 4000 });
        }
    }, [error]);

    return (
        <>
            <FileUploaderContainer>
                <FileUploader
                    handleChange={(files) =>{
                        if (files?.length > 1) {
                            return showModal(InfoModal,{
                                title:intl.formatMessage({
                                    id:  'fileUpload.modal.limit.exceeded.title'
                                }),
                                message: intl.formatMessage({
                                    id:  'fileUpload.modal.limit.exceeded.message'
                                })
                            });
                        }
                        fileUploaderUtils.handleFileUpload(files, photos, setPhotos);
                    }

                    }
                    name="file"
                    multiple={true}
                    disabled={disableFileUploader}
                    types={BUSINESS_PHOTOS_FILE_TYPES}
                >
                    <Button btnType="optional" size="md" icon="image" type="button">
                        <FormattedMessage id="addBusiness.page.sections.photos.fileUploader.label" />
                    </Button>
                </FileUploader>
            </FileUploaderContainer>

            {!isEmpty(fileUploaderUtils.currentPhotoList(photos)) && (
                <PhotoListContainer>
                    <Row justifyContent="space-between">
                        <Col>
                            <P lead>
                                <FormattedMessage id="addBusiness.page.sections.photos.list.title" />
                            </P>
                        </Col>
                        <Col>
                            <P lead color="muted">
                                <Small>
                                    <FormattedMessage
                                        id="addBusiness.page.sections.photos.list.count.label"
                                        values={{
                                            count: fileUploaderUtils.currentPhotosCount(photos),
                                            maxCount: context === CONTEXT_EVENT ? EVENT_PHOTOS_MAX_COUNT : BUSINESS_PHOTOS_MAX_COUNT,
                                        }}
                                    />
                                </Small>
                            </P>
                        </Col>
                    </Row>
                    <SortableList
                        as={PhotoListSortableList}
                        onSortEnd={(oldIndex, newIndex) =>
                            fileUploaderUtils.onSortEnd({ photos, setPhotos, oldIndex, newIndex })
                        }
                    >
                        <PhotoListSortable
                            isInOverlay={isInOverlay}
                            photos={fileUploaderUtils.currentPhotoList(photos)}
                            onClickDelete={(photo) => {
                                showModal(ModalPhotoConfirmDelete, {
                                    confirmDeleteCallback: () => setDeletePhoto(photo),
                                    context,
                                });
                            }}
                            onClickEdit={(photo, index) => {
                                fileUploaderUtils.editImage(photo.preview || photo.url, (output) =>
                                    fileUploaderUtils.editImageDoneCallback({
                                        photos,
                                        setPhotos,
                                        index,
                                        output,
                                    }),
                                );
                            }}
                        />
                    </SortableList>
                </PhotoListContainer>
            )}
        </>
    );
};
export default memo(ManagePhotos, (prevProps, newProps) => {
    return prevProps.photos === newProps.photos && prevProps.error === newProps.error;
});
