import {
    PUT_REQUESTTOLINK_SUCCESS,
    PUT_REQUESTTOLINK_ERROR,
    RESET_REQUESTTOLINK_START,
} from "../actions/RequestToLink";

/**
 * Initial state.
 */
const initiaState = {
    requestId: '',
    status: '',
    code: '',
};

/**
 * Entity Picker reducer.
 */
const RequestToLinkReducer = (state = {}, action = {}) => {
   const { type, payload } = action;
    switch (type) {
        case RESET_REQUESTTOLINK_START: {
            return {
                ...state,
                ...initiaState
            };
        }
        case PUT_REQUESTTOLINK_ERROR: {
            return {
                ...state,
                requestId: '',
                code: '',
                ...payload,
                status: 'ERROR'
            };
        }
        case PUT_REQUESTTOLINK_SUCCESS: {
            return {
                ...state,
                code: '',
                ...payload,
                status: 'SUCCESS'
            };
        }     
        default: {
            return state;
        }
    }
};
export default RequestToLinkReducer;
