import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { EntityPickerImage } from './EntityPickerImage';
import { EntityPickerData } from './components';

export const EntityPickerContent = ({ entity }) => {
    const image = entity.photos?.[0]?.url ?? '';
    return (
        <>
            <EntityPickerImage image={image} type={entity.type} />
            <EntityPickerData>
                <div><strong>{entity.name}</strong></div>
                <div>{entity.formattedAddress}</div>
            </EntityPickerData>
        </>
    );
}
EntityPickerContent.propTypes = {
    entity: PropTypes.object.isRequired,
};
