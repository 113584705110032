import React from 'react';
import PropTypes from 'prop-types';
import HeaderOffsetContainer from './HeaderOffsetContainer';

const HeaderOffset = ({ isFullHeight, isBlurred, bgStart, bgEnd, children, isVertical }) => {
    return (
        <HeaderOffsetContainer
            bgStart={bgStart}
            bgEnd={bgEnd}
            isFullHeight={isFullHeight}
            isBlurred={isBlurred}
            isVertical={isVertical}
        >
            {children}
        </HeaderOffsetContainer>
    );
};

HeaderOffset.propTypes = {
    bgStart: PropTypes.string,
    bgEnd: PropTypes.string,
    isFullHeight: PropTypes.bool,
    isBlurred: PropTypes.bool,
    children: PropTypes.node,
    isVertical: PropTypes.bool,
};

HeaderOffset.defaultProps = {
    bgStart: undefined,
    bgEnd: undefined,
    isFullHeight: false,
    isBlurred: false,
    children: undefined,
    isVertical: false,
};

export default HeaderOffset;
