import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import Container from './Container';

const ProgressiveBg = ({ image, imageLow, imagePos, bgColor, style, children, className }) => {
    return (
        <ProgressiveImage src={image} placeholder={imageLow} className={className}>
            {(src) => {
                return (
                    <Container
                        delay={200}
                        style={{
                            ...style,
                            backgroundImage: `${
                                src && src !== null ? `url(${src})` : style.backgroundImage
                            }`,
                            backgroundPosition: imagePos,
                            backgroundColor: bgColor,
                        }}
                    >
                        {children}
                    </Container>
                );
            }}
        </ProgressiveImage>
    );
};

ProgressiveBg.propTypes = {
    /** url for the image */
    image: PropTypes.string,
    /** url for the low quality image */
    imageLow: PropTypes.string,
    /** takes default css positions see https://developer.mozilla.org/en-US/docs/Web/CSS/background-position */
    imagePos: PropTypes.string,
    /** background color of the image container */
    bgColor: PropTypes.string,
    /** inline styles */
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /** content  */
    children: PropTypes.node,
    className: PropTypes.string,
};

ProgressiveBg.defaultProps = {
    image: null,
    imageLow: null,
    imagePos: 'center center',
    bgColor: null,
    style: {},
    children: null,
    className: undefined,
};

export default ProgressiveBg;
