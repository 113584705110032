import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const Tablet = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const minWidth = get(theme, 'layout.media.xs', layoutTheme.layout.media.xs);
    const maxWidth = get(theme, 'layout.media.md', layoutTheme.layout.media.md);

    return <Responsive {...props} minWidth={minWidth} maxWidth={parseInt(maxWidth, 10) - 1} />;
};

// displayName for storybook
Tablet.displayName = 'Tablet';

const Hoc = Tablet;
Hoc.displayName = Tablet.displayName;
export default Hoc;
