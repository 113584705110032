export const GET_SUGGESTIONS_PROCESS = "GET_SUGGESTIONS_PROCESS";
export const GET_SUGGESTIONS_START = "GET_SUGGESTIONS_START";
export const GET_SUGGESTIONS_SUCCESS = "GET_SUGGESTIONS_SUCCESS";

export const SET_SUGGESTIONS_NEXT_PAGE = "SET_SUGGESTIONS_NEXT_PAGE";
export const SET_SUGGESTIONS_PREV_PAGE = "SET_SUGGESTIONS_PREV_PAGE";
export const RESET_SUGGESTIONS_NEXT_PAGE = "RESET_SUGGESTIONS_NEXT_PAGE";

export const SET_SUGGESTIONS_STATE_FILTER = "SET_SUGGESTIONS_STATE_FILTER";
export const SET_SUGGESTIONS_SORT_FIELD_FILTER =
  "SET_SUGGESTIONS_SORT_FIELD_FILTER";
export const SET_SUGGESTIONS_SORT_DIRECTION_FILTER =
  "SET_SUGGESTIONS_SORT_DIRECTION_FILTER";
