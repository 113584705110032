import React from 'react';
import PropTypes from 'prop-types';
import { TransitionItem } from 'baseline-ui/helpers';
import { H4, H5 } from 'baseline-ui/typography';
import Container from './Container';
import authPropTypes from '../../prop-types/authPropTypes';

const OneMoreStepMessage = ({ oneMoreStep, oneMoreStepMessage }) => {
    if (!oneMoreStepMessage) {
        return null;
    }

    const { title, subtitle, body } = oneMoreStepMessage;

    return (
        <TransitionItem inProp={oneMoreStep} appear mountOnEnter>
            <Container>
                {title && <H4 color="muted">{title}</H4>}
                {subtitle && <H5 color="muted">{subtitle}</H5>}
                {body}
            </Container>
        </TransitionItem>
    );
};

OneMoreStepMessage.propTypes = {
    oneMoreStep: PropTypes.bool,
    oneMoreStepMessage: authPropTypes.authOneMoreStepMessage,
};

OneMoreStepMessage.defaultProps = {
    oneMoreStep: false,
    oneMoreStepMessage: undefined,
};

export default OneMoreStepMessage;
