import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import EventList from './EventsList';
import EventInformation from 'screens/Events/EventInformation';
import {GET_EVENT_START} from 'redux/actions/EventActions';
import {useDispatch} from 'react-redux';

const EventsTabs = () => {
    const [value, setValue] = useState(0);
    const [event, setEvent] = useState(null);
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setEvent(event);
    };
    const handleFetchEventDetails = (event) => {
       dispatch({
            type: GET_EVENT_START,
            payload: event,
        });
        setEvent(event);
        setValue(0);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="event tabs"
                >
                    <Tab label="Add Event" />
                    <Tab label="Event List" />
                </Tabs>
            </Box>
            <Box>
                {value === 0 && (
                    <EventInformation eventDetails={event} />
                )}
                {value === 1 && <EventList onFetchEventDetails={handleFetchEventDetails} />}
            </Box>
        </>
    );
};

export default EventsTabs;
