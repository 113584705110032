import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { lighten, transparentize, rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import { buttonTheme } from 'baseline-ui/button';
import { typographyTheme } from 'baseline-ui/typography';
import { BUTTON_GROUP_LABEL_MARGIN } from '../../constants/formConstants';

const borderRadius = ({ theme, buttonSize }) =>
    rem(
        get(
            theme,
            `button.borderRadius[${buttonSize}]`,
            buttonTheme.button.borderRadius[buttonSize],
        ),
    );

const FormCheckboxButtonLabel = styled.label`
    align-items: center;
    background-color: transparent;
    border-color: ${({ theme }) => get(theme, 'base.colors.muted', baseTheme.base.colors.muted)};
    border-style: solid;
    border-width: 1px;
    color: ${({ theme }) => get(theme, 'base.colors.muted', baseTheme.base.colors.muted)};
    cursor: pointer;
    display: inline-flex;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-weight: ${({ theme }) => get(theme, 'button.fontWeight', buttonTheme.button.fontWeight)};
    font-size: ${({ theme, buttonSize }) =>
        rem(get(theme, `button.fontSize[${buttonSize}]`, buttonTheme.button.fontSize[buttonSize]))};
    margin: 0;
    padding-bottom: ${({ theme, buttonSize }) =>
        rem(
            get(theme, `button.padding[${buttonSize}].v`, buttonTheme.button.padding[buttonSize].v),
        )};
    padding-left: ${({ theme, buttonSize }) =>
        rem(
            get(theme, `button.padding[${buttonSize}].h`, buttonTheme.button.padding[buttonSize].h),
        )};
    padding-right: ${({ theme, buttonSize }) =>
        rem(
            get(theme, `button.padding[${buttonSize}].h`, buttonTheme.button.padding[buttonSize].h),
        )};
    padding-top: ${({ theme, buttonSize }) =>
        rem(
            get(theme, `button.padding[${buttonSize}].v`, buttonTheme.button.padding[buttonSize].v),
        )};
    position: relative;
    text-align: center;
    transition:
        background-color 0.15s ease-in-out,
        color 0.15s ease-in-out,
        border-radius 0.15s ease-in-out;
    user-select: none;

    ${({ theme, checked, disabledValue, labelOnly }) =>
        checked === false &&
        disabledValue === false &&
        labelOnly === false &&
        css`
            &:hover {
                border-color: ${lighten(
                    0.1,
                    get(theme, 'base.colors.muted', baseTheme.base.colors.muted),
                )};
                color: ${lighten(
                    0.1,
                    get(theme, 'base.colors.muted', baseTheme.base.colors.muted),
                )};
            }
            &:focus,
            &:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }
        `}

    ${({ theme, buttonType }) =>
        buttonType === 'pointer' &&
        css`
            background-color: transparent;
            border: 0;
            color: ${get(theme, 'base.colors.muted', baseTheme.base.colors.muted)};
            cursor: pointer;
            position: relative;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;

            &:after {
                border-left: 0 solid transparent;
                border-right: 0 solid transparent;
                border-top: 0 solid transparent;
                bottom: 0;
                content: '';
                left: 50%;
                opacity: 0;
                position: absolute;
                transform: translateX(-50%);
                transition:
                    opacity 0.3s ease-in-out,
                    bottom 0.3s ease-in-out,
                    border 0.3s ease-in-out;
            }

            &:hover {
                color: ${lighten(
                    0.1,
                    get(theme, 'base.colors.muted', baseTheme.base.colors.muted),
                )};
            }

            &:focus,
            &:active {
                box-shadow: none;
            }
        `}

    
    ${({ buttonType, buttonSize, theme }) =>
        buttonType === 'default' &&
        css`
            margin-left: -1px;

            &:first-of-type {
                border-bottom-left-radius: ${borderRadius({ buttonSize, theme })};
                border-left-width: 1px;
                border-top-left-radius: ${borderRadius({ buttonSize, theme })};
                margin-left: 0;
            }

            &:last-of-type {
                border-bottom-right-radius: ${borderRadius({ buttonSize, theme })};
                border-top-right-radius: ${borderRadius({ buttonSize, theme })};
            }
        `}
    
    ${({ buttonType, buttonSize, theme }) =>
        buttonType === 'pills' &&
        css`
            border-radius: ${borderRadius({ buttonSize, theme })};
            margin: ${rem(BUTTON_GROUP_LABEL_MARGIN)};
        `}
    
    ${({ theme, buttonType, buttonSkin, buttonSize }) =>
        buttonType === 'bubble' &&
        css`
            border-color: ${get(
                theme,
                `button[${buttonSkin}].background`,
                buttonTheme.button[buttonSkin].background,
            )};
            border-radius: ${borderRadius({ buttonSize, theme })};
            border-width: 2px;

            color: ${get(
                theme,
                `button[${buttonSkin}].background`,
                buttonTheme.button[buttonSkin].background,
            )};
            margin: ${rem(BUTTON_GROUP_LABEL_MARGIN)};
            text-transform: uppercase;

            &:hover {
                border-color: ${lighten(
                    0.1,
                    get(
                        theme,
                        `button[${buttonSkin}].background`,
                        buttonTheme.button[buttonSkin].background,
                    ),
                )};
                color: ${lighten(
                    0.1,
                    get(
                        theme,
                        `button[${buttonSkin}].background`,
                        buttonTheme.button[buttonSkin].background,
                    ),
                )};
            }
        `}

    ${({ theme, disabledValue, buttonType, buttonSkin }) =>
        disabledValue &&
        buttonType === 'bubble' &&
        css`
            border-color: ${lighten(
                0.2,
                get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                ),
            )};
            color: ${lighten(
                0.2,
                get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                ),
            )};
            cursor: not-allowed;
        `}

    ${({ buttonSize, theme }) =>
        buttonSize === 'sm' &&
        css`
            font-weight: ${get(
                theme,
                'typography.strong.fontWeight',
                typographyTheme.typography.strong.fontWeight,
            )};
            text-transform: uppercase;
        `}

    /* Checked */

    ${({ theme, labelOnly, checked, buttonSkin }) =>
        labelOnly === false &&
        checked &&
        css`
            &,
            &:hover {
                background-color: ${get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                )};
                border-color: ${get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                )};
                color: ${get(
                    theme,
                    `button[${buttonSkin}].color`,
                    buttonTheme.button[buttonSkin].color,
                )};
            }
            z-index: 2;
        `}

    ${({ theme, buttonType, labelOnly, checked, buttonSkin }) =>
        buttonType === 'pointer' &&
        labelOnly === false &&
        checked &&
        css`
            background-color: ${get(
                theme,
                `button[${buttonSkin}].background`,
                buttonTheme.button[buttonSkin].background,
            )};
            color: ${get(
                theme,
                `button[${buttonSkin}].color`,
                buttonTheme.button[buttonSkin].color,
            )};
            text-decoration: none;

            &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid
                    ${get(
                        theme,
                        `button[${buttonSkin}].background`,
                        buttonTheme.button[buttonSkin].background,
                    )};
                bottom: -6px;
                opacity: 1;
            }
        `}

    
    /* Disabled */
    
    ${({ theme, disabledValue, labelOnly, buttonType }) =>
        disabledValue &&
        labelOnly === false &&
        buttonType !== 'bubble' &&
        css`
            &,
            &:hover {
                border-color: ${lighten(
                    0.2,
                    get(theme, 'base.colors.muted', baseTheme.base.colors.muted),
                )};
                color: ${lighten(
                    0.2,
                    get(theme, 'base.colors.muted', baseTheme.base.colors.muted),
                )};
            }
            cursor: not-allowed;
        `}

    

    ${({ theme, disabledValue, checked, labelOnly, buttonSkin }) =>
        disabledValue &&
        checked &&
        labelOnly === false &&
        css`
            &,
            &:hover {
                background-color: ${lighten(
                    0.2,
                    get(
                        theme,
                        `button[${buttonSkin}].background`,
                        buttonTheme.button[buttonSkin].background,
                    ),
                )};
                border-color: ${lighten(
                    0.2,
                    get(
                        theme,
                        `button[${buttonSkin}].background`,
                        buttonTheme.button[buttonSkin].background,
                    ),
                )};
                color: ${lighten(
                    0.1,
                    get(theme, `button[${buttonSkin}].color`, buttonTheme.button[buttonSkin].color),
                )};
            }
            cursor: not-allowed;
        `}

    ${({ theme, disabledValue, labelOnly, checked, buttonType, buttonSkin }) =>
        disabledValue &&
        labelOnly === false &&
        checked &&
        buttonType === 'pointer' &&
        css`
            &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid
                    ${lighten(
                        0.2,
                        get(
                            theme,
                            `button[${buttonSkin}].background`,
                            buttonTheme.button[buttonSkin].background,
                        ),
                    )};
                bottom: -6px;
                opacity: 1;
            }
        `}

    
    /* Label Only */

    ${({ theme, labelOnly }) =>
        labelOnly &&
        css`
            &,
            &:hover {
                background-color: transparent;
                border-color: transparent;
                color: ${get(theme, 'base.colors.muted', baseTheme.base.colors.muted)};
            }
            font-weight: ${get(
                theme,
                'typography.strong.fontWeight',
                typographyTheme.typography.strong.fontWeight,
            )};
            text-transform: none;
        `}

    ${({ theme, labelOnly, checked, buttonSkin }) =>
        labelOnly &&
        checked &&
        css`
            &,
            &:hover {
                color: ${get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                )};
            }
        `}

    ${({ theme, disabledValue, labelOnly }) =>
        disabledValue &&
        labelOnly &&
        css`
            color: ${lighten(0.2, get(theme, 'base.colors.muted', baseTheme.base.colors.muted))};
            cursor: not-allowed;
        `}

    ${({ theme, disabledValue, checked, labelOnly, buttonSkin }) =>
        disabledValue &&
        checked &&
        labelOnly &&
        css`
            color: ${transparentize(
                0.2,
                get(
                    theme,
                    `button[${buttonSkin}].background`,
                    buttonTheme.button[buttonSkin].background,
                ),
            )};
        `}

    /* List */

    ${({ buttonType, theme }) =>
        buttonType === 'list' &&
        css`
            border: 0;
            margin: ${rem(4)} 0;
            text-transform: uppercase;
            width: 100%;

            &:focus,
            &:active {
                box-shadow: none;
            }

            &:after {
                background-color: ${get(
                    theme,
                    'base.grays.grayLighter',
                    baseTheme.base.grays.grayLighter,
                )};
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                top: -${rem(4)};
                width: 100%;
            }

            &:first-of-type {
                &:after {
                    display: none;
                }
            }
        `}
        
   
    ${({ buttonType, theme, buttonSize, checked }) =>
        buttonType === 'list' &&
        checked &&
        css`
            &,
            &:hover {
                border-radius: ${borderRadius({ buttonSize, theme })};
            }
        `}
`;

export default FormCheckboxButtonLabel;
