import React from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const CustomDatePicker = ({ value, onChange, label, name, disabled, error, helperText, required }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={required ? `${label}*` : label}
                name={name}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => onChange(newValue)}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoComplete="off"
                        fullWidth
                        error={error}
                        helperText={helperText}
                        onChange={(newValue) => onChange(newValue)}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;