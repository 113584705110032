import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import headingStyles from './headingStyles';
import typographyTheme from './typographyTheme';
import typographyUtils from '../utils/typographyUtils';

const h1Styles = () => css`
    ${headingStyles}

    font-size: ${({ theme }) =>
        rem(
            get(
                theme,
                'typography.headings.fontSize.h1',
                typographyTheme.typography.headings.fontSize.h1,
            ),
        )};
    font-weight: ${({ theme }) =>
        get(
            theme,
            'typography.headings.fontWeight.h1',
            typographyTheme.typography.headings.fontWeight.h1,
        )};
    margin: ${({ theme }) => typographyUtils.headingMarginByPaddingRatio({ theme, heading: 'h1' })}
        0;
`;

export default h1Styles;
