import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_ITEM_DETAILS_PROCESS,
  GET_ITEM_DETAILS_START,
} from "../redux/actions/ItemActions";
import FavePage from "./FavePage";
import Carousel from "react-material-ui-carousel";
import insta_icon from "../images/insta_icon.png";
import fb_icon from "../images/fb_icon.png";
import tt_icon from "../images/tt_icon.png";
import other_icon from "../images/other_icon.png";
import { getMobileOperatingSystem } from "../common/utils";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../common/consts";

const LINK_TYPE_MAPPING = {
  instagram: insta_icon,
  twitter: tt_icon,
  other: other_icon,
  facebook: fb_icon,
};

const ItemPage = () => {
  const dispatch = useDispatch();
  const { id, whoShares } = useParams();
  const navigate = useNavigate();
  const processStep = useSelector(
    ({ ProcessReducer }) => ProcessReducer[GET_ITEM_DETAILS_PROCESS],
  );
  const isProcessLoading = processStep === "START";

  useEffect(() => {
    dispatch({
      type: GET_ITEM_DETAILS_START,
      payload: id,
    });
  }, [id]);
  const system = getMobileOperatingSystem();
  const { itemDetails } = useSelector(({ ItemReducer }) => ItemReducer);
  const { name, description, phone, email, photos, links } = get(
    itemDetails,
    ["business"],
    {},
  );

  return (
    <FavePage>
      {itemDetails && !isProcessLoading && (
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            justifyContent="center"
            alignItems="center"
            justify="center"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            {whoShares && system && (
              <Button
                variant="contained"
                sx={{ marginBottom: 3 }}
                onClick={() => {
                  if (system === "Android") {
                    window.open(ANDROID_APP_LINK);
                  }
                  if (system === "iOS") {
                    window.open(IOS_APP_LINK);
                  }
                }}
              >
                {whoShares} wants to share more faves with you
              </Button>
            )}
            {photos && (
              <Carousel
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {photos?.map((photo, i) => (
                  <Box
                    key={`photo_${photo.name}_${photo.order}`}
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <img src={photo.url} style={{ width: "100%" }} />
                  </Box>
                ))}
              </Carousel>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4" paragraph>
                {name}
              </Typography>
              <Typography variant="p" paragraph>
                {description}
              </Typography>
              {phone && (
                <Typography variant="p" paragraph>
                  <Link href={`tel:${phone}`}>{phone}</Link>
                </Typography>
              )}
              {email && (
                <Typography variant="p" paragraph>
                  <Link href={`mailto:${email}`}>{email}</Link>
                </Typography>
              )}
              {links?.map((link) => {
                const icon = LINK_TYPE_MAPPING[link.type];
                return (
                  <Button
                    key={`link_${link.id}`}
                    onClick={() => window.open(link.url, "_blank")}
                  >
                    <img src={icon} />
                  </Button>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      )}
    </FavePage>
  );
};

export default ItemPage;
