import React, { useContext, useEffect, useReducer, useMemo, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { produce } from 'immer';
import PageHeader from '../../../components/pageLayout/PageHeader';
import AdminDefaultTemplate from '../../../components/pageLayout/templates/AdminDefaultTemplate';
import CommonWrapper from '../../CommonWrapper';
import { GetTier } from '../../../api';
import { TierRoot } from './tierroot';
import {
    stateUpdateItem,
    stateRemoveItem,
    WithTiers,
    orderTiers,
    collapseItemAndChildren,
    stateFindFirst,
} from './helpers';
import { Button } from 'baseline-ui/button';

let uniqueId = 0;
function getNextId() {
    uniqueId += 1;
    return uniqueId;
}

/**
 * Tiers Manager component.
 * @returns The component.
 */
export function TiersManager() {
    const theme = useContext(ThemeContext);
    const intl = useIntl();
    const [tiers, dispatchTiers] = useReducer((state, { type, payload }) => {
        return produce(state, (draft) => {
            switch (type) {
                case 'init':
                    return orderTiers(payload);
                case 'collapseAllChildren': {
                    const { _id } = payload;
                    const startingPointItem = stateFindFirst(draft, _id);
                    collapseItemAndChildren([startingPointItem]);

                    return draft;
                }
                case 'expand': {
                    const { _id, expand } = payload;
                    return stateUpdateItem(draft, _id, (item) => {
                        const expanded = expand === undefined ? !item.expanded : expand;
                        return {
                            ...item,
                            expanded,
                        };
                    });
                }
                case 'remove': {
                    // This currently only removes new unsaved items.
                    const { _id } = payload;
                    return stateRemoveItem(draft, _id);
                }
                case 'addGroup': {
                    const { data } = payload;
                    return {
                        ...draft,
                        resources: [
                            ...draft.resources,
                            {
                                _id: `new:${getNextId()}`,
                                new: Date.now(),
                                editing: true,
                                parentid: 'ROOT',
                                type: 'group',
                                name: '',
                            },
                        ],
                    };
                }
                case 'add': {
                    const { _id, tierType, data } = payload;
                    return orderTiers(
                        stateUpdateItem(draft, _id, (item) => {
                            uniqueId += 1;
                            const newItem = {
                                _id: `new:${getNextId()}`,
                                parentid: _id,
                                new: Date.now(),
                                editing: true,
                                type: tierType,
                                name: '',
                            };
                            const updated = {
                                ...item,
                                resources: [newItem, ...item.resources],
                            };
                            return updated;
                        }),
                    );
                }
                case 'update': {
                    const { _id, data } = payload;
                    return stateUpdateItem(draft, _id, data);
                }
                case 'order': {
                    return orderTiers(state);
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.warn(`Unknown action type: ${type} with payload:`, payload);
                    break;
                }
            }
            return draft;
        });
    }, undefined);
    const withTiers = useMemo(() => {
        return new WithTiers(tiers, dispatchTiers);
    }, [tiers, dispatchTiers]);

    const [showHidden, setShowHidden] = useState(false);

    useEffect(() => {
        if (!tiers) {
            const effect = async () => {
                const [resources, err] = await GetTier({ showHidden });
                if (err) {
                    // eslint-disable-next-line no-console
                    console.error(`Failed to get tags:`, err);
                    return;
                }
                dispatchTiers({
                    type: 'init',
                    payload: {
                        type: 'ROOT',
                        expanded: true,
                        resources,
                    },
                });
            };
            effect();
        }
    }, [tiers, dispatchTiers]);

    useEffect(() => {
        const effect = async () => {
            const [resources, err] = await GetTier({ showHidden });
            if (err) {
                // eslint-disable-next-line no-console
                console.error(`Failed to get tags:`, err);
                return;
            }
            dispatchTiers({
                type: 'init',
                payload: {
                    type: 'ROOT',
                    expanded: true,
                    resources,
                },
            });
        };
        effect();
    }, [showHidden]);

    return (
        <CommonWrapper>
            <AdminDefaultTemplate
                bgEnd={theme.site.admin.bgEnd}
                bgStart={theme.site.admin.bgStart}
                menu={
                    <Button
                        onClick={() => {
                            setShowHidden(!showHidden);
                        }}
                    >
                        {showHidden ? 'Hide Hidden' : 'Show Hidden'}
                    </Button>
                }
                banner={
                    <PageHeader title={intl.formatMessage({ id: 'superAdmin.tiers.page.title' })} />
                }
                main={<TierRoot tiers={tiers} withTiers={withTiers} showHidden={showHidden} />}
            />
        </CommonWrapper>
    );
}

export default TiersManager;
