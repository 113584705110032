import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'baseline-ui/layout';
import { FormCheckbox } from 'baseline-ui/form';
import AdminBusinessAdvertisingAddItem from './AdminBusinessAdvertisingAddItem';
import AdminBusinessAdvertisingGroupEnablerWithCategory from './AdminBusinessAdvertisingGroupEnablerWithCategory';
import AdminBusinessAdvertisingCategoryAddCity from './AdminBusinessAdvertisingCategoryAddCity';
import AdminBusinessAdvertisingAddItemRowContainer from './AdminBusinessAdvertisingAddItemRowContainer';
import AdminBusinessAdvertisingCategoryOrder from './AdminBusinessAdvertisingCategoryOrder';
import AdminBusinessAdvertisingCategoryRotate from './AdminBusinessAdvertisingCategoryRotate';

const AdminBusinessAdvertisingAddItemRow = () => {
    const { values } = useFormikContext();

    return (
        <>
            {values.categoriesAndFilters.map((categoriesAndFiltersRow, index) => {
                return (
                    <AdminBusinessAdvertisingAddItemRowContainer
                        key={index}
                        isLast={values.categoriesAndFilters.length === index + 1}
                    >
                        <Row alignItems="center">
                            <Col xxs={30} lg={8}>
                                <Field
                                    component={FormCheckbox}
                                    skin="dark"
                                    id={`categoriesAndFilters.${index}.categoryEnabled`}
                                    name={`categoriesAndFilters.${index}.categoryEnabled`}
                                >
                                    Category and filters
                                </Field>
                            </Col>
                            <Col xxs={30} lg={8}>
                                <AdminBusinessAdvertisingCategoryAddCity
                                    groupId="categoriesAndFilters"
                                    id="category"
                                    index={index}
                                />
                            </Col>
                            <Col xxs={30} lg={7}>
                                <AdminBusinessAdvertisingCategoryOrder
                                    id="categoriesAndFilters"
                                    index={index}
                                    isCategory
                                />
                            </Col>
                            <Col xxs={30} lg={6}>
                                <AdminBusinessAdvertisingCategoryRotate
                                    id="categoriesAndFilters"
                                    index={index}
                                    isCategory
                                />
                            </Col>
                            <Col>
                                <AdminBusinessAdvertisingGroupEnablerWithCategory>
                                    <AdminBusinessAdvertisingAddItem index={index} />
                                </AdminBusinessAdvertisingGroupEnablerWithCategory>
                            </Col>
                        </Row>
                    </AdminBusinessAdvertisingAddItemRowContainer>
                );
            })}
        </>
    );
};

export default AdminBusinessAdvertisingAddItemRow;
