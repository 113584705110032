import styled from 'styled-components';
import { rem } from 'polished';

const FooterActions = styled.div`
    display: inline-flex;
    width: 100%;
    margin-top: ${rem(16)};
    padding: ${rem(16)} 0;
    align-items: center;
    gap: ${rem(24)};
    justify-content: ${({ alignContent }) =>
    alignContent
        ? alignContent
        : 'flex-end'};
`;

export default FooterActions;
