import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { H6 } from 'baseline-ui/typography';
import { AnchorButton } from 'baseline-ui/button';
import { useDispatch } from 'react-redux';
import { REMOVE_PRODUCT_FROM_SECTION_START } from 'redux/actions/EditProductActions';
import { useMessageBox } from 'components/MessageBox';
import ProductsAndServicesListItemSectionTagContainer from './ProductsAndServicesListItemSectionTagContainer';
import ProductsAndServicesListItemSectionTagsContainer from './ProductsAndServicesListItemSectionTagsContainer';

const ProductsAndServicesListItemSectionTags = ({ product, sections }) => {
    const dispatch = useDispatch();
    const messageBox = useMessageBox({
        messageId: 'addBusiness.modal.productsAndServices.removeSection.message',
        show: {
            yes: true,
            no: true,
        },
        onResponse(answer, data) {
            if (answer === 'yes') {
                dispatch({
                    type: REMOVE_PRODUCT_FROM_SECTION_START,
                    payload: {
                        item: product,
                        sectionId: data.id,
                    },
                });
            }
            return true;
        },
    });

    if (isEmpty(sections)) {
        return null;
    }
    return (
        <ProductsAndServicesListItemSectionTagsContainer>
            {sections.map((section) => {
                const { _id: id, name } = section;
                const buildRemoveTagHandler = ({ id, name }) => {
                    return (evt) => {
                        evt.preventDefault(); // Prevent navigation.
                        evt.stopPropagation(); // Prevent parent button from activating.
                        messageBox.showModal({
                            data: {
                                id,
                                name,
                            },
                        });
                    };
                };
                return (
                    <ProductsAndServicesListItemSectionTagContainer key={id}>
                        <H6 color="primary">
                            {name}
                            <AnchorButton
                                path="#"
                                btnType="link"
                                onClick={buildRemoveTagHandler({ id, name })}
                            >
                                x
                            </AnchorButton>
                        </H6>
                    </ProductsAndServicesListItemSectionTagContainer>
                );
            })}
        </ProductsAndServicesListItemSectionTagsContainer>
    );
};

ProductsAndServicesListItemSectionTags.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    product: PropTypes.object.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
};

ProductsAndServicesListItemSectionTags.defaultProps = {
    sections: undefined,
};

export default ProductsAndServicesListItemSectionTags;
