import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: ${rem(16)};
    padding: ${rem(24)} ${rem(24)} ${rem(16)};

    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
`}
`;

export default ModalEditProductHeaderContainer;
