import React, { useEffect } from 'react';
import { Container, Box, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import {
    GET_EVENT_LIST_START, SET_EVENT_FILTER_START,
} from 'redux/actions/EventActions';
import dayjs from 'dayjs';
import {Button} from 'baseline-ui/button';
import {FormattedMessage} from 'react-intl';


const EventList = ({ onFetchEventDetails }) => {
    const businessId = useSelector((state) => state.BusinessFormReducer._id);
    const {eventList, hasMore} = useSelector((state) => state.EventReducer);
    const page = useSelector((state) => state.EventReducer?.page) || 0;
    const dispatch = useDispatch();

    useEffect(() => {
        if (businessId) {
            dispatch({
                type: SET_EVENT_FILTER_START,
            });
            dispatch({
                type: GET_EVENT_LIST_START,
            });
        }
    }, [businessId]);
    const onMore = () => {
        dispatch({
            type: SET_EVENT_FILTER_START,
            payload: {
                page: page + 1,
            },
        });
    };
    return (
        <Container>
            <Box sx={{ p: 2 }}>
                <List>
                    {eventList?.map((event) => (
                        <React.Fragment key={event.entity_id}>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => onFetchEventDetails(event)}
                                        sx={{ color: '#f15a24' }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={event?.eventName}
                                    secondary={`${dayjs(event?.date).format(
                                        'YYYY-MM-DD',
                                    )}      -       ${event.formattedAddress}`}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>

                {
                    eventList?.length > 0 && hasMore && (
                    <div style={{textAlign: "center", marginBottom: "1rem"}}>
                        <Button skin="primary" type="button" onClick={onMore}>
                            <FormattedMessage id="myBusinesses.page.more.button.label"/>
                        </Button>
                    </div>
                    )

                }
            </Box>
        </Container>
    );
};

export default EventList;
