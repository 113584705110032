import { rem } from 'polished';
import styled from 'styled-components';

const WelcomeMainHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(32)};
    text-align: center;
    max-width: ${rem(480)};
    margin-left: auto;
    margin-right: auto;
`;

export default WelcomeMainHeaderContainer;
