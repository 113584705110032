import styled from 'styled-components';
import { get } from 'lodash';
import typographyTheme from '../styles/typographyTheme';
import smallStyles from '../styles/smallStyles';

const SmallNote = styled.small`
    ${smallStyles}

    color: ${({ theme, colorValue }) =>
        get(
            theme,
            `typography.colors.${colorValue}`,
            typographyTheme.typography.colors[colorValue],
        )};
    display: inline-block;
`;

export default SmallNote;
