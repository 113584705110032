import { css } from 'styled-components';
import { get } from 'lodash';
import typographyTheme from './typographyTheme';

const smallStyles = ({ theme, colorValue, textAlign }) => css`
    color: ${get(
        theme,
        `typography.colors.${colorValue}`,
        typographyTheme.typography.colors[colorValue],
    )};
    font-family: ${get(
        theme,
        'typography.base.fontFamily',
        typographyTheme.typography.base.fontFamily,
    )};
    font-size: ${get(
        theme,
        'typography.small.fontSize',
        typographyTheme.typography.small.fontSize,
    )};
    text-align: ${textAlign};
`;

export default smallStyles;
