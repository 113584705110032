import styled, { css } from 'styled-components';
import { siteContainer, container } from 'baseline-ui/layout';

const Inner = styled.div`
    align-items: center;
    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
    height: 100%;
    justify-content: ${({ isVertical }) => (isVertical ? 'center' : 'flex-start')};
    margin: 0 auto;

    ${({ isVertical, isFullWidth }) =>
        !isVertical &&
        !isFullWidth &&
        css`
            ${siteContainer}
        `}

    ${({ isVertical, isFullWidth }) =>
        !isVertical &&
        isFullWidth &&
        css`
            ${container}
        `}
`;

export default Inner;
