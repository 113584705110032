import { rem } from 'polished';
import styled from 'styled-components';

const FormPasswordInputRevealButton = styled.button.attrs(() => ({
    type: 'button',
}))`
    background-color: transparent;
    border: 0;
    color: ${({ theme }) => theme.typography.colors.muted};
    cursor: pointer;
    display: flex;
    padding-bottom: ${rem(4)};
    padding-left: ${rem(12)};

    &,
    &:hover,
    &:focus,
    &:active {
        outline: 0;
        text-decoration: none;
    }
`;

export default FormPasswordInputRevealButton;
