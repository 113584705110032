import { baseTheme } from 'baseline-ui/base-theme';

const headerTheme = {
    header: {
        backgroundColor: baseTheme.base.colors.light,
        height: 70,
        logo: {
            width: 48,
        },
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.125)',
            verticalShadow: '6px 0 8px rgba(0,0,0,0.125)',
        },
        vertical: {
            width: 240,
        },
        navigation: {
            label: {
                uppercase: false,
                fontWeight: 700,
            },
            active: {
                height: 2,
                width: 8,
            },
        },
        menuModal: {
            backgroundColor: baseTheme.base.colors.light,
            titleTypographyColor: 'dark',
            offsetTop: 16,
            entryTransitionY: -5,
            menuIcon: {
                icon: 'menu',
                size: 28,
                color: baseTheme.base.grays.gray,
            },
        },
        zIndex: 3,
        filter: {
            blur: 5,
        },
        backdropFilter: {
            blur: 16,
        },
    },
};

export default headerTheme;
