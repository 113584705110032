import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GaIcon } from 'baseline-ui/icon';
import { Button } from 'baseline-ui/button';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { useMeasure } from 'baseline-ui/helpers';
import useWindowSize from 'common/useWindowSize';
import addBusinessSectionsUtils from 'utils/addBusinessSectionsUtils';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { ENTITY_TYPE_ICONS } from 'common/consts';
import AddFavePageAsideSectionContainer from './AddFavePageAsideSectionContainer';
import FavePageAsideContainer from './FavePageAsideContainer';
import FavePageAsideImageContainer from './FavePageAsideImageContainer';
import FavePageAsideMyBusinessSectionsContainer from './FavePageAsideMyBusinessSectionsContainer';
import AddFavePageAsideSectionOuter from './AddFavePageAsideSectionOuter';
import FavePageAsideImageContainerOuter from './FavePageAsideImageContainerOuter';
import { HorizontalRule } from './components';
import useScroll from 'common/useScroll';

const FavePageAsideMyBusinessDesktop = ({ onClick, currentSectionId }) => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);

    const addBusinessSections = addBusinessSectionsUtils.renderAddBusinessSections({
        intl,
        type,
    });

    const [bind, bounds] = useMeasure();
    const [containerBind, containerBounds] = useMeasure();
    const [imageContainerBind, imageContainerBounds] = useMeasure();
    const { height } = useWindowSize();
    const {position, docHeight} = useScroll();

    const calculateSectionsHeight = useMemo(() => {
        if (!bounds || !containerBounds || !imageContainerBounds) {
            return null;
        }
        const footerHeight = 370;
        const SAVA_AREA_HEIGHT = 40;
        const availableHeight =
            height -
            footerHeight -
            SAVA_AREA_HEIGHT -
            theme.site.page.header.height;

        const adminMainFooterHeight =
            theme.site.navigationFooter.bottomOffset +
            theme.site.navigationFooter.height +
            theme.site.navigationFooter.paddingOffset * 2;

            if (position >= docHeight) {
                return availableHeight;
            } else {
                return (
                    height - (theme.header.height + theme.site.page.header.height + adminMainFooterHeight + SAVA_AREA_HEIGHT)
                    );
                }

    }, [theme, height, bounds, addBusinessSections, containerBounds, imageContainerBounds]);

    if (isEmpty(addBusinessSections)) {
        return null;
    }

    return (
        <FavePageAsideContainer {...containerBind}>
            <FavePageAsideImageContainerOuter {...imageContainerBind}>
                <FavePageAsideImageContainer>
                    <GaIcon
                        icon={ENTITY_TYPE_ICONS[type]}
                        size={theme.site.aside.icon.size}
                        color={theme.base.colors.primary}
                    />
                </FavePageAsideImageContainer>
            </FavePageAsideImageContainerOuter>
            <FavePageAsideMyBusinessSectionsContainer
                sectionsContainerHeight={calculateSectionsHeight}
            >
                {addBusinessSections.map((section, index) => {
                    const { id, label, divider } = section;
                    return (
                        <AddFavePageAsideSectionOuter {...bind} key={index}>
                            {divider === 'top' && <HorizontalRule />}
                            <AddFavePageAsideSectionContainer>
                                <Button
                                    block
                                    onClick={() =>
                                        currentSectionId === id ? {} : onClick(id, index)
                                    }
                                    skin={currentSectionId === id ? 'primary' : 'primaryDisabled'}
                                >
                                    {label}
                                </Button>
                            </AddFavePageAsideSectionContainer>
                        </AddFavePageAsideSectionOuter>
                    );
                })}
            </FavePageAsideMyBusinessSectionsContainer>
        </FavePageAsideContainer>
    );
};

FavePageAsideMyBusinessDesktop.propTypes = {
    onClick: PropTypes.func.isRequired,
    currentSectionId: PropTypes.string.isRequired,
};

export default FavePageAsideMyBusinessDesktop;
