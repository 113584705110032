import React from 'react';
import { useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import addBusinessRequiredInformationSchema from 'schema/addBusinessRequiredInformationSchema';
import PersonRequiredInformationForm from './PersonRequiredInformationForm';

const PersonRequiredInformation = () => {
    const basicInformation = useSelector(businessFormSelector);
    const {
        name,
        firstName,
        lastName,
        website,
        position,
        phone,
        privatephone,
        email,
        formattedAddress,
        type,
        showAddress,
    } = basicInformation;

    const initialValues = {
        name,
        ownerFirstName: firstName || '',
        ownerLastName: lastName || '',
        website,
        position,
        phone,
        privatephone,
        email,
        formattedAddress,
        searchBusinessAddress: '',
        showAddress: showAddress,
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id={`${type}.page.sections.requiredInformation.title`} />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.requiredInformation.subtitle`} />
            </P>
            <Formik
                initialValues={initialValues}
                validationSchema={addBusinessRequiredInformationSchema(type)}
            >
                {() => <PersonRequiredInformationForm />}
            </Formik>
        </>
    );
};

export default PersonRequiredInformation;
