import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewFavesButtonLabel = styled.span`
    font-size: ${rem(12)};
    font-weight: 400;
    line-height: ${rem(16)};
`;

export default FavePagePreviewFavesButtonLabel;
