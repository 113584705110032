import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const Textarea = styled.textarea`
    appearance: none;
    background-color: transparent;
    border-color: ${({ theme, skin }) =>
        get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)};
    border-style: solid;
    border-width: 0;
    color: ${({ theme, skin }) =>
        get(theme, `form.input.${skin}.color`, formTheme.form.input[skin].color)};
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.input.fontSize', formTheme.form.input.fontSize))};
    margin-top: ${rem(20)};
    outline: 0;
    padding: ${rem(4)} ${rem(12)} ${rem(8)};
    resize: none;
    width: 100%;

    ${({ readOnly }) =>
        readOnly &&
        css`
            border-color: transparent;
        `}

    ${({ disabled, skin, theme }) =>
        disabled &&
        css`
            border-bottom-style: dotted;
            border-color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
            color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};

            cursor: not-allowed;
        `}

    ${({ hideLabel }) =>
        hideLabel &&
        css`
            margin-top: 0;
            padding-top: ${rem(12)};
        `}
`;

export default Textarea;
