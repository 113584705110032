import styled from 'styled-components';
import { get } from 'lodash';
import { media } from 'baseline-ui/layout';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../styles/formTheme';

const Legend = styled.legend`
    border: 0;
    color: ${(props) =>
        get(
            props,
            `theme.form.legend[${props.skin}].color`,
            formTheme.form.legend[props.skin].color,
        )};
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: 1.3125rem;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
    padding: 0;
    text-align: center;
    width: 100%;

    ${media.md`
        font-size: 1.875rem;
        margin-bottom: 1.875rem;
    `}
`;

export default Legend;
