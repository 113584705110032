export const GET_SECTIONS_PROCESS = 'GET_SECTIONS_PROCESS';
export const GET_SECTIONS_START = 'GET_SECTIONS_START';
export const GET_SECTIONS_SUCCESS = 'GET_SECTIONS_SUCCESS';
export const GET_SECTIONS_RESET = 'GET_SECTIONS_RESET';

export const DELETE_SECTION_START = 'DELETE_SECTION_START';

export const UPDATE_SECTION_START = 'UPDATE_SECTION_START';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_PROCESS = 'UPDATE_SECTION_PROCESS';

export const ADD_SECTION_START = 'ADD_SECTION_START';
export const ADD_SECTION_PROCESS = 'ADD_SECTION_PROCESS';

export const UPDATE_SECTIONS_START = 'UPDATE_SECTIONS_START';
export const SET_SELECTED_SECTION_START = 'SET_SELECTED_SECTION_START';

export const PUT_SECTIONS_PROCESS = 'PUT_SECTIONS_PROCESS';
export const PUT_SECTIONS_START = 'PUT_SECTIONS_START';
export const PUT_SECTIONS_SUCCESS = 'PUT_SECTIONS_SUCCESS';

export const GET_SECTION_PRODUCTS_PROCESS = 'GET_SECTION_PRODUCTS_PROCESS';
export const GET_SECTION_PRODUCTS_START = 'GET_SECTION_PRODUCTS_START';
export const GET_SECTION_PRODUCTS_SUCCESS = 'GET_SECTION_PRODUCTS_SUCCESS';

export const ADD_SINGLE_SECTION_START = 'ADD_SINGLE_SECTION_START';
export const ADD_SINGLE_SECTION_SUCCESS = 'ADD_SINGLE_SECTION_SUCCESS';
export const ADD_SINGLE_SECTION_PROCESS = 'ADD_SINGLE_SECTION_PROCESS';

export const UPDATE_PRODUCT_IN_SECTIONS_START = 'UPDATE_PRODUCT_IN_SECTIONS_START';
export const UPDATE_PRODUCT_IN_SECTIONS_SUCCESS = 'UPDATE_PRODUCT_IN_SECTIONS_SUCCESS';

export const UPDATE_PRODUCT_IN_ALL_SECTIONS_START = 'UPDATE_PRODUCT_IN_ALL_SECTIONS_START';
