import { PROCESS_CLEAR, PROCESS_RESET } from "../actions/CommonActions";

const ProcessReducer = (state = {}, action) => {
  const { type } = action;
  const processState = type.substr(type.lastIndexOf("_") + 1);
  const processName = type.substr(0, type.lastIndexOf("_"));

  switch (type) {
    case PROCESS_CLEAR:
      return {};
    case PROCESS_RESET: {
      const newState = { ...state };
      delete newState[processName];
      return newState;
    }
    default:
      return {
        ...state,
        [processName]: processState,
      };
  }
};

export default ProcessReducer;
