import { css } from 'styled-components';

const collapsableButtonStyles = () => css`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    transition: color 0.15s ease-in-out;
`;

export default collapsableButtonStyles;
