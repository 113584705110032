import { rem } from 'polished';
import styled from 'styled-components';

const ModalBrowseProductsFormSelectionContainer = styled.div`
    margin-bottom: ${rem(16)};
    margin-left: ${rem(32)};
    margin-right: ${rem(16)};
`;

export default ModalBrowseProductsFormSelectionContainer;
