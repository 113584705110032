import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const useScroll = () => {
    const { height } = useWindowSize();
    const [scroll, setScroll] = useState({
        position: window.scrollY + height,
        docHeight: document.body.offsetHeight,
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            function handleResize() {
                setScroll({
                    position: window.scrollY + height,
                    docHeight: document.body.offsetHeight,
                });
            }

            window.addEventListener("scroll", handleResize);

            handleResize();

            return () => window.removeEventListener("scroll", handleResize);
        }
    }, []);

    return scroll;
};

export default useScroll;