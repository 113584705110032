const alertTypeData = [
    'primary',
    'secondary',
    'primaryMuted',
    'info',
    'infoLight',
    'warning',
    'success',
    'danger',
];

export default alertTypeData;
