import React from 'react';
import PropTypes from 'prop-types';
import ModalEditProductHeaderContainer from './ModalEditProductHeaderContainer';
import ModalEditProductHeaderTitle from './ModalEditProductHeaderTitle';
import ModalEditProductTotalFaves from '../ModalEditProductTotalFaves';

const ModalEditProductHeader = ({ title, totalFaves }) => {
    return (
        <ModalEditProductHeaderContainer>
            <ModalEditProductHeaderTitle>{title}</ModalEditProductHeaderTitle>
            <ModalEditProductTotalFaves totalFaves={totalFaves} />
        </ModalEditProductHeaderContainer>
    );
};

ModalEditProductHeader.propTypes = {
    title: PropTypes.string.isRequired,
    totalFaves: PropTypes.number.isRequired,
};

export default ModalEditProductHeader;
