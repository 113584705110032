export const POST_SYSTEM_NOTIFICATION_PROCESS =
  "POST_SYSTEM_NOTIFICATION_PROCESS";
export const POST_SYSTEM_NOTIFICATION_START = "POST_SYSTEM_NOTIFICATION_START";
export const POST_SYSTEM_NOTIFICATION_SUCCESS =
  "POST_SYSTEM_NOTIFICATION_SUCCESS";

export const POST_PROMOTION_NOTIFICATION_PROCESS =
  "POST_PROMOTION_NOTIFICATION_PROCESS";
export const POST_PROMOTION_NOTIFICATION_START =
  "POST_PROMOTION_NOTIFICATION_START";
export const POST_PROMOTION_NOTIFICATION_SUCCESS =
  "POST_PROMOTION_NOTIFICATION_SUCCESS";

export const GET_SYSTEM_NOTIFICATIONS_PROCESS =
  "GET_SYSTEM_NOTIFICATIONS_PROCESS";
export const GET_SYSTEM_NOTIFICATIONS_START = "GET_SYSTEM_NOTIFICATIONS_START";
export const GET_SYSTEM_NOTIFICATIONS_SUCCESS =
  "GET_SYSTEM_NOTIFICATIONS_SUCCESS";

export const GET_PROMO_NOTIFICATIONS_PROCESS =
  "GET_PROMO_NOTIFICATIONS_PROCESS";
export const GET_PROMO_NOTIFICATIONS_START = "GET_PROMO_NOTIFICATIONS_START";
export const GET_PROMO_NOTIFICATIONS_SUCCESS =
  "GET_PROMO_NOTIFICATIONS_SUCCESS";

export const RESET_PROMO_NOTIFICATIONS_START =
  "RESET_PROMO_NOTIFICATIONS_START";

export const SET_PROMO_NOTIFICATIONS_NEXT_PAGE_PROCESS =
  "SET_PROMO_NOTIFICATIONS_NEXT_PAGE_PROCESS";
export const SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START =
  "SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START";
export const SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS =
  "SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS";

export const SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_PROCESS =
  "SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_PROCESS";
export const SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START =
  "SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START";
export const SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS =
  "SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS";

export const RESET_SYSTEM_NOTIFICATIONS_START =
  "RESET_SYSTEM_NOTIFICATIONS_START";
