const businessAddressUtils = {
    requestSupportMessage: ({ name, address, phone, message }) => {
        const renderContent = [];
        if (name) {
            const renderName = `\n\n Business name: ${name}`;
            renderContent.push(renderName);
        }
        if (phone) {
            const renderPhone = `\n\n Phone number: ${phone}`;
            renderContent.push(renderPhone);
        }
        if (address) {
            const renderAddress = `\n\n Address entered: ${address}`;
            renderContent.push(renderAddress);
        }
        if (message) {
            const renderMessage = `\n\n Message: ${message}`;
            renderContent.push(renderMessage);
        }

        const subject = `Request Support${name && ` by ${name}}: Trouble finding address`}`;

        return { subject, body: renderContent.join() };
    },
    formatBusinessAddress: (formattedAddress) => {
        const stringWithoutCountry = formattedAddress.replace(', USA', '');
        const commaCount = stringWithoutCountry.match(/,/g)?.length;
        const inputSplit =
            commaCount >= 2 ? stringWithoutCountry.split(',') : [stringWithoutCountry];
        return {
            streetAddress: inputSplit[0]?.trim(),
            cityAddress: inputSplit[1]?.trim(),
            stateCodeAddress: inputSplit[2] ? `, ${inputSplit[2]?.trim()}` : '',
        };
    },
};

export default businessAddressUtils;
