import {
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_PEOPLE,
    SEARCH_BUSINESSES_ANY_STATUS,
    BUSINESS_STATUS_AS_DRAFT,
} from 'common/consts';

const businessUtils = {
    fullName: (firstName, lastName) => {
        if (!firstName || !lastName) {
            return null;
        }
        return [firstName, lastName].join(' ').toString();
    },
    resetSearchFavePagesForm: (setFieldValue) => {
        setFieldValue('searchFavePages', '');
        setFieldValue('entityStatus', SEARCH_BUSINESSES_ANY_STATUS);
        setFieldValue('entityType', ENTITY_TYPE_BUSSINESS);
        setFieldValue('searchBusinessCity', '');
        setFieldValue('category', '');
        setFieldValue('subcategory', '');
        setFieldValue('filter', {});
    },
    positionByEntityType: ({ position, entityType }) => {
        if (entityType === ENTITY_TYPE_PEOPLE) {
            return position;
        }

        if (entityType === ENTITY_TYPE_BUSSINESS) {
            return null;
        }

        return null;
    },
};

export function isDraftlikeState(approvalStatus) {
    // Intead of equality matching BUSINESS_APPROVAL_STATUS_DRAFT allow aliasing for other statuses.
    return BUSINESS_STATUS_AS_DRAFT.includes(approvalStatus);
}

export default businessUtils;
