import { ADD_BUSINESS_REQUIRED_INFORMATION } from 'common/addBusinessViewIds';
import {useContext, useEffect, useState} from 'react';
// import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
// import { FeedbackContext } from 'baseline-ui/feedback';
// import { useDispatch } from 'react-redux';
// import { SAVE_DATA_TO_LOCAL_STORAGE_START } from 'redux/actions/BusinessFormActions';
import { ModalContext } from 'baseline-ui/modal';
import ModalUnsavedChangesSection from 'screens/BusinessForm/ModalUnsavedChangesSection';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import addBusinessSectionsUtils from './addBusinessSectionsUtils';
import {useLocation} from "react-router-dom";

const useBusinessSectionsNavigation = (doBlock, onSaveCallback) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);
    const { showModal } = useContext(ModalContext);
    const location = useLocation();
    // const dispatch = useDispatch();
    // const { setFeedbackOnStage } = useContext(FeedbackContext);
    const [activeView, setActiveView] = useState({
        sectionId: ADD_BUSINESS_REQUIRED_INFORMATION,
        sectionIndex: 0,
    });
    const addBusinessSections = addBusinessSectionsUtils.renderAddBusinessSections({
        intl,
        type,
    });

    const lastSectionIndex = !isEmpty(addBusinessSections) && addBusinessSections.length - 1;

    const handleBackNavigation = () => {
        const handleSetActiveView = () =>
            setActiveView((prevState) => {
                const { sectionIndex: index } = prevState;
                if (index === 0) {
                    return prevState;
                }
                return {
                    sectionIndex: index - 1,
                    sectionId: addBusinessSections[index - 1].id,
                };
            });

        if (doBlock) {
            return showModal(ModalUnsavedChangesSection, {
                onSaveCallback,
                onCompleteCallback: () => handleSetActiveView(),
            });
        }
        return handleSetActiveView();
    };

    const handleNextNavigation = () => {
        const handleSetActiveView = () =>
            setActiveView((prevState) => {
                const { sectionIndex: index } = prevState;
                if (index === lastSectionIndex) {
                    return prevState;
                }
                return {
                    sectionIndex: index + 1,
                    sectionId: addBusinessSections[index + 1].id,
                };
            });

        if (doBlock) {
            return showModal(ModalUnsavedChangesSection, {
                onSaveCallback,
                onCompleteCallback: () => handleSetActiveView(),
            });
        }
        return handleSetActiveView();
    };
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const activeSection = urlParams.get('section');
        if (activeSection && !doBlock) {
            const sectionIndex = addBusinessSections?.findIndex(
                section => section.id === activeSection
            );
            if (sectionIndex !== -1) {
                setActiveView({
                    sectionId: activeSection,
                    sectionIndex: sectionIndex,
                });
            }

        }
    }, [location.search]);
    // TODO reuse for feedback upon any unsaved changes when editing business
    // const handleSaveDraftAction = () => {
    //     // TODO temp feedback test - feedback should be a callback of the save action
    //     setFeedbackOnStage({
    //         label: intl.formatMessage({ id: 'addBusiness.feedback.saveBusiness.button.label' }),
    //         show: true,
    //         type: 'success',
    //         timeout: 2400,
    //     });
    //     // TODO: can we use a loading state from the reducer?
    //     dispatch({
    //         type: SAVE_DATA_TO_LOCAL_STORAGE_START,
    //     });
    // };

    return {
        handleBackNavigation,
        handleNextNavigation,
        // handleSaveDraftAction,
        activeView,
        setActiveView,
        lastSectionIndex,
    };
};

export default useBusinessSectionsNavigation;
