import styled from 'styled-components';
import { get } from 'lodash';
import tabsTheme from '../../styles/tabsTheme';

const Wrapper = styled.div`
    background-color: ${({ theme }) =>
        get(theme, 'tabs.panel.backgroundColor', tabsTheme.tabs.panel.backgroundColor)};
    position: relative;
    z-index: 1;
`;

export default Wrapper;
