import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import collapsableTheme from '../../styles/collapsableTheme';

const AccordionContainer = styled.div`
    background-color: ${({ theme }) =>
        get(
            theme,
            'collapsable.accordion.backgroundColor',
            collapsableTheme.collapsable.accordion.backgroundColor,
        )};
    border-radius: ${({ theme }) =>
        get(
            theme,
            'collapsable.accordion.roundedCorners',
            collapsableTheme.collapsable.accordion.roundedCorners,
        )
            ? rem(
                  get(
                      theme,
                      'collapsable.accordion.borderRadius',
                      collapsableTheme.collapsable.accordion.borderRadius,
                  ),
              )
            : 0};
    border-color: ${({ theme }) =>
        get(theme, 'collapsable.accordion.border', collapsableTheme.collapsable.accordion.border)};
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
`;

export default AccordionContainer;
