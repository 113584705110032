import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { stripUnit, rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const FormCheckboxLabel = styled.label`
    cursor: pointer;
    display: inline-flex;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme, sizeValue }) =>
        rem(
            get(
                theme,
                `form.checkbox.label.fontSize.${sizeValue}`,
                formTheme.form.checkbox.label.fontSize[sizeValue],
            ),
        )};
    margin-right: 1rem;
    padding-left: ${({ theme, sizeValue }) =>
        stripUnit(
            rem(
                get(
                    theme,
                    `form.checkbox.size.${sizeValue}`,
                    formTheme.form.checkbox.size[sizeValue],
                ),
            ),
        ) * 1.5}rem;
    position: relative;
    z-index: 1;

    min-height: ${({ theme, sizeValue }) =>
        rem(
            get(theme, `form.checkbox.size.${sizeValue}`, formTheme.form.checkbox.size[sizeValue]),
        )};
    min-width: ${({ theme, sizeValue }) =>
        rem(
            get(theme, `form.checkbox.size.${sizeValue}`, formTheme.form.checkbox.size[sizeValue]),
        )};

    vertical-align: middle;

    ${({ hideLabel }) =>
        hideLabel &&
        css`
            margin-right: 0;
            padding-left: 0;
        `}
`;

export default FormCheckboxLabel;
