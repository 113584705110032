import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import formTheme from '../../styles/formTheme';

const DobInputContainer = styled.div`
    align-items: baseline;
    background-color: ${({ theme, skin }) =>
        get(
            theme,
            `form.input.${skin}.backgroundColor`,
            formTheme.form.input[skin].backgroundColor,
        )};
    border-color: ${({ theme, skin }) =>
        get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'form.input.borderRadius', formTheme.form.input.borderRadius))};
    border-style: solid;
    border-width: 1px;
    display: flex;
    transition: background 0.3s ease-in 0.1s;
    z-index: 0;

    ${({ active, skin, theme }) =>
        active &&
        css`
            background-color: ${get(
                theme,
                `form.input.${skin}.backgroundColorFocus`,
                formTheme.form.input[skin].backgroundColorFocus,
            )};
            border-color: ${({ theme, skin }) =>
            get(theme, `form.input.${skin}.borderColorFocus`, formTheme.form.input[skin].borderColorFocus)};
        `}

    ${({ readOnlyValue }) =>
        readOnlyValue &&
        css`
            border-color: transparent;
        `}

    ${({ disabledValue, theme, skin }) =>
        disabledValue &&
        css`
            border-color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
            border-style: dotted;
            color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
        `}
`;

export default DobInputContainer;
