import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import { Form } from 'formik';
import { useSelector } from 'react-redux';
import { allSectionsSelector } from 'redux/selectors/sectionSelectors';
import { FieldContainer } from 'baseline-ui/form';
import { ContentRenderer } from 'baseline-ui/helpers';
import { productToEditNameSelector } from 'redux/selectors/productSelectors';
import ModalEditProductManageSectionsListContainer from './ModalEditProductManageSectionsListContainer';
import ModalEditProductManageSectionsListItem from './ModalEditProductManageSectionsListItem';
import ModalEditProductManageSectionsSelectionLabel from './ModalEditProductManageSectionsSelectionLabel';

const ModalEditProductManageSectionsForm = ({ onRequestClose, ...props }) => {
    const intl = useIntl();
    const allSections = useSelector(allSectionsSelector);
    const productName = useSelector(productToEditNameSelector);

    return (
        <Modal
            title={intl.formatMessage({
                id: 'addBusiness.modal.editProduct.modal.manageSections.title',
            })}
            showCloseButton={false}
            handleOnDismiss={onRequestClose}
            size="sm"
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.modal.manageSections.footerActions.button.confirm.label',
                    }),
                    skin: 'primary',
                    onClick: onRequestClose,
                },
            ]}
            footerPre={<ModalEditProductManageSectionsSelectionLabel />}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage(
                    { id: 'addBusiness.modal.editProduct.modal.manageSections.content.md' },
                    { productName },
                )}
            />
            <ModalEditProductManageSectionsListContainer>
                <Form noValidate>
                    {allSections.map((section) => {
                        const { name, _id: id } = section;
                        return (
                            <FieldContainer key={id}>
                                <ModalEditProductManageSectionsListItem
                                    productId={id}
                                    sectionName={name}
                                />
                            </FieldContainer>
                        );
                    })}
                </Form>
            </ModalEditProductManageSectionsListContainer>
        </Modal>
    );
};

ModalEditProductManageSectionsForm.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
};

export default ModalEditProductManageSectionsForm;
