import styled from 'styled-components';

const AccessRightsUserCreatorContentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export default AccessRightsUserCreatorContentContainer;
