/* eslint-disable camelcase */
import {
    // Image editor
    openEditor,
    createDefaultImageReader,
    createDefaultImageWriter,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_finetune,
    plugin_annotate,

    // The main UI and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_finetune_locale_en_gb,
    plugin_annotate_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale and the shape preprocessor
    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    // Import the default properties
    markup_editor_defaults,
    plugin_finetune_defaults,
    blobToFile,
} from '@pqina/pintura';
import arrayMove from 'array-move';
import { PHOTO_STATE_DELETED } from 'common/consts';
import heic2any from 'heic2any';

const fileUploaderUtils = {
    setPlugins: () => {
        setPlugins(plugin_crop, plugin_finetune, plugin_annotate);
    },
    editImage: (image, doneCallbackFunc) => {
        const imageFile = image.pintura ? image.pintura.file : image;
        const imageState = image.pintura ? image.pintura.data : {};

        const editor = openEditor({
            src: imageFile,
            imageState,
            imageReader: createDefaultImageReader({
                preprocessImageFile: async (file, options, onprogress) => {
                    // If is not of type HEIC we skip the file
                    if (!/heic/.test(file.type)) return file;

                    // Let's turn the HEIC image into JPEG so the browser can read it
                    const blob = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                        quality: 0.5,
                        targetSize: {
                            width: 1170,
                            height: 1170,
                            fit: 'cover',
                        },
                    });

                    // The editor expects a File so let's convert our Blob
                    return blobToFile(blob, file.name);
                },
            }),
            imageWriter: createDefaultImageWriter({
                quality: 0.5,
                mimeType: 'image/jpeg',
                targetSize: {
                    width: 1170,
                    height: 1170,
                    fit: 'cover',
                },
            }),

            // Set Markup Editor defaults
            ...markup_editor_defaults,

            // Set Finetune plugin defaults
            ...plugin_finetune_defaults,

            // This handles complex shapes like arrows / frames
            shapePreprocessor: createDefaultShapePreprocessor(),

            // This will set a square crop aspect ratio
            imageCropAspectRatio: 1,
            // Set locale
            locale: {
                ...locale_en_gb,
                ...plugin_crop_locale_en_gb,
                ...plugin_finetune_locale_en_gb,
                ...plugin_annotate_locale_en_gb,
                ...markup_editor_locale_en_gb,
            },
        });

        editor.on('close', () => {
            // the user cancelled editing the image
        });

        editor.on('process', ({ dest, editorImageState }) => {
            Object.assign(dest, {
                pintura: { file: imageFile, data: editorImageState },
            });
            doneCallbackFunc(dest);
        });
    },
    editImageDoneCallback: ({ photos, setPhotos, index, output }) => {
        const updatedPhotos = [...photos];

        // replace original image with new image
        updatedPhotos[index] = output;

        // revoke preview URL for old image
        // if (file.preview) URL.revokeObjectURL(file.preview);

        // set new preview URL
        Object.assign(output, {
            preview: URL.createObjectURL(output),
            state: 'updated',
        });

        // update view
        setPhotos(updatedPhotos);
    },
    handleFileUpload: (files, photos, setPhotos) => {
        const file = files instanceof FileList ? files[0] : files;

        Object.assign(file, {
            preview: URL.createObjectURL(file),
        });

        fileUploaderUtils.editImage(file, (output) => {
            const updatedPhotos = [...photos];
            // replace original image with new image
            updatedPhotos.push(output);
            // revoke preview URL for old image
            if (file.preview) URL.revokeObjectURL(file.preview);
            // set new preview URL
            Object.assign(output, {
                preview: URL.createObjectURL(output),
                state: 'new',
            });
            // update view
            setPhotos(updatedPhotos);
        });
    },
    onSortEnd: ({ photos, setPhotos, oldIndex, newIndex }) => {
        setPhotos(arrayMove(photos, oldIndex, newIndex));
    },
    currentPhotosCount: (photos) => {
        if (!photos) {
            return 0;
        }
        return photos.filter((photo) => photo.state !== PHOTO_STATE_DELETED).length;
    },
    currentPhotoList: (photos) => photos?.filter((photo) => photo.state !== 'deleted'),
};

export default fileUploaderUtils;
