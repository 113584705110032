import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_SUGGESTIONS_START,
  SET_SUGGESTIONS_NEXT_PAGE,
  SET_SUGGESTIONS_PREV_PAGE,
  SET_SUGGESTIONS_SORT_DIRECTION_FILTER,
  SET_SUGGESTIONS_SORT_FIELD_FILTER,
  SET_SUGGESTIONS_STATE_FILTER,
} from "../redux/actions/SuggestActions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import * as dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { i18n } from "../common/i18n";
import { Box } from "@mui/system";

const Suggestions = () => {
  const dispatch = useDispatch();
  const { suggestions, hasNext, page, stateFilter, sortField, sortDirection } =
    useSelector(({ SuggestReducer }) => SuggestReducer);

  useEffect(() => {
    dispatch({
      type: GET_SUGGESTIONS_START,
    });
  }, []);

  return (
    <div>
      <Typography variant="h6">Suggestions</Typography>

      <Box marginTop={2}>
        <FormControl>
          <InputLabel id="state">State</InputLabel>
          <Select
            labelId="state"
            id="dstate"
            value={stateFilter}
            label="State"
            onChange={(e) =>
              dispatch({
                type: SET_SUGGESTIONS_STATE_FILTER,
                payload: e.target.value,
              })
            }
          >
            <MenuItem value={"SUBMITTED"}>SUBMITTED</MenuItem>
            <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
            <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ marginLeft: 2 }}>
          <InputLabel id="sortField">Sort Field</InputLabel>
          <Select
            labelId="sortField"
            id="sortField"
            value={sortField}
            label="Sort Field"
            onChange={(e) =>
              dispatch({
                type: SET_SUGGESTIONS_SORT_FIELD_FILTER,
                payload: e.target.value,
              })
            }
          >
            <MenuItem value={"NAME"}>NAME</MenuItem>
            <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
            <MenuItem value={"SUGGESTTYPE"}>SUGGESTTYPE</MenuItem>
            <MenuItem value={"STATE"}>STATE</MenuItem>
            <MenuItem value={"CREATED"}>CREATED</MenuItem>
            <MenuItem value={"UPDATED"}>UPDATED</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ marginLeft: 2 }}>
          <InputLabel id="sortField">Sort Direction</InputLabel>
          <Select
            labelId="sortDirection"
            id="sortDirection"
            value={sortDirection}
            label="Sort Direction"
            onChange={(e) =>
              dispatch({
                type: SET_SUGGESTIONS_SORT_DIRECTION_FILTER,
                payload: e.target.value,
              })
            }
          >
            <MenuItem value={"ASC"}>ASC</MenuItem>
            <MenuItem value={"DESC"}>DESC</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <List>
        {suggestions?.map((suggestion, idx) => (
          <div key={`user_key_${suggestion._id}`}>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container>
                    <Grid item xs={10}>
                      <ListItemText>
                        <Grid container>
                          <Grid xs={5} item>
                            <Grid>
                              <b>Suggested by: </b>
                              {suggestion.name}
                            </Grid>
                            <Grid>
                              <a href={`mailto:${suggestion.email}`}>
                                {suggestion.email}
                              </a>
                            </Grid>
                            <Grid>
                              <a href={`tel:${suggestion.phone}`}>
                                {suggestion.phone}
                              </a>
                            </Grid>
                          </Grid>

                          <Grid xs={2} item />
                          <Grid xs={5} item>
                            <Grid>
                              <b>Created at: </b>
                              {dayjs(suggestion.created).format(
                                "MM-DD-YYYY hh:mm A",
                              )}
                            </Grid>
                            <Grid>
                              <b>Last updated at: </b>
                              {dayjs(suggestion.updated).format(
                                "MM-DD-YYYY hh:mm A",
                              )}
                            </Grid>
                            <Grid>
                              <b>State: </b>
                              {suggestion.state}
                            </Grid>
                            <Grid>
                              <b>Suggestion type: </b>
                              {suggestion.suggestType}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.keys(suggestion?.payload || []).map((payloadProp) => (
                    <Typography key={`payload_prop_${payloadProp}`}>
                      {i18n().SUGGESTIONS[payloadProp]
                        ? i18n().SUGGESTIONS[payloadProp]
                        : payloadProp}
                      : {suggestion.payload[payloadProp]}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </div>
        ))}
      </List>
      {page > 0 && (
        <Button
          onClick={() =>
            dispatch({
              type: SET_SUGGESTIONS_PREV_PAGE,
            })
          }
        >
          Previous page
        </Button>
      )}
      {hasNext && (
        <Button
          onClick={() =>
            dispatch({
              type: SET_SUGGESTIONS_NEXT_PAGE,
            })
          }
        >
          Next page
        </Button>
      )}
    </div>
  );
};

export default Suggestions;
