import { baseTheme } from 'baseline-ui/base-theme';

const buttonTheme = {
    button: {
        primary: {
            background: baseTheme.base.colors.primary,
            border: baseTheme.base.colors.primary,
            color: baseTheme.base.colors.light,
        },
        secondary: {
            background: baseTheme.base.colors.secondary,
            border: baseTheme.base.colors.secondary,
            color: baseTheme.base.colors.light,
        },
        info: {
            background: baseTheme.base.colors.info,
            border: baseTheme.base.colors.info,
            color: baseTheme.base.colors.light,
        },
        success: {
            background: baseTheme.base.colors.success,
            border: baseTheme.base.colors.success,
            color: baseTheme.base.colors.light,
        },
        warning: {
            background: baseTheme.base.colors.warning,
            border: baseTheme.base.colors.warning,
            color: baseTheme.base.colors.light,
        },
        danger: {
            background: baseTheme.base.colors.danger,
            border: baseTheme.base.colors.danger,
            color: baseTheme.base.colors.light,
        },
        muted: {
            background: baseTheme.base.colors.muted,
            border: baseTheme.base.colors.muted,
            color: baseTheme.base.colors.light,
        },
        light: {
            background: baseTheme.base.colors.light,
            border: baseTheme.base.colors.light,
            color: baseTheme.base.colors.light,
        },
        dark: {
            background: baseTheme.base.colors.dark,
            border: baseTheme.base.colors.dark,
            color: baseTheme.base.colors.dark,
        },
        fontFamily: 'inherit',
        fontWeight: 700,
        lineHeight: 1.2,
        uppercase: true,
        fontSize: {
            default: 14,
            sm: 12,
            md: 16,
            lg: 18,
            xl: 24,
        },
        roundedCorners: true,
        borderRadius: {
            default: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
        },
        iconSize: {
            default: 16,
            sm: 12,
            md: 18,
            lg: 20,
            xl: 26,
        },
        padding: {
            default: {
                h: 12,
                v: 8,
            },
            sm: {
                h: 8,
                v: 6,
            },
            md: {
                h: 14,
                v: 10,
            },
            lg: {
                h: 16,
                v: 12,
            },
            xl: {
                h: 20,
                v: 14,
            },
        },
    },
};

export default buttonTheme;
