import * as yup from 'yup';
import { validationMessages } from 'baseline-ui/validation';
import { BUSINESS_LINKS_HTTPS_PROTOCOL } from 'common/consts';

const formAddBusinessLinksSchema = (links) =>
    yup.object().shape({
        type: yup.string().emptyToNull().required(),
        url: yup
            .string()
            .transform((_, originalValue) => {
                return `${BUSINESS_LINKS_HTTPS_PROTOCOL}${originalValue}`;
            })
            .url(validationMessages.url.matches)
            .test({
                name: 'alreadyExists',

                test: (value, context) => {
                    const { createError } = context;

                    if (!links.find((link) => link.url === value)) {
                        return true;
                    }

                    return createError({
                        message: 'error.addBusiness.page.sections.link.url.alreadyExists.label',
                    });
                },
            })

            .required(),
        caption: yup.string().emptyToNull().trim().required(),
    });

export default formAddBusinessLinksSchema;
