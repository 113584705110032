import React, { useEffect, useState, useContext } from 'react';
import { get } from 'lodash';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import { typographyPropTypes } from 'baseline-ui/typography';
import OverlayOverlay from './OverlayOverlay';
import OverlayContent from './OverlayContent';
import OverlayHeader from './OverlayHeader';
import OverlayBody from './OverlayBody';
import OverlayFooter from './OverlayFooter';
import OverlayCloseButton from './OverlayCloseButton';
import OverlayCloseIcon from './OverlayCloseButton/OverlayCloseIcon';
import overlayPropTypes from '../../prop-types/overlayPropTypes';
import overlayTheme from '../../styles/overlayTheme';
import overlayDarkTheme from '../../styles/overlayDarkTheme';

const Overlay = ({
    children,
    isContentCover,
    title,
    titleAriaLabel,
    customHeader,
    footerActions,
    footerPre,
    footerPost,
    customFooter,
    isOpen,
    onDismiss,
    closeIcon,
    closeIconColor,
    showCloseButton,
    size,
    entryTransitionY,
    isOverlayTransparent,
    offsetBottom,
    offsetTop,
    handleOnDismiss,
    onEntered,
    onExited,
    customBackgroundColor,
    $titleTypographyColor,
    isFullWidth,
}) => {
    const { themeMode } = useContext(ThemeModeContext);
    const intl = useIntl();

    const [showDialog, setShowDialog] = useState(isOpen);
    const [initialIsOpen, setInitialIsOpen] = useState(isOpen);
    const theme = useContext(ThemeProvider);

    const transitionY = get(theme, 'overlay.transition.y', overlayTheme.overlay.transition.y);
    const defaultCloseIconColor =
        themeMode === 'darkMode'
            ? get(theme, 'overlay.closeIcon.color', overlayDarkTheme.overlay.closeIcon.color)
            : get(theme, 'overlay.closeIcon.color', overlayTheme.overlay.closeIcon.color);

    const transition = useTransition(showDialog, {
        from: { opacity: 0, y: Number(entryTransitionY) || transitionY },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: Number(entryTransitionY) || transitionY },
        onDestroyed: () => {
            onDismiss();
            setInitialIsOpen(false);
        },
        onRest: () => {
            if (isOpen) {
                onEntered();
            } else {
                onExited();
            }
        },
    });

    useEffect(() => {
        setShowDialog(isOpen);
    }, [isOpen]);

    const renderCloseIcon = closeIcon || (
        <OverlayCloseIcon color={closeIconColor || defaultCloseIconColor} />
    );

    const AnimatedContainer = animated(OverlayOverlay);
    const AnimatedContentContainer = animated(OverlayContent);

    const hasDefaultFooter = (footerActions || footerPre || footerPost) && !customFooter;

    return (
        <>
            {transition(({ opacity, y }, item) => {
                return (
                    item && (
                        <AnimatedContainer
                            isOpen={initialIsOpen}
                            style={{ opacity }}
                            $isOverlayTransparent={isOverlayTransparent}
                        >
                            <AnimatedContentContainer
                                $customBackgroundColor={customBackgroundColor}
                                $offsetTop={offsetTop}
                                $offsetBottom={offsetBottom}
                                $isFullWidth={isFullWidth}
                                aria-label={titleAriaLabel || title}
                                style={{
                                    transform: y.to((value) => `translate3d(0, ${rem(value)}, 0)`),
                                }}
                                $modalSize={size}
                                $isOverlayTransparent={isOverlayTransparent}
                            >
                                <OverlayCloseButton
                                    aria-label={intl.formatMessage({ id: 'overlay.close.label' })}
                                    onClick={showCloseButton ? handleOnDismiss : () => {}}
                                    showCloseButton={showCloseButton}
                                >
                                    {renderCloseIcon}
                                </OverlayCloseButton>

                                {title && !customHeader && (
                                    <OverlayHeader
                                        title={title}
                                        $titleTypographyColor={$titleTypographyColor}
                                    />
                                )}
                                {customHeader && customHeader}
                                <OverlayBody
                                    $isContentCover={isContentCover}
                                    hasFooter={hasDefaultFooter}
                                >
                                    {children}
                                </OverlayBody>
                                {hasDefaultFooter && (
                                    <OverlayFooter
                                        footerActions={footerActions}
                                        footerPre={footerPre}
                                        footerPost={footerPost}
                                        handleOnDismiss={handleOnDismiss}
                                        customBackgroundColor={customBackgroundColor}
                                    />
                                )}
                                {customFooter && customFooter}
                            </AnimatedContentContainer>
                        </AnimatedContainer>
                    )
                );
            })}
        </>
    );
};

Overlay.propTypes = {
    children: PropTypes.node,
    isContentCover: PropTypes.bool,
    title: PropTypes.node,
    titleAriaLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    onDismiss: PropTypes.func,
    size: overlayPropTypes.overlaySize,
    customHeader: PropTypes.node,
    customFooter: PropTypes.node,
    footerActions: overlayPropTypes.overlayFooterActions,
    footerPre: PropTypes.node,
    footerPost: PropTypes.node,
    closeIcon: PropTypes.node,
    closeIconColor: PropTypes.string,
    showCloseButton: PropTypes.bool,
    entryTransitionY: PropTypes.number,
    isOverlayTransparent: PropTypes.bool,
    offsetBottom: PropTypes.number,
    offsetTop: PropTypes.number,
    handleOnDismiss: PropTypes.func.isRequired,
    onEntered: PropTypes.func,
    onExited: PropTypes.func,
    customBackgroundColor: PropTypes.string,
    $titleTypographyColor: typographyPropTypes.typographyColor,
    isFullWidth: PropTypes.bool,
};

Overlay.defaultProps = {
    children: undefined,
    isContentCover: false,
    title: undefined,
    titleAriaLabel: undefined,
    isOpen: false,
    onDismiss: () => {},
    size: 'default',
    customHeader: undefined,
    footerActions: undefined,
    footerPre: undefined,
    footerPost: undefined,
    customFooter: undefined,
    closeIcon: undefined,
    closeIconColor: undefined,
    showCloseButton: true,
    entryTransitionY: undefined,
    isOverlayTransparent: false,
    offsetBottom: undefined,
    offsetTop: undefined,
    onEntered: () => {},
    onExited: () => {},
    customBackgroundColor: undefined,
    $titleTypographyColor: undefined,
    isFullWidth: false,
};

export default Overlay;
