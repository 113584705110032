const collapsableTheme = {
    collapsable: {
        accordion: {
            backgroundColor: '#fff',
            border: '#dddddd',
            roundedCorners: true,
            borderRadius: 8,
            title: {
                fontSize: '1rem',
                fontWeight: '700',
            },
        },
    },
};

export default collapsableTheme;
