import { rem } from 'polished';
import styled, { css } from 'styled-components';

const LogoContainer = styled.div`
    align-items: center;
    display: flex;
    height: ${({ isVertical }) => (isVertical ? '50vh' : '100%')};
    line-height: 0;

    ${({ isVertical }) =>
        isVertical &&
        css`
            padding-left: ${rem(16)};
            padding-top: 10vh;
        `}
`;

export default LogoContainer;
