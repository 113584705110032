export const GET_BETA_SIGNUPS_PROCESS = 'GET_BETA_SIGNUPS_PROCESS';
export const GET_BETA_SIGNUPS_START = 'GET_BETA_SIGNUPS_START';
export const GET_BETA_SGNUPS_SUCCESS = 'GET_BETA_SGNUPS_SUCCESS';

export const PUT_BETA_SIGNUPS_PROCESS = 'PUT_BETA_SIGNUPS_PROCESS';
export const PUT_BETA_SIGNUPS_START = 'PUT_BETA_SIGNUPS_START';
export const PUT_BETA_SGNUPS_SUCCESS = 'PUT_BETA_SGNUPS_SUCCESS';

export const SET_NEXT_PAGE_START = 'SET_NEXT_PAGE_START';
export const SET_PREV_PAGE_START = 'SET_PREV_PAGE_START';
export const RESET_NEXT_PAGE_START = 'RESET_NEXT_PAGE_START';
