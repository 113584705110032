import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import overlayTheme from '../../styles/overlayTheme';

const OverlayBody = styled.div`
    padding-bottom: ${({ theme }) =>
        rem(get(theme, `overlay.padding.v`, overlayTheme.overlay.padding.v))};
    padding-left: ${({ theme }) =>
        rem(get(theme, `overlay.padding.h`, overlayTheme.overlay.padding.h))};
    padding-right: ${({ theme }) =>
        rem(get(theme, `overlay.padding.h`, overlayTheme.overlay.padding.h))};
    padding-top: ${({ theme }) =>
        rem(get(theme, `overlay.padding.v`, overlayTheme.overlay.padding.v))};
    position: relative;

    ${({ $isContentCover }) =>
        $isContentCover &&
        css`
            @media (min-width: 0) {
                padding: 0;
            }
        `}

    ${({ hasDefaultFooter }) =>
        !hasDefaultFooter &&
        css`
            @media (min-width: 0) {
                padding-bottom: ${({ theme }) =>
                    rem(get(theme, 'overlay.padding.v', overlayTheme.overlay.padding.v))};
            }
        `}
`;

export default OverlayBody;
