import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesFilteredListEmptyResultsContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${rem(8)};
`;

export default ProductsAndServicesFilteredListEmptyResultsContainer;
