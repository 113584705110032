import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { ModalContext, modalPropTypes } from 'baseline-ui/modal';
import { Button } from 'baseline-ui/button';
import { GaIcon } from 'baseline-ui/icon';
import { SrLabel } from 'baseline-ui/helpers';
import MenuModal from './MenuModal';
import headerPropTypes from '../../prop-types/headerPropTypes';
import headerTheme from '../../styles/headerTheme';
import HeaderMenuContainer from './HeaderMenuContainer';
import {HOME_PATH, LINK_NOTIFICATIONS} from "common/pathnames";
import {RenderButton} from "../../../../button";
import {NotificationCount} from "../Navigation/NotificationCount";
import {useDispatch, useSelector} from "react-redux";
import {unreadNotifications} from "../../../../../redux/selectors/notificationSelectors";
import {useEffect} from "react";
import {
    GET_ALL_LINK_NOTIFICATIONS_START
} from "../../../../../redux/actions/LinkNotificationActions";
import {useLocation} from "react-router-dom";

const HeaderMenu = ({ list, secondaryList, onClickLinkCallback, currentPath }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { showModal, component } = useContext(ModalContext);
    const theme = useContext(ThemeContext);
    const notificationCount = useSelector(unreadNotifications);
    const user = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    useEffect(() => {
        dispatch({type: GET_ALL_LINK_NOTIFICATIONS_START});
    }, []);
    const menuIcon = get(
        theme,
        'header.menuModal.menuIcon.icon',
        headerTheme.header.menuModal.menuIcon.icon,
    );
    const menuIconSize = get(
        theme,
        'header.menuModal.menuIcon.size',
        headerTheme.header.menuModal.menuIcon.size,
    );

    const menuIconColor = pathname === HOME_PATH
        ? get(theme, 'header.menuModal.menuIcon.darkColor', headerTheme.header.menuModal.menuIcon.darkColor)
        : get(theme, 'header.menuModal.menuIcon.color', headerTheme.header.menuModal.menuIcon.color);


    return (
        <HeaderMenuContainer sizeValue="sm">
            {user && <RenderButton
                icon="bell"
                path={LINK_NOTIFICATIONS}
                skin= "light"
                btnType= "link"
                size= "md"
                style={{position: "relative"}}
            >
                <NotificationCount>{notificationCount > 5 ? '5+' : notificationCount}</NotificationCount>
            </RenderButton>
            }

            <Button
                btnType="link"
                skin="muted"
                size="sm"
                onClick={() =>
                    showModal(MenuModal, {
                        list,
                        secondaryList,
                        onClickLinkCallback,
                        currentPath,
                    })
                }
                disabled={!!component}
            >
                <SrLabel as="span">
                    <FormattedMessage id="header.mobile.menu.title" />
                </SrLabel>
                <GaIcon icon={menuIcon} size={menuIconSize} color={menuIconColor} />
            </Button>
        </HeaderMenuContainer>
    );
};

HeaderMenu.propTypes = {
    list: headerPropTypes.list,
    secondaryList: modalPropTypes.modalFooterActions,
    onClickLinkCallback: PropTypes.func,
    currentPath: PropTypes.string,
};

HeaderMenu.defaultProps = {
    list: null,
    secondaryList: null,
    onClickLinkCallback: () => {},
    currentPath: undefined,
};

export default HeaderMenu;
