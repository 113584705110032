import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { Small } from 'baseline-ui/typography';
import {
    sectionProductsNameBySectionNameSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import ModalBrowseProductsFormSelectionContainer from './ModalBrowseProductsFormSelectionContainer';
import ModalBrowseProductsFormSelectionLabelContainer from './ModalBrowseProductsFormSelectionLabelContainer';

const ModalBrowseProductsFormSelectionLabel = () => {
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const sectionProductsNameBySectionName = useSelector(
        sectionProductsNameBySectionNameSelector(selectedSectionName),
    );

    if (isEmpty(sectionProductsNameBySectionName)) {
        return null;
    }

    return (
        <ModalBrowseProductsFormSelectionContainer>
            <ModalBrowseProductsFormSelectionLabelContainer>
                <Small textAlign="right">
                    <FormattedMessage
                        id="addBusiness.modal.browseProducts.seletectItems.overview.label"
                        values={{ items: <b>{sectionProductsNameBySectionName.join(', ')}</b> }}
                    />
                </Small>
            </ModalBrowseProductsFormSelectionLabelContainer>
        </ModalBrowseProductsFormSelectionContainer>
    );
};

export default ModalBrowseProductsFormSelectionLabel;
