import { baseTheme } from 'baseline-ui/base-theme';

const carouselTheme = {
    carousel: {
        arrows: {
            backgroundColor: baseTheme.base.grays.grayLightest,
            horizontal: {
                iconPrev: 'chevron-left',
                iconNext: 'chevron-right',
            },
            vertical: {
                iconPrev: 'chevron-up',
                iconNext: 'chevron-down',
            },
            default: {
                size: 22,
                iconSize: 16,
            },
            lg: {
                size: 34,
                iconSize: 22,
            },
            color: {
                default: baseTheme.base.grays.grayDarker,
                primary: baseTheme.base.grays.grayDarker,
            },
        },
        dots: {
            background: {
                default: baseTheme.base.grays.grayLighter,
                primary: 'transparent',
            },
            activeBackground: {
                default: baseTheme.base.grays.gray,
                primary: 'transparent',
            },
            border: {
                default: 'transparent',
                primary: baseTheme.base.colors.primary,
            },
            size: {
                default: 8,
                primary: 16,
            },
        },
    },
};

export default carouselTheme;
