import React from 'react';
import { PropTypes } from 'prop-types';
import { Field } from 'formik';
import { FormRadio } from 'baseline-ui/form';
import { EntityPickerContent } from './EntityPickerContent';
import { EntityPickerOptionItem } from './components';

export const EntityPickerOption = ({ entity, selectedField, selectedFieldId }) => {
    const isSelected = selectedField === entity._id;
    return (
        <EntityPickerOptionItem
            isSelected={isSelected}
            htmlFor={`${selectedFieldId}_${entity._id}`}
        >
            <EntityPickerContent entity={entity} />
            {selectedFieldId && (
                <div hidden>
                    <Field
                        component={FormRadio}
                        name={selectedFieldId}
                        id={`${selectedFieldId}_${entity._id}`}
                        value={entity._id}
                    />
                </div>
            )}
        </EntityPickerOptionItem>
    );
};
EntityPickerOption.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    entity: PropTypes.object.isRequired,
    selectedField: PropTypes.string.isRequired,
    selectedFieldId: PropTypes.string.isRequired,
};
EntityPickerOption.defaultProps = {
};

export default EntityPickerOption;
