import reboot from 'styled-reboot';
import { get } from 'lodash';
import { rem, lighten } from 'polished';
import { createGlobalStyle, css } from 'styled-components';
import { typographyTheme } from 'baseline-ui/typography';
import { baseTheme, baseScrollbarStyles } from 'baseline-ui/base-theme';

const globalOptions = ({ theme }) => {
    return {
        fontFamilyBase: get(
            theme,
            'typography.base.fontFamily',
            typographyTheme.typography.base.fontFamily,
        ),
        linkColor: get(theme, 'typography.link.color', typographyTheme.typography.link.color),
        linkDecoration: get(
            theme,
            'typography.link.decoration',
            typographyTheme.typography.link.decoration,
        ),
        linkHoverColor: lighten(
            0.1,
            get(theme, 'typography.link.color', typographyTheme.typography.link.color),
        ),
        linkHoverDecoration: get(
            theme,
            'typography.link.hoverDecoration',
            typographyTheme.typography.link.hoverDecoration,
        ),
        bodyColor: get(theme, 'base.colors.dark', baseTheme.base.colors.dark),
        bodyBg: get(theme, 'base.body.background', baseTheme.base.body.background),
        fontSizeBase: rem(
            get(theme, 'typography.base.fontSize', typographyTheme.typography.base.fontSize),
        ),
        paragraphMarginBottom: rem(
            get(theme, 'typography.paragraph.margin', typographyTheme.typography.paragraph.margin),
        ),
        lineHeightBase: get(
            theme,
            'typography.base.lineHeight',
            typographyTheme.typography.base.lineHeight,
        ),
        labelMarginBottom: get(
            theme,
            'typography.label.marginBottom',
            typographyTheme.typography.label.marginBottom,
        ),
    };
};

const rebootThemed = ({ theme }) => reboot(globalOptions({ theme }));

const rebootScrollbar = ({ theme }) => {
    const isEnabledOnBody = get(theme, 'base.scrollbar.onBody', baseTheme.base.scrollbar.onBody);

    if (!isEnabledOnBody) {
        return null;
    }

    return css`
        ${baseScrollbarStyles}
    `;
};

const GlobalStyle = createGlobalStyle`
    ol, ul {
        margin-bottom: ${rem(16)};
        padding-left: ${rem(16)};
    }

    /** Override focus-within used by react-drag-drop-files  */
    :focus-within {
        outline: 0 !important;
    }
 
    .intl-title {
      font-size: 3.375rem;
      font-weight: 700;
    }
    @media (max-width: 600px) {
      .intl-title {
        font-size: 2rem;
      }
    }
    @media (max-width: 375px) {
       .intl-title {
        font-size: 1.75rem;
      }
    }
    
    ${rebootThemed}
    ${rebootScrollbar}
`;

export default GlobalStyle;
