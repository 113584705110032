import React from "react";
import { useSelector } from "react-redux";
import { PROCESS_COMPLETE } from "../redux/actions/CommonActions";

export const useComplete = (processName) => {
  const processState = useSelector(
    ({ ProcessReducer }) => ProcessReducer[processName],
  );

  if (processState === PROCESS_COMPLETE) {
    return true;
  }
  return false;
};
