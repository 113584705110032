import {
  GET_SESSION_SUCCESS,
  LOGOUT_SUCCESS,
  POST_LOGIN_SUCCESS,
} from "../actions/AuthenticationActions";

const AuthenticationReducer = (state = {}, action) => {
  const { type, payload } = action;
  if (type === POST_LOGIN_SUCCESS || type === GET_SESSION_SUCCESS) {
    return {
      ...state,
      ...payload,
    };
  }
  if (type === LOGOUT_SUCCESS) {
    return {};
  }
  return {
    ...state,
  };
};

export default AuthenticationReducer;
