import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';

const Body = styled.div`
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    padding: ${rem(16)};

    ${({ isFullWidth }) =>
        isFullWidth &&
        css`
            padding: 0;
        `}
`;

export default Body;
