import React from 'react';
import PropTypes from 'prop-types';
import { GaIcon } from 'baseline-ui/icon';
import { buttonPropTypes } from 'baseline-ui/button';
import FavePagePreviewFavesButtonIcon from './FavePagePreviewFavesButtonIcon';
import FavePagePreviewFavesButtonLabel from './FavePagePreviewFavesButtonLabel';
import FavePagePreviewFavesButtonContainer from './FavePagePreviewFavesButtonContainer';

const FavePagePreviewFavesButton = ({ skin, icon, iconSize, children }) => {
    return (
        <FavePagePreviewFavesButtonContainer skin={skin}>
            {icon && (
                <FavePagePreviewFavesButtonIcon>
                    <GaIcon icon={icon} size={iconSize} />
                </FavePagePreviewFavesButtonIcon>
            )}
            <FavePagePreviewFavesButtonLabel>{children}</FavePagePreviewFavesButtonLabel>
        </FavePagePreviewFavesButtonContainer>
    );
};

FavePagePreviewFavesButton.propTypes = {
    skin: buttonPropTypes.buttonSkin.isRequired,
    icon: PropTypes.string,
    children: PropTypes.node.isRequired,
    iconSize: PropTypes.number,
};

FavePagePreviewFavesButton.defaultProps = {
    icon: undefined,
    iconSize: undefined,
};

export default FavePagePreviewFavesButton;
