export const GET_BUSINESS_ACCESS_RIGHTS_PROCESS = 'GET_BUSINESS_ACCESS_RIGHTS_PROCESS';
export const GET_BUSINESS_ACCESS_RIGHTS_START = 'GET_BUSINESS_ACCESS_RIGHTS_START';
export const GET_BUSINESS_ACCESS_RIGHTS_SUCCESS = 'GET_BUSINESS_ACCESS_RIGHTS_SUCCESS';
export const RESET_BUSINESS_ACCESS_RIGHTS_START = 'RESET_BUSINESS_ACCESS_RIGHTS_START';

export const ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS = 'ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS';
export const ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS_ERROR =
    'ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS_ERROR';
export const ADD_BUSINESS_ACCESS_RIGHTS_USER_SUCCESS = 'ADD_BUSINESS_ACCESS_RIGHTS_USER_SUCCESS';
export const ADD_BUSINESS_ACCESS_RIGHTS_USER_START = 'ADD_BUSINESS_ACCESS_RIGHTS_USER_START';

export const REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS =
    'REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS';
export const REMOVE_BUSINESS_ACCESS_RIGHTS_USER_SUCCESS =
    'REMOVE_BUSINESS_ACCESS_RIGHTS_USER_SUCCESS';
export const REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START = 'REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START';
