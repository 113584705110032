import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { GaIcon } from 'baseline-ui/icon';
import { TransitionItem } from 'baseline-ui/helpers';
import alertPropTypes from '../../prop-types/alertPropTypes';
import Container from './Container';
import CloseButton from './CloseButton';
import MessageContainer from './MessageContainer';

const DefaultAlert = ({ alertType, close, children, inProp, appear, showShadow, ...props }) => {
    const intl = useIntl();

    return (
        <TransitionItem inProp={inProp} appear={appear} transitionType="fadeInFadeOut" {...props}>
            <Container alertType={alertType} role="alert" showShadow={showShadow}>
                {!!close && (
                    <CloseButton
                        alertType={alertType}
                        onClick={close}
                        aria-label={intl.formatMessage({ id: 'defaultAlert.closeButton.label' })}
                    >
                        <GaIcon icon="x" size={16} color="currentColor" />
                    </CloseButton>
                )}
                <MessageContainer>{children}</MessageContainer>
            </Container>
        </TransitionItem>
    );
};

DefaultAlert.propTypes = {
    children: PropTypes.node.isRequired,
    alertType: alertPropTypes.type,
    close: PropTypes.func,
    inProp: PropTypes.bool,
    appear: PropTypes.bool,
    showShadow: PropTypes.bool,
};

DefaultAlert.defaultProps = {
    alertType: 'info',
    close: null,
    inProp: false,
    appear: true,
    showShadow: false,
};

export default DefaultAlert;
