import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { productToEditSectionsSelector } from 'redux/selectors/productSelectors';
import productsAndServicesUtils from 'utils/productsAndServicesUtils';

import ModalEditProductManageSectionsForm from './ModalEditProductManageSectionsForm';

const ModalEditProductManageSections = ({ ...props }) => {
    const productToEditSections = useSelector(productToEditSectionsSelector);
    const initialSelectedSectionsIds =
        productsAndServicesUtils.getProductIds(productToEditSections);

    const initialValues = {
        ...productsAndServicesUtils.initialValuesSelectedProductIdsFormik(
            initialSelectedSectionsIds,
        ),
    };

    return (
        <Formik initialValues={initialValues}>
            {() => {
                return <ModalEditProductManageSectionsForm {...props} />;
            }}
        </Formik>
    );
};

export default ModalEditProductManageSections;
