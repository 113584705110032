import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import modalTheme from '../../styles/modalTheme';

const ModalBody = styled.div`
    padding-bottom: ${({ theme }) =>
        rem(get(theme, `modal.padding.v`, modalTheme.modal.padding.v))};
    padding-left: ${({ theme }) => rem(get(theme, `modal.padding.h`, modalTheme.modal.padding.h))};
    padding-right: ${({ theme }) => rem(get(theme, `modal.padding.h`, modalTheme.modal.padding.h))};
    padding-top: ${({ theme }) => rem(get(theme, `modal.padding.v`, modalTheme.modal.padding.v))};
    position: relative;

    ${({ $isContentCover }) =>
        $isContentCover &&
        css`
            @media (min-width: 0) {
                padding: 0;
            }
        `}

    ${({ hasDefaultFooter }) =>
        !hasDefaultFooter &&
        css`
            @media (min-width: 0) {
                padding-bottom: ${({ theme }) =>
                    rem(get(theme, 'modal.padding.v', modalTheme.modal.padding.v))};
            }
        `}
`;

export default ModalBody;
