import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const TabletDesktop = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const minWidth = get(theme, 'layout.media.xs', layoutTheme.layout.media.xs);

    return <Responsive {...props} minWidth={minWidth} />;
};

// displayName for storybook
TabletDesktop.displayName = 'TabletDesktop';

const Hoc = TabletDesktop;
Hoc.displayName = TabletDesktop.displayName;
export default Hoc;
