import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewFavesContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${rem(8)};
    padding-bottom: ${rem(16)};
    padding-top: ${rem(8)};
`;

export default FavePagePreviewFavesContainer;
