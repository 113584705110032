import styled, { css } from 'styled-components';
import { rem } from 'polished';

const OverlayCloseButton = styled.button`
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.7;
    padding: 0;
    position: absolute;
    right: ${rem(8)};
    top: ${rem(8)};
    transition: opacity 0.15s ease-in-out;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }

    ${({ showCloseButton }) =>
        !showCloseButton &&
        css`
            &,
            &:hover {
                cursor: not-allowed;
                opacity: 0;
            }
        `}
`;

export default OverlayCloseButton;
