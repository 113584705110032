import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { H2, H4, P } from 'baseline-ui/typography';
import { FormInput, FieldContainer } from 'baseline-ui/form';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, DefaultAlert, alertPropTypes } from 'baseline-ui/alert';
import AuthFormContainer from './AuthFormContainer';
import AuthFormSignError from './AuthFormSignError';
import AuthHeadingContainer from './AuthHeadingContainer';
import formAccountSchema from '../schema/formAccountSchema';
import authPropTypes from '../prop-types/authPropTypes';

const FormAccount = ({
    onSubmitUpdateUser,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    inProp,
    inPropFeedback,
    accountInitialValues,
    feedbackType,
    feedbackMessage,
    isAccountDisabled,
    accountDisabledMessage,
    disabled,
}) => {
    const intl = useIntl();

    const submitUpdateUser = (values) => {
        onSubmitUpdateUser(values);
    };

    const initialValues = {
        ...accountInitialValues,
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="auth.account.yourAccount.heading.title" />
            </H2>
            <P>
                <FormattedMessage id="auth.account.yourAccount.heading.content" />
            </P>
        </AuthHeadingContainer>
    );

    const isDisabled = isAccountDisabled || isLoading || disabled;

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            {isAccountDisabled && (
                <DefaultAlert alertType="warning" inProp appear>
                    {accountDisabledMessage}
                </DefaultAlert>
            )}
            <TransitionItem inProp={inProp} appear>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formAccountSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values, { setFieldValue }) => {
                        submitUpdateUser(values);
                        setFieldValue('newPassword', '');
                    }}
                >
                    {() => {
                        return (
                            <Form noValidate>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.emailAddress.label',
                                        })}
                                        name="emailAddress"
                                        id="emailAddress"
                                        type="email"
                                        disabled={isDisabled}
                                        readOnly
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.username.label',
                                        })}
                                        name="username"
                                        id="username"
                                        type="text"
                                        disabled={isDisabled}
                                        required
                                    />
                                </FieldContainer>
                                <H4>
                                    <FormattedMessage id="auth.account.managePassword.heading.title" />
                                </H4>
                                <P>
                                    <FormattedMessage id="auth.account.managePassword.heading.content" />
                                </P>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.newPassword.label',
                                        })}
                                        name="newPassword"
                                        id="newPassword"
                                        type="password"
                                        disabled={isDisabled}
                                        preventWhiteSpaces
                                    />
                                </FieldContainer>
                                <H4>
                                    <FormattedMessage id="auth.account.saveChanges.heading.title" />
                                </H4>
                                <P>
                                    <FormattedMessage id="auth.account.saveChanges.heading.content" />
                                </P>
                                <Button
                                    skin={buttonSkin}
                                    btnType={buttonBtnType}
                                    block
                                    type="submit"
                                    loading={isLoading}
                                    loadingMessage={intl.formatMessage({
                                        id: 'auth.saveChanges.loading.label',
                                    })}
                                    disabled={isDisabled}
                                >
                                    <FormattedMessage id="auth.saveChanges.label" />
                                </Button>
                                <AuthFormSignError errorMessage={errorMessage} />
                            </Form>
                        );
                    }}
                </Formik>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormAccount.propTypes = {
    onSubmitUpdateUser: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    accountInitialValues: PropTypes.shape({
        emailAddress: PropTypes.string,
        username: PropTypes.string,
        newPassword: PropTypes.string,
    }).isRequired,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    isAccountDisabled: PropTypes.bool,
    accountDisabledMessage: PropTypes.string,
    disabled: PropTypes.bool,
};

FormAccount.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    isAccountDisabled: false,
    accountDisabledMessage: undefined,
    disabled: false,
};

export default FormAccount;
