import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const FavePageMainContainer = styled.div`
    position: relative;

    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(24)};
        padding-top: ${({ theme }) => rem(theme.site.page.main.spacingV)};
    `}
`;

export default FavePageMainContainer;
