import { rem } from 'polished';
import styled from 'styled-components';

const AddFavePageAsideSectionContainer = styled.div`
    margin-bottom: ${rem(12)};
    padding-left: ${rem(4)};
    padding-right: ${rem(4)};
`;

export default AddFavePageAsideSectionContainer;
