import styled, { css } from 'styled-components';

const ProductsAndServicesItemButton = styled.button`
    background-color: transparent;
    border: 0;
    display: flex;
    text-align: left;
    user-select: none;
    width: 100%;

    ${({ allowDrag }) =>
        allowDrag &&
        css`
            cursor: grab;
        `}
`;

export default ProductsAndServicesItemButton;
