import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CLAIMS_START,
  RESET_CLAIM_FILTERS_START,
  SET_CLAIM_FILTERS_START,
} from "../redux/actions/ClaimsActions";

const SORT_OPTIONS = [
  { value: "LASTNAME", label: "Last name" },
  { value: "FIRSTNAME", label: "First name" },
  { value: "CLAIMSTATE", label: "Claim state" },
  { value: "CREATED", label: "Created date" },
  { value: "UPDATED", label: "Last updated date" },
];

const CLAIM_STATE_OPTIONS = [
  { value: "SUBMITTED", label: "Submitted" },
  { value: "APPROVED", label: "Approved" },
  { value: "REJECTED", label: "Rejected" },
];

const SORT_ORDER_OPTIONS = [
  { value: "DESC", label: "Descending" },
  { value: "ASC", label: "Ascending" },
];

const ClaimsSearchForm = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(({ ClaimsReducer }) => ClaimsReducer);
  const { sortFields, sortOrders, claimState, ticket } = filters;

  return (
    <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
      <TextField
        fullWidth
        label={"Ticket"}
        value={ticket ? ticket : ""}
        onChange={(el) =>
          dispatch({
            type: SET_CLAIM_FILTERS_START,
            payload: {
              ticket: el.target.value !== "" ? el.target.value : undefined,
            },
          })
        }
      />
      <Box>
        <FormControl>
          <FormLabel>Sort order</FormLabel>
          <RadioGroup row name="row-radio-buttons-group">
            {SORT_ORDER_OPTIONS.map((item) => (
              <FormControlLabel
                key={`sort_order_options_${item.value}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
                checked={sortOrders === item.value}
                onClick={(value) =>
                  dispatch({
                    type: SET_CLAIM_FILTERS_START,
                    payload: { sortOrders: value.target.value },
                  })
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <FormLabel>Sort by</FormLabel>
          <RadioGroup row name="row-radio-buttons-group">
            {SORT_OPTIONS.map((item) => (
              <FormControlLabel
                key={`sort_options_${item.value}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
                checked={sortFields === item.value}
                onClick={(value) =>
                  dispatch({
                    type: SET_CLAIM_FILTERS_START,
                    payload: { sortFields: value.target.value },
                  })
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <FormLabel>Claim state</FormLabel>
          <RadioGroup row name="row-radio-buttons-group">
            {CLAIM_STATE_OPTIONS.map((item) => (
              <FormControlLabel
                key={`state_optoins_${item.value}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
                checked={claimState === item.value}
                onClick={(value) =>
                  dispatch({
                    type: SET_CLAIM_FILTERS_START,
                    payload: { claimState: value.target.value },
                  })
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <FormControl
        sx={{
          display: "inline",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          type={"primary"}
          onClick={() => dispatch({ type: RESET_CLAIM_FILTERS_START })}
        >
          Reset
        </Button>
        <Button
          type={"primary"}
          variant={"outlined"}
          onClick={() => dispatch({ type: GET_CLAIMS_START })}
        >
          Search
        </Button>
      </FormControl>
    </Paper>
  );
};

export default ClaimsSearchForm;
