import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import horizontalScrollTheme from './horizontalScrollTheme';

const horizontalScrollbarStyles = () => css`
    &::-webkit-scrollbar {
        height: ${({ theme }) =>
            rem(
                get(
                    theme,
                    'horizontalScroll.scrollbar.height',
                    horizontalScrollTheme.horizontalScroll.scrollbar.height,
                ),
            )};
    }

    &::-webkit-scrollbar-track {
        border-radius: ${({ theme }) =>
            rem(get(theme, 'base.borderRadius.default', baseTheme.base.borderRadius.default))};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) =>
            get(theme, 'base.grays.grayLighter', baseTheme.base.grays.grayLighter)};
        border-radius: ${({ theme }) =>
            rem(get(theme, 'base.borderRadius.default', baseTheme.base.borderRadius.default))};
    }
`;

export default horizontalScrollbarStyles;
