import {
    POST_VALIDATE_PASSWORD_RESET_CODE_SUCCESS,
    SET_RESET_PASSWORD_CODE_SUCCESS,
    SET_RESET_PASSWORD_IDENTIFIER_PROCESS,
} from '../actions/ResetPasswordActions';

const ResetPasswordReducer = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        // case POST_VALIDATE_PASSWORD_RESET_CODE_SUCCESS: {
        //     return {
        //         ...state,
        //         code: payload,
        //     };
        // }
        case SET_RESET_PASSWORD_IDENTIFIER_PROCESS: {
            return {
                ...state,
                identifier: payload,
            };
        }
        case SET_RESET_PASSWORD_CODE_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default ResetPasswordReducer;
