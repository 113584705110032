import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { TransitionItem } from 'baseline-ui/helpers';
import ErrorContainer from './ErrorContainer';
import FeedBack from './FeedBack';
import formPropTypes from '../../prop-types/formPropTypes';

const FormInputFeedback = ({ intl, skin, id, feedbackType, message, centered, children }) => {
    const [messageTag, value1, value2] = message ? message.split('__') : '';

    return (
        <TransitionItem appear inProp={!!(message || children)}>
            <ErrorContainer centered={centered} aria-live="polite">
                <FeedBack feedbackType={feedbackType} id={`${id}-${feedbackType}`} skin={skin}>
                    {message && intl.formatMessage({ id: messageTag }, { value1, value2 })}
                    {children}
                </FeedBack>
            </ErrorContainer>
        </TransitionItem>
    );
};

FormInputFeedback.propTypes = {
    intl: PropTypes.shape().isRequired,
    feedbackType: PropTypes.oneOf(['error']).isRequired,
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
    skin: formPropTypes.formInputSkin,
    children: PropTypes.node,
    centered: PropTypes.bool,
};

FormInputFeedback.defaultProps = {
    skin: 'dark',
    message: undefined,
    children: undefined,
    centered: false,
};

export default injectIntl(FormInputFeedback);
