import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalContext } from 'baseline-ui/modal';
import { DELETE_SECTION_START } from 'redux/actions/SectionActions';
import { useIntl } from 'react-intl';
import {
    selectedSectionIndexSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalDeleteSection = ({ onRequestClose, onClickDeleteSectionCallback, ...props }) => {
    const dispatch = useDispatch();
    const { hideModal } = useContext(ModalContext);
    const intl = useIntl();
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const selectedSectionIndex = useSelector(selectedSectionIndexSelector);

    return (
        <Modal
            size="sm"
            handleOnDismiss={onRequestClose}
            title={intl.formatMessage({ id: 'addBusiness.modal.deleteSectionName.title' })}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deleteSectionName.footerActions.cancel.button.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => hideModal(),
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deleteSectionName.footerActions.delete.button.label',
                    }),
                    skin: 'danger',
                    onClick: () => {
                        dispatch({
                            type: DELETE_SECTION_START,
                            payload: selectedSectionIndex,
                        });
                        hideModal();
                        onClickDeleteSectionCallback();
                    },
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage(
                    { id: 'addBusiness.modal.addSectionName.content.md' },
                    { sectionName: selectedSectionName },
                )}
            />
        </Modal>
    );
};

ModalDeleteSection.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onClickDeleteSectionCallback: PropTypes.func.isRequired,
};

export default ModalDeleteSection;
