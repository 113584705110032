import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EntityTypeTagContainer from './EntityTypeTagContainer';

const EntityTypeTag = ({ type }) => {
    return (
        <EntityTypeTagContainer>
            <FormattedMessage id={`myBusinesses.listItem.entityType.tag.${type}.label`} />
        </EntityTypeTagContainer>
    );
};

EntityTypeTag.propTypes = {
    type: PropTypes.string.isRequired,
};

export default EntityTypeTag;
