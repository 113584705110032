import PropTypes from 'prop-types';
import { buttonSkinData, buttonBtnTypeData, buttonSizeData } from 'baseline-ui/button';
import overlaySizeData from '../data/overlaySizeData';

const overlaySize = PropTypes.oneOf(overlaySizeData);

const overlayFooterAction = PropTypes.shape({
    onClick: PropTypes.func,
    isExternal: PropTypes.bool,
    path: PropTypes.string,
    skin: PropTypes.oneOf(buttonSkinData),
    icon: PropTypes.string,
    btnType: PropTypes.oneOf(buttonBtnTypeData),
    size: PropTypes.oneOf(buttonSizeData),
    label: PropTypes.string,
    onClickDismiss: PropTypes.bool,
    disabled: PropTypes.bool,
});

const overlayFooterActions = PropTypes.arrayOf(overlayFooterAction);

export default { overlaySize, overlayFooterActions };
