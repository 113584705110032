import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
} from '@mui/material';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    ADD_BUSINESS_ACCESS_RIGHTS_USER_START,
    GET_BUSINESS_ACCESS_RIGHTS_START,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
} from '../../redux/actions/BusinessAccessRightsActions';
import ErrorAlertComponent from '../../components/ErrorAlertComponent';
import TextInput from '../../components/TextxInput';
import PrimaryButton from '../../components/PrimaryButton';

let schema = yup.object().shape({
    email: yup.string().email(),
});

const ConfirmDeletionModal = ({ show, closeCallback, businessId, userId }) => {
    const dispatch = useDispatch();
    const { users } = useSelector(({ BusinessAccessRightsReducer }) => BusinessAccessRightsReducer);
    const userToDelete = users.find((user) => user._id === userId);

    return (
        <Dialog open={show}>
            <DialogTitle>Dialog title</DialogTitle>
            <DialogContent>Are you sure you want to remove {userToDelete?.email}?</DialogContent>
            <DialogActions>
                <Button onClick={closeCallback}>Cancel</Button>
                <Button
                    onClick={() => {
                        dispatch({
                            type: REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
                            payload: {
                                entityid: businessId,
                                userId,
                            },
                        });
                        closeCallback();
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ERROR_VALIDATION_ERROR = 'Invalid erro message';

const BusinessAccessRights = ({ businessId }) => {
    const dispatch = useDispatch();
    const [userToDelete, setUserToDelete] = useState();
    const [autocomplete, setAutocomplete] = useState('');
    const [error, setError] = useState('');
    const { users } = useSelector(({ BusinessAccessRightsReducer }) => BusinessAccessRightsReducer);

    useEffect(() => {
        dispatch({
            type: GET_BUSINESS_ACCESS_RIGHTS_START,
            payload: businessId,
        });
    }, []);

    return (
        <Grid container>
            <Grid item md={1} xs={0} />
            <Grid item container xs={12} md={10}>
                <ConfirmDeletionModal
                    businessId={businessId}
                    userId={userToDelete}
                    show={userToDelete !== undefined}
                    closeCallback={() => setUserToDelete(undefined)}
                />
                <Grid item container xs={12}>
                    <ErrorAlertComponent processName={ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS} />
                    <Grid container>
                        <Typography variant="h6">Access rights management</Typography>
                    </Grid>
                    <Grid container>
                        <Typography variant="body2">
                            This page shows all the users that can manage this business. To add a
                            new user simply type in the email address of that user and hit enter.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} container item sx={{ height: '56px' }}>
                    {error}
                    <Grid xs={10} container item>
                        <TextInput
                            value={autocomplete}
                            onBlur={async () => {
                                const isValid = await schema.isValid({
                                    email: autocomplete,
                                });
                                if (isValid) {
                                    setError(undefined);
                                } else {
                                    setError(ERROR_VALIDATION_ERROR);
                                }
                            }}
                            onChange={(e, val) => {
                                setAutocomplete(e.target.value);
                            }}
                            fullWidth
                            error={!!error}
                            helperText={error}
                            label="Email"
                            sx={{ marginTop: 2 }}
                        />
                    </Grid>
                    <Grid xs={2} container item justifyContent={'center'} alignItems={'center'}>
                        <PrimaryButton
                            sx={{ minHeight: '56px', marginLeft: '10px', marginTop: '16px' }}
                            variant="outlined"
                            onClick={async () => {
                                const isValid = await schema.isValid({
                                    email: autocomplete,
                                });
                                if (isValid) {
                                    dispatch({
                                        type: ADD_BUSINESS_ACCESS_RIGHTS_USER_START,
                                        payload: {
                                            businessId,
                                            email: autocomplete,
                                        },
                                    });
                                    setAutocomplete('');
                                    setError(undefined);
                                } else {
                                    setError(ERROR_VALIDATION_ERROR);
                                }
                            }}
                            caption={'Add'}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <List sx={{ width: '100%' }}>
                        <ListSubheader sx={{ paddingLeft: 0 }}>Business Owners</ListSubheader>
                        {users?.map((user, idx) => (
                            <ListItem key={`user_key_${user._id}`} sx={{ paddingLeft: 0 }}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <ListItemText>{user.email}</ListItemText>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        display="flex"
                                        justifyContent={'center'}
                                        alignItems="center"
                                    >
                                        <Button onClick={() => setUserToDelete(user._id)}>
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
            <Grid xs={0} md={1} />
        </Grid>
    );
};
export default BusinessAccessRights;
