import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';

export const productToEditSelector = (state) => state.EditProductReducer.productToEdit;

export const productToEditNameSelector = (state) => state.EditProductReducer.productToEdit.name;

export const originalProductSelector = (state) => state.EditProductReducer.originalProduct;

export const editedProductsSelector = (state) => state.EditProductReducer.editedProducts;

export const productToEditCategoriesSelector = (state) =>
    state.EditProductReducer.productToEdit.mapping.tags;

export const productToEditSectionsSelector = (state) =>
    state.EditProductReducer.productToEdit.sectionsFoundIn;

export const productToEditSectionsNameSelector = createSelector(
    productToEditSectionsSelector,
    (productToEditSections) => map(productToEditSections, 'name'),
);

export const productToEditCategoriesSortedByNameSelector = createSelector(
    productToEditCategoriesSelector,
    (productToEditCategories) => sortBy(productToEditCategories, ['name']),
);

export const allItemCategoriesSelector = (state) => state.CategoriesReducer.itemCategories;

export const productAllCategoriesAndFiltersSelector = createSelector(
    allItemCategoriesSelector,
    (allCategoriesAndFilters) => sortBy(allCategoriesAndFilters, ['name']),
);
