import { createGlobalStyle } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import headingStyles from '../styles/headingStyles';
import h1Styles from '../styles/h1Styles';
import h2Styles from '../styles/h2Styles';
import h3Styles from '../styles/h3Styles';
import h4Styles from '../styles/h4Styles';
import h5Styles from '../styles/h5Styles';
import h6Styles from '../styles/h6Styles';
import paragraphStyles from '../styles/paragraphStyles';
import paragraphLeadStyles from '../styles/paragraphLeadStyles';
import smallStyles from '../styles/smallStyles';
import typographyTheme from '../styles/typographyTheme';

const TypographyGlobalStyles = createGlobalStyle`
    html {
        font-size: min(max(${({ theme }) =>
            rem(
                get(
                    theme,
                    'typography.base.fontSizeMin',
                    typographyTheme.typography.base.fontSizeMin,
                ),
            )}, ${({ theme }) =>
            get(
                theme,
                'typography.base.fontSizeViewport',
                typographyTheme.typography.base.fontSizeViewport,
            )}vw), ${({ theme }) =>
            rem(
                get(
                    theme,
                    'typography.base.fontSizeMax',
                    typographyTheme.typography.base.fontSizeMax,
                ),
            )});
    }
    h1, h2, h3, h4, h5, h6 {
        ${headingStyles}
    }
    h1 {
        ${h1Styles}
    }
    h2 {
       ${h2Styles}
    }
    h3 {
        ${h3Styles}
    }
    h4 {
       ${h4Styles}
    }
    h5 {
        ${h5Styles}
    }
    h6 {
       ${h6Styles}
    }
    p {
        ${paragraphStyles}
        &.lead {
            ${paragraphLeadStyles}
        }
    }
    small {
        ${smallStyles}
    }
    strong {
        font-weight: ${({ theme }) =>
            get(
                theme,
                'typography.strong.fontWeight',
                typographyTheme.typography.strong.fontWeight,
            )} !important;
    }
`;

export default TypographyGlobalStyles;
