import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import UnorderedList from './UnorderedList';

const UL = ({ list, className, ...props }) => {
    return (
        <UnorderedList className={className}>
            <List list={list} {...props} />
        </UnorderedList>
    );
};

UL.propTypes = {
    list: PropTypes.arrayOf(PropTypes.node).isRequired,
    className: PropTypes.string,
};

UL.defaultProps = {
    className: null,
};

export default UL;
