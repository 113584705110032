import styled, { css } from 'styled-components';
import { rem } from 'polished';

const AssetImage = styled.img`
    height: auto;
    line-height: 1;
    width: auto;

    ${({ containerHeight }) =>
        containerHeight &&
        css`
            height: 100%;
        `}

    ${({ containerHeight }) =>
        containerHeight &&
        css`
            height: 100%;
        `}

    ${({ assetHeight, assetWidth }) =>
        assetHeight &&
        !assetWidth &&
        css`
            height: ${rem(assetHeight)};
        `}

    ${({ assetHeight, assetWidth }) =>
        !assetHeight &&
        assetWidth &&
        css`
            width: ${rem(assetWidth)};
        `}
`;

export default AssetImage;
