import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import { paragraphStyles, typographyTheme } from 'baseline-ui/typography';

const FeedbackLabel = styled.div`
    ${paragraphStyles}
    color: ${({ theme, alertType }) =>
        get(theme, `base.colors.${alertType}`, baseTheme.base.colors[alertType])};
    font-weight: ${({ theme }) =>
        get(theme, 'typography.strong.fontWeight', typographyTheme.typography.strong.fontWeight)};
    max-width: ${rem(300)};
    text-align: center;
`;

export default FeedbackLabel;
