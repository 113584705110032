import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import footerTheme from '../../styles/footerTheme';

const FooterContentWrapper = styled.footer`
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) =>
                get(
                    theme,
                    'footer.defaultFooter.backgroundStart',
                    footerTheme.footer.defaultFooter.backgroundStart,
                )}
            0,
        ${({ theme }) =>
                get(
                    theme,
                    'footer.defaultFooter.backgroundEnd',
                    footerTheme.footer.defaultFooter.backgroundEnd,
                )}
            100%
    );
    padding: ${rem(16)} 0 ${rem(4)};
    position: relative;
    width: 100%;
    z-index: 1;

    ${({ showDivider, theme }) =>
        showDivider &&
        css`
            border-top: 1px solid
                ${get(
                    theme,
                    'footer.defaultFooter.dividerColor',
                    footerTheme.footer.defaultFooter.dividerColor,
                )};
        `}
`;

export default FooterContentWrapper;
