import { createGlobalStyle } from 'styled-components';

// https://reach.tech/styling/

const ModalGlobalStyle = createGlobalStyle`
    :root {
        --reach-dialog: 1;
    }
`;

export default ModalGlobalStyle;
