import { rem, transparentize } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesItemCard = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    background-image: linear-gradient(90deg, #fef5f6 0%, #f2edf6 50%, #f2f6ff 100%);
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    display: flex;
    margin-top: ${rem(8)};
    padding: ${rem(8)} ${rem(8)} ${rem(8)} ${rem(16)};
    position: relative;
    z-index: 1;

    &:after {
        border: 1px solid ${({ theme }) => transparentize(0.6, theme.base.grays.grayLightest)};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export default ProductsAndServicesItemCard;
