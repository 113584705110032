import styled from 'styled-components';
import { get } from 'lodash';
import { DialogOverlay } from '@reach/dialog';
import modalTheme from '../../styles/modalTheme';

const ModalOverlay = styled(DialogOverlay)`
    background-color: ${({ theme, $isOverlayTransparent }) =>
        $isOverlayTransparent
            ? 'transparent'
            : get(theme, 'modal.overlay.color', modalTheme.modal.overlay.color)};
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => get(theme, 'modal.overlay.zIndex', modalTheme.modal.overlay.zIndex)};
`;

export default ModalOverlay;
