import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import carouselTheme from '../styles/carouselTheme';

const Arrow = styled.span`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: ${(props) =>
        rem(
            get(
                props,
                `theme.carousel.arrows[${props.arrowSize}].size`,
                carouselTheme.carousel.arrows[props.arrowSize].size,
            ),
        )};
    padding: 0;
    width: ${(props) =>
        rem(
            get(
                props,
                `theme.carousel.arrows[${props.arrowSize}].size`,
                carouselTheme.carousel.arrows[props.arrowSize].size,
            ),
        )};

    ${(props) =>
        props.arrowType === 'primary' &&
        css`
            background-color: ${get(
                props,
                'theme.carousel.arrows.backgroundColor',
                carouselTheme.carousel.arrows.backgroundColor,
            )};
            border-radius: 50%;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            transition: box-shadow 0.15s ease-in-out;

            &:active {
                box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        `}

    ${(props) =>
        props.arrowType === 'default' &&
        css`
            background-color: transparent;
        `}

    ${(props) =>
        props.vertical &&
        css`
            margin: 0 auto;
        `}
`;

export default Arrow;
