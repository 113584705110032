import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import typographyTheme from './typographyTheme';

const paragraphStyles = ({ theme, textAlign, colorValue }) => css`
    color: ${get(
        theme,
        `typography.colors.${colorValue}`,
        typographyTheme.typography.colors[colorValue],
    )};
    font-family: ${get(
        theme,
        'typography.base.fontFamily',
        typographyTheme.typography.base.fontFamily,
    )};
    font-size: ${rem(
        get(theme, 'typography.base.fontSize', typographyTheme.typography.base.fontSize),
    )};
    font-weight: ${get(
        theme,
        'typography.paragraph.fontWeight',
        typographyTheme.typography.paragraph.fontWeight,
    )};
    text-align: ${textAlign};
`;

export default paragraphStyles;
