import { rem } from 'polished';
import styled from 'styled-components';

const ModalBrowseProductsFormListContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    max-height: ${rem(320)};
    overflow-y: auto;
    padding: ${rem(16)} ${rem(24)} ${rem(8)};
`;

export default ModalBrowseProductsFormListContainer;
