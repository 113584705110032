const loadScript = ({ url, id, callback }) => {
    const scriptById = document.getElementById(id);
    if (!scriptById) {
        const script = document.createElement('script');
        script.type = 'text/javascript';

        script.src = url;
        script.id = id;
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = () => {
            if (!callback) {
                return null;
            }
            return callback();
        };
    }

    if (scriptById) {
        return callback();
    }

    return null;
};

export default loadScript;
