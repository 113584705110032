import React from 'react';
import PropTypes from 'prop-types';
import GridList from './GridList';
import GridListItem from './GridListItem';

const ULGrid = ({ list, rowAmount, className, ...props }) => {
    return (
        <GridList className={className}>
            <GridListItem rowAmount={rowAmount} list={list} {...props} />
        </GridList>
    );
};

ULGrid.propTypes = {
    list: PropTypes.arrayOf(PropTypes.node).isRequired,
    rowAmount: PropTypes.number.isRequired,
    className: PropTypes.string,
};

ULGrid.defaultProps = {
    className: null,
};

export default ULGrid;
