const baseColorData = [
    'primary',
    'secondary',
    'muted',
    'info',
    'success',
    'warning',
    'danger',
    'light',
    'dark',
];

export default baseColorData;
