import styled, { css } from 'styled-components';

const ImageSrcsetContainer = styled.img`
    ${({ imageFluid }) =>
        imageFluid &&
        css`
            height: auto;
            max-width: 100%;
        `}
`;

export default ImageSrcsetContainer;
