import { rem } from 'polished';
import styled from 'styled-components';

const CreateFavePageMainContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: ${rem(24)};
`;

export default CreateFavePageMainContainer;
