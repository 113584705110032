import {
  GET_SUGGESTIONS_SUCCESS,
  RESET_SUGGESTIONS_NEXT_PAGE,
  SET_SUGGESTIONS_NEXT_PAGE,
  SET_SUGGESTIONS_PREV_PAGE,
  SET_SUGGESTIONS_SORT_DIRECTION_FILTER,
  SET_SUGGESTIONS_SORT_FIELD_FILTER,
  SET_SUGGESTIONS_STATE_FILTER,
} from "../actions/SuggestActions";

const SuggestReducer = (
  state = {
    suggestions: [],
    hasNext: true,
    page: 0,
    stateFilter: "SUBMITTED",
    sortField: "CREATED",
    sortDirection: "DESC",
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUGGESTIONS_SUCCESS: {
      let hasNext = true;
      if (payload.length < 10) {
        hasNext = false;
      }
      return {
        ...state,
        suggestions: payload,
        hasNext,
      };
    }
    case SET_SUGGESTIONS_SORT_DIRECTION_FILTER: {
      return {
        ...state,
        page: 0,
        sortDirection: payload,
      };
    }
    case SET_SUGGESTIONS_SORT_FIELD_FILTER: {
      return {
        ...state,
        page: 0,
        sortField: payload,
      };
    }
    case SET_SUGGESTIONS_STATE_FILTER: {
      return {
        ...state,
        stateFilter: payload,
        page: 0,
      };
    }
    case SET_SUGGESTIONS_NEXT_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case SET_SUGGESTIONS_PREV_PAGE: {
      return {
        ...state,
        page: state.page - 1,
      };
    }
    case RESET_SUGGESTIONS_NEXT_PAGE: {
      return {
        ...state,
        page: 0,
        hasNext: true,
        suggestions: undefined,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default SuggestReducer;
