import { typographyUtils } from 'baseline-ui/typography';
import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesListItemTotalFaves = styled.div`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.typography.strong.fontWeight};
    margin-left: ${rem(8)};
    margin-top: ${({ theme }) =>
        typographyUtils.headingMarginByPaddingRatio({ theme, heading: 'h5' })};
`;

export default ProductsAndServicesListItemTotalFaves;
