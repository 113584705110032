import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ErrorAlertComponent from "../components/ErrorAlertComponent";
import {
  GET_CLAIMS_START,
  PUT_APPROVE_CLAIM_STATE_PROCESS,
  PUT_APPROVE_CLAIM_STATE_START,
  PUT_REJECT_CLAIM_STATE_PROCESS,
  PUT_REJECT_CLAIM_STATE_START,
} from "../redux/actions/ClaimsActions";
import ClaimsSearchForm from "./ClaimsSearchForm";

const RejectModal = ({ show, closeCallback, claimId }) => {
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState("");

  return (
    <Dialog open={show} onClose={closeCallback}>
      <DialogTitle>Reject claim</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to reject a claim. Please provide an explanation why you
          want to reject it.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          multiline
          rows={5}
          value={rejectReason}
          onChange={(el) => setRejectReason(el.target.value)}
          label="Rejection reason"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback}>Cancel</Button>
        <Button
          onClick={() => {
            dispatch({
              type: PUT_REJECT_CLAIM_STATE_START,
              payload: {
                claimid: claimId,
                rejectreason: rejectReason,
              },
            });
            closeCallback();
          }}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Claims = () => {
  const dispatch = useDispatch();
  const { claims } = useSelector(({ ClaimsReducer }) => ClaimsReducer);

  const [rejectedClaimId, setRejectedClaimId] = useState();

  useEffect(() => {
    dispatch({
      type: GET_CLAIMS_START,
    });
  }, []);

  return (
    <Grid container>
      <ClaimsSearchForm />
      <RejectModal
        claimId={rejectedClaimId}
        show={rejectedClaimId !== undefined}
        closeCallback={() => setRejectedClaimId(undefined)}
      />
      <ErrorAlertComponent processName={PUT_APPROVE_CLAIM_STATE_PROCESS} />
      <ErrorAlertComponent processName={PUT_REJECT_CLAIM_STATE_PROCESS} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {claims?.map((claim) => (
          <div key={`claim_${claim._id}`}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={`${claim.firstName} ${claim.lastName} is claiming ${claim.business.name}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component={"div"}
                      gutterBottom
                      variant="body2"
                      color="text.primary"
                    ></Typography>
                    <Typography variant="body2" color="text.primary">
                      Ticket id: {claim.ticket}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      Name: {claim.firstName} {claim.lastName}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      Cell phone: {claim.cellphone}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      Business phone: {claim.businessphone}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      Email: {claim.email}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      State: {claim.claimstate}
                    </Typography>
                    {claim.claimstate === "SUBMITTED" && (
                      <Button
                        onClick={() =>
                          dispatch({
                            type: PUT_APPROVE_CLAIM_STATE_START,
                            payload: { claimid: claim._id },
                          })
                        }
                      >
                        Approve
                      </Button>
                    )}
                    {claim.claimstate === "SUBMITTED" && (
                      <Button onClick={() => setRejectedClaimId(claim._id)}>
                        Reject
                      </Button>
                    )}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider component="li" />
          </div>
        ))}
      </List>
    </Grid>
  );
};
export default Claims;
