import PropTypes from 'prop-types';
import transitionTypeData from '../data/transitionTypeData';

const transitionType = PropTypes.oneOf(transitionTypeData);
const transitionTimeout = PropTypes.number;

const fixedImage = PropTypes.shape({
    base64: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
});

const fluidImage = PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    aspectRatio: PropTypes.number,
});

const fluidImageSet = PropTypes.arrayOf(fluidImage);
const fixedImageSet = PropTypes.arrayOf(fixedImage);

export default {
    transitionType,
    transitionTimeout,
    fluidImageSet,
    fixedImageSet,
    fluidImage,
    fixedImage,
};
