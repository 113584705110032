import { baseTheme } from 'baseline-ui/base-theme';
import { typographyTheme } from 'baseline-ui/typography';

const tabsTheme = {
    tabs: {
        tab: {
            color: typographyTheme.typography.colors.dark,
            backgroundColor: '#fff',
            borderColor: 'transparent',
            selectedBorderColor: typographyTheme.typography.link.color,
            minHeight: 60,
        },
        panel: {
            backgroundColor: '#fff',
            borderColor: baseTheme.base.grays.grayLighter,
            borderRadius: baseTheme.base.borderRadius.default,
            shadow: true,
        },
        title: {
            fontFamily: typographyTheme.typography.base.fontFamily,
            fontSize: 12,
            fontWeight: '700',
            uppercase: true,
        },
        scrollButton: {
            color: 'inherit',
            backgroundColor: '#fff',
        },
    },
};

export default tabsTheme;
