import styled, { css } from 'styled-components';

const TabsContainer = styled.div`
    display: inline-flex;

    ${({ justified }) =>
        justified &&
        css`
            width: 100%;
        `};

    ${({ tabsPosition }) =>
        tabsPosition === 'left' &&
        css`
            display: flex;
            flex-direction: column;
        `}
`;

export default TabsContainer;
