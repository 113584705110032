import { rem } from 'polished/lib';
import styled from 'styled-components';

const AdminBusinessAdvertisingRowContainer = styled.div`
    border-bottom: 1px solid lightgray;
    margin-bottom: ${rem(16)};
    margin-top: ${rem(8)};
    padding-bottom: ${rem(16)};
`;

export default AdminBusinessAdvertisingRowContainer;
