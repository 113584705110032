import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { SrLabel } from 'baseline-ui/helpers';
import Textarea from './textarea/Textarea';
import FormInputLabel from './input/FormInputLabel';
import FormInputFeedback from './feedback/FormInputFeedback';
import formPropTypes from '../prop-types/formPropTypes';
import TextAreaContainer from './textarea/TextAreaContainer';
import TextAreaOuter from './textarea/TextAreaOuter';

class FormTextarea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            filled: false,
        };
    }

    componentDidMount() {
        const { form, field } = this.props;
        const value = getIn(form.values, field.name);

        if (value) {
            this.setState({ filled: true });
        }
    }

    handleFocus = () => {
        const { readOnly } = this.props;
        if (!readOnly) {
            this.setState({ focused: true });
        }
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
    };

    handleChange = (event) => {
        const { field } = this.props;
        this.setState({ filled: !!event.target.value });
        field.onChange(event);
    };

    render() {
        const {
            id,
            label,
            field,
            form,
            autoComplete,
            autoCorrect,
            autoCapitalize,
            spellCheck,
            maxLength,
            disabled,
            readOnly,
            required,
            rows,
            skin,
            hideLabel,
            className,
            autoFocus,
            placeholder,
        } = this.props;
        const { focused, filled } = this.state;
        const error = getIn(form.errors, field.name);
        const value = getIn(form.values, field.name);
        const isSubmitted = form.submitCount > 0;

        return (
            <TextAreaOuter>
                <TextAreaContainer
                    focused={focused}
                    readOnly={readOnly}
                    disabledValue={disabled}
                    skin={skin}
                >
                    <SrLabel htmlFor={id}>{label}</SrLabel>
                    <Textarea
                        className={className}
                        id={id}
                        name={field.name}
                        type="textarea"
                        autoComplete={autoComplete}
                        autoCorrect={autoCorrect}
                        autoCapitalize={autoCapitalize}
                        autoFocus={autoFocus}
                        spellCheck={spellCheck}
                        maxLength={maxLength}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        value={value}
                        disabled={disabled}
                        readOnly={readOnly}
                        required={required}
                        rows={rows}
                        skin={skin}
                        hideLabel={hideLabel}
                        placeholder={placeholder}
                    />
                    {!hideLabel && (
                        <FormInputLabel
                            requiredValue={required}
                            focused={focused}
                            filled={filled}
                            disabledValue={disabled}
                            aria-hidden="true"
                            skin={skin}
                            error={error && isSubmitted}
                            isSubmitted={isSubmitted}
                        >
                            {label}
                        </FormInputLabel>
                    )}
                </TextAreaContainer>
                {error && isSubmitted && (
                    <FormInputFeedback feedbackType="error" id={id} message={error} skin={skin} />
                )}
            </TextAreaOuter>
        );
    }
}

FormTextarea.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoCapitalize: PropTypes.string,
    spellCheck: PropTypes.string,
    maxLength: PropTypes.string,
    rows: PropTypes.number,
    skin: formPropTypes.formInputSkin,
    hideLabel: PropTypes.bool,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
};

FormTextarea.defaultProps = {
    required: false,
    disabled: false,
    readOnly: false,
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellCheck: 'false',
    maxLength: null,
    rows: 4,
    skin: 'dark',
    hideLabel: false,
    className: undefined,
    autoFocus: false,
    placeholder: undefined,
};

export default FormTextarea;
