import * as yup from 'yup';
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const formEventSchema = yup.object({
    eventName: yup.string().required('Event Name is required'),
    startDate: yup.mixed().test('is-valid-date', function (value) {
        const { createError } = this;

        if (!value) {
            return createError({ message: 'Date is required' });
        }

        if (!dayjs(value).isValid()) {
            return createError({ message: 'Invalid date format' });
        }

        return true;
    }),
    endDate: yup.string()
        .nullable()
        .test('is-greater', 'End date cannot be before start date', function (value) {
            const { startDate } = this.parent;
            if (value && startDate) {
                return dayjs(value).isSameOrAfter(dayjs(startDate));
            }
            return true;
        }),
    startTime: yup
        .mixed()
        .test('is-valid-time', function (value) {
            const { createError } = this;

            if (!value) {
                return createError({ message: 'Time is required' });
            }

            if (!dayjs(value).isValid()) {
                return createError({ message: 'Invalid time format' });
            }

            return true;
        }),
    endTime: yup.mixed()
            .nullable()
            .test('is-greater-time', 'End time must be greater than start time', function (value) {
            const { startTime, endDate } = this.parent;

            if (value && startTime) {
                const startTimeParsed = dayjs(startTime);
                const endTimeParsed = dayjs(value);
                if (endDate && value) {
                    return endTimeParsed?.isValid();
                }

                return endTimeParsed?.isAfter(startTimeParsed);
            }
            return true;
        }),
    locationType: yup.string().required('Location type is required'),
    locationBusiness_selected: yup.string().when('locationType', {
        is: 'BUSINESS',
        then: yup.string().required('Business location is required'),
        otherwise: yup.string().notRequired(),
    }),
    url: yup.string().url('Enter a valid URL'),
    caption: yup.string(),
    selectedCategories: yup.array(),
    headlinePerson: yup.array(),
    additionalPeople: yup.array(),
    description: yup.string(),
    locationName: yup.string().when('locationType', {
        is: 'OTHER',
        then: yup.string().required('Location Name is required'),
        otherwise: yup.string().notRequired(),
    }),
    address: yup.string().when('locationType', {
        is: 'OTHER',
        then: yup.string().required('Address is required'),
        otherwise: yup.string().notRequired(),
    }),
    city: yup.string().when('locationType', {
        is: 'OTHER',
        then: yup.string().required('City is required'),
        otherwise: yup.string().notRequired(),
    }),
    state: yup.string().when('locationType', {
        is: 'OTHER',
        then: yup.string().required('State is required'),
        otherwise: yup.string().notRequired(),
    }),
    zipCode: yup.string().when('locationType', {
        is: 'OTHER',
        then: yup.string().required('ZIP Code is required'),
        otherwise: yup.string().notRequired(),
    }),
    eventType: yup.string().default(''),
    customEventType: yup.string().when('eventType', {
        is: (value) => {
            return value === 'Other';
        },
        then: yup.string().required('Label is required'),
        otherwise: yup.string().notRequired(),
    })
});

export default formEventSchema;
