import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const FormInputAddOn = styled.div`
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    color: ${({ theme, skin }) =>
        get(theme, `form.label.${skin}.color`, formTheme.form.label[skin].color)};
    font-size: ${rem(2)};
    ${({ type }) =>
        type === 'prepend' &&
        css`
            left: ${rem(12)};
        `};
    ${({ type }) =>
        type === 'append' &&
        css`
            right: ${rem(12)};
        `};
    opacity: 0;
    position: absolute;
    top: ${rem(24)};
    transition: all 0.3s;

    ${({ focused, filled }) =>
        (focused || filled) &&
        css`
            font-size: ${({ theme }) =>
                rem(get(theme, 'form.input.fontSize', formTheme.form.input.fontSize))};
            opacity: 1;
        `}

    ${({ disabledValue, skin, theme }) =>
        disabledValue &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorDisabled`,
                formTheme.form.label[skin].colorDisabled,
            )};
        `}
`;

export default FormInputAddOn;
