import { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import layoutTheme from './layoutTheme';

// Media Queries
// https://github.com/styled-components/styled-components/issues/791

const mediaQ =
    (...query) =>
    (...rules) => css`
        @media ${css(...query)} {
            ${css(...rules)}
        }
    `;

export const media = {
    xxs: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.xxs', layoutTheme.layout.media.xxs))})`,
    xs: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.xs', layoutTheme.layout.media.xs))})`,
    sm: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.sm', layoutTheme.layout.media.sm))})`,
    md: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.md', layoutTheme.layout.media.md))})`,
    lg: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.lg', layoutTheme.layout.media.lg))})`,
    xl: mediaQ`(min-width: ${({ theme }) =>
        rem(get(theme, 'layout.media.xl', layoutTheme.layout.media.xl))})`,
    anyPointerFine: mediaQ`(any-pointer: fine)`,
    anyPointerNone: mediaQ`(any-pointer: none)`,
};

export const container = () => css`
    margin-left: auto;
    margin-right: auto;
    padding-left: ${({ theme }) =>
        rem(get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter))};
    padding-right: ${({ theme }) =>
        rem(get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter))};
`;

export const siteContainer = () => css`
    ${container}
    ${media.md`
        max-width: ${({ theme }) =>
            rem(get(theme, 'layout.maxSiteWidth', layoutTheme.layout.maxSiteWidth))};
    `}
`;

export const touch = matchMedia('(hover: none), (any-pointer: coarse)').matches;
