import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { FeedbackContext } from 'baseline-ui/feedback';
import { PROCESS_COMPLETE, PROCESS_RESET } from 'redux/actions/CommonActions';

const useProcessComplete = ({ processNames, withFeedback }) => {
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const dispatch = useDispatch();
    const intl = useIntl();

    const selectProcessesSelector = (state) => state.ProcessReducer;

    const selectCompletedProcesses = createSelector(selectProcessesSelector, (processes) =>
        processNames.filter((processName) => processes[processName] === PROCESS_COMPLETE),
    );

    const completeProcesses = useSelector(selectCompletedProcesses);

    const isComplete = !isEmpty(completeProcesses);

    useEffect(() => {
        if (isComplete) {
            if (withFeedback) {
                completeProcesses.forEach((completeProcess) => {
                    setFeedbackOnStage({
                        label: intl.formatMessage({
                            id: `process.complete.${completeProcess}.feedback.label`,
                        }),
                        type: 'success',
                        show: true,
                        timeout: 3200,
                    });
                });
            }
            completeProcesses.forEach((completeProcess) => {
                dispatch({
                    type: `${completeProcess}_${PROCESS_RESET}`,
                });
            });
        }
    }, [isComplete, withFeedback]);

    return isComplete;
};

export default useProcessComplete;
