import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Header, HeaderOffset } from 'baseline-ui/header';
import { useSelector } from 'react-redux';
import { ModalContext } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import {
    ADMIN_AREA_PATH,
    HOME_PATH,
    INQUIRE_PATH,
    LOGIN_PATH,
    MY_FAVEPAGE_PATH,
    ACCOUNT_PATH,
    CREATE_FAVEPAGE,
    FORGOT_PASSWORD_PATH,
    LINK_NOTIFICATIONS,
    BUSINESS_PATH,
    ABOUT_PATH,
    UPDATES_PATH,
} from 'common/pathnames';
import { ThemeContext } from 'styled-components';
import { OverlayContext } from 'baseline-ui/overlay';

const SiteHeader = ({ logoPath, logoPathAlt, title, logoWidth, children }) => {
    const intl = useIntl();

    const { isOpen } = useContext(ModalContext);
    const { isOverlayOpen } = useContext(OverlayContext);
    const user = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const { pathname } = useLocation();
    const isAuthenticated = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const isAdmin = useSelector(({ AuthenticationReducer }) => AuthenticationReducer.is_admin);

    const theme = useContext(ThemeContext);
    const renderSkinByPath = (itemPath, currentPath) => {
        if (currentPath === itemPath) {
            return 'primary';
        } else if (currentPath === HOME_PATH) {
            return 'secondary';
        }
        return 'light';
    };

    const commonNavigation = [
        {
            label: intl.formatMessage({ id: 'siteHeader.home.button.label' }),
            path: HOME_PATH,
            btnType: 'link',
            skin: renderSkinByPath(HOME_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.updates.button.label' }),
            path: UPDATES_PATH,
            btnType: 'link',
            skin: renderSkinByPath(UPDATES_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.about.button.label' }),
            path: ABOUT_PATH,
            btnType: 'link',
            skin: renderSkinByPath(ABOUT_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.businesses.button.label' }),
            path: BUSINESS_PATH,
            btnType: 'link',
            skin: renderSkinByPath(BUSINESS_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.inquiry.button.label' }),
            path: INQUIRE_PATH,
            btnType: 'link',
            skin: renderSkinByPath(INQUIRE_PATH, pathname)
        },
    ];

    const renderConditionalsMenuModalNavigationSecondaryListPublic = () => {
        if (pathname === HOME_PATH) {
            return [
                {
                    label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                    path: LOGIN_PATH,
                    skin: 'primary',
                    btnType: 'default',
                },
            ];
        }

        return null;
    };

    const renderConditionalsMenuModalNavigationListPublic = () => {
        if (
            pathname === HOME_PATH ||
            pathname === UPDATES_PATH ||
            pathname === INQUIRE_PATH ||
            pathname === BUSINESS_PATH ||
            pathname === ABOUT_PATH
        ) {
            return [
                ...commonNavigation,
                {
                    label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                    path: LOGIN_PATH,
                    skin: 'primary',
                    btnType: 'default',
                },                
            ];
        }
        return [...commonNavigation];
    };
    const renderConditionalsNavigationListPublic = () => {
        if (pathname === HOME_PATH ||
            pathname === INQUIRE_PATH ||
            pathname === UPDATES_PATH ||
            pathname === BUSINESS_PATH ||
            pathname === ABOUT_PATH) {
            return [
                ...commonNavigation,
                {
                    label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                    path: LOGIN_PATH,
                    skin: 'primary',
                    btnType: 'default',
                },
            ];
        }
        if (pathname === FORGOT_PASSWORD_PATH) {
            return [
                ...commonNavigation,
                {
                    label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
                    path: LOGIN_PATH,
                    skin: 'primary',
                    btnType: 'default',
                },
            ];
        }
        return [
            ...commonNavigation,
        ];
    };

    const commonNavigationListClient = [
        {
            label: intl.formatMessage({ id: 'siteHeader.createFavePage.button.label' }),
            skin: 'light',
            btnType: 'link',
            path: CREATE_FAVEPAGE,
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.managePages.button.label' }),
            skin: 'light',
            btnType: 'link',
            path: MY_FAVEPAGE_PATH,
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.help.button.label' }),
            path: INQUIRE_PATH,
            skin: 'light',
            btnType: 'link',
        },
    ];

    /**
     * If user is admin, show navigation item Admin page
     */
    if (isAdmin) {
        commonNavigationListClient.push({
            label: intl.formatMessage({ id: 'siteHeader.admin.button.label' }),
            path: ADMIN_AREA_PATH,
            skin: 'light',
            btnType: 'link',
        });
    }
    commonNavigationListClient.push({
        icon: 'settings',
        path: ACCOUNT_PATH,
        skin: 'light',
        btnType: 'link',
        size: 'xl'
    },
    {
        icon: 'bell',
        path: LINK_NOTIFICATIONS,
        skin: 'light',
        btnType: 'link',
        size: 'xl'
    },
    );

    const navigationList = {
        public: renderConditionalsNavigationListPublic(),
        client: [...commonNavigationListClient],
    };
    const filteredNavigationList = commonNavigationListClient.filter(item => item.icon !== 'bell');
    const menuModalNavigationList = {
        public: renderConditionalsMenuModalNavigationListPublic(),
        client: [...filteredNavigationList],
    };
    const menuModalNavigationSecondaryList = {
        public: renderConditionalsMenuModalNavigationSecondaryListPublic(),
        client: null,
    };

    const isBlurred = isOpen || isOverlayOpen;

    return (
        <>
            <Header
                isFullWidth={false}
                logoPath={logoPath}
                logoNavigateTo={isAuthenticated ? MY_FAVEPAGE_PATH : HOME_PATH}
                logoPathAlt={logoPathAlt}
                logoLabel={intl.formatMessage({ id: 'siteName' })}
                title={title}
                logoWidth={logoWidth}
                navigationList={user ? navigationList.client : navigationList.public}
                menuModalNavigationList={
                    user ? menuModalNavigationList.client : menuModalNavigationList.public
                }
                menuModalSecondaryList={
                    user
                        ? menuModalNavigationSecondaryList.client
                        : menuModalNavigationSecondaryList.public
                }
                isBlurred={isBlurred}
                headerColors={pathname === HOME_PATH && ['#FFF']}
            />
            <HeaderOffset isBlurred={isBlurred} bgStart={theme.site.admin.bgStart} isFullHeight>
                {children}
            </HeaderOffset>
        </>
    );
};

SiteHeader.propTypes = {
    logoPath: PropTypes.string,
    logoPathAlt: PropTypes.string,
    title: PropTypes.string,
    logoWidth: PropTypes.number,
    children: PropTypes.node.isRequired,
};

SiteHeader.defaultProps = {
    logoPath: undefined,
    logoPathAlt: undefined,
    title: undefined,
    logoWidth: undefined,
};

export default SiteHeader;
