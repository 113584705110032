import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash';
import { GaIcon } from 'baseline-ui/icon';
import FormPasswordInputRevealButton from './FormPasswordInputRevealButton';

const FormPasswordInputReveal = ({ onClick, revealPassword }) => {
    const theme = useContext(ThemeContext);

    const showPasswordIcon = get(theme, 'form.passwordInput.revealPassword.icon.show');
    const hidePasswordIcon = get(theme, 'form.passwordInput.revealPassword.icon.hide');

    return (
        <FormPasswordInputRevealButton onClick={onClick}>
            <GaIcon icon={revealPassword ? showPasswordIcon : hidePasswordIcon} size={18} />
        </FormPasswordInputRevealButton>
    );
};

FormPasswordInputReveal.propTypes = {
    onClick: PropTypes.func.isRequired,
    revealPassword: PropTypes.bool,
};

FormPasswordInputReveal.defaultProps = {
    revealPassword: false,
};

export default FormPasswordInputReveal;
