const horizontalScrollTheme = {
    horizontalScroll: {
        fadeColor: '#ffffff',
        fadeWidth: 40,
        scrollbar: {
            height: 8,
            icon: {
                size: 16,
            },
            button: {
                width: 40,
            },
        },
    },
};

export default horizontalScrollTheme;
