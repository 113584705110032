import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import FormCheckboxButtonLabel from './FormCheckboxButtonLabel';
import CheckboxInput from '../checkbox/CheckboxInput';
import formPropTypes from '../../prop-types/formPropTypes';

const FormRadioButton = ({
    id,
    field,
    form,
    value,
    disabled,
    buttonType,
    buttonSize,
    buttonSkin,
    labelOnly,
    children,
    onChange,
    title,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (event) => {
        setIsFocused(false);
        field.onBlur(event);
    };

    const handleChange = (event) => {
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    const newValue = getIn(form.values, field.name);
    const checked = newValue === value;

    return (
        <FormCheckboxButtonLabel
            focused={isFocused}
            checked={checked}
            disabledValue={disabled}
            buttonType={buttonType}
            buttonSize={buttonSize}
            buttonSkin={buttonSkin}
            labelOnly={labelOnly}
            title={title}
        >
            <CheckboxInput
                id={id}
                name={field.name}
                type="radio"
                value={value}
                checked={checked}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
            />
            {children}
        </FormCheckboxButtonLabel>
    );
};

FormRadioButton.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    buttonType: formPropTypes.radioButtonGroupType,
    buttonSize: formPropTypes.radioButtonGroupSize,
    buttonSkin: formPropTypes.radioButtonGroupSkin,
    labelOnly: PropTypes.bool,
    children: PropTypes.node,
    onChange: PropTypes.func,
    title: PropTypes.string,
};

FormRadioButton.defaultProps = {
    buttonType: 'default',
    buttonSize: 'default',
    buttonSkin: 'primary',
    labelOnly: false,
    disabled: false,
    children: null,
    onChange: null,
    title: undefined,
};

export default FormRadioButton;
