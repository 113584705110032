import styled from 'styled-components';
import { get } from 'lodash';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../styles/formTheme';

const LabelInline = styled.div`
    color: ${(props) =>
        get(
            props,
            `theme.form.label[${props.skin}].color`,
            formTheme.form.label[props.skin].color,
        )};
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: 1rem;
    padding: 0.3125rem 0;
`;

export default LabelInline;
