import React from 'react';
import PropTypes from 'prop-types';
import SiteHeader from 'components/pageLayout/SiteHeader';
import { HeaderProvider } from 'baseline-ui/header';
import useScrollToTop from 'utils/useScrollToTop';
import { useLocation } from 'react-router-dom';
import {LOGO, LOGO_DARK} from 'images';
import DefaultFooter from 'baseline-ui/footer/src/components/DefaultFooter';
import {useMemo} from 'react';
import {HOME_PATH} from 'common/pathnames';

const CommonWrapper = ({ children }) => {
    const { pathname } = useLocation();

    useScrollToTop(pathname);
    const renderLogoPath = useMemo(() => {
        if (pathname === HOME_PATH) {
            return LOGO_DARK;
        }
        return LOGO;
    }, [pathname]);

    return (
        <HeaderProvider>
            <SiteHeader logoPath={renderLogoPath} logoPathAlt={renderLogoPath} logoWidth={217}>
                {children}
            </SiteHeader>
            <DefaultFooter logoPath={LOGO} logoPathAlt={LOGO}/>
        </HeaderProvider>
    );
};

CommonWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CommonWrapper;
