import { baseTheme } from 'baseline-ui/base-theme';

const typographyTheme = {
    typography: {
        base: {
            lineHeight: 1.5,
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: 16,
            fontSizeMax: 16,
            fontSizeMin: 14,
            fontSizeViewport: 2,
        },
        headings: {
            fontFamily: 'Trebuchet MS,Tahoma,sans-serif',
            lineHeight: 1.2,
            fontSize: {
                h1: 36,
                h2: 30,
                h3: 24,
                h4: 20,
                h5: 18,
                h6: 16,
            },
            paddingRatio: 3,
            fontWeight: {
                h1: 700,
                h2: 400,
                h3: 400,
                h4: 700,
                h5: 400,
                h6: 700,
            },
        },
        largeHeadings: {
            fontSize: {
                h1: 50,
                h2: 30,
            },
            fontWeight: {
                h1: 700,
                h2: 400,
            },
            lineHeight: {
                h1: 1.1,
                h2: 1.2,
            },
            paddingV: {
                h1: 8,
                h2: 8,
            },
        },
        xLargeHeadings: {
            fontSize: {
                h1: 64,
                h2: 34,
            },
            fontWeight: {
                h1: 700,
                h2: 400,
            },
            lineHeight: {
                h1: 1.1,
                h2: 1.2,
            },
            paddingV: {
                h1: 8,
                h2: 8,
            },
        },
        link: {
            color: baseTheme.base.colors.primary,
            decoration: 'none',
            hoverDecoration: 'none',
        },
        label: {
            marginBottom: 0,
        },
        paragraph: {
            fontWeight: 400,
            margin: 16,
            lead: {
                fontWeight: 400,
            },
        },
        small: {
            fontSize: '80%',
        },
        strong: {
            fontWeight: 700,
        },
        colors: {
            primary: baseTheme.base.colors.primary,
            secondary: baseTheme.base.colors.secondary,
            muted: baseTheme.base.colors.muted,
            info: baseTheme.base.colors.info,
            success: baseTheme.base.colors.success,
            warning: baseTheme.base.colors.warning,
            danger: baseTheme.base.colors.danger,
            light: baseTheme.base.colors.light,
            dark: baseTheme.base.colors.dark,
        },
    },
};

export default typographyTheme;
