import { baseTheme } from 'baseline-ui/base-theme';

const tooltipTheme = {
    tooltip: {
        backgroundColor: baseTheme.base.colors.light,
        borderColor: baseTheme.base.grays.grayLighter,
        borderRadius: baseTheme.base.borderRadius.default,
        boxShadow: baseTheme.base.boxShadow.elevationMedium,
        zIndex: 101,
        closeIcon: {
            size: 18,
            color: baseTheme.base.grays.grayLight,
        },
        pointer: {
            size: 8,
        },
        info: {
            icon: 'help-circle',
            color: baseTheme.base.grays.gray,
            size: 18,
        },
        optionList: {
            item: {
                iconSize: 12,
                backgroundColor: baseTheme.base.colors.light,
                color: baseTheme.base.colors.dark,
                colorActive: baseTheme.base.colors.light,
                backgroundColorActive: baseTheme.base.colors.primary,
            },
        },
    },
};

export default tooltipTheme;
