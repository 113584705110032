import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AssetObject from './AssetObject';
import AssetImage from './AssetImage';

const AssetRenderer = ({
    assetPath,
    assetWidth,
    assetHeight,
    containerHeight,
    altText,
    assetLoadedCallback,
}) => {
    const ref = useRef();

    useEffect(() => {
        ref.current.addEventListener('load', () => {
            assetLoadedCallback();
        });
    }, []);

    const fileExt = assetPath && assetPath.substring(assetPath.lastIndexOf('.') + 1);
    const renderAsset =
        assetPath && fileExt === 'svg' ? (
            <AssetObject
                data={assetPath}
                type="image/svg+xml"
                assetWidth={assetWidth}
                assetHeight={assetHeight}
                alt={altText}
                containerHeight={containerHeight}
                ref={ref}
            />
        ) : (
            <AssetImage
                src={assetPath}
                alt={altText}
                assetWidth={assetWidth}
                assetHeight={assetHeight}
                containerHeight={containerHeight}
                ref={ref}
            />
        );

    return renderAsset;
};

AssetRenderer.propTypes = {
    assetPath: PropTypes.string,
    assetWidth: PropTypes.number,
    assetHeight: PropTypes.number,
    altText: PropTypes.string,
    containerHeight: PropTypes.bool,
    assetLoadedCallback: PropTypes.func,
};

AssetRenderer.defaultProps = {
    assetPath: undefined,
    assetWidth: undefined,
    assetHeight: undefined,
    altText: '',
    containerHeight: false,
    assetLoadedCallback: () => {},
};

export default AssetRenderer;
