import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import FormInputFeedback from './feedback/FormInputFeedback';
import FormCheckboxLabel from './checkbox/FormCheckboxLabel';
import CheckboxIndicator from './checkbox/CheckboxIndicator';
import CheckboxInput from './checkbox/CheckboxInput';
import CheckboxLabel from './checkbox/CheckboxLabel';
import Container from './Container';
import formPropTypes from '../prop-types/formPropTypes';

class FormCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
    };

    handleChange = (event) => {
        const { field, onChange } = this.props;
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    render() {
        const { form, field, sizeValue, id, disabled, skin, hideLabel, children } = this.props;
        const { focused } = this.state;
        const error = getIn(form.errors, field.name);
        const value = getIn(form.values, field.name);
        const isSubmitted = form.submitCount > 0;

        return (
            <Container>
                <FormCheckboxLabel sizeValue={sizeValue} hideLabel={hideLabel}>
                    <CheckboxInput
                        id={id}
                        name={field.name}
                        type="checkbox"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        value={value}
                        disabled={disabled}
                        checked={value || false}
                    />
                    <CheckboxIndicator
                        focused={focused}
                        checked={value || false}
                        disabledValue={disabled}
                        sizeValue={sizeValue}
                        skin={skin}
                    />
                    <CheckboxLabel disabledValue={disabled} sizeValue={sizeValue} skin={skin}>
                        {children}
                    </CheckboxLabel>
                </FormCheckboxLabel>
                {error && isSubmitted && (
                    <FormInputFeedback feedbackType="error" id={id} message={error} />
                )}
            </Container>
        );
    }
}

FormCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    sizeValue: PropTypes.oneOf(['default', 'lg']),
    hideLabel: PropTypes.bool,
    children: PropTypes.node,
    skin: formPropTypes.formInputSkin,
};

FormCheckbox.defaultProps = {
    sizeValue: 'default',
    onChange: null,
    disabled: false,
    hideLabel: false,
    children: null,
    skin: 'dark',
};

export default FormCheckbox;
