import styled from 'styled-components';
import collapsableButtonStyles from '../../styles/collapsableButtonStyles';

const Item = styled.button`
    ${collapsableButtonStyles}

    padding: 0;

    &:focus {
        outline: 0;
    }
`;

export default Item;
