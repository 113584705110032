import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import { Modal } from 'baseline-ui/modal';

const BusinessAddressProblemModalFormSubmitConfirmed = ({ onRequestClose, ...props }) => {
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage({
                id: 'addBusiness.modal.businessAddressProblemSubmitConfirmed.title',
            })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.businessAddressProblemSubmitConfirmed.footerAction.continue.button.label',
                    }),
                    onClick: () => onRequestClose(),
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: 'addBusiness.modal.businessAddressProblemSubmitConfirmed.content.md',
                })}
            />
        </Modal>
    );
};

BusinessAddressProblemModalFormSubmitConfirmed.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
};

export default BusinessAddressProblemModalFormSubmitConfirmed;
