import styled, { css } from 'styled-components';
import { get, isNumber } from 'lodash';
import { rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import { media } from 'baseline-ui/layout';
import overlayTheme from '../../styles/overlayTheme';

const OverlayContent = styled.div`
    background-color: ${({ theme, $customBackgroundColor }) =>
        $customBackgroundColor ||
        get(theme, 'overlay.backgroundColor', overlayTheme.overlay.backgroundColor)};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'overlay.content.borderRadius', overlayTheme.overlay.content.borderRadius))};
    box-shadow: 0 0 32px 8px
        ${({ theme }) =>
            get(
                theme,
                'overlay.content.boxShadowColor',
                overlayTheme.overlay.content.boxShadowColor,
            )};
    margin: 5vh 5vw;
    overflow: hidden;
    z-index: ${({ theme }) =>
        get(theme, 'overlay.content.zIndex', overlayTheme.overlay.content.zIndex)};

    ${({ $isOverlayTransparent, theme }) =>
        $isOverlayTransparent &&
        css`
            box-shadow: ${get(
                theme,
                'base.boxShadow.elevationLarge',
                baseTheme.base.boxShadow.elevationLarge,
            )};
        `}

    ${({ theme, $modalSize }) =>
        $modalSize &&
        css`
            ${media.sm`
                margin: 15vh auto;
                max-width: ${rem(
                    get(
                        theme,
                        `overlay.width[${$modalSize}]`,
                        overlayTheme.overlay.width[$modalSize],
                    ),
                )};
            `}
        `}

    ${({ $offsetTop }) =>
        isNumber($offsetTop) &&
        css`
            margin-top: ${rem($offsetTop)};

            ${media.sm`
                margin-top: ${rem($offsetTop)};
            `}
        `}
   
   ${({ $offsetBottom }) =>
        isNumber($offsetBottom) &&
        css`
            margin-bottom: ${rem($offsetBottom)};

            ${media.sm`
                margin-bottom: ${rem($offsetBottom)};
            `}
        `}

    ${({ $customBackgroundColor }) =>
        $customBackgroundColor === 'transparent' &&
        css`
            box-shadow: none;
        `}
   
   ${({ $isFullWidth }) =>
        $isFullWidth &&
        css`
            max-width: none;

            ${media.sm`
                max-width: none;
            `}
        `}
`;

export default OverlayContent;
