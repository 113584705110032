import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import SplitAsideMainTemplate from 'components/pageLayout/templates/SplitAsideMainTemplate';
import { useIntl } from 'react-intl';
import splitAsideMainTemplateBgImage from 'components/pageLayout/templates/splitAsideMainTemplateBgImage';
import FormReferAFriend from 'components/FormReferAFriend';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import {
    POST_REFER_FRIEND_PROCESS,
    POST_REFER_FRIEND_START,
} from 'redux/actions/ReferAFriendActions';
import useProcessLoader from 'utils/useProcessLoader';
import { useComplete } from 'common/useComplete';
import JoinBetaAside from './Welcome/JoinBetaAside';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import WelcomeMainHeader from './Welcome/WelcomeMainHeader';
import CommonWrapper from './CommonWrapper';

const ReferAFriendRoute = () => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const referFriendError = useProcessErrorMessage({ processName: POST_REFER_FRIEND_PROCESS });
    const isReferFriendLoading = useProcessLoader({ processNames: [POST_REFER_FRIEND_PROCESS] });
    const isReferFriendComplete = useComplete(POST_REFER_FRIEND_PROCESS);

    return (
        <CommonWrapper>
            <SplitAsideMainTemplate
                bgStart={theme.site.welcome.bgStart}
                bgEnd={theme.site.welcome.bgEnd}
                bgImage={splitAsideMainTemplateBgImage}
                aside={<JoinBetaAside />}
                main={
                    <WelcomeMainContainer>
                        <WelcomeMainHeader
                            title={intl.formatMessage({
                                id: 'formReferAFriend.referAFriend.heading.title',
                            })}
                        />
                        <FormReferAFriend
                            signUpValuesReferral={state}
                            showHeading={false}
                            onSubmit={(values) => {
                                dispatch({
                                    type: POST_REFER_FRIEND_START,
                                    payload: values,
                                });
                            }}
                            errorMessage={referFriendError}
                            isLoading={isReferFriendLoading}
                            inProp={!isReferFriendComplete}
                            inPropFeedback={isReferFriendComplete}
                            feedbackType="success"
                            feedbackMessage={intl.formatMessage({
                                id: 'formReferAFriend.referAFriend.heading.success',
                            })}
                            appear={false}
                        />
                    </WelcomeMainContainer>
                }
            />
        </CommonWrapper>
    );
};

export default ReferAFriendRoute;
