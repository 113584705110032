import {createSelector} from 'reselect';
import {pick, sortBy} from 'lodash';

export const eventFormSelector = (state) => state.EventReducer;
const eventCategoriesSelectorState = (state) => state.EventReducer.selectedCategories;

export const eventCategoriesSelector = createSelector(
    eventCategoriesSelectorState,
    (eventCategories) => sortBy(eventCategories, ['name']),
);
export const eventFormRequiredFieldKeys = () => {
    return [
        'eventName',
        'startDate',
        'endDate',
        'startTime',
        'endTime',
        'locationType',
        'locationName',
        'locationBusiness_selected',
        'address',
        'city',
        'state',
        'zipCode',
        'customEventType',
        'eventType'
    ];
};

export const eventFormRequiredFieldSelector = createSelector(
    eventFormSelector,
    (eventForm) => pick(eventForm, eventFormRequiredFieldKeys()),
);