import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { SrLabel, helpersUtils, useMeasure } from 'baseline-ui/helpers';
import Input from './Input';
import FormInputLabel from './input/FormInputLabel';
import FormInputFeedback from './feedback/FormInputFeedback';
import FormInputAddon from './input/FormInputAddon';
import Container from './Container';
import formPropTypes from '../prop-types/formPropTypes';

const FormInput = ({
    id,
    label,
    prependAddon,
    appendAddon,
    field,
    form,
    type,
    autoComplete,
    autoCorrect,
    autoCapitalize,
    spellCheck,
    maxLength,
    size,
    pattern,
    inputmode,
    disabled,
    readOnly,
    required,
    showIsRequired,
    skin,
    hideLabel,
    className,
    autoFocus,
    placeholder,
    centered,
    onChange,
    onBlur,
    onFocus,
    customError,
    onKeyUp,
    onKeyDown,
    innerRef,
    trimValue,
    preventWhiteSpaces,
    min,
    max,
}) => {
    const { setFieldValue } = useFormikContext();
    const error = getIn(form.errors, field.name);
    const value = getIn(form.values, field.name);
    const isSubmitted = form.submitCount > 0;

    const [appendAddonBind, appendAddonBounds] = useMeasure();
    const [prependAddonBind, prependAddonBounds] = useMeasure();

    const [isFilled, setIsFilled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const currentValue = getIn(form.values, field.name);

        if (currentValue) {
            setIsFilled(true);
        }
    }, []);

    useEffect(() => {
        const currentValue = getIn(form.values, field.name);

        if (currentValue !== '' && !isFilled) {
            setIsFilled(true);
        }
    }, [value]);

    const handleFocus = (event) => {
        if (!readOnly) {
            setIsFocused(true);
        }

        if (onFocus) {
            onFocus(event);
        }
    };

    const handleBlur = (event) => {
        setIsFocused(false);
        field.onBlur(event);

        if (trimValue) {
            setFieldValue(field.name, event.target.value.trim());
        }

        if (onBlur) {
            onBlur(event);
        }
    };

    const handleChange = (event) => {
        setIsFilled(!!event.target.value);
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    const handleKeyUp = (event) => {
        if (onKeyUp) {
            onKeyUp(event);
        }
    };
    const handleKeyDown = (event) => {
        if (preventWhiteSpaces || type === 'email' || type === 'url' || type === 'password') {
            helpersUtils.preventWhiteSpaces(event);
        }

        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    const handleAutoFill = () => {
        setIsFilled(true);
    };

    return (
        <Container>
            <SrLabel htmlFor={id}>{label}</SrLabel>
            <Input
                className={className}
                id={id}
                name={field.name}
                type={type}
                autoComplete={autoComplete}
                autoCorrect={autoCorrect}
                autoCapitalize={autoCapitalize}
                autoFocus={autoFocus}
                spellCheck={spellCheck}
                maxLength={maxLength}
                size={size}
                pattern={pattern}
                inputMode={inputmode}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                prependAddon={prependAddon}
                appendAddon={appendAddon}
                appendAddonWidth={appendAddonBounds.width}
                prependAddonWidth={prependAddonBounds.width}
                skin={skin}
                hideLabel={hideLabel}
                placeholder={placeholder}
                onAnimationStart={handleAutoFill}
                centered={centered}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                ref={innerRef}
                min={min}
                max={max}
            />

            {!hideLabel && (
                <FormInputLabel
                    requiredValue={required}
                    showIsRequired={showIsRequired}
                    focused={isFocused}
                    filled={isFilled}
                    disabledValue={disabled}
                    aria-hidden="true"
                    skin={skin}
                    error={error && isSubmitted}
                    isSubmitted={isSubmitted}
                    centered={centered}
                >
                    {label}
                </FormInputLabel>
            )}

            {prependAddon && (
                <FormInputAddon
                    focused={isFocused}
                    filled={isFilled}
                    disabledValue={disabled}
                    skin={skin}
                    type="prepend"
                    {...prependAddonBind}
                >
                    {prependAddon}
                </FormInputAddon>
            )}

            {appendAddon && (
                <FormInputAddon
                    focused={isFocused}
                    filled={isFilled}
                    disabledValue={disabled}
                    skin={skin}
                    type="append"
                    {...appendAddonBind}
                >
                    {appendAddon}
                </FormInputAddon>
            )}
            {error && isSubmitted && (
                <FormInputFeedback
                    centered={centered}
                    feedbackType="error"
                    id={id}
                    message={error}
                    skin={skin}
                />
            )}
            {customError && (
                <FormInputFeedback
                    centered={centered}
                    feedbackType="error"
                    id={id}
                    message={customError}
                    skin={skin}
                />
            )}
        </Container>
    );
};

FormInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    type: PropTypes.string,
    required: PropTypes.bool,
    showIsRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoCapitalize: PropTypes.string,
    spellCheck: PropTypes.string,
    maxLength: PropTypes.string,
    size: PropTypes.string,
    pattern: PropTypes.string,
    inputmode: PropTypes.string,
    prependAddon: PropTypes.node,
    appendAddon: PropTypes.node,
    skin: formPropTypes.formInputSkin,
    hideLabel: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    centered: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    customError: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    trimValue: PropTypes.bool,
    preventWhiteSpaces: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string,
};

FormInput.defaultProps = {
    type: 'text',
    required: false,
    showIsRequired: true,
    disabled: false,
    readOnly: false,
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellCheck: 'false',
    maxLength: null,
    size: undefined,
    inputmode: null,
    pattern: null,
    prependAddon: null,
    appendAddon: null,
    skin: 'dark',
    hideLabel: false,
    autoFocus: false,
    placeholder: undefined,
    className: undefined,
    centered: false,
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
    onKeyUp: undefined,
    onKeyDown: undefined,
    innerRef: undefined,
    customError: undefined,
    trimValue: true,
    preventWhiteSpaces: false,
    min: undefined,
    max: undefined,
};

export default FormInput;
