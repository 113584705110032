import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import loadScript from './loadScript';

const useGoogleMapsAutocomplete = (ref, type = ['address']) => {
    const { setFieldValue } = useFormikContext();

    const handleGoogleMapsAutocomplete = () => {
        if (typeof google === 'object') {
            const autoComplete = new window.google.maps.places.Autocomplete(ref.current, {
                types: type,
                componentRestrictions: { country: 'us' },
            });
            autoComplete.setFields(['address_components', 'formatted_address']);
            autoComplete.addListener('place_changed', async () => {
                const addressObject = await autoComplete.getPlace();
                const query = addressObject.formatted_address;
                setFieldValue('formattedAddress', query);
            });
        }
    };

    useEffect(() => {
        loadScript({
            url: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRUvvw2xf7i1p7s7focKGh46eAFdOLxrQ&libraries=places&callback=Function.prototype`,
            callback: handleGoogleMapsAutocomplete,
            id: 'googleMaps',
        });
    }, []);
};

export default useGoogleMapsAutocomplete;
