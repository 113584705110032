import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessLoader from 'utils/useProcessLoader';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalUnsavedChanges = ({
    onRequestClose,
    blocker,
    onSaveCallback,
    onSaveCompleteCallback,
    processNames,
    onLeaveCallback,
    ...props
}) => {
    const intl = useIntl();

    const isComplete = useProcessComplete({
        processNames,
    });
    const isLoading = useProcessLoader({
        processNames,
    });

    const handleOnDismiss = () => {
        onRequestClose();
        onLeaveCallback();
    };

    useEffect(() => {
        if (isComplete) {
            onRequestClose();
            onSaveCompleteCallback();
        }
    }, [isComplete]);

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.unsavedChanges.title' })}
            handleOnDismiss={() => {
                handleOnDismiss();
                if (blocker) {
                    blocker.proceed();
                }
            }}
            size="sm"
            {...props}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.unsavedChanges.footerAction.leave.button.label',
                    }),
                    skin: 'muted',
                    btnType: 'link',
                    onClick: () => {
                        handleOnDismiss();
                        if (blocker) {
                            blocker.proceed();
                        }
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.unsavedChanges.footerAction.save.button.label',
                    }),
                    skin: 'secondary',
                    onClick: () => {
                        onSaveCallback();
                    },
                    loading: isLoading,
                    loadingMessage: intl.formatMessage({
                        id: 'addBusiness.modal.unsavedChanges.footerAction.saving.button.label',
                    }),
                },
            ]}
        >
            <ContentRenderer
                source={intl.formatMessage({ id: 'addBusiness.modal.unsavedChanges.content.md' })}
            />
        </Modal>
    );
};

ModalUnsavedChanges.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onLeaveCallback: PropTypes.func,
    onSaveCompleteCallback: PropTypes.func.isRequired,
    blocker: PropTypes.shape(),
    onSaveCallback: PropTypes.func.isRequired,
    processNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ModalUnsavedChanges.defaultProps = {
    blocker: null,
    onLeaveCallback: () => {},
};

export default ModalUnsavedChanges;
