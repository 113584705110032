import PropTypes from 'prop-types';

const itemProducts = PropTypes.shape({
    sectionName: PropTypes.string,
    sectionId: PropTypes.string,
    accepts: PropTypes.arrayOf(PropTypes.string),
    // TODO listItems
    listItems: PropTypes.arrayOf(PropTypes.string),
});

const itemsProducts = PropTypes.arrayOf(itemProducts);

export default { itemsProducts };
