import {
  Alert,
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductPhotoEditor from "./ProductPhotoEditor";
import ClearIcon from "@mui/icons-material/Clear";
import CategoriesPicker from "./CategoriesPicker";
import {
  ADD_PRODUCT_START,
  POST_SAVE_PRODUCT_PROCESS,
  POST_SAVE_PRODUCT_START,
  SET_PRODUCT_SECTIONS_START,
  SET_PRODUCT_TO_EDIT_PROPERTY_START,
  UNSET_PRODUCT_SECTIONS_START,
  UPDATE_PRODUCT_SECTION_START,
} from "../redux/actions/EditProductActions";
import ReduxLoaderComponent from "./ReduxLoaderComponent";
import { useNavigate } from "react-router-dom";

const EditProductForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productToEdit, originalProduct } = useSelector(
    ({ EditProductReducer }) => EditProductReducer
  );

  const { sections } = useSelector(({ SectionReducer }) => SectionReducer);
  const { active } = productToEdit || {};
  const [errors, setErrors] = useState([]);

  const hasChanged = useMemo(() => {
    if (productToEdit && originalProduct) {
      return JSON.stringify(productToEdit) !== JSON.stringify(originalProduct);
    }
    return false;
  }, [productToEdit]);

  const { sectionsFoundIn } = productToEdit || {};

  if (!productToEdit) {
    return <></>;
  }

  return (
    <Box sx={{ border: 1, padding: 2, borderRadius: 2 }}>
      <ReduxLoaderComponent processName={POST_SAVE_PRODUCT_PROCESS} />
      <Grid item xs={12}>
        <Box xs={12}>
          {active !== undefined && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                onChange={() => {
                  dispatch({
                    type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
                    payload: {
                      active: !active,
                    },
                  });
                }}
                checked={active}
              />
              <Typography>Active</Typography>
            </Stack>
          )}
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Box marginRight={1}>
              <TextField
                label={"Product name"}
                value={productToEdit.name}
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
                    payload: {
                      name: e.target.value,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Price"}
              value={productToEdit.price}
              fullWidth
              onChange={(e) => {
                dispatch({
                  type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
                  payload: {
                    price: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Box marginTop={2}>
              <TextField
                fullWidth
                label={"Product description"}
                multiline
                rows={10}
                value={productToEdit.description}
                onChange={(e) => {
                  dispatch({
                    type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
                    payload: {
                      description: e.target.value,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} justifyContent="space-between">
          <ProductPhotoEditor />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Categories</Typography>
          <CategoriesPicker />
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"h6"}>Sections</Typography>
          {sectionsFoundIn?.map((section, idx) => {
            if (!section) {
              return;
            }
            return (
              <Grid
                container
                marginTop={1}
                key={`section_picker_${section?._id}`}
                flexDirection={"row"}
              >
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    id="demo-simple-select"
                    value={section?._id}
                    label="Section"
                    select
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_PRODUCT_SECTION_START,
                        payload: {
                          index: idx,
                          value: e?.target?.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"new"}>Select section name</MenuItem>
                    {sections?.map((oneSection) => (
                      <MenuItem
                        value={oneSection?._id}
                        key={`section_name_${idx}_${oneSection?._id}`}
                      >
                        {oneSection.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() =>
                      dispatch({
                        type: UNSET_PRODUCT_SECTIONS_START,
                        payload: idx,
                      })
                    }
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Box marginTop={2}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => {
                dispatch({
                  type: SET_PRODUCT_SECTIONS_START,
                });
              }}
            >
              Reference this product <br />
              in another section
            </Button>
          </Box>
        </Grid>
        {errors?.length > 0 && (
          <>
            {errors.map((err, idx) => (
              <Box
                marginTop={2}
                marginBottom={2}
                key={`product_save_error_${err.type}_${idx}`}
              >
                <Alert severity="error">{err.message}</Alert>
              </Box>
            ))}
          </>
        )}
        <Grid container alignItems={"flex-end"} justifyContent={"flex-end"}>
          {hasChanged && (
            <Button
              variant={"outlined"}
              onClick={() => {
                if (
                  !productToEdit?.mapping?.categories ||
                  productToEdit?.mapping?.categories?.length === 0
                ) {
                  const newErrors = [...errors];
                  if (!newErrors.find((e) => e.type === "categories")) {
                    newErrors.push({
                      type: "categories",
                      message:
                        "Please select at least one category for your product",
                    });
                    setErrors(newErrors);
                  }

                  return;
                }
                setErrors([]);
                if (productToEdit._id) {
                  dispatch({ type: POST_SAVE_PRODUCT_START });
                } else {
                  dispatch({ type: ADD_PRODUCT_START });
                }
              }}
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProductForm;
