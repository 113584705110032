import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import {H2, P, H6} from 'baseline-ui/typography';
import {
    FormInput,
    FieldContainer,
    FormPasswordInput,
    FieldsGrouped,
    FieldGroupedContainer,
    FormRadio,
    FormMaskedInput,
} from 'baseline-ui/form';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import AuthFormContainer from './AuthFormContainer';
import AuthFormSignError from './AuthFormSignError';
import AuthHeadingContainer from './AuthHeadingContainer';
import formSignUpSchema from '../schema/formSignUpSchema';
import authPropTypes from '../prop-types/authPropTypes';
import AuthFootnote from './AuthFootnote';
import {MOBILE_PHONE_NUMBER_MASK, POSTAL_CODE_LENGTH} from "common/consts";
import {AuthFieldHeaderContainer} from 'baseline-ui/auth/index';
import FormJoinBetaReferralFieldSeparator from 'components/FormJoinBeta/FormJoinBetaReferralFieldSeparator';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';
import dayjs from 'dayjs';
import CustomDatePicker from 'components/CustomDatePicker';

const FormSignUp = ({
    onSubmitSignUp,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    footnote,
    appear,
}) => {
    const intl = useIntl();

    const submitSignUp = (values) => {
        onSubmitSignUp(values);
    };

    const initialValues = {
        fullName: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        zipCode: '',
        password: '',
        confirmPassword: '',
        referralByBusiness: '',
        referralByPerson: '',
        dob: null,
        operatingSystem: '',
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="auth.signUp.heading.title" />
            </H2>
            <P>
                <FormattedMessage id="auth.signUp.heading.content" />
            </P>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer maxWidth={750}>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formSignUpSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        submitSignUp(values);
                    }}
                >
                    {(props) => {
                        const { values, touched, errors, setFieldValue } = props;
                        const handleBirthDateChange = (birthDate) => {
                            if (birthDate) {
                                const formattedDate = dayjs(birthDate).format('YYYY-MM-DD');
                                setFieldValue('dob', formattedDate !== 'Invalid Date' ? formattedDate : null);
                            }
                        };

                        return (
                            <Form noValidate>
                                <FieldsGrouped>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'auth.firstName.label',
                                                })}
                                                name="firstName"
                                                id="firstName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                                autoComplete="off"
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'auth.lastName.label',
                                                })}
                                                name="lastName"
                                                id="lastName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                                autoComplete="off"
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldsGrouped>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'auth.emailAddress.label',
                                                })}
                                                name="emailAddress"
                                                id="emailAddress"
                                                type="email"
                                                disabled={isLoading}
                                                required
                                                autoComplete="off"
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                            <FieldContainer>
                                                <Field
                                                    component={FormMaskedInput}
                                                    label={intl.formatMessage({
                                                        id: 'auth.phoneNumber.label',
                                                    })}
                                                    name="phoneNumber"
                                                    id="phoneNumber"
                                                    disabled={isLoading}
                                                    required
                                                    mask={MOBILE_PHONE_NUMBER_MASK}
                                                />
                                            </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldsGrouped>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'auth.zipCode.label',
                                                })}
                                                name="zipCode"
                                                id="zipCode"
                                                maxLength={POSTAL_CODE_LENGTH.toString()}
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <CustomDatePicker
                                                value={values.dob ? dayjs(values.dob) : null}
                                                onChange={(birthDate) => handleBirthDateChange(birthDate)}
                                                label={intl.formatMessage({
                                                    id: 'auth.dob.label',
                                                })}
                                                name="dob"
                                                error={Boolean(touched.dob && errors.dob)}
                                                helperText={touched.dob && errors.dob ? intl.formatMessage({id: errors.dob}) : ''}
                                                disabled={isLoading}
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldsGrouped>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                        <Field
                                            component={FormPasswordInput}
                                            label={intl.formatMessage({
                                                id: 'auth.password.label',
                                            })}
                                            name="password"
                                            id="password"
                                            type="password"
                                            disabled={isLoading}
                                            required
                                            autoComplete="new-password"
                                            preventWhiteSpaces
                                        />
                                    </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                    <Field
                                        component={FormPasswordInput}
                                        label={intl.formatMessage({
                                            id: 'auth.confirmPassword.label',
                                        })}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        type="password"
                                        disabled={isLoading}
                                        required
                                        autoComplete="new-password"
                                        preventWhiteSpaces
                                    />
                                </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldContainer paddingBottom={0}>
                                    <AuthFieldHeaderContainer>
                                        <H6>
                                            {intl.formatMessage({
                                                id: 'auth.signUp.operatingSystem.title',
                                            })}
                                        </H6>
                                    </AuthFieldHeaderContainer>
                                    <FieldsGrouped>
                                        <FieldGroupedContainer>
                                            <FieldContainer paddingBottom={8}>
                                                <Field
                                                    component={FormRadio}
                                                    name="operatingSystem"
                                                    id="appleOS"
                                                    value="Apple"
                                                >
                                                   <FormattedMessage id='auth.signUp.operatingSystem.apple' />
                                                </Field>
                                                <Field
                                                    component={FormRadio}
                                                    name="operatingSystem"
                                                    id="androidOS"
                                                    value="Android"
                                                >
                                                    <FormattedMessage id='auth.signUp.operatingSystem.android' />
                                                </Field>
                                            </FieldContainer>
                                        </FieldGroupedContainer>
                                    </FieldsGrouped>
                                </FieldContainer>
                                <FieldContainer paddingBottom={0}>
                                    <AuthFieldHeaderContainer>
                                        <H6>
                                            {intl.formatMessage({
                                                id: 'formJoinBeta.form.referral.title',
                                            })}
                                        </H6>
                                    </AuthFieldHeaderContainer>
                                    <FieldsGrouped>
                                        <FieldGroupedContainer>
                                            <FieldContainer>
                                                <Field
                                                    component={FormInput}
                                                    label={intl.formatMessage({
                                                        id: 'formJoinBeta.form.referralByBusiness.label',
                                                    })}
                                                    name="referralByBusiness"
                                                    id="referralByBusiness"
                                                    type="text"
                                                    disabled={isLoading}
                                                />
                                            </FieldContainer>
                                        </FieldGroupedContainer>
                                        <FormJoinBetaReferralFieldSeparator>
                                            <H6>
                                                <FormattedMessage id="formJoinBeta.form.referralByOr.label" />
                                            </H6>
                                        </FormJoinBetaReferralFieldSeparator>
                                        <FieldGroupedContainer>
                                            <FieldContainer>
                                                <Field
                                                    component={FormInput}
                                                    label={intl.formatMessage({
                                                        id: 'formJoinBeta.form.referralByPerson.label',
                                                    })}
                                                    name="referralByPerson"
                                                    id="referralByPerson"
                                                    type="text"
                                                    disabled={isLoading}
                                                />
                                            </FieldContainer>
                                        </FieldGroupedContainer>
                                    </FieldsGrouped>
                                </FieldContainer>
                                <ButtonWrapper width={310}>
                                    <Button
                                        skin={buttonSkin}
                                        btnType={buttonBtnType}
                                        type="submit"
                                        block
                                        loading={isLoading}
                                        loadingMessage={intl.formatMessage({
                                            id: 'auth.signUp.loading.label',
                                        })}
                                    >
                                        <FormattedMessage id="auth.signUp.label" />
                                    </Button>
                                </ButtonWrapper>
                                <AuthFormSignError errorMessage={errorMessage} />
                            </Form>
                        );
                    }}
                </Formik>

                <AuthFootnote footnote={footnote} />
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormSignUp.propTypes = {
    onSubmitSignUp: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    toSignIn: PropTypes.string,
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    footnote: PropTypes.node,
    appear: PropTypes.bool,
};

FormSignUp.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    toSignIn: '/signIn/',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    footnote: undefined,
    appear: true,
};

export default FormSignUp;
