import React from 'react';
import PropTypes from 'prop-types';
import ImageSrcsetContainer from './ImageSrcsetContainer';

const ImageSrcset = ({ image1x, image2x, alt, imageFluid, className }) => {
    return (
        <ImageSrcsetContainer
            src={image1x}
            srcSet={`${image1x} 1x, ${image2x} 2x`}
            alt={alt}
            imageFluid={imageFluid}
            className={className}
        />
    );
};

ImageSrcset.propTypes = {
    image1x: PropTypes.string.isRequired,
    image2x: PropTypes.string.isRequired,
    /** Alternative text for the image */
    alt: PropTypes.string,
    /** Responsive image */
    imageFluid: PropTypes.bool,
    className: PropTypes.string,
};

ImageSrcset.defaultProps = {
    imageFluid: true,
    alt: null,
    className: null,
};

export default ImageSrcset;
