import styled from 'styled-components';
import { rem } from 'polished';

const LogoContainer = styled.div`
    display: flex;
    line-height: 0;
    padding: ${rem(4)} 0;
`;

export default LogoContainer;
