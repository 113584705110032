import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import siteBaseTheme from 'styles/siteBaseTheme';

const LaunchWrapper = styled.div`
    background-color: ${({ theme, bgWhite }) => bgWhite ? theme.site.welcome.bgEnd : theme.site.welcome.bg};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    position: relative;
    padding-bottom: ${rem(40)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    position: relative;
    overflow: hidden;
    border-top: ${({borderTop}) =>
    borderTop
        ? `14px solid ${siteBaseTheme.base.colors.primary}`
        : '0'
    };
    ${({ afterImage, width }) =>
    afterImage &&
    css`
            &::after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 0;
                background-image: url(${afterImage});
              
                ${media.md`
                    background-image: url(${afterImage});
                    width: ${width ? `${rem(width)}` : '100%'};
                    height: 100%;
                `}
            }
        `}
        
    ${({ beforeImage }) =>
    beforeImage &&
    css`
            &::before {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
               background-image: url(${beforeImage});
               ${media.xxs`
                    
                `}
                ${media.md`
                    background-image: url(${beforeImage});
                `}
            }
        `}

    ${media.md`
        border-radius: unset;
        padding-left: ${rem(32)};
        padding-right: 0;
        padding-top: ${rem(60)};
    `};
`;

export default LaunchWrapper;
