import { Grid, Typography } from "@mui/material";
import React from "react";
import ProductPhotoEditor from "../../components/ProductPhotoEditor";

const ProductPhotosForm = () => {
  return (
    <Grid container item xs={12}>
      <Typography variant="h4">Photos</Typography>
      <Typography variant="body2">
        You can load up to 6 photos. These are going to be shown in the results
        as well as in the FavePage image carousel. The first image will be
        displayed in search lists. To re-order, drag and move the images.
      </Typography>
      <ProductPhotoEditor />
    </Grid>
  );
};

export default ProductPhotosForm;
