import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import siteErrorMessagesEnUs from 'i18n/siteErrorMessagesEnUs.json';
import baselineUiMessagesEnUs from 'i18n/baselineUiMessagesEnUs';
import siteAuthMessagesEnUs from 'i18n/siteAuthMessagesEnUs.json';
import siteMessagesEnUs from 'i18n/siteMessagesEnUs.json';
import siteProcessMessagesEnUs from 'i18n/siteProcessMessagesEnUs.json';

const messagesEnUs = {
    ...baselineUiMessagesEnUs,
    ...siteAuthMessagesEnUs,
    ...siteErrorMessagesEnUs,
    ...siteMessagesEnUs,
    ...siteProcessMessagesEnUs,
};

const SiteIntlProvider = ({ children }) => {
    return (
        <IntlProvider
            key="en-US"
            locale="en-US"
            messages={messagesEnUs}
            defaultLocale="en-US"
            fallbackOnEmptyString={false}
        >
            {children}
        </IntlProvider>
    );
};

SiteIntlProvider.propTypes = {
    children: PropTypes.node,
};

SiteIntlProvider.defaultProps = {
    children: undefined,
};

export default SiteIntlProvider;
