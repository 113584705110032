const PREVIEW_MODAL_OFFSET_TOP = 16;
const PREVIEW_MODAL_HEADER_HEIGHT = 48;
const PREVIEW_HEADER_HEIGHT = 88;
const PREVIEW_HEADER_HEIGHT_SM = 100;
const PREVIEW_HEADER_BORDER_RADIUS = 40;
const PREVIEW_MAX_WIDTH = 414;
const PREVIEW_MAX_HEIGHT = 700;
const PREVIEW_MAX_HEIGHT_SM = 844;
const PREVIEW_BORDER_RADIUS = 48;
const PREVIEW_HEIGHT = '98vh';
const PREVIEW_BORDER_WIDTH = 8;
const PREVIEW_BOX_SHADOW_WIDTH = 4;
const PREVIEW_BORDER_TOTAL_WIDTH = PREVIEW_BORDER_WIDTH + PREVIEW_BOX_SHADOW_WIDTH;

export {
    PREVIEW_MODAL_OFFSET_TOP,
    PREVIEW_HEADER_HEIGHT,
    PREVIEW_HEADER_HEIGHT_SM,
    PREVIEW_MAX_WIDTH,
    PREVIEW_MAX_HEIGHT,
    PREVIEW_MAX_HEIGHT_SM,
    PREVIEW_HEADER_BORDER_RADIUS,
    PREVIEW_MODAL_HEADER_HEIGHT,
    PREVIEW_BORDER_RADIUS,
    PREVIEW_HEIGHT,
    PREVIEW_BORDER_WIDTH,
    PREVIEW_BOX_SHADOW_WIDTH,
    PREVIEW_BORDER_TOTAL_WIDTH,
};
