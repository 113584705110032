import styled from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';

const Container = styled.div`
    padding: ${rem(24)} ${rem(24)} ${rem(16)};

    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
    `}
`;
export default Container;
