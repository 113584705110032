import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import List from './List';

const GridListItem = styled(List)`
    padding-bottom: ${rem(16)};
    padding-right: ${rem(16)};
    width: 100%;

    ${media.sm`
    padding-bottom: ${rem(24)};
    padding-right: ${rem(24)};
        width: ${({ rowAmount }) => `${100 / rowAmount}%`};
    
    `}

    ${({ rowAmount }) =>
        rowAmount &&
        css`
            &:nth-child(${rowAmount}n) {
                padding-right: 0;
            }
        `}
`;

export default GridListItem;
