import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { Small } from 'baseline-ui/typography';
import { productToEditSectionsNameSelector } from 'redux/selectors/productSelectors';
import ModalEditProductManageSectionsSelectionLabelContainer from './ModalEditProductManageSectionsSelectionLabelContainer';
import ModalEditProductManageSectionsSelectionContainer from './ModalEditProductManageSectionsSelectionContainer';

const ModalEditProductManageSectionsSelectionLabel = () => {
    const productToEditSectionsNames = useSelector(productToEditSectionsNameSelector);

    if (isEmpty(productToEditSectionsNames)) {
        return null;
    }

    return (
        <ModalEditProductManageSectionsSelectionContainer>
            <ModalEditProductManageSectionsSelectionLabelContainer>
                <Small textAlign="right">
                    <FormattedMessage
                        id="addBusiness.modal.editProduct.modal.manageSections.seletectItems.overview.label"
                        values={{ items: <b>{productToEditSectionsNames.join(', ')}</b> }}
                    />
                </Small>
            </ModalEditProductManageSectionsSelectionLabelContainer>
        </ModalEditProductManageSectionsSelectionContainer>
    );
};

export default ModalEditProductManageSectionsSelectionLabel;
