import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductManageSectionsListItemContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.base.colors.light};
    border: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    display: flex;
    justify-content: space-between;
    padding: ${rem(16)} ${rem(0)} ${rem(16)} ${rem(16)};
    text-align: left;
    user-select: none;
    width: 100%;
`;

export default ModalEditProductManageSectionsListItemContainer;
