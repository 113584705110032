import { alertI18nEnUs } from 'baseline-ui/alert';
import { authI18nEnUs } from 'baseline-ui/auth';
import { buttonI18nEnUs } from 'baseline-ui/button';
import { formI18nEnUs } from 'baseline-ui/form';
import { headerI18nEnUs } from 'baseline-ui/header';
import { modalI18nEnUs } from 'baseline-ui/modal';
import { overlayI18nEnUs } from 'baseline-ui/overlay';
import { footerI18nEnUs } from 'baseline-ui/footer';
import { validationI18nEnUs } from 'baseline-ui/validation';

const baselineUiMessagesEnUs = {
    ...alertI18nEnUs,
    ...authI18nEnUs,
    ...buttonI18nEnUs,
    ...formI18nEnUs,
    ...headerI18nEnUs,
    ...modalI18nEnUs,
    ...overlayI18nEnUs,
    ...footerI18nEnUs,
    ...validationI18nEnUs,
};

export default baselineUiMessagesEnUs;
