import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DISCARD_PRODUCT_CHANGES_START,
  POST_SAVE_PRODUCT_START,
  SET_PRODUCT_TO_EDIT_START,
} from "../redux/actions/EditProductActions";

const SaveProductModal = ({ open, onClose, targetProductToEdit }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Unsaved changes?</DialogTitle>
      <DialogContent>
        <Typography>
          You changed a product, but you haven't saved the changes yet. Do you
          want to save them now?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Back to edit</Button>
        <Button
          onClick={() => {
            dispatch({
              type: DISCARD_PRODUCT_CHANGES_START,
            });
            if (targetProductToEdit) {
              dispatch({
                type: SET_PRODUCT_TO_EDIT_START,
                payload: targetProductToEdit,
              });
            }

            onClose();
          }}
        >
          Discard my changes
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: POST_SAVE_PRODUCT_START,
            });
            onClose();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveProductModal;
