import siteBaseTheme from './siteBaseTheme';

const siteModalTheme = {
    modal: {
        content: {
            borderRadius: siteBaseTheme.base.borderRadius.lg,
        },
    },
};

export default siteModalTheme;
