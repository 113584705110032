/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';

const BannerWrapper = ({ children }) => {
    return <>{children}</>;
};

BannerWrapper.propTypes = {
    children: PropTypes.node,
};

BannerWrapper.defaultProps = {
    children: null,
};

export default BannerWrapper;
