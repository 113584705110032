import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Small } from 'baseline-ui/typography';
import { isEmpty, truncate } from 'lodash';
import { Button } from 'baseline-ui/button';
import { ADD_BUSINESS_ADDITIONAL_INFORMATION } from 'common/addBusinessViewIds';
import { ModalContext } from 'baseline-ui/modal';
import FavePagePreviewDescriptionContainer from './FavePagePreviewDescriptionContainer';
import FavePagePreviewDescriptionPlaceholderContainer from './FavePagePreviewDescriptionPlaceholderContainer';
import FavePagePreviewCategories from '../FavePagePreviewCategories';

const TRUNCATE_DESCRIPTION_COUNT = 200;

const FavePagePreviewDescription = ({ description, addToSection }) => {
    const { hideModal } = useContext(ModalContext);
    const [collapsableIsOpenLabel, setCollapsableIsOpenLabel] = useState(false);

    if (isEmpty(description)) {
        return (
            <FavePagePreviewDescriptionPlaceholderContainer>
                <Button
                    btnType="link"
                    size="sm"
                    icon="plus"
                    onClick={() => {
                        hideModal();
                        addToSection(ADD_BUSINESS_ADDITIONAL_INFORMATION);
                    }}
                >
                    Add Description
                </Button>
            </FavePagePreviewDescriptionPlaceholderContainer>
        );
    }

    const currentDescriptionLength = description.length;

    const renderDescription = useMemo(() => {
        if (collapsableIsOpenLabel) {
            return description;
        }
        return truncate(description, { length: TRUNCATE_DESCRIPTION_COUNT });
    }, [collapsableIsOpenLabel, description]);

    return (
        <>
            <FavePagePreviewDescriptionContainer>
                <Small color="muted">{renderDescription}</Small>
            </FavePagePreviewDescriptionContainer>
            {((currentDescriptionLength > TRUNCATE_DESCRIPTION_COUNT && collapsableIsOpenLabel) ||
                currentDescriptionLength <= TRUNCATE_DESCRIPTION_COUNT) && (
                <FavePagePreviewCategories addToSection={addToSection} />
            )}
            {currentDescriptionLength > TRUNCATE_DESCRIPTION_COUNT && (
                <div style={{ marginLeft: -12, width: '100%' }}>
                    <Button
                        btnType="link"
                        size="sm"
                        skin="primary"
                        icon={collapsableIsOpenLabel ? 'chevron-up' : 'chevron-down'}
                        iconPostLabel
                        onClick={() => setCollapsableIsOpenLabel((prev) => !prev)}
                    >
                        {collapsableIsOpenLabel ? 'Read less' : 'Read more'}
                    </Button>
                </div>
            )}
        </>
    );
};

FavePagePreviewDescription.propTypes = {
    description: PropTypes.string,
    addToSection: PropTypes.func,
};

FavePagePreviewDescription.defaultProps = {
    description: undefined,
    addToSection: () => {},
};

export default FavePagePreviewDescription;
