import styled from 'styled-components';
import { media } from 'baseline-ui/layout';

export const FormMainActions = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: 2rem;

    ${media.sm`
        margin-top: 3.75rem;
    `}
`;

export const FormMainButton = styled.div`
    margin-bottom: 1.25rem;
`;
