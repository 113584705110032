import React from 'react';
import PropTypes from 'prop-types';
import RouterLink from './RouterLink';
import typographyPropTypes from '../prop-types/typographyPropTypes';

const RouterA = ({ color, className, children, to, ...props }) => {
    return (
        <RouterLink $colorValue={color} className={className} to={to} {...props}>
            {children}
        </RouterLink>
    );
};

RouterA.propTypes = {
    color: typographyPropTypes.typographyColor,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

RouterA.defaultProps = {
    color: undefined,
    className: undefined,
};

export default RouterA;
