import React, { useMemo, useContext } from 'react';
import { ModalContext } from 'baseline-ui/modal';
import { MessageBox } from './base';

export const useMessageBox = (hookProps = {}) => {
    const modalContext = useContext(ModalContext);
    const MessageBoxInstance = (componenProps) => {
        const props = useMemo(() => {
            const { data: hookData, ...hookRest } = componenProps;
            const { data: componentData, ...componentRest } = hookProps;
            const data = { ...hookData, ...componentData };
            const props = {
                ...hookRest,
                ...componentRest,
                data,
            };
            if (!props.onDismiss) {
                props.onDismiss = () => {
                    // If no onDismiss, add one that hides the modal.
                    modalContext.hideModal();
                };
            }
            if (props.onResponse) {
                const { onResponse } = props;
                props.onResponse = async (type, data) => {
                    const close = await onResponse(type, data);
                    if (close) {
                        modalContext.hideModal();
                    }
                };
            }
            return props;
        }, [componenProps, hookProps]);
        return <MessageBox {...props} />;
    };
    const iface = useMemo(() => {
        return {
            handleShow(data = {}, props = {}) {
                return (evt) => {
                    if (evt) {
                        evt.preventDefault();
                    }
                    modalContext.showModal(MessageBoxInstance, { ...props, data });
                };
            },
            showModal(props) {
                modalContext.showModal(MessageBoxInstance, props);
            },
            removeModal: modalContext.removeModal,
            hideModal: modalContext.hideModal,
            isOpen: modalContext.isOpen,
        };
    }, [modalContext]);
    return iface;
};

export default useMessageBox;
