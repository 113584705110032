import styled from 'styled-components';
import { pulseKeyframes } from 'baseline-ui/helpers';

const Container = styled.div`
    animation: ${pulseKeyframes} 2s infinite ease-in-out;
    background-color: #feeee8;
    height: 100%;
    left: 0;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
`;

export default Container;
