import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem, lighten, transparentize } from 'polished';
import headerTheme from '../../styles/headerTheme';

const HeaderContainer = styled.div`
    backdrop-filter: blur(
        ${({ theme }) => get(theme, 'header.backdropFilter', headerTheme.header.backdropFilter)}
    );
    background-color: ${({ headerColors, theme }) =>
        headerColors
            ? lighten(0.05, headerColors[0])
            : get(theme, 'header.backgroundColor', headerTheme.header.backgroundColor)};
    box-shadow: ${({ theme, isVertical }) =>
        isVertical
            ? get(theme, 'header.shadow.verticalShadow', headerTheme.header.shadow.verticalShadow)
            : get(
                  theme,
                  'header.shadow.horizontalShadow',
                  headerTheme.header.shadow.horizontalShadow,
              )};
    height: ${({ theme, isVertical }) =>
        isVertical ? '100vh' : rem(get(theme, 'header.height', headerTheme.header.height))};
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color 0.3s ease-in-out;
    width: ${({ isVertical, theme }) =>
        isVertical
            ? rem(get(theme, 'header.vertical.width', headerTheme.header.vertical.width))
            : '100%;'};
    z-index: ${({ theme }) => get(theme, 'header.zIndex', headerTheme.header.zIndex)};

    ${({ isBlurred, theme }) =>
        isBlurred &&
        css`
            filter: blur(${rem(get(theme, 'header.filter.blur', headerTheme.header.filter.blur))});
        `}

    ${({ isBackdropBlurred, theme }) =>
        isBackdropBlurred &&
        css`
            backdrop-filter: blur(
                ${rem(
                    get(
                        theme,
                        'header.backdropFilter.blur',
                        headerTheme.header.backdropFilter.blur,
                    ),
                )}
            );
            background-color: ${({ headerColors }) =>
                headerColors
                    ? transparentize(0.8, lighten(0.08, headerColors[0]))
                    : transparentize(
                          0.8,
                          get(theme, 'header.backgroundColor', headerTheme.header.backgroundColor),
                      )};
        `}

    ${({ isVertical }) =>
        isVertical &&
        css`
            padding: ${rem(32)} 0;
        `}
`;

export default HeaderContainer;
