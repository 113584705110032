import { rem } from 'polished';
import styled from 'styled-components';
import { baseScrollbarStyles } from 'baseline-ui/base-theme';
import { media } from 'baseline-ui/layout';
import {
    PREVIEW_BORDER_RADIUS,
    PREVIEW_BORDER_WIDTH,
    PREVIEW_BOX_SHADOW_WIDTH,
    PREVIEW_HEIGHT,
    PREVIEW_MAX_HEIGHT,
    PREVIEW_MAX_HEIGHT_SM,
    PREVIEW_MAX_WIDTH,
    PREVIEW_MODAL_HEADER_HEIGHT,
} from 'common/previewConstants';

const FavePagePreviewScreenContainer = styled.div`
    background-color: #fff;
    border: ${rem(PREVIEW_BORDER_WIDTH)} solid #000;
    border-radius: ${rem(PREVIEW_BORDER_RADIUS)};
    box-shadow: 0 0 0 ${rem(PREVIEW_BOX_SHADOW_WIDTH)} #2d2b29;
    height: calc(${PREVIEW_HEIGHT} - ${PREVIEW_MODAL_HEADER_HEIGHT}px);
    margin: 8px;
    max-height: ${PREVIEW_MAX_HEIGHT}px;
    max-width: ${PREVIEW_MAX_WIDTH}px;
    overflow: hidden auto;
    position: relative;
    width: 100%;
    ${baseScrollbarStyles({ marginV: PREVIEW_BORDER_RADIUS })}

    ${media.sm`
        max-height: ${PREVIEW_MAX_HEIGHT_SM}px;
    `}
`;

export default FavePagePreviewScreenContainer;
