import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import iconPaths from 'data/selection.json';
import iconPropTypes from '../prop-types/iconPropTypes';

const getPath = (iconName) => {
    const icons = iconPaths.icons.find((icon) => icon.properties.name === iconName);
    if (icons) {
        return icons.icon.paths.join(' ');
    }
    console.warn(`icon ${iconName} does not exist.`); // eslint-disable-line no-console
    return '';
};

const getSizes = (iconName) => {
    let width;
    let height;
    const icons = iconPaths.icons.find((icon) => icon.properties.name === iconName);
    if (icons.icon.width) {
        width = icons.icon.width;
        height = 1024;
        return { width, height };
    }
    return { width: 1024, height: 1024 };
};

const GaIcon = ({ color, size, icon }) => {
    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        path: {
            fill: color,
        },
    };
    if (!icon) {
        return null;
    }
    return (
        <svg
            style={styles.svg}
            width={rem(size)}
            height={rem(size)}
            viewBox={`0 0 ${getSizes(icon).width} ${getSizes(icon).height}`}
        >
            <path style={styles.path} d={getPath(icon)} />
        </svg>
    );
};

GaIcon.propTypes = {
    icon: iconPropTypes.iconSet.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
};

GaIcon.defaultProps = {
    size: 22,
    color: 'currentColor',
};

export default GaIcon;
