import { get } from 'lodash';
import { rem, tint, shade } from 'polished';
import { css } from 'styled-components';
import baseTheme from './baseTheme';

const baseScrollbarStyles = ({ theme, themeMode, size, color, borderRadius, marginV }) => {
    const trackColorFunction = themeMode === 'darkMode' ? shade : tint;
    const thumbColorFunction = themeMode === 'darkMode' ? tint : shade;

    return css`
        &::-webkit-scrollbar {
            height: ${rem(
                size || get(theme, 'base.scrollbar.size', baseTheme.base.scrollbar.size),
            )};
            width: ${rem(size || get(theme, 'base.scrollbar.size', baseTheme.base.scrollbar.size))};
        }

        &::-webkit-scrollbar-track {
            background-color: ${trackColorFunction(
                0.15,
                color || get(theme, 'base.scrollbar.color', baseTheme.base.scrollbar.color),
            )};
            border-radius: ${rem(
                borderRadius ||
                    get(
                        theme,
                        'base.scrollbar.borderRadius.track',
                        baseTheme.base.scrollbar.borderRadius.track,
                    ),
            )};
            margin: ${rem(
                    marginV ||
                        get(theme, 'base.scrollbar.marginV', baseTheme.base.scrollbar.marginV),
                )}
                0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${thumbColorFunction(
                0.15,
                color || get(theme, 'base.scrollbar.color', baseTheme.base.scrollbar.color),
            )};
            border-radius: ${rem(
                borderRadius ||
                    get(
                        theme,
                        'base.scrollbar.borderRadius.thumb',
                        baseTheme.base.scrollbar.borderRadius.thumb,
                    ),
            )};
        }
    `;
};

export default baseScrollbarStyles;
