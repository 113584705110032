import { call, put, select, takeEvery } from "redux-saga/effects";
import genericSagaHandler from "./CommonSaga";
import {
    GET_ALL_LINK_NOTIFICATIONS_PROCESS,
    GET_ALL_LINK_NOTIFICATIONS_START,
    GET_ALL_LINK_NOTIFICATIONS_SUCCESS,
    GET_LINK_NOTIFICATIONS_PROCESS,
    GET_LINK_NOTIFICATIONS_START,
    GET_LINK_NOTIFICATIONS_SUCCESS,
    PUT_LINK_NOTIFICATIONS_PROCESS,
    PUT_LINK_NOTIFICATIONS_START,
    SET_LINK_NOTIFICATIONS_NEXT_PAGE_PROCESS,
    SET_LINK_NOTIFICATIONS_NEXT_PAGE_START,
    SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
} from "../actions/LinkNotificationActions";
import {
    GetUserNotifications,
    GetUserNotificationsTotal,
    PutUserNotifications
} from "../../api";

function* getAllLinkNotificationsSaga() {
    yield genericSagaHandler(GET_ALL_LINK_NOTIFICATIONS_PROCESS, function* () {

        const notifications = yield GetUserNotificationsTotal();

        yield put({
            type: GET_ALL_LINK_NOTIFICATIONS_SUCCESS,
            payload: { total: notifications.totalNotifications , unread: notifications.unreadNotifications},
        });
    });
}
function* getLinkNotificationsSaga() {
    yield genericSagaHandler(GET_LINK_NOTIFICATIONS_PROCESS, function* () {
        const { page, linkNotifications } = yield select(
            ({ LinkNotificationsReducer }) => LinkNotificationsReducer,
        );
        const type = "LINK";
        const notifications = yield GetUserNotifications(page, type);
        const newNotifications = [...(linkNotifications ?? [])];
        notifications.forEach((notification) => {
            const isExisting = newNotifications.find(
                (newNotification) => newNotification?.id === notification?.id,
            );
            if (!isExisting) {
                newNotifications.push(notification);
            }
        });

        const hasNext = notifications?.length > 0;
        yield put({
            type: GET_LINK_NOTIFICATIONS_SUCCESS,
            payload: { linkNotifications: newNotifications, hasNext },
        });
    });
}

function* putLinkNotificationsSaga(action) {
    yield genericSagaHandler(PUT_LINK_NOTIFICATIONS_PROCESS, function* () {
        const { notificationId } = action.payload;
        const { linkNotifications } = yield select(
            ({ LinkNotificationsReducer }) => LinkNotificationsReducer,
        );
        const notification = linkNotifications.find(n => n.id === notificationId);
        if (notification.status !== 'READ') {
            const formData = new FormData();
            formData.append('notificationId', notificationId);
            formData.append('type', 'READ');

            yield PutUserNotifications(formData);
        }
    });
}
function* setLinkNotificationsPageSaga() {
    yield genericSagaHandler(
        SET_LINK_NOTIFICATIONS_NEXT_PAGE_PROCESS,
        function* () {
            const { page } = yield select(
                ({ LinkNotificationsReducer }) => LinkNotificationsReducer,
            );
            yield put({
                type: SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
                payload: { page: page + 1 },
            });
            yield call(getLinkNotificationsSaga);
        },
    );
}

export default function* LinkNotificationSagas() {
    yield takeEvery(GET_LINK_NOTIFICATIONS_START, getLinkNotificationsSaga);
    yield takeEvery(PUT_LINK_NOTIFICATIONS_START, putLinkNotificationsSaga);
    yield takeEvery(SET_LINK_NOTIFICATIONS_NEXT_PAGE_START, setLinkNotificationsPageSaga);
    yield takeEvery(GET_ALL_LINK_NOTIFICATIONS_START, getAllLinkNotificationsSaga);
}