import {
    CLEAR_BUSINESS_CATEGORIES,
    GET_BUSINESS_CATEGORIES_SUCCESS,
    GET_BUSINESS_SUBCATEGORIES_SUCCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
    GET_INTERESTS_CATEGORY_SUCCESS,
    GET_ITEM_CATEGORIES_SUCCESS,
    GET_TOP_LEVEL_GROUPS_SUCCESS,
} from '../actions/CategoriesActions';
const initialState = {
    businessCategories: [],
    businessSubcategories: {},
    itemCategories: [],
    topLevelGroups: [],
    businessSubcategoryFilters: {},
    interestsCategory: [],
};
const CategoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ITEM_CATEGORIES_SUCCESS: {
            return { ...state, itemCategories: payload };
        }
        case GET_BUSINESS_CATEGORIES_SUCCESS: {
            return { ...state, businessCategories: payload };
        }
        case GET_INTERESTS_CATEGORY_SUCCESS: {
            return { ...state, interestsCategory: payload };
        }
        case CLEAR_BUSINESS_CATEGORIES: {
            return {
                ...state,
                businessCategories: [],
            };
        }
        case GET_BUSINESS_SUBCATEGORIES_SUCCESS: {
            const { categoryId, data } = payload;
            return {
                ...state,
                businessSubcategories: {
                    ...state.businessSubcategories,
                    [categoryId]: data,
                }
            };
        }
        case GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS: {
            const { subcategoryId, data } = payload;
            return {
                ...state,
                businessSubcategoryFilters: {
                    ...state.businessSubcategoryFilters,
                    [subcategoryId]: data,
                }
            };
        }
        case GET_TOP_LEVEL_GROUPS_SUCCESS: {
            return { ...state, topLevelGroups: payload };
        }
        default: {
            return { ...state };
        }
    }
};

export default CategoriesReducer;
