import { rem } from 'polished';
import styled from 'styled-components';

const PhotoListItemContainer = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    height: 100%;
    overflow: hidden;
`;

export default PhotoListItemContainer;
