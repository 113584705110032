import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../common/i18n";
import { PROCESS_RESET } from "../redux/actions/CommonActions";

const ErrorAlertComponent = ({ processName, styleOverride }) => {
  const dispatch = useDispatch();
  const processError = useSelector(
    ({ ErrorReducer }) => ErrorReducer[processName],
  );
  const errorMessage = i18n().ERRORS[processName]
    ? i18n().ERRORS[processName][[processError?.code?.errorCode]] ||
      i18n().ERRORS[processName].GENERIC_ERROR
    : i18n().ERRORS.GENERIC_ERROR;

  useEffect(() => {
    if (errorMessage && processError) {
      alert(errorMessage);
      dispatch({
        type: `${processName}_${PROCESS_RESET}`,
      });
    }
  }, [errorMessage, processError]);

  if (!processError) {
    return null;
  }

  return null;
};
export default ErrorAlertComponent;
