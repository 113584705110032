import PropTypes from 'prop-types';
import { buttonBtnTypeData, buttonSkinData, buttonSizeData } from 'baseline-ui/button';

const listItem = PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
    btnType: PropTypes.oneOf(buttonBtnTypeData),
    skin: PropTypes.oneOf(buttonSkinData),
    size: PropTypes.oneOf(buttonSizeData),
    onClick: PropTypes.func,
    isExternal: PropTypes.bool,
    icon: PropTypes.string,
    showLabel: PropTypes.bool,
});

const list = PropTypes.arrayOf(listItem);

export default { listItem, list };
