import React from 'react';
import PropTypes from 'prop-types';
import SliderDotList from './SliderDotList';
import SliderDotsContainer from './SliderDotsContainer';
import carouselPropTypes from '../prop-types/carouselPropTypes';

const CarouselDotList = ({ dotsType, dotsPosition, dotsAlignment, dotsOrientation, children }) => {
    return (
        <SliderDotsContainer
            dotsType={dotsType}
            dotsPosition={dotsPosition}
            dotsAlignment={dotsAlignment}
            dotsOrientation={dotsOrientation}
        >
            <SliderDotList>{children}</SliderDotList>
        </SliderDotsContainer>
    );
};

CarouselDotList.propTypes = {
    /** Type of navigational dots */
    dotsType: carouselPropTypes.carouselDotsType,
    dotsPosition: carouselPropTypes.carouselDotsPosition,
    dotsAlignment: carouselPropTypes.carouselDotsAlignment,
    dotsOrientation: carouselPropTypes.carouselDotsOrientation,
    children: PropTypes.node,
};

CarouselDotList.defaultProps = {
    dotsType: 'default',
    dotsPosition: 'outside bottom',
    dotsAlignment: 'center',
    dotsOrientation: 'horizontal',
    children: null,
};

export default CarouselDotList;
