import { rem } from 'polished';
import styled from 'styled-components';

const FavePageMainMyBusinessStatusTagContainer = styled.span`
    display: block;
    margin-bottom: ${rem(4)};
    margin-top: ${rem(4)};
`;

export default FavePageMainMyBusinessStatusTagContainer;
