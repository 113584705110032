import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import modalTheme from '../../../styles/modalTheme';

const ModalCloseIcon = ({ color }) => {
    const theme = useContext(ThemeContext);
    const sizeValue = get(theme, 'modal.closeIcon.size', modalTheme.modal.closeIcon.size);

    return <GaIcon icon="x" color={color} size={sizeValue} />;
};

ModalCloseIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

export default ModalCloseIcon;
