import styled from 'styled-components';

const Fieldset = styled.fieldset`
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
`;

export default Fieldset;
