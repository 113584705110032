import { get } from 'lodash';
import { rem } from 'polished';
import { css } from 'styled-components';
import formTheme from './formTheme';

const formInputFillStyles = ({ theme }) => css`
    font-size: ${rem(get(theme, 'form.label.fontSizeFocus', formTheme.form.label.fontSizeFocus))};
    padding-right: ${rem(16)};
    top: ${rem(6)};
`;

export default formInputFillStyles;
