import styled from 'styled-components';
import { get } from 'lodash';
import collapsableTheme from '../../styles/collapsableTheme';

const Container = styled.div`
    background-color: #fff;
    border-color: ${({ theme }) =>
        get(theme, '.collapsable.accordion.border', collapsableTheme.collapsable.accordion.border)};
    border-style: solid;
    border-width: 0 0 1px;

    &:last-child {
        border: 0;
    }
`;

export default Container;
