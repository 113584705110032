const footerTheme = {
    footer: {
        defaultFooter: {
            backgroundStart: '#482655',
            backgroundEnd: '#482655',
            dividerColor: '#551c85',
        },
    },
};

export default footerTheme;
