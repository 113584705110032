import styled from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';

const WelcomeAsideContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(45vh - ${({ theme }) => rem(theme.header.height)});
    padding-bottom: ${rem(40)};
    padding-top: ${rem(40)};

    ${media.md`
        min-height: calc(
            100vh - ${({ theme }) => rem(theme.header.height)}
        );
    `}
`;

export default WelcomeAsideContainer;
