import CommonWrapper from "../../CommonWrapper";
import {ContentRenderer} from "baseline-ui/helpers";
import ButtonWrapper from "baseline-ui/button/src/components/ButtonWrapper";
import {LinkButton} from "baseline-ui/button";
import {FormattedMessage, useIntl} from "react-intl";
import VideoWrapper from "../HomePage/VideoWrapper";
import Video from "../HomePage/Video";
import LandingIntroWrapper from "../HomePage/LandingIntroWrapper";
import React from "react";
import HeroWrapper from "./HeroWrapper";
import {HOME_PATH, INQUIRE_PATH} from "../../../common/pathnames";
import Separator from "../../../components/Separator";
import triangle from "../../../images/small-triangle.svg";
import heartPart from "../../../images/heart-side.svg";
import upTriangle from 'images/triangle_up_left.svg';
import rightHeartPart from "images/heart-side-right.svg";
import Container from "../../../components/Container";

const BusinessesPageRoute = () => {
    const intl = useIntl();
    return(
        <CommonWrapper>
            <HeroWrapper bgWhite beforeImage={upTriangle}>
                <ContentRenderer
                    textAlign="center"
                    color="secondary"
                    source={intl.formatMessage({
                        id: 'auth.business.title',
                    })}
                />
            </HeroWrapper>
            <Separator />
            <LandingIntroWrapper beforeImage={rightHeartPart} width={400} height={400}>
                <Container>
                    <ContentRenderer
                        textAlign="left"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.business.intro.title',
                        })}
                    />
                    <ContentRenderer
                        textAlign="left"
                        color="light"
                        source={intl.formatMessage({
                            id: 'auth.business.intro.content',
                        })}
                    />
                    <ButtonWrapper width={310} marginY={32} marginX={1}  justifyContent='left'>
                        <LinkButton to={HOME_PATH} >
                            <FormattedMessage id="auth.business.intro.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                </Container>
                <VideoWrapper>
                    <Video controls src={'https://www.youtube.com/embed/FeT4_ocigLc'} />
                </VideoWrapper>
            </LandingIntroWrapper>
            <LandingIntroWrapper bgWhite beforeImage={triangle} afterImage={heartPart}>
                <Container>
                    <ContentRenderer
                        textAlign="left"
                        color="secondary"
                        source={intl.formatMessage({
                            id: 'auth.business.prefer.title',
                        })}
                    />
                    <ContentRenderer
                        textAlign="left"
                        color="mute"
                        source={intl.formatMessage({
                            id: 'auth.business.prefer.content',
                        })}
                    />
                    <ButtonWrapper width={310} marginY={32} marginX={1} justifyContent='left'>
                        <LinkButton to={INQUIRE_PATH} >
                            <FormattedMessage id="auth.business.prefer.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                </Container>
                <VideoWrapper>
                    <Video controls src={'https://www.youtube.com/embed/FeT4_ocigLc'} />
                </VideoWrapper>
            </LandingIntroWrapper>
        </CommonWrapper>
    )
}

export default BusinessesPageRoute;