import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const WelcomeAsideContentFooter = styled.div`
    justify-self: flex-end;
    position: relative;
    text-align: center;
    z-index: 1;

    ${media.md`
        margin-left: ${rem(40)};
        margin-right: ${rem(40)};
    `}
`;

export default WelcomeAsideContentFooter;
