import React from "react";
import { COLORS } from "../common/consts";
import { TextField } from "@mui/material";

const TextInput = ({
  inputType = "text",
  value,
  label,
  onChange,
  multiline,
  styleOverride = {},
  rows = 4,
  ...rest
}) => {
  if (multiline) {
    return (
      <textarea
        // type={inputType}
        rows={rows}
        placeholder={label}
        style={{
          borderRadius: "25px",
          borderWidth: 1,
          borderColor: COLORS.INPUT_BORDER,
          borderStyle: "solid",
          minHeight: "34px",
          paddingLeft: 19,
          paddingRight: 19,
          paddingTop: 9,
          minWidth: 0,
          paddingBottom: 9,
          color: COLORS.INPUT_TEXT,
          backgroundColor: COLORS.LIGHT_COLOR_TEXT,
          flex: 1,
          marginTop: 8,
          ...styleOverride,
        }}
        onChange={onChange}
        {...rest}
      >
        {value}
      </textarea>
    );
  }
  return (
    <TextField
      type={inputType}
      placeholder={label}
      value={value}
      fullWidth
      InputProps={{ sx: { borderRadius: "28px" } }}
      // style={{
      //   borderRadius: "25px",
      //   borderWidth: 1,
      //   borderColor: COLORS.INPUT_BORDER,
      //   borderStyle: "solid",
      //   height: "34px",
      //   paddingLeft: 19,
      //   paddingRight: 19,
      //   paddingTop: 9,
      //   minWidth: 0,
      //   paddingBottom: 9,
      //   color: COLORS.INPUT_TEXT,
      //   backgroundColor: COLORS.LIGHT_COLOR_TEXT,
      //   flex: 1,
      //   marginTop: 8,
      //   ...styleOverride,
      // }}
      onChange={onChange}
      {...rest}
    />
  );
};

export default TextInput;
