import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import carouselTheme from '../styles/carouselTheme';

// Slick carousel has an active class on the parent <li> element
// Pull request: Pass in if current dot is activeDot on customPaging
// https://github.com/akiran/react-slick/pull/1270

const SliderDot = styled.button`
    background-color: ${({ theme, dotsType }) =>
        get(
            theme,
            `carousel.dots.background.${dotsType}`,
            carouselTheme.carousel.dots.background[dotsType],
        )};
    border-radius: 50%;
    border-width: 0;
    border-style: solid;
    border-color: ${({ theme, dotsType }) =>
        get(
            theme,
            `carousel.dots.border.${dotsType}`,
            carouselTheme.carousel.dots.border[dotsType],
        )};
    cursor: pointer;

    height: ${({ theme, dotsType }) =>
        rem(
            get(
                theme,
                `carousel.dots.size[${dotsType}]`,
                carouselTheme.carousel.dots.size[dotsType],
            ),
        )};
    margin: 0;
    padding: 0;
    width: ${({ theme, dotsType }) =>
        rem(
            get(
                theme,
                `carousel.dots.size[${dotsType}]`,
                carouselTheme.carousel.dots.size[dotsType],
            ),
        )};
    transition: all 0.15s ease-in-out;

    &:hover,
    &:focus {
        outline: none;
        transform: scale(0.95);
    }

    ${({ dotsType, theme }) =>
        dotsType === 'default' &&
        css`
            .slick-active & {
                background-color: ${get(
                    theme,
                    'carousel.dots.activeBackground.default',
                    carouselTheme.carousel.dots.activeBackground.default,
                )};
            }
        `}

    ${({ dotsType, theme }) =>
        dotsType === 'primary' &&
        css`
            border-width: 2px;

            .slick-active & {
                background-color: ${get(
                    theme,
                    'carousel.dots.border.primary',
                    carouselTheme.carousel.dots.border.primary,
                )};
            }
        `}
`;

export default SliderDot;
