import { put, takeEvery } from "redux-saga/effects";
import {
  GetEntityLinkRequests,
  UpdateEntityLinkRequests,
  CreateEntityLinkRequests, 
} from "../../api";
import {
  GET_ENTITY_LINKS_TO_ENTITY,
  GET_ENTITY_LINKS_TO_ENTITY_CALL,
  GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
  POST_ENTITY_LINKS_TO_ENTITY,
  POST_ENTITY_LINKS_TO_ENTITY_CALL,
  POST_ENTITY_LINKS_TO_ENTITY_SUCCESS,
  PUT_ENTITY_LINKS_TO_ENTITY_SUCCESS
} from "../actions/OwnABussinessActions";
import {
  PUT_REQUESTTOLINK_ERROR,
  PUT_REQUESTTOLINK_START,
  PUT_REQUESTTOLINK_PROCESS,
  PUT_REQUESTTOLINK_SUCCESS,
} from "../actions/RequestToLink";
import genericSagaHandler from "./CommonSaga";

function* getEntityLinksToEntitySaga({payload}) {
  yield genericSagaHandler(GET_ENTITY_LINKS_TO_ENTITY, function* () {
      const [results] = yield GetEntityLinkRequests(payload);

      yield put({
        type: GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
        payload: {
          ...payload,
          results
        },
      });      
  });
}

function* postEntityLinksToEntitySaga({ payload }) {
  yield genericSagaHandler(POST_ENTITY_LINKS_TO_ENTITY, function* () {
    const { entityLinkRequestsId, approvalstatus } = payload;
    const [results] = yield UpdateEntityLinkRequests({ entityLinkRequestsId, approvalstatus });
    yield put({
      type: POST_ENTITY_LINKS_TO_ENTITY_SUCCESS,
      payload: {
        ...payload,
        results
      },
    });      
  });
}

function* putRequestToLinkSaga({ payload }) {
  yield genericSagaHandler(PUT_REQUESTTOLINK_PROCESS, function* () {
      const { requestedEntityType } = payload

      const [results, err] = yield CreateEntityLinkRequests({
          "entityIdRequested": payload.entityId,
          "requestingEntityId": payload.requestingEntityId,
          "name": payload.name,
          "firstName": payload.firstName,
          "lastName": payload.lastName,
          "approvalstatus": "PENDING",
          "message": payload.message,
          "position": payload.position
      });
      if(err) {
          yield put({
              type: PUT_REQUESTTOLINK_ERROR,
              payload: {
                  requestId: '',
                  status: 'ERROR',
                  code: err.code,
              },
          });
          return;
      }

      yield put({
          type: PUT_ENTITY_LINKS_TO_ENTITY_SUCCESS,
          payload: {
              requestedEntityType,
              item: results
          },
      })

      const requestId = results._id;
      if(payload.approvalstatus && payload.approvalstatus !== 'PENDING') {
          // If a payload is provided, then update the payload
          const [_results, err] = yield UpdateEntityLinkRequests({
              entityLinkRequestsId: requestId,
              approvalstatus: payload.approvalstatus,
          });
          if(err) {
              yield put({
                  type: PUT_REQUESTTOLINK_ERROR,
                  payload: {
                      requestId: '',
                      status: 'ERROR',
                      code: err.code,
                  },
              });
              return;
          }

          yield put({
              type: POST_ENTITY_LINKS_TO_ENTITY_SUCCESS,
              payload: {
                  requestedEntityType,
                  entityLinkRequestsId: _results._id,
                  approvalstatus: _results.approvalstatus,
              },
          })
    }

      yield put({
          type: PUT_REQUESTTOLINK_SUCCESS,
          payload: {
              requestId,
          },
      });
  });
}

export default function* EntityLinkToEntitySagas() {  
  yield takeEvery(GET_ENTITY_LINKS_TO_ENTITY_CALL, getEntityLinksToEntitySaga);
  yield takeEvery(POST_ENTITY_LINKS_TO_ENTITY_CALL, postEntityLinksToEntitySaga);
  yield takeEvery(PUT_REQUESTTOLINK_START, putRequestToLinkSaga);
}