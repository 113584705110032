import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'baseline-ui/modal';
import { ContentRenderer } from 'baseline-ui/helpers';
import { CONTEXT_TYPES } from 'common/consts';

const ModalPhotoConfirmDelete = ({ onRequestClose, confirmDeleteCallback, context, ...props }) => {
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.deletePhotos.title' })}
            handleOnDismiss={onRequestClose}
            size="sm"
            {...props}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deletePhotos.footerActions.notNow.button.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => {
                        onRequestClose();
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deletePhotos.footerActions.delete.button.label',
                    }),
                    onClick: () => {
                        confirmDeleteCallback();
                        onRequestClose();
                    },
                },
            ]}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: `addBusiness.modal.deletePhotos.content.${context}.md`,
                })}
            />
        </Modal>
    );
};

ModalPhotoConfirmDelete.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    confirmDeleteCallback: PropTypes.func.isRequired,
    context: PropTypes.oneOf(CONTEXT_TYPES).isRequired,
};

ModalPhotoConfirmDelete.defaultProps = {};

export default ModalPhotoConfirmDelete;
