import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import { Loader } from 'baseline-ui/helpers';
import IconContainer from './IconContainer';
import LabelContainer from './LabelContainer';
import buttonTheme from '../../styles/buttonTheme';
import buttonPropTypes from '../../prop-types/buttonPropTypes';

const Label = ({ skin, icon, iconPostLabel, spin, iconRotate, size, color, btnSize, isLoading, children }) => {
    const theme = useContext(ThemeContext);
    let iconSizeValue = null;

    if (size) {
        iconSizeValue = parseInt(`${size}`, 10);
    } else {
        iconSizeValue =
            (theme && theme.button && theme.button.iconSize && theme.button.iconSize[btnSize]) ||
            buttonTheme.button.iconSize[btnSize];
    }

    const renderIcon = isLoading ? (
        <Loader skin={skin} />
    ) : (
        <GaIcon icon={icon} size={iconSizeValue} color={color} btnSize={btnSize} />
    );

    return (
        <>
            {!iconPostLabel && <IconContainer spin={spin} iconRotate={iconRotate}>{renderIcon}</IconContainer>}
            {children && (
                <LabelContainer
                    hasIcon={icon || isLoading || false}
                    sizeValue={iconSizeValue}
                    iconPostLabel={iconPostLabel}
                >
                    {children}
                </LabelContainer>
            )}
            {iconPostLabel && <IconContainer spin={spin} iconRotate={iconRotate}>{renderIcon}</IconContainer>}
        </>
    );
};

Label.propTypes = {
    skin: buttonPropTypes.buttonSkin,
    btnSize: buttonPropTypes.buttonSize.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string,
    spin: PropTypes.bool,
    size: PropTypes.number,
    isLoading: PropTypes.bool,
    children: PropTypes.node,
    iconPostLabel: PropTypes.bool,
    iconRotate: PropTypes.bool,
};

Label.defaultProps = {
    skin: undefined,
    spin: false,
    color: 'currentColor',
    size: null,
    isLoading: false,
    children: null,
    icon: undefined,
    iconPostLabel: false,
    iconRotate: false,
};

export default Label;
