import React from 'react';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import { typographyPropTypes } from 'baseline-ui/typography';
import reactMarkdownUtils from '../../utils/reactMarkdownUtils';

const ContentRenderer = ({ source, isEscapedHtml, color, textAlign }) => {
    if (!source) {
        return null;
    }

    const htmlDecode = (input) => {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
    };

    const sourceToParse = isEscapedHtml ? htmlDecode(source) : source;

    return (
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={reactMarkdownUtils.components({ color, textAlign })}
        >
            {sourceToParse}
        </ReactMarkdown>
    );
};

ContentRenderer.propTypes = {
    source: PropTypes.string,
    isEscapedHtml: PropTypes.bool,
    color: typographyPropTypes.typographyColor,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
};

ContentRenderer.defaultProps = {
    source: undefined,
    isEscapedHtml: false,
    color: undefined,
    textAlign: undefined,
};

export default ContentRenderer;
