import styled from "styled-components";
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const ListWrapper = styled.div`
    margin-top: ${rem(8)};
    margin-bottom: ${rem(16)};
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    grid-template-rows: repeat(6, 1fr); 
    margin-left: ${rem(-8)};
    ${media.md`
        margin-top: ${rem(0)};
        grid-template-columns: repeat(3, 1fr); 
        grid-template-rows: repeat(2, 1fr);
    `};
`;

export default ListWrapper;