import styled from 'styled-components';
import { get } from 'lodash';
import { transparentize } from 'polished';
import formTheme from '../../styles/formTheme';

const FormSelectOption = styled.option`
    color: ${({ theme, skin }) =>
        get(theme, `form.select.${skin}.optionColor`, formTheme.form.select[skin].optionColor)};

    &:disabled {
        color: ${({ theme, skin }) =>
            transparentize(
                0.3,
                get(
                    theme,
                    `form.select.${skin}.optionColor`,
                    formTheme.form.select[skin].optionColor,
                ),
            )};
    }
`;

export default FormSelectOption;
