import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import {authTheme} from "baseline-ui/auth";
import { get } from 'lodash';

const WelcomeMainContainer = styled.div`
    background-color: ${({ theme }) => theme.site.welcome.bgEnd};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    display: flex;
    flex-direction: column;
    justify-content:  ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
    margin-bottom: ${rem(40)};
    margin-top: ${rem(40)};
    min-height: 55vh;
    padding-bottom: ${rem(40)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(40)};
    position: relative;
    z-index: 1;
    overflow: hidden;
    ${({ border }) =>
    border &&
    css`
        border-width: 1px;
        border-style: solid;
        border-color: ${({ theme }) => get(theme, 'auth.border.color', authTheme.auth.border.color)}; 
        border-radius: ${({ theme }) => rem(authTheme.auth.border.borderRadius)};
        background-color: ${({ theme }) =>  theme.site.welcome.bgEnd};
       
    `}

   ${({ beforeImage }) =>
    beforeImage &&
    css`
            &::before {
                content: '';
                background-image: url(${beforeImage});
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: -25%;
                height: 100%;
                z-index: -1; 
                opacity: 0.1;
                ${media.xxs`
                    background-size: cover;
                    background-position-x: 100%;
                    width: 100%;
                `}
                ${media.sm`
                    width: 67%;
                `}
                ${media.md`
                    background-size: contain;
                    width: 50%;
                `}
            }
        `}

    ${({ afterImage }) =>
    afterImage &&
    css`
            &::after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                right: -25%;
                width: 50%;
                height: 100%;
                z-index: -1;
                opacity: 0.1;
               ${media.xxs`
                    background-image: none;
                `}
                ${media.md`
                    background-image: url(${afterImage});
                `}
            }
        `}

    ${media.md`
        background-color: transparent; 
        border-radius: unset;
        margin: unset;
        height: fit-content;
        padding-left: 0;
        padding-right: 0; 
        padding-bottom: ${rem(16)};
        ${({ border }) =>
            border &&
            css`
                background-color: ${({ theme }) =>  theme.site.welcome.bgEnd}; 
                padding-left: ${rem(40)};
                padding-right: ${rem(40)};
                border-radius: ${({ theme }) => rem(authTheme.auth.border.borderRadius)};
            `}
    `};
`;

export default WelcomeMainContainer;
