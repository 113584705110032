import React from 'react';
// import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import {
    allSectionActiveProductsBySectionNameSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import productsAndServicesUtils from 'utils/productsAndServicesUtils';
import ModalBrowseProductsForm from './ModalBrowseProductsForm';

const ModalBrowseProducts = ({ ...props }) => {
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const allSectionActiveProductsBySectionName = useSelector(
        allSectionActiveProductsBySectionNameSelector(selectedSectionName),
    );

    const initialSelectedProductsIds = productsAndServicesUtils.getProductIds(
        allSectionActiveProductsBySectionName,
    );

    const initialValues = {
        ...productsAndServicesUtils.initialValuesSelectedProductIdsFormik(
            initialSelectedProductsIds,
        ),
    };
    return (
        <Formik initialValues={initialValues}>
            {() => {
                return <ModalBrowseProductsForm {...props} />;
            }}
        </Formik>
    );
};

ModalBrowseProducts.propTypes = {};

ModalBrowseProducts.defaultProps = {};

export default ModalBrowseProducts;
