import { ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_DESTINATION, ENTITY_TYPE_PEOPLE } from "common/consts";
import {
  ADD_BUSINESS_PRODUCT_SUCCESS,
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_BUSINESS_SUCCESS,
  GET_FEATURE_MAPPING_SUCCESS,
  RESET_BUSINESS_START,
  RESET_PRODUCTS_START,
  SET_PRODUCT_FILTER_SUCCESS,
  GET_ENTITY_LINKS_TO_ENTITY_ERROR,
  GET_ENTITY_LINKS_TO_ENTITY_START,
  GET_ENTITY_LINKS_TO_ENTITY_RESET,
  GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
  POST_ENTITY_LINKS_TO_ENTITY_SUCCESS,
  PUT_ENTITY_LINKS_TO_ENTITY_SUCCESS
} from "../actions/OwnABussinessActions";

const OwnABusinessReducer = (state = {
  inprogress: false,
  people: [],
  businesses: [],
  destinations: [],
  error: ''
}, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_PRODUCTS_START: {
      return {
        ...state,
        products: undefined,
      };
    }
    case RESET_BUSINESS_START: {
      const newState = { ...state };

      delete newState.business;
      delete newState.products;
      return newState;
    }
    case SET_PRODUCT_FILTER_SUCCESS: {
      return {
        ...state,
        productFilter: payload,
      };
    }
    case GET_BUSINESS_SUCCESS: {
      return { ...state, ...payload };
    }
    case ADD_BUSINESS_PRODUCT_SUCCESS:
    case GET_BUSINESS_PRODUCTS_SUCCESS: {
      return { ...state, products: payload };
    }  
    case GET_FEATURE_MAPPING_SUCCESS: {
      return {
        ...state,
        featureMapping: payload,
      };
    }
    case POST_ENTITY_LINKS_TO_ENTITY_SUCCESS: {
      const {requestedEntityType, entityLinkRequestsId, approvalstatus} = payload

      switch (requestedEntityType) {
        case ENTITY_TYPE_PEOPLE:
          return {
            ...state,
            people: state.people.map(entity => {
              if(entity._id === entityLinkRequestsId) {
                return {
                  ...entity,
                  approvalstatus
                }
              }
              return entity
            })
          }
        case ENTITY_TYPE_BUSSINESS:
          return {
            ...state,
            businesses: state.businesses.map(entity => {
              if(entity._id === entityLinkRequestsId) {
                return {
                  ...entity,
                  approvalstatus
                }
              }
              return entity
            })
          }
        case ENTITY_TYPE_DESTINATION:
          return {
            ...state,
            destinations: state.destinations.map(entity => {
              if(entity._id === entityLinkRequestsId) {
                return {
                  ...entity,
                  approvalstatus
                }
              }
              return entity
            })
          }
        default:
          return state;
      }
    }
    case PUT_ENTITY_LINKS_TO_ENTITY_SUCCESS: {
      const {requestedEntityType, item} = payload

      switch (requestedEntityType) {
        case ENTITY_TYPE_PEOPLE:
          return {
            ...state,
            people: [
              ...state.people,
              item
            ]
          }
        case ENTITY_TYPE_BUSSINESS:
          return {
            ...state,
            businesses: [
              ...state.businesses,
              item
            ]
          }
        case ENTITY_TYPE_DESTINATION:
          return {
            ...state,
            destinations: [
              ...state.destinations,
              item
            ]
          }
        default:
          return state;
      }
    }
    case GET_ENTITY_LINKS_TO_ENTITY_RESET: {
      return {
        ...state,
        people: [],
        destinations: [],
        businesses: [],
        inprogress: false,
        error: ''
      }
    }
    case GET_ENTITY_LINKS_TO_ENTITY_START: {
      return {
        ...state,
        inprogress: true,
      }
    }
    case GET_ENTITY_LINKS_TO_ENTITY_SUCCESS: {
      const { entityRequestingType, results } = payload;
      const copiedState = { ...state }

      switch (entityRequestingType.toUpperCase()) {
        case ENTITY_TYPE_PEOPLE:
          copiedState.people = results
        case ENTITY_TYPE_DESTINATION:
          copiedState.destinations = results
          break;
        case ENTITY_TYPE_BUSSINESS:
            copiedState.businesses = results
            break;
        default:
          break;
      }

      return {
        ...copiedState,
        inprogress: false
      }
    }
    case GET_ENTITY_LINKS_TO_ENTITY_ERROR: {
      return {
        ...state,
        inprogress: false,
        error: payload.error
      }
    }
    default: {
      return { ...state };
    }
  }
};
export default OwnABusinessReducer;
