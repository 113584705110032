import { baseTheme } from 'baseline-ui/base-theme';

const overlayTheme = {
    overlay: {
        backgroundColor: baseTheme.base.colors.light,
        footer: {
            backgroundColor: baseTheme.base.colors.light,
            dividerColor: baseTheme.base.grays.grayLighter,
        },
        content: {
            boxShadowColor: 'rgb(0, 0, 0, 0.04)',
            borderRadius: baseTheme.base.borderRadius.default,
            zIndex: 90,
        },
        overlay: {
            zIndex: 89,
            color: 'rgba(0, 0, 0, 0.35)',
        },
        width: {
            sm: 400,
            default: 600,
            lg: 800,
            xl: 1000,
        },
        padding: {
            v: 16,
            h: 32,
        },
        closeIcon: {
            size: 28,
            color: baseTheme.base.grays.grayLight,
            icon: 'x',
        },
        transition: {
            y: -100,
        },
    },
};

export default overlayTheme;
