import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SiteMetadata = ({
    canonical,
    siteName,
    siteUrl,
    title,
    siteIcon,
    siteImage,
    siteImageAlt,
    siteDescription,
    twitterHandle,
    keywords,
}) => {
    const renderImage = siteImage || `${siteUrl}${siteIcon}`;

    return (
        <Helmet defaultTitle={siteName} titleTemplate={`%s | ${siteName}`}>
            <html lang="en" />
            {title && <title>{title}</title>}
            <meta name="description" content={siteDescription} />
            {keywords && <meta name="keywords" content={keywords} />}
            <link rel="canonical" href={canonical} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={siteDescription} />
            <meta property="og:url" content={canonical} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en" />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:image" content={renderImage} />
            <meta name="twitter:card" content="summary" />
            {siteImageAlt && <meta property="og:image:alt" content={siteImageAlt} />}
            {siteImageAlt && <meta property="twitter:image:alt" content={siteImageAlt} />}
            {twitterHandle && <meta name="twitter:site" content={twitterHandle} />}
            {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}
            <meta name="robots" content="index, follow" />
        </Helmet>
    );
};

SiteMetadata.propTypes = {
    canonical: PropTypes.string,
    siteName: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    title: PropTypes.string,
    siteIcon: PropTypes.string.isRequired,
    siteImage: PropTypes.string,
    siteImageAlt: PropTypes.string,
    siteDescription: PropTypes.string.isRequired,
    twitterHandle: PropTypes.string,
    keywords: PropTypes.string,
};

SiteMetadata.defaultProps = {
    title: undefined,
    canonical: undefined,
    siteImage: undefined,
    siteImageAlt: undefined,
    twitterHandle: undefined,
    keywords: undefined,
};

export default SiteMetadata;
