import { rem } from 'polished';
import styled from 'styled-components';
import { smallStyles } from 'baseline-ui/typography';

const EntityTypeTagContainer = styled.span`
    ${smallStyles};
    background-color: ${({ theme }) => theme.base.colors.secondary};
    border-radius: ${({ theme }) => rem(theme?.base?.borderRadius?.lg)};
    color: ${({ theme }) => theme.base.colors.light};
    display: inline-block;
    padding: ${rem(4)} ${rem(16)};
`;

export default EntityTypeTagContainer;
