import styled from 'styled-components';

const Container = styled.div`
    background-size: cover;
    height: 100%;
    transition: background-image 0.1s ease-in;
    width: 100%;
`;

export default Container;
