import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import Heading4 from './Heading4';

function H4({ color, className, children, isUppercase, isBaseFont, textAlign, as }) {
    return (
        <Heading4
            className={className}
            colorValue={color}
            isUppercase={isUppercase}
            isBaseFont={isBaseFont}
            textAlign={textAlign}
            as={as}
        >
            {children}
        </Heading4>
    );
}

H4.propTypes = {
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    isUppercase: PropTypes.bool,
    children: PropTypes.node,
    isBaseFont: PropTypes.bool,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    as: PropTypes.string,
};

H4.defaultProps = {
    color: 'dark',
    className: null,
    isUppercase: false,
    children: null,
    isBaseFont: false,
    textAlign: undefined,
    as: undefined,
};

export default H4;
