import styled from 'styled-components';
import { get } from 'lodash';
import { rem, lighten } from 'polished';
import tooltipTheme from '../styles/tooltipTheme';

const TooltipDivider = styled.span.attrs(() => ({ role: 'separator' }))`
    background-color: ${({ theme }) =>
        lighten(0.1, get(theme, 'tooltip.borderColor', tooltipTheme.tooltip.borderColor))};
    height: ${rem(1)};
    margin: ${rem(5)} 0;
    overflow: hidden;
`;

export default TooltipDivider;
