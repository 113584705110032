import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import MountedContext from './MountedContext';

const initialState = {
    hasMounted: undefined,
    setHasMounted: () => {},
};

const actions = {
    SET_HAS_MOUNTED: 'SET_HAS_MOUNTED',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_HAS_MOUNTED:
            return {
                ...state,
                hasMounted: action.hasMounted,
            };
        default:
            return state;
    }
};

const MountedProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            hasMounted: state.hasMounted,
            setHasMounted: (hasMounted) => {
                dispatch({ type: actions.SET_HAS_MOUNTED, hasMounted });
            },
        }),
        [state],
    );

    return <MountedContext.Provider value={value}>{children}</MountedContext.Provider>;
};

MountedProvider.propTypes = {
    children: PropTypes.node,
};

MountedProvider.defaultProps = {
    children: undefined,
};

export default MountedProvider;
