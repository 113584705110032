import React from 'react';
import { H3 } from 'baseline-ui/typography';
import { ContentRenderer } from 'baseline-ui/helpers';
import joinBetaBackgroundImage from 'images/banner.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import LargeH1 from 'baseline-ui/typography/src/components/LargeH1';
import WelcomeAsideContainer from '../WelcomeAsideContainer';
import WelcomeAsideContentContainer from '../WelcomeAsideContentContainer';
import WelcomeAsideBackgroundImage from '../WelcomeAsideBackgroundImage';
import WelcomeAsideContentRendererContainer from './WelcomeAsideContentRendererContainer';
import WelcomeAsideContentFooter from './WelcomeAsideContentFooter';

const JoinBetaAside = () => {
    const intl = useIntl();

    return (
        <WelcomeAsideContainer>
            <WelcomeAsideContentContainer>
                <WelcomeAsideContentRendererContainer>
                    <LargeH1 colorValue="light">
                        <FormattedMessage id="welcome.join.aside.title" />
                    </LargeH1>
                    <ContentRenderer
                        color="light"
                        source={intl.formatMessage({ id: 'welcome.join.aside.content.md' })}
                    />
                </WelcomeAsideContentRendererContainer>
            </WelcomeAsideContentContainer>
            <WelcomeAsideContentFooter>
                <H3 color="light">
                    <FormattedMessage id="welcome.join.aside.footer.label" />
                </H3>
            </WelcomeAsideContentFooter>
            <WelcomeAsideBackgroundImage image={joinBetaBackgroundImage} />
        </WelcomeAsideContainer>
    );
};

export default JoinBetaAside;
