import siteBaseTheme from './siteBaseTheme';

const siteOverlayTheme = {
    overlay: {
        content: {
            borderRadius: siteBaseTheme.base.borderRadius.lg,
        },
    },
};

export default siteOverlayTheme;
