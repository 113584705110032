import { rem } from 'polished';
import styled from 'styled-components';

const FavePageAsideImageContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: ${rem(8)};
`;

export default FavePageAsideImageContainer;
