import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import {
    allCategoriesAndFiltersSelector,
    businessFormSelector,
    getSubcategoryFilters,
    getSubcategoryById,
} from 'redux/selectors/businessFormSelectors';
import categoriesAndFiltersUtils from 'utils/categoriesAndFiltersUtils';
import {
    GET_BUSINESS_CATEGORIES_START,
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
} from '../../redux/actions/CategoriesActions';
import DataTable from './DataTable';
import CategoriesForm from './CategoriesForm';
import PropTypes from 'prop-types';
import AdminBusinessAdvertisingAddItem
    from '../../components/favePageMain/admin/AdminBusinessStatus/AdminBusinessAdvertising/AdminBusinessAdvertisingAddItem';

const BaseCategories = ({
    selectedCategories,
    onSubmit,
    onRemoveFilter,
    onRemoveCategory,
    onRemoveSubcategory,
}) => {
    const dispatch = useDispatch();
    const { type } = useSelector(businessFormSelector);
    const categories = useSelector(allCategoriesAndFiltersSelector);
    const [categoryId, setCategoryId] = useState(null);
    const [subcategoryId, setSubcategoryId] = useState(null);
    const subcategoryFilters = useSelector((state) =>
        getSubcategoryFilters(state, subcategoryId || categoryId),
    );
    const subcategories = useSelector((state) => getSubcategoryById(state, categoryId));
    const tableData = categoriesAndFiltersUtils.getOrganizedSelectedTableData(selectedCategories);

    useEffect(() => {
        if(type) {
            dispatch({
                type: GET_BUSINESS_CATEGORIES_START,
            });
        }
    }, [type]);

    const onSubmitData = (values) => {
        onSubmit(categoriesAndFiltersUtils.handleOnSubmitFormikAddCategoryAndFilters({
            values,
            categories,
            subcategories,
            subcategoryFilters,
            selectedCategories,
        }))
    };

    const handleCategoryChange = (e, setFieldValue) => {
        const categoryId = e.target.value;
        setCategoryId(categoryId);
        setFieldValue('category', categoryId);
        setSubcategoryId('');
        setFieldValue('subcategory', '');
        setFieldValue('filter', []);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });
    };

    const handleSubcategoryChange = (e, setFieldValue) => {
        const subcategoryId = e.target.value;
        setSubcategoryId(subcategoryId);
        setFieldValue('subcategory', subcategoryId);
        setFieldValue('filter', {});
        if(subcategoryId !== ''){
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId, categoryId },
            });
        }
    };

    const handleAddFilterClick = (categoryId, subcategoryId) => {
        setCategoryId(categoryId);
        setSubcategoryId(subcategoryId);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });

        const scrollPosition = window.innerWidth >= 600 ? 300 : 500;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    return (
        <>
            <div>
                <CategoriesForm
                    onSubmit={onSubmitData}
                    categories={categories}
                    subcategories={subcategories}
                    selectedCategories={selectedCategories}
                    handleCategoryChange={handleCategoryChange}
                    handleSubcategoryChange={handleSubcategoryChange}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    subcategoryFilters={subcategoryFilters}
                />

                <H1 color="secondary">
                    <FormattedMessage id="addBusiness.page.addedCategoryAndFilters.table.title" />
                </H1>

                <DataTable
                    data={tableData}
                    onRemoveFilter={filterId => {
                        if (onRemoveFilter) {
                            onRemoveFilter({selectedCategories, filterId});
                        }
                    }}
                    onRemoveSubcategory={subcategoryId => {
                        if (onRemoveSubcategory) {
                            onRemoveSubcategory({selectedCategories, subcategoryId});
                        }
                    }}
                    onRemoveCategory={categoryId => {
                        if (onRemoveCategory) {
                            onRemoveCategory({selectedCategories, categoryId});
                        }
                    }}
                    onAddFilter={handleAddFilterClick}
                />
            </div>
        </>
    );
};

BaseCategories.propTypes = {
    selectedCategories: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRemoveFilter: PropTypes.func,
    onRemoveCategory: PropTypes.func,
    onRemoveSubcategory: PropTypes.func,
};

export default BaseCategories;
