import React from 'react';
import CommonWrapper from '../CommonWrapper';
import PageHeader from "../../components/pageLayout/PageHeader";
import {useIntl} from "react-intl";
import NotificationTable from "./NotificationTable";

const LinkNotifications = () => {
    const intl = useIntl();

    return (
        <CommonWrapper>
           <PageHeader title={intl.formatMessage({ id: 'siteHeader.notifications.page.title' })} />
           <NotificationTable />
        </CommonWrapper>
    );
};

export default LinkNotifications;
