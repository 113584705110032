import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const HeroWrapper = styled.div`
    background-color: ${({ theme, bgWhite }) => bgWhite ? theme.site.welcome.bgEnd : theme.site.welcome.bg};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%
    margin: 0 auto;
    padding-bottom: ${rem(16)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    z-index: 1;
};
    ${({ beforeImage }) =>
    beforeImage &&
    css`
            &::before {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 50px;
                background-image: url(${beforeImage});
               
                ${media.md`
                    background-image: url(${beforeImage});
                    width: 100px;
                    height: 100px;
                `}
            }
        `}
    ${media.md`
        height: 200px;
    `};
`;

export default HeroWrapper;