import { ERROR_RESET_ALL } from "../actions/ErrorActions";

const ErrorReducer = (state = {}, { payload, type }) => {
  const processState = type.substr(type.lastIndexOf("_") + 1);
  const processName = type.substr(0, type.lastIndexOf("_"));
  if (processState === "ERROR") {
    return {
      ...state,
      [processName]: payload,
    };
  }

  if (processState === "RESET" || processState === "SUCCESS") {
    return {
      ...state,
      [processName]: null,
    };
  }

  if (type === ERROR_RESET_ALL) {
    return {};
  }
  return {
    ...state,
  };
};

export default ErrorReducer;
