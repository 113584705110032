import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import carouselTheme from '../styles/carouselTheme';

const SliderArrow = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    opacity: 1;
    padding: 0;
    position: absolute;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;

    &:focus {
        outline: 0;
    }

    ${(props) =>
        props.vertical === false &&
        css`
            top: 50%;
            transform: translate(0, -50%);
        `}

    ${(props) =>
        props.vertical &&
        css`
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
        `}

    ${(props) =>
        props.arrowType === 'default' &&
        css`
            ${props.vertical &&
            props.slide === 'prev' &&
            css`
                top: -${rem(get(props, `theme.carousel.arrows[${props.arrowSize}].size`, carouselTheme.carousel.arrows[props.arrowSize].size))};
                &:active {
                    transform: translate(-50%, -1px);
                }
            `}

            ${props.vertical &&
            props.slide === 'next' &&
            css`
                bottom: -${rem(get(props, `theme.carousel.arrows[${props.arrowSize}].size`, carouselTheme.carousel.arrows[props.arrowSize].size))};
                &:active {
                    transform: translate(-50%, 1px);
                }
            `}

            ${props.vertical === false &&
            props.slide === 'prev' &&
            css`
                left: -${rem(get(props, `theme.carousel.arrows[${props.arrowSize}].size`, carouselTheme.carousel.arrows[props.arrowSize].size))};
                &:active {
                    transform: translate(-1px, -45%);
                }
            `} 

            ${props.vertical === false &&
            props.slide === 'next' &&
            css`
                right: -${rem(get(props, `theme.carousel.arrows[${props.arrowSize}].size`, carouselTheme.carousel.arrows[props.arrowSize].size))};
                &:active {
                    transform: translate(1px, -45%);
                }
            `}
                
            /* if (!this.props.infinite && 
                (this.props.currentSlide === 0 || this.props.slideCount <= this.props.slidesToShow)) { */
            &.slick-disabled {
                cursor: not-allowed;
                opacity: 0.2;
            }
        `}
        

    ${(props) =>
        props.arrowType === 'primary' &&
        css`
            ${props.vertical &&
            props.slide === 'prev' &&
            css`
                top: 0;
                &:active {
                    transform: translate(-50%, -1px);
                }
            `}

            ${props.vertical &&
            props.slide === 'next' &&
            css`
                bottom: 0;
                &:active {
                    transform: translate(-50%, 1px);
                }
            `}

            ${props.vertical === false &&
            props.slide === 'prev' &&
            css`
                left: 13px;
                &:active {
                    transform: translate(-1px, -45%);
                }
            `} 

            ${props.vertical === false &&
            props.slide === 'next' &&
            css`
                right: 13px;
                &:active {
                    transform: translate(1px, -45%);
                }
            `}
                    
            /* if (!this.props.infinite && 
                (this.props.currentSlide === 0 || this.props.slideCount <= this.props.slidesToShow)) { */
            &.slick-disabled {
                cursor: default;
                opacity: 0;
            }
        `}

    ${(props) =>
        props.vertical === false &&
        props.slide === 'prev' &&
        props.rtl &&
        css`
            left: auto;
            right: 13px;
        `}

    ${(props) =>
        props.vertical === false &&
        props.slide === 'next' &&
        props.rtl &&
        css`
            right: auto;
            left: 13px;
        `}
`;

export default SliderArrow;
