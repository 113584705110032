import styled, {css} from 'styled-components';
import siteBaseTheme from "styles/siteBaseTheme";
import {rem} from "polished";

const Separator = styled.div`
     border-style: solid;
     border-color: ${siteBaseTheme.base.colors.primary};
     border-width: ${({borderWidth}) =>
    borderWidth
        ? `${borderWidth}px`
        : '6px'
    };
 ${({ marginX }) =>
    marginX &&
        css`
         margin-left: ${rem(marginX)};
         margin-right: ${rem(marginX)};
    `}
`;

export default Separator;