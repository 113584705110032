import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import {
    OuterWrapper,
    SiteContentWrapper,
    Container,
    Row,
    MainWrapper,
    isSizes,
    SplitAsideWrapper,
    SplitAsidePageContentWrapper,
} from 'baseline-ui/layout';
import AdminMain from './AdminMain';
import SplitAside from './SplitAside';

const SplitAsideMainTemplate = ({
    main,
    bgStart,
    bgImage,
    bgEnd,
    aside,
    footer,
    isMobile,
    isFullWidth,
}) => {
    return (
        <OuterWrapper>
            <SplitAsidePageContentWrapper
                bgStart={bgStart}
                bgEnd={bgEnd}
                isMobile={isMobile}
                bgImage={bgImage}
            >
                <SiteContentWrapper isFullWidth={isFullWidth}>
                    <Container isFullHeight={!isMobile}>
                        <Row
                            alignItems="center"
                            justifyContent="center"
                            isFullHeight={!isMobile}
                            flexWrap={isMobile ? 'wrap' : 'nowrap'}
                        >
                            <SplitAsideWrapper>
                                <SplitAside>{aside}</SplitAside>
                            </SplitAsideWrapper>
                            <MainWrapper>
                                <AdminMain>{main}</AdminMain>
                            </MainWrapper>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            </SplitAsidePageContentWrapper>
            {footer}
        </OuterWrapper>
    );
};

SplitAsideMainTemplate.propTypes = {
    bgStart: PropTypes.string.isRequired,
    bgImage: PropTypes.shape({
        image: PropTypes.string,
        position: PropTypes.string,
        repeat: PropTypes.bool,
        size: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string,
        }),
        opacity: PropTypes.number,
    }),
    bgEnd: PropTypes.string,
    main: PropTypes.element,
    aside: PropTypes.element,
    footer: PropTypes.element,
    isMobile: PropTypes.bool,
    isFullWidth: PropTypes.bool,
};

SplitAsideMainTemplate.defaultProps = {
    bgImage: undefined,
    bgEnd: null,
    main: null,
    aside: null,
    footer: null,
    isMobile: false,
    isFullWidth: false,
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.mdView({ width }, { theme }),
    };
};

export default withSizes(mapSizesToProps)(SplitAsideMainTemplate);
