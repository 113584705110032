import React from 'react';
import PropTypes from 'prop-types';
import { SortableItem } from 'react-easy-sort';
import { isEmpty } from 'lodash';
import PhotoListItemSortableItem from './PhotoListItemSortableItem';
import PhotoListItem from './PhotoListItem';

const PhotoListSortable = ({ photos, onClickDelete, onClickEdit, isInOverlay }) => {
    if (isEmpty(photos)) {
        return null;
    }

    return photos.map((photo, index) => {
        return (
            <SortableItem key={index}>
                <PhotoListItemSortableItem isInOverlay={isInOverlay}>
                    <PhotoListItem
                        image={photo.preview || photo.url}
                        onClickDelete={() => onClickDelete(photo)}
                        onClickEdit={() => onClickEdit(photo, index)}
                    />
                </PhotoListItemSortableItem>
            </SortableItem>
        );
    });
};

PhotoListSortable.propTypes = {
    onClickDelete: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
            order: PropTypes.number,
            state: PropTypes.string,
        }),
    ),
    isInOverlay: PropTypes.bool,
};

PhotoListSortable.defaultProps = {
    photos: undefined,
    isInOverlay: false,
};

export default PhotoListSortable;
