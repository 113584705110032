import './transitionKeyframes.css';

const transitionOptionStyles = (timeout) => [
    {
        type: 'fadeInFadeOut',
        initial: {
            transition: `opacity ${timeout}ms ease-in-out`,
            opacity: 0,
        },
        transition: {
            entering: { opacity: 0 },
            entered: { opacity: 1 },
            exiting: { opacity: 0 },
            exited: { opacity: 0 },
        },
        timeout,
    },
];

export default transitionOptionStyles;
