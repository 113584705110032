import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditSectionFooterPostContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.base.grays.grayLighter};
    margin-top: ${rem(8)};
    padding-top: ${rem(4)};
    display: flex;
    justify-content: flex-end;
`;

export default ModalEditSectionFooterPostContainer;
