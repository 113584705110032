import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem, darken, lighten } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import { buttonTheme } from 'baseline-ui/button';
import tooltipTheme from '../../styles/tooltipTheme';

const OptionButton = styled.button`
    background-color: ${({ theme }) =>
        lighten(
            0.1,
            get(
                theme,
                'tooltip.optionList.item.backgroundColor',
                tooltipTheme.tooltip.optionList.item.backgroundColor,
            ),
        )};
    border: 0;
    color: ${({ theme }) =>
        get(theme, 'tooltip.optionList.item.color', tooltipTheme.tooltip.optionList.item.color)};
    cursor: pointer;
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'button.fontSize.default', buttonTheme.button.fontSize.default))};
    padding: ${rem(7)} ${rem(14)};
    text-align: left;
    touch-action: manipulation;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    user-select: none;
    width: 100%;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: ${({ theme }) =>
            get(
                theme,
                'tooltip.optionList.item.backgroundColorActive',
                tooltipTheme.tooltip.optionList.item.backgroundColorActive,
            )};
        color: ${({ theme }) =>
            get(
                theme,
                'tooltip.optionList.item.colorActive',
                tooltipTheme.tooltip.optionList.item.colorActive,
            )};
    }

    &:focus,
    &:active {
        background-color: ${({ theme }) =>
            darken(
                0.1,
                get(
                    theme,
                    'tooltip.optionList.item.backgroundColorActive',
                    tooltipTheme.tooltip.optionList.item.backgroundColorActive,
                ),
            )};
        color: ${({ theme }) =>
            get(
                theme,
                'tooltip.optionList.item.colorActive',
                tooltipTheme.tooltip.optionList.item.colorActive,
            )};
    }

    ${({ current }) =>
        current &&
        css`
            background-color: ${({ theme }) =>
                darken(
                    0.1,
                    get(
                        theme,
                        'tooltip.optionList.item.backgroundColorActive',
                        tooltipTheme.tooltip.optionList.item.backgroundColorActive,
                    ),
                )};
            color: ${({ theme }) =>
                get(
                    theme,
                    'tooltip.optionList.item.colorActive',
                    tooltipTheme.tooltip.optionList.item.colorActive,
                )};
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.65;
            pointer-events: none;
        `}
`;

export default OptionButton;
