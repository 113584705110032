import styled from 'styled-components';
import { get } from 'lodash';
import { lighten, rem } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';

const CloseButton = styled.button`
    background-color: transparent;
    border: 0;
    color: ${({ theme }) => get(theme, 'base.colors.light', baseTheme.base.colors.light)};
    cursor: pointer;
    line-height: 1;
    margin-left: ${rem(16)};
    order: 1;
    padding: ${rem(2)} ${rem(6)};
    transition: color 0.15s ease-in-out;

    &,
    &:hover,
    &:focus,
    &:active {
        outline: 0;
        text-decoration: none;
    }

    &:focus,
    &:active {
        color: ${({ theme, alertType }) =>
            lighten(
                0.5,
                get(theme, `base.colors[${alertType}]`, baseTheme.base.colors[alertType]),
            )};
    }
`;

export default CloseButton;
