import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import tooltipTheme from '../styles/tooltipTheme';

const CloseButtonIcon = ({ color }) => {
    const theme = useContext(ThemeContext);
    const sizeValue = get(theme, 'tooltip.closeIcon.size', tooltipTheme.tooltip.closeIcon.size);

    return <GaIcon icon="x" color={color} size={sizeValue} />;
};

CloseButtonIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

export default CloseButtonIcon;
