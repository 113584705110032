import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import Item from './Item';
import transitionOptionStyles from '../../styles/transitionOptionStyles';
import helpersPropTypes from '../../prop-types/helpersPropTypes';

const TransitionItem = ({
    children,
    inProp,
    transitionType,
    transitionTimeout,
    customTransition,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    appear,
    mountOnEnter,
    unmountOnExit,
    enter,
    exit,
    isFullHeight,
    isFullWidth,
    ...props
}) => {
    const transitionTypeStyles = (transitionOption, transitionOptionTimeout) => {
        const {
            initial = {},
            transition = {},
            timeout = 0,
        } = customTransition
            ? {}
            : transitionOptionStyles(transitionOptionTimeout).find(
                  (styleOption) => styleOption.type === transitionOption,
              );
        return {
            initial,
            transition,
            timeout,
        };
    };

    const renderStyle = customTransition || transitionTypeStyles(transitionType, transitionTimeout);
    const renderTimeout = transitionTypeStyles(transitionType, transitionTimeout).timeout;

    return (
        <Transition
            in={inProp}
            timeout={renderTimeout}
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
            appear={appear}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}
            enter={enter}
            exit={exit}
            {...props}
        >
            {(state) => (
                <Item
                    isFullHeight={isFullHeight}
                    isFullWidth={isFullWidth}
                    style={{
                        ...renderStyle.initial,
                        ...renderStyle.transition[state],
                    }}
                >
                    {children}
                </Item>
            )}
        </Transition>
    );
};

TransitionItem.propTypes = {
    children: PropTypes.node.isRequired,
    /** Show the component; triggers the enter or exit states */
    inProp: PropTypes.bool,
    /** The transition or animation to apply to the items */
    transitionType: helpersPropTypes.transitionType,
    /** Custom transition or animation to apply to the items */
    customTransition: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /** The duration of the transition on appear, enter and exit, in milliseconds. */
    transitionTimeout: helpersPropTypes.transitionTimeout,
    /** Callback fired before the "entering" status is applied. An extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount */
    onEnter: PropTypes.func,
    /** Callback fired after the "entering" status is applied. An extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount */
    onEntering: PropTypes.func,
    /** Callback fired after the "entered" status is applied. An extra parameter isAppearing is supplied to indicate if the enter stage is occurring on the initial mount */
    onEntered: PropTypes.func,
    /** Callback fired before the "exiting" status is applied. */
    onExit: PropTypes.func,
    /** Callback fired after the "exiting" status is applied. */
    onExiting: PropTypes.func,
    /** Callback fired after the "exited" status is applied. */
    onExited: PropTypes.func,
    /** transition on the first mount */
    appear: PropTypes.bool,
    /** "lazy mount" the component on the first in={true}. After the first enter transition the component will stay mounted, even on "exited", unless you also specify  unmountOnExit */
    mountOnEnter: PropTypes.bool,
    /** unmount the component after it finishes exiting. */
    unmountOnExit: PropTypes.bool,
    /** Enable or disable enter transitions. */
    enter: PropTypes.bool,
    /** Enable or disable exit transitions. */
    exit: PropTypes.bool,
    /** Sets full height for the content container */
    isFullHeight: PropTypes.bool,
    /** Sets full width for the content container */
    isFullWidth: PropTypes.bool,
};

TransitionItem.defaultProps = {
    inProp: false,
    transitionType: 'fadeInFadeOut',
    transitionTimeout: 500,
    customTransition: null,
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {},
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
    appear: false,
    mountOnEnter: false,
    unmountOnExit: false,
    enter: true,
    exit: true,
    isFullHeight: false,
    isFullWidth: true,
};

export default TransitionItem;
