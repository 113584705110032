import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import OptionButton from './OptionButton';
import OptionInner from './OptionInner';
import OptionIcon from './OptionIcon';
import OptionLabel from './OptionLabel';
import tooltipTheme from '../../styles/tooltipTheme';

const TooltipOptionItem = forwardRef(
    ({ label, icon, onClick, current, disabled, ...props }, ref) => {
        const theme = useContext(ThemeContext);
        const iconSize = get(
            theme,
            'tooltip.optionList.item.iconSize',
            tooltipTheme.tooltip.optionList.item.iconSize,
        );
        return (
            <OptionButton
                ref={ref}
                type="button"
                btnType="link"
                block
                onClick={onClick}
                current={current}
                disabled={disabled}
                {...props}
            >
                <OptionInner>
                    {icon && (
                        <OptionIcon>
                            <GaIcon icon={icon} size={iconSize} color="currentColor" />
                        </OptionIcon>
                    )}
                    <OptionLabel>{label}</OptionLabel>
                </OptionInner>
            </OptionButton>
        );
    },
);

TooltipOptionItem.propTypes = {
    label: PropTypes.node.isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    current: PropTypes.bool,
    disabled: PropTypes.bool,
};

TooltipOptionItem.defaultProps = {
    icon: null,
    onClick: null,
    current: false,
    disabled: false,
};

export default TooltipOptionItem;
