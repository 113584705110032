import React from "react";

const ResponsiveImage = ({ image, style = {} }) => {
  return (
    <img
      src={image}
      style={{
        maxWidth: "100%",
        height: "auto",
        padding: 0,
        margin: 0,
        ...style,
      }}
    />
  );
};

export default ResponsiveImage;
