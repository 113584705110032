import { rem } from 'polished';
import styled, { css } from 'styled-components';

const FavePagePreviewEntityTypesTab = styled.button.attrs(() => ({
    type: 'button',
}))`
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
    padding-bottom: ${rem(8)};
    padding-top: ${rem(4)};
    width: 100%;

    ${({ isActive }) =>
        isActive &&
        css`
            border-bottom: 1px solid ${({ theme }) => theme.base.colors.primary};
        `}
`;

export default FavePagePreviewEntityTypesTab;
