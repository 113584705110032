import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesListContainer = styled.div`
    height: ${({ theme }) =>
        rem(theme.site.products.container.height - theme.site.products.search.container.height)};
    overflow: hidden auto;
    padding: ${rem(4)} ${rem(8)};
    position: relative;
`;

export default ProductsAndServicesListContainer;
