import { media } from 'baseline-ui/layout';
import { rem, transparentize } from 'polished';
import styled from 'styled-components';

const MyBusinessesListItemImageContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => transparentize(0.6, theme.base.colors.light)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    display: flex;
    height: ${({ theme }) => rem(theme.site.myBusinessesList.item.image.height)};
    justify-content: center;
    margin-bottom: ${rem(8)};
    overflow: hidden;
    width: 100%;

    ${media.md`
        margin-bottom: ${rem(0)};
    `}
`;

export default MyBusinessesListItemImageContainer;
