import styled from 'styled-components';
import { rem } from 'polished';

const ForgotPasswordLinkContainer = styled.div`
    padding-top: ${rem(8)};
    margin-top: ${rem(-24)};
    margin-bottom: ${rem(32)};
    text-align: right;
    width: 100%;
`;

export default ForgotPasswordLinkContainer;
