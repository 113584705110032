import React from "react";
import { COLORS } from "../common/consts";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextInput from "./TextxInput";

const SelectPicker = ({
  value,
  label,
  onChange,
  styleOverride = {},
  options,
  width = 100,
  multiple,
  ...rest
}) => {
  return (
    // <select
    //   onChange={onChange}
    //   placeholder={label}
    //   style={{
    //     borderRadius: "25px",
    //     borderWidth: 1,
    //     fontSize: "15px",
    //     paddingRight: "5px",
    //     borderColor: COLORS.INPUT_BORDER,
    //     borderStyle: "solid",
    //     minHeight: "45px",
    //     paddingLeft: 19,
    //     paddingTop: 9,
    //     minWidth: 0,
    //     paddingBottom: 9,
    //     color: COLORS.INPUT_TEXT,
    //     backgroundColor: COLORS.LIGHT_COLOR_TEXT,
    //     flex: 1,
    //     marginTop: 8,
    //     ...styleOverride,
    //   }}
    // >
    //   <option key={`select_option_placeholder`} value="">
    //     {label}
    //   </option>
    //   {options.map((option) => (
    //     <option key={`select_option_${option.value}`} value={option.value}>
    //       {option.label}
    //     </option>
    //   ))}
    // </select>

    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        value={value ? value : []}
        label={label}
        multiple={multiple}
        sx={{ borderRadius: 16 }}
        onClick={(e) => onChange(e.target.dataset.value)}
      >
        {options.map((option) => (
          <MenuItem key={`select_option_${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectPicker;
