import {
  GET_PROMO_NOTIFICATIONS_SUCCESS,
  RESET_PROMO_NOTIFICATIONS_START,
  SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
} from "../actions/PushActions";

const DEFAULT_STATE = {
  page: 0,
  maxCount: 10,
  hasNext: true,
};
const PromotionNotificationsReducer = (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS:
    case GET_PROMO_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case RESET_PROMO_NOTIFICATIONS_START: {
      return { ...DEFAULT_STATE };
    }
    default: {
      return { ...state };
    }
  }
};

export default PromotionNotificationsReducer;
