import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import ButtonGroup from './ButtonGroup';
import FormInputFeedback from '../feedback/FormInputFeedback';
import formPropTypes from '../../prop-types/formPropTypes';

const FormButtonGroup = ({
    id,
    form,
    field,
    title,
    buttonType,
    buttonSize,
    buttonSkin,
    labelOnly,
    role,
    children,
    flexWrap,
}) => {
    const error = getIn(form.errors, field.name);
    const isSubmitted = form.submitCount > 0;
    const renderChildren = Children.map(children, (child) => {
        return cloneElement(child, {
            buttonType,
            buttonSize,
            buttonSkin,
            labelOnly,
        });
    });

    return (
        <ButtonGroup
            buttonType={buttonType}
            buttonSize={buttonSize}
            buttonSkin={buttonSkin}
            role={role}
            aria-label={title}
            flexWrap={flexWrap}
        >
            {renderChildren}
            {error && isSubmitted && (
                <FormInputFeedback feedbackType="error" id={id} message={error} />
            )}
        </ButtonGroup>
    );
};

FormButtonGroup.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    title: PropTypes.string.isRequired,
    buttonType: formPropTypes.radioButtonGroupType,
    buttonSize: formPropTypes.radioButtonGroupSize,
    buttonSkin: formPropTypes.radioButtonGroupSkin,
    labelOnly: PropTypes.bool,
    role: PropTypes.oneOf(['radiogroup', 'group']).isRequired,
    children: PropTypes.node,
    flexWrap: PropTypes.string,
};

FormButtonGroup.defaultProps = {
    buttonType: 'default',
    buttonSize: 'default',
    buttonSkin: 'primary',
    labelOnly: false,
    children: null,
    flexWrap: 'wrap',
};

export default FormButtonGroup;
