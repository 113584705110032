import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewEntityTypesTabs = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    justify-content: space-evenly;
    margin-bottom: ${rem(4)};
    margin-left: -${rem(16)};
    margin-right: -${rem(16)};
`;

export default FavePagePreviewEntityTypesTabs;
