import { rem } from 'polished';
import styled from 'styled-components';

const BusinessAddressProblemFindingAddressContainer = styled.div`
    align-items: center;
    display: flex;

    /** Offset margin of button Request support, default size */
    margin-bottom: -${({ theme }) => rem(theme.button.padding.default.v)};
    margin-top: ${rem(8)};
`;

export default BusinessAddressProblemFindingAddressContainer;
