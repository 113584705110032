import React, { useContext, useEffect } from 'react';
import { DefaultAlert, alertTypeData } from 'baseline-ui/alert';
import { GaIcon } from 'baseline-ui/icon';
import { H6 } from 'baseline-ui/typography';
import { ThemeContext } from 'styled-components';
import Container from './Container';
import FeedbackContentContainer from './FeedbackContentContainer';
import FeedbackContentIconContainer from './FeedbackContentIconContainer';
import FeedbackContext from '../FeedbackContext';

const FeedbackContainer = () => {
    const theme = useContext(ThemeContext);
    const iconColor = theme.base.colors.light;
    const { feedbackOnStage, setFeedbackOnStage } = useContext(FeedbackContext);

    const { label, type, show, icon, offsetTop, timeout } = feedbackOnStage;

    useEffect(() => {
        const timer = setTimeout(() => {
            if (show) {
                setFeedbackOnStage({ ...feedbackOnStage, show: false });
            }
        }, timeout || 1600);

        return () => clearTimeout(timer);
    }, [feedbackOnStage]);

    return (
        <Container offsetTop={offsetTop}>
            <DefaultAlert
                inProp={show}
                appear
                mountOnEnter
                unmountOnExit
                alertType={alertTypeData.includes(type) ? type : undefined}
                showShadow
            >
                <FeedbackContentContainer>
                    {icon && (
                        <FeedbackContentIconContainer>
                            <GaIcon icon={icon} size={18} color={iconColor} />
                        </FeedbackContentIconContainer>
                    )}
                    <H6 color="light" textAlign="center">
                        {label}
                    </H6>
                </FeedbackContentContainer>
            </DefaultAlert>
        </Container>
    );
};

export default FeedbackContainer;
