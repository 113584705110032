import React from 'react';
import PropTypes from 'prop-types';
import ThemeModeProvider from './context/ThemeModeProvider';
import BaselineThemeMode from './BaselineThemeMode';

const BaselineThemeProvider = ({ theme, children }) => {
    return (
        <ThemeModeProvider>
            <BaselineThemeMode theme={theme}>{children}</BaselineThemeMode>
        </ThemeModeProvider>
    );
};

BaselineThemeProvider.propTypes = {
    theme: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};

BaselineThemeProvider.defaultProps = {
    theme: undefined,
};

export default BaselineThemeProvider;
