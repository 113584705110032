import styled, { css } from 'styled-components';

const PhotoListItemSortableItem = styled.div`
    /**  isInOverlay, used to set the z-index above the overlay but keep it below the blurred header offset when in page */
    ${({ isInOverlay }) =>
        isInOverlay &&
        css`
            z-index: ${({ theme }) => theme.overlay.content.zIndex + 1};
        `}
`;

export default PhotoListItemSortableItem;
