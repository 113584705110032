import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const SmallDesktop = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const minWidth = get(theme, 'layout.media.md', layoutTheme.layout.media.md);
    const maxWidth = get(theme, 'layout.media.lg', layoutTheme.layout.media.lg);

    return <Responsive {...props} minWidth={minWidth} maxWidth={parseInt(maxWidth, 10) - 1} />;
};

// displayName for storybook
SmallDesktop.displayName = 'SmallDesktop';

const Hoc = SmallDesktop;
Hoc.displayName = SmallDesktop.displayName;
export default Hoc;
