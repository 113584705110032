import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const LandingIntroWrapper = styled.div`
    background-color: ${({ theme, bgWhite }) => bgWhite ? theme.site.welcome.bgEnd : theme.site.welcome.bg};
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    position: relative;
    width: 100%
    margin: 0 auto;
    justify-content: space-between;
    padding-bottom: ${rem(16)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    ${({ afterImage}) =>
    afterImage &&
    css`
            &::after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 0;
                width: ${rem(224)};
                height: 100%;
                background-image: url(${afterImage});
                ${media.md`
                    background-image: url(${afterImage});
                `}
            }
        `}
        
    ${({ beforeImage }) =>
    beforeImage &&
    css`
            &::before {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 0;
                width:${({width}) =>
                    width
                        ? `${rem(width/2)}`
                        : '100px'
                };
                height: ${({height}) =>
                    height
                        ? `${rem(height)}`
                        : '100px'
                };
               background-image: url(${beforeImage});
                ${media.md`
                    background-image: url(${beforeImage});
                    width:${({width}) =>
                        width
                            ? `${rem(width)}`
                            : '100px'
                    };
                `}
            }
        `}
    ${media.sm`
        align-items: center;
    `};
    ${media.lg`
        flex-direction: row;
        border-radius: unset;
        margin: unset;
        padding-bottom: ${rem(56)};
        padding-left: ${rem(40)};
        padding-right: ${rem(40)};
        padding-top: ${rem(56)};
    `};
`;

export default LandingIntroWrapper;