const baseTheme = {
    base: {
        colors: {
            primary: '#0069d9',
            secondary: '#002B59',
            muted: '#666666',
            info: '#138496',
            success: '#35b944',
            warning: '#ff5722',
            danger: '#c82333',
            light: '#ffffff',
            dark: '#0c0c0c',
        },
        grays: {
            gray: '#808080',
            grayLight: '#a6a6a6',
            grayLighter: '#cccccc',
            grayLightest: '#e5e5e5',
            grayDark: '#595959',
            grayDarker: '#333333',
            grayDarkest: '#262626',
        },
        icons: {
            info: 'info',
            success: 'check-circle',
            warning: 'alert-circle',
            danger: 'x-circle',
        },
        body: {
            background: 'transparent',
        },
        borderRadius: {
            default: 4,
            md: 8,
        },
        boxShadow: {
            elevationSmall: '0 2px 4px rgba(0,0,0,0.125)',
            elevationDefault: '0 4px 8px rgba(0,0,0,0.125)',
            elevationMedium: '0 8px 16px rgba(0,0,0,0.125)',
            elevationLarge: '0 16px 32px rgba(0,0,0,0.125)',
        },
        scrollbar: {
            onBody: false,
            size: 8,
            color: '#e5e5e5',
            marginV: 0,
            borderRadius: {
                track: 0,
                thumb: 2,
            },
        },
    },
};

export default baseTheme;
