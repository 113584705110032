import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ThemeModeContext from './ThemeModeContext';

const ThemeModeProvider = ({ children }) => {
    const [localStorageThemeMode, setLocalStorageThemeMode] = useState(
        typeof window !== 'undefined' ? localStorage.getItem('theme') || 'lightMode' : 'lightMode',
    );

    useEffect(() => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setLocalStorageThemeMode('darkMode');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            setLocalStorageThemeMode(e.matches ? 'darkMode' : 'lightMode');
        });
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', localStorageThemeMode);
    }, [localStorageThemeMode]);

    const toggleThemeMode = () => {
        setLocalStorageThemeMode((prevState) =>
            prevState === 'lightMode' ? 'darkMode' : 'lightMode',
        );
    };

    const value = useMemo(
        () => ({
            /** Disable darkMode as we don't have a dark theme to implement */
            themeMode: 'lightMode',
            // themeMode: localStorageThemeMode,
            toggleThemeMode,
        }),
        [localStorageThemeMode],
    );

    return <ThemeModeContext.Provider value={value}>{children}</ThemeModeContext.Provider>;
};

ThemeModeProvider.propTypes = {
    children: PropTypes.node,
};

ThemeModeProvider.defaultProps = {
    children: undefined,
};

export default ThemeModeProvider;
