import styled from 'styled-components';

const FormActionGroup = styled.div`
    align-items: baseline;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
`;

export default FormActionGroup;
