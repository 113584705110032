import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const FavePageMainMyBusinessContainer = styled.div`
    padding-top: ${({ theme }) => rem(theme.site.page.sectionNavigation.height + 32)};

    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
        padding-top: ${({ theme }) => rem(theme.site.myBusiness.container.spacingV)}; 
    `}
`;

export default FavePageMainMyBusinessContainer;
