import React, { useState } from "react";
import { CreateItem, EditItem } from "./api";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import ManagePhotos from "./ManagePhotos";
import CatSubCat from "./CatSubCat";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

function ProductItem({ itemProduct, business_id, catSubCats }) {
  const [itemP, setItemP] = useState(itemProduct);

  const [title, setTitle] = useState(itemProduct.name);
  const [price, setPrice] = useState(itemProduct.price);
  const [description, setDescription] = useState(itemProduct.description);
  const [itemMapping, setItemMapping] = useState(itemProduct.mapping);

  const [photos, setPhotos] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (itemProduct && itemProduct.photos) {
      const newPhotos = [...itemProduct.photos];
      newPhotos.map((photo) => {
        Object.assign(photo, {
          preview: photo.url,
          state: "original",
        });
      });

      setPhotos(newPhotos);
    }
  }, [itemProduct]);

  // delete itemProduct.photos;

  const useStyles = makeStyles({
    boxContainer: {
      marginLeft: 20,
      marginRight: 20,
      paddingRight: 20,
    },
  });
  const classes = useStyles();

  async function formSubmit(e) {
    setSubmitting(true);
    setSubmited(false);

    e.preventDefault();

    let formData = new FormData();
    formData.append("parent_id", business_id);
    if (itemP?._id != "NEW") {
      formData.append("item_id", itemP?._id);
    }

    let photosOrder = [];

    for (let i = 0; i < photos.length; i++) {
      photos[i].order = i;

      let photoOrderInfo = {
        name: photos[i].name,
        order: photos[i].order,
      };
      photosOrder.push(photoOrderInfo);
    }

    let originalPhotos = photos.filter((photo) => {
      if (photo.state === "original") return true;
      else return false;
    });

    if (originalPhotos)
      formData.append("photos", JSON.stringify(originalPhotos));

    photos.map((photo) => {
      if (photo.state != "original" && photo.state != "deleted")
        formData.append(photo.name, photo);
    });

    if (photosOrder.length > 0)
      formData.append("photosOrder", JSON.stringify(photosOrder));

    formData.append("name", title);
    formData.append("price", price);
    formData.append("description", description);

    formData.append("categories", JSON.stringify(itemMapping));

    if (itemP?._id == "NEW") {
      let itemData = await CreateItem(formData);

      // If not in error:
      if (itemData.errorCode == 0) itemP._id = itemData.payload.item._id;

      console.log(itemData);
    } else {
      await EditItem(formData);
    }

    setSubmitting(false);
    setSubmited(true);

    setTimeout(() => {
      setSubmited(false);
    }, 3000);
  }

  return (
    <Box boxShadow={3} container style={{ padding: 12 }}>
      <Grid container justify="space-around" spacing={2}>
        <Grid item xs={12} sm={12} container alignItems="center">
          <LoadingButton
            loading={submitting}
            loadingPosition="start"
            sx={{ backgroundColor: "#e7744b" }}
            variant="contained"
            disabled={submitting}
            size="small"
            type="submit"
            onClick={formSubmit}
          >
            Save
          </LoadingButton>
          {submited == true ? (
            <Typography
              variant="subtitle2"
              display="inline"
              align="center"
              color="gray"
            >
              &nbsp;&nbsp;&nbsp;Changes Saved
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            key={`${itemProduct}.title`}
            id={`${itemProduct}.title`}
            required
            label="Product Title"
            variant="outlined"
            defaultValue={title}
            onBlur={(e) => {
              setTitle(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            key={`${itemProduct}.price`}
            id={`${itemProduct}.price`}
            label="Price"
            variant="outlined"
            defaultValue={price}
            onBlur={(e) => {
              setPrice(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            key={`${itemProduct}.description`}
            id={`${itemProduct}.description`}
            required
            label="Description"
            defaultValue={description}
            onBlur={(e) => {
              setDescription(e.target.value);
            }}
            variant="outlined"
            fullWidth
            multiline
            rows={10}
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} container>
          <b>Photos:</b>
        </Grid>
        <ManagePhotos photos={photos} setPhotos={setPhotos}></ManagePhotos>

        <CatSubCat
          mainSubCatMap={catSubCats}
          fieldMap={itemMapping}
          setFieldMap={setItemMapping}
        ></CatSubCat>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={2} container alignItems="center">
          <LoadingButton
            loading={submitting}
            loadingPosition="start"
            sx={{ backgroundColor: "#e7744b" }}
            variant="contained"
            disabled={submitting}
            size="small"
            type="submit"
            onClick={formSubmit}
          >
            Save
          </LoadingButton>
          {submited == true ? (
            <Typography
              variant="subtitle2"
              display="inline"
              align="center"
              color="gray"
            >
              &nbsp;&nbsp;&nbsp;Changes Saved
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sm={10}></Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </Box>
  );
}

export default ProductItem;
