import React from 'react';
import PropTypes from 'prop-types';
import LabelInline from './LabelInline';
import formPropTypes from '../prop-types/formPropTypes';

const FormLabelInline = ({ skin, children }) => {
    return <LabelInline skin={skin}>{children}</LabelInline>;
};

FormLabelInline.propTypes = {
    skin: formPropTypes.formInputSkin,
    children: PropTypes.node,
};

FormLabelInline.defaultProps = {
    skin: 'dark',
    children: null,
};

export default FormLabelInline;
