import { get } from 'lodash';
import { rem } from 'polished';
import styled from 'styled-components';
import formTheme from '../styles/formTheme';

const FieldGroupedContainer = styled.div`
    margin: 0
        ${({ theme }) =>
            rem(get(theme, 'form.fieldsGrouped.marginH', formTheme.form.fieldsGrouped.marginH))};
    flex: 1;
`;

export default FieldGroupedContainer;
