import PropTypes from 'prop-types';
import detectionTypeData from '../data/detectionTypeData';
import placementTypeData from '../data/placementTypeData';
import roleTypeData from '../data/roleTypeData';

const detectionType = PropTypes.oneOf(detectionTypeData);
const placementType = PropTypes.oneOf(placementTypeData);
const roleType = PropTypes.oneOf(roleTypeData);

export default { detectionType, placementType, roleType };
