import { Alert, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../common/i18n";
import { PROCESS_RESET } from "../redux/actions/CommonActions";

const MuiAlertErrorComponent = ({ processName }) => {
  const dispatch = useDispatch();
  const processError = useSelector(
    ({ ErrorReducer }) => ErrorReducer[processName],
  );
  const errorMessage = i18n().ERRORS[processName]
    ? i18n().ERRORS[processName][[processError?.code?.errorCode]] ||
      i18n().ERRORS[processName].GENERIC_ERROR
    : i18n().ERRORS.GENERIC_ERROR;

  if (!processError) {
    return null;
  }

  return (
    <Alert
      sx={{ marginTop: "10px" }}
      severity="error"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            dispatch({
              type: `${processName}_${PROCESS_RESET}`,
            });
          }}
        >
          Dismiss
        </Button>
      }
    >
      {errorMessage}
    </Alert>
  );
};

export default MuiAlertErrorComponent;
