import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductManageSectionsSelectionContainer = styled.div`
    margin-bottom: ${rem(16)};
    margin-left: ${rem(32)};
    margin-right: ${rem(16)};
`;

export default ModalEditProductManageSectionsSelectionContainer;
