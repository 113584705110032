import React, { useEffect } from "react";
import CommonWrapper from "../CommonWrapper";
import EditProductForm from "../../components/EditProductForm";
import { useDispatch } from "react-redux";
import { SET_EMPTY_PRODUCT_START } from "../../redux/actions/EditProductActions";
import ProductForm from "./ProductForm";

const AddProduct = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_EMPTY_PRODUCT_START,
    });
  }, []);

  return (
    <CommonWrapper title="Add product">
      {/* <EditProductForm /> */}
      <ProductForm />
    </CommonWrapper>
  );
};

export default AddProduct;
