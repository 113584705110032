import styled, { css } from 'styled-components';
import { get } from 'lodash';
import alertTheme from '../../styles/alertTheme';

const Container = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    ${({ theme, isFullScreen }) =>
        isFullScreen &&
        css`
            background-color: ${get(
                theme,
                'alert.alertFeedback.backgroundColor',
                alertTheme.alert.alertFeedback.backgroundColor,
            )};
            position: fixed;
            z-index: 999;
        `}
`;

export default Container;
