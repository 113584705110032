import styled from 'styled-components';
import { get } from 'lodash';
import overlayTheme from '../../styles/overlayTheme';

const OverlayOverlay = styled.div`
    background-color: ${({ theme, $isOverlayTransparent }) =>
        $isOverlayTransparent
            ? 'transparent'
            : get(theme, 'overlay.overlay.color', overlayTheme.overlay.overlay.color)};
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ theme }) =>
        get(theme, 'overlay.overlay.zIndex', overlayTheme.overlay.overlay.zIndex)};
`;

export default OverlayOverlay;
