import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import RadioGroup from './radio/RadioGroup';
import FormInputFeedback from './feedback/FormInputFeedback';
import formPropTypes from '../prop-types/formPropTypes';

const FormRadioGroup = (props) => {
    const { id, form, field, skin, children } = props;
    const error = getIn(form.errors, field.name);
    const isSubmitted = form.submitCount > 0;

    return (
        <RadioGroup>
            {children}
            {error && isSubmitted && (
                <FormInputFeedback feedbackType="error" id={id} message={error} skin={skin} />
            )}
        </RadioGroup>
    );
};

FormRadioGroup.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    children: PropTypes.node,
    skin: formPropTypes.formInputSkin,
};

FormRadioGroup.defaultProps = {
    children: null,
    skin: 'dark',
};

export default FormRadioGroup;
