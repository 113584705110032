import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    allSectionActiveProductsBySectionNameSelector,
    allSectionsActiveProductsSelector,
    initialSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { H6 } from 'baseline-ui/typography';
import { HorizontalScroll } from 'baseline-ui/horizontal-scroll';
import { Button } from 'baseline-ui/button';
import {
    GET_SECTION_PRODUCTS_PROCESS,
    GET_SECTION_PRODUCTS_START,
} from 'redux/actions/SectionActions';
import { businessFormSelector, businessIdSelector } from 'redux/selectors/businessFormSelectors';
import { usePrevious } from 'baseline-ui/helpers';
import { isEmpty } from 'lodash';
import useProcessLoader from 'utils/useProcessLoader';
import useProcessComplete from 'utils/useProcessComplete';
import { ENTITY_TYPE_PEOPLE } from 'common/consts';
import FavePagePreviewEntityTypesContainer from './FavePagePreviewEntityTypesContainer';
import FavePagePreviewSectionButtonContainer from './FavePagePreviewSectionButtonContainer';
import FavePagePreviewEntityTypesHorizontalScrollContainer from './FavePagePreviewEntityTypesHorizontalScrollContainer';
import FavePagePreviewEntityTypesTabs from './FavePagePreviewEntityTypesTabs';
import FavePagePreviewEntityTypesTab from './FavePagePreviewEntityTypesTab';
import FavePagePreviewEntityTypesTabItem from './FavePagePreviewEntityTypesTabItem';
import FavePagePreviewEntityTypesProductsContainer from './FavePagePreviewEntityTypesProductsContainer';
import FavePagePreviewEntityTypesEmptyList from './FavePagePreviewEntityTypesEmptyList';
import FavePagePreviewEntityTypesProductItem from './FavePagePreviewEntityTypesProductItem';

const PRODUCT_CARD_HEIGHT = 150;

const FavePagePreviewEntityTypes = ({ addToSection }) => {
    const dispatch = useDispatch();

    const businessId = useSelector(businessIdSelector);
    const initialSectionName = useSelector(initialSectionNameSelector);
    const allSectionsActiveProducts = useSelector(allSectionsActiveProductsSelector);
    const { type } = useSelector(businessFormSelector);

    const [activeSectionName, setActiveSectionName] = useState(initialSectionName);

    const allSectionActiveProductsBySectionName = useSelector(
        allSectionActiveProductsBySectionNameSelector(activeSectionName),
    );

    const previousAllSectionActiveProducts = usePrevious(allSectionActiveProductsBySectionName);

    const isLoadingSection = useProcessLoader({ processNames: [GET_SECTION_PRODUCTS_PROCESS] });
    const isCompleteSection = useProcessComplete({ processNames: [GET_SECTION_PRODUCTS_PROCESS] });

    const renderContainerHeightByItemCount = useMemo(() => {
        if (
            !isEmpty(previousAllSectionActiveProducts) &&
            isEmpty(allSectionActiveProductsBySectionName)
        ) {
            return previousAllSectionActiveProducts.length * PRODUCT_CARD_HEIGHT;
        }

        if (!isEmpty(allSectionActiveProductsBySectionName)) {
            return allSectionActiveProductsBySectionName.length * PRODUCT_CARD_HEIGHT;
        }
        return PRODUCT_CARD_HEIGHT;
    }, [activeSectionName, allSectionActiveProductsBySectionName]);

    const renderProducts = useMemo(() => {
        if (isLoadingSection && !isCompleteSection) {
            return (
                <FavePagePreviewEntityTypesEmptyList
                    label="Loading"
                    containerHeight={renderContainerHeightByItemCount}
                />
            );
        }
        if (isEmpty(allSectionActiveProductsBySectionName)) {
            return (
                <FavePagePreviewEntityTypesEmptyList
                    label="No Items"
                    containerHeight={renderContainerHeightByItemCount}
                    addToSection={addToSection}
                />
            );
        }

        return allSectionActiveProductsBySectionName.map((allSectionActiveProduct) => {
            const { name, photos, description, totalFaves, _id: id } = allSectionActiveProduct;

            return (
                <FavePagePreviewEntityTypesProductItem
                    key={id}
                    name={name}
                    photos={photos}
                    description={description}
                    totalFaves={totalFaves}
                />
            );
        });
    }, [
        isLoadingSection,
        isCompleteSection,
        allSectionActiveProductsBySectionName,
        renderContainerHeightByItemCount,
    ]);

    useEffect(() => {
        dispatch({
            type: GET_SECTION_PRODUCTS_START,
            payload: {
                businessId,
                selectedSection: initialSectionName,
            },
        });
    }, []);

    /**
     * Do not show Items | People on entity type of PEOPLE
     */
    if (type === ENTITY_TYPE_PEOPLE) {
        return null;
    }

    return (
        <FavePagePreviewEntityTypesContainer>
            <FavePagePreviewEntityTypesTabs>
                <FavePagePreviewEntityTypesTab isActive>
                    <H6 color="muted">Items</H6>
                </FavePagePreviewEntityTypesTab>
                <FavePagePreviewEntityTypesTab>
                    <H6 color="muted">People</H6>
                </FavePagePreviewEntityTypesTab>
            </FavePagePreviewEntityTypesTabs>

            <FavePagePreviewEntityTypesTabItem>
                <HorizontalScroll showScrollbar={false}>
                    <FavePagePreviewEntityTypesHorizontalScrollContainer>
                        {Array.isArray(allSectionsActiveProducts) &&
                            allSectionsActiveProducts.map((allSectionsActiveProduct) => {
                                const { sectionName } = allSectionsActiveProduct;

                                return (
                                    <FavePagePreviewSectionButtonContainer key={sectionName}>
                                        <Button
                                            skin={
                                                activeSectionName === sectionName
                                                    ? 'primaryMuted'
                                                    : 'muted'
                                            }
                                            btnType={
                                                activeSectionName === sectionName
                                                    ? 'default'
                                                    : 'outlined'
                                            }
                                            size="sm"
                                            onClick={(e) => {
                                                e.currentTarget.blur();
                                                setActiveSectionName(sectionName);
                                                dispatch({
                                                    type: GET_SECTION_PRODUCTS_START,
                                                    payload: {
                                                        businessId,
                                                        selectedSection: sectionName,
                                                    },
                                                });
                                            }}
                                        >
                                            {sectionName}
                                        </Button>
                                    </FavePagePreviewSectionButtonContainer>
                                );
                            })}
                    </FavePagePreviewEntityTypesHorizontalScrollContainer>
                </HorizontalScroll>
                <FavePagePreviewEntityTypesProductsContainer
                    style={{ minHeight: renderContainerHeightByItemCount, height: '100%' }}
                >
                    {renderProducts}
                </FavePagePreviewEntityTypesProductsContainer>
            </FavePagePreviewEntityTypesTabItem>
        </FavePagePreviewEntityTypesContainer>
    );
};

FavePagePreviewEntityTypes.propTypes = {
    addToSection: PropTypes.func,
};

FavePagePreviewEntityTypes.defaultProps = {
    addToSection: () => {},
};

export default FavePagePreviewEntityTypes;
