import * as yup from 'yup';
import {
    EDIT_PRODUCT_DESCRIPTION_MAX_LENGTH,
    EDIT_PRODUCT_NAME_MAX_LENGTH,
    EDIT_PRODUCT_PRICE_MAX_LENGTH,
} from 'common/consts';

const editProductSchema = yup.object().shape({
    name: yup.string().min(1).max(EDIT_PRODUCT_NAME_MAX_LENGTH).required(),
    price: yup.string().max(EDIT_PRODUCT_PRICE_MAX_LENGTH),
    description: yup.string().max(EDIT_PRODUCT_DESCRIPTION_MAX_LENGTH),
});

export default editProductSchema;
