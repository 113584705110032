import React from 'react';
import { P, Small } from 'baseline-ui/typography';
import { isEmpty, reject } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import { Col, Row } from 'baseline-ui/layout';
import { BUSINESS_LINKS_MAX_COUNT } from 'common/consts';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { useMessageBox } from 'components/MessageBox';
import AddBusinessLinksListContainer from './AddBusinessLinksListContainer';
import AddBusinessLinksListItem from './AddBusinessLinksListItem';

const AddBusinessLinksList = () => {
    const dispatch = useDispatch();
    const { links } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer);
    const messageBox = useMessageBox({
        messageId: 'addBusiness.modal.links.message',
        show: {
            yes: true,
            no: true,
        },
        onResponse(answer, data) {
            if (answer === 'yes') {
                dispatch({
                    type: SET_BUSINESS_FORM_DATA_START,
                    payload: {
                        links: reject(links, data),
                        hasUnsavedChanges: true,
                    },
                });
            }
            return true;
        },
    });

    const onSortEnd = (oldIndex, newIndex) => {
        const sortedLinks = arrayMove(links, oldIndex, newIndex);
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: sortedLinks,
                hasUnsavedChanges: true,
            },
        });
    };

    if (isEmpty(links)) {
        return null;
    }

    const allowDrag = links.length > 1;

    return (
        <AddBusinessLinksListContainer>
            <Row justifyContent="space-between">
                <Col>
                    <P lead>
                        <FormattedMessage id="addBusiness.page.sections.links.list.title" />
                    </P>
                </Col>
                <Col>
                    <P lead color="muted">
                        <Small>
                            <FormattedMessage
                                id="addBusiness.page.sections.links.list.count.label"
                                values={{ count: links.length, maxCount: BUSINESS_LINKS_MAX_COUNT }}
                            />
                        </Small>
                    </P>
                </Col>
            </Row>

            <SortableList allowDrag={allowDrag} onSortEnd={onSortEnd}>
                {links.map((link, index) => {
                    const { url, caption } = link;
                    return (
                        <SortableItem key={`${url}-${index}`}>
                            <AddBusinessLinksListItem
                                isFirst={index === 0}
                                url={url}
                                caption={caption}
                                onClick={messageBox.handleShow({ url })}
                                allowDrag={allowDrag}
                            />
                        </SortableItem>
                    );
                })}
            </SortableList>
        </AddBusinessLinksListContainer>
    );
};

export default AddBusinessLinksList;
