import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewDescriptionPlaceholderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(16)};
    margin-top: ${rem(8)};
`;

export default FavePagePreviewDescriptionPlaceholderContainer;
