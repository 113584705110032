import { css } from 'styled-components';

export const hiddenInput = () => css`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;

export const clearfix = () => css`
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
`;

export const srOnly = () => css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;

export const textOverflow = () => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
