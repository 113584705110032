import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { rem } from 'polished';
import { Box } from '@rebass/grid';
import { ThemeContext } from 'styled-components';
import layoutTheme from '../../styles/layoutTheme';

const AsideWrapper = ({ order, children }) => {
    const theme = useContext(ThemeContext);
    const gutter = get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter);

    return (
        <Box
            px={gutter}
            width={[
                1,
                1,
                1,
                1,
                rem(get(theme, 'layout.aside.width.xl', layoutTheme.layout.aside.width.xl)),
            ]}
            flex="0 0 auto"
            order={order}
        >
            {children}
        </Box>
    );
};

AsideWrapper.propTypes = {
    order: PropTypes.number,
    children: PropTypes.node,
};

AsideWrapper.defaultProps = {
    order: 0,
    children: null,
};

export default AsideWrapper;
