import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import formAddBusinessLinksSchema from 'schema/formAddBusinessLinksSchema';
import AddBusinessLinksList from 'components/AddBusinessLinksList';
import { LINK_OTHER } from 'common/linksConstants';
import businessLinksUtils from 'utils/businessLinksUtils';
import { SET_BUSINESS_FORM_DATA_START } from '../../redux/actions/BusinessFormActions';
import AddBusinessLinksForm from './AddBusinessLinksForm';

const Links = () => {
    const dispatch = useDispatch();
    const { links } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer);

    const handleOnSubmit = (values) =>
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: [...links, businessLinksUtils.addProtocolFormikLinkValue(values)],
                hasUnsavedChanges: true,
            },
        });

    const initialValues = {
        type: LINK_OTHER,
        url: '',
        caption: '',
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.links.title" />
            </H1>
            <P>
                <FormattedMessage id="addBusiness.page.sections.links.subtitle" />
            </P>
            <Formik
                initialValues={initialValues}
                validationSchema={formAddBusinessLinksSchema(links)}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                    handleOnSubmit(values);
                    resetForm();
                }}
            >
                {() => <AddBusinessLinksForm />}
            </Formik>
            <AddBusinessLinksList />
        </>
    );
};

export default Links;
