export const SET_PRODUCT_TO_EDIT_PROCESS = 'SET_PRODUCT_TO_EDIT_PROCESS';
export const SET_PRODUCT_TO_EDIT_START = 'SET_PRODUCT_TO_EDIT_START';
export const SET_PRODUCT_TO_EDIT_SUCCESS = 'SET_PRODUCT_TO_EDIT_SUCCESS';

export const SET_PRODUCT_TO_EDIT_PROPERTY_PROCESS = 'SET_PRODUCT_TO_EDIT_PROPERTY_PROCESS';
export const SET_PRODUCT_TO_EDIT_PROPERTY_START = 'SET_PRODUCT_TO_EDIT_PROPERTY_START';

export const SET_PRODUCT_SECTIONS_PROCESS = 'SET_PRODUCT_SECTIONS_PROCESS';
export const SET_PRODUCT_SECTIONS_START = 'SET_PRODUCT_SECTIONS_START';
export const SET_PRODUCT_SECTIONS_SUCCESS = 'SET_PRODUCT_SECTIONS_SUCCESS';

export const UNSET_PRODUCT_SECTIONS_PROCESS = 'UNSET_PRODUCT_SECTIONS_PROCESS';
export const UNSET_PRODUCT_SECTIONS_START = 'UNSET_PRODUCT_SECTIONS_START';
export const UNSET_PRODUCT_SECTIONS_SUCCESS = 'UNSET_PRODUCT_SECTIONS_SUCCESS';

export const UPDATE_PRODUCT_SECTION_PROCESS = 'UPDATE_PRODUCT_SECTION_PROCESS';
export const UPDATE_PRODUCT_SECTION_START = 'UPDATE_PRODUCT_SECTION_START';
export const UPDATE_PRODUCT_SECTION_SUCCESS = 'UPDATE_PRODUCT_SECTION_SUCCESS';

export const ADD_PRODUCT_SUBCATEGORY_PROCESS = 'ADD_PRODUCT_SUBCATEGORY_PROCESS';
export const ADD_PRODUCT_SUBCATEGORY_START = 'ADD_PRODUCT_SUBCATEGORY_START';
export const ADD_PRODUCT_SUBCATEGORY_SUCCESS = 'ADD_PRODUCT_SUBCATEGORY_SUCCESS';

export const DELETE_PRODUCT_SUBCATEGORY_PROCESS = 'DELETE_PRODUCT_SUBCATEGORY_PROCESS';
export const DELETE_PRODUCT_SUBCATEGORY_START = 'DELETE_PRODUCT_SUBCATEGORY_START';
export const DELETE_PRODUCT_SUBCATEGORY_SUCCESS = 'DELETE_PRODUCT_SUBCATEGORY_SUCCESS';

export const SET_PRODUCT_CATEGORY_PROCESS = 'SET_PRODUCT_CATEGORY_PROCESS';
export const SET_PRODUCT_CATEGORY_START = 'SET_PRODUCT_CATEGORY_START';
export const SET_PRODUCT_CATEGORY_SUCCESS = 'SET_PRODUCT_CATEGORY_SUCCESS';

export const ADD_PRODUCT_CATEGORY_PROCESS = 'ADD_PRODUCT_CATEGORY_PROCESS';
export const ADD_PRODUCT_CATEGORY_START = 'ADD_PRODUCT_CATEGORY_START';
export const ADD_PRODUCT_CATEGORY_SUCCESS = 'ADD_PRODUCT_CATEGORY_SUCCESS';

export const SET_SELECTED_PRODUCT_CATEGORY_PROCESS = 'SET_SELECTED_PRODUCT_CATEGORY_PROCESS';
export const SET_SELECTED_PRODUCT_CATEGORY_START = 'SET_SELECTED_PRODUCT_CATEGORY_START';
export const SET_SELECTED_PRODUCT_CATEGORY_SUCCESS = 'SET_SELECTED_PRODUCT_CATEGORY_SUCCESS';

export const DELETE_SELECTED_PRODUCT_CATEGORY_PROCESS = 'DELETE_SELECTED_PRODUCT_CATEGORY_PROCESS';
export const DELETE_SELECTED_PRODUCT_CATEGORY_START = 'DELETE_SELECTED_PRODUCT_CATEGORY_START';
export const DELETE_SELECTED_PRODUCT_CATEGORY_SUCCESS = 'DELETE_SELECTED_PRODUCT_CATEGORY_SUCCESS';

export const DELETE_PRODUCT_CATEGORY_PROCESS = 'DELETE_PRODUCT_CATEGORY_PROCESS';
export const DELETE_PRODUCT_CATEGORY_START = 'DELETE_PRODUCT_CATEGORY_START';
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS';

export const POST_SAVE_PRODUCT_PROCESS = 'POST_SAVE_PRODUCT_PROCESS';
export const POST_SAVE_PRODUCT_START = 'POST_SAVE_PRODUCT_START';

export const SET_PRODUCT_PHOTO_PROCESS = 'SET_PRODUCT_PHOTO_PROCESS';
export const SET_PRODUCT_PHOTO_START = 'SET_PRODUCT_PHOTO_START';
export const SET_PRODUCT_PHOTO_SUCCESS = 'SET_PRODUCT_PHOTO_SUCCESS';

export const DELETE_PRODUCT_PHOTO_PROCESS = 'DELETE_PRODUCT_PHOTO_PROCESS';
export const DELETE_PRODUCT_PHOTO_START = 'DELETE_PRODUCT_PHOTO_START';
export const DELETE_PRODUCT_PHOTO_SUCCESS = 'DELETE_PRODUCT_PHOTO_SUCCESS';

export const DISCARD_PRODUCT_CHANGES_PROCESS = 'DISCARD_PRODUCT_CHANGES_PROCESS';
export const DISCARD_PRODUCT_CHANGES_START = 'DISCARD_PRODUCT_CHANGES_START';
export const DISCARD_PRODUCT_CHANGES_SUCCESS = 'DISCARD_PRODUCT_CHANGES_SUCCESS';

export const ADD_PRODUCT_PROCESS = 'ADD_PRODUCT_PROCESS';
export const ADD_PRODUCT_START = 'ADD_PRODUCT_START';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';

export const SET_EMPTY_PRODUCT_START = 'SET_EMPTY_PRODUCT_START';

export const UPDATE_SECTION_PRODUCTS_ORDER_PROCESS = 'UPDATE_SECTION_PRODUCTS_ORDER_PROCESS';
export const UPDATE_SECTION_PRODUCTS_ORDER_START = 'UPDATE_SECTION_PRODUCTS_ORDER_START';
export const UPDATE_SECTION_PRODUCTS_ORDER_SUCCESS = 'UPDATE_SECTION_PRODUCTS_ORDER_SUCCESS';

export const RESET_PRODUCT_TO_EDIT_START = 'RESET_PRODUCT_TO_EDIT_START';

export const SET_SHOW_ACTIVE_PRODUCTS_ONLY_PROCESS = 'SET_SHOW_ACTIVE_PRODUCTS_ONLY_PROCESS';
export const SET_SHOW_ACTIVE_PRODUCTS_ONLY_START = 'SET_SHOW_ACTIVE_PRODUCTS_ONLY_START';
export const SET_SHOW_ACTIVE_PRODUCTS_ONLY_SUCCESS = 'SET_SHOW_ACTIVE_PRODUCTS_ONLY_SUCCESS';

export const UPDATE_PRODUCTS_TO_EDIT_PROCESS = 'UPDATE_PRODUCTS_TO_EDIT_PROCESS';
export const UPDATE_PRODUCTS_TO_EDIT_START = 'UPDATE_PRODUCTS_TO_EDIT_START';
export const UPDATE_PRODUCTS_TO_EDIT_SUCCESS = 'UPDATE_PRODUCTS_TO_EDIT_SUCCESS';

export const SAVE_PRODUCTS_BULK_PROCESS = 'SAVE_PRODUCTS_BULK_PROCESS';
export const SAVE_PRODUCTS_BULK_START = 'SAVE_PRODUCTS_BULK_START';
export const SAVE_PRODUCTS_BULK_SUCCESS = 'SAVE_PRODUCTS_BULK_SUCCESS';

export const DROP_PRODUCT_TO_SECTION_PROCESS = 'DROP_PRODUCT_TO_SECTION_PROCESS';
export const DROP_PRODUCT_TO_SECTION_START = 'DROP_PRODUCT_TO_SECTION_START';
export const DROP_PRODUCT_TO_SECTION_SUCCESS = 'DROP_PRODUCT_TO_SECTION_SUCCESS';

export const REMOVE_PRODUCT_FROM_SECTION_PROCESS = 'REMOVE_PRODUCT_FROM_SECTION_PROCESS';
export const REMOVE_PRODUCT_FROM_SECTION_START = 'REMOVE_PRODUCT_FROM_SECTION_START';
export const REMOVE_PRODUCT_FROM_SECTION_SUCCESS = 'REMOVE_PRODUCT_FROM_SECTION_SUCCESS';

export const DELETE_PRODUCT_FROM_SECTION_START = 'DELETE_PRODUCT_FROM_SECTION_START';
