import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesItemListTabItemDrop = styled.div`
    height: ${({ theme }) => rem(theme.site.products.container.height - theme.tabs.tab.minHeight)};
    overflow: hidden auto;
    padding: ${rem(16)} ${rem(8)};
`;

export default ProductsAndServicesItemListTabItemDrop;
