import React from 'react';
import PropTypes from 'prop-types';
import Col from '../grid/Col';

const MainWrapper = ({ children }) => {
    return <Col flex="1 1 auto">{children}</Col>;
};

MainWrapper.propTypes = {
    children: PropTypes.node,
};

MainWrapper.defaultProps = {
    children: null,
};

export default MainWrapper;
