import React, { useState, useEffect } from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { useFormik } from "formik";
import { useUserStore } from "./stores/LoginStore";
import {
  deserializeOption,
  serializeOption,
  getFlatSubcatOptionsWithHeaders,
  getSubcatOptionsWithHeaders,
  deserializeMapping,
} from "./helpers/features";
import {
  OwnBusiness,
  EditOwnBusiness,
  GetOwnedBusiness,
  GetBusinessMapping,
} from "./api";
import LocationFormPart from "./LocationFormPart";
import CatSubCat from "./CatSubCat";
import { Link } from "react-router-dom";
import Links from "./Links";
import { v4 as uuidv4 } from "uuid";

import ManagePhotos from "./ManagePhotos";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

function OwnABusiness({ isEditing }) {
  // used for editing
  const [dataLoading, setDataLoading] = useState(true);

  const [businessLoading, setBusinessLoading] = useState(!!isEditing);
  const [businessMapping, setBusinessMapping] = useState([]);
  const [photos, setPhotos] = useState([]);
  // const [businessValues, setBusinessValues] = useState({});
  // shared
  const [featureMapping, setFeatureMapping] = useState([]);
  const [featureLoading, setFeatureLoading] = useState(true);
  const [reqError, setReqError] = useState("");
  const [userStore] = useUserStore();
  const [submitted, setSubmitted] = useState(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const [businessName, setBusinessName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [website, setWebsite] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");

  const [links, setLinks] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState();
  const [touched, setTouched] = useState();

  // only for admin
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [privateCell, setPrivateCell] = useState("");
  const [followupStatus, setFollowupStatus] = useState("");

  const linksOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "twitter", label: "Twitter" },
    { value: "other", label: "Other" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    GetBusinessMapping().then((mapping) => {
      setFeatureMapping(mapping);
      setFeatureLoading(false);
    });
    if (isEditing) {
      let params = new URLSearchParams(window.location.search);
      let business_id = params.get("business_id");
      if (!business_id) {
        setReqError(
          "Business not found. Go back to the homepage and try again",
        );
      } else {
        GetOwnedBusiness(business_id).then((businessObj) => {
          let business = businessObj.business;
          if (business.photos) {
            business.photos.map((photo) => {
              Object.assign(photo, {
                preview: photo.url,
                state: "original",
              });
            });

            setPhotos(business.photos);
          }
          delete business.photos;
          setBusinessMapping(deserializeMapping(businessObj.mapping));

          setValidValue(business.name, setBusinessName);
          setValidValue(business.ownerName, setOwnerName);
          setValidValue(business.description, setDescription);
          setValidValue(business.phone, setPhone);
          setValidValue(business?.email, setEmail);
          setValidValue(business?.position, setPosition);
          setValidValue(business?.website, setWebsite);
          setValidValue(business?.formattedAddress, setBusinessAddress);

          if (userStore?.is_admin) {
            setValidValue(business?.adminOnly?.privateCell, setPrivateCell);
            setValidValue(
              business?.adminOnly?.followupStatus,
              setFollowupStatus,
            );
          }

          setValidValue(business?.links, setLinks);

          setDataLoading(false);

          setBusinessLoading(false);
          setApprovalStatus(business.approval_status);
        });
      }
    } else {
      setDataLoading(false);
    }
  }, [isEditing]);

  function setValidValue(v, setFunc) {
    if (v !== undefined && v !== null) setFunc(v);
  }
  function hasValue(v) {
    return v !== undefined && v !== null;
  }

  async function formSubmit(e) {
    setSubmitting(true);

    e.preventDefault();

    let formData = new FormData();
    if (isEditing) {
      let params = new URLSearchParams(window.location.search);
      formData.append("business_id", params.get("business_id") + "");
    }

    let photosOrder = [];

    for (let i = 0; i < photos.length; i++) {
      photos[i].order = i;

      let photoOrderInfo = {
        name: photos[i].name,
        order: photos[i].order,
      };
      photosOrder.push(photoOrderInfo);
    }

    let originalPhotos = photos.filter((photo) => {
      if (photo.state === "original") return true;
      else return false;
    });

    if (originalPhotos)
      formData.append("photos", JSON.stringify(originalPhotos));

    photos.map((photo) => {
      if (photo.state != "original" && photo.state != "deleted")
        formData.append(photo.name, photo);
    });

    if (photosOrder.length > 0)
      formData.append("photosOrder", JSON.stringify(photosOrder));

    formData.append("name", businessName);
    formData.append("ownerName", ownerName);
    formData.append("description", description);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("position", position);
    formData.append("website", website);
    formData.append("formattedAddress", businessAddress);

    if (userStore?.is_admin) {
      formData.append("followupStatus", followupStatus);
      formData.append("privateCell", privateCell);
      formData.append("approval_status", approvalStatus);
    }

    if (links !== undefined && links !== null)
      formData.append("links", JSON.stringify(links));

    formData.append("categories", JSON.stringify(businessMapping));

    const businessData = isEditing
      ? await EditOwnBusiness(formData)
      : await OwnBusiness(formData);
    if (!businessData?.errorMessage) {
      //resetForm();
      setSubmitting(false);
      setSubmitted(true);
      setReqError("");
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);

      // if we are creating, then we need to move to the edit screen
      if (!isEditing) {
        navigate(`/own-a-business/edit?business_id=${businessData.payload.id}`);
      }

      // If editing, stay here
    } else {
      setReqError(businessData?.errorMessage);
    }
  }

  const createLinks = () => {
    setLinks([...links, { type: "other", url: "", caption: "" }]);
  };
  const removeLinksRow = (idx) => {
    let newLinks = links.filter((v, index) => idx != index);
    setLinks(newLinks);
  };

  let hasErrors = false;
  // let hasErrors = Object.keys(errors).some((error) => !!error && touched[error]);
  // if (hasErrors && submitted) {
  //   setSubmitted(false);
  // }
  let params = new URLSearchParams(window.location.search);
  let business_id = params.get("business_id");

  const [showImageModal, setShowImageModal] = useState(false);

  const goBack = (e) => {
    // Go back to search
    navigate("/my-submissions");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: "1rem 1rem",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Roboto",
        }}
      >
        <h1>{isEditing ? "Modify " : "Create a new "}Business</h1>
      </div>

      {dataLoading ? (
        <></>
      ) : (
        <form onSubmit={formSubmit} autoComplete="off">
          <div style={{ display: "flex", padding: "2.5rem 3rem" }}>
            <Grid container spacing={2} justify="space-around">
              <Grid item xs={12}>
                <Button variant="contained" onClick={goBack}>
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} container></Grid>
              <Grid item xs={12} container>
                {isEditing ? (
                  <Button
                    variant="contained"
                    href={
                      "/own-a-business/products-search?business_id=" +
                      business_id
                    }
                  >
                    Products
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              {userStore?.is_admin ? (
                <>
                  <Grid item xs={12} container>
                    <b>Admin Only Fields:</b>
                  </Grid>
                  <Grid item xs={12} sm={2} container>
                    <FormControl fullWidth>
                      <InputLabel id="approvallabel">Approval</InputLabel>
                      <Select
                        name="approval"
                        value={approvalStatus}
                        label="Approval"
                        onChange={(e) => {
                          setApprovalStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={"PENDING"}>PENDING</MenuItem>
                        <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                        <MenuItem value={"DENIED"}>DENIED</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2} container>
                    <FormControl fullWidth>
                      <InputLabel id="followupStatuslabel">
                        Follow Up Status
                      </InputLabel>
                      <Select
                        name="followupStatus"
                        value={followupStatus}
                        label="Follow up Status"
                        onChange={(e) => {
                          setFollowupStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={"LEFT_MESSAGE"}>Left message</MenuItem>
                        <MenuItem value={"PITCHED"}>Pitched</MenuItem>
                        <MenuItem value={"POPULATED"}>Populated</MenuItem>
                        <MenuItem value={"ADVERTISE"}>Advertise</MenuItem>
                        <MenuItem value={"BETA"}>Beta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2} container>
                    <TextField
                      id="privateCell"
                      label="Private Cell"
                      defaultValue={privateCell}
                      onBlur={(e) => {
                        setPrivateCell(e.target.value);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={12} container>
                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "flex-start",
                    fontFamily: "Roboto",
                  }}
                >
                  <h2>Required Information</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  required
                  id="name"
                  label="Business Name"
                  variant="outlined"
                  defaultValue={businessName}
                  onBlur={(e) => {
                    setBusinessName(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  required
                  id="ownerName"
                  label="Contact Name"
                  defaultValue={ownerName}
                  onBlur={(e) => {
                    setOwnerName(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  required
                  id="phone"
                  label="Phone"
                  defaultValue={phone}
                  onBlur={(e) => {
                    setPhone(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  required
                  id="email"
                  label="Email"
                  defaultValue={email}
                  onBlur={(e) => {
                    setEmail(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {
                <Grid item xs={12} container>
                  <LocationFormPart
                    isSubmitting={submitting}
                    isEditing={isEditing}
                    businessAddress={businessAddress}
                    setBusinessAddress={setBusinessAddress}
                    errors={errors}
                  />
                </Grid>
              }
              <Grid item xs={12} sm={6} container alignItems="center">
                <LoadingButton
                  loading={submitting || featureLoading || businessLoading}
                  loadingPosition="start"
                  sx={{ backgroundColor: "#e7744b" }}
                  variant="contained"
                  type="submit"
                  disabled={
                    submitting || featureLoading || businessLoading || hasErrors
                  }
                  size="small"
                >
                  Submit and Finish Later
                </LoadingButton>
                {submitted == true ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    align="center"
                    color="gray"
                  >
                    &nbsp;&nbsp;&nbsp;Changes Saved
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} sm={12} container>
                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "flex-start",
                    fontFamily: "Roboto",
                  }}
                >
                  <h2>Additional Information</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  id="website"
                  label="Business Website"
                  defaultValue={website}
                  onBlur={(e) => {
                    setWebsite(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} container>
                <TextField
                  id="position"
                  label="Position"
                  defaultValue={position}
                  onBlur={(e) => {
                    setPosition(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} container>
                <TextField
                  id="description"
                  label="Description"
                  defaultValue={description}
                  onBlur={(e) => {
                    setDescription(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                />
              </Grid>
              <Grid item xs={12} container></Grid>
              <Grid item xs={12} container>
                <b>Links:</b>
              </Grid>

              {links != null &&
                links.map((value, index) => {
                  return (
                    <>
                      <Grid item xs={12} md={2} container>
                        <FormControl fullWidth>
                          <InputLabel id={`links[${index}].type.label`}>
                            Type
                          </InputLabel>
                          <Select
                            key={`links[${index}].type`}
                            name={`links[${index}].type`}
                            value={links[index].type}
                            label="Type"
                            onChange={(e) => {
                              links[index].type = e.target.value;
                              setLinks([...links]);
                            }}
                          >
                            <MenuItem value={"facebook"}>Facebook</MenuItem>
                            <MenuItem value={"instagram"}>Instagram</MenuItem>
                            <MenuItem value={"twitter"}>Twitter</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4} container>
                        <TextField
                          key={`links[${index}].url`}
                          id={`links[${index}].url`}
                          label="URL"
                          name={`links[${index}].url`}
                          onBlur={(e) => {
                            links[index].url = e.target.value;
                            setLinks(links);
                          }}
                          defaultValue={links[index].url}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4} container>
                        <TextField
                          id={`links[${index}].caption`}
                          key={`links[${index}].caption`}
                          label="Caption"
                          name={`links[${index}].caption`}
                          onBlur={(e) => {
                            links[index].caption = e.target.value;
                            setLinks(links);
                          }}
                          defaultValue={links[index].caption}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={2} container alignItems="center">
                        <Button
                          key={`remove${index}`}
                          variant="contained"
                          sx={{ height: 40 }}
                          onClick={(e) => {
                            removeLinksRow(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </>
                  );
                })}
              <Grid item xs={6} container>
                <Button variant="contained" onClick={createLinks}>
                  Add New Link+
                </Button>
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={12} container></Grid>
              <Grid item xs={12} container>
                <b>Photos:</b>
              </Grid>

              <ManagePhotos
                photos={photos}
                setPhotos={setPhotos}
              ></ManagePhotos>
              <Grid item xs={12} container></Grid>

              <CatSubCat
                mainSubCatMap={featureMapping}
                fieldMap={businessMapping}
                setFieldMap={(value) => {
                  setBusinessMapping(value);
                }}
              ></CatSubCat>

              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} container alignItems="center">
                <LoadingButton
                  loading={submitting || featureLoading || businessLoading}
                  loadingPosition="start"
                  sx={{ backgroundColor: "#e7744b" }}
                  variant="contained"
                  type="submit"
                  disabled={
                    submitting || featureLoading || businessLoading || hasErrors
                  }
                >
                  Submit
                </LoadingButton>
                {submitted == true ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    align="center"
                    color="gray"
                  >
                    &nbsp;&nbsp;&nbsp;Changes Saved
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </div>
  );
}

export default OwnABusiness;
