import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const LargeDesktop = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const minWidth = get(theme, 'layout.media.lg', layoutTheme.layout.media.lg);

    return <Responsive {...props} minWidth={minWidth} />;
};

// displayName for storybook
LargeDesktop.displayName = 'LargeDesktop';

const Hoc = LargeDesktop;
Hoc.displayName = LargeDesktop.displayName;
export default Hoc;
