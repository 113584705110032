import styled from 'styled-components';
import { get } from 'lodash';
import { baseTheme } from 'baseline-ui/base-theme';
import typographyTheme from '../styles/typographyTheme';

const Link = styled.a`
    color: ${({ theme, colorValue }) =>
        colorValue
            ? get(theme, `base.colors.${colorValue}`, baseTheme.base.colors[colorValue])
            : get(theme, 'typography.link.color', typographyTheme.typography.link.color)};
`;

export default Link;
