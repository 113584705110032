import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_SECTIONS_START,
  PUT_SECTIONS_START,
  SET_SELECTED_SECTION_START,
  UPDATE_SECTIONS_START,
} from "../redux/actions/SectionActions";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Box, Button, ButtonBase, Grid, Tabs } from "@mui/material";
import AddSectionModal from "./AddSectionModal";
import { useDrag, useDrop } from "react-dnd";
import SortableSectionListItem from "./SortableSectionListItem";
import update from "immutability-helper";

const SectionsList = () => {
  const { _id } = useSelector(
    ({ BusinessFormReducer }) => BusinessFormReducer || {},
  );

  const { sections, originalSections, selectedSection } = useSelector(
    ({ SectionReducer }) => SectionReducer,
  );
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sections) {
      const selectedSectionIndex = sections.findIndex(
        (section) => section.name === selectedSection,
      );
      setSectionIndex(selectedSectionIndex);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (_id) {
      dispatch({
        type: GET_SECTIONS_START,
        payload: { entityId: _id },
      });
    }
  }, [_id]);

  useEffect(() => {
    if (JSON.stringify(sections) != JSON.stringify(originalSections)) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [sections]);

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      if (sections) {
        const newSections = update(sections, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, sections[dragIndex]],
          ],
        });
        dispatch({
          type: UPDATE_SECTIONS_START,
          payload: newSections,
        });
      }
    },
    [sections],
  );

  return (
    <Box marginTop={2} marginBottom={2}>
      <AddSectionModal
        open={showAddSectionModal}
        onClose={() => setShowAddSectionModal(false)}
        sectionIndex={sectionIndex}
      />
      <Stack direction={"row"}>
        <Tabs value={sectionIndex}>
          {sections?.map((section, index) => (
            <SortableSectionListItem
              key={section._id}
              _id={section._id}
              name={section.name}
              variant={selectedSection === section.name ? "selected" : null}
              index={index}
              moveItem={moveItem}
              onClick={() => {
                setSectionIndex(index);
                setShowAddSectionModal(true);
              }}
            />
          ))}
        </Tabs>

        {/* <Button
          sx={{ marginLeft: 5 }}
          variant={"outlined"}
          color="primary"
          onClick={() => {
            setSectionIndex(undefined);
            setShowAddSectionModal(true);
          }}
        >
          Add section +
        </Button> */}
      </Stack>
      {showSaveButton && (
        <Button
          onClick={() =>
            dispatch({
              type: PUT_SECTIONS_START,
              payload: { entityId: _id, sections },
            })
          }
        >
          Save
        </Button>
      )}
    </Box>
  );
};

export default SectionsList;
