import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import LinkButton from './LinkButton';
import AnchorButton from './AnchorButton';

const RenderButton = ({
    onClick,
    onClickLinkCallback,
    isExternal,
    path,
    iconPostLabel,
    iconRotate,
    label,
    children,
    ...props
}) => {
    if (isExternal && path) {
        return (
            <AnchorButton path={path} onClick={() => onClickLinkCallback()} {...props}>
                {label || children}
            </AnchorButton>
        );
    }

    if (!isExternal && path) {
        return (
            <LinkButton to={path} onClick={() => onClickLinkCallback()} {...props} iconPostLabel={iconPostLabel}>
                {label || children}
            </LinkButton>
        );
    }

    return (
        <Button
            onClick={() => {
                onClick();
                onClickLinkCallback();
            }}
            {...props}
            iconPostLabel={iconPostLabel}
            iconRotate={iconRotate}
        >
            {label || children}
        </Button>
    );
};

RenderButton.propTypes = {
    onClick: PropTypes.func,
    isExternal: PropTypes.bool,
    path: PropTypes.string,
    label: PropTypes.string,
    onClickLinkCallback: PropTypes.func,
    children: PropTypes.node,
};

RenderButton.defaultProps = {
    onClick: null,
    isExternal: false,
    path: undefined,
    label: undefined,
    onClickLinkCallback: () => {},
    children: null,
};

export default RenderButton;
