const typographyColorData = [
    'light',
    'dark',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'danger',
    'muted',
];

export default typographyColorData;
