import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import genericSagaHandler from './CommonSaga';
import {
    ADD_EVENT_START,
    GET_EVENT_LIST_START,
    GET_EVENT_LIST_FAILURE,
    GET_EVENT_LIST_SUCCESS,
    ADD_EVENT_PROCESS,
    SET_EVENT_START,
    GET_EVENT_PROCESS,
    GET_EVENT_START,
    ADD_EVENT_FORM_SUCCESS,
    ADD_EVENT_FORM_ERROR,
    SET_EVENT_FILTER_PROCESS,
    SET_EVENT_FILTER_SUCCESS,
    SET_EVENT_FILTER_START,
} from '../actions/EventActions';
import dayjs from 'dayjs';
import {AddEvent, editEvent, getEntityEvents} from 'api';
import {businessIdSelector} from '../selectors/businessFormSelectors';
import {
    EVENT_HOST_LABEL,
    EVENT_PERFORMER_LABEL,
    EVENT_SPONSOR_LABEL
} from 'common/consts';
import addEventUtils from 'utils/addEventUtils';
function* payloadToSend() {
    const {
        entityId,
        eventName,
        startDate,
        endDate,
        startTime,
        endTime,
        description,
        locationType,
        locationBusiness_selected,
        locationName,
        address,
        city,
        state,
        zipCode,
        location,
        eventType,
        customEventType,
        headlinePerson,
        additionalPeople,
        eventLinks,
        selectedCategories,
        photos,
    } = yield select(({ EventReducer }) => EventReducer);
    return{
        entity_id: entityId,
        name: eventName,
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        startTime: dayjs(startTime).format('HH:mm'),
        endTime: endTime ? dayjs(endTime).format('HH:mm') : null,
        description,
        locationType,
        locationBusiness_selected,
        locationName,
        address,
        city,
        state,
        zipCode,
        location,
        eventType,
        customEventType,
        hosts: headlinePerson?.map(host => host._id),
        guests: additionalPeople?.map(guest => guest._id) ,
        eventLinks,
        tags: selectedCategories?.map(tag => tag.id),
        photos: JSON.stringify(
            photos
                ? photos
                    .filter((photo) => photo.state === 'original')
                    .map((photo, index) => {
                        const { state, ...rest } = photo;

                        return { ...rest, order: index };
                    })
                : [],
        ),
        photosOrder: JSON.stringify(
            photos
                ? photos.map((photo, index) => {
                    return { name: photo.name, order: index };
                })
                : [],
        ),
    }
}

function* addEventBusinessSaga() {
    yield genericSagaHandler(ADD_EVENT_PROCESS, function* () {
        try{
            const { photos } = yield select(
                ({ EventReducer }) => {
                    return EventReducer;
                },
            );
            const businessId = yield select(businessIdSelector);
            const eventPayload = yield payloadToSend();
            const formData = new FormData();

            formData.append('type', 'EVENT');
            formData.append('parent_id', businessId);

            if (eventPayload.locationType === 'CREATOR') {
                formData.append('businessId', businessId);
            }
            if (eventPayload.locationType === 'BUSINESS') {
                formData.append('businessId', eventPayload.locationBusiness_selected);
            }
            if (eventPayload.locationType === 'OTHER') {
                const location = {
                    businessName: eventPayload.locationName,
                    formattedAddress:
                        eventPayload.address +
                        ' , ' +
                        eventPayload.city +
                        ' , ' +
                        eventPayload.state +
                        ' , ' +
                        eventPayload.zipCode,
                };
                formData.append('location', JSON.stringify(location));
            }
            const eventTypes = [EVENT_PERFORMER_LABEL, EVENT_HOST_LABEL, EVENT_SPONSOR_LABEL];
            if (!eventTypes.includes(eventPayload.eventType)) {
                formData.append('label', eventPayload.customEventType);
            }
            else{
                formData.append('label', eventPayload.eventType);
            }
            for (const item in eventPayload) {
                if (eventPayload[item] !== undefined) {
                    if (Array.isArray(eventPayload[item])) {
                        formData.append(item, JSON.stringify(eventPayload[item]));
                    } else {
                        formData.append(item, eventPayload[item]);
                    }
                }
            }
            photos?.forEach((photo) => {
                if (photo.state === 'new' || photo.state === 'updated') {
                    formData.append(photo.name, photo);
                }
            });

            if (eventPayload.entity_id) {
                yield editEvent(formData);

            } else {
                yield AddEvent(formData);
            }
            yield put({
                type: SET_EVENT_START,
                payload: {
                    hasUnsavedChanges: false,
                }
            })
            yield put({
                type: ADD_EVENT_FORM_SUCCESS,
            });


        }catch (e) {
            yield put({
                type: ADD_EVENT_FORM_ERROR,
                payload: e
            });
        }

    });
}

function* getEventSaga({ payload }) {
    yield genericSagaHandler(GET_EVENT_PROCESS, function* () {
        const data = yield eventFormData(payload);

        yield put({
            type: SET_EVENT_START,
            payload: data,
        });
    });
}
function* handleGetEventList() {
    try {
        const businessFormReducer = yield select((state) => state.BusinessFormReducer);
        const maxCount = 10;
        const page = yield select((state) => state.EventReducer?.page) || 0;
        const events = yield select((state) => state.EventReducer?.eventList) || [];
        const businessId = businessFormReducer._id;
        const eventList = yield getEntityEvents(businessId, page);

        const transformedEvents = yield all(eventList.map(event =>
            call(addEventUtils.eventFormData, event)
        ));
        const hasMore = (eventList?.length ?? 0) >= maxCount;
        let updatedEvents;
        if(page === undefined || page <= 0) {
            updatedEvents = transformedEvents;
        }
        else{
            updatedEvents = [...events, ...transformedEvents]
        }
        yield put({
            type: GET_EVENT_LIST_SUCCESS,
            payload: {eventList: updatedEvents, hasMore: hasMore},
        });

    } catch (error) {
        yield put({
            type: GET_EVENT_LIST_FAILURE,
            payload: error,
        });
    }
}
function* setEventsFilterSaga({ payload = {} }) {
    yield genericSagaHandler(SET_EVENT_FILTER_PROCESS, function* () {
        const { page } = payload;
        yield put({
            type: SET_EVENT_FILTER_SUCCESS,
            payload: {
                page,
            },
        });
        yield call(handleGetEventList);
    });
}
export default function* EventSagas(){
    yield takeEvery(ADD_EVENT_START, addEventBusinessSaga);
    yield takeEvery(GET_EVENT_LIST_START, handleGetEventList);
    yield takeEvery(GET_EVENT_START, getEventSaga);
    yield takeEvery(SET_EVENT_FILTER_START, setEventsFilterSaga)
}