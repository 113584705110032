import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewEntityTypesProduct = styled.div`
    border: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    border-radius: ${rem(8)};
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(10)};
    margin-top: ${rem(10)};
    padding: ${rem(16)};
`;

export default FavePagePreviewEntityTypesProduct;
