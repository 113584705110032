import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewEntityTypesEmptyListContainer = styled.div`
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: ${({ containerHeight }) => rem(containerHeight)};
`;

export default FavePagePreviewEntityTypesEmptyListContainer;
