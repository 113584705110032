import PropTypes from 'prop-types';
import radioButtonGroupTypeData from '../data/radioButtonGroupTypeData';
import radioButtonGroupSizeData from '../data/radioButtonGroupSizeData';
import radioButtonGroupSkinData from '../data/radioButtonGroupSkinData';
import formInputSkinData from '../data/formInputSkinData';
import formSwitchInputSizeValueData from '../data/formSwitchInputSizeValueData';

const formikForm = PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    values: PropTypes.shape(),
    submitCount: PropTypes.number,
});

const formikField = PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
    ]),
    name: PropTypes.string,
});

const formikArrayHelpers = PropTypes.shape({
    push: PropTypes.func,
    handlePush: PropTypes.func,
    swap: PropTypes.func,
    handleSwap: PropTypes.func,
    move: PropTypes.func,
    handleMove: PropTypes.func,
    insert: PropTypes.func,
    handleInsert: PropTypes.func,
    replace: PropTypes.func,
    handleReplace: PropTypes.func,
    unshift: PropTypes.func,
    handleUnshift: PropTypes.func,
    handleRemove: PropTypes.func,
    handlePop: PropTypes.func,
    remove: PropTypes.func,
    pop: PropTypes.func,
});

const selectData = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
    }),
);

const radioButtonGroupType = PropTypes.oneOf(radioButtonGroupTypeData);

const radioButtonGroupSize = PropTypes.oneOf(radioButtonGroupSizeData);

const radioButtonGroupSkin = PropTypes.oneOf(radioButtonGroupSkinData);

const formInputSkin = PropTypes.oneOf(formInputSkinData);

const formSwitchInputSizeValue = PropTypes.oneOf(formSwitchInputSizeValueData);

export default {
    formikForm,
    formikField,
    formikArrayHelpers,
    selectData,
    radioButtonGroupType,
    radioButtonGroupSize,
    radioButtonGroupSkin,
    formInputSkin,
    formSwitchInputSizeValue,
};
