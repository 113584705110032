import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import PageHeader from 'components/pageLayout/PageHeader';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import NavigationFooter from 'components/NavigationFooter';
import { ModalContext } from 'baseline-ui/modal';
import { useNavigate, useParams } from 'react-router-dom';
import FavePageAsideMyBusiness from 'components/favePageAside/FavePageAsideMyBusiness';
import useBusinessSectionsNavigation from 'utils/useBusinessSectionsNavigation';
import FavePageMainMyBusiness from 'components/favePageMain/FavePageMainMyBusiness';
import useProcessLoader from 'utils/useProcessLoader';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessError from 'utils/useProcessError';
import useAddBusinessValid from 'utils/useAddBusinessValid';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import useBlockerOnUnsavedChangesBusiness from 'utils/useBlockerOnUnsavedChangesBusiness';
import {
    POST_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    RESET_BUSINESS_FORM_START,
    SET_BUSINESS_FORM_DATA_START,
} from '../redux/actions/BusinessFormActions';
import CommonWrapper from './CommonWrapper';
import ModalSaveBusinessConfirmSubmit from './BusinessForm/ModalSaveBusinessConfirmSubmit';

const AddFavePage = () => {
    const { entityType } = useParams();

    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    const { showModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const { hasUnsavedChanges, type } = useSelector(businessFormSelector);

    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
        withOverlay: true,
    });

    useProcessComplete({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: false });
    useProcessError({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: true });

    const isSubmitValid = useAddBusinessValid();

    useEffect(() => {
        dispatch({ type: RESET_BUSINESS_FORM_START });

        if (entityType) {
            dispatch({
                type: SET_BUSINESS_FORM_DATA_START,
                payload: { type: entityType },
            });
        }
    }, [dispatch, entityType]);

    const handleOnClickSectionsCallback = () => {
        dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                navigate,
                showModal,
            },
        });
    };

    const handleOnClickSubmitCallback = useCallback(() => {
        if (!isSubmitValid) {
            return showModal(ModalSaveBusinessConfirmSubmit);
        }

        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                navigate,
                showModal,
            },
        });
    }, [isSubmitValid]);

    const { handleBackNavigation, handleNextNavigation, activeView } =
        useBusinessSectionsNavigation();

    const { sectionId } = activeView;

    const mainFooterActions = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.page.headerNavigation.create.button.label',
            }),
            skin: 'secondary',
            onClick: () => handleOnClickSubmitCallback(),
            loading: isLoading,
            loadingMessage: intl.formatMessage({
                id: 'addBusiness.page.headerNavigation.creating.button.label',
            }),
        },
    ];

    useBlockerOnUnsavedChangesBusiness(hasUnsavedChanges, handleOnClickSubmitCallback);

    if (type !== entityType) {
        return null;
    }

    return (
        <CommonWrapper>
            <AdminLeftAsideTemplate
                bgStart={theme.site.admin.bgStart}
                bgEnd={theme.site.admin.bgEnd}
                banner={
                    <PageHeader
                        title={intl.formatMessage({
                            id: `addFavePage.${type}.page.title`,
                        })}
                    />
                }
                aside={
                    <FavePageAsideMyBusiness
                        currentSectionId={sectionId}
                        onClick={() =>
                            showModal(ModalSaveBusinessConfirmSubmit, {
                                onClickSubmitCallback: handleOnClickSectionsCallback,
                            })
                        }
                    />
                }
                main={
                    <FavePageMainMyBusiness
                        currentSectionId={sectionId}
                        handleBackNavigation={handleBackNavigation}
                        disableBackNavigation
                        handleNextNavigation={handleNextNavigation}
                        disableNextNavigation
                    />
                }
                mainFooter={<NavigationFooter actions={mainFooterActions} />}
            />
        </CommonWrapper>
    );
};

export default AddFavePage;
