import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const WelcomeAsideBackgroundImage = styled.div`
    background-color: ${({ theme }) => theme.site.welcome.bgStart};
    ${({ image }) => css`
        background-image: url(${image});
    `}

    background-position: center;
    background-size: cover;
    height: 50vh;
    left: -${rem(1)};
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;

    &:after {
        content: '';
        background-image: ${({ theme }) =>
            `linear-gradient(0deg, ${theme.site.welcome.bgStart} 0%, transparent 100%)`};
        bottom: 0;
        height: ${rem(160)};
        left: 0;
        position: absolute;
        width: 100vw;
    }

    ${media.md`
        min-height: 100vh;
        width: 50vw;
        
        &:after {
            width: 50vw;
        }
    `}
`;

export default WelcomeAsideBackgroundImage;
