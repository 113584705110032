import React, { useContext, useEffect } from 'react';
import { H6 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { FormattedMessage } from 'react-intl';
import { ModalContext } from 'baseline-ui/modal';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { POST_SUPPORT_PROCESS, POST_SUPPORT_START } from 'redux/actions/SupportActions';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessError from 'utils/useProcessError';
import useProcessLoader from 'utils/useProcessLoader';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import BusinessAddressProblemFindingAddressContainer from './BusinessAddressProblemFindingAddressContainer';
import BusinessAddressProblemModal from './BusinessAddressProblemModal';
import BusinessAddressProblemModalFormSubmitConfirmed from './BusinessAddressProblemModalFormSubmitConfirmed';

const BusinessAddressProblemFindingAddress = () => {
    const { showModal, hideModal } = useContext(ModalContext);
    const { values } = useFormikContext();
    const dispatch = useDispatch();
    const { name, formattedAddress, phone } = values;
    const { type } = useSelector(businessFormSelector);

    const isComplete = useProcessComplete({
        processNames: [POST_SUPPORT_PROCESS],
        withFeedback: false,
    });

    useProcessError({ processNames: [POST_SUPPORT_PROCESS], withFeedback: true });
    const isLoading = useProcessLoader({ processNames: [POST_SUPPORT_PROCESS], withOverlay: true });

    useEffect(() => {
        if (!isLoading && isComplete) {
            hideModal();
            showModal(BusinessAddressProblemModalFormSubmitConfirmed);
        }
    }, [isLoading, isComplete]);

    return (
        <BusinessAddressProblemFindingAddressContainer>
            <H6 color="muted">
                <FormattedMessage id={`${type}.businessAddressProblem.requestSupport.label`} />
            </H6>
            <Button
                onClick={() =>
                    showModal(BusinessAddressProblemModal, {
                        name,
                        formattedAddress,
                        phone,
                        onSubmit: ({ subject, body }) => {
                            dispatch({
                                type: POST_SUPPORT_START,
                                payload: {
                                    subject,
                                    body,
                                },
                            });
                        },
                    })
                }
                btnType="link"
                type="button"
                loading={isLoading}
            >
                <FormattedMessage id="addBusiness.businessAddressProblem.requestSupport.button.label" />
            </Button>
        </BusinessAddressProblemFindingAddressContainer>
    );
};

export default BusinessAddressProblemFindingAddress;
