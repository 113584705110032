import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const BusinessListItemStatusTagContainer = styled.span`
    display: block;
    margin-top: ${rem(8)};

    ${media.xs`
    margin-top: ${rem(4)};
    `}
`;

export default BusinessListItemStatusTagContainer;
