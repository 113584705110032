import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewAppHeaderSafeAreaBatteryIcon = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: ${rem(16)};
`;

export default FavePagePreviewAppHeaderSafeAreaBatteryIcon;
