import styled from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const StyledVideo = styled.iframe`
  width: 100%;
  height: ${rem(300)};
  allow: accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
  allowfullscreen;
   ${media.xs`
        width: 100%;
        height: ${rem(400)};
    `};
     ${media.sm`
        width: 80%;
    `};
    ${media.md`
        width: 100%;
    `};
    ${media.xl`
        width: 80%;
    `};
`;

export default StyledVideo;