import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

const Tab = forwardRef(
    ({ title, onClick, id, tabIndex, isOpen, itemId, minWidth, maxWidth, justified }, ref) => {
        const handleClick = () => {
            onClick(id, tabIndex);
        };

        return (
            <Item
                ref={ref}
                onClick={handleClick}
                aria-expanded={isOpen}
                aria-controls={itemId}
                justified={justified}
                role="tab"
                aria-selected={isOpen}
                selected={isOpen}
                id={`${itemId}-tab`}
                minWidth={minWidth}
                maxWidth={maxWidth}
            >
                {title}
            </Item>
        );
    },
);

Tab.propTypes = {
    title: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tabIndex: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    justified: PropTypes.bool,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
};

Tab.defaultProps = {
    isOpen: false,
    justified: false,
    minWidth: 120,
    maxWidth: 260,
};

export default Tab;
