import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, useNavigate} from 'react-router-dom';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import {Container, Row, Col} from 'baseline-ui/layout';
import { AssetRenderer } from 'baseline-ui/helpers';
import FooterContentWrapper from './FooterContentWrapper';
import LogoContainer from './LogoContainer';
import {H5, H6} from "baseline-ui/typography";
import {BUSINESS_PATH, HOME_PATH, INQUIRE_PATH} from "common/pathnames";
import Separator from "components/Separator";
import ButtonWrapper from "../../../../button/src/components/ButtonWrapper";
import { Button, LinkButton } from 'baseline-ui/button';
import ListWrapper from "./ListWrapper";
import BlockWrapper from "./BlockWrapper";
import CopyrightContainer from "./CopyrightContainer";
import PrivacyTermsWrapper from "./PrivacyTermsWrapper";
import ContentContainer from "./ContentContainer";

const DefaultFooter = ({
    logoPath,
    logoPathAlt,
    logoWidth,
    logoHeight,
    copyrightOwner,
}) => {
    const { themeMode } = useContext(ThemeModeContext);
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <FooterContentWrapper>
            <Container>
                <Row justifyContent="center">
                    {logoPath && (
                        <Col xxs={30} lg={7} xl={10}>
                            <LogoContainer>
                                <Link
                                    to="/"
                                    aria-label={intl.formatMessage({
                                        id: 'footer.logo.home.label',
                                    })}
                                >
                                    <AssetRenderer
                                        assetPath={
                                            themeMode === 'darkMode' ? logoPathAlt : logoPath
                                        }
                                        assetWidth={logoWidth}
                                        assetHeight={logoHeight}
                                    />
                                </Link>
                            </LogoContainer>
                        </Col>
                    )}
                    <Col xxs={30} lg={23} xl={20}>
                        <ContentContainer>
                            <ListWrapper>
                                <LinkButton to={BUSINESS_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.businesses.label"
                                    />
                                </LinkButton>

                                <LinkButton to={INQUIRE_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.advertise.label"
                                    />
                                </LinkButton>

                                <LinkButton to={INQUIRE_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.join.label"
                                    />
                                </LinkButton>

                                <LinkButton to={INQUIRE_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.affiliate.label"
                                    />
                                </LinkButton>

                                <LinkButton to={INQUIRE_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.invest.label"
                                    />
                                </LinkButton>

                                <LinkButton to={INQUIRE_PATH} btnType='link' skin='light' size='lg'>
                                    <FormattedMessage
                                        id="footer.contact.label"
                                    />
                                </LinkButton>
                            </ListWrapper>
                            <BlockWrapper alignItems="end">
                                <H5 color='light' textAlign='right'>
                                    <FormattedMessage
                                        id="footer.note.label"
                                    />
                                </H5>
                                <ButtonWrapper width={180} marginY={8} marginX={1} justifyContent="right" >
                                    <Button
                                        skin='primary'
                                        btnType='default'
                                        block
                                        onClick = {() => navigate(HOME_PATH)}
                                    >
                                        <FormattedMessage
                                            id="footer.button.join.label"
                                        />
                                    </Button>
                                </ButtonWrapper>
                            </BlockWrapper>
                        </ContentContainer>
                    </Col>
                </Row>
            </Container>
            <Separator borderWidth={1} marginX={16}/>
            <Container>
                <Row justifyContent="center">
                    <Col xxs={30}>
                        <CopyrightContainer>
                            <H6 color="muted">
                                <FormattedMessage
                                    id="footer.copyrights.label"
                                    values={{
                                        currentYear: new Date().getFullYear(),
                                        copyrightOwner,
                                    }}
                                />
                            </H6>
                            <PrivacyTermsWrapper>
                                <LinkButton to={"/"} btnType='link' skin='muted' size='sm'>
                                    <FormattedMessage id="footer.terms.label" />
                                </LinkButton>
                                <LinkButton to={"/"} btnType='link' skin='muted' size='sm'>
                                    <FormattedMessage id="footer.privacy.label" />
                                </LinkButton>
                            </PrivacyTermsWrapper>
                        </CopyrightContainer>
                    </Col>
                </Row>
            </Container>
        </FooterContentWrapper>
    );
};

DefaultFooter.propTypes = {
    logoPath: PropTypes.string,
    logoPathAlt: PropTypes.string,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    missionTitle: PropTypes.string,
    missionContent: PropTypes.node,
    copyrightOwner: PropTypes.string,
    copyrightCentered: PropTypes.bool,
    details: PropTypes.node,
    isFullWidth: PropTypes.bool,
    showDivider: PropTypes.bool,
};

DefaultFooter.defaultProps = {
    logoPath: undefined,
    logoPathAlt: undefined,
    logoWidth: undefined,
    logoHeight: undefined,
    missionTitle: undefined,
    missionContent: undefined,
    copyrightCentered: false,
    details: undefined,
    isFullWidth: false,
    showDivider: false,
};

export default DefaultFooter;
