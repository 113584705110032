import { smallStyles } from 'baseline-ui/typography';
import { rem } from 'polished';
import styled from 'styled-components';

const StatusTagContainer = styled.span`
    ${smallStyles};
    background-color: ${({ theme, status }) =>
        theme.site.myBusinessesList.item.status[status]?.backgroundColor ?? 
        theme.site.myBusinessesList.item.status['FALLBACK'].backgroundColor};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    color: ${({ theme, status }) => theme.site.myBusinessesList.item.status[status]?.color ??
        theme.site.myBusinessesList.item.status['FALLBACK'].color};
    display: inline-block;
    padding: ${rem(4)} ${rem(16)};
    text-transform: uppercase;
`;

export default StatusTagContainer;
