import { rem } from 'polished';
import styled, { css } from 'styled-components';

const FavePageAsideMyBusinessSectionsContainer = styled.div`
    margin-bottom: ${rem(32)};
    padding-top: ${rem(8)};
    overflow-y: auto;

    ${({ sectionsContainerHeight }) =>
        sectionsContainerHeight &&
        css`
            height: ${sectionsContainerHeight}px;
            padding-right: ${rem(8)};
        `}
`;

export default FavePageAsideMyBusinessSectionsContainer;
