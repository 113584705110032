import { rem } from 'polished';
import { get } from 'lodash';
import styled from 'styled-components';
import formTheme from '../styles/formTheme';

const FieldsGrouped = styled.div`
    display: flex;
    margin: 0 -${({ theme }) => rem(get(theme, 'form.fieldsGrouped.marginH', formTheme.form.fieldsGrouped.marginH))};
`;

export default FieldsGrouped;
