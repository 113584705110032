import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import { useMeasure } from 'baseline-ui/helpers';
import Container from './collapse/Container';
import Body from './collapse/Body';
import Content from './collapse/Content';

const Collapse = ({ children, isOpen, itemId }) => {
    const [bind, bounds] = useMeasure();

    const { height } = useSpring({
        from: { height: 0 },
        to: { height: isOpen ? bounds.height : 0 },
    });

    return (
        <Container
            style={{
                height: height.to((v) => v),
            }}
            id={itemId}
        >
            <Body {...bind}>
                <Content>{children}</Content>
            </Body>
        </Container>
    );
};

Collapse.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
};

Collapse.defaultProps = {
    isOpen: false,
};

export default Collapse;
