import styled, {css} from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';

const FavePageAsideContainer = styled.div`
    margin-bottom: ${rem(32)};
    margin-top: ${({ theme }) => rem(theme.site.page.main.spacingV)};
    overflow-y: auto;
     ${({ sectionsContainerHeight }) =>
        sectionsContainerHeight &&
        css`
            height: ${sectionsContainerHeight}px;
            padding-right: ${rem(8)};
        `}
    ${media.md`
        border-right: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
        padding-right: ${rem(32)};
        position: fixed;
        width: ${({ theme }) => rem(theme.layout.aside.width.md)};
        z-index: 1;
    `}
`;

export default FavePageAsideContainer;
