import { call, put, select, takeEvery } from 'redux-saga/effects';
import genericSagaHandler from './CommonSaga';
import {
    GET_BETA_SGNUPS_SUCCESS,
    GET_BETA_SIGNUPS_PROCESS,
    GET_BETA_SIGNUPS_START,
    PUT_BETA_SIGNUPS_PROCESS,
    PUT_BETA_SIGNUPS_START,
    RESET_NEXT_PAGE_START,
    SET_NEXT_PAGE_START,
    SET_PREV_PAGE_START,
} from 'redux/actions/BetaSignupActions';
import { GetBetaSignUps, PutBetaSignUps } from 'api';

function* getBetaSignupsSaga() {
    yield genericSagaHandler(GET_BETA_SIGNUPS_PROCESS, function* () {
        const { page } = yield select(({ BetaSignupReducer }) => BetaSignupReducer);
        const data = yield GetBetaSignUps({ page });
        yield put({
            type: GET_BETA_SGNUPS_SUCCESS,
            payload: data,
        });
    });
}

function* putBetaSignupSaga({ payload }) {
    yield genericSagaHandler(PUT_BETA_SIGNUPS_PROCESS, function* () {
        yield PutBetaSignUps(payload);
        yield call(getBetaSignupsSaga);
    });
}

export default function* BetaSignupSagas() {
    yield takeEvery(GET_BETA_SIGNUPS_START, getBetaSignupsSaga);
    yield takeEvery(PUT_BETA_SIGNUPS_START, putBetaSignupSaga);
    yield takeEvery(SET_NEXT_PAGE_START, getBetaSignupsSaga);
    yield takeEvery(SET_PREV_PAGE_START, getBetaSignupsSaga);
    yield takeEvery(RESET_NEXT_PAGE_START, getBetaSignupsSaga);
}
