import styled from 'styled-components';

export const MessageContent = styled.div`
    padding: 1rem;
`;


export const MessageButtons = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: flex-end;

    & > button {
        margin: 0 0.5rem;
    }
`;
