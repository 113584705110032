import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';

const WelcomeWrapper = styled.div`
    background-color: ${({ theme }) => theme.site.welcome.bg};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    padding-bottom: ${rem(40)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    position: relative;
    overflow: hidden;
    ${({ afterImage }) =>
    afterImage &&
    css`
            &::after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: 5%;
                right: -1%;
                width: 100%;
                height: 100%;
                background-image: url(${afterImage});
             
                ${media.md`
                    background-image: url(${afterImage});
                    top: -20%;
                    right: -1%;
                    width: 70%;
                `}
            }
        `}

    ${media.md`
        flex-direction: row;
        border-radius: unset;
        margin: unset;
        min-height: 90vh;
        align-items: center;
        padding-left: ${rem(32)};
        padding-right: 0;
        padding-top: ${rem(32)};
        padding-bottom: ${rem(32)};
    `};
    ${media.xl`
        flex-direction: row;
        border-radius: unset;
        margin: unset;
        height: 90vh;
        align-items: center;
        padding-left: ${rem(32)};
        padding-right: 0;
        padding-top: ${rem(40)};
        padding-bottom: ${rem(40)};
    `};
`;

export default WelcomeWrapper;
