import React from 'react';
import { H4 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import FavePagePreviewModalHeaderContainer from './FavePagePreviewModalHeaderContainer';
import FavePagePreviewModalCloseButton from './FavePagePreviewModalCloseButton';
import FavePagePreviewModalHeaderTitle from './FavePagePreviewModalHeaderTitle';

const FavePagePreviewModalHeader = () => {
    return (
        <FavePagePreviewModalHeaderContainer>
            <FavePagePreviewModalHeaderTitle>
                <H4 color="light">
                    <FormattedMessage id="addBusiness.modal.preview.title" />
                </H4>
            </FavePagePreviewModalHeaderTitle>
            <FavePagePreviewModalCloseButton />
        </FavePagePreviewModalHeaderContainer>
    );
};

export default FavePagePreviewModalHeader;
