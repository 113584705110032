import React, { Fragment, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_FEATURE_MAPPING_START,
  POST_BUSINESS_PROCESS,
  POST_BUSINESS_START,
} from "../../redux/actions/OwnABussinessActions";
import BusinessForm from "./BusinessForm";

const OwnABusinessRedux = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { featureMapping } = useSelector(
    ({ OwnABusinessReducer }) => OwnABusinessReducer,
  );

  useEffect(() => {
    dispatch({
      type: GET_FEATURE_MAPPING_START,
    });
  }, []);

  return (
    <BusinessForm
      processName={POST_BUSINESS_PROCESS}
      onSubmit={(values) => {
        dispatch({
          type: POST_BUSINESS_START,
          payload: { ...values, navigate },
        });
      }}
      initialValues={{
        featureMapping: JSON.stringify(featureMapping),
        name: "",
        ownerName: "",
        phone: "",
        email: "",
        formattedAddress: "",
        approval_status: "",
        website: "",
        description: "",
        addressFromGoogle: "",
      }}
    />
  );
};

export default OwnABusinessRedux;
