import {
  GET_SYSTEM_NOTIFICATIONS_SUCCESS,
  RESET_SYSTEM_NOTIFICATIONS_START,
  SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
} from "../actions/PushActions";

const DEFAULT_STATE = {
  systemNotifications: undefined,
  hasNext: true,
  page: 0,
};

const SystemNotificationsReducer = (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS:
    case GET_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case RESET_SYSTEM_NOTIFICATIONS_START: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export default SystemNotificationsReducer;
