import React from 'react';
import PropTypes from 'prop-types';
import Legend from './Legend';
import formPropTypes from '../prop-types/formPropTypes';

const FormLegend = ({ skin, className, children }) => {
    return (
        <Legend skin={skin} className={className}>
            {children}
        </Legend>
    );
};

FormLegend.propTypes = {
    skin: formPropTypes.formInputSkin,
    className: PropTypes.string,
    children: PropTypes.node,
};

FormLegend.defaultProps = {
    skin: 'dark',
    className: null,
    children: null,
};

export default FormLegend;
