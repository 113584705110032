import styled from 'styled-components';

const ProductsAndServicesItemListTabItemDragLabel = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    user-select: none;
`;

export default ProductsAndServicesItemListTabItemDragLabel;
