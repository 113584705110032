import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { allActiveProductsSelector } from 'redux/selectors/sectionSelectors';
import ProductsAndServicesFilteredListContainer from './ProductsAndServicesFilteredListContainer';
import ProductsAndServicesFilteredListForm from './ProductsAndServicesFilteredListForm';
import ProductsAndServicesListContainer from './ProductsAndServicesListContainer';
import ProductsAndServicesFilteredListResults from './ProductsAndServicesFilteredListResults';
import ProductsAndServicesFilteredListOuter from './ProductsAndServicesFilteredListOuter';
import ProductsAndServicesListEmpty from './ProductsAndServicesListEmpty';

const ProductsAndServicesFilteredList = () => {
    const allActiveProducts = useSelector(allActiveProductsSelector);
    const hasProducts = !isEmpty(allActiveProducts);

    return (
        <ProductsAndServicesFilteredListOuter showSearch={hasProducts}>
            {hasProducts && (
                <Formik initialValues={{ filterProducts: '' }}>
                    {() => {
                        return (
                            <>
                                <ProductsAndServicesFilteredListContainer>
                                    <ProductsAndServicesFilteredListForm />
                                </ProductsAndServicesFilteredListContainer>
                                <ProductsAndServicesListContainer>
                                    <ProductsAndServicesFilteredListResults />
                                </ProductsAndServicesListContainer>
                            </>
                        );
                    }}
                </Formik>
            )}
            {!hasProducts && <ProductsAndServicesListEmpty />}
        </ProductsAndServicesFilteredListOuter>
    );
};

export default ProductsAndServicesFilteredList;
