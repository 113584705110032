import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import {
    OuterWrapper,
    PageContentWrapper,
    BannerWrapper,
    SiteContentWrapper,
    Container,
    Row,
    MainWrapper,
    AsideWrapper,
    isSizes,
} from 'baseline-ui/layout';
import AdminMain from './AdminMain';
import AdminAside from './AdminAside';
import AdminMainFooterOuterWrapper from './AdminMainFooterOuterWrapper';
import AdminMainFooterWrapper from './AdminMainFooterWrapper';

const AdminLeftAsideTemplate = ({
    main,
    bgStart,
    bgEnd,
    banner,
    aside,
    footer,
    isMobile,
    isFullWidth,
    mainFooter,
}) => {
    return (
        <OuterWrapper>
            <PageContentWrapper bgStart={bgStart} bgEnd={bgEnd}>
                <BannerWrapper>{banner}</BannerWrapper>
                <SiteContentWrapper isFullWidth={isFullWidth}>
                    <Container>
                        <Row flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                            <AsideWrapper>
                                <AdminAside hasBanner={banner}>{aside}</AdminAside>
                            </AsideWrapper>
                            <MainWrapper>
                                <AdminMain
                                    hasBanner={banner}
                                    hasMainFooter={mainFooter}
                                    isMobile={isMobile}
                                >
                                    {main}
                                    {mainFooter && (
                                        <AdminMainFooterOuterWrapper>
                                            <SiteContentWrapper>
                                                <AdminMainFooterWrapper>
                                                    {mainFooter}
                                                </AdminMainFooterWrapper>
                                            </SiteContentWrapper>
                                        </AdminMainFooterOuterWrapper>
                                    )}
                                </AdminMain>
                            </MainWrapper>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            </PageContentWrapper>
            {footer}
        </OuterWrapper>
    );
};

AdminLeftAsideTemplate.propTypes = {
    bgStart: PropTypes.string.isRequired,
    bgEnd: PropTypes.string,
    main: PropTypes.element,
    aside: PropTypes.element,
    banner: PropTypes.element,
    footer: PropTypes.element,
    isMobile: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    mainFooter: PropTypes.element,
};

AdminLeftAsideTemplate.defaultProps = {
    bgEnd: null,
    main: null,
    aside: null,
    banner: null,
    footer: null,
    isMobile: false,
    isFullWidth: false,
    mainFooter: null,
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.mdView({ width }, { theme }),
    };
};

export default withSizes(mapSizesToProps)(AdminLeftAsideTemplate);
