import styled, { css, keyframes } from 'styled-components';
import { rem } from 'polished';
import pulseKeyframes from '../../../styles/pulseKeyframes';

const fadeInKeyframes = keyframes`
        0% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    `;

const Image = styled.img`
    ${({ fadeIn }) =>
        fadeIn &&
        css`
            animation: ${fadeInKeyframes} 0.3s ease-in-out;
        `}

    ${({ isLoading }) =>
        isLoading &&
        css`
            animation: ${pulseKeyframes} 1s infinite ease-in-out;
        `}

    ${({ imageFluid }) =>
        imageFluid &&
        css`
            height: auto;
            max-width: 100%;
        `}

    ${({ height }) =>
        height &&
        css`
            height: ${rem(height)};
        `}

    ${({ width }) =>
        width &&
        css`
            width: ${rem(width)};
        `}
`;

export default Image;
