import styled from 'styled-components';
import { get } from 'lodash';
import headerTheme from '../../styles/headerTheme';

const Label = styled.span`
    font-weight: ${({ theme }) =>
        get(
            theme,
            'header.navigation.label.fontWeight',
            headerTheme.header.navigation.label.fontWeight,
        )};
    text-transform: ${({ theme }) =>
        get(
            theme,
            'header.navigation.label.uppercase',
            headerTheme.header.navigation.label.uppercase,
        )
            ? 'uppercase'
            : 'none'};
`;

export default Label;
