import { media } from 'baseline-ui/layout';
import {
    PREVIEW_BORDER_TOTAL_WIDTH,
    PREVIEW_HEADER_BORDER_RADIUS,
    PREVIEW_HEADER_HEIGHT,
    PREVIEW_HEADER_HEIGHT_SM,
    PREVIEW_MAX_WIDTH,
} from 'common/previewConstants';
import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewAppHeaderContainer = styled.div`
    background-image: linear-gradient(90deg, #fef4f3 0%, #f4f0f7 50%, #f4f7fe 100%);
    border-top-left-radius: ${rem(PREVIEW_HEADER_BORDER_RADIUS)};
    border-top-right-radius: ${rem(PREVIEW_HEADER_BORDER_RADIUS)};
    height: ${PREVIEW_HEADER_HEIGHT};
    max-width: calc(100% - ${rem(PREVIEW_BORDER_TOTAL_WIDTH * 2)});
    position: fixed;
    width: calc(${PREVIEW_MAX_WIDTH}px - ${rem(PREVIEW_BORDER_TOTAL_WIDTH * 2)});
    z-index: 1;

    ${media.sm`
        height: ${PREVIEW_HEADER_HEIGHT_SM};
    `}
`;

export default FavePagePreviewAppHeaderContainer;
