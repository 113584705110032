import React from 'react';

const { H1, H2, H3, H4, H5, H6, P } = require('baseline-ui/typography');

const reactMarkdownUtils = {
    components: ({ color, textAlign }) => {
        return {
            h1: ({ node, ...props }) => <H1 color={color} textAlign={textAlign} {...props} />,
            h2: ({ node, ...props }) => <H2 color={color} textAlign={textAlign} {...props} />,
            h3: ({ node, ...props }) => <H3 color={color} textAlign={textAlign} {...props} />,
            h4: ({ node, ...props }) => <H4 color={color} textAlign={textAlign} {...props} />,
            h5: ({ node, ...props }) => <H5 color={color} textAlign={textAlign} {...props} />,
            h6: ({ node, ...props }) => <H6 color={color} textAlign={textAlign} {...props} />,
            p: ({ node, ...props }) => <P color={color} textAlign={textAlign} {...props} />,
        };
    },
};

export default reactMarkdownUtils;
