import React, {useContext, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalContext} from 'baseline-ui/modal';
import { UL } from 'baseline-ui/typography';
import { useIntl } from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import { ContentRenderer } from 'baseline-ui/helpers';
import addEventUtils from 'utils/addEventUtils';
import {eventFormRequiredFieldSelector} from 'redux/selectors/eventSelectors';
import {useLocation, useNavigate} from 'react-router-dom';
import {SET_EVENT_START} from 'redux/actions/EventActions';

const ModalSaveEvent = ({ onRequestClose, onClickSubmitCallback, ...props }) => {
    const intl = useIntl();
    const { hideModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const urlParams = `${pathname}?section=EVENTS`;
    const eventFormRequiredFields = useSelector(eventFormRequiredFieldSelector);
    const isSubmitValid = addEventUtils.addEventHasAllRequiredFields(eventFormRequiredFields);
    const formSubmitValidation = useSelector((state) => state.ProcessReducer?.ADD_EVENT_FORM);
    const { onLeave, onCompleteCallback } = props;
    useEffect(() => {
        if(formSubmitValidation === 'SUCCESS'){
            hideModal();
            onCompleteCallback();
        }

    }, [formSubmitValidation]);
    const submitButtonLabel = useMemo(() => {
        if (onLeave) {
            return {
                label: intl.formatMessage({
                    id: 'addBusiness.modal.unsavedChangesSection.footerAction.save.button.label',
                }),
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.modal.unsavedChangesSection.footerAction.saving.button.label',
                }),
            };
        }
        return {
            label: intl.formatMessage({
                id: 'saveBusinessConfirmed.modal.submit.footerAction.continue.button.label',
            }),
            loadingMessage: intl.formatMessage({
                id: 'saveBusinessConfirmed.modal.submit.footerAction.continue.button.label',
            }),
        };
    }, [onLeave]);
    const footerActionButtons = [
        {
            ...submitButtonLabel,
            skin: onLeave ? 'secondary' : 'primary',
            onClick: () => {
                if(onLeave){
                    onClickSubmitCallback();
                }
                else{
                    onRequestClose();
                    navigate(urlParams);
                }

            },
        },

    ];
    if(onLeave){
        footerActionButtons.unshift({
            label: intl.formatMessage({
                id: 'addBusiness.modal.unsavedChangesSection.footerAction.noSave.button.label',
            }),
            skin: 'muted',
            btnType: 'link',
            onClick: () => {
                onRequestClose();
                dispatch({
                    type: SET_EVENT_START,
                    payload: {
                        hasUnsavedChanges: false,
                    },
                });
                onCompleteCallback();
            },
        })
    }
    return (
        <Modal
            title={intl.formatMessage({
                id: onLeave
                    ? 'addBusiness.modal.unsavedChangesSection.title'
                    : isSubmitValid
                        ? 'saveBusiness.modal.submit.isValid.title'
                        : 'saveBusiness.modal.submit.isInvalid.title',
            })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={footerActionButtons}
            {...props}
        >

        <ContentRenderer
            source={intl.formatMessage({
                id: onLeave
                    ? 'EVENT.modal.submit.isValid.content.md'
                    : isSubmitValid
                        ? 'saveBusiness.modal.submit.isValid.content.md'
                        : 'EVENT.saveEntity.modal.submit.isInvalid.content.md'
            })}
        />

        {!onLeave && (
            <UL
                list={addEventUtils.addEventMissingRequiredFields(
                    eventFormRequiredFields,
                    intl,
                )}
            />
        )}
        </Modal>
    );
};

ModalSaveEvent.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onClickSubmitCallback: PropTypes.func,
};

ModalSaveEvent.defaultProps = {
    onClickSubmitCallback: null,
};

export default ModalSaveEvent;
