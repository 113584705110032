import {
  Box,
  Button,
  Input,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useComplete } from "../common/useComplete";
import {
  POST_SUPPORT_PROCESS,
  POST_SUPPORT_START,
} from "../redux/actions/SupportActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

const RequestSupportModal = ({
  caption,
  defaultValue = "",
  defaultType = "GENERAL",
  formParameters = {},
}) => {
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(defaultType);
  const [value, setValue] = useState(defaultValue);
  const isCompleted = useComplete(POST_SUPPORT_PROCESS);
  const dispatch = useDispatch();
  const { name, formattedAddress } = formParameters;

  useEffect(() => {
    if (isCompleted) {
      setShowModal(false);
    }
  }, [isCompleted]);
  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {caption}
      </Button>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You are opening a support request. We pre populated the message
            body, but feel free to modify it explaining your problem in more
            detail.
          </Typography>
          <Select
            label="Subject"
            variant="outlined"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value={"ADDRESS"}>
              ADDRESS - not able to find my address
            </MenuItem>
            <MenuItem value={"CATEGORIES"}>
              CATEGORIES - not able to find a suitable category
            </MenuItem>
            <MenuItem value={"PHOTOS"}>
              PHOTOS - problem uploading photos
            </MenuItem>
            <MenuItem value={"CREATING_A_NEW_BUSINESS"}>
              CREATING A NEW BUSINESS - problems creating a business FavePage
            </MenuItem>
            <MenuItem value={"GENERAL"}>
              GENERAL - a generic issue type for the top level button
            </MenuItem>
          </Select>

          <Input
            variant="outlined"
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            multiline
            rows={10}
            label="Message body"
          />
          <Button
            variant="contained"
            onClick={() => {
              let body = value;
              if (name) {
                body += `\n\n Business name: ${name}`;
              }
              if (formattedAddress) {
                body += `\n Address entered: ${formattedAddress}`;
              }
              dispatch({
                type: POST_SUPPORT_START,
                payload: {
                  subject: `[${type}] - ${name || "name unknown"}`,
                  body,
                },
              });
            }}
          >
            Send request
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RequestSupportModal;
