import { keyframes } from 'styled-components';

const dashAnimation = keyframes`
  0% {
    left: -5%;
    width: 25%;
  }
 
   20% {
    width: 45%;
  }
  
  30% {
    width: 30%;
  }

  45% {
    left: 80%;
    width: 25%;
  }
  
  50% {
    left: 80%;
    width: 25%;
  }
  
  55% {
    left: 80%;
    width: 25%;
  }
 
  70% {
    width: 45%;
  }
 
  100% {
    left: -5%;
    width: 25%;
  }
`;

export default {
    dashAnimation,
};
