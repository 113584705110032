import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewFaveButton = styled.div`
    align-items: center;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
    cursor: pointer;
    display: flex;
    height: ${rem(40)};
    justify-content: center;
    width: ${rem(40)};
`;

export default FavePagePreviewFaveButton;
