const siteTheme = {
    lightMode: {
        site: {
            page: {
                header: {
                    height: 72,
                },
                main: {
                    spacingV: 32,
                },
                sectionNavigation: {
                    height: 56,
                },
            },
            admin: {
                bgStart: '#ffffff',
                bgEnd: '#ffffff',
            },
            welcome: {
                bgStart: '#341847',
                bgEnd: '#FFFFFF',
                bg: '#482655',
            },
            aside: {
                icon: {
                    size: 88,
                },
                info: {
                    container: {
                        backgroundColor: '#f8f3ee',
                    },
                },
            },
            myBusinessesList: {
                item: {
                    height: 248,
                    image: {
                        height: 200,
                    },
                    status: {
                        DRAFT: { backgroundColor: '#E5EDFF', color: '#0855FF' },
                        PENDING: { backgroundColor: '#ffeade', color: '#d0500c' },
                        APPROVED: { backgroundColor: '#e3ffde', color: '#2AD00C' },
                        DENIED: { backgroundColor: '#e9a7ad', color: '#c82333' },
                        FALLBACK: { backgroundColor: '#E5EDFF', color: '#0855FF' },
                    },
                },
            },
            myBusiness: {
                container: {
                    spacingV: 40,
                },
            },
            navigationFooter: {
                backgroundColor: '#F7F1EA',
                height: 72,
                bottomOffset: 8,
                paddingOffset: 8,
            },
            products: {
                container: {
                    height: 560,
                },
                search: {
                    container: {
                        height: 85,
                    },
                },
            },
        },
    },
    darkMode: {
        site: {
            page: {
                header: {
                    height: 72,
                },
                main: {
                    spacingV: 32,
                },
                sectionNavigation: {
                    height: 56,
                },
            },
            admin: {
                bgStart: '#fbf9f6',
                bgEnd: '#fbf9f6',
            },
            welcome: {
                bgStart: '#341847',
                bgEnd: '#FFFFFF',
                bg: '#482655',
            },
            aside: {
                info: {
                    container: {
                        backgroundColor: '#f8f3ee',
                    },
                },
            },
            myBusinessesList: {
                item: {
                    height: 248,
                    image: {
                        height: 200,
                    },
                    status: {
                        DRAFT: { backgroundColor: '#E5EDFF', color: '#0855FF' },
                        PENDING: { backgroundColor: '#ffeade', color: '#d0500c' },
                        APPROVED: { backgroundColor: '#e3ffde', color: '#2AD00C' },
                        DENIED: { backgroundColor: '#e9a7ad', color: '#c82333' },
                        FALLBACK: { backgroundColor: '#ffeade', color: '#d0500c' },
                    },
                },
            },
            myBusiness: {
                container: {
                    spacingV: 40,
                },
            },
            navigationFooter: {
                backgroundColor: '#F7F1EA',
                height: 72,
                bottomOffset: 8,
                paddingOffset: 8,
            },
            products: {
                container: {
                    height: 560,
                },
                search: {
                    container: {
                        height: 85,
                    },
                },
            },
        },
    },
};

export default siteTheme;
