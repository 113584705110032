import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Row, Col } from 'baseline-ui/layout';
import FormInputFeedback from './feedback/FormInputFeedback';
import FormSwitch from './FormSwitch';
import FormSwitchLabel from './switch/FormSwitchLabel';
import formPropTypes from '../prop-types/formPropTypes';

class FormSwitchInput extends React.Component {
    handleChange = (event) => {
        const { field, onChange } = this.props;
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    render() {
        const { id, field, form, title, sizeValue, tagOnLabelId, tagOffLabelId, skin, disabled } =
            this.props;
        const error = getIn(form.errors, field.name);
        const value = getIn(form.values, field.name) || false;
        const isSubmitted = form.submitCount > 0;

        return (
            <>
                <Row>
                    {title && (
                        <Col xs={15}>
                            <FormSwitchLabel htmlFor={id} skin={skin}>
                                {title}
                            </FormSwitchLabel>
                        </Col>
                    )}
                    <Col xs={title ? 9 : 24}>
                        <FormSwitch
                            id={id}
                            name={field.name}
                            onChange={this.handleChange}
                            value={value}
                            sizeValue={sizeValue}
                            tagOn={tagOnLabelId}
                            tagOff={tagOffLabelId}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                {error && isSubmitted && (
                    <FormInputFeedback feedbackType="error" id={id} message={error} skin={skin} />
                )}
            </>
        );
    }
}

FormSwitchInput.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func,
    tagOnLabelId: PropTypes.string,
    tagOffLabelId: PropTypes.string,
    sizeValue: PropTypes.oneOf(['default', 'sm', 'xs']),
    skin: formPropTypes.formInputSkin,
    disabled: PropTypes.bool,
};

FormSwitchInput.defaultProps = {
    title: undefined,
    tagOnLabelId: 'switch.on.label',
    tagOffLabelId: 'switch.off.label',
    sizeValue: 'default',
    onChange: null,
    skin: 'dark',
    disabled: false,
};

export default FormSwitchInput;
