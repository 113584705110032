import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import OverlayContext from './OverlayContext';
import OverlayRoot from './OverlayRoot';

const initialState = {
    isOverlayOpen: false,
    component: null,
    overlayProps: {},
};

const actions = {
    OPEN_OVERLAY: 'OPEN_OVERLAY',
    REMOVE_OVERLAY: 'REMOVE_OVERLAY',
    HIDE_OVERLAY: 'HIDE_OVERLAY',
};

function reducer(state, action) {
    switch (action.type) {
        case actions.OPEN_OVERLAY: {
            const { component, overlayProps } = action.payload;
            return {
                ...state,
                isOverlayOpen: true,
                component,
                overlayProps,
            };
        }
        case actions.HIDE_OVERLAY: {
            return {
                ...state,
                isOverlayOpen: false,
            };
        }
        case actions.REMOVE_OVERLAY: {
            return {
                ...state,
                isOverlayOpen: false,
                component: null,
                overlayProps: {},
            };
        }
        default:
            return state;
    }
}

const OverlayProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            isOverlayOpen: state.isOverlayOpen,
            component: state.component,
            overlayProps: state.overlayProps,
            showOverlay: (component, overlayProps) => {
                dispatch({ type: actions.OPEN_OVERLAY, payload: { component, overlayProps } });
            },
            removeOverlay: () => {
                setTimeout(() => {
                    dispatch({ type: actions.REMOVE_OVERLAY });
                });
            },
            hideOverlay: () => {
                dispatch({ type: actions.HIDE_OVERLAY });
            },
        }),
        [state],
    );

    return (
        <OverlayContext.Provider value={value}>
            <OverlayRoot />
            {children}
        </OverlayContext.Provider>
    );
};

OverlayProvider.propTypes = {
    children: PropTypes.node,
};

OverlayProvider.defaultProps = {
    children: undefined,
};

export default OverlayProvider;
