import styled from 'styled-components';

const ContainerActions = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
`;

export default ContainerActions;
