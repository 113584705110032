import { h4Styles } from 'baseline-ui/typography';
import styled from 'styled-components';

const CreateFavePageOptionLabel = styled.span.attrs(() => ({
    textAlign: 'center',
}))`
    ${h4Styles}
`;

export default CreateFavePageOptionLabel;
