export const SET_BUSSINESS_FORM_DATA_PROCESS = "SET_BUSINESS_FORM_DATA_PROCESS";
export const SET_BUSINESS_FORM_DATA_START = "SET_BUSINESS_FORM_DATA_START";

export const SAVE_DATA_TO_LOCAL_STORAGE_PROCESS =
  "SAVE_DATA_TO_LOCAL_STORAGE_PROCESS";
export const SAVE_DATA_TO_LOCAL_STORAGE_START =
  "SAVE_DATA_TO_LOCAL_STORAGE_START";

export const GET_DATA_FROM_LOCAL_STORAGE_PROCESS =
  "GET_DATA_FROM_LOCAL_STORAGE_PROCESS";
export const GET_DATA_FROM_LOCAL_STORAGE_START =
  "GET_DATA_FROM_LOCAL_STORAGE_START";

export const POST_BUSINESS_FORM_PROCESS = "POST_BUSINESS_FORM_PROCESS";
export const POST_BUSINESS_FORM_START = "POST_BUSINESS_FORM_START";
export const POST_BUSINESS_FORM_ERROR = "POST_BUSINESS_FORM_ERROR";

export const GET_BUSINESS_FORM_PROCESS = "GET_BUSINESS_FORM_PROCESS";
export const GET_BUSINESS_FORM_START = "GET_BUSINESS_FORM_START";
export const GET_BUSINESS_FORM_SUCCESS = "GET_BUSINESS_FORM_SUCCESS";

export const RESET_BUSINESS_FORM_START = "RESET_BUSINESS_FORM_START";

// -------------------------------- THE ITEMS BELOW CAN POTENTIALLY BE REMOVED - REFACTOR

export const SAVE_REQUIRED_INFORMATION_PROCESS =
  "SAVE_REQUIRED_INFORMATION_PROCESS";
export const SAVE_REQUIRED_INFORMATION_START =
  "SAVE_REQUIRED_INFORMATION_START";

export const SAVE_CATEGORIES_PROCESS = "SAVE_CATEGORIES_PROCESS";
export const SAVE_CATEGORIES_START = "SAVE_CATEGORIES_START";

export const SAVE_ADDITIONAL_INFORMATION_PROCESS =
  "SAVE_ADDITIONAL_INFORMATION_PROCESS";
export const SAVE_ADDITIONA_INFORMATION_START =
  "SAVE_ADDITIONA_INFORMATION_START";

export const SAVE_LINKS_PROCESS = "SAVE_LINKS_PROCESS";
export const SAVE_LINKS_START = "SAVE_LINKS_START";

export const SAVE_PHOTOS_PROCESS = "SAVE_PHOTOS_PROCESS";
export const SAVE_PHOTOS_START = "SAVE_PHOTOS_START";
