export const exists = (prop) => {
  // NOTE: In most places this can be replaced with something like: prop ?? ''
  if (typeof prop !== "undefined" && prop != null && prop != "") return true;
  else return false;
};
// map from stored values to selectable option
export const serializeOption = (opt) => {
  return { id: opt.id, value: opt.id, label: opt.name };
};
// map from selectable option to stored values
export const deserializeOption = (opt) => {
  return { id: opt.id, name: opt.label };
};

export const serializeOptionT = (opt) => {
  return { id: opt.id, label: opt.name, typeName: opt.typeName, value: opt.id };
};

export function getSubcats(catName, cats) {
  for (let index = 0; index < cats.length; index++) {
    if (catName === cats[index].name) {
      return cats[index];
    }
  }
}
export function getSubcatOptionsWithHeaders(catName, cats) {
  const subCategoryOptions = [];
  let subcategoriesFound = getSubcats(catName, cats);
  if (subcategoriesFound) {
    subcategoriesFound.headers.forEach((header) => {
      let options = [];
      header.subcategories.forEach((subcat) => {
        options.push({ id: subcat.id, value: subcat.id, label: subcat.name });
      });
      subCategoryOptions.push({ label: header.name, options: options });
    });
  }
  return [!!subcategoriesFound, subCategoryOptions];
}
export function getFlatSubcatOptionsWithHeaders(catName, cats) {
  const subCategoryOptions = [];
  let subcategoriesFound = getSubcats(catName, cats);
  if (subcategoriesFound) {
    subcategoriesFound.headers.forEach((header) => {
      header.subcategories.forEach((subcat) => {
        subCategoryOptions.push({
          typeName: header.name,
          id: subcat.id,
          value: subcat.id,
          label: subcat.name,
        });
      });
    });
  }
  return [!!subcategoriesFound, subCategoryOptions];
}

export const deserializeMapping = (mapping) => {
  if (!mapping?.tags) {
    return [];
  }

  const categories = mapping.categories.map((cat) => {
    const subcats = [];
    if (cat?.headers) {
      cat.headers.forEach((header) => {
        if (header?.subcategories) {
          header.subcategories.forEach((sub) => {
            subcats.push({ id: sub.id, name: sub.name });
          });
        }
      });
    }
    return { id: cat.id, name: cat.name, subcategories: subcats };
  });
  return categories;
};
