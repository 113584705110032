import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'baseline-ui/typography';
import PageHeaderTitleContainer from './PageHeaderTitleContainer';
import PageHeaderTitleContainerOuter from './PageHeaderTitleContainerOuter';

const PageHeaderTitle = ({ title }) => {
    return (
        <PageHeaderTitleContainerOuter>
            <H2 color="secondary">
                <PageHeaderTitleContainer>{title}</PageHeaderTitleContainer>
            </H2>
        </PageHeaderTitleContainerOuter>
    );
};

PageHeaderTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageHeaderTitle;
