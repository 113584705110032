import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Flex } from '@rebass/grid';
import { ThemeContext } from 'styled-components';
import layoutTheme from '../../styles/layoutTheme';

const Row = ({ justifyContent, alignItems, flexWrap, children, isFullHeight, ...props }) => {
    const theme = useContext(ThemeContext);
    return (
        <Flex
            mx={-get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter)}
            flexWrap={flexWrap}
            flex="1 1 auto"
            justifyContent={justifyContent}
            alignItems={alignItems}
            height={isFullHeight ? '100%' : 'auto'}
            {...props}
        >
            {children}
        </Flex>
    );
};

Row.propTypes = {
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    flexWrap: PropTypes.string,
    children: PropTypes.node,
    isFullHeight: PropTypes.bool,
};

Row.defaultProps = {
    justifyContent: undefined,
    alignItems: undefined,
    flexWrap: 'wrap',
    children: undefined,
    isFullHeight: false,
};

export default Row;
