import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import businessAddressUtils from 'utils/businessAddressUtils';
import businessAddressRequestSupportSchema from 'schema/businessAddressRequestSupportSchema';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import BusinessAddressProblemModalForm from './BusinessAddressProblemModalForm';

const BusinessAddressProblemModal = ({ name, formattedAddress, phone, onSubmit, ...props }) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);

    const initialValues = {
        name,
        phone,
        formattedAddress,
        message: intl.formatMessage({
            id: `${type}.modal.businessAddressProblem.form.message.template.label`,
        }),
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={businessAddressRequestSupportSchema}
            onSubmit={(values) => {
                const {
                    name: formName,
                    phone: formPhone,
                    formattedAddress: formAddress,
                    message,
                } = values;

                const { subject, body } = businessAddressUtils.requestSupportMessage({
                    name: formName,
                    phone: formPhone,
                    address: formAddress,
                    message,
                });

                onSubmit({ subject, body });
            }}
        >
            {() => (
                <BusinessAddressProblemModalForm
                    name={name}
                    formattedAddress={formattedAddress}
                    phone={phone}
                    {...props}
                />
            )}
        </Formik>
    );
};

BusinessAddressProblemModal.propTypes = {
    name: PropTypes.string,
    phone: PropTypes.string,
    formattedAddress: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

BusinessAddressProblemModal.defaultProps = {
    name: undefined,
    phone: undefined,
    formattedAddress: undefined,
};

export default BusinessAddressProblemModal;
