import React, { Component, Children, cloneElement, createRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withTheme } from 'styled-components';
import { injectIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import { helpersUtils } from 'baseline-ui/helpers';
import Container from './Container';
import CloseButton from './CloseButton';
import CloseButtonIcon from './CloseButtonIcon';
import tooltipPropTypes from '../prop-types/tooltipPropTypes';
import tooltipTheme from '../styles/tooltipTheme';

class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.tooltipEl = createRef();
        this.tooltipCloseEl = createRef();

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleIsOpenCallback = this.handleIsOpenCallback.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.closeOnScroll = this.closeOnScroll.bind(this);

        this.renderplacementPos = (placementOptions, placementType) => {
            const pos = placementOptions.find(
                (placementOption) => placementOption.type === placementType,
            );
            return {
                left: pos.placementPos,
                top: pos.alignPos,
            };
        };

        // eslint-disable-next-line react/no-unused-class-component-methods
        this.handleClickOutside = () => {
            const { closeOnClickOutside } = this.props;
            if (closeOnClickOutside) {
                this.setState({
                    isOpen: false,
                });
            }
        };

        this.state = {
            isOpen: false,
            triggerSize: { height: 0, width: 0 },
            tooltipSize: {
                height: 0,
                width: 0,
            },
            currStyle: {
                left: 0,
                position: 'absolute',
                top: 0,
            },
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { isOpen } = this.state;
        const { closeOnScroll } = this.props;

        if (prevState.isOpen !== isOpen) {
            this.handleIsOpenCallback(isOpen);
            this.updateDimensions();
        }

        if (isOpen && closeOnScroll) {
            window.addEventListener('scroll', this.closeOnScroll, true);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.closeOnScroll, true);
    }

    closeOnScroll = () => {
        this.setState(() => ({
            isOpen: false,
        }));
    };

    resize = (bounds) => {
        const { tooltipSize, isOpen } = this.state;
        const { offset, placement } = this.props;

        const scrolledAmount = window.scrollY;

        const placementPosTop = bounds.top - tooltipSize.height - offset + scrolledAmount;
        const placementPosBottom = bounds.top + bounds.height + offset + scrolledAmount;
        const placementPosLeft = bounds.left - tooltipSize.width - offset;
        const placementPosRight = bounds.right + offset;
        const alignPosXStart = bounds.left;
        const alignPosXCenter = bounds.left + bounds.width / 2 - tooltipSize.width / 2;
        const alignPosXEnd = bounds.left + bounds.width - tooltipSize.width;
        const alignPosYStart = bounds.top - bounds.height / 4 + scrolledAmount;
        const alignPosYCenter =
            bounds.top + bounds.height / 2 - tooltipSize.height / 2 + scrolledAmount;
        const alignPosYEnd =
            bounds.top + bounds.height - tooltipSize.height + bounds.height / 4 + scrolledAmount;

        const placementOptions = [
            {
                type: 'top',
                alignPos: placementPosTop,
                placementPos: alignPosXCenter,
            },
            {
                type: 'bottom',
                alignPos: placementPosBottom,
                placementPos: alignPosXCenter,
            },
            {
                type: 'left',
                placementPos: placementPosLeft,
                alignPos: alignPosYCenter,
            },
            {
                type: 'right',
                placementPos: placementPosRight,
                alignPos: alignPosYCenter,
            },
            {
                type: 'top start',
                alignPos: placementPosTop,
                placementPos: alignPosXStart,
            },
            {
                type: 'top end',
                alignPos: placementPosTop,
                placementPos: alignPosXEnd,
            },
            {
                type: 'bottom start',
                alignPos: placementPosBottom,
                placementPos: alignPosXStart,
            },
            {
                type: 'bottom end',
                alignPos: placementPosBottom,
                placementPos: alignPosXEnd,
            },
            {
                type: 'left start',
                placementPos: placementPosLeft,
                alignPos: alignPosYStart,
            },
            {
                type: 'left end',
                placementPos: placementPosLeft,
                alignPos: alignPosYEnd,
            },
            {
                type: 'right start',
                placementPos: placementPosRight,
                alignPos: alignPosYStart,
            },
            {
                type: 'right end',
                placementPos: placementPosRight,
                alignPos: alignPosYEnd,
            },
        ];

        if (isOpen) {
            this.setState((prevState) => ({
                currStyle: {
                    ...prevState.currStyle,
                    left: this.renderplacementPos(placementOptions, placement).left,
                    top: this.renderplacementPos(placementOptions, placement).top,
                },
            }));
        }

        this.setState({ triggerSize: { height: bounds.height, width: bounds.width } });
    };

    openMenu = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    closeMenu = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    updateDimensions = () => {
        if (this.tooltipEl && this.tooltipEl.current) {
            this.setState({
                tooltipSize: {
                    height: this.tooltipEl.current.offsetHeight,
                    width: this.tooltipEl.current.offsetWidth,
                },
            });
        }
        if (this.tooltipCloseEl && this.tooltipCloseEl.current) {
            this.tooltipCloseEl.current.focus();
        }
    };

    handleIsOpenCallback = (v) => {
        const { isOpenCallback } = this.props;
        isOpenCallback(v);
    };

    render() {
        const { isOpen, tooltipSize, currStyle, triggerSize } = this.state;
        const {
            intl,
            children,
            detect,
            closeOnClick,
            closeOnClickOutside,
            showPointer,
            placement,
            closeIconColor,
            role,
            showCloseButton,
            title,
            minWidth,
            maxWidth,
            theme,
            centerPointer,
            closeOnScroll,
        } = this.props;

        const defaultCloseIconColor = get(
            theme,
            'tooltip.closeIcon.color',
            tooltipTheme.tooltip.closeIcon.color,
        );

        const inputChildren = Children.map(children, (child) => {
            if (child.type.displayName === 'Select') {
                return cloneElement(child, {
                    detect,
                    openMenu: this.openMenu,
                    closeMenu: this.closeMenu,
                    resize: this.resize,
                    tooltipSize,

                    selectId:
                        isOpen && role === 'tooltip'
                            ? `tooltip${helpersUtils.generateUniqueId()}`
                            : null,
                });
            }

            return (
                isOpen &&
                createPortal(
                    <Container
                        style={currStyle}
                        ref={this.tooltipEl}
                        onClick={closeOnClick ? this.closeMenu : null}
                        closeOnClickOutside={closeOnClickOutside}
                        showPointer={showPointer}
                        placement={placement}
                        role={role}
                        showCloseButton={showCloseButton}
                        id={`tooltip${helpersUtils.generateUniqueId()}`}
                        aria-labelledby={role === 'dialog' ? title : null}
                        minWidth={minWidth}
                        maxWidth={maxWidth}
                        triggerHeight={Math.floor(triggerSize.height)}
                        triggerWidth={Math.floor(triggerSize.width)}
                        centerPointer={centerPointer}
                        closeOnScroll={closeOnScroll}
                    >
                        <>
                            {showCloseButton && (
                                <CloseButton
                                    onClick={this.closeMenu}
                                    aria-label={intl.formatMessage({
                                        id: 'tooltip.close.label',
                                    })}
                                    ref={this.tooltipCloseEl}
                                >
                                    <CloseButtonIcon
                                        color={closeIconColor || defaultCloseIconColor}
                                    />
                                </CloseButton>
                            )}
                            {cloneElement(child, {
                                title,
                                showCloseButton,
                                hideTooltip: this.closeMenu,
                            })}
                        </>
                    </Container>,
                    document.body,
                )
            );
        });

        return inputChildren;
    }
}
Tooltip.propTypes = {
    intl: PropTypes.shape().isRequired,
    detect: tooltipPropTypes.detectionType,
    closeOnClick: PropTypes.bool,
    closeOnClickOutside: PropTypes.bool,
    showPointer: PropTypes.bool,
    placement: tooltipPropTypes.placementType,
    closeIconColor: PropTypes.string,
    offset: PropTypes.number,
    role: tooltipPropTypes.roleType,
    showCloseButton: PropTypes.bool,
    title: PropTypes.string,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    centerPointer: PropTypes.bool,
    children: PropTypes.node.isRequired,
    isOpenCallback: PropTypes.func,
    theme: PropTypes.shape({}),
    closeOnScroll: PropTypes.bool,
};

Tooltip.defaultProps = {
    detect: 'click',
    closeOnClick: false,
    closeOnClickOutside: true,
    showPointer: true,
    placement: 'top',
    closeIconColor: undefined,
    offset: 16,
    role: 'tooltip',
    showCloseButton: false,
    title: null,
    minWidth: 160,
    maxWidth: 280,
    centerPointer: false,
    isOpenCallback: () => {},
    theme: {},
    closeOnScroll: false,
};

export default withTheme(injectIntl(onClickOutside(Tooltip)));
