import styled, {css} from 'styled-components';
import {rem} from "polished";

const Container = styled.div`
    position: relative;
    padding: ${rem(40)} ${rem(32)};
    ${({ bgColor }) => css`
        background-color: ${bgColor};
    `}
`;

export default Container;