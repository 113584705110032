import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';

const Title = styled.div`
    font-family: ${({ theme }) =>
        get(
            theme,
            'typography.headings.fontFamily',
            typographyTheme.typography.headings.fontFamily,
        )};
    font-size: ${rem(16)};
    font-weight: ${({ theme }) =>
        get(theme, 'typography.strong.fontWeight', typographyTheme.typography.strong.fontWeight)};
    padding-bottom: ${rem(8)};

    ${({ showCloseButton }) =>
        showCloseButton &&
        css`
            padding-right: ${rem(12)};
        `}
`;

export default Title;
