import { baseDarkTheme } from 'baseline-ui/base-theme';
import headerTheme from './headerTheme';

const headerDarkTheme = {
    header: {
        ...headerTheme.header,
        backgroundColor: baseDarkTheme.base.colors.light,
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.5)',
            verticalShadow: '6px 0 8px rgba(0,0,0,0.5)',
        },
        menuModal: {
            backgroundColor: baseDarkTheme.base.colors.light,
        },
    },
};

export default headerDarkTheme;
