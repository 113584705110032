import React, {useContext, useEffect, useState} from 'react';
import {Field, Form, useFormikContext} from 'formik';
import {Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField,} from '@mui/material';
import {H3, P} from 'baseline-ui/typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    CONTEXT_EVENT,
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_PEOPLE,
    EVENT_HOST_LABEL,
    EVENT_OTHER_LABEL,
    EVENT_PERFORMER_LABEL,
    EVENT_SPONSOR_LABEL,
    INTERESTS_EVENTS
} from 'common/consts';
import EventEntityPicker from 'components/EntityPicker/EventEntityPicker';
import {EntityPickerDetails} from 'components/EntityPicker/EntityPickerDetails';
import {Button as BaselineButton} from 'baseline-ui/button';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {GetOwnedBusiness} from 'api';
import {ADD_EVENT_FORM_COMPLETE, ADD_EVENT_START, SET_EVENT_START} from 'redux/actions/EventActions';
import LinksForm from 'components/Links/LinksForm';
import businessLinksUtils from 'utils/businessLinksUtils';
import LinksList from 'components/Links/LinksList';
import CategoriesFields from '../BusinessForm/CategoriesFields';
import {
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    GET_INTERESTS_CATEGORY_START
} from 'redux/actions/CategoriesActions';
import categoriesAndFiltersUtils from 'utils/categoriesAndFiltersUtils';
import {getSubcategoryById, getSubcategoryFilters} from 'redux/selectors/businessFormSelectors';
import DataTable from '../BusinessForm/DataTable';
import {eventCategoriesSelector} from 'redux/selectors/eventSelectors';
import {ModalContext} from 'baseline-ui/modal';
import useAddEventValid from 'utils/useAddEventValid';
import ModalSaveEvent from './ModalSaveEvent';
import addEventUtils from 'utils/addEventUtils';
import ManagePhotos from 'components/ManagePhotos';
import {FeedbackContext} from 'baseline-ui/feedback';
import ButtonWrapper from './ButtonWrapper';
import {FormInput, FormTextarea} from 'baseline-ui/form';
import {isEqual} from 'lodash';
import useBlockerOnUnsavedChangesBusiness from 'utils/useBlockerOnUnsavedChangesBusiness';
import {DEFAULT_STATE} from "../../redux/reducers/EventReducer";
import CustomTimePicker from "../../components/CustomTimePicker";
import CustomDatePicker from "../../components/CustomDatePicker";

const EventForm = ({ initialValues }) => {
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const intl = useIntl();
    const { showModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const linkTypeDefaults = businessLinksUtils.linkTypeDefaults(intl);
    const { values, touched, dirty, errors, setFieldValue} = useFormikContext();
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [eventType, setEventType] = useState('');
    const [headlinePerformer, setHeadlinePerformer] = useState([]);
    const [additionalPeople, setAdditionalPeople] = useState([]);
    const [isExecuted, setIsExecuted] = useState(true);
    const state = useSelector((state) => state.EventReducer);
    const eventLinks = (!Array.isArray(state.eventLinks) && state.eventLinks) ? JSON.parse(state.eventLinks) : state.eventLinks;
    const selectedCategories = useSelector(eventCategoriesSelector);
    const subcategoryFilters = useSelector((state) =>
        getSubcategoryFilters(state, values.subcategory || values.category),
    );
    const photos = state?.photos;
    const subcategories = useSelector((state) => getSubcategoryById(state, values.category));
    const categories = useSelector((state) => state.CategoriesReducer.interestsCategory);
    const [tableData, setTableData] = useState();
    const isSaveSubmitValid = useAddEventValid();
    const isEdit = !!state.entityId;
    const formSubmitValidation = useSelector((state) => state.ProcessReducer?.ADD_EVENT_FORM);
    const hasUnsavedChanges = state?.hasUnsavedChanges || false;

    const addEvent = () => {
        if (!isSaveSubmitValid) {
            return showModal(ModalSaveEvent);
        }
        else{
            dispatch({
                type: ADD_EVENT_START,
            })

        }
    };
    useEffect(() => {
        dispatch({
            type: SET_EVENT_START,
            payload: values,
        });
    }, [values]);

    useEffect(() => {
        const hasChanged = !isEqual(initialValues, values);
        const defaultValues = isEqual(DEFAULT_STATE, values);
        if (dirty && hasChanged && !hasUnsavedChanges && touched && !defaultValues) {
            dispatch({
                type: SET_EVENT_START,
                payload: {
                    hasUnsavedChanges: true,
                },
            });

        }
    }, [touched]);

    useBlockerOnUnsavedChangesBusiness(hasUnsavedChanges, addEvent);

    useEffect(() => {
       setTableData(categoriesAndFiltersUtils.getOrganizedSelectedTableData(selectedCategories));
    }, [selectedCategories]);
    useEffect(() => {
        dispatch({
            type: GET_INTERESTS_CATEGORY_START,
            payload: { interest: INTERESTS_EVENTS }
        });
    }, [dispatch]);
    useEffect(() => {
        if (categories && categories[0]?._id) {
            let categoryId = categories[0]._id;
            setFieldValue('category', categoryId);
            setFieldValue('subcategory', '');
            setFieldValue('filter', {});
            dispatch({
                type: GET_BUSINESS_SUBCATEGORIES_START,
                payload:  {categoryId} ,
            })
        }
    }, [categories, setFieldValue]);
    useEffect(() => {
        if (isExecuted) {
            setEventType(values.eventType);
            const updateHeadlinePerformer = async () => {
                if (values.headlinePerson?.length > 0) {
                    const promises = JSON.parse(values.headlinePerson).map((element) =>
                        GetOwnedBusiness(element),
                    );
                    const results = await Promise.all(promises);
                    const businesses = results.map((result) => result.business);
                    setHeadlinePerformer(businesses);
                    setFieldValue('headlinePerson', businesses);
                }
            };

            const updateAdditionalPeople = async () => {
                if (values.additionalPeople?.length > 0) {
                    const promises = JSON.parse(values.additionalPeople).map((element) =>
                        GetOwnedBusiness(element),
                    );
                    const results = await Promise.all(promises);
                    const businesses = results.map((result) => result.business);
                    setAdditionalPeople(businesses);
                    setFieldValue('additionalPeople', businesses);
                }
            };
            if(values.locationType === 'BUSINESS'){
                const updateBusinessLocation = async () => {
                        const data = GetOwnedBusiness(values.locationBusiness_selected);
                        const results = await data;

                        const business = Array(results.business);
                        setSelectedEntities(business);
                };

                updateBusinessLocation();
            }


            updateHeadlinePerformer();
            updateAdditionalPeople();
            setIsExecuted(false);
        }
    }, []);

    const onSubmitData = (values) => {
        const onSubmitSelectedCategories =
            categoriesAndFiltersUtils.handleOnSubmitFormikAddCategoryAndFilters({
                values,
                categories,
                subcategories,
                subcategoryFilters,
                selectedCategories,
            });
        dispatch({
            type: SET_EVENT_START,
            payload: {
                ...values,
                selectedCategories: onSubmitSelectedCategories,
            },
        });
        setFieldValue('selectedCategories', onSubmitSelectedCategories);
    };

    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        setFieldValue('category', categoryId);
        setFieldValue('subcategory', '');
        setFieldValue('filter', []);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload:  {categoryId} ,
        })
    };

    const handleSubcategoryChange = (e) => {
        const subcategoryId = e.target.value;
        const categoryId = values.category;
        setFieldValue('subcategory', subcategoryId);
        setFieldValue('filter', {});
        if(subcategoryId !== ''){
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId, categoryId },
            });
        }
    };

    const handleLocationChange = (value) => {
        setFieldValue('locationType', value);
    };

    const handleeventChange = (value) => {
        setFieldValue('eventType', value);
        setEventType(value);
    };

    const handleAdditionalSelect = (entity) => {
        const isEntityExist = additionalPeople.some((additional) => additional._id === entity._id);

        if (!isEntityExist) {
            const updatedAdditionalPeople = [...additionalPeople, entity];
            setAdditionalPeople(updatedAdditionalPeople);
            setFieldValue('additionalPeople', updatedAdditionalPeople);
        }
    };
    const handleAdditionalPeopleRemove = (entityId) => {
        const updatedPeople = additionalPeople.filter((entity) => entity._id !== entityId);
        setAdditionalPeople(updatedPeople);
        setFieldValue('additionalPeople', updatedPeople);
    };
    const handleBusinessLocationRemove = (entityId) => {
        const updatedLocation = selectedEntities.filter((entity) => entity._id !== entityId);
        setSelectedEntities(updatedLocation);
        setFieldValue('selectedEntities', updatedLocation);
    };

    const handlePerformerSelect = (entity) => {
        const isEntityExist = headlinePerformer.some((performer) => performer._id === entity._id);
        if (!isEntityExist) {
            const updatedHeadlinePerformer = [...headlinePerformer, entity];
            setHeadlinePerformer(updatedHeadlinePerformer);
            setFieldValue('headlinePerson', updatedHeadlinePerformer);
        }
    };

    const handlePerformerRemove = (entityId) => {
        const updatedPeople = headlinePerformer.filter((entity) => entity._id !== entityId);
        setHeadlinePerformer(updatedPeople);
        setFieldValue('headlinePerson', updatedPeople);
    };
    const handleDateChange = (newValue, name) => {
        if (newValue) {
            const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
            setFieldValue(name, formattedDate !== 'Invalid Date' ? formattedDate : null);
        }
    };
    const handleTimeChange = (newValue, name) => {
        if (newValue) {
            const formattedTime = dayjs(newValue).format('HH:mm');
            setFieldValue(name, formattedTime !== 'Invalid Date' ?  newValue : null);
        }
    };
    const dynamicTitle = eventType;

    const handleEntitySelect = (entity) => {
        if (entity) {
            setSelectedEntities([entity]);
            setFieldValue('locationBusiness_selected', entity._id);
        }
    };
    const handleAddFilterClick = (categoryId, subcategoryId) => {
        setFieldValue('category',categoryId);
        setFieldValue('subcategory', subcategoryId);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });

        const scrollPosition = window.innerWidth >= 600 ? 300 : 500;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };
    const clearFields = () => {
        addEventUtils.resetEventForm(setFieldValue);
        setHeadlinePerformer([]);
        setSelectedEntities([]);
        setAdditionalPeople([]);
        setEventType('');
    };

    useEffect(() => {
        if(formSubmitValidation === 'SUCCESS'){
            setFeedbackOnStage({
                label: intl.formatMessage({
                    id: 'process.complete.ADD_EVENT_PROCESS.feedback.label',
                }),
                type: 'success',
                show: true,
                timeout: 3200,
            })
            clearFields();
            dispatch({
                type: ADD_EVENT_FORM_COMPLETE,
            });
            dispatch({
                type: SET_EVENT_START,
                payload: {
                    hasUnsavedChanges: false,
                }
            });
        }
        if(formSubmitValidation === 'ERROR'){
            setFeedbackOnStage({
                label: intl.formatMessage({
                    id: 'process.error.default.feedback.label',
                }),
                type: 'danger',
                show: true,
                timeout: 3200,
            })
        }
    }, [formSubmitValidation]);
    const addLinks = () => {
        const newLink = {
            type: 'other',
            url: values.url,
            caption: values.caption,
        };
        const updatedEventLinks = [...eventLinks, newLink];
        dispatch({
            type: SET_EVENT_START,
            payload: {
                ...values,
                eventLinks: [...eventLinks],
            },
        });
        setFieldValue('eventLinks', updatedEventLinks);
        setFieldValue('url', '');
        setFieldValue('caption', '');
    }

    return (
        <Form noValidate>
            {isEdit && (
                <ButtonWrapper marginTop={-48}>
                    <BaselineButton
                        type="button"
                        onClick={clearFields}
                    >
                        <FormattedMessage id='addBusiness.page.sections.events.newEvent.name' />
                    </BaselineButton>
                </ButtonWrapper>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Field
                        component={FormInput}
                        name="eventName"
                        id="eventName"
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.name',
                        })}
                        required
                        type="text"
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomDatePicker
                        value={values.startDate ? dayjs(values.startDate) : null}
                        onChange={(newValue) => handleDateChange(newValue, 'startDate')}
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.startDate',
                        })}
                        name="startDate"
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate ? errors.startDate : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomDatePicker
                        value={values.endDate ? dayjs(values.endDate) : null}
                        onChange={(newValue) => handleDateChange(newValue, 'endDate')}
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.endDate',
                        })}
                        name="endDate"
                        disabled={!values.startDate}
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate ? errors.endDate : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomTimePicker
                        value={values.startTime ? dayjs(values.startTime) : null}
                        onChange={(newValue) => handleTimeChange(newValue, 'startTime')}
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.startTime',
                        })}
                        name="startTime"
                        error={Boolean(touched.startTime && errors.startTime)}
                        helperText={touched.startTime && errors.startTime ? errors.startTime : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomTimePicker
                        value={values.endTime ? dayjs(values.endTime) : null}
                        onChange={(newValue) => handleTimeChange(newValue, 'endTime')}
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.endTime',
                        })}
                        name="endTime"
                        disabled={!values.startTime}
                        error={Boolean(touched.endTime && errors.endTime)}
                        helperText={touched.endTime && errors.endTime ? errors.endTime : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H3 color="secondary">
                        <FormattedMessage id="addBusiness.page.sections.events.photo" />
                    </H3>
                    <ManagePhotos
                        context={CONTEXT_EVENT}
                        isInOverlay={false}
                        photos={photos}
                        setPhotos={(photoList) => {
                            dispatch({
                                type: SET_EVENT_START,
                                payload: {
                                    photos: photoList,
                                },
                            });
                            setFieldValue('photos', photoList);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H3 color="secondary">
                        <FormattedMessage id="addBusiness.page.sections.events.title.description" />
                    </H3>
                    <Field
                        component={FormTextarea}
                        name="description"
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.events.label.description',
                        })}
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H3 color="secondary">
                        <FormattedMessage id="addBusiness.page.sections.events.links" />
                    </H3>
                    <LinksForm
                        name="eventLinks"
                        urlLabel="addBusiness.page.sections.links.form.url.label"
                        captionLabel="addBusiness.page.sections.links.form.caption.label"
                        linkTypeDefaults={linkTypeDefaults}
                        onAddLink={addLinks}
                        links={eventLinks}
                        disableSubmitByCondition={businessLinksUtils.disableAddLinksByLinksCount(values.eventLinks?.length)}
                    />
                    <LinksList links={eventLinks} action={SET_EVENT_START} fieldName="eventLinks"/>
                </Grid>
                <Grid item xs={12}>
                    <CategoriesFields
                    subcategoryFilters={subcategoryFilters}
                    handleSubcategoryChange={handleSubcategoryChange}
                    handleCategoryChange={handleCategoryChange}
                    categories={categories}
                    subcategories={subcategories}
                    selectedCategories={selectedCategories}
                    onSubmit={onSubmitData}
                    />
                    <DataTable
                        data={tableData}
                        onRemoveFilter={filterId => {
                            if (onRemoveFilter) {
                                onRemoveFilter({selectedCategories, filterId});
                            }
                        }}
                        onRemoveSubcategory={subcategoryId => {
                            if (onRemoveSubcategory) {
                                onRemoveSubcategory({selectedCategories, subcategoryId});
                            }
                        }}
                        onRemoveCategory={categoryId => {
                            if (onRemoveCategory) {
                                onRemoveCategory({selectedCategories, categoryId});
                            }
                        }}
                        onAddFilter={handleAddFilterClick}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <H3 color="secondary">
                            <FormattedMessage id="addBusiness.page.sections.events.title.location" />
                        </H3>
                        <RadioGroup
                            row
                            aria-label="locationType"
                            name="locationType"
                            value={values.locationType}
                            onChange={(e) => handleLocationChange(e.target.value)}
                        >
                            <FormControlLabel
                                value="CREATOR"
                                control={<Radio />}
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.this.business',
                                })}
                            />
                            <FormControlLabel
                                value="BUSINESS"
                                control={<Radio />}
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.link.business',
                                })}
                            />
                            <FormControlLabel
                                value="OTHER"
                                control={<Radio  />}
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.set.location',
                                })}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {values.locationType === 'BUSINESS' && (
                    <Grid item xs={12}>
                        <EventEntityPicker
                            identifier="locationBusinessPicker"
                            label={intl.formatMessage({
                                id: 'addBusiness.page.sections.events.label.link.business.placeholder',
                            })}
                            name="locationBusiness"
                            type={ENTITY_TYPE_BUSSINESS}
                            onEntitySelect={handleEntitySelect}
                        />
                        <Box mt={2}>
                            {selectedEntities.map((entity, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={1}>
                                    <EntityPickerDetails entity={entity} />
                                    <BaselineButton
                                        skin="primary"
                                        icon="x"
                                        btnType="outlined"
                                        type="button"
                                        onClick={() => handleBusinessLocationRemove(entity._id)}
                                    >
                                        <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                    </BaselineButton>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                )}
                {values.locationType === 'OTHER' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <Field
                                component={FormInput}
                                name="locationName"
                                id="locationName"
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.location.name',
                                })}
                                type="text"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                component={FormInput}
                                name="address"
                                id="address"
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.location.address',
                                })}
                                type="text"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
                                component={FormInput}
                                name="city"
                                id="city"
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.location.city',
                                })}
                                type="text"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
                                component={FormInput}
                                name="state"
                                id="state"
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.location.state',
                                })}
                                type="text"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
                                component={FormInput}
                                name="zipCode"
                                id="zipCode"
                                label={intl.formatMessage({
                                    id: 'addBusiness.page.sections.events.label.location.zip',
                                })}
                                type="text"
                                autoComplete="off"
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <H3 color="secondary">
                            <FormattedMessage id="addBusiness.page.sections.events.link.performer.title" />
                        </H3>
                        <RadioGroup
                            row
                            aria-label="eventType"
                            name="eventType"
                            value={eventType}
                            onChange={(e) => handleeventChange(e.target.value)}
                        >
                            <FormControlLabel
                                value={EVENT_PERFORMER_LABEL}
                                control={<Radio />}
                                label={EVENT_PERFORMER_LABEL}
                            />
                            <FormControlLabel
                                value={EVENT_HOST_LABEL}
                                control={<Radio />}
                                label={EVENT_HOST_LABEL}
                            />
                            <FormControlLabel
                                value={EVENT_SPONSOR_LABEL}
                                control={<Radio />}
                                label={EVENT_SPONSOR_LABEL}
                            />
                            <FormControlLabel
                                value={EVENT_OTHER_LABEL}
                                control={<Radio />}
                                label={EVENT_OTHER_LABEL}
                            />
                        </RadioGroup>
                    </FormControl>
                    {eventType === EVENT_OTHER_LABEL && (
                        <Field
                            component={FormInput}
                            name="customEventType"
                            id="customEventType"
                            label="Label"
                            type="text"
                            autoComplete="off"
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <H3 color="secondary">
                        <FormattedMessage
                            id="addBusiness.page.sections.events.link.performer.label"
                            values={{ type: dynamicTitle }}
                        />
                    </H3>
                    <P>
                        <FormattedMessage
                            id="addBusiness.page.sections.events.link.performer.placeholder"
                            values={{ type: dynamicTitle }}
                        />
                    </P>
                    <EventEntityPicker
                        identifier="headlinePersonPicker"
                        label={intl.formatMessage(
                            {
                                id: 'addBusiness.page.sections.events.link.performer.additional.placeholder',
                            },
                        )}
                        name="searchHeadlinePerformer"
                        type={ENTITY_TYPE_PEOPLE}
                        onEntitySelect={handlePerformerSelect}
                    />
                    {headlinePerformer && !!headlinePerformer.length && (
                        <Box mt={2}>
                            {headlinePerformer.map((entity, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={1}>
                                    <EntityPickerDetails entity={entity} />
                                    <BaselineButton
                                        skin="primary"
                                        icon="x"
                                        btnType="outlined"
                                        type="button"
                                        onClick={() => handlePerformerRemove(entity._id)}
                                    >
                                        <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                    </BaselineButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <H3 color="secondary">
                        <FormattedMessage
                            id="addBusiness.page.sections.events.link.performer.additional"
                            values={{ type: dynamicTitle }}
                        />
                    </H3>
                    <P>
                        <FormattedMessage
                            id="addBusiness.page.sections.events.link.performer.placeholder"
                            values={{ type: dynamicTitle }}
                        />
                    </P>
                    <EventEntityPicker
                        identifier="additionalPeoplePicker"
                        label={intl.formatMessage(
                            {
                                id: 'addBusiness.page.sections.events.link.performer.additional.placeholder',
                            },
                        )}
                        name='additionalPeoplePicker'
                        type={ENTITY_TYPE_PEOPLE}
                        onEntitySelect={handleAdditionalSelect}
                    />
                    {additionalPeople && !!additionalPeople.length && (
                        <Box mt={2}>
                            {additionalPeople.map((entity, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={1}>
                                    <EntityPickerDetails entity={entity} />
                                    <BaselineButton
                                        skin="primary"
                                        icon="x"
                                        btnType="outlined"
                                        type="button"
                                        onClick={() => handleAdditionalPeopleRemove(entity._id)}
                                    >
                                        <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                    </BaselineButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Grid>

            </Grid>
            <ButtonWrapper marginTop={16}>
                <BaselineButton
                    type="submit"
                    onClick={addEvent}
                >
                    <FormattedMessage id={isEdit ? 'addBusiness.page.sections.events.updateEvent.name' : 'addBusiness.page.sections.events.saveEvent.name'} />
                </BaselineButton>
            </ButtonWrapper>
        </Form>
    );
};

export default EventForm;
