import { rem } from 'polished';
import styled from 'styled-components';

const FavePageAsideInfoBannerContainer = styled.div`
    margin-top: ${rem(24)};
    padding: ${rem(16)} ${rem(24)};
    background-color: ${({ theme }) => theme.site.aside.info.container.backgroundColor};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
`;

export default FavePageAsideInfoBannerContainer;
