import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const businessAddressRequestSupportSchema = yup.object().shape({
    name: yup.string().required(),
    phone: fieldSchema.phoneNumber.required(),
    formattedAddress: yup.string().emptyToNull().required(),
    message: yup.string().emptyToNull().min(1).max(2000),
});

export default businessAddressRequestSupportSchema;
