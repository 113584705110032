import styled, { css } from 'styled-components';
import paragraphStyles from '../styles/paragraphStyles';
import paragraphLeadStyles from '../styles/paragraphLeadStyles';

const ListItem = styled.li`
    ${paragraphStyles}

    ${({ lead }) =>
        lead &&
        css`
            ${paragraphLeadStyles}
        `}
`;

export default ListItem;
