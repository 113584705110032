import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransitionItem } from 'baseline-ui/helpers';
import { FormInput, FieldContainer } from 'baseline-ui/form';
import { Small } from 'baseline-ui/typography';
import { Button, buttonBtnTypeData, buttonSkinData } from 'baseline-ui/button';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import AuthFormContainer from './AuthFormContainer';
import FormMailingListNote from './FormMailingListNote';
import formMailingListSignup from '../schema/formMailingListSignup';

const FormMailingList = ({
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    inProp,
    isLoading,
    buttonSkin,
    buttonBtnType,
    onSubmit,
    resetFormTimeout,
}) => {
    const intl = useIntl();

    const initialValues = {
        emailAddressMailingList: '',
    };

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear>
                <Formik
                    initialValues={initialValues}
                    validationSchema={formMailingListSignup}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values, { resetForm }) => {
                        onSubmit(values);
                        setTimeout(() => {
                            resetForm(initialValues);
                        }, resetFormTimeout);
                    }}
                >
                    {() => {
                        return (
                            <Form noValidate>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.emailAddress.label',
                                        })}
                                        name="emailAddressMailingList"
                                        id="emailAddressMailingList"
                                        type="email"
                                        disabled={isLoading}
                                        required
                                    />
                                </FieldContainer>
                                <Button
                                    skin={buttonSkin}
                                    btnType={buttonBtnType}
                                    block
                                    type="submit"
                                    loading={isLoading}
                                    loadingMessage={intl.formatMessage({
                                        id: 'auth.subscribe.loading.label',
                                    })}
                                >
                                    <FormattedMessage id="auth.subscribe.label" />
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
                <FormMailingListNote>
                    <Small color="muted">
                        <FormattedMessage id="auth.mailingList.note" />
                    </Small>
                </FormMailingListNote>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormMailingList.propTypes = {
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    isLoading: PropTypes.bool,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    onSubmit: PropTypes.func.isRequired,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    resetFormTimeout: PropTypes.number.isRequired,
};

FormMailingList.defaultProps = {
    inProp: false,
    inPropFeedback: false,
    isLoading: false,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    feedbackType: undefined,
    feedbackMessage: undefined,
};

export default FormMailingList;
