import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import { usePrevious } from 'baseline-ui/helpers';
import { GaIcon } from 'baseline-ui/icon';
import Icon from './collapsable/Icon';
import Item from './collapsable/Item';
import Wrapper from './collapsable/Wrapper';
import Title from './collapsable/Title';

const Trigger = ({
    title,
    customTitle,
    icon,
    iconSize,
    iconColor,
    iconTransformStart,
    iconTransformEnd,
    itemId,
    toggleItem,
    isOpen,
    className,
    triggerColor,
    ...props
}) => {
    const previous = usePrevious(isOpen);

    const { transform } = useSpring({
        from: { transform: iconTransformStart },
        to: { transform: isOpen ? iconTransformEnd : iconTransformStart },
    });

    return (
        <Item
            onClick={toggleItem}
            aria-expanded={isOpen}
            aria-controls={itemId}
            className={className}
            type="button"
            triggerColor={triggerColor}
            {...props}
        >
            <Wrapper>
                <Title title={title} customTitle={customTitle} triggerColor={triggerColor} />
                {icon && (
                    <Icon
                        style={{
                            transform: isOpen && previous === isOpen ? iconTransformEnd : transform,
                        }}
                    >
                        <GaIcon icon={icon} size={iconSize} color={iconColor} />
                    </Icon>
                )}
            </Wrapper>
        </Item>
    );
};

Trigger.propTypes = {
    title: PropTypes.string,
    customTitle: PropTypes.node,
    itemId: PropTypes.string.isRequired,
    icon: PropTypes.string,
    toggleItem: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
    iconTransformStart: PropTypes.string,
    iconTransformEnd: PropTypes.string,
    className: PropTypes.string,
    triggerColor: PropTypes.string,
};

Trigger.defaultProps = {
    title: null,
    customTitle: null,
    isOpen: false,
    icon: null,
    iconSize: 14,
    iconColor: 'currentColor',
    iconTransformStart: 'rotate(0deg)',
    iconTransformEnd: 'rotate(180deg)',
    className: null,
    triggerColor: 'inherit',
};

export default Trigger;
