import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { ADD_SINGLE_SECTION_PROCESS, ADD_SINGLE_SECTION_START } from 'redux/actions/SectionActions';
import { ModalContext } from 'baseline-ui/modal';
import { useDispatch, useSelector } from 'react-redux';
import useProcessLoader from 'utils/useProcessLoader';
import { itemsEditSectionSchema } from 'schema/itemsSchema';
import useProcessComplete from 'utils/useProcessComplete';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import { allSectionsSelector } from 'redux/selectors/sectionSelectors';
import ModalEditSectionForm from './ModalEditSectionForm';

const ModalAddSection = ({ ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const allSections = useSelector(allSectionsSelector);
    const { hideModal } = useContext(ModalContext);

    const initialValues = {
        sectionName: '',
    };

    const isLoading = useProcessLoader({
        processNames: [ADD_SINGLE_SECTION_PROCESS],
        withOverlay: true,
    });

    const isComplete = useProcessComplete({
        processNames: [ADD_SINGLE_SECTION_PROCESS],
        withFeedback: true,
    });

    useEffect(() => {
        if (isComplete && !isLoading) {
            hideModal();
        }
    }, [isComplete, isLoading]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                const { sectionName } = values;
                dispatch({
                    type: ADD_SINGLE_SECTION_START,
                    payload: { name: sectionName },
                });
                dispatch({
                    type: SET_BUSINESS_FORM_DATA_START,
                    payload: { hasUnsavedChanges: true },
                });
            }}
            validationSchema={itemsEditSectionSchema(allSections)}
        >
            {({ handleSubmit }) => {
                return (
                    <ModalEditSectionForm
                        title={intl.formatMessage({ id: 'addBusiness.modal.addSectionName.title' })}
                        footerActions={[
                            {
                                label: intl.formatMessage({
                                    id: 'addBusiness.modal.addSectionName.footerActions.cancel.button.label',
                                }),
                                btnType: 'link',
                                skin: 'muted',
                                onClick: () => {
                                    hideModal();
                                },
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'addBusiness.modal.addSectionName.footerActions.add.button.label',
                                }),
                                skin: 'secondary',
                                type: 'submit',
                                onClick: () => handleSubmit(),
                                loading: isLoading,
                                loadingMessage: 'Adding',
                            },
                        ]}
                        {...props}
                    />
                );
            }}
        </Formik>
    );
};

export default ModalAddSection;
