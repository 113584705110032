import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from '@mui/material';
import { Button } from 'baseline-ui/button';
import { H5, H6 } from "../../baseline-ui/typography";
import AddBusinessCategoriesFilterListItemContainer
    from "../../components/AddBusinessCategoriesList/AddBusinessCategoriesFilterListItemContainer";
import {FormattedMessage} from "react-intl";
import AddBusinessCategoriesListAddFilterButton
    from "../../components/AddBusinessCategoriesList/AddBusinessCategoriesListAddFilterButton";
import {Box} from "@mui/system";
import PropTypes from "prop-types";

const DataTable = ({
    data,
    onRemoveFilter,
    onRemoveSubcategory,
    onRemoveCategory,
    onAddFilter,
}) => {
    const renderTableRows = () => {
        const rows = [];

        for (const categoryId in data) {
            const category = data[categoryId];

            // Check if there are subcategories
            const subcategoryRows = Object.keys(category.subcategories).map(
                (subcategoryId, index) => {
                    const subcategory = category.subcategories[subcategoryId];
                    const filters = subcategory.filters.map((filter) => (
                        <span
                            key={filter.id}
                            style={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                            <AddBusinessCategoriesFilterListItemContainer>
                                    <H6 color="primary">{filter.name}</H6>
                                    <Button
                                        icon="x"
                                        btnType="link"
                                        size="sm"
                                        onClick={() => onRemoveFilter(filter.id)}
                                    />
                                </AddBusinessCategoriesFilterListItemContainer>
                        </span>
                    ));

                    return (
                        <TableRow key={subcategoryId}>
                            {index === 0 && (
                                <TableCell rowSpan={Object.keys(category.subcategories).length}>
                                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <H5 color="primary">{category.name}</H5>
                                        <Button
                                            icon="x"
                                            btnType="link"
                                            size="sm"
                                            onClick={() => onRemoveCategory(category.id)}
                                        />
                                    </div>
                                </TableCell>
                            )}
                            <TableCell>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <H6 color="primary">{subcategory.name}</H6>
                                    <Button
                                        icon="x"
                                        btnType="link"
                                        size="sm"
                                        onClick={() => onRemoveSubcategory(subcategory.id)}
                                    />
                                </div>
                            </TableCell>
                            <TableCell>
                                {filters}

                                {onAddFilter ? (
                                    <AddBusinessCategoriesListAddFilterButton>
                                        <Button
                                            type="button"
                                            size="sm"
                                            btnType="link"
                                            icon="plus"
                                            onClick={() => onAddFilter(category.id, subcategory.id)}
                                        >
                                            <FormattedMessage id="addBusiness.modal.addedCategoryAndFilters.form.filters.addFilters.label" />
                                        </Button>
                                    </AddBusinessCategoriesListAddFilterButton>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    );
                },
            );

            // If there are no subcategories, render the category directly with its filters
            if (subcategoryRows.length === 0) {
                rows.push(
                    <TableRow key={categoryId}>
                        <TableCell>
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <H5 color="primary">{category.name}</H5>
                                <Button
                                    icon="x"
                                    btnType="link"
                                    size="sm"
                                    onClick={() => onRemoveCategory(category.id)}
                                />
                            </div>

                        </TableCell>
                        <TableCell>
                            <H5 color="primary" textAlign={'center'}>-</H5>
                        </TableCell>
                        <TableCell>
                            {category?.filters?.map((filter) => (
                                <span
                                    key={filter.id}
                                    style={{ display: 'inline-flex', alignItems: 'center' }}
                                >
                                     <AddBusinessCategoriesFilterListItemContainer>
                                    <H6 color="primary">{filter.name}</H6>
                                    <Button
                                        icon="x"
                                        btnType="link"
                                        size="sm"
                                        onClick={() => onRemoveFilter(filter.id)}
                                    />
                                </AddBusinessCategoriesFilterListItemContainer>
                                </span>
                            ))}
                            {onAddFilter ? (
                                <AddBusinessCategoriesListAddFilterButton>
                                    <Button
                                        type="button"
                                        size="sm"
                                        btnType="link"
                                        icon="plus"
                                        onClick={() => onAddFilter(category.id, null)}
                                    >
                                        <FormattedMessage id="addBusiness.modal.addedCategoryAndFilters.form.filters.addFilters.label" />
                                    </Button>
                                </AddBusinessCategoriesListAddFilterButton>
                            ) : null}
                        </TableCell>
                    </TableRow>,
                );
            } else {
                rows.push(...subcategoryRows);
            }
        }

        return rows;
    };

    return (
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Box sx={{ overflow: "auto" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Table>
                        <TableBody>{renderTableRows()}</TableBody>
                    </Table>
                </Box>
            </Box>
        </TableContainer>
    );
};
DataTable.propTypes = {
    data: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            subcategories: PropTypes.objectOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                    filters: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                            name: PropTypes.string.isRequired
                        })
                    ).isRequired
                })
            ).isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onRemoveSubcategory: PropTypes.func.isRequired,
    onRemoveCategory: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func,
};

export default DataTable;
