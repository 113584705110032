import { rem } from 'polished';
import styled from 'styled-components';

const AccessRightsUsersListItemContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    padding-bottom: ${rem(16)};
    padding-top: ${rem(16)};
`;

export default AccessRightsUsersListItemContainer;
