import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormInput } from 'baseline-ui/form';
import { Field, useFormikContext } from 'formik';
import { GaIcon } from 'baseline-ui/icon';
import useGoogleMapsAutocompletePlaces from 'utils/useGoogleMapsAutocompletePlaces';
import { H6 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { isEmpty } from 'lodash';
import AdminBusinessAdvertisingCityButtonContainer from './AdminBusinessAdvertisingCityButtonContainer';
import AdminBusinessAdvertisingCityListContainer from './AdminBusinessAdvertisingCityListContainer';

const AdminBusinessAdvertisingAddCityField = ({ id, arrayHelpers }) => {
    const ref = useRef();
    const renderSearchId = `${id}SearchCity`;
    const renderListId = `${id}CityList`;

    const { values, setFieldValue } = useFormikContext();

    useGoogleMapsAutocompletePlaces({
        ref,
        types: ['(cities)'],
        queryCallback: (city) => {
            arrayHelpers.push(city);
        },
    });

    return (
        <>
            <Field
                component={FormInput}
                label="City"
                name={renderSearchId}
                id={renderSearchId}
                type="text"
                innerRef={ref}
                placeholder=""
                required={false}
                prependAddon={<GaIcon icon="search" size={14} />}
                autoComplete="off"
                onBlur={() => {
                    setFieldValue(renderSearchId, '');
                }}
            />
            {!isEmpty(values[renderListId]) && (
                <AdminBusinessAdvertisingCityListContainer>
                    {values[renderListId].map((selectedCity, selectedCityIndex) => (
                        <AdminBusinessAdvertisingCityButtonContainer key={selectedCity}>
                            <H6 color="primary">{selectedCity}</H6>
                            <Button
                                icon="x"
                                btnType="link"
                                onClick={() => arrayHelpers.remove(selectedCityIndex)}
                                size="sm"
                            />
                        </AdminBusinessAdvertisingCityButtonContainer>
                    ))}
                </AdminBusinessAdvertisingCityListContainer>
            )}
        </>
    );
};

AdminBusinessAdvertisingAddCityField.propTypes = {
    id: PropTypes.string.isRequired,
    arrayHelpers: PropTypes.shape().isRequired,
};

export default AdminBusinessAdvertisingAddCityField;
