import styled, { css } from 'styled-components';
import { get } from 'lodash';
import formTheme from '../../styles/formTheme';

const CheckboxLabel = styled.span`
    color: ${({ theme, skin }) =>
        get(
            theme,
            `form.checkbox.${skin}.colorChecked`,
            formTheme.form.checkbox[skin].colorChecked,
        )};
    display: block;

    ${({ theme, disabledValue, skin }) =>
        disabledValue &&
        css`
            color: ${get(
                theme,
                `form.checkbox.${skin}.colorDisabled`,
                formTheme.form.checkbox[skin].colorDisabled,
            )};
            cursor: not-allowed;
        `}
`;

export default CheckboxLabel;
