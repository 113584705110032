import styled from 'styled-components';
import { get } from 'lodash';
import { rem, lighten, darken } from 'polished';
import overlayTheme from '../../../styles/overlayTheme';

const Container = styled.div`
    background-color: ${({ themeMode, theme, customBackgroundColor }) => {
        const themeFunction = themeMode === 'darkMode' ? lighten : darken;
        return themeFunction(
            0.025,
            customBackgroundColor ||
                get(
                    theme,
                    'overlay.footer.backgroundColor',
                    overlayTheme.overlay.footer.backgroundColor,
                ),
        );
    }};
    border-top-color: ${({ theme, customBackgroundColor }) =>
        customBackgroundColor ||
        get(theme, 'overlay.footer.dividerColor', overlayTheme.overlay.footer.dividerColor)};
    border-top-style: solid;
    border-top-width: 1px;
    padding: ${rem(16)};
`;

export default Container;
