import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormCheckbox } from 'baseline-ui/form';
import { useDispatch, useSelector } from 'react-redux';
import {
    DROP_PRODUCT_TO_SECTION_START,
    REMOVE_PRODUCT_FROM_SECTION_START,
} from 'redux/actions/EditProductActions';
import { productToEditSelector } from 'redux/selectors/productSelectors';
import ModalEditProductManageSectionsListItemTitle from './ModalEditProductManageSectionsListItemTitle';
import ModalEditProductManageSectionsListItemContainer from './ModalEditProductManageSectionsListItemContainer';

const ModalEditProductManageSectionsListItem = ({ sectionName, productId }) => {
    const dispatch = useDispatch();
    const item = useSelector(productToEditSelector);
    const { values } = useFormikContext();

    const handleOnChange = useCallback(
        (targetId, targetValue) => {
            if (targetValue) {
                dispatch({
                    type: DROP_PRODUCT_TO_SECTION_START,
                    payload: {
                        item,
                        sectionId: targetId,
                    },
                });
            }
            if (!targetValue) {
                dispatch({
                    type: REMOVE_PRODUCT_FROM_SECTION_START,
                    payload: {
                        item,
                        sectionId: targetId,
                    },
                });
            }
        },
        [values],
    );
    return (
        <ModalEditProductManageSectionsListItemContainer>
            <Field
                component={FormCheckbox}
                id={productId}
                name={productId}
                onChange={(v) => handleOnChange(v.target.id, v.target.checked)}
            >
                <ModalEditProductManageSectionsListItemTitle>
                    {sectionName}
                </ModalEditProductManageSectionsListItemTitle>
            </Field>
        </ModalEditProductManageSectionsListItemContainer>
    );
};

ModalEditProductManageSectionsListItem.propTypes = {
    sectionName: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
};

export default ModalEditProductManageSectionsListItem;
