import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import formTheme from '../../styles/formTheme';
import dobInputStyles from '../../styles/dobInputStyles';

const InputDay = styled.input`
    ${dobInputStyles}
    flex: 0 0 ${(props) =>
        rem(get(props, `theme.form.input.fontSize`, formTheme.form.input.fontSize) * 2.6)};
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    text-align: center;
`;

export default InputDay;
