import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesListItemContainer = styled.button`
    background-color: ${({ theme }) => theme.base.colors.light};
    border: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    cursor: grab;
    justify-content: space-between;
    margin-bottom: ${rem(4)};
    margin-top: ${rem(4)};
    overflow-wrap: anywhere;
    padding: ${rem(8)} ${rem(16)};
    width: 100%;
    text-align: left;
    user-select: none;
`;

export default ProductsAndServicesListItemContainer;
