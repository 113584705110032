import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import { GaIcon } from 'baseline-ui/icon';
import Title from './Title';
import Item from './Item';
import Wrapper from './Wrapper';
import Icon from './Icon';

const Header = ({ title, icon, iconSize, onClick, id, isOpen, itemId, triggerColor }) => {
    const { transform } = useSpring({
        from: { transform: 0 },
        to: { transform: isOpen ? 180 : 0 },
    });

    const handleClick = () => {
        onClick(id);
    };

    return (
        <Item
            onClick={handleClick}
            aria-expanded={isOpen}
            aria-controls={itemId}
            triggerColor={triggerColor}
        >
            <Wrapper>
                <Title triggerColor={triggerColor}>{title}</Title>
                <Icon
                    style={{
                        transform: transform.to((v) => `rotate(${v}deg)`),
                    }}
                >
                    <GaIcon icon={icon} size={iconSize} color="currentColor" />
                </Icon>
            </Wrapper>
        </Item>
    );
};

Header.propTypes = {
    title: PropTypes.node.isRequired,
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    triggerColor: PropTypes.string,
};

Header.defaultProps = {
    icon: 'arrow-down',
    iconSize: 14,
    isOpen: false,
    triggerColor: 'inherit',
};

export default Header;
