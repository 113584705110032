import styled, { css } from 'styled-components';
import { siteContainer, container } from '../../styles/layout';

const SiteContentWrapper = styled.div`
    ${({ isFullWidth }) =>
        !isFullWidth &&
        css`
            ${siteContainer}
        `}

    ${({ isFullWidth }) =>
        isFullWidth &&
        css`
            ${container}
        `}
`;

export default SiteContentWrapper;
