import { Button } from "@mui/material";
import { COLORS } from "../common/consts";
import Spinner from "./Spinner";
import { LoadingButton } from "@mui/lab";

const PrimaryButton = ({
  caption,
  onClick,
  isLoading,
  disabled,
  variant = "contained",
  sx = {},
}) => {
  const BACKGROUND_COLOR = {
    outlined: COLORS.ACTIVE_LIGHT_COLOR_BG,
    contained: COLORS.ACTIVE_COLOR_BG,
    disabled: COLORS.DISABLED_GREY,
  };

  const TEXT_COLOR = {
    outlined: COLORS.ACTIVE_COLOR_BG,
    contained: COLORS.LIGHT_COLOR_TEXT,
    disabled: COLORS.DISABLED_GREY_DARK,
  };

  const BORDER_COLOR = {
    outlined: COLORS.ACTIVE_COLOR_BG,
    contained: COLORS.ACTIVE_COLOR_BG,
    disabled: COLORS.DISABLED_GREY,
  };
  return (
    <LoadingButton
      fullWidth
      disabled={isLoading || disabled}
      loading={isLoading}
      onClick={onClick}
      sx={{
        borderRadius: 25,
        backgroundColor: BACKGROUND_COLOR[variant],
        color: TEXT_COLOR[variant],
        paddingLeft: 5,
        paddingRight: 5,
        minHeight: "32px",
        border: `1px solid ${BORDER_COLOR[variant]}`,
        ...sx,
      }}
    >
      {caption}
    </LoadingButton>
  );
};

export default PrimaryButton;
