import { createSelector } from 'reselect';

export const businessAccessRightsSelector = (state) => state.BusinessAccessRightsReducer;

export const allUsersBusinessAccessRightsSelector = createSelector(
    businessAccessRightsSelector,
    ({ users }) =>
        users.map((user) => {
            return { ...user, id: user._id };
        }),
);

export const addedUsersBusinessAccessRightsSelector = createSelector(
    businessAccessRightsSelector,
    ({ users }) =>
        users
            .filter((user) => !user.creator)
            .map((user) => {
                return { ...user, id: user._id };
            }),
);

export const userBusinessAccessRightsByUserId = (userId) =>
    createSelector(allUsersBusinessAccessRightsSelector, (users) =>
        users.find((user) => user._id === userId),
    );

export const userBusinessAccessRightsCreatorSelector = createSelector(
    allUsersBusinessAccessRightsSelector,
    (users) => users.find((user) => user.creator),
);
