import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'baseline-ui/modal';
import { ContentRenderer } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import { MessageButtons, MessageContent } from './components';

export const MessageBox = ({
    titleId, messageId, data,
    okId, cancelId, yesId, noId,
    show,
    onDismiss, onResponse,
    ...props
}) => {
    titleId = titleId ?? 'messagebox.modal.default.title';
    const intl = useIntl();
    const messageContent = intl.formatMessage({ id: messageId }, data);
    const generateResponse = (type) => {
        return async (_evt) => {
            await onResponse(type, data);
        }
    };
    return (
        <Modal
            title={intl.formatMessage({ id: titleId }, data)}
            handleOnDismiss={onDismiss}
            {...props}
        >
            <MessageContent>
                <ContentRenderer source={messageContent} />
            </MessageContent>
            <MessageButtons>
                {show.no && (
                    <Button onClick={generateResponse('no')}>
                        {intl.formatMessage({ id: noId ?? 'messagebox.modal.default.buttons.no' })}
                    </Button>
                )}                 
                {show.yes && (
                    <Button onClick={generateResponse('yes')}>
                        {intl.formatMessage({ id: yesId ?? 'messagebox.modal.default.buttons.yes' })}
                    </Button>
                )}
                {show.cancel && (
                    <Button onClick={generateResponse('cancel')}>
                        {intl.formatMessage({ id: cancelId ?? 'messagebox.modal.default.buttons.cancel' })}
                    </Button>
                )}
                {show.ok && (
                    <Button onClick={generateResponse('ok')}>
                        {intl.formatMessage({ id: okId ?? 'messagebox.modal.default.buttons.ok' })}
                    </Button>
                )}
            </MessageButtons>
        </Modal>
    )
};
MessageBox.propTypes = {
    messageId: PropTypes.string.isRequired,
    titleId: PropTypes.string,
    data: PropTypes.object,
    show: PropTypes.object,
    onDismiss: PropTypes.func.isRequired,
    onResponse: PropTypes.func,
};
MessageBox.defaultProps = {
    size: 'lg',
    titleId: undefined,
    onResponse: () => true,
    show: {},
    data: {},
};
