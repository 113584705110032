import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import loaderTheme from '../../styles/loaderTheme';
import loaderKeyframes from '../../styles/loaderKeyframes';

const Dash = styled.span`
    animation: ${loaderKeyframes.dashAnimation} infinite 1s;
    background-color: ${({ theme }) =>
        get(theme, 'loader.dash.backgroundColor', loaderTheme.loader.dash.backgroundColor)};
    border-radius: ${rem(2)};
    height: ${rem(4)};
    left: -5%;
    position: absolute;
    right: auto;
    transition: all 1s ease-in-out;
    width: 0.5em;
    z-index: 1;

    ${({ skin }) =>
        skin &&
        css`
            background-color: currentColor;
        `}
`;

export default Dash;
