import {
  GET_BUSINESS_ACCESS_RIGHTS_SUCCESS,
  RESET_BUSINESS_ACCESS_RIGHTS_START,
} from "../actions/BusinessAccessRightsActions";

const BusinessAccessRightsReducer = (state = { users: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_BUSINESS_ACCESS_RIGHTS_START: {
      return {
        ...state,
        users: [],
      };
    }
    case GET_BUSINESS_ACCESS_RIGHTS_SUCCESS: {
      return {
        ...state,
        users: payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default BusinessAccessRightsReducer;
