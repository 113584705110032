import styled, { css } from 'styled-components';
import { rem } from 'polished';

const Image = styled.img`
    ${({ imageFluid }) =>
        imageFluid &&
        css`
            height: auto;
            max-width: 100%;
        `}

    ${({ height }) =>
        height &&
        css`
            height: ${rem(height)};
        `}

    ${({ width }) =>
        width &&
        css`
            width: ${rem(width)};
        `}
        
    ${({ logoWidth }) =>
    logoWidth &&
        css`
            width: ${rem(logoWidth)};
            @media (min-width: 1024px) and (max-width: 1210px) {
                width: ${rem(logoWidth*0.7)};
            }
             @media (max-width: 340px) {
                width: ${rem(logoWidth*0.6)};
            }
        `}
`;

export default Image;
