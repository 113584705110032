import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import SplitAsideMainTemplate from 'components/pageLayout/templates/SplitAsideMainTemplate';
import { FormRecoverPassword, FormResetPassword } from 'baseline-ui/auth';
import { useIntl } from 'react-intl';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import splitAsideMainTemplateBgImage from 'components/pageLayout/templates/splitAsideMainTemplateBgImage';
import { useDispatch } from 'react-redux';
import {
    POST_REQUEST_PASSWORD_RESET_CODE_PROCESS,
    POST_REQUEST_PASSWORD_RESET_CODE_START,
    POST_RESET_PASSWORD_PROCESS,
    POST_RESET_PASSWORD_PROCESS_RESET,
    POST_RESET_PASSWORD_START,
    SET_RESET_PASSWORD_CODE_PROCESS_RESET,
    SET_RESET_PASSWORD_IDENTIFIER_PROCESS_RESET,
} from 'redux/actions/ResetPasswordActions';
import { useComplete } from 'common/useComplete';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { AlertFeedback } from 'baseline-ui/alert';
import CommonWrapper from './CommonWrapper';
import { useLoading } from '../common/useLoading';
import WelcomeAside from './Welcome/WelcomeAside';
import WelcomeMainHeader from './Welcome/WelcomeMainHeader';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';

const RecoverPasswordRoute = () => {
    const theme = useContext(ThemeContext);

    const navigate = useNavigate();
    const intl = useIntl();
    const isLoadingRequestCode = useLoading(POST_REQUEST_PASSWORD_RESET_CODE_PROCESS);
    const isLoadingResetPassword = useLoading(POST_RESET_PASSWORD_PROCESS);
    const errorMessageRequestCode = useProcessErrorMessage({
        processName: POST_REQUEST_PASSWORD_RESET_CODE_PROCESS,
    });
    const errorMessageResetCodePassword = useProcessErrorMessage({
        processName: POST_REQUEST_PASSWORD_RESET_CODE_PROCESS,
    });
    const errorMessageResetPassword = useProcessErrorMessage({
        processName: POST_RESET_PASSWORD_PROCESS,
    });
    const dispatch = useDispatch();
    const isRequestComplete = useComplete(POST_REQUEST_PASSWORD_RESET_CODE_PROCESS);

    const [identifier, setIdentifier] = useState('');
    const [resendRecoveryEmail, setResendRecoveryEmail] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (resendRecoveryEmail && isRequestComplete) {
                setResendRecoveryEmail(false);
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [resendRecoveryEmail, isRequestComplete]);

    useEffect(() => {
        return () => {
            setIdentifier('');
            dispatch({ type: POST_RESET_PASSWORD_PROCESS_RESET });
            dispatch({ type: SET_RESET_PASSWORD_CODE_PROCESS_RESET });
            dispatch({ type: SET_RESET_PASSWORD_IDENTIFIER_PROCESS_RESET });
        };
    }, [dispatch]);

    return (
        <CommonWrapper>
            <SplitAsideMainTemplate
                bgStart={theme.site.welcome.bgStart}
                bgEnd={theme.site.welcome.bgEnd}
                bgImage={splitAsideMainTemplateBgImage}
                aside={<WelcomeAside />}
                main={
                    <WelcomeMainContainer>
                        <AlertFeedback
                            inProp={resendRecoveryEmail}
                            alertType="success"
                            alertMessage={intl.formatMessage({
                                id: 'welcome.requestRecoveryEmail.feedbackMessage.label',
                            })}
                        />
                        {isRequestComplete && !isEmpty(identifier) && !resendRecoveryEmail && (
                            <>
                                <WelcomeMainHeader
                                    title={intl.formatMessage({
                                        id: 'welcome.resetPassword.title',
                                    })}
                                    content={intl.formatMessage({
                                        id: 'welcome.resetPassword.md',
                                    })}
                                />
                                <FormResetPassword
                                    showHeading={false}
                                    onSubmitResetPassword={(values) => {
                                        const { passwordCode, password } = values;
                                        dispatch({
                                            type: POST_RESET_PASSWORD_START,
                                            payload: {
                                                identifier,
                                                code: passwordCode,
                                                newPassword: password,
                                                navigate,
                                            },
                                        });
                                    }}
                                    onClickRequestRecoveryEmail={() => {
                                        setResendRecoveryEmail(true);
                                        dispatch({
                                            type: POST_REQUEST_PASSWORD_RESET_CODE_START,
                                            payload: {
                                                identifier,
                                            },
                                        });
                                    }}
                                    errorMessage={
                                        errorMessageResetCodePassword || errorMessageResetPassword
                                    }
                                    isLoading={isLoadingResetPassword}
                                    inProp
                                    appear={false}
                                />
                            </>
                        )}
                        {!identifier && (
                            <>
                                <WelcomeMainHeader
                                    title={intl.formatMessage({
                                        id: 'welcome.forgotPassword.title',
                                    })}
                                    content={intl.formatMessage({
                                        id: 'welcome.forgotPassword.md',
                                    })}
                                />
                                <FormRecoverPassword
                                    showHeading={false}
                                    onSubmitRecoverPassword={(values) => {
                                        setIdentifier(values.emailAddress);
                                        dispatch({
                                            type: POST_REQUEST_PASSWORD_RESET_CODE_START,
                                            payload: {
                                                identifier: values.emailAddress,
                                            },
                                        });
                                    }}
                                    errorMessage={errorMessageRequestCode}
                                    isLoading={isLoadingRequestCode}
                                    inProp
                                    appear={false}
                                />
                            </>
                        )}
                    </WelcomeMainContainer>
                }
            />
        </CommonWrapper>
    );
};

export default RecoverPasswordRoute;
