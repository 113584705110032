import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { get, uniqBy } from "lodash";
// import { getSubcatOptionsWithHeaders } from "../helpers/features";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlatSubcatOptionsWithHeaders,
  getSubcatOptionsWithHeaders,
  serializeOption,
  serializeOptionT,
} from "../helpers/features";
import { GET_ITEM_CATEGORIES_START } from "../redux/actions/CategoriesActions";
import {
  ADD_PRODUCT_CATEGORY_START,
  ADD_PRODUCT_SUBCATEGORY_START,
  DELETE_PRODUCT_CATEGORY_START,
  SET_PRODUCT_CATEGORY_START,
} from "../redux/actions/EditProductActions";
import SelectPicker from "./SelectPicker";
import update from "immutability-helper";

const CategoriesPicker = () => {
  const dispatch = useDispatch();
  let { itemCategories } = useSelector(
    ({ CategoriesReducer }) => CategoriesReducer
  );

  const { productToEdit } = useSelector(
    ({ EditProductReducer }) => EditProductReducer
  );

  const productCategories = get(productToEdit, ["mapping", "categories"]);

  useEffect(() => {
    if (!itemCategories || itemCategories?.length === 0) {
      dispatch({
        type: GET_ITEM_CATEGORIES_START,
      });
    }
  }, []);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [productCategoryFull, setProductCategoryFull] = useState({});

  return (
    <Grid item container xs={12}>
      {productCategories &&
        itemCategories &&
        productCategories.map((productCategory, productCategoryIndex) => {
          const productCategoryFull = itemCategories.find(
            (itemCategory) => itemCategory.id === productCategory.id
          );
          let subcats = [];
          const selectedSubCategories = productCategory.headers.map(
            (header) => {
              subcats = subcats.concat(header.subcategories);
            }
          );

          itemCategories = uniqBy(itemCategories, "name");
          subcats = uniqBy(subcats, "id");
          console.log(
            serializeOption(productCategory),
            serializeOption(itemCategories[0])
          );

          console.log("productCategory", productCategoryFull);
          return (
            <Grid
              container
              item
              xs={12}
              key={`categories_${productCategory.id}_${productCategoryIndex}`}
            >
              {/* <Box marginBottom={2} xs={12}>
                <Box marginTop={2} xs={12}> */}
              <Autocomplete
                fullWidth
                key={`product_category_${productCategory.id}_${productCategoryIndex}`}
                options={itemCategories.map((category) => {
                  return serializeOption(category);
                })}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                  serializeOption(productCategory).id
                    ? serializeOption(productCategory)
                    : undefined
                }
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                onChange={(e, val) => {
                  console.log(e, val);
                  dispatch({
                    type: SET_PRODUCT_CATEGORY_START,
                    payload: {
                      productCategoryIndex,
                      value: val,
                    },
                  });
                }}
              />
              {/* </Box> */}

              {productCategoryFull?.headers.map((header) => {
                return (
                  <Grid item xs={12} key={`header_${header.id}`}>
                    <SelectPicker
                      multiple
                      value={header.subcategories
                        .filter((subcategory) => subcategory.selected)
                        .map((subcategory) => subcategory.id)}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        const selectedTagIndex = header.subcategories.findIndex(
                          (c) => c.id === value
                        );
                        console.log(selectedTagIndex);

                        // const selectedCategoryIndex =
                        //   selectedCategories.findIndex(
                        //     (cat) => cat.id === header.id
                        //   );
                        const selectedHeaderIndex =
                          productCategoryFull.headers.findIndex(
                            (h) => h.id === header.id
                          );

                        console.log(selectedHeaderIndex);

                        const newProductCategoryFull = update(
                          productCategoryFull,
                          {
                            headers: {
                              [selectedHeaderIndex]: {
                                subcategories: {
                                  [selectedTagIndex]: {
                                    $merge: {
                                      selected: true,
                                    },
                                  },
                                },
                              },
                            },
                          }
                        );

                        console.log(
                          "newProductCategoryFull",
                          newProductCategoryFull
                        );
                        setSelectedCategories(newProductCategoryFull);
                        // const subcategoryIndex = selectedCategories[
                        //   selectedCategoryIndex
                        // ].headers[selectedHeaderIndex].subcategories.findIndex(
                        //   (subcategory) => subcategory.id === value
                        // );
                        // const newSelectedFilters = update(selectedCategories, {
                        //   [selectedCategoryIndex]: {
                        //     headers: {
                        //       [selectedHeaderIndex]: {
                        //         subcategories: {
                        //           [subcategoryIndex]: {
                        //             $merge: {
                        //               selected: true,
                        //             },
                        //           },
                        //         },
                        //       },
                        //     },
                        //   },
                        // });
                        // setSelectedCategories(newSelectedFilters);
                      }}
                      label={`${header.name}`}
                      options={header?.subcategories?.map((entityCategory) => ({
                        ...entityCategory,
                        label: entityCategory.name,
                        value: entityCategory.id,
                      }))}
                    />
                  </Grid>
                );
              })}

              {/* {productCategoryFull?.subCategories && (
                <Box marginTop={2}>
                  <Autocomplete
                    multiple
                    options={
                      getFlatSubcatOptionsWithHeaders(
                        productCategory.name,
                        itemCategories
                      )[1]
                    }
                    value={subcats.map((c) => {
                      return serializeOptionT(c);
                    })}
                    label="Subcategory"
                    onChange={(e, val) => {
                      console.log(e, val);
                      dispatch({
                        type: ADD_PRODUCT_SUBCATEGORY_START,
                        payload: {
                          productCategoryIndex,
                          productCategoryId: productCategory.id,
                          subcategoryIds: val.map(
                            (subCategory) => subCategory.id
                          ),
                        },
                      });
                    }}
                    getOptionLabel={(option) => option.label}
                    groupBy={(option) => option.typeName}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subcategory"
                        variant="standard"
                      />
                    )}
                  />
                  <Box marginTop={5} />
                </Box>
              )} */}
              {/* <Button
                onClick={() =>
                  dispatch({
                    type: DELETE_PRODUCT_CATEGORY_START,
                    payload: productCategoryIndex,
                  })
                }
              >
                Delete category
              </Button> */}
              {/* </Box> */}
              <Divider />
            </Grid>
          );
        })}
      <Box marginTop={2}>
        <Button
          variant={"outlined"}
          onClick={() => dispatch({ type: ADD_PRODUCT_CATEGORY_START })}
        >
          Select additional category
        </Button>
      </Box>
    </Grid>
  );
};

export default CategoriesPicker;
