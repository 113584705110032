import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import FormInput from '../FormInput';
import FormPasswordInputReveal from './FormPasswordInputReveal';
import formPropTypes from '../../prop-types/formPropTypes';

const FormPasswordInput = ({ showRevealPasswordAddon, form, field, ...props }) => {
    const [revealPassword, setRevealPassword] = useState(false);
    const ref = useRef();
    const value = getIn(form.values, field.name);

    useEffect(() => {
        if (ref && ref.current && revealPassword) {
            ref.current.focus();
            ref.current.setSelectionRange(value.length, value.length);
        }
    }, [revealPassword]);

    return (
        <FormInput
            {...props}
            form={form}
            field={field}
            innerRef={ref}
            type={revealPassword ? 'text' : 'password'}
            appendAddon={
                showRevealPasswordAddon && (
                    <FormPasswordInputReveal
                        onClick={() => setRevealPassword((prev) => !prev)}
                        revealPassword={revealPassword}
                    />
                )
            }
        />
    );
};

FormPasswordInput.propTypes = {
    showRevealPasswordAddon: PropTypes.bool,
    form: formPropTypes.formikForm.isRequired,
    field: formPropTypes.formikField.isRequired,
};

FormPasswordInput.defaultProps = {
    showRevealPasswordAddon: true,
};

export default FormPasswordInput;
