import React from 'react';
import OverlayConsumer from './OverlayConsumer';

const ModalRoot = () => {
    return (
        <OverlayConsumer>
            {({
                component: Component,
                isOverlayOpen,
                overlayProps,
                removeOverlay,
                hideOverlay,
                showOverlay,
            }) => {
                const renderComponent = Component ? (
                    <Component
                        {...overlayProps}
                        onDismiss={removeOverlay}
                        isOpen={isOverlayOpen}
                        onRequestClose={hideOverlay}
                        showOverlay={showOverlay}
                    />
                ) : null;
                return renderComponent;
            }}
        </OverlayConsumer>
    );
};

export default ModalRoot;
