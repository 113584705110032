import { rem } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesListItemSectionTagsContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    flex-wrap: wrap;
    margin-top: ${rem(8)};
    padding-top: ${rem(8)};
`;

export default ProductsAndServicesListItemSectionTagsContainer;
