import { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize, rem } from 'polished';
import formTheme from './formTheme';

const dobInputStyles = (props) => css`
    appearance: none;
    background-color: transparent;
    border: 0;
    color: ${get(
        props,
        `theme.form.input[${props.skin}].color`,
        formTheme.form.input[props.skin].color,
    )};
    display: block;
    font-family: ${get(props, `theme.form.fontFamily`, formTheme.form.fontFamily)};
    font-size: ${rem(get(props, `theme.form.input.fontSize`, formTheme.form.input.fontSize))};
    height: 3.375rem;
    min-width: 0;
    padding: 1.5rem 0.625rem 0.3125rem 0.625rem;
    transition: background 0.3s ease-in 0.1s;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        color: ${transparentize(
            0.4,
            get(
                props,
                `theme.form.input[${props.skin}].color`,
                formTheme.form.input[props.skin].color,
            ),
        )};
    }

    ${props.disabled &&
    css`
        color: ${get(
            props,
            `theme.form.input[${props.skin}].colorDisabled`,
            formTheme.form.input[props.skin].colorDisabled,
        )};
        cursor: not-allowed;
    `}
`;

export default dobInputStyles;
