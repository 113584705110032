import React from 'react';
import { FormInputFeedback } from 'baseline-ui/form';
import { helpersUtils } from 'baseline-ui/helpers';
import FormErrorContainer from './FormErrorContainer';
import authPropTypes from '../prop-types/authPropTypes';

const AuthFormSignError = ({ errorMessage }) => {
    if (!errorMessage) {
        return null;
    }
    return (
        <FormErrorContainer>
            <FormInputFeedback
                feedbackType="error"
                id={errorMessage.errorType || helpersUtils.generateUniqueId()}
            >
                {errorMessage.errorDescription}
            </FormInputFeedback>
        </FormErrorContainer>
    );
};

AuthFormSignError.propTypes = {
    errorMessage: authPropTypes.authErrorMessage,
};

AuthFormSignError.defaultProps = {
    errorMessage: undefined,
};

export default AuthFormSignError;
