import { rem } from 'polished';
import styled from 'styled-components';

const CreateFavePageOptionContainer = styled.div`
    flex: 1;
    margin: ${rem(16)};
    position: relative;
`;

export default CreateFavePageOptionContainer;
