import { siteContainer } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const PageHeaderInner = styled.div`
    ${siteContainer}
    padding: 0 ${rem(32)};
`;

export default PageHeaderInner;
