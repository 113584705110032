import React from 'react';
import PropTypes from 'prop-types';
import {
    OuterWrapper,
    PageContentWrapper,
    SiteContentWrapper,
    Container,
    Row,
    MainWrapper,
    BannerWrapper,
} from 'baseline-ui/layout';
import AdminMain from './AdminMain';
import { MenuContainer } from 'screens/superadmin/categories/components';

const AdminDefaultTemplate = ({ main, menu, bgStart, bgEnd, banner, footer, isFullWidth }) => {
    return (
        <OuterWrapper>
            <PageContentWrapper bgStart={bgStart} bgEnd={bgEnd}>
                <BannerWrapper>{banner}</BannerWrapper>
                {menu && <MenuContainer>{menu}</MenuContainer>}
                <SiteContentWrapper isFullWidth={isFullWidth}>
                    <Container>
                        <Row>
                            <MainWrapper>
                                <AdminMain hasBanner={banner}>{main}</AdminMain>
                            </MainWrapper>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            </PageContentWrapper>
            {footer}
        </OuterWrapper>
    );
};

AdminDefaultTemplate.propTypes = {
    bgStart: PropTypes.string.isRequired,
    bgEnd: PropTypes.string,
    main: PropTypes.element,
    banner: PropTypes.element,
    footer: PropTypes.element,
    isFullWidth: PropTypes.bool,
};

AdminDefaultTemplate.defaultProps = {
    bgEnd: null,
    main: null,
    banner: null,
    footer: null,
    isFullWidth: false,
};

export default AdminDefaultTemplate;
