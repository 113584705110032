export const GET_LINK_NOTIFICATIONS_SUCCESS = "GET_LINK_NOTIFICATIONS_SUCCESS";
export const GET_LINK_NOTIFICATIONS_PROCESS = "GET_LINK_NOTIFICATIONS_PROCESS";
export const GET_LINK_NOTIFICATIONS_START = "GET_LINK_NOTIFICATIONS_START";
export const RESET_LINK_NOTIFICATIONS_START = "RESET_LINK_NOTIFICATIONS_START";
export const PUT_LINK_NOTIFICATIONS_PROCESS = "PUT_LINK_NOTIFICATIONS_PROCESS";
export const PUT_LINK_NOTIFICATIONS_START = "PUT_LINK_NOTIFICATIONS_START";
export const SET_LINK_NOTIFICATIONS_NEXT_PAGE_PROCESS = "SET_LINK_NOTIFICATIONS_NEXT_PAGE_PROCESS";
export const SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS = "SET_LINK_NOTIFICATIONS_NEXT_PAGE_SUCCESS";
export const SET_LINK_NOTIFICATIONS_NEXT_PAGE_START = "SET_LINK_NOTIFICATIONS_NEXT_PAGE_START";
export const GET_ALL_LINK_NOTIFICATIONS_SUCCESS = "GET_ALL_LINK_NOTIFICATIONS_SUCCESS";
export const GET_ALL_LINK_NOTIFICATIONS_PROCESS = "GET_ALL_LINK_NOTIFICATIONS_PROCESS";
export const GET_ALL_LINK_NOTIFICATIONS_START = "GET_ALL_LINK_NOTIFICATIONS_START";