import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FieldContainer, FormInput, FormSelect } from 'baseline-ui/form';
import { Button } from 'baseline-ui/button';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessLoader from 'utils/useProcessLoader';
import {
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_DESTINATION,
    ENTITY_TYPE_PEOPLE,
    SEARCH_BUSINESSES_ANY_STATUS,
} from 'common/consts';
import businessUtils from 'utils/businessUtils';
import FavePageAsideSearchContainer from './FavePageAsideSearchContainer';
import { debounce } from 'lodash';
import useGoogleMapsAutocomplete from "utils/useGoogleMapsAutocomplete";
import {useRef} from "react";
import {GaIcon} from "baseline-ui/icon";
import {
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    GET_SEARCH_CATEGORIES_START
} from "../../redux/actions/CategoriesActions";
import {
    allCategoriesAndFiltersSelector,
    getSubcategoryById,
    getSubcategoryFilters
} from "redux/selectors/businessFormSelectors";
import {useState} from "react";
import categoriesAndFiltersUtils from "utils/categoriesAndFiltersUtils";
import CategoriesSelect from "../CategoriesSelect";
import {Box, Checkbox, ListItemText, MenuItem} from "@mui/material";
import categoryAndFiltersUtils from "utils/categoriesAndFiltersUtils";

const FavePageAsideSearch = ({ label, searchType, searchTypeProcess }) => {
    const dispatch = useDispatch();
    const isComplete = useProcessComplete({ processNames: [searchTypeProcess] });
    const isLoading = useProcessLoader({ processNames: [searchTypeProcess] });
    const { values, setFieldValue, getFieldMeta } = useFormikContext();
    const categories = useSelector(allCategoriesAndFiltersSelector);
    const [categoryId, setCategoryId] = useState(values.category || '');
    const [subcategoryId, setSubcategoryId] = useState(values.subcategory || '');
    const subcategoryFilters = useSelector((state) =>
        getSubcategoryFilters(state, subcategoryId || categoryId),
    );
    const subcategories = useSelector((state) => getSubcategoryById(state, categoryId));
    const initialSelectedFilters = typeof values.filter === 'object' ? categoriesAndFiltersUtils.initializeCategoryFiltersWhenSearch(values.filter) : {};
    const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
    const isAdmin = useSelector(isAdminSelector);
    const intl = useIntl();
    const ref = useRef();
    const type = values.entityType;
    const filters = categoriesAndFiltersUtils.extractFilterIds(values.category, values.subcategory, values.filter);
    const entityTypeMeta = getFieldMeta('entityType');
    useGoogleMapsAutocomplete(ref, ['(cities)']);
    useEffect(() => {
        // Debounce search
        const triggerSearch = debounce((searchQuery, approvalstatus, type, cityLocation, filters, category, subcategory, filter) => {
            const page = 0;
            dispatch({
                type: searchType,
                payload: {
                    page,
                    searchQuery,
                    approvalstatus,
                    type,
                    cityLocation,
                    filters,
                    category,
                    subcategory,
                    filter
                },
            });            
        }, 1000);
        triggerSearch(values.searchFavePages, values.entityStatus, values.entityType, values.searchBusinessCity, filters, values.category, values.subcategory, values.filter);
        return () => {
            triggerSearch.cancel();
        };
    }, [values, dispatch, searchType ]);

    useEffect(() => {
        dispatch({
            type: GET_SEARCH_CATEGORIES_START,
            payload: {
                type
            }
        });
    }, [type]);

    useEffect(() => {
        if (entityTypeMeta.initialValue !== entityTypeMeta.value) {
            setCategoryId('');
            setSubcategoryId('');
            setFieldValue('category', '');
            setFieldValue('subcategory', '');
            setFieldValue('filter', {});
        }
    }, [entityTypeMeta.value]);

    useEffect(() => {
        if(values.filter){
            setSelectedFilters(initialSelectedFilters);
        }
        if(!values.category){
            setCategoryId('');
            setSubcategoryId('');
        }
    }, [values.filter, values.category]);

    const STATE_SEARCH_OPTIONS = useMemo(() => {
        return [
            {
                value: SEARCH_BUSINESSES_ANY_STATUS,
                label: 'Any status',
            },
            {
                value: 'DRAFT',
                label: 'Draft',
            },
            {
                value: 'PENDING',
                label: 'Pending',
            },

            {
                value: 'APPROVED',
                label: 'Approved',
            },
            {
                value: 'DENIED',
                label: 'Denied',
            },
            {
                value: 'NO_PHOTOS',
                label: 'No Photos',
            },
            {
                value: 'OUT_OF_BUSINESS',
                label: 'Out of Business',
            },
        ];
    }, []);

    const ENTITY_TYPE_OPTIONS = useMemo(() => {
        return [
            {
                value: ENTITY_TYPE_DESTINATION,
                label: `myBusinesses.page.selectEntityType.${ENTITY_TYPE_DESTINATION}.label`,
            },
            {
                value: ENTITY_TYPE_BUSSINESS,
                label: `myBusinesses.page.selectEntityType.${ENTITY_TYPE_BUSSINESS}.label`,
            },
            {
                value: ENTITY_TYPE_PEOPLE,
                label: `myBusinesses.page.selectEntityType.${ENTITY_TYPE_PEOPLE}.label`,
            },
        ];
    }, []);
    const handleCategoryChange = (e, setFieldValue) => {
        const categoryId = e.target.value;
        setCategoryId(categoryId);
        setFieldValue('category', categoryId);
        setSubcategoryId('');
        setFieldValue('subcategory', '');
        setFieldValue('filter', {});
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId },
        });
        setSelectedFilters({});
    };
    const handleSubcategoryChange = (e, setFieldValue) => {
        const subcategoryId = e.target.value;
        setSubcategoryId(subcategoryId);
        setFieldValue('subcategory', subcategoryId);
        setFieldValue('filter', {});
        if(subcategoryId !== ''){
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId, categoryId },
            });
            setSelectedFilters({});
        }

    };
    const handleCheckboxToggle = (headerId, filterId) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [headerId]: {
                ...(prevFilters[headerId] || {}),
                [filterId]: !(prevFilters[headerId]?.[filterId] || false)
            }
        }));
    };
    const isChecked = (headerId, filterId) => {
        return selectedFilters[headerId]?.[filterId] || false;
    };
    return (
        <FavePageAsideSearchContainer>
            <FieldContainer>
                <Field
                    component={FormInput}
                    label={label}
                    name="searchFavePages"
                    id="searchFavePages"
                    type="text"
                />
                <Button
                    skin="muted"
                    btnType="link"
                    icon="x-circle"
                    type="button"
                    disabled={!isComplete && isLoading}
                    size="sm"
                    onClick={() => { businessUtils.resetSearchFavePagesForm(setFieldValue); }}
                >
                    <FormattedMessage id="myBusinesses.page.clearSearch.button.label" />
                </Button>
                {isAdmin && (
                    <Field
                        translateOptionsLabel={false}
                        component={FormSelect}
                        id="entityStatus"
                        name="entityStatus"
                        label="Entity status"
                        options={STATE_SEARCH_OPTIONS}
                    />
                )}
            </FieldContainer>
            <FieldContainer>
                <Field
                    component={FormSelect}
                    id="entityType"
                    name="entityType"
                    label={intl.formatMessage({ id: 'myBusinesses.page.selectEntityType.label' })}
                    options={ENTITY_TYPE_OPTIONS}
                />
            </FieldContainer>
            {isAdmin && (
                <FieldContainer>
                    <Field
                        component={FormInput}
                        label={intl.formatMessage({
                            id: 'myBusinesses.page.searchBusinessAddress.label',
                        })}
                        name="searchBusinessCity"
                        id="searchBusinessCity"
                        type="text"
                        innerRef={ref}
                        placeholder=""
                        required={false}
                        prependAddon={
                            <GaIcon icon="search" size={14} />
                        }
                        autoComplete="off"
                    />
                </FieldContainer>
                )}
            {isAdmin && (
                <FieldContainer>
                    <CategoriesSelect
                        name="category"
                        fullWidth
                        displayEmpty
                        value={values.category}
                        label={intl.formatMessage({
                            id: 'myBusinesses.page.category.label',
                        })}
                        onChange={(e) => handleCategoryChange(e, setFieldValue)}
                    >
                        <MenuItem value="">
                            <em>Select a category</em>
                        </MenuItem>
                        {categories?.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </CategoriesSelect>
                </FieldContainer>
            )}

            {isAdmin && subcategories && subcategories.length > 0 && (
                <FieldContainer>
                    <CategoriesSelect
                        name="subcategory"
                        label={intl.formatMessage({
                            id: 'myBusinesses.page.subcategory.label',
                        })}
                        fullWidth
                        displayEmpty
                        value={values.subcategory}
                        onChange={(e) =>
                            handleSubcategoryChange(e, setFieldValue)
                        }
                    >
                        <MenuItem value="">
                            <em>Select a subcategory</em>
                        </MenuItem>
                        {subcategories.map((subcategory) => (
                            <MenuItem
                                key={subcategory._id}
                                value={subcategory._id}
                            >
                                {subcategory.name}
                            </MenuItem>
                        ))}
                    </CategoriesSelect>
                </FieldContainer>
            )}
            <FieldContainer>
            {(isAdmin && subcategoryFilters && subcategoryFilters.length > 0) &&
                subcategoryFilters.map(
                        (header, index) => {
                            const selectedValue = categoryAndFiltersUtils.processFilterValue(values, index, header, selectedFilters);
                            const availableOptions = categoryAndFiltersUtils.getAvailableOptions(header);
                            return (
                                header.filters.length > 0 && (
                                    <Box marginBottom={2} key={index}>
                                        <CategoriesSelect
                                            name={`filter[${index}]`}
                                            fullWidth
                                            displayEmpty
                                            value={selectedValue }
                                            multiple
                                            label={header.name}
                                            renderValue={(selected) => (
                                                <div>
                                                    {Array.isArray(selected)
                                                        ? selected.map(value => {
                                                            const selectedItem = availableOptions.find(option => option.value === value);
                                                            return selectedItem ? selectedItem.label : '';
                                                        }).join(', ')
                                                        : selected.label
                                                    }
                                                </div>
                                            )}
                                        >
                                            <MenuItem value="">
                                                <em>Select {header.name}</em>
                                            </MenuItem>
                                            {header.filters.map((filter) => {
                                                return (
                                                    <MenuItem
                                                        key={filter._id}
                                                        value={`${header._id},${filter._id}`}
                                                        onClick={() => handleCheckboxToggle(header._id, filter._id)}
                                                    >
                                                        <Checkbox
                                                            checked={isChecked(header._id, filter._id)}
                                                        />
                                                        <ListItemText primary={filter.name} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </CategoriesSelect>
                                    </Box>
                                ));
                        }
                )}
            </FieldContainer>
        </FavePageAsideSearchContainer>
    );
};

FavePageAsideSearch.propTypes = {
    label: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    searchTypeProcess: PropTypes.string.isRequired,
};

FavePageAsideSearch.defaultProps = {};

export default FavePageAsideSearch;
