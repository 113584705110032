import React from 'react';
import PropTypes from 'prop-types';
import PageHeaderRibbon from './PageHeaderRibbon';
import PageHeaderTitle from './PageHeaderTitle';
import PageHeaderInner from './PageHeaderInner';

const PageHeader = ({ title }) => {
    return (
        <PageHeaderRibbon>
            <PageHeaderInner>{title && <PageHeaderTitle title={title} />}</PageHeaderInner>
        </PageHeaderRibbon>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
};

PageHeader.defaultProps = {
    title: undefined,
};

export default PageHeader;
