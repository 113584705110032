import { h5Styles } from 'baseline-ui/typography';
import { rem } from 'polished';
import styled from 'styled-components';

const ModalBrowseProductsFormListItemTitle = styled.span.attrs(() => ({
    color: 'secondary',
}))`
    ${h5Styles}
    margin-bottom: 0;
    margin-top: 0;
    padding-left: ${rem(4)};
`;

export default ModalBrowseProductsFormListItemTitle;
