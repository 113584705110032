import React from 'react';
import styled from 'styled-components';
import { Button } from 'baseline-ui/button';
import { GaIcon } from 'baseline-ui/icon';
import { rem } from 'polished';

// Menu components
export const MenuContainer = styled.div`
    width: 100%;
    height: 50px;
    top: ${({ theme }) => rem(theme.site.page.header.height)};
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 0.5rem;
`;

/// Lists
export const TierListComponent = styled.ul`
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    list-style-type: none;
    background-color: #f9f9f9;
`;

export const TierRootComponent = styled(TierListComponent)`
    margin: 1rem 0;
`;

export const TierItemComponent = styled.li`
    padding-top: 1px;
    padding-bottom: 1px;
    & > ul {
        padding-left: 1.5rem;
    }
    &:hover {
        background-color: #ececec;
    }
`;

// Tier Header
export const TierHeader = styled.div`
    display: flex;
`;

export const TierFlexPlaceholder = styled.span`
    flex: 1 0 auto;
`;
export const TierExpandButtonComponent = styled(Button).attrs((props) => {
    return {
        btnType: 'link',
        icon: props.icon ?? 'minus',
    };
})`
    padding: 0rem;
    margin: 0.2rem;
    margin-left: 0.5rem;
`;
export const TierType = styled.small`
    padding-top: 0.15rem;
    padding-left: 0.3rem;
    color: #777;
`;

export const HorizontalSpacer = styled.span`
    margin: 0 0.2rem;
`;

export const TierLabel = styled.span`
    cursor: pointer;
    font-weight: bold;
`;

export const TierBusyIcon = (props) => {
    // Wrap the icon in a span to allow for additional styling.
    return (
        <span {...props}>
            <GaIcon icon="loader" size={12} />
        </span>
    );
};

export const TierBusy = styled(TierBusyIcon)`
    padding: 0 0.3rem;
`;

// Tier Controls
export const TierControlsArea = styled.form`
    display: inline-block;
    visibility: hidden;
    ${TierItemComponent} > div > && {
        visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    }
    ${TierItemComponent}:hover > div > & {
        visibility: visible;
    }
`;

export const TierControlButton = styled(Button).attrs((props) => {
    return {
        btnType: 'link',
        icon: props.icon ?? 'box',
    };
})`
    padding: 0rem;
    margin: 0.2rem;
    margin-left: 0.5rem;
`;

export const TierControlEditButton = styled(TierControlButton).attrs({
    icon: 'edit',
})``;

export const TierControlAddButton = styled(TierControlButton).attrs({
    icon: 'plus-circle',
})``;

export const TierEditControl = styled.input.attrs(() => {
    return {
        type: 'text',
    };
})``;

export const TierControlDiscardButton = styled(TierControlButton).attrs({
    icon: 'x-circle',
})``;

export const TierControlSaveButton = styled(TierControlButton).attrs({
    icon: 'save',
})``;

export const TierControlsAreaSeparator = styled.span`
    &:before {
        content: '|';
        margin: 0 0.2rem;
        color: #888;
    }
`;

export const TierAddControls = styled(TierItemComponent)`
    margin-left: 0;
    border-bottom: thin solid rgba(0, 0, 0, 0.1);
`;
