import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { helpersUtils } from 'baseline-ui/helpers';
import Header from './header/Header';
import Collapse from './Collapse';
import Container from './accordion/Container';
import AccordionContainer from './accordion/AccordionContainer';
import Content from './accordion/Content';

const Accordion = ({ list, allowMultiple, icon, triggerColor, initialActive }) => {
    const [isActiveItem, setActiveItem] = useState({ [`${initialActive}`]: true });
    const [isActiveIndex, setActiveIndex] = useState(initialActive);
    const [itemId] = useState(helpersUtils.generateUniqueId());

    const toggleItem = (index) => {
        if (allowMultiple) {
            setActiveItem((prevState) => ({
                ...prevState,
                [index]: !prevState[index],
            }));
        }
        return setActiveIndex(isActiveIndex === index ? null : index);
    };

    return (
        <AccordionContainer>
            {list.map((item, index) => {
                let checkOpen;
                if (allowMultiple) {
                    checkOpen = isActiveItem[index];
                } else {
                    checkOpen = isActiveIndex === index;
                }

                return (
                    <Container key={index}>
                        <Header
                            title={item.title}
                            icon={icon}
                            id={index}
                            onClick={toggleItem}
                            allowMultiple={allowMultiple}
                            isOpen={checkOpen}
                            itemId={`${itemId}-${index}`}
                            triggerColor={triggerColor}
                        />
                        <Collapse isOpen={checkOpen} itemId={`${itemId}-${index}`}>
                            <Content>{item.content}</Content>
                        </Collapse>
                    </Container>
                );
            })}
        </AccordionContainer>
    );
};

Accordion.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node.isRequired,
            content: PropTypes.node.isRequired,
        }).isRequired,
    ).isRequired,
    icon: PropTypes.string,
    triggerColor: PropTypes.string,
    allowMultiple: PropTypes.bool,
    initialActive: PropTypes.number,
};

Accordion.defaultProps = {
    icon: undefined,
    triggerColor: 'inherit',
    allowMultiple: true,
    initialActive: null,
};

export default Accordion;
