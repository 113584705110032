import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const NavigationFooterMainLabelContainer = styled.div`
    padding-bottom: ${rem(8)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    width: 100%;

    ${media.sm`
        padding-bottom: 0;
    `}
`;

export default NavigationFooterMainLabelContainer;
