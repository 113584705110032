import React from 'react';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthFieldHeaderContainer } from 'baseline-ui/auth';
import { FieldContainer, FormInput, FormMaskedInput, FormTextarea } from 'baseline-ui/form';
import { H3 } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import ModalEditProductContentContainer from './ModalEditProductContentContainer';

const ModalEditProductInformation = () => {
    const intl = useIntl();

    return (
        <ModalEditProductContentContainer>
            <AuthFieldHeaderContainer>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.modal.editProduct.section.productInformation.title" />
                </H3>
            </AuthFieldHeaderContainer>
            <Row flexWrap="wrap" justifyContent="space-between">
                <Col xxs={30} sm={20}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({
                                id: 'addBusiness.modal.editProduct.form.productInformation.productName.label',
                            })}
                            name="name"
                            id="name"
                            type="text"
                            required
                            autoComplete="off"
                        />
                    </FieldContainer>
                </Col>
                <Col xxs={30} sm={9}>
                    <FieldContainer>
                        <Field
                            component={FormMaskedInput}
                            mask={Number}
                            scale={2}
                            padFractionalZeros
                            normalizeZeros
                            thousandsSeparator=","
                            radix="."
                            label={intl.formatMessage({
                                id: 'addBusiness.modal.editProduct.form.productInformation.price.label',
                            })}
                            name="price"
                            id="price"
                            autoComplete="off"
                            prependAddon="$"
                        />
                    </FieldContainer>
                </Col>
            </Row>
            <FieldContainer>
                <Field
                    component={FormTextarea}
                    label={intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.form.productInformation.description.label',
                    })}
                    name="description"
                    id="description"
                />
            </FieldContainer>
        </ModalEditProductContentContainer>
    );
};

export default ModalEditProductInformation;
