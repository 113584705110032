import React from 'react';
import PropTypes from 'prop-types';
import SliderDot from './SliderDot';
import carouselPropTypes from '../prop-types/carouselPropTypes';

const CarouselDot = ({ children, ...props }) => <SliderDot {...props}>{children}</SliderDot>;

CarouselDot.propTypes = {
    /** Type of navigational dots */
    children: PropTypes.node.isRequired,
    dotsType: carouselPropTypes.carouselDotsType,
};

CarouselDot.defaultProps = {
    dotsType: 'default',
};

export default CarouselDot;
