import { css } from 'styled-components';
import { get } from 'lodash';
import { lighten, darken, transparentize, rem } from 'polished';
import buttonTheme from './buttonTheme';

const buttonStyles = ({ themeMode, ...props }) => {
    const colorFunctionIsActive = themeMode === 'darkMode' ? lighten : darken;
    const colorFunctionHover = themeMode === 'darkMode' ? lighten : darken;
    const colorFunctionFocus = themeMode === 'darkMode' ? darken : lighten;

    return css`
        border: 1px solid transparent;
        border-radius: ${({ theme, sizeValue }) =>
            get(theme, `button.roundedCorners`, buttonTheme.button.roundedCorners)
                ? rem(
                      get(
                          theme,
                          `button.borderRadius[${sizeValue}]`,
                          buttonTheme.button.borderRadius[sizeValue],
                      ),
                  )
                : '0'};
        cursor: pointer;
        display: ${props.blockValue ? 'block' : 'inline-block'};
        font-family: ${({ theme }) =>
            get(theme, 'button.fontFamily', buttonTheme.button.fontFamily)};
        font-size: ${({ theme, sizeValue }) =>
            rem(
                get(theme, `button.fontSize[${sizeValue}]`, buttonTheme.button.fontSize[sizeValue]),
            )};
        font-weight: ${({ theme }) =>
            get(theme, 'button.fontWeight', buttonTheme.button.fontWeight)};
        line-height: ${({ theme }) =>
            get(theme, 'button.lineHeight', buttonTheme.button.lineHeight)};
        padding-bottom: ${({ theme, sizeValue }) =>
            rem(
                get(
                    theme,
                    `button.padding[${sizeValue}].v`,
                    buttonTheme.button.padding[sizeValue].v,
                ),
            )};
        padding-left: ${({ theme, sizeValue }) =>
            rem(
                get(
                    theme,
                    `button.padding[${sizeValue}].h`,
                    buttonTheme.button.padding[sizeValue].h,
                ),
            )};
        padding-right: ${({ theme, sizeValue }) =>
            rem(
                get(
                    theme,
                    `button.padding[${sizeValue}].h`,
                    buttonTheme.button.padding[sizeValue].h,
                ),
            )};
        padding-top: ${({ theme, sizeValue }) =>
            rem(
                get(
                    theme,
                    `button.padding[${sizeValue}].v`,
                    buttonTheme.button.padding[sizeValue].v,
                ),
            )};
        text-align: center;
        text-transform: ${({ theme }) =>
            get(theme, 'button.fontWeight', buttonTheme.button.fontWeight)};
        touch-action: manipulation;
        transition:
            background-color 0.15s ease-in-out,
            color 0.15s ease-in-out;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;

        &,
        &:hover,
        &:focus,
        &:active {
            outline: 0;
            text-decoration: none;
        }

        ${({ btnType }) => {
            return (
                btnType === 'default' &&
                css`
                    background-color: ${({ theme, skin }) =>
                        get(
                            theme,
                            `button[${skin}].background`,
                            buttonTheme.button[skin].background,
                        )};
                    border-color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].border`, buttonTheme.button[skin].border)};
                    color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].color`, buttonTheme.button[skin].color)};

                    &:hover {
                        background-color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                        border-color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(theme, `button[${skin}].color`, buttonTheme.button[skin].color),
                            )};
                    }

                    &:focus,
                    &:active {
                        background-color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                        border-color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(theme, `button[${skin}].color`, buttonTheme.button[skin].color),
                            )};
                    }
                `
            );
        }}

        ${({ btnType }) => {
                return (
                    btnType === 'navigation' &&
                    css`
                            background-color: ${({ theme, skin }) =>
                        get(
                            theme,
                            `button[${skin}].background`,
                            buttonTheme.button[skin].background,
                        )};
                            padding: 0.5rem 3.125rem;
                            border-color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].border`, buttonTheme.button[skin].border)};
                            color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].color`, buttonTheme.button[skin].color)};
        
                            &:hover {
                                background-color: ${({ theme, skin }) =>
                        colorFunctionHover(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                                border-color: ${({ theme, skin }) =>
                        colorFunctionHover(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].border`,
                                buttonTheme.button[skin].border,
                            ),
                        )};
                                color: ${({ theme, skin }) =>
                        colorFunctionHover(
                            0.05,
                            get(theme, `button[${skin}].color`, buttonTheme.button[skin].color),
                        )};
                            }
        
                            &:focus,
                            &:active {
                                background-color: ${({ theme, skin }) =>
                        colorFunctionFocus(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                                border-color: ${({ theme, skin }) =>
                        colorFunctionFocus(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].border`,
                                buttonTheme.button[skin].border,
                            ),
                        )};
                                color: ${({ theme, skin }) =>
                        colorFunctionFocus(
                            0.05,
                            get(theme, `button[${skin}].color`, buttonTheme.button[skin].color),
                        )};
                            }
                        `
                );
            }}
            
        ${({ btnType }) => {
            return (
                btnType === 'outlined' &&
                css`
                    background-color: transparent;
                    border-color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].border`, buttonTheme.button[skin].border)};
                    color: ${({ theme, skin }) =>
                        get(
                            theme,
                            `button[${skin}].background`,
                            buttonTheme.button[skin].background,
                        )};

                    &:hover {
                        border-color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                    }

                    &:focus,
                    &:active {
                        border-color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                    }
                `
            );
        }}

        ${({ btnType }) => {
            return (
                btnType === 'optional' &&
                css`
                    background-color: transparent;
                    border-color: ${({ theme, skin }) =>
                        get(theme, `button[${skin}].border`, buttonTheme.button[skin].border)};
                    border-style: dashed;
                    color: ${({ theme, skin }) =>
                        get(
                            theme,
                            `button[${skin}].background`,
                            buttonTheme.button[skin].background,
                        )};

                    &:hover {
                        border-color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionHover(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                    }

                    &:focus,
                    &:active {
                        border-color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].border`,
                                    buttonTheme.button[skin].border,
                                ),
                            )};
                        color: ${({ theme, skin }) =>
                            colorFunctionFocus(
                                0.05,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                    }
                `
            );
        }}

    ${({ btnType }) =>
            btnType === 'link' &&
            css`
                background-color: transparent;
                border: 0;
                color: ${({ theme, skin }) =>
                    get(theme, `button[${skin}].background`, buttonTheme.button[skin].background)};
                font-weight: 400;
                text-transform: inherit;

                &:hover {
                    color: ${({ theme, skin }) =>
                        colorFunctionHover(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                }

                &:focus,
                &:active {
                    color: ${({ theme, skin }) =>
                        colorFunctionFocus(
                            0.05,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                }
            `}
    

        
    ${({ btnType, isActive }) =>
            btnType === 'default' &&
            isActive &&
            css`
                &,
                &:hover,
                &:focus,
                &:active {
                    background-color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                    border-color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(theme, `button[${skin}].border`, buttonTheme.button[skin].border),
                        )};
                    color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(theme, `button[${skin}].color`, buttonTheme.button[skin].color),
                        )};
                }
            `}

    ${({ btnType, isActive }) =>
            btnType === 'outlined' &&
            isActive &&
            css`
                &,
                &:hover,
                &:focus,
                &:active {
                    border-color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(theme, `button[${skin}].border`, buttonTheme.button[skin].border),
                        )};
                    color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                }
            `}

    ${({ btnType, isActive }) =>
            btnType === 'link' &&
            isActive &&
            css`
                &,
                &:hover,
                &:focus,
                &:active {
                    color: ${({ theme, skin }) =>
                        colorFunctionIsActive(
                            0.25,
                            get(
                                theme,
                                `button[${skin}].background`,
                                buttonTheme.button[skin].background,
                            ),
                        )};
                }
            `}


    ${({ btnType }) =>
            btnType !== 'link' &&
            css`
                &:focus,
                &:active {
                    box-shadow: 0 0 2px 4px
                        ${({ theme, skin }) =>
                            transparentize(
                                0.8,
                                get(
                                    theme,
                                    `button[${skin}].background`,
                                    buttonTheme.button[skin].background,
                                ),
                            )};
                }
            `}

    ${({ icon }) =>
            icon &&
            css`
                &:hover {
                    fill: ${({ theme, skin }) =>
                        colorFunctionHover(
                            0.05,
                            get(theme, `button[${skin}].border`, buttonTheme.button[skin].border),
                        )};
                }

                &:focus,
                &:active {
                    fill: ${({ theme, skin }) =>
                        colorFunctionFocus(
                            0.05,
                            get(theme, `button[${skin}].border`, buttonTheme.button[skin].border),
                        )};
                }
            `}

    ${({ blockValue }) =>
            blockValue &&
            css`
                width: 100%;
            `}

    ${({ disabled }) =>
            disabled &&
            css`
                cursor: not-allowed;
                opacity: 0.65;
                pointer-events: none;
            `}
    `;
};

export default buttonStyles;
