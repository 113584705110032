import React, { useState, useEffect } from "react";
//import "./Form.css";
import "./Invest.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RecommendBusiness, GetCategories } from "./api";
import LocationFormPart from "./LocationFormPart";

function RecommendABusiness() {
  const [reqError, setReqError] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [submitted, setSubmitted] = useState(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  useEffect(() => {
    GetCategories().then((mapping) => {
      setCategories(mapping);
      setLoading(false);
    });
  }, []);

  const schema = Yup.object({
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    your_phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    zipcode: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    street: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    notes: Yup.string().required("Required"),
    contact_email: Yup.string().email("Invalid Email"),
    contact_phone: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid",
    ),
  });

  const {
    handleSubmit,
    handleChange,
    setSubmitting,
    handleBlur,
    isSubmitting,
    touched,
    errors,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      zipcode: "",
      state: "",
      city: "",
      street: "",
      suite: "",
      name: "",
      phone: "",
      category: "Select a category",
      website: "",
      notes: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_phone: "",
      your_phone: "",
    },
    validationSchema: schema,
    onSubmit: formSubmit,
  });

  async function formSubmit(values) {
    const businessData = await RecommendBusiness(values);
    if (!businessData?.errorMessage) {
      resetForm();
      setSubmitting(false);
      setSubmitted(true);
      setReqError("");
    } else {
      setReqError(businessData?.errorMessage);
    }
  }

  const categoryOptions = categories.map((cat) => {
    return <option value={cat.id}>{cat.name}</option>;
  });
  let hasErrors = Object.keys(errors).some(
    (error) => !!error && touched[error],
  );
  if (hasErrors && submitted) {
    setSubmitted(false);
  }
  return (
    <div className="Invest">
      <h1 className="invest-header">Recommend a Business</h1>
      {submitted ? (
        <div className="success-msg">
          The form has been submitted! You will be notified once it gets
          approved
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="form-entry">
              <label htmlFor="your_phone">
                Your Phone #<span className="required">*</span>
              </label>
              <input
                type="tel"
                name="your_phone"
                onChange={handleChange}
                className={
                  touched.your_phone && errors.your_phone ? "input-error" : ""
                }
                onBlur={handleBlur}
                value={values.your_phone}
              />
            </div>
          </div>
          <LocationFormPart
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
          />
          <div className="form-entry">
            <textarea
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notes}
              name="notes"
              cols={40}
              rows={10}
              className={touched.notes && errors.notes ? "input-error" : ""}
              placeholder="Notes:"
              spellCheck="false"
            ></textarea>
          </div>
          <h2 className="invest-header">Optional Business Info</h2>
          <div className="flex">
            <div className="form-entry">
              <label htmlFor="phone">Business Phone #</label>
              <input
                type="tel"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
            </div>
          </div>
          <div className="flex">
            <div className="form-entry">
              <label htmlFor="website">Business Website</label>
              <input
                type="text"
                name="website"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.website}
              />
            </div>
          </div>
          <div className="form-entry">
            <label htmlFor="category">Business Category</label>
            <select
              className="select-field"
              name="category"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.category}
              aria-invalid="false"
            >
              <option value="Select a category">Select a category</option>
              {categoryOptions}
            </select>
          </div>
          <div className="flex">
            <div className="form-entry">
              <label htmlFor="contact_first_name">Contact's First Name</label>
              <input
                type="text"
                name="contact_first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_first_name}
              />
            </div>
            <div className="form-entry">
              <label htmlFor="contact_last_name">Contact's Last Name</label>
              <input
                type="text"
                name="contact_last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_last_name}
              />
            </div>
          </div>
          <div className="flex">
            <div className="form-entry">
              <label htmlFor="contact_phone">Contact's Phone</label>
              <input
                type="tel"
                name="contact_phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_phone}
              />
            </div>
            <div className="form-entry">
              <label htmlFor="contact_email">Contact's Email</label>
              <input
                type="email"
                name="contact_email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_email}
              />
            </div>
          </div>

          {reqError ? (
            <div className="error-msg">
              There was an error submitting. Make sure your address is valid.
            </div>
          ) : null}
          {hasErrors ? (
            <div className="error-msg">
              One or more fields have an error. Please check and try again.
            </div>
          ) : null}
          <button
            className="submit"
            type="submit"
            disabled={isSubmitting || isLoading || hasErrors}
          >
            Submit
            {isSubmitting || isLoading ? (
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            ) : null}
          </button>
        </form>
      )}
    </div>
  );
}

export default RecommendABusiness;
