import { rem } from 'polished';
import styled from 'styled-components';

const ModalReoderSectionsListItem = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    border: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    cursor: grab;
    margin-bottom: ${rem(8)};
    padding: ${rem(16)};
    user-select: none;
    z-index: ${({ theme }) => theme.modal.content.zIndex + 1};
`;

export default ModalReoderSectionsListItem;
