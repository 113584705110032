import styled, { css } from 'styled-components';

const NavigationList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    ${({ isVertical }) =>
        isVertical &&
        css`
            display: flex;
            flex-direction: column;
        `}
`;

export default NavigationList;
