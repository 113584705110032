import React, { useContext, useMemo } from 'react';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { OverlayContext } from 'baseline-ui/overlay';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_SECTION_START } from 'redux/actions/SectionActions';
import {
    allSectionsSelector,
    selectedSectionIndexSelector,
} from 'redux/selectors/sectionSelectors';
import { FormattedMessage } from 'react-intl';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import ModalEditSectionFooterPostContainer from './ModalEditSectionFooterPostContainer';
import ModalDeleteSection from '../ModalDeleteSection';

const ModalEditSectionFooterPost = () => {
    const { showModal } = useContext(ModalContext);
    const { hideOverlay } = useContext(OverlayContext);
    const dispatch = useDispatch();
    const allSections = useSelector(allSectionsSelector);
    const selectedSectionIndex = useSelector(selectedSectionIndexSelector);

    const onDeleteSetSelectedSectionName = useMemo(() => {
        if (allSections.length === 1) {
            return allSections[0].name;
        }
        if (selectedSectionIndex > 0) {
            return allSections[selectedSectionIndex - 1].name;
        }
        return allSections[selectedSectionIndex + 1].name;
    }, [allSections, selectedSectionIndex]);

    if (allSections.length === 1) {
        return null;
    }

    return (
        <ModalEditSectionFooterPostContainer>
            <Button
                onClick={() =>
                    showModal(ModalDeleteSection, {
                        onClickDeleteSectionCallback: () => {
                            hideOverlay();
                            dispatch({
                                type: SET_BUSINESS_FORM_DATA_START,
                                payload: { hasUnsavedChanges: true },
                            });
                            dispatch({
                                type: SET_SELECTED_SECTION_START,
                                payload: onDeleteSetSelectedSectionName,
                            });
                        },
                    })
                }
                size="sm"
                skin="danger"
                btnType="link"
                textAlign="right"
                type="button"
            >
                <FormattedMessage id="addBusiness.modal.editSectionName.footerPost.delete.label" />
            </Button>
        </ModalEditSectionFooterPostContainer>
    );
};

export default ModalEditSectionFooterPost;
