import { ENTITY_TYPE_PEOPLE } from 'common/consts';
import { pick, sortBy } from 'lodash';
import { createSelector } from 'reselect';

export const businessFormRequiredFieldKeys = (entityType) => {
    if (entityType === ENTITY_TYPE_PEOPLE) {
        return ['ownerFirstName', 'ownerLastName', 'phone', 'privatephone', 'email'];
    }
    return [
        'name',
        'formattedAddress',
        'ownerFirstName',
        'ownerLastName',
        'position',
        'phone',
        'privatephone',
        'email',
    ];
};

export const businessFormRequiredApprovalFieldKeys = (entityType) => [
    ...businessFormRequiredFieldKeys(entityType),
    'selectedCategories',
];

export const businessFormSelector = (state) => state.BusinessFormReducer;

export const businessFormRequiredFieldSelector = createSelector(
    businessFormSelector,
    (businessForm) => pick(businessForm, businessFormRequiredFieldKeys(businessForm.type)),
);

export const businessFormRequiredApprovalFieldSelector = createSelector(
    businessFormSelector,
    (businessForm) => pick(businessForm, businessFormRequiredApprovalFieldKeys(businessForm.type)),
);

const businessCategoriesSelectorState = (state) => state.BusinessFormReducer.selectedCategories;

export const businessCategoriesSelector = createSelector(
    businessCategoriesSelectorState,
    (businessCategories) => sortBy(businessCategories, ['name']),
);

const allCategoriesAndFiltersSelectorState = (state) => state.CategoriesReducer.businessCategories;

export const allCategoriesAndFiltersSelector = createSelector(
    allCategoriesAndFiltersSelectorState,
    (allCategoriesAndFilters) => {
        return sortBy(allCategoriesAndFilters, ['name']);
    },
);

export const getBusinessSubcategoriesSelector = (state) => state.CategoriesReducer.businessSubcategories;

export const getSubcategoryById = createSelector(
    [getBusinessSubcategoriesSelector, (state, categoryId) => categoryId],
    (subcategories, categoryId) => {
        // Retrieve the subcategory for the given categoryId
        const subcategory = subcategories[categoryId];
        return subcategory ? sortBy(subcategory, ['name']) : [];
    }
);

const getBusinessSubcategoryFilters = (state) => state.CategoriesReducer.businessSubcategoryFilters;
export const getSubcategoryFilters = createSelector(
    [getBusinessSubcategoryFilters, (state, subcategoryId) => subcategoryId],
    (subcategoryFilters, subcategoryId) => {
        const subcategoryFilter = subcategoryFilters[subcategoryId];

        return subcategoryFilter ? sortBy(subcategoryFilter, ['name']) : [];
    }
);

export const businessIdSelector = createSelector(
    businessFormSelector,
    (businessForm) => businessForm._id,
);
