import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import carouselPropTypes from '../prop-types/carouselPropTypes';
import SliderArrow from './SliderArrow';
import ArrowIcon from './ArrowIcon';

const CarouselArrow = (props) => {
    const {
        onClick,
        slide,
        arrowType,
        arrowCustomColor,
        className,
        arrowSize,
        labelId,
        children,
        rtl,
        intl,
        vertical,
    } = props;

    return (
        <SliderArrow
            className={className}
            onClick={onClick}
            slide={slide}
            vertical={vertical}
            arrowType={arrowType}
            {...props}
        >
            {slide === 'next' && (
                <>
                    <ArrowIcon
                        arrowSize={arrowSize}
                        vertical={vertical}
                        arrowType={arrowType}
                        arrowCustomColor={arrowCustomColor}
                        slide="next"
                        label={intl.formatMessage({
                            id: labelId,
                        })}
                        rtl={rtl}
                    />
                    {children}
                </>
            )}
            {slide === 'prev' && (
                <>
                    <ArrowIcon
                        arrowSize={arrowSize}
                        vertical={vertical}
                        arrowType={arrowType}
                        arrowCustomColor={arrowCustomColor}
                        slide="prev"
                        label={intl.formatMessage({
                            id: labelId,
                        })}
                    />
                    {children}
                </>
            )}
        </SliderArrow>
    );
};

CarouselArrow.propTypes = {
    intl: PropTypes.shape().isRequired,
    /** Type of navigational arrow, whether previous or next button  */
    slide: carouselPropTypes.carouselArrowDirection.isRequired,
    /** Type of arrow */
    arrowType: carouselPropTypes.carouselArrowType,
    /** Size of the arrows */
    arrowSize: carouselPropTypes.carouselArrowSize,
    /** Custom color of arrow  */
    arrowCustomColor: PropTypes.string,
    /** String ID of text for the label of the arrow button, for screen reader only */
    labelId: PropTypes.string.isRequired,
    /** Change the slider's direction to become right-to-left */
    rtl: PropTypes.bool,
    /** Children can include any other component */
    children: PropTypes.node,
    /** Arrow action */
    onClick: PropTypes.func,
    /** Defined in order to pass react-slick styles  */
    className: PropTypes.string,
    /** Vertical content */
    vertical: PropTypes.bool,
};

CarouselArrow.defaultProps = {
    arrowSize: 'default',
    arrowType: 'default',
    arrowCustomColor: null,
    rtl: false,
    children: null,
    onClick: null,
    className: null,
    vertical: false,
};

export default injectIntl(CarouselArrow);
