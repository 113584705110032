import CommonWrapper from "../../CommonWrapper";
import React from "react";
import {FormattedMessage} from "react-intl";
import ExtraLargeH1 from "baseline-ui/typography/src/components/ExtraLargeH1";
import UpdatesWrapper from "./UpdatesWrapper";

const UpdatesPageRoute = () => {
    return(
        <CommonWrapper>
            <UpdatesWrapper>
                <ExtraLargeH1 colorValue='primary' fontWeight={700} fontSize={54}>
                    <FormattedMessage id="auth.signUp.heading.title" />
                </ExtraLargeH1>
            </UpdatesWrapper>
        </CommonWrapper>
    )
}

export default UpdatesPageRoute;