import styled from 'styled-components';
import { H3 } from 'baseline-ui/typography';

const ModalEditProductHeaderTitle = styled(H3).attrs(({ color }) => ({
    color,
}))`
    margin: 0;
`;

export default ModalEditProductHeaderTitle;
