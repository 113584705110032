import React from 'react';
import PropTypes from 'prop-types';
import { baseColorData } from 'baseline-ui/base-theme';
import Container from './Container';
import DashContainer from './DashContainer';
import Dash from './Dash';
import LoaderMessage from './LoaderMessage';

const Loader = ({ label, skin, isFullScreen, fullScreenBackgroundColor, size, offsetH }) => (
    <Container
        isFullScreen={isFullScreen}
        size={size}
        backgroundColor={fullScreenBackgroundColor}
        offsetH={offsetH}
    >
        <DashContainer skin={skin} isFullScreen={isFullScreen}>
            <Dash skin={skin} />
        </DashContainer>
        {label && <LoaderMessage color="muted">{label}</LoaderMessage>}
    </Container>
);

Loader.propTypes = {
    label: PropTypes.string,
    isFullScreen: PropTypes.bool,
    skin: PropTypes.oneOf(baseColorData),
    size: PropTypes.number,
    fullScreenBackgroundColor: PropTypes.string,
    offsetH: PropTypes.number,
};

Loader.defaultProps = {
    label: undefined,
    isFullScreen: false,
    skin: undefined,
    size: undefined,
    fullScreenBackgroundColor: undefined,
    offsetH: undefined,
};

export default Loader;
