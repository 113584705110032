import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize, rem } from 'polished';
import tabsTheme from '../../styles/tabsTheme';

const Item = styled.button.attrs(() => ({
    type: 'button',
}))`
    background-color: ${({ theme }) =>
        get(theme, 'tabs.tab.backgroundColor', tabsTheme.tabs.tab.backgroundColor)};
    border-bottom-color: ${({ theme }) =>
        get(theme, 'tabs.tab.borderColor', tabsTheme.tabs.tab.borderColor)};
    border-style: solid;
    border-width: 0 0 ${rem(2)};
    color: ${({ theme }) =>
        transparentize(0.15, get(theme, 'tabs.tab.color', tabsTheme.tabs.tab.color))};
    cursor: pointer;
    font-family: ${({ theme }) =>
        get(theme, `tabs.title.fontFamily`, tabsTheme.tabs.title.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'tabs.title.fontSize', tabsTheme.tabs.title.fontSize))};
    font-weight: ${({ theme }) =>
        get({ theme }, `theme.tabs.title.fontWeight`, tabsTheme.tabs.title.fontWeight)};
    line-height: 1.2;
    min-height: ${({ theme }) =>
        rem(get(theme, 'tabs.tab.minHeight', tabsTheme.tabs.tab.minHeight))};
    min-width: ${({ minWidth }) => rem(minWidth)};
    padding: ${rem(4)} ${rem(16)};
    text-transform: ${({ theme }) =>
        get(theme, 'tabs.title.uppercase', tabsTheme.tabs.title.uppercase) ? 'uppercase' : 'none'};
    user-select: none;

    &:focus {
        outline: 0;
    }

    ${({ justified }) =>
        justified &&
        css`
            max-width: none;
            width: 100%;
        `}

    ${({ selected }) =>
        selected &&
        css`
            border-bottom-color: ${({ theme }) =>
                get(theme, 'tabs.tab.selectedBorderColor', tabsTheme.tabs.tab.selectedBorderColor)};
            color: ${({ theme }) => get(theme, 'tabs.tab.color', tabsTheme.tabs.tab.color)};
            transition:
                border-bottom-color 0.15s ease-in-out,
                color 0.15s ease-in-out;
        `}

    ${({ maxWidth }) => {
        if (maxWidth) {
            return css`
                max-width: ${rem(maxWidth)};
            `;
        }
        return css`
            white-space: nowrap;
        `;
    }}
`;

export default Item;
