import { baseDarkTheme } from 'baseline-ui/base-theme';
import modalTheme from './modalTheme';

const modalDarkTheme = {
    modal: {
        ...modalTheme.modal,
        backgroundColor: baseDarkTheme.base.colors.light,
        footer: {
            backgroundColor: baseDarkTheme.base.colors.light,
            dividerColor: baseDarkTheme.base.grays.grayLighter,
        },
        content: {
            boxShadowColor: 'rgb(255, 255, 255, 0.04)',
        },
        overlay: {
            color: 'rgba(0, 0, 0, 0.75)',
        },
        closeIcon: {
            size: 28,
            color: baseDarkTheme.base.grays.grayLight,
            icon: 'x',
        },
    },
};

export default modalDarkTheme;
