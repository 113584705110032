import styled, { css } from 'styled-components';
import { rem } from 'polished';

const LabelContainer = styled.span`
    ${({ hasIcon, sizeValue, iconPostLabel }) => {
        if (hasIcon && sizeValue) {
            return iconPostLabel
                ? css`
                      margin-right: ${rem(8)};
                  `
                : css`
                      margin-left: ${rem(8)};
                  `;
        }
        return css``;
    }};
`;

export default LabelContainer;
