import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import typographyTheme from '../styles/typographyTheme';
import paragraphStyles from '../styles/paragraphStyles';
import paragraphLeadStyles from '../styles/paragraphLeadStyles';

const Paragraph = styled.p`
    ${paragraphStyles}

    margin-bottom: ${({ theme }) =>
        rem(
            get(theme, 'typography.paragraph.margin', typographyTheme.typography.paragraph.margin),
        )};
    ${({ lead }) =>
        lead &&
        css`
            ${paragraphLeadStyles}
        `}
`;

export default Paragraph;
