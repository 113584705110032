import styled from 'styled-components';
import { rem } from 'polished';
import {authTheme} from "../../index";
import { get } from 'lodash';

const AuthWrapper = styled.div`
    padding: ${rem(40)} ${rem(24)};
    border: 1px solid 
    ${({ theme }) => get(theme, 'auth.border.color', authTheme.auth.border.color)}; 
    border-radius: ${({ theme }) => rem(authTheme.auth.border.borderRadius)};
`;

export default AuthWrapper;