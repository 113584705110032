import hearOnly from 'images/heart-only-empty.svg';
import { rem } from 'polished';

const splitAsideMainTemplateBgImage = {
    image: hearOnly,
    position: `calc(100vw - ${rem(300)}) calc(100vh - ${rem(360)})`,
    repeat: false,
    size: { width: rem(600), height: rem(600) },
    opacity: 0.1,
};

export default splitAsideMainTemplateBgImage;
