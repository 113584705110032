import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { baseTheme } from 'baseline-ui/base-theme';
import { GaIcon } from 'baseline-ui/icon';
import ScrollButton from './ScrollButton';
import horizontalScrollTheme from '../styles/horizontalScrollTheme';

const ScrollAction = ({
    showStart,
    showEnd,
    btnWidth,
    handleClick,
    btnColor,
    iconColor,
    iconSize,
}) => {
    const theme = useContext(ThemeContext);
    const renderIconSize =
        iconSize ||
        get(
            theme,
            'horizontalScroll.scrollbar.icon.size',
            horizontalScrollTheme.horizontalScroll.scrollbar.icon.size,
        );

    const renderIconColor = iconColor || get(theme, 'base.grays.gray', baseTheme.base.grays.gray);

    const renderButtonWidth =
        btnWidth ||
        get(
            theme,
            'horizontalScroll.scrollbar.button.width',
            horizontalScrollTheme.horizontalScroll.scrollbar.button.width,
        );

    return (
        <ScrollButton
            showStart={showStart}
            showEnd={showEnd}
            btnWidth={renderButtonWidth}
            onClick={handleClick}
            btnColor={btnColor}
            type="button"
        >
            {showStart && (
                <GaIcon icon="chevron-left" size={renderIconSize} color={renderIconColor} />
            )}
            {showEnd && (
                <GaIcon icon="chevron-right" size={renderIconSize} color={renderIconColor} />
            )}
        </ScrollButton>
    );
};

ScrollAction.propTypes = {
    showStart: PropTypes.bool,
    showEnd: PropTypes.bool,
    btnWidth: PropTypes.number,
    handleClick: PropTypes.func.isRequired,
    btnColor: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
};

ScrollAction.defaultProps = {
    showStart: false,
    showEnd: false,
    btnWidth: undefined,
    btnColor: undefined,
    iconColor: undefined,
    iconSize: undefined,
};

export default ScrollAction;
