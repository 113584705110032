import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

import AdminBusinessAdvertisingAddCityField from './AdminBusinessAdvertisingAddCityField';

const AdminBusinessAdvertisingAddCity = ({ id }) => {
    const renderListId = `${id}CityList`;

    return (
        <FieldArray
            name={renderListId}
            render={(arrayHelpers) => (
                <AdminBusinessAdvertisingAddCityField id={id} arrayHelpers={arrayHelpers} />
            )}
        />
    );
};

AdminBusinessAdvertisingAddCity.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AdminBusinessAdvertisingAddCity;
