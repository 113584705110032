import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewAppHeaderContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: ${rem(50)};
    justify-content: center;
    width: 100%;
`;

export default FavePagePreviewAppHeaderContent;
