import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalAccessRightsRemoveUser = ({
    onRequestClose,
    confirmDeleteCallback,
    emailAddress,
    ...props
}) => {
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.deleteUser.title' })}
            handleOnDismiss={onRequestClose}
            size="sm"
            {...props}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deleteUser.footerActions.notNow.button.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => {
                        onRequestClose();
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.deleteUser.footerActions.delete.button.label',
                    }),
                    onClick: () => {
                        confirmDeleteCallback();
                        onRequestClose();
                    },
                },
            ]}
        >
            <ContentRenderer
                source={intl.formatMessage(
                    {
                        id: 'addBusiness.modal.deleteUser.content.md',
                    },
                    { emailAddress },
                )}
            />
        </Modal>
    );
};

ModalAccessRightsRemoveUser.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    confirmDeleteCallback: PropTypes.func.isRequired,
    emailAddress: PropTypes.string.isRequired,
};

export default ModalAccessRightsRemoveUser;
