import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const useProcessErrorMessage = ({ processName }) => {
    const intl = useIntl();

    const processError = useSelector(({ ErrorReducer }) => ErrorReducer[processName]);

    if (!processError) {
        return null;
    }

    const { code } = processError;
    const { errorCode, errorMessage } = code;
    let responseText;
    try {
        responseText = JSON.parse(code.responseText);
    } catch (e) {
        responseText = code.responseText;
    }

    const renderStatus = typeof responseText === 'object' ? responseText.status : responseText;

    return {
        errorDescription: !isEmpty(responseText)
            ? renderStatus
            : intl.formatMessage({
                  id: `error.${processName}.${errorCode}.errorDescription`,
              }),
        errorType: errorMessage,
    };
};

export default useProcessErrorMessage;
