import React from 'react';
import { FormSignUp } from 'baseline-ui/auth';
import {FormattedMessage, useIntl} from 'react-intl';
import {ABOUT_PATH, BUSINESS_PATH, LOGIN_PATH} from 'common/pathnames';
import { useDispatch } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import {ContentRenderer, Image} from 'baseline-ui/helpers';
import CommonWrapper from './CommonWrapper';
// TODO remove component
import { POST_SIGNUP_PROCESS, POST_SIGNUP_START } from '../redux/actions/AuthenticationActions';
import { useLoading } from '../common/useLoading';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import WelcomeWrapper from "./Welcome/WelcomeWrapper";
import heartBg from 'images/heart_white_background.png'
import SignUpIntro from './Welcome/HomePage/SignUpIntro';
import {H1, H3, H4, H5} from 'baseline-ui/typography';
import ExtraLargeH1 from '../baseline-ui/typography/src/components/ExtraLargeH1';
import FavePageText from './Welcome/HomePage/FavePageText';
import LandingIntroWrapper from './Welcome/HomePage/LandingIntroWrapper';
import ButtonWrapper from '../baseline-ui/button/src/components/ButtonWrapper';
import {Button, LinkButton} from '../baseline-ui/button';
import VideoWrapper from './Welcome/HomePage/VideoWrapper';
import Video from './Welcome/HomePage/Video';
import LaunchWrapper from './Welcome/HomePage/LaunchWrapper';
import {LOGO_ONLY_EMPTY} from 'images';
import triangleLeft from 'images/Triangle-left.svg';
import triangleRight from 'images/Triangle-right.svg';
import leftArrow from 'images/leftArrow.svg';
import rightArrow from 'images/rightArrow.svg';
import triangle from 'images/small-triangle.svg';
import heartPart from 'images/heart-side.svg';
import Separator from '../components/Separator';
import Container from "../components/Container";
import ScrollButtonWrapper from "./Welcome/HomePage/ScrollButtonWrapper";
import SignUpTitleWrapper from "./Welcome/HomePage/SignUpTitleWrapper";
import SearchShareWrapper from "./Welcome/HomePage/SearchShareWrapper";
import Bullet from "./Welcome/HomePage/Bullet";
import Label from "../components/Label";
import ContentWrapper from "./Welcome/HomePage/ContentWrapper";

const SignUpRoute = () => {
    const intl = useIntl();
    const isLoading = useLoading(POST_SIGNUP_PROCESS);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = useProcessErrorMessage({ processName: POST_SIGNUP_PROCESS });
    const handleOnSubmitSignUp = (values) => {
        dispatch({
            type: POST_SIGNUP_START,
            payload: {
                ...values,
                navigate,
            },
        });
    };
    const scrollToSection = () => {
        const section = document.getElementById('homeIntroSection');
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionTop - 90;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };
    return (
        <CommonWrapper>
            <WelcomeWrapper afterImage={heartBg}>
                <SignUpIntro>
                    <ExtraLargeH1 colorValue='light' fontWeight={400} fontSize={54}>
                        <FormattedMessage id="auth.signUp.heading.title" />
                    </ExtraLargeH1>
                    <FavePageText fontSize={54} fontWeight={700} color='light'/>
                    <ContentWrapper>
                        <FormattedMessage
                            id="auth.signUp.heading.text"
                            values={{
                                section1: (text) => <ExtraLargeH1 colorValue='light' fontWeight={500}>{text}</ExtraLargeH1>,
                                boldunderline: (text) => <Label underline fontWeight={700}>{text}</Label>,
                                section2: (text) => <H4 color='light' >{text}</H4>,
                                bold: (text) => <Label fontWeight={700}>{text}</Label>,
                                orange: (text) => <Label skin='primary'>{text}</Label>,
                                bullet: (text) => <Bullet>{text}</Bullet>,
                                searchshare: (text) => <SearchShareWrapper>{text}</SearchShareWrapper>,
                                new: () => <br/>,
                            }}
                        />
                    </ContentWrapper>
                    <ScrollButtonWrapper>
                        <Button icon="arrow-down" btnType="link" onClick={scrollToSection} iconSize="50" skin="light"/>
                    </ScrollButtonWrapper>
                </SignUpIntro>
                <WelcomeMainContainer border justifyContent='start'>
                    <SignUpTitleWrapper>
                        <H1 color="secondary">
                            <FormattedMessage id='welcome.signup.title' values={{ sitename: intl.formatMessage({ id: 'siteName' }) }} />
                            <H5 fontWeight={500} color='secondary'>
                                <FormattedMessage
                                    id="welcome.signup.content"
                                    values={{
                                        link: (label) => <Link to={LOGIN_PATH}>{label}</Link>,
                                    }}
                                />
                            </H5>
                        </H1>
                    </SignUpTitleWrapper>
                    <FormSignUp
                        showHeading={false}
                        siteName={intl.formatMessage({ id: 'siteName' })}
                        onSubmitSignUp={(values) => handleOnSubmitSignUp(values)}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        inProp
                        toSignIn={LOGIN_PATH}
                        appear={false}
                        footnote={
                            <ContentRenderer
                                textAlign="center"
                                color="muted"
                                source={intl.formatMessage({
                                    id: 'welcome.signup.footnote.md',
                                })}
                            />
                        }
                    />
                </WelcomeMainContainer>
            </WelcomeWrapper>
            <Separator />
            <LandingIntroWrapper bgWhite beforeImage={triangle} afterImage={heartPart} id="homeIntroSection">
                <Container>
                    <ExtraLargeH1 colorValue='secondary' fontWeight={600} fontSize={54}>
                        What is <FavePageText fontSize={54} fontWeight={600} color='secondary'/>
                    </ExtraLargeH1>
                    <ContentRenderer
                        textAlign="left"
                        color="mute"
                        source={intl.formatMessage({
                            id: 'auth.signUp.intro.content',
                        })}
                    />
                    <ButtonWrapper width={310} marginY={32} marginX={1} justifyContent='left'>
                        <LinkButton to={ABOUT_PATH} >
                            <FormattedMessage id="auth.signUp.intro.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                </Container>
                <VideoWrapper>
                    <Video controls src={'https://www.youtube.com/embed/FeT4_ocigLc'} />
                </VideoWrapper>
            </LandingIntroWrapper>
            <LaunchWrapper beforeImage={leftArrow} afterImage={rightArrow} width={216}>
                <ContentRenderer
                    textAlign="center"
                    color="light"
                    source={intl.formatMessage({
                        id: 'auth.signUp.launch.title',
                    })}
                />
                <ContentRenderer
                    textAlign="center"
                    color="light"
                    source={intl.formatMessage({
                        id: 'auth.signUp.launch.content',
                    })}
                />
                <br/>
                <H3 color="light" fontWeight={700} textAlign="center">
                    <Image src={LOGO_ONLY_EMPTY} width={32} height={32} imageFluid={false} />
                    {' '}
                    <FormattedMessage id='auth.signUp.launch.logo' />
                </H3>
                <ButtonWrapper width={310} marginY={32} marginX={1}>
                    <LinkButton to={"/"} >
                        <FormattedMessage id="auth.signUp.launch.button" />
                    </LinkButton>
                </ButtonWrapper>
            </LaunchWrapper>
            <LaunchWrapper bgWhite beforeImage={triangleLeft} afterImage={triangleRight} width={384}>
                <ExtraLargeH1 colorValue='secondary' fontWeight={600} fontSize={54}>
                    <ContentRenderer
                        textAlign="center"
                        color="secondary"
                        source={intl.formatMessage({
                            id: 'auth.signUp.banner.content',
                        })}
                    />
                </ExtraLargeH1>
                <br/>
                <ButtonWrapper width={360} margin={1}>
                    <LinkButton to={BUSINESS_PATH} >
                        <FormattedMessage id="auth.signUp.intro.button.label" />
                    </LinkButton>
                </ButtonWrapper>
            </LaunchWrapper>
        </CommonWrapper>
    );
};

export default SignUpRoute;
