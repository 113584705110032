import { baseTheme } from 'baseline-ui/base-theme';

const loaderTheme = {
    loader: {
        height: 4,
        width: {
            default: 1.25,
            isFullScreen: 3.25,
        },
        container: {
            backgroundColor: baseTheme.base.grays.grayLightest,
            zIndex: 3,
        },
        dash: {
            backgroundColor: baseTheme.base.grays.grayLight,
        },
    },
};

export default loaderTheme;
