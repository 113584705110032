import React, { useContext, useEffect } from 'react';
import PageHeader from 'components/pageLayout/PageHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'baseline-ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_START } from 'redux/actions/AuthenticationActions';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { H3 } from 'baseline-ui/typography';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import FavePageAsideInfoBanner from 'components/favePageAside/FavePageAsideInfoBanner';
import FavePageMainContainer from 'components/favePageMain/FavePageMainContainer';
import { GET_USER_PROFILE_START } from 'redux/actions/ProfileActions';
import CommonWrapper from './CommonWrapper';

const AccountRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useContext(ThemeContext);
    const userProfile = useSelector(({ ProfileReducer }) => ProfileReducer);
    const { email, firstName, lastName } = userProfile;

    useEffect(() => {
        dispatch({ type: GET_USER_PROFILE_START });
    }, []);

    return (
        <CommonWrapper>
            <AdminLeftAsideTemplate
                bgEnd={theme.site.admin.bgEnd}
                bgStart={theme.site.admin.bgStart}
                banner={<PageHeader title={intl.formatMessage({ id: 'settings.page.title' })} />}
                aside={<FavePageAsideInfoBanner />}
                main={
                    <FavePageMainContainer>
                        <div style={{ marginTop: 16 }}>
                            {firstName && lastName && (
                                <H3 color="secondary">
                                    {firstName} {lastName}
                                </H3>
                            )}
                            <H3 color="secondary">{email}</H3>
                            <br />
                            <Button
                                onClick={() => {
                                    dispatch({
                                        type: LOGOUT_START,
                                        payload: { navigate },
                                    });
                                }}
                            >
                                <FormattedMessage id="settings.signOut.button.label" />
                            </Button>
                        </div>
                    </FavePageMainContainer>
                }
            />
        </CommonWrapper>
    );
};

export default AccountRoute;
