import styled from 'styled-components';

const ProductsAndServicesFilteredListContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLighter};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

export default ProductsAndServicesFilteredListContainer;
