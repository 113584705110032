import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, buttonPropTypes } from 'baseline-ui/button';
import { H6 } from 'baseline-ui/typography';
import { isEmpty } from 'lodash';
import { TransitionItem } from 'baseline-ui/helpers';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import NavigationFooterContainer from './NavigationFooterContainer';
import NavigationFooterContent from './NavigationFooterContent';
import NavigationFooterMainActionsContainer from './NavigationFooterMainActionsContainer';
import NavigationFooterMainActionContainer from './NavigationFooterMainActionContainer';
import NavigationFooterMainLabelContainer from './NavigationFooterMainLabelContainer';

const NavigationFooter = ({ actions }) => {
    const { hasUnsavedChanges, approval_status: approvalStatus } =
        useSelector(businessFormSelector);

    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInProp(true);
        }, 150);
        return () => clearTimeout(timer);
    }, []);

    return (
        <TransitionItem inProp={actions && inProp} transitionTimeout={150}>
            <NavigationFooterContainer>
                <NavigationFooterContent>
                    <TransitionItem
                        inProp={hasUnsavedChanges && Boolean(approvalStatus)}
                        appear
                        unmountOnExit
                        mountOnEnter
                    >
                        <NavigationFooterMainLabelContainer>
                            <H6 color="muted" textAlign="right">
                                <FormattedMessage id="addBusiness.page.headerNavigation.unsavedChanges.label" />
                            </H6>
                        </NavigationFooterMainLabelContainer>
                    </TransitionItem>
                    <NavigationFooterMainActionsContainer>
                        {!isEmpty(actions) &&
                            actions.map((action) => {
                                const {
                                    label,
                                    onClick,
                                    skin,
                                    btnType,
                                    icon,
                                    loading,
                                    loadingMessage,
                                    accessibilityLabel,
                                    disabled,
                                } = action;
                                return (
                                    <NavigationFooterMainActionContainer key={label}>
                                        <Button
                                            skin={skin}
                                            btnType={btnType}
                                            icon={icon}
                                            loading={loading}
                                            loadingMessage={loadingMessage}
                                            disabled={disabled}
                                            onClick={onClick}
                                            aria-label={accessibilityLabel}
                                        >
                                            {label}
                                        </Button>
                                    </NavigationFooterMainActionContainer>
                                );
                            })}
                    </NavigationFooterMainActionsContainer>
                </NavigationFooterContent>
            </NavigationFooterContainer>
        </TransitionItem>
    );
};

NavigationFooter.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
            skin: buttonPropTypes.buttonSkin,
            btnType: buttonPropTypes.buttonBtnType,
            icon: PropTypes.string,
            loading: PropTypes.bool,
            disabled: PropTypes.bool,
        }),
    ),
};

NavigationFooter.defaultProps = {
    actions: undefined,
};

export default NavigationFooter;
