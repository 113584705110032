import styled, { css } from 'styled-components';

const ErrorContainer = styled.div`
    margin-top: 0.125rem;

    ${({ centered }) =>
        centered &&
        css`
            text-align: center;
        `}
`;

export default ErrorContainer;
