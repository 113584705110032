import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { H5 } from 'baseline-ui/typography';
import { GaIcon } from 'baseline-ui/icon';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import ProductsAndServicesListItemTotalFaves from './ProductsAndServicesListItemTotalFaves';
import ProductsAndServicesListItemHeaderContainer from './ProductsAndServicesListItemHeaderContainer';
import ProductsAndServicesListItemHeaderIconContainer from './ProductsAndServicesListItemHeaderIconContainer';
import ProductsAndServicesListItemHeaderFavesCountLabel from './ProductsAndServicesListItemHeaderFavesCountLabel';

const ProductsAndServicesListItemHeader = ({ name, totalFaves }) => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();

    return (
        <ProductsAndServicesListItemHeaderContainer>
            <H5 color="secondary">{name}</H5>
            <ProductsAndServicesListItemTotalFaves>
                <ProductsAndServicesListItemHeaderIconContainer>
                    <GaIcon
                        icon="favepage-heart-filled"
                        size={16}
                        color={theme.base.colors.primary}
                    />
                </ProductsAndServicesListItemHeaderIconContainer>
                <ProductsAndServicesListItemHeaderFavesCountLabel>
                    {intl.formatNumber(totalFaves)}
                </ProductsAndServicesListItemHeaderFavesCountLabel>
            </ProductsAndServicesListItemTotalFaves>
        </ProductsAndServicesListItemHeaderContainer>
    );
};

ProductsAndServicesListItemHeader.propTypes = {
    name: PropTypes.string.isRequired,
    totalFaves: PropTypes.number.isRequired,
};

export default ProductsAndServicesListItemHeader;
