import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import FormCheckboxButtonLabel from './FormCheckboxButtonLabel';
import CheckboxInput from '../checkbox/CheckboxInput';
import formPropTypes from '../../prop-types/formPropTypes';

class FormCheckboxButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
    };

    handleChange = (event) => {
        const { field, onChange } = this.props;
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    render() {
        const {
            form,
            field,
            id,
            disabled,
            buttonType,
            buttonSize,
            buttonSkin,
            labelOnly,
            children,
        } = this.props;
        const { focused } = this.state;
        const value = getIn(form.values, field.name);

        return (
            <FormCheckboxButtonLabel
                focused={focused}
                checked={value || false}
                disabledValue={disabled}
                buttonType={buttonType}
                buttonSize={buttonSize}
                buttonSkin={buttonSkin}
                labelOnly={labelOnly}
            >
                <CheckboxInput
                    id={id}
                    name={field.name}
                    type="checkbox"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={value}
                    disabled={disabled}
                    checked={value || false}
                />

                {children}
            </FormCheckboxButtonLabel>
        );
    }
}

FormCheckboxButton.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    buttonType: formPropTypes.radioButtonGroupType,
    buttonSize: formPropTypes.radioButtonGroupSize,
    buttonSkin: formPropTypes.radioButtonGroupSkin,
    labelOnly: PropTypes.bool,
    children: PropTypes.node,
};

FormCheckboxButton.defaultProps = {
    buttonType: 'default',
    buttonSize: 'default',
    buttonSkin: 'primary',
    labelOnly: false,
    onChange: null,
    disabled: false,
    children: null,
};

export default FormCheckboxButton;
