import PropTypes from 'prop-types';
import buttonSkinData from '../data/buttonSkinData';
import buttonSizeData from '../data/buttonSizeData';
import buttonBtnTypeData from '../data/buttonBtnTypeData';
import buttonTypeData from '../data/buttonTypeData';
import buttonTargetData from '../data/buttonTargetData';

const buttonSkin = PropTypes.oneOf(buttonSkinData);
const buttonSize = PropTypes.oneOf(buttonSizeData);
const buttonBtnType = PropTypes.oneOf(buttonBtnTypeData);
const buttonType = PropTypes.oneOf(buttonTypeData);
const buttonTarget = PropTypes.oneOf(buttonTargetData);

export default { buttonSkin, buttonSize, buttonBtnType, buttonType, buttonTarget };
