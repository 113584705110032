import siteBaseTheme from './siteBaseTheme';
import siteTypographyTheme from './siteTypographyTheme';

const tabsTheme = {
    tabs: {
        tab: {
            selectedBorderColor: siteTypographyTheme.typography.link.color,
            minHeight: 48,
        },
        panel: {
            shadow: false,
            borderRadius: siteBaseTheme.base.borderRadius.md,
        },
        title: {
            fontFamily: siteTypographyTheme.typography.base.fontFamily,
            fontSize: 14,
            uppercase: false,
        },
    },
};

export default tabsTheme;
