import styled, { css } from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const CopyrightContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    margin-top: ${rem(16)};
    flex-direction: column;
    ${media.xs`
        flex-direction: row;
    `};
`;

export default CopyrightContainer;
