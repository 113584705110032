import { baseTheme } from 'baseline-ui/base-theme';

const authTheme = {
    auth: {
        divider: {
            color: baseTheme.base.grays.grayLighter,
        },
        border: {
            color: baseTheme.base.grays.grayLightest,
            borderRadius: 18,
        },
        container: {
            maxWidth: 360,
        },
    },
};

export default authTheme;
