import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import { usePrevious } from 'baseline-ui/helpers';
import Container from './panel/Container';
import Wrapper from './panel/Wrapper';

const Panel = ({ children, isOpen, itemId }) => {
    const previous = usePrevious(isOpen);

    const { opacity } = useSpring({
        from: { opacity: 0 },
        to: { opacity: isOpen ? 1 : 0 },
    });

    return (
        <Wrapper>
            <Container
                style={{
                    opacity: isOpen && previous === isOpen ? 1 : opacity,
                    height: isOpen ? 'auto' : 0,
                }}
                role="tabpanel"
                aria-labelledby={`${itemId}-tab`}
                id={itemId}
            >
                {children}
            </Container>
        </Wrapper>
    );
};

Panel.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
};

Panel.defaultProps = {
    isOpen: false,
};

export default Panel;
