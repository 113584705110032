import React from 'react';
import { PropTypes } from 'prop-types';
import { EntityPickerContent } from './EntityPickerContent';
import { EntityPickerDetailsItem, EntityPickerButtonCell } from './components';

export const EntityPickerDetails = ({ entity, ClearButton }) => {
    return (
        <EntityPickerDetailsItem key={entity._id} hasClearButton={!!ClearButton}>
            <EntityPickerContent entity={entity} />
            {ClearButton ? (
                <EntityPickerButtonCell>
                     {ClearButton}
                </EntityPickerButtonCell>
            ) : null}
        </EntityPickerDetailsItem>
    );
};
EntityPickerDetails.propTypes = {
    entity: PropTypes.object.isRequired,
    ClearButton: PropTypes.node,
};
