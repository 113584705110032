import styled from 'styled-components';
import { get } from 'lodash';
import { lighten, darken } from 'polished';
import tooltipTheme from '../../styles/tooltipTheme';

const TooltipInfoButton = styled.button`
    background-color: transparent;
    border: 0;
    color: ${({ theme }) => get(theme, 'tooltip.info.color', tooltipTheme.tooltip.info.color)};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) =>
            lighten(0.1, get(theme, 'tooltip.info.color', tooltipTheme.tooltip.info.color))};
    }

    &:focus,
    &:active {
        color: ${({ theme }) =>
            darken(0.1, get(theme, 'tooltip.info.color', tooltipTheme.tooltip.info.color))};
    }
`;

export default TooltipInfoButton;
