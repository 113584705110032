import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const BlockWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: ${rem(16)};
   margin-top: ${rem(16)};
   align-items: ${({alignItems}) =>
    alignItems
        ? alignItems
        : 'center'
    };
   ${media.sm`
        margin-top: ${rem(8)};
        align-items: end;
    `};
`;

export default BlockWrapper;