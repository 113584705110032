import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { RenderButton } from 'baseline-ui/button';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import { useMeasure } from 'baseline-ui/helpers';
import Label from './Label';
import NavigationListItem from './NavigationListItem';
import headerPropTypes from '../../prop-types/headerPropTypes';
import NavigationList from './NavigationList';
import NavigationListContainer from './NavigationListContainer';
import { DropdownMenu, DropdownMenuItem } from "./Dropdown";
import { LOGOUT_START } from "../../../../../redux/actions/AuthenticationActions";
import { GET_USER_PROFILE_START } from "../../../../../redux/actions/ProfileActions";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {NotificationCount} from "./NotificationCount";
import {
    unreadNotifications
} from "../../../../../redux/selectors/notificationSelectors";
import {
    GET_ALL_LINK_NOTIFICATIONS_START,
    GET_LINK_NOTIFICATIONS_START,
    RESET_LINK_NOTIFICATIONS_START
} from "../../../../../redux/actions/LinkNotificationActions";

const Navigation = ({
    isVertical,
    list,
    isListVertical,
    onClickLinkCallback,
    isMobile,
    currentPath,
    isModal,
    scrollbarStylesColor,
}) => {
    const { themeMode } = useContext(ThemeModeContext);
    const [hasScrollbar, setHasScrollbar] = useState();
    const [bindList, boundsList] = useMeasure();
    const [bindContainer, boundsContainer] = useMeasure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkHasScrollbar = () => {
        if (isVertical && boundsContainer.height > 0) {
            setHasScrollbar(boundsContainer.height < boundsList.height);
        }
    };
    const [openMenu, setOpenMenu] = useState(null);
    const dropdownRef = useRef(null);
    const notificationCount = useSelector(unreadNotifications);

    const toggleMenu = (menu) => {
        setOpenMenu(openMenu === menu ? null : menu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        checkHasScrollbar();
        window.addEventListener('resize', checkHasScrollbar);
        return () => window.removeEventListener('resize', checkHasScrollbar);
    }, [boundsContainer]);

    useEffect(() => {
        dispatch({ type: GET_USER_PROFILE_START });
    }, []);
    useEffect(() => {
        dispatch({ type: RESET_LINK_NOTIFICATIONS_START });
        dispatch({type: GET_ALL_LINK_NOTIFICATIONS_START});
        dispatch({ type: GET_LINK_NOTIFICATIONS_START });
    }, []);


    if (!list) {
        return null;
    }

    return (
        <NavigationListContainer
            isVertical={isVertical}
            hasScrollbar={hasScrollbar}
            scrollbarStylesColor={scrollbarStylesColor}
            themeMode={themeMode}
            {...bindContainer}
            ref={dropdownRef}
        >
            <NavigationList isVertical={isVertical} {...bindList}>
                {list.map((item, index) => {
                    const {
                        path,
                        label,
                        btnType,
                        skin,
                        size,
                        onClick,
                        isExternal,
                        icon,
                        showLabel = !!label,
                        dropdownItems,
                    } = item;
                    let renderSize = size;
                    if (!size) {
                        renderSize = isMobile ? 'lg' : 'default';
                    }
                    const isActive = currentPath && currentPath === path;
                    const isBellIcon = icon === 'bell';
                    return (
                        <NavigationListItem
                            key={index}
                            isVertical={isVertical}
                            isListVertical={isListVertical}
                            isActive={isActive}
                            isModal={isModal}
                        >
                            <RenderButton
                                path={path}
                                isExternal={isExternal}
                                btnType={btnType}
                                size={renderSize}
                                skin={skin}
                                icon={icon}
                                iconPostLabel
                                iconRotate={!!openMenu}
                                onClick={(e) => {
                                    onClick?.(e);
                                    if (dropdownItems) {
                                        toggleMenu(index);
                                    }
                                    if(label === 'Log Out'){
                                        dispatch({
                                            type: LOGOUT_START,
                                            payload: { navigate },
                                        });
                                    }
                                }}
                                onClickLinkCallback={() => {
                                    if(isMobile && !dropdownItems)
                                        onClickLinkCallback()
                                }}
                                aria-label={label || icon}
                                isActive={Boolean(isActive)}
                            >
                                {showLabel && <Label>{label}</Label>}
                                {isBellIcon && notificationCount > 0 && (
                                    <NotificationCount>{notificationCount > 5 ? '5+' : notificationCount}</NotificationCount>
                                )}
                            </RenderButton>
                                {openMenu === index && dropdownItems && (
                                    <DropdownMenu>
                                        {dropdownItems.map((dropdownItem, dropdownIndex) => (
                                            <DropdownMenuItem
                                                key={dropdownIndex}
                                            >
                                                <RenderButton
                                                    path={dropdownItem.path}
                                                    isExternal={isExternal}
                                                    btnType={dropdownItem.btnType}
                                                    size={dropdownItem.size}
                                                    skin={dropdownItem.skin}
                                                    onClickLinkCallback={() => {
                                                        if(isMobile)
                                                            onClickLinkCallback()
                                                    }}
                                                >
                                                    {dropdownItem.label}
                                                </RenderButton>

                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenu>
                                )}
                        </NavigationListItem>
                    );
                })}
            </NavigationList>
        </NavigationListContainer>
    );
};

Navigation.propTypes = {
    isVertical: PropTypes.bool,
    list: headerPropTypes.list,
    isListVertical: PropTypes.bool,
    onClickLinkCallback: PropTypes.func,
    isMobile: PropTypes.bool,
    isModal: PropTypes.bool,
    currentPath: PropTypes.string,
    scrollbarStylesColor: PropTypes.string,
};

Navigation.defaultProps = {
    isVertical: false,
    list: null,
    isListVertical: false,
    onClickLinkCallback: () => {},
    isMobile: false,
    isModal: false,
    currentPath: undefined,
    scrollbarStylesColor: undefined,
};

export default Navigation;
