import {
    GET_EVENT_LIST_SUCCESS,
    GET_EVENT_LIST_FAILURE,
    EVENT_FORM_PROCESS_RESET,
    SET_EVENT_START,
    ADD_EVENT_FORM_ERROR, SET_EVENT_FILTER_SUCCESS,
} from '../actions/EventActions';

export const DEFAULT_STATE = {
    entityId: '',
    eventName: '',
    startDate: '',
    endDate: '',
    startTime: null,
    endTime: null,
    description:  '',
    locationType: 'CREATOR',
    locationBusiness_selected:  '',
    locationName: '',
    address: '',
    city: '',
    state:  '',
    zipCode: '',
    eventType:  '',
    customEventType:  '',
    headlinePerson: [],
    additionalPeople:  [],
    selectedCategories: [],
    eventLinks:  [],
    category: '',
    subcategory: '',
    filter: {},
    photos: [],
    hasUnsavedChanges: false,
};
const EventReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_EVENT_START: {
            return {
                ...state,
                ...payload,
            };
        }
        case SET_EVENT_FILTER_SUCCESS: {
            return{
                ...state,
                page: payload.page ?? 0
            }
        }
        case GET_EVENT_LIST_SUCCESS: {
            return {
                ...state,
                eventList: payload.eventList,
                lastItemId: payload.lastItemId,
                hasMore: payload.hasMore,
            };
        }
        case ADD_EVENT_FORM_ERROR:
        case GET_EVENT_LIST_FAILURE: {
            return state;
        }
        case EVENT_FORM_PROCESS_RESET: {
            return {
                ...DEFAULT_STATE,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default EventReducer;
