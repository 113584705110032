import styled from 'styled-components';

const UpdatesWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
`;

export default UpdatesWrapper;