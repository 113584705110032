import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_SECTION_START,
  DELETE_SECTION_START,
  UPDATE_SECTION_START,
} from "../redux/actions/SectionActions";

const AddSectionModal = ({ open, onClose, sectionIndex }) => {
  const dispatch = useDispatch();
  const [sectionName, setSectionName] = useState("");
  const { sections } = useSelector(({ SectionReducer }) => SectionReducer);

  useEffect(() => {
    if (sections && sections[sectionIndex] && sectionIndex !== undefined) {
      setSectionName(sections[sectionIndex].name);
    }
    if (sectionIndex === undefined) {
      setSectionName("");
    }
  }, [sections, sectionIndex]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add section</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 1 }}
          id="sectionName"
          label="Section Name"
          name="sectionName"
          variant="outlined"
          fullWidth
          value={sectionName}
          onChange={(e) => setSectionName(e.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        {sectionIndex === undefined && (
          <Button
            onClick={() => {
              dispatch({
                type: ADD_SECTION_START,
                payload: { name: sectionName },
              });
              setSectionName("");
              onClose();
            }}
          >
            Add new
          </Button>
        )}
        {sectionIndex !== undefined && (
          <Button
            onClick={() => {
              dispatch({
                type: DELETE_SECTION_START,
                payload: sectionIndex,
              });
              onClose();
            }}
          >
            Delete
          </Button>
        )}
        {sectionIndex !== undefined && (
          <Button
            onClick={() => {
              dispatch({
                type: UPDATE_SECTION_START,
                payload: { name: sectionName, sectionIndex },
              });
              onClose();
            }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddSectionModal;
