import { baseTheme, baseDarkTheme } from 'baseline-ui/base-theme';
import formTheme from './formTheme';

const formDarkTheme = {
    form: {
        ...formTheme.form,
        input: {
            light: {
                backgroundColor: '#F2F2F2',
                backgroundColorFocus: '#ECECEC',
                borderColor: baseDarkTheme.base.grays.grayLight,
                borderColorFocus: baseTheme.base.colors.primary,
                color: baseDarkTheme.base.grays.grayLightest,
                colorDisabled: baseDarkTheme.base.grays.gray,
            },
            dark: {
                backgroundColor: '#3D3D3D',
                backgroundColorFocus: '#424242',
                borderColor: baseDarkTheme.base.grays.grayLight,
                borderColorFocus: baseDarkTheme.base.colors.primary,
                color: baseDarkTheme.base.grays.grayDarkest,
                colorDisabled: baseDarkTheme.base.grays.gray,
            },
        },
        label: {
            light: {
                color: baseDarkTheme.base.grays.grayLight,
                colorFilled: baseDarkTheme.base.grays.gray,
                colorFocus: baseTheme.base.colors.primary,
                colorDisabled: baseDarkTheme.base.grays.gray,
            },
            dark: {
                color: baseDarkTheme.base.grays.grayDark,
                colorFilled: baseDarkTheme.base.grays.gray,
                colorFocus: baseDarkTheme.base.colors.primary,
                colorDisabled: baseDarkTheme.base.grays.gray,
            },
        },
        select: {
            light: {
                optionColor: baseDarkTheme.base.grays.grayLightest,
            },
            dark: {
                optionColor: baseDarkTheme.base.grays.grayDarkest,
            },
        },
        checkbox: {
            light: {
                backgroundColor: 'transparent',
                backgroundColorChecked: baseTheme.base.colors.primary,
                borderColor: baseDarkTheme.base.grays.grayDark,
                borderColorChecked: baseTheme.base.colors.primary,
                colorChecked: baseTheme.base.colors.primary,
                colorDisabled: baseDarkTheme.base.grays.grayLighter,
                colorDisabledChecked: baseDarkTheme.base.grays.grayLighter,
                indicatorColor: '#ffffff',
                indicatorDisabledColor: baseDarkTheme.base.grays.grayLightest,
            },
            dark: {
                backgroundColor: 'transparent',
                backgroundColorChecked: 'transparent',
                borderColor: '#ffffff',
                borderColorChecked: '#ffffff',
                colorChecked: '#ffffff',
                colorDisabled: baseDarkTheme.base.grays.grayDark,
                colorDisabledChecked: baseDarkTheme.base.grays.grayDark,
                indicatorColor: '#ffffff',
                indicatorDisabledColor: baseDarkTheme.base.grays.gray,
            },
            muted: {
                backgroundColor: 'transparent',
                backgroundColorChecked: baseTheme.base.colors.muted,
                borderColor: baseDarkTheme.base.grays.grayDark,
                borderColorChecked: baseTheme.base.colors.muted,
                colorChecked: baseTheme.base.colors.muted,
                colorDisabled: baseDarkTheme.base.grays.grayLighter,
                colorDisabledChecked: baseDarkTheme.base.grays.grayLighter,
                indicatorColor: baseTheme.base.colors.light,
                indicatorDisabledColor: baseDarkTheme.base.grays.grayLightest,
            },
            label: {
                color: {
                    light: {
                        default: 'inherit',
                        disabled: baseDarkTheme.base.grays.grayLighter,
                    },
                    dark: {
                        default: '#ffffff',
                        disabled: baseDarkTheme.base.grays.grayDark,
                    },
                },
            },
        },
        legend: {
            light: {
                color: baseDarkTheme.base.colors.light,
            },
            dark: {
                color: baseDarkTheme.base.colors.dark,
            },
        },
        error: {
            color: baseDarkTheme.base.colors.danger,
        },
    },
};

export default formDarkTheme;
