import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, LinkButton, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { H2, P, H6 } from 'baseline-ui/typography';
import { FormInput, FieldContainer, FormPasswordInput } from 'baseline-ui/form';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import AuthFormContainer from './AuthFormContainer';
import AuthFormSignError from './AuthFormSignError';
import ForgotPasswordLinkContainer from './ForgotPasswordLinkContainer';
import FooterActions from './FooterActions';
import AuthHeadingContainer from './AuthHeadingContainer';
import formSignInSchema from '../schema/formSignInSchema';
import authPropTypes from '../prop-types/authPropTypes';
import AuthWrapper from './AuthWrapper';

const FormSignIn = ({
    onSubmitSignIn,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    toRecoverPassword,
    toSignUp,
    siteName,
    showHeading,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    appear,
}) => {
    const intl = useIntl();

    const submitSignIn = (values) => {
        onSubmitSignIn(values);
    };

    const initialValues = {
        emailAddress: '',
        password: '',
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="auth.signIn.heading.title" />
            </H2>
            <P>
                <FormattedMessage id="auth.signIn.heading.content" />
            </P>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <AuthWrapper>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={formSignInSchema}
                        validateOnChange
                        validateOnBlur
                        onSubmit={(values) => {
                            submitSignIn(values);
                        }}
                    >
                        {() => {
                            return (
                                <Form noValidate>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'auth.emailAddress.label',
                                            })}
                                            name="emailAddress"
                                            id="emailAddress"
                                            type="email"
                                            disabled={isLoading}
                                            required
                                            showIsRequired={false}
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <Field
                                            component={FormPasswordInput}
                                            label={intl.formatMessage({
                                                id: 'auth.password.label',
                                            })}
                                            name="password"
                                            id="password"
                                            type="password"
                                            disabled={isLoading}
                                            required
                                            showIsRequired={false}
                                            preventWhiteSpaces
                                        />
                                    </FieldContainer>
                                    <ForgotPasswordLinkContainer>
                                        <LinkButton btnType="link" size="sm" to={toRecoverPassword}>
                                           <FormattedMessage id="auth.forgotPassword.label" />
                                        </LinkButton>
                                    </ForgotPasswordLinkContainer>
                                    <Button
                                        skin={buttonSkin}
                                        btnType={buttonBtnType}
                                        block
                                        type="submit"
                                        loading={isLoading}
                                        loadingMessage={intl.formatMessage({
                                            id: 'auth.signIn.loading.label',
                                        })}
                                    >
                                        <FormattedMessage id="auth.signIn.label" />
                                    </Button>
                                    <AuthFormSignError errorMessage={errorMessage} />
                                </Form>
                            );
                        }}
                    </Formik>
                </AuthWrapper>
                <FooterActions>
                    <H6 color="muted">
                        <FormattedMessage
                            id="auth.signIn.newToSite.label"
                            values={{
                                siteName,
                            }}
                        />
                    </H6>
                    <LinkButton to={toSignUp}>
                        <FormattedMessage id="auth.signIn.joinNow.label" />
                    </LinkButton>
                </FooterActions>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormSignIn.propTypes = {
    onSubmitSignIn: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    toRecoverPassword: PropTypes.string,
    toSignUp: PropTypes.string,
    siteName: PropTypes.string.isRequired,
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    appear: PropTypes.bool,
};

FormSignIn.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    toRecoverPassword: '/recoverPassword',
    toSignUp: '/signUp/',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    appear: true,
};

export default FormSignIn;
