import { baseTheme } from 'baseline-ui/base-theme';
import alertTheme from './alertTheme';

const alertDarkTheme = {
    alert: {
        ...alertTheme.alert,
        alertFeedback: {
            backgroundColor: baseTheme.base.colors.dark,
        },
    },
};

export default alertDarkTheme;
