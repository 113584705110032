import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Box } from '@rebass/grid';
import { ThemeContext } from 'styled-components';
import layoutTheme from '../../styles/layoutTheme';

const SplitAsideWrapper = ({ order, children }) => {
    const theme = useContext(ThemeContext);
    const gutter = get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter);

    return (
        <Box px={gutter} width={[1, 1, 1, 1, 0.5]} flex="0 0 auto" order={order}>
            {children}
        </Box>
    );
};

SplitAsideWrapper.propTypes = {
    order: PropTypes.number,
    children: PropTypes.node,
};

SplitAsideWrapper.defaultProps = {
    order: 0,
    children: null,
};

export default SplitAsideWrapper;
