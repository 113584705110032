import styled from 'styled-components';
import { rem } from 'polished';

const TooltipOptionList = styled.ul`
    border-radius: ${rem(6)};
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: ${rem(1)};
`;

export default TooltipOptionList;
