import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import ModalContext from './ModalContext';
import ModalRoot from './ModalRoot';
import ModalGlobalStyles from '../ModalGlobalStyles';

const initialState = {
    isOpen: false,
    component: null,
    modalProps: {},
};

const actions = {
    OPEN_MODAL: 'OPEN_MODAL',
    REMOVE_MODAL: 'REMOVE_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
};

function reducer(state, action) {
    switch (action.type) {
        case actions.OPEN_MODAL: {
            const { component, modalProps } = action.payload;
            return {
                ...state,
                isOpen: true,
                component,
                modalProps,
            };
        }
        case actions.HIDE_MODAL: {
            return {
                ...state,
                isOpen: false,
            };
        }
        case actions.REMOVE_MODAL: {
            return {
                ...state,
                isOpen: false,
                component: null,
                modalProps: {},
            };
        }
        default:
            return state;
    }
}

const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            isOpen: state.isOpen,
            component: state.component,
            modalProps: state.modalProps,
            showModal: (component, modalProps) => {
                dispatch({ type: actions.OPEN_MODAL, payload: { component, modalProps } });
            },
            removeModal: () => {
                setTimeout(() => {
                    dispatch({ type: actions.REMOVE_MODAL });
                });
            },
            hideModal: () => {
                dispatch({ type: actions.HIDE_MODAL });
            },
        }),
        [state],
    );

    return (
        <ModalContext.Provider value={value}>
            <ModalGlobalStyles />
            <ModalRoot />
            {children}
        </ModalContext.Provider>
    );
};

ModalProvider.propTypes = {
    children: PropTypes.node,
};

ModalProvider.defaultProps = {
    children: undefined,
};

export default ModalProvider;
