import { ProcessOverlayContext } from 'baseline-ui/processOverlay';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PROCESS_START } from 'redux/actions/CommonActions';
import { createSelector } from 'reselect';

const useProcessLoader = ({ processNames, withOverlay }) => {
    const { setProcessOverlayOnStage } = useContext(ProcessOverlayContext);
    const selectProcessesSelector = (state) => state.ProcessReducer;

    const selectLoadingProcesses = createSelector(selectProcessesSelector, (processes) =>
        processNames.some((processName) => processes[processName] === PROCESS_START),
    );

    const isLoading = useSelector(selectLoadingProcesses);

    useEffect(() => {
        if (withOverlay) {
            setProcessOverlayOnStage({ show: isLoading });
        }
    }, [isLoading, withOverlay]);

    return isLoading;
};

export default useProcessLoader;
