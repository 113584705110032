import { css } from 'styled-components';
import { get } from 'lodash';
import typographyTheme from './typographyTheme';

const headingStyles = ({ isUppercase, colorValue, textAlign, theme }) => css`
    color: ${get(
        theme,
        `typography.colors.${colorValue}`,
        typographyTheme.typography.colors[colorValue],
    )};
    display: block;
    font-family: ${({ isBaseFont }) =>
        isBaseFont
            ? get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)
            : get(
                  theme,
                  'typography.headings.fontFamily',
                  typographyTheme.typography.headings.fontFamily,
              )};
    line-height: ${get(
        theme,
        'typography.headings.lineHeight',
        typographyTheme.typography.headings.lineHeight,
    )};
    padding: 0;
    text-align: ${textAlign};
    text-transform: ${isUppercase ? 'uppercase' : 'none'};
    transition: color 0.15s ease-in-out;
`;

export default headingStyles;
