import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductSectionsListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${rem(4)};
    padding-top: ${rem(2)};
`;

export default ModalEditProductSectionsListContainer;
