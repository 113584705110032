import { baseDarkTheme } from 'baseline-ui/base-theme';
import typographyTheme from './typographyTheme';

const typographyDarkTheme = {
    typography: {
        ...typographyTheme.typography,
        link: {
            color: baseDarkTheme.base.colors.primary,
        },
        colors: {
            primary: baseDarkTheme.base.colors.primary,
            secondary: baseDarkTheme.base.colors.secondary,
            muted: baseDarkTheme.base.colors.muted,
            info: baseDarkTheme.base.colors.info,
            success: baseDarkTheme.base.colors.success,
            warning: baseDarkTheme.base.colors.warning,
            danger: baseDarkTheme.base.colors.danger,
            light: baseDarkTheme.base.colors.light,
            dark: baseDarkTheme.base.colors.dark,
        },
    },
};

export default typographyDarkTheme;
