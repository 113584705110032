import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { UL } from 'baseline-ui/typography';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    businessFormRequiredFieldSelector,
    businessFormSelector,
} from 'redux/selectors/businessFormSelectors';
import addBusinessSectionsUtils from 'utils/addBusinessSectionsUtils';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalSaveBusinessConfirmSubmit = ({ onRequestClose, onClickSubmitCallback, ...props }) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);
    const businessFormRequiredFields = useSelector(businessFormRequiredFieldSelector);

    const isSubmitValid = addBusinessSectionsUtils.addBusinessHasAllRequiredFields(
        businessFormRequiredFields,
        type,
    );

    const validSubmitFooterActions = [
        {
            label: intl.formatMessage({
                id: 'saveBusiness.modal.submit.footerAction.submit.button.label',
            }),
            skin: 'secondary',
            onClick: () => {
                onRequestClose();
                onClickSubmitCallback();
            },
        },
    ];
    const invalidSubmitFooterActions = [
        {
            label: intl.formatMessage({
                id: 'saveBusiness.modal.submit.footerAction.continue.button.label',
            }),
            onClick: () => onRequestClose(),
        },
    ];

    return (
        <Modal
            title={intl.formatMessage({
                id: isSubmitValid
                    ? 'saveBusiness.modal.submit.isValid.title'
                    : 'saveBusiness.modal.submit.isInvalid.title',
            })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={isSubmitValid ? validSubmitFooterActions : invalidSubmitFooterActions}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: isSubmitValid
                        ? 'saveBusiness.modal.submit.isValid.content.md'
                        : `${type}.saveEntity.modal.submit.isInvalid.content.md`,
                })}
            />
            <UL
                list={addBusinessSectionsUtils.addBusinessMissingRequiredFields(
                    businessFormRequiredFields,
                    intl,
                    type,
                )}
            />
        </Modal>
    );
};

ModalSaveBusinessConfirmSubmit.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onClickSubmitCallback: PropTypes.func,
};

ModalSaveBusinessConfirmSubmit.defaultProps = {
    onClickSubmitCallback: null,
};

export default ModalSaveBusinessConfirmSubmit;
