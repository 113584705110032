import React from 'react';
import { Image } from 'baseline-ui/helpers';
import { LOGO_APP_HEADER } from 'images';
import FavePagePreviewAppHeaderContainer from './FavePagePreviewAppHeaderContainer';
import FavePagePreviewAppHeaderSafeArea from './FavePagePreviewAppHeaderSafeArea';
import FavePagePreviewAppHeaderContent from './FavePagePreviewAppHeaderContent';
import FavePagePreviewAppHeaderSafeAreaTime from './FavePagePreviewAppHeaderSafeAreaTime';
import FavePagePreviewAppHeaderSafeAreaBattery from './FavePagePreviewAppHeaderSafeAreaBattery';

const FavePagePreviewAppHeader = () => {
    return (
        <FavePagePreviewAppHeaderContainer>
            <FavePagePreviewAppHeaderSafeArea>
                <FavePagePreviewAppHeaderSafeAreaTime />
                <FavePagePreviewAppHeaderSafeAreaBattery />
            </FavePagePreviewAppHeaderSafeArea>
            <FavePagePreviewAppHeaderContent>
                <Image src={LOGO_APP_HEADER} width={152} />
            </FavePagePreviewAppHeaderContent>
        </FavePagePreviewAppHeaderContainer>
    );
};

export default FavePagePreviewAppHeader;
