import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    productToEditSectionsSelector,
    productToEditSelector,
} from 'redux/selectors/productSelectors';
import { P } from 'baseline-ui/typography';
import { REMOVE_PRODUCT_FROM_SECTION_START } from 'redux/actions/EditProductActions';
import ModalEditProductSectionsListItem from './ModalEditProductSectionsListItem';
import ModalEditProductSectionsListContainer from './ModalEditProductSectionsListContainer';

const ModalEditProductSectionsList = () => {
    const productToEditSections = useSelector(productToEditSectionsSelector);
    const product = useSelector(productToEditSelector);
    const dispatch = useDispatch();

    const handleonOnClickRemoveSection = (id) => {
        dispatch({
            type: REMOVE_PRODUCT_FROM_SECTION_START,
            payload: {
                item: product,
                sectionId: id,
            },
        });
    };

    if (isEmpty(productToEditSections)) {
        return null;
    }

    return (
        <>
            <P lead>
                <FormattedMessage id="addBusiness.modal.editProduct.section.list.title" />
            </P>
            <ModalEditProductSectionsListContainer>
                {productToEditSections.map((productToEditSection) => {
                    const { name, _id: id } = productToEditSection;
                    return (
                        <ModalEditProductSectionsListItem
                            name={name}
                            key={id}
                            onClickRemoveSection={() => handleonOnClickRemoveSection(id)}
                        />
                    );
                })}
            </ModalEditProductSectionsListContainer>
        </>
    );
};

export default ModalEditProductSectionsList;
