import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewAppHeaderSafeAreaTimeContainer = styled.div`
    align-items: center;
    display: flex;
    height: ${rem(30)};
    justify-content: flex-end;
    width: 25%;
`;

export default FavePagePreviewAppHeaderSafeAreaTimeContainer;
