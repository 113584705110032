import { baseDarkTheme } from 'baseline-ui/base-theme';
import buttonTheme from './buttonTheme';

const buttonDarkTheme = {
    button: {
        ...buttonTheme.button,
        primary: {
            background: baseDarkTheme.base.colors.primary,
            border: baseDarkTheme.base.colors.primary,
            color: baseDarkTheme.base.colors.light,
        },
        secondary: {
            background: baseDarkTheme.base.colors.secondary,
            border: baseDarkTheme.base.colors.secondary,
            color: baseDarkTheme.base.colors.light,
        },
        info: {
            background: baseDarkTheme.base.colors.info,
            border: baseDarkTheme.base.colors.info,
            color: baseDarkTheme.base.colors.light,
        },
        success: {
            background: baseDarkTheme.base.colors.success,
            border: baseDarkTheme.base.colors.success,
            color: baseDarkTheme.base.colors.light,
        },
        warning: {
            background: baseDarkTheme.base.colors.warning,
            border: baseDarkTheme.base.colors.warning,
            color: baseDarkTheme.base.colors.light,
        },
        danger: {
            background: baseDarkTheme.base.colors.danger,
            border: baseDarkTheme.base.colors.danger,
            color: baseDarkTheme.base.colors.light,
        },
        muted: {
            background: baseDarkTheme.base.colors.muted,
            border: baseDarkTheme.base.colors.muted,
            color: baseDarkTheme.base.colors.light,
        },
        light: {
            background: baseDarkTheme.base.colors.light,
            border: baseDarkTheme.base.colors.light,
            color: baseDarkTheme.base.colors.light,
        },
        dark: {
            background: baseDarkTheme.base.colors.dark,
            border: baseDarkTheme.base.colors.dark,
            color: baseDarkTheme.base.colors.dark,
        },
    },
};

export default buttonDarkTheme;
