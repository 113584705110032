import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_BUSINESS_START,
  GET_FEATURE_MAPPING_START,
  POST_BUSINESS_PROCESS,
  POST_BUSINESS_START,
} from "../../redux/actions/OwnABussinessActions";
import { deserializeMapping } from "../../helpers/features";
import BusinessForm from "./BusinessForm";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import OwnABusinessProductsRedux from "./OwnABusinessProductsRedux";
import BusinessPromotionalPush from "./BusinessPromotionalPush";
import BusinessAccessRights from "./BusinessAccessRights";

const TabPanel = ({ value, index, children }) => {
  return <div>{value === index && <>{children}</>}</div>;
};

const OwnABusinessRedux = () => {
  const { business_id } = useParams();
  const dispatch = useDispatch();
  const isEditing = true;
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    mapping: businessMapping,
    business,
    featureMapping,
  } = useSelector(({ OwnABusinessReducer }) => OwnABusinessReducer);

  useEffect(() => {
    dispatch({
      type: GET_FEATURE_MAPPING_START,
    });
    if (business_id) {
      dispatch({
        type: GET_BUSINESS_START,
        payload: business_id,
      });
    }
  }, [business_id]);

  const businessObject = {
    adminOnly: {},
    links: [],
    photos: [],
    ...business,
  };

  const {
    _id,
    name,
    ownerName,
    phone,
    email,
    formattedAddress,
    approval_status,
    website,
    description,
    links,
    photos,
    position,
    adminOnly: {
      followupStatus,
      privateCell,
      advertise,
      advertisementPriority,
    },
  } = businessObject;

  const businessMappingDeserialized = deserializeMapping(businessMapping);

  if (!business) {
    return null;
  }

  return (
    <Grid xs={12} item>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
        }}
      >
        <Tab label="Business FavePage" value={0} />
        <Tab label="Access Rights" value={3} />
        <Tab label="Products" value={1} />
        {businessObject?.adminOnly?.advertise && (
          <Tab label="Push notifications" value={2} />
        )}
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <BusinessForm
          processName={POST_BUSINESS_PROCESS}
          isEditing={isEditing}
          onSubmit={(values) => {
            dispatch({
              type: POST_BUSINESS_START,
              payload: values,
            });
          }}
          initialValues={{
            _id,
            name,
            ownerName,
            advertise,
            advertisementPriority,
            photos:
              business.photos?.map((photo) => {
                return Object.assign(photo, {
                  preview: photo.url,
                  state: "original",
                });
              }) || [],
            phone,
            email,
            formattedAddress,
            addressFromGoogle: formattedAddress,
            approval_status,
            originalPhotos: photos,
            description,
            website,
            links: JSON.stringify(
              links.map((link) => {
                return { ...link, id: Math.random() * 10000 };
              }),
            ),
            followupStatus,
            privateCell,
            featureMapping: JSON.stringify(featureMapping),
            position,
            businessMappingDeserialized: JSON.stringify(
              businessMappingDeserialized,
            ),
          }}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <OwnABusinessProductsRedux />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <BusinessPromotionalPush businessId={_id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <BusinessAccessRights businessId={_id} />
      </TabPanel>
    </Grid>
  );
};

export default OwnABusinessRedux;
