import { rem } from 'polished';
import styled from 'styled-components';

const FavePageAsideMyBusinessHorizontalScrollItemsContainer = styled.div`
    min-height: ${({ theme }) => rem(theme.site.page.sectionNavigation.height)};
    padding: ${rem(8)} 0;
    white-space: nowrap;
`;

export default FavePageAsideMyBusinessHorizontalScrollItemsContainer;
