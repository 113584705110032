import styled from 'styled-components';
import {rem} from 'polished';
export const Bullet = styled.span`
    position: relative;
    margin-left: ${rem(32)};
    
    &::before {
      content: '•';
      position: absolute;
      left: ${rem(-24)};
    }
`;

export default Bullet;