import styled from 'styled-components';

const Wrap = styled.div`
    white-space: nowrap;
    > * {
        display: inline-block;
        white-space: normal;
    }
`;

export default Wrap;
