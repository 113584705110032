import React from 'react';
import { AuthFieldHeaderContainer } from 'baseline-ui/auth';
import { FormattedMessage } from 'react-intl';
import { H3 } from 'baseline-ui/typography';
import { CONTEXT_PRODUCT } from 'common/consts';
import { productToEditSelector } from 'redux/selectors/productSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PRODUCT_TO_EDIT_PROPERTY_START } from 'redux/actions/EditProductActions';
import ManagePhotos from 'components/ManagePhotos';
import ModalEditProductContentContainer from './ModalEditProductContentContainer';

const ModalEditProductPhotos = () => {
    const product = useSelector(productToEditSelector);
    const { photos } = product;

    const dispatch = useDispatch();

    return (
        <ModalEditProductContentContainer>
            <AuthFieldHeaderContainer>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.modal.editProduct.section.photos.title" />
                </H3>
            </AuthFieldHeaderContainer>
            <ManagePhotos
                context={CONTEXT_PRODUCT}
                isInOverlay
                photos={photos}
                setPhotos={(photoList) => {
                    dispatch({
                        type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
                        payload: {
                            photos: photoList,
                            hasUnsavedChanges: true,
                        },
                    });
                }}
            />
        </ModalEditProductContentContainer>
    );
};

export default ModalEditProductPhotos;
