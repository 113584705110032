import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import ListItem from './ListItem';

const List = ({ list, color, className, lead }) => {
    return list.map((item, index) => {
        return (
            <ListItem key={index} className={className} colorValue={color} lead={lead}>
                {item}
            </ListItem>
        );
    });
};

List.propTypes = {
    list: PropTypes.arrayOf(PropTypes.node).isRequired,
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    lead: PropTypes.bool,
};

List.defaultProps = {
    color: 'dark',
    className: null,
    lead: false,
};

export default List;
