import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import tabsTheme from '../../styles/tabsTheme';

const Wrapper = styled.div`
    background-color: ${({ theme }) =>
        get(theme, 'tabs.panel.backgroundColor', tabsTheme.tabs.panel.backgroundColor)};
    border-color: ${({ theme }) =>
        get(theme, 'tabs.panel.borderColor', tabsTheme.tabs.panel.borderColor)};
    border-radius: ${({ theme }) =>
        rem(get(theme, 'tabs.panel.borderRadius', tabsTheme.tabs.panel.borderRadius))};
    border-style: solid;
    border-width: 1px;
    overflow: hidden;

    ${({ theme }) => {
        const shadow = get(theme, 'tabs.panel.shadow', tabsTheme.tabs.panel.shadow);
        return (
            shadow &&
            css`
                box-shadow: 0 2px 7px 5px rgba(0, 0, 0, 0.2);
            `
        );
    }}
`;

export default Wrapper;
