import { baseDarkTheme } from 'baseline-ui/base-theme';
import carouselTheme from './carouselTheme';

const carouselDarkTheme = {
    carousel: {
        ...carouselTheme.carousel,
        arrows: {
            backgroundColor: baseDarkTheme.base.grays.grayDark,
            color: {
                default: baseDarkTheme.base.grays.grayLightest,
                primary: baseDarkTheme.base.grays.grayLightest,
            },
        },
        dots: {
            background: {
                default: baseDarkTheme.base.grays.grayDark,
            },
            activeBackground: {
                default: baseDarkTheme.base.grays.grayLight,
            },
            border: {
                primary: baseDarkTheme.base.colors.primary,
            },
        },
    },
};

export default carouselDarkTheme;
