import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewFavesButtonContainer = styled.div`
    align-items: center;
    background-color: ${({ theme, skin }) => theme.button[skin].background};
    border-radius: ${rem(22)};
    color: ${({ theme, skin }) => theme.button[skin].color};
    display: flex;
    padding: ${rem(4)} ${rem(16)};
    min-height: ${rem(32)};
`;

export default FavePagePreviewFavesButtonContainer;
