import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'baseline-ui/layout';
import { Button } from 'baseline-ui/button';
import { H6 } from 'baseline-ui/typography';
import { useIntl } from 'react-intl';
import AccessRightsUsersListItemContainer from './AccessRightsUsersListItemContainer';
import AccessRightsUsersListItemLabelContainer from './AccessRightsUsersListItemLabelContainer';
import AccessRightsUsersListItemButtonContainer from './AccessRightsUsersListItemButtonContainer';

const AccessRightsUsersListItem = ({ userEmailAddress, onClick }) => {
    const intl = useIntl();

    if (!userEmailAddress) {
        return null;
    }

    return (
        <AccessRightsUsersListItemContainer>
            <Row alignItems="center">
                <Col xxs={30} xl={30}>
                    <H6>
                        <AccessRightsUsersListItemLabelContainer>
                            {userEmailAddress}
                        </AccessRightsUsersListItemLabelContainer>
                    </H6>
                </Col>
            </Row>

            <AccessRightsUsersListItemButtonContainer>
                <Button
                    btnType="link"
                    icon="x"
                    aria-label={intl.formatMessage({
                        id: 'addBusiness.page.sections.accessRights.form.removeUser.accessibilityLabel',
                    })}
                    onClick={onClick}
                />
            </AccessRightsUsersListItemButtonContainer>
        </AccessRightsUsersListItemContainer>
    );
};

AccessRightsUsersListItem.propTypes = {
    userEmailAddress: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default AccessRightsUsersListItem;
