import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SectionsList from "../../components/SectionsList";
import { deserializeMapping } from "../../helpers/features";
import ProductItem from "../../ProductItem";
import {
  ADD_BUSINESS_PRODUCT_START,
  GET_BUSINESS_PRODUCTS_START,
  RESET_BUSINESS_START,
  SET_PRODUCT_FILTER_START,
} from "../../redux/actions/OwnABussinessActions";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SectionProductList from "../../components/SectionProductList";
import EditProductForm from "../../components/EditProductForm";
import { Box } from "@mui/system";
import { RESET_PRODUCT_TO_EDIT_START } from "../../redux/actions/EditProductActions";

const OwnABusinessProductsRedux = () => {
  const dispatch = useDispatch();
  const { products, business, featureMapping, productFilter } = useSelector(
    ({ OwnABusinessReducer }) => OwnABusinessReducer,
  );

  useEffect(() => {
    if (!products) {
      dispatch({
        type: GET_BUSINESS_PRODUCTS_START,
      });
      dispatch({
        type: RESET_PRODUCT_TO_EDIT_START,
      });
    }
  }, [products]);

  return (
    <Grid container justify="space-around">
      <DndProvider backend={HTML5Backend}>
        <SectionsList />
      </DndProvider>
      <Grid container>
        <Grid item xs={6}>
          <Box marginRight={1} display="flex" flexGrow={1}>
            <SectionProductList />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <EditProductForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OwnABusinessProductsRedux;
