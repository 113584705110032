import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { rem } from 'polished';
import { ThemeContext } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { GaIcon } from 'baseline-ui/icon';
import { baseTheme } from 'baseline-ui/base-theme';
import Container from './Container';
import FeedbackLabel from './FeedbackLabel';
import FeedbackIcon from './FeedbackIcon';
import alertTheme from '../../styles/alertTheme';
import alertPropTypes from '../../prop-types/alertPropTypes';

const AlertFeedback = ({ alertType, alertMessage, inProp, isFullScreen }) => {
    const theme = useContext(ThemeContext);
    const iconColor = get(theme, `base.colors.${alertType}`, baseTheme.base.colors[alertType]);
    const icon = get(theme, `base.icons.${alertType}`, baseTheme.base.icons[alertType]);
    const iconSize = get(
        theme,
        'alert.alertFeedback.icon.size',
        alertTheme.alert.alertFeedback.icon.size,
    );

    const transition = useTransition(inProp, {
        from: { opacityContainer: 0, opacityLabel: 0, yIcon: -50 },
        enter: { opacityContainer: 1, opacityLabel: 1, yIcon: 0 },
        leave: { opacityContainer: 0, opacityLabel: 0, yIcon: -50 },
    });

    const AnimatedContainer = animated(Container);
    const AnimatedIcon = animated(FeedbackIcon);
    const AnimatedLabel = animated(FeedbackLabel);

    return (
        <>
            {transition(({ opacityContainer, opacityLabel, yIcon }, item) => {
                return (
                    item && (
                        <AnimatedContainer
                            style={{ opacity: opacityContainer }}
                            role="alert"
                            isFullScreen={isFullScreen}
                        >
                            <AnimatedIcon
                                style={{
                                    opacity: opacityLabel,
                                    transform: yIcon.to(
                                        (value) => `translate3d(0, ${rem(value)}, 0)`,
                                    ),
                                }}
                            >
                                <GaIcon icon={icon} size={iconSize} color={iconColor} />
                            </AnimatedIcon>
                            <AnimatedLabel alertType={alertType} style={{ opacity: opacityLabel }}>
                                {alertMessage}
                            </AnimatedLabel>
                        </AnimatedContainer>
                    )
                );
            })}
        </>
    );
};

AlertFeedback.propTypes = {
    alertMessage: PropTypes.node,
    inProp: PropTypes.bool,
    alertType: alertPropTypes.type,
    isFullScreen: PropTypes.bool,
};

AlertFeedback.defaultProps = {
    alertMessage: undefined,
    inProp: false,
    alertType: 'info',
    isFullScreen: false,
};

export default AlertFeedback;
