export const i18n = (key) => {
  const translations = {
    SUGGESTIONS: {
      selectedCategory: "Selected Category Id",
      featureName: "Feature Name",
      featureDescription: "Feature Description",
      suggestType: "Type of suggestion",
      entityId: "Entity Id",
      entityName: "Entity Name",
      personName: "Person Name",
      personDescription: "Person Description",
      businessName: "Business Name",
      businessAddress: "Business Address",
      businessUnit: "Business Unit",
      businessPhone: "Business Phone",
    },
    INQUIRE_TITLE_MAPPING: {
      INVESTMENT: "Investment Opprtunities",
      MARKETING: "Employment/Marketing Opportunities",
      ADVERTISE: "Advertise",
    },
    ERRORS: {
      GENERIC_ERROR: "An error ocurred when performing an action.",
      GET_BUSINESSES_PROCESS: {
        404: "Error",
        GENERIC_ERROR: "Generic error",
      },
      ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS: {
        400: "Such user doesn't exist in our database",
      },
      PUT_APPROVE_CLAIM_STATE_PROCESS: {
        400: "An error occurred when approving a claim",
      },
      PUT_REJECT_CLAIM_STATE_START: {
        400: "An error occurred when rejecting a claim",
      },
    },
  };

  return translations;
};
