import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BUSINESS_APPROVAL_STATUSES } from 'common/consts';
import StatusTagContainer from './StatusTagContainer';

const StatusTag = ({ status }) => {
    if (!status) {
        return null;
    }

    return (
        <StatusTagContainer status={status}>
            <FormattedMessage id={`status.${status}.label`} />
        </StatusTagContainer>
    );
};

StatusTag.propTypes = {
    status: PropTypes.oneOf(BUSINESS_APPROVAL_STATUSES),
};

StatusTag.defaultProps = {
    status: undefined,
};

export default StatusTag;
