import { rem, transparentize } from 'polished';
import styled from 'styled-components';

const ProductsAndServicesItemImageContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => transparentize(0.6, theme.base.colors.light)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    display: flex;
    height: ${rem(80)};
    justify-content: center;
    margin-right: ${rem(8)};
    max-width: ${rem(80)};
    overflow: hidden;
    width: 100%;
`;

export default ProductsAndServicesItemImageContainer;
