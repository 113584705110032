import styled from 'styled-components';

export const DropdownMenu = styled.div`
    position: absolute;
    top: 100%; 
    right: 0;
    background-color: #fff; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
    z-index: 1000; 
    min-width: 160px; 
    padding: 8px 0;
    
    @media (min-width: 1024px) and (max-width: 1170px) {
        padding: 8px 10px;
    }
    
    @media (max-width: 768px) {
        position: relative;
        background-color: rgb(72, 38, 85);
        border: none;
        box-shadow: none;
        padding: 8px 0;
    }
`;
export const DropdownMenuItem = styled.div`
    cursor: pointer;
    color: #F15A24;
    text-decoration: none;
    display: block;
    &:hover,
    &:focus {
        background-color: #f1f1f1; 
        color: #000; 
    }
    
    @media (max-width: 768px) {
        color: #fff;
        &:hover,
        &:focus {
            background-color: transparent;
            color: #fff;
        }
    }
`;


