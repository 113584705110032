import styled, { css } from 'styled-components';
import horizontalScrollbarStyles from '../styles/horizontalScrollbarStyles';

const Inner = styled.div`
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    position: relative;
    -webkit-overflow-scrolling: touch;

    ${({ showScrollbar }) =>
        showScrollbar
            ? horizontalScrollbarStyles
            : css`
                  ::-webkit-scrollbar {
                      display: none;
                  }
              `}
`;

export default Inner;
