import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useSelector } from 'react-redux';
import {
    allActiveProductsSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import { Form } from 'formik';
import { isEmpty } from 'lodash';
import { FieldContainer } from 'baseline-ui/form';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import ModalBrowseProductsFormListItem from './ModalBrowseProductsFormListItem';
import ModalBrowseProductsFormListContainer from './ModalBrowseProductsFormListContainer';
import ModalBrowseProductsFormSelectionLabel from './ModalBrowseProductsFormSelectionLabel';

const ModalBrowseProductsForm = ({ onRequestClose, ...props }) => {
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const allActiveProducts = useSelector(allActiveProductsSelector);
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage(
                {
                    id: 'addBusiness.modal.browseProducts.title',
                },
                {
                    sectionName: selectedSectionName,
                },
            )}
            showCloseButton={false}
            handleOnDismiss={onRequestClose}
            size="sm"
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.browseProducts.footerActions.done.button.label',
                    }),
                    onClick: () => onRequestClose(),
                },
            ]}
            footerPre={<ModalBrowseProductsFormSelectionLabel />}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage(
                    { id: 'addBusiness.modal.browseProducts.content' },
                    { sectionName: selectedSectionName },
                )}
            />
            <ModalBrowseProductsFormListContainer>
                <Form noValidate>
                    {!isEmpty(allActiveProducts) &&
                        allActiveProducts.map((product) => {
                            const { _id: id, name } = product;
                            return (
                                <FieldContainer key={id}>
                                    <ModalBrowseProductsFormListItem
                                        productId={id}
                                        productName={name}
                                    />
                                </FieldContainer>
                            );
                        })}
                </Form>
            </ModalBrowseProductsFormListContainer>
        </Modal>
    );
};

ModalBrowseProductsForm.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
};

export default ModalBrowseProductsForm;
