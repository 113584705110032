import styled from "styled-components";
import {rem} from "polished";
import {media} from "../../../layout";

const ButtonWrapper = styled.div`
     display: flex;
     justify-content: ${({justifyContent}) =>
        justifyContent
            ? justifyContent
            : 'center'
     };
     width: ${({width}) =>
            width
                ? rem(width)/1.8
                : '100%'
     };  
     margin: ${({ marginY, marginX }) =>
        (marginY && marginX)
            ? `${rem(marginY/2)} ${rem(marginX/2)}`
            : '0 auto'
     };
     z-index: 1;
     ${media.xs`
        margin: ${({ marginY, marginX }) =>
        (marginY && marginX)
            ? `${rem(marginY)} ${rem(marginX)}`
            : '0 auto'
    };
    width: ${({width}) =>
        width
            ? rem(width/1.6)
            : '100%'
    };
   `};
    ${media.sm`
        margin: ${({ marginY, marginX }) =>
            (marginY && marginX)
                ? `${rem(marginY)} ${rem(marginX)}`
                : '0 auto'
        };
        width: ${({width}) =>
            width
                ? rem(width)
                : '100%'
        };
    `};

`;

export default ButtonWrapper;