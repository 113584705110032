const layoutTheme = {
    layout: {
        media: {
            xxs: 414,
            xs: 568,
            sm: 768,
            md: 1024,
            lg: 1366,
            xl: 1680,
        },
        grid: {
            columns: 30,
            gutter: 16,
        },
        maxSiteWidth: 1440,
        aside: {
            width: {
                md: 400,
            },
        },
    },
};

export default layoutTheme;
