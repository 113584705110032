import React, { useEffect } from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_PROMO_NOTIFICATIONS_START,
  SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START,
} from "../redux/actions/PushActions";

const PromotionalNotificationsHistory = ({ businessId }) => {
  const dispatch = useDispatch();
  const { notifications, hasNext } = useSelector(
    ({ PromotionNotificationsReducer }) => PromotionNotificationsReducer,
  );

  useEffect(() => {
    dispatch({
      type: GET_PROMO_NOTIFICATIONS_START,
      payload: businessId,
    });
  }, []);

  return (
    <List dense={false}>
      {notifications?.map((notification) => {
        const notifierName = "";

        const {
          title,
          body,
          created,
          //   name:
          subjectEntity: { name },
        } = notification;
        const createdDate = new Date(created);
        return (
          <ListItemText
            primary={
              <Typography variant="h6">{title ?? "Empty title"}</Typography>
            }
            secondary={
              <div>
                <Typography variant="subtitle1">
                  {body ?? "Empty body"}
                </Typography>
                <Typography variant="body">
                  Sent by <b>{notifierName ?? "unknown user"}</b> on{" "}
                  <b>{createdDate.toISOString()}</b>
                </Typography>
                <Grid>
                  {name && (
                    <Typography variant="body">
                      Sent as a promotion for {name}
                    </Typography>
                  )}
                </Grid>
              </div>
            }
          />
        );
      })}
      {hasNext && (
        <Button
          onClick={() =>
            dispatch({
              type: SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START,
              payload: businessId,
            })
          }
        >
          Load more
        </Button>
      )}
    </List>
  );
};

export default PromotionalNotificationsHistory;
