import styled from 'styled-components';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import typographyTheme from '../styles/typographyTheme';

const RouterLink = styled(Link)`
    color: ${({ theme, $colorValue }) =>
        $colorValue
            ? get(
                  theme,
                  `typography.colors.${$colorValue}`,
                  typographyTheme.typography.colors[$colorValue],
              )
            : get(theme, 'typography.link.color', typographyTheme.typography.link.color)};
    transition: color 0.15s ease-in-out;
`;

export default RouterLink;
