import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: ${rem(16)};
    ${media.xs`
        justify-content: end;
        margin-top: ${({marginTop}) => marginTop && css`${rem(marginTop)}` } ;
    `};
    
`;

export default ButtonWrapper;