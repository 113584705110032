import { rem, transparentize } from 'polished';
import styled from 'styled-components';

const MyBusinessesListItemContainer = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    background-image: linear-gradient(90deg, #fef5f6 0%, #f2edf6 50%, #f2f6ff 100%);
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${rem(24)};
    min-height: ${({ theme }) => rem(theme.site.myBusinessesList.item.height)};
    padding: ${rem(24)};
    position: relative;

    &:after {
        border: 1px solid ${({ theme }) => transparentize(0.6, theme.base.grays.grayLightest)};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export default MyBusinessesListItemContainer;
