import React from 'react';
import PropTypes from 'prop-types';
import { typographyPropTypes } from 'baseline-ui/typography';
import Container from './Container';
import OverlayTitle from './OverlayTitle';

const OverlayHeader = ({ title, $titleTypographyColor }) => {
    return (
        <Container>
            <OverlayTitle color={$titleTypographyColor}>{title}</OverlayTitle>
        </Container>
    );
};

OverlayHeader.propTypes = {
    title: PropTypes.node.isRequired,
    $titleTypographyColor: typographyPropTypes.typographyColor,
};

OverlayHeader.defaultProps = {
    $titleTypographyColor: undefined,
};

export default OverlayHeader;
