import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import Container from './Label/Container';
import Label from './Label/Label';
import buttonPropTypes from '../prop-types/buttonPropTypes';
import buttonStyles from '../styles/buttonStyles';

const LinkButtonBase = styled(Link)`
    &,
    &:hover {
        text-decoration: none;
    }
    ${({ $block }) =>
        $block &&
        css`
            display: block;
            width: 100%;
        `}
`;

const Button = styled.span`
    ${buttonStyles}
`;

const LinkButton = ({
    icon,
    iconSize,
    iconColor,
    iconPostLabel,
    skin,
    size,
    block,
    to,
    btnType,
    children,
    isActive,
    ...props
}) => {
    const { themeMode } = useContext(ThemeModeContext);

    const buttonContent = icon ? (
        <Container>
            <Label icon={icon} size={iconSize} color={iconColor} btnSize={size} skin={skin} iconPostLabel={iconPostLabel}>
                {children}
            </Label>
        </Container>
    ) : (
        children
    );

    return (
        <LinkButtonBase to={to} $block={block} {...props}>
            <Button
                sizeValue={size}
                blockValue={block}
                skin={skin}
                btnType={btnType}
                isActive={isActive}
                themeMode={themeMode}
            >
                {buttonContent}
            </Button>
        </LinkButtonBase>
    );
};

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    skin: buttonPropTypes.buttonSkin,
    btnType: buttonPropTypes.buttonBtnType,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    block: PropTypes.bool,
    size: buttonPropTypes.buttonSize,
    children: PropTypes.node,
    isActive: PropTypes.bool,
};

LinkButton.defaultProps = {
    skin: 'primary',
    size: 'default',
    btnType: 'default',
    icon: undefined,
    iconColor: 'currentColor',
    iconSize: undefined,
    block: false,
    children: undefined,
    isActive: false,
};

export default LinkButton;
