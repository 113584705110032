import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { typographyTheme } from 'baseline-ui/typography';
import { darken, lighten } from 'polished';
import collapsableTheme from '../../styles/collapsableTheme';

const TitleItem = styled.span`
    font-family: ${({ theme }) =>
        get(
            theme,
            'typography.headings.fontFamily',
            typographyTheme.typography.headings.fontFamily,
        )};
    font-size: ${({ theme }) =>
        get(
            theme,
            'collapsable.accordion.title.fontSize',
            collapsableTheme.collapsable.accordion.title.fontSize,
        )};
    font-weight: ${({ theme }) =>
        get(
            theme,
            'collapsable.accordion.title.fontWeight',
            collapsableTheme.collapsable.accordion.title.fontWeight,
        )};

    ${({ triggerColor }) =>
        triggerColor !== 'inherit' &&
        css`
            color: ${triggerColor};

            &:hover {
                color: ${lighten(0.1, triggerColor)};
            }

            &:focus,
            &:active {
                color: ${darken(0.1, triggerColor)};
            }
        `}

    ${({ triggerColor }) =>
        triggerColor === 'inherit' &&
        css`
            color: inherit;
        `}
`;

export default TitleItem;
