import FavePageWrapper from "./FavePageWrapper";
import ExtraLargeH1 from "baseline-ui/typography/src/components/ExtraLargeH1";
import React from "react";

function FavePageText({fontWeight,fontSize, color}){
    return (
        <FavePageWrapper>
            <ExtraLargeH1 colorValue='primary' fontWeight={fontWeight} fontSize={fontSize}>
              Fave
            </ExtraLargeH1>
            <ExtraLargeH1 colorValue={color} fontWeight={fontWeight} fontSize={fontSize}>
               Page
            </ExtraLargeH1>
        </FavePageWrapper>
    )
}

export default FavePageText;