import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import withSizes from 'react-sizes';
import { Modal, modalPropTypes } from 'baseline-ui/modal';
import { isSizes } from 'baseline-ui/layout';
import { typographyPropTypes } from 'baseline-ui/typography';
import Navigation from '../Navigation';
import headerPropTypes from '../../prop-types/headerPropTypes';
import headerTheme from '../../styles/headerTheme';
import {HOME_PATH} from "common/pathnames";
import {useLocation} from "react-router-dom";

const MenuModal = ({
    list,
    secondaryList,
    isMobile,
    isLargeMobile,
    onRequestClose,
    onClickLinkCallback,
    currentPath,
    ...props
}) => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const { pathname } = useLocation();
    const modalOffsetTop = get(
        theme,
        'header.menuModal.offsetTop',
        headerTheme.header.menuModal.offsetTop,
    );

    const modalEntryTransitionY = get(
        theme,
        'header.menuModal.entryTransitionY',
        headerTheme.header.menuModal.entryTransitionY,
    );

    const menuBackgroundColor = pathname === HOME_PATH
        ? get(theme, 'header.menuModal.backgroundLightColor', headerTheme.header.menuModal.backgroundLightColor)
        : get(theme, 'header.menuModal.backgorundColor', headerTheme.header.menuModal.backgroundColor);

    const menuTitleTypgraphyColor = pathname === HOME_PATH
        ? get(theme, 'header.menuModal.titleTypographyDarkColor', headerTheme.header.menuModal.titleTypographyDarkColor,)
        : get(theme, 'header.menuModal.titleTypographyColor', headerTheme.header.menuModal.titleTypographyColor,);

    useEffect(() => {
        if (!isMobile) {
            onRequestClose();
        }
    }, [isMobile]);

    return (
        <Modal
            offsetTop={modalOffsetTop}
            entryTransitionY={modalEntryTransitionY}
            title={intl.formatMessage({ id: 'header.mobile.menu.title' })}
            footerActions={secondaryList}
            size="default"
            showCloseButton
            isContentCover
            handleOnDismiss={() => onRequestClose()}
            customBackgroundColor={menuBackgroundColor}
            $titleTypographyColor={menuTitleTypgraphyColor}
            {...props}
        >
            <Navigation
                list={list}
                isMobile={isMobile}
                isModal
                isListVertical
                onClickLinkCallback={() => {
                    onClickLinkCallback();
                    setTimeout(() => {
                        onRequestClose();
                    }, 100);
                }}
                currentPath={currentPath}
            />
        </Modal>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.mdView({ width }, { theme }),
    };
};

MenuModal.propTypes = {
    list: headerPropTypes.list,
    secondaryList: modalPropTypes.modalFooterActions,
    isMobile: PropTypes.bool,
    isLargeMobile: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onClickLinkCallback: PropTypes.func,
    currentPath: PropTypes.string,
    titleTypographyColor: typographyPropTypes.typographyColor,
};

MenuModal.defaultProps = {
    list: null,
    secondaryList: null,
    isMobile: false,
    isLargeMobile: false,
    onRequestClose: null,
    onClickLinkCallback: () => {},
    currentPath: undefined,
    titleTypographyColor: undefined,
};

export default withSizes(mapSizesToProps)(MenuModal);
