import styled from 'styled-components';

const Container = styled.span`
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`;

export default Container;
