/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';

const OuterWrapper = ({ children }) => {
    return <>{children}</>;
};

OuterWrapper.propTypes = {
    children: PropTypes.node,
};

OuterWrapper.defaultProps = {
    children: null,
};

export default OuterWrapper;
