import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import carouselTheme from '../styles/carouselTheme';

const SliderDotsContainer = styled.div`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0
        ${({ theme, dotsType }) =>
            rem(
                get(
                    theme,
                    `carousel.dots.size[${dotsType}]`,
                    carouselTheme.carousel.dots.size[dotsType],
                ),
            )};
    position: absolute;
    text-align: center;

    ${({ dotsPosition, dotsOrientation }) =>
        dotsPosition === 'inside top' &&
        dotsOrientation !== 'vertical' &&
        css`
            top: ${({ theme, dotsType }) =>
                rem(
                    get(
                        theme,
                        `carousel.dots.size[${dotsType}]`,
                        carouselTheme.carousel.dots.size[dotsType],
                    ) * 2,
                )};
        `}
    ${({ dotsPosition, dotsOrientation }) =>
        dotsPosition === 'inside bottom' &&
        dotsOrientation !== 'vertical' &&
        css`
            bottom: ${({ theme, dotsType }) =>
                rem(
                    get(
                        theme,
                        `carousel.dots.size[${dotsType}]`,
                        carouselTheme.carousel.dots.size[dotsType],
                    ) * 2,
                )};
        `}

    ${({ dotsPosition, dotsOrientation }) =>
        dotsPosition === 'outside bottom' &&
        dotsOrientation !== 'vertical' &&
        css`
            bottom: -${({ theme, dotsType }) => rem(get(theme, `carousel.dots.size[${dotsType}]`, carouselTheme.carousel.dots.size[dotsType]) * 2.8)};
        `}

    ${({ dotsPosition, dotsOrientation }) =>
        dotsPosition === 'outside top' &&
        dotsOrientation !== 'vertical' &&
        css`
            top: -${({ theme, dotsType }) => rem(get(theme, `carousel.dots.size[${dotsType}]`, carouselTheme.carousel.dots.size[dotsType]) * 2.8)};
        `}
    
    ${({ dotsAlignment }) =>
        dotsAlignment === 'start' &&
        css`
            justify-content: flex-start;
            align-items: flex-start;
        `}

    ${({ dotsAlignment }) =>
        dotsAlignment === 'end' &&
        css`
            justify-content: flex-end;
            align-items: flex-end;
        `}

    ${({ dotsAlignment }) =>
        dotsAlignment === 'center' &&
        css`
            justify-content: center;
            align-items: center;
        `}


    ${({ dotsOrientation }) =>
        dotsOrientation === 'horizontal' &&
        css`
            margin: 0;
            width: 100%;
        `}

    ${({ dotsOrientation }) =>
        dotsOrientation === 'vertical' &&
        css`
            bottom: 0;
            height: 100%;
            width: ${rem(50)};
            right: 0;
            padding: ${({ theme, dotsType }) =>
                rem(
                    get(
                        theme,
                        `carousel.dots.size[${dotsType}]`,
                        carouselTheme.carousel.dots.size[dotsType],
                    ),
                )};
        `}
`;

export default SliderDotsContainer;
