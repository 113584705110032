import {
    GET_BUSINESS_FORM_SUCCESS,
    RESET_BUSINESS_FORM_START,
    SET_BUSINESS_FORM_DATA_START,
} from '../actions/BusinessFormActions';

const DEFAULT_STATE = {
    name: '',
    ownerFirstName: '',
    ownerLastName: '',
    position: '',
    phone: '',
    privatephone: '',
    email: '',
    formattedAddress: '',
    showAddress: 'true',
    website: '',
    description: '',
    links: [],
    photos: [],
    type: null,
    selectedCategories: [],
    hasUnsavedChanges: false,
    approval_status: '',
    adminOnly: {
        followupStatus: '',
        privateCell: '',
        advertise: {
            appAnchor: false,
            appAnchorCityList: [],
            appAnchorOrder: '',
            appAnchorOrderEnabled: false,
            appAnchorRotate: false,
            appAnchorSearchCity: '',
            categoriesAndFilters: [
                {
                    categoryCityList: [],
                    categoryEnabled: false,
                    categoryOrder: '',
                    categoryOrderEnabled: false,
                    categoryRotate: false,
                    categorySearchCity: '',
                    categoryTags: [],
                },
            ],
        },
        advertisementPriority: '',
    },
};

const BusinessFormReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_BUSINESS_FORM_DATA_START: {
            return {
                ...state,
                ...payload,
            };
        }
        case GET_BUSINESS_FORM_SUCCESS: {
            return {
                ...state,
                ...payload,
                position: payload?.position
                    ? payload.position
                    : payload?.own_business?.position || '',
                showAddress:
                    typeof payload.showAddress === 'boolean'
                        ? String(payload.showAddress)
                        : state.showAddress,
            };
        }
        case RESET_BUSINESS_FORM_START: {
            return {
                ...DEFAULT_STATE,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default BusinessFormReducer;
