import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { modalPropTypes, Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';

const ModalEditSectionForm = ({ title, footerActions, footerPost, onRequestClose, ...props }) => {
    const intl = useIntl();

    return (
        <Modal
            title={title}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={footerActions}
            footerPost={footerPost}
            {...props}
        >
            <Form noValidate>
                <FieldContainer>
                    <Field
                        component={FormInput}
                        label={intl.formatMessage({
                            id: 'addBusiness.modal.addSectionName.form.sectionName.label',
                        })}
                        name="sectionName"
                        id="sectionName"
                        type="text"
                        required
                        autoComplete="off"
                    />
                </FieldContainer>
            </Form>
        </Modal>
    );
};

ModalEditSectionForm.propTypes = {
    title: PropTypes.string.isRequired,
    footerActions: modalPropTypes.modalFooterActions,
    onRequestClose: PropTypes.func.isRequired,
    footerPost: PropTypes.node,
};

ModalEditSectionForm.defaultProps = {
    footerActions: undefined,
    footerPost: undefined,
};

export default ModalEditSectionForm;
