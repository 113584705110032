import styled, { css } from 'styled-components';
import { rem, transparentize } from 'polished';
import { get } from 'lodash';
import horizontalScrollTheme from '../styles/horizontalScrollTheme';

const ScrollButton = styled.button`
    background-color: ${({ btnColor }) => btnColor || 'transparent'};
    border: 0;
    cursor: pointer;
    height: ${({ theme }) => `calc(
                    100% -
                        ${rem(
                            get(
                                theme,
                                'horizontalScroll.scrollbar.height',
                                horizontalScrollTheme.horizontalScroll.scrollbar.height,
                            ),
                        )}
                )`};
    position: absolute;
    top: 0;
    z-index: 3;

    &:focus {
        outline: 0;
    }

    ${({ btnWidth }) =>
        btnWidth &&
        css`
            width: ${rem(btnWidth)};
        `}

    ${({ showEnd, showStart, btnColor, theme }) =>
        (showEnd || showStart) &&
        css`
            &:after {
                background-image: linear-gradient(
                    to left,
                    ${btnColor} 10%,
                    ${transparentize(100, btnColor)} 100%
                );
                content: '';
                height: ${`calc(
                    100% -
                        ${rem(
                            get(
                                theme,
                                'horizontalScroll.scrollbar.height',
                                horizontalScrollTheme.horizontalScroll.scrollbar.height,
                            ),
                        )}
                )`};
                pointer-events: none;
                position: absolute;
                top: 0;
                width: ${rem(24)};
            }
        `}

    ${({ showEnd }) =>
        showEnd &&
        css`
            right: 0;

            &:after {
                left: -${rem(24)};
            }
        `}
    ${({ showStart }) =>
        showStart &&
        css`
            left: 0;

            &:after {
                right: -${rem(24)};
                transform: scaleX(-1);
            }
        `}
`;

export default ScrollButton;
