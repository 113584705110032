import React, { useRef, useLayoutEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import TooltipSelectContainer from './TooltipSelectContainer';
import tooltipPropTypes from '../prop-types/tooltipPropTypes';

const TooltipSelect = ({
    detect,
    openMenu,
    closeMenu,
    resize,
    tooltipSize,
    children,
    selectId,
}) => {
    const select = useRef(null);

    const resizeSelect = () => {
        const trigger = select.current.getBoundingClientRect();
        resize(trigger);
    };

    useLayoutEffect(() => {
        resizeSelect();
        window.addEventListener('resize', resizeSelect);
        return () => window.removeEventListener('resize', resizeSelect);
    }, [tooltipSize]);

    if (detect === 'click') {
        return (
            <TooltipSelectContainer ref={select}>
                {cloneElement(children, {
                    ref: select,
                    onClick: openMenu,
                    onTouchEnd: openMenu,
                    'aria-describedby': selectId,
                })}
            </TooltipSelectContainer>
        );
    }
    if (detect === 'hover') {
        return (
            <TooltipSelectContainer ref={select}>
                {cloneElement(children, {
                    ref: select,
                    onMouseEnter: openMenu,
                    onMouseLeave: closeMenu,
                    onTouchEnd: openMenu,
                    'aria-describedby': selectId,
                })}
            </TooltipSelectContainer>
        );
    }
    return null;
};

TooltipSelect.propTypes = {
    detect: tooltipPropTypes.detectionType,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
    resize: PropTypes.func,
    children: PropTypes.node.isRequired,
    selectId: PropTypes.string,
    tooltipSize: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }),
};

TooltipSelect.defaultProps = {
    detect: 'click',
    openMenu: null,
    closeMenu: null,
    resize: null,
    selectId: null,
    tooltipSize: null,
};

TooltipSelect.displayName = 'Select';

export default TooltipSelect;
