import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Box } from '@rebass/grid';
import { ThemeContext } from 'styled-components';
import layoutTheme from '../../styles/layoutTheme';

const Col = ({ xxs, xs, sm, md, lg, xl, flex, children, ...props }) => {
    const theme = useContext(ThemeContext);

    let xxsValue;
    let xsValue;
    let smValue;
    let mdValue;
    let lgValue;
    let xlValue;

    const gutter = get(theme, 'layout.grid.gutter', layoutTheme.layout.grid.gutter);
    const col = get(theme, 'layout.grid.columns', layoutTheme.layout.grid.columns);

    if (xxs) {
        xxsValue = xxs / col;
    }
    if (xs) {
        xsValue = xs / col;
    }
    if (sm) {
        smValue = sm / col;
    }
    if (md) {
        mdValue = md / col;
    }
    if (lg) {
        lgValue = lg / col;
    }
    if (xl) {
        xlValue = xl / col;
    }
    return (
        <Box
            px={gutter}
            width={[xxsValue, xsValue, smValue, mdValue, lgValue, xlValue]}
            flex={flex}
            {...props}
        >
            {children}
        </Box>
    );
};

Col.propTypes = {
    xxs: PropTypes.number,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    flex: PropTypes.string,
    children: PropTypes.node,
};

Col.defaultProps = {
    xxs: null,
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    flex: undefined,
    children: null,
};

export default Col;
