import siteBaseTheme from './siteBaseTheme';

const siteTooltipTheme = {
    tooltip: {
        backgroundColor: siteBaseTheme.base.colors.light,
        borderColor: siteBaseTheme.base.grays.grayLighter,
        borderRadius: siteBaseTheme.base.borderRadius.default,
        closeIcon: {
            color: siteBaseTheme.base.grays.grayLight,
        },
        info: {
            color: siteBaseTheme.base.grays.gray,
        },
        optionList: {
            item: {
                backgroundColor: siteBaseTheme.base.colors.light,
                color: siteBaseTheme.base.colors.dark,
                colorActive: siteBaseTheme.base.colors.light,
                backgroundColorActive: siteBaseTheme.base.colors.primary,
            },
        },
    },
};

export default siteTooltipTheme;
