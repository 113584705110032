import React from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import PhotoListItemContainer from './PhotoListItemContainer';
import PhotoListItemFooter from './PhotoListItemFooter';
import PhotoListItemImageContainer from './PhotoListItemImageContainer';
import PhotoListItemOuter from './PhotoListItemOuter';

const PhotoListItem = ({ image, onClickEdit, onClickDelete }) => {
    return (
        <PhotoListItemOuter>
            <PhotoListItemContainer>
                <PhotoListItemImageContainer>
                    <ProgressiveBg image={image} />
                </PhotoListItemImageContainer>
                <PhotoListItemFooter>
                    <Button btnType="link" icon="edit" size="sm" onClick={onClickEdit} />
                    <Button btnType="link" icon="x" size="sm" onClick={onClickDelete} />
                </PhotoListItemFooter>
            </PhotoListItemContainer>
        </PhotoListItemOuter>
    );
};

PhotoListItem.propTypes = {
    image: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

PhotoListItem.defaultProps = {};

export default PhotoListItem;
