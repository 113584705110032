import { Small } from 'baseline-ui/typography';
import styled from 'styled-components';

const ProductsAndServicesListItemHeaderFavesCountLabel = styled(Small).attrs(() => ({
    color: 'primary',
}))`
    font-weight: ${({ theme }) => theme.typography.strong.fontWeight};
    margin: 0;
`;

export default ProductsAndServicesListItemHeaderFavesCountLabel;
