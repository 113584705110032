import React, { useContext } from 'react';
import { Formik } from 'formik';
import { SET_SELECTED_SECTION_START, UPDATE_SECTION_START } from 'redux/actions/SectionActions';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayContext } from 'baseline-ui/overlay';
import { itemsEditSectionSchema } from 'schema/itemsSchema';
import { useIntl } from 'react-intl';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import {
    allSectionsSelector,
    selectedSectionIndexSelector,
    selectedSectionNameSelector,
} from 'redux/selectors/sectionSelectors';
import ModalEditSectionForm from './ModalEditSectionForm';
import ModalEditSectionFooterPost from './ModalEditSectionFooterPost';

const ModalEditSection = ({ ...props }) => {
    const selectedSectionName = useSelector(selectedSectionNameSelector);
    const selectedSectionIndex = useSelector(selectedSectionIndexSelector);
    const allSections = useSelector(allSectionsSelector);
    const dispatch = useDispatch();
    const intl = useIntl();
    const { hideOverlay } = useContext(OverlayContext);

    const initialValues = {
        sectionName: selectedSectionName,
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                const { sectionName } = values;
                dispatch({
                    type: UPDATE_SECTION_START,
                    payload: {
                        name: sectionName,
                        sectionIndex: selectedSectionIndex,
                    },
                });
                dispatch({
                    type: SET_BUSINESS_FORM_DATA_START,
                    payload: { hasUnsavedChanges: true },
                });
                dispatch({
                    type: SET_SELECTED_SECTION_START,
                    payload: sectionName,
                });
                hideOverlay();
            }}
            validationSchema={itemsEditSectionSchema(allSections)}
        >
            {({ handleSubmit }) => {
                return (
                    <ModalEditSectionForm
                        title={intl.formatMessage(
                            {
                                id: 'addBusiness.modal.editSectionName.title',
                            },
                            {
                                sectionName: selectedSectionName,
                            },
                        )}
                        footerPost={<ModalEditSectionFooterPost />}
                        footerActions={[
                            {
                                label: intl.formatMessage({
                                    id: 'addBusiness.modal.editSectionName.footerActions.cancel.button.label',
                                }),
                                btnType: 'link',
                                skin: 'muted',
                                onClick: () => hideOverlay(),
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'addBusiness.modal.editSectionName.footerActions.update.button.label',
                                }),
                                skin: 'secondary',
                                type: 'submit',
                                onClick: () => handleSubmit(),
                            },
                        ]}
                        {...props}
                    />
                );
            }}
        </Formik>
    );
};

export default ModalEditSection;
