import styled from 'styled-components';
import dobInputStyles from '../../styles/dobInputStyles';

const InputYear = styled.input`
    ${dobInputStyles}

    flex: 1 1 auto;
    padding-left: 0.5rem;
    padding-right: 0.625rem;
    text-align: left;

    &::-ms-clear {
        display: none;
    }
`;

export default InputYear;
