import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SwitchContainer from './switch/SwitchContainer';
import SwitchInput from './switch/SwitchInput';
import SwitchIndicator from './switch/SwitchIndicator';
import formPropTypes from '../prop-types/formPropTypes';

class FormSwitch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState({ focused: false });

        if (onBlur) {
            onBlur(event);
        }
    };

    handleChange = (event) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(event);
        }
    };

    render() {
        const { id, name, sizeValue, tagOnLabelId, tagOffLabelId, value, disabled, intl } =
            this.props;
        const { focused } = this.state;

        return (
            <SwitchContainer sizeValue={sizeValue}>
                <SwitchInput
                    id={id}
                    name={name}
                    type="checkbox"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={value}
                    disabled={disabled}
                />
                <SwitchIndicator
                    htmlFor={id}
                    focused={focused}
                    checked={value}
                    sizeValue={sizeValue}
                    data-switch-on={intl.formatMessage({ id: tagOnLabelId || 'switch.on.label' })}
                    data-switch-off={intl.formatMessage({
                        id: tagOffLabelId || 'switch.off.label',
                    })}
                    disabled={disabled}
                />
            </SwitchContainer>
        );
    }
}

FormSwitch.propTypes = {
    intl: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tagOnLabelId: PropTypes.string,
    tagOffLabelId: PropTypes.string,
    value: PropTypes.bool.isRequired,
    sizeValue: formPropTypes.formSwitchInputSizeValue,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FormSwitch.defaultProps = {
    tagOnLabelId: undefined,
    tagOffLabelId: undefined,
    sizeValue: 'default',
    onBlur: null,
    onChange: null,
    disabled: false,
};

export default injectIntl(FormSwitch);
