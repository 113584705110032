import styled from 'styled-components';
import {rem} from 'polished';

const ScrollButtonWrapper = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 padding-top: ${rem(16)};
`;

export default ScrollButtonWrapper;
