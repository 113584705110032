import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../styles/formTheme';
import autofillKeyframes from '../styles/autofillKeyframes';
import formInputFillStyles from '../styles/formInputFillStyles';

const Input = styled.input`
    appearance: none;
    background-color: ${({ theme, skin }) =>
        get(
            theme,
            `form.input.${skin}.backgroundColor`,
            formTheme.form.input[skin].backgroundColor,
        )};
    border-color: ${({ skin, theme }) =>
        get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)};
    border-style: solid;
    border-radius: ${({ theme }) =>
    rem(get(theme, 'form.input.borderRadius', formTheme.form.input.borderRadius))};
    border-width: 1px;
    color: ${({ skin, theme }) =>
        get(theme, `form.input.${skin}.color`, formTheme.form.input[skin].color)};
    display: block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.input.fontSize', formTheme.form.input.fontSize))};
    height: ${({ theme }) => rem(get(theme, 'form.input.height', formTheme.form.input.height))};
    outline: 0;
    padding: ${rem(24)} ${rem(12)} ${rem(6)};
    transition: background 0.3s ease-in 0.1s;
    width: 100%;
     &:focus {
            border-color: ${({ theme, skin }) => 
            get(theme, `form.input.${skin}.borderColorFocus`, formTheme.form.input[skin].borderColorFocus)};
        }
    ${({ centered }) =>
        centered &&
        css`
            text-align: center;
        `}

    ${({ readOnly, skin, theme }) =>
        !readOnly &&
        css`
            &:focus {
                background-color: ${get(
                    theme,
                    `form.input.${skin}.backgroundColorFocus`,
                    formTheme.form.input[skin].backgroundColorFocus,
                )};
            }
        `}

    ${({ readOnly }) =>
        readOnly &&
        css`
            border-color: transparent;
        `}
        
    ${({ theme, skin, disabled }) =>
        disabled &&
        css`
            border-color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
            border-style: dotted;
            color: ${get(
                theme,
                `form.input.${skin}.colorDisabled`,
                formTheme.form.input[skin].colorDisabled,
            )};
            cursor: not-allowed;
        `}

    ${({ prependAddon, prependAddonWidth }) =>
        prependAddon &&
        prependAddonWidth &&
        css`
            padding-left: ${16 + prependAddonWidth}px;
        `}

    ${({ appendAddon, appendAddonWidth }) =>
        appendAddon &&
        appendAddonWidth &&
        css`
            padding-right: ${12 + appendAddonWidth}px;
        `}

    ${({ hideLabel, theme }) =>
        hideLabel &&
        css`
            height: ${rem(
                get(theme, 'form.input.heightNoLabel', formTheme.form.input.heightNoLabel),
            )};
            padding-top: ${rem(12)};
        `}

    /* Autofill styles, with + span address the input label and fake the filled state */
    &:-webkit-autofill {
        animation-name: ${autofillKeyframes};
    }

    &:-webkit-autofill + span {
        ${formInputFillStyles}
    }
`;

export default Input;
