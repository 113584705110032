import { put, takeEvery, select, call } from 'redux-saga/effects';
import { GetSubmissions, getEntities } from '../../api';
import {
    GET_BUSINESESS_PROCESS,
    GET_BUSINESSES_START,
    GET_BUSINESSES_SUCCESS,
    SET_BUSINESS_FILTER_PROCESS,
    SET_BUSINESS_FILTER_START,
    SET_BUSINESS_FILTER_SUCCESS,
} from '../actions/BusinessesActions';
import genericSagaHandler from './CommonSaga';

export function* getBusinessesSaga() {
    yield genericSagaHandler(GET_BUSINESESS_PROCESS, function* () {
        const { searchText, approvalstatus, type, page, cityLocation, filters, ownedBusinesses } = yield select(({ BusinessesReducer }) => BusinessesReducer);
        const maxCount = 10;
        const entityData = yield getEntities({ searchText, approvalstatus, type, page, cityLocation, filters });

        let updatedOwnedBusinesses;

        if (page === undefined || page <= 0) {
            updatedOwnedBusinesses = entityData;
        } else {
            // If page > 0, concatenate new data with existing data and remove duplicate ids
            updatedOwnedBusinesses = [...ownedBusinesses, ...entityData].filter((item, idx, self) =>
                idx === self.findIndex(obj => obj._id === item._id)
            );
        }
        const hasMore = (entityData?.length ?? 0) >= maxCount;
        yield put({
            type: GET_BUSINESSES_SUCCESS,
            payload: {
                ownedBusinesses: updatedOwnedBusinesses,
                recommendedBusinesses: [],
                hasMore: hasMore
            },
        });
    });
}

function* setBusinessesFilterSaga({ payload = {} }) {
    yield genericSagaHandler(SET_BUSINESS_FILTER_PROCESS, function* () {
        const { approvalstatus, searchQuery, type, page, cityLocation, filters, category, subcategory, filter } = payload;
        yield put({
            type: SET_BUSINESS_FILTER_SUCCESS,
            payload: {
                approvalstatus,
                searchQuery,
                type,
                page,
                cityLocation,
                filters,
                category,
                subcategory,
                filter
            },
        });
        yield call(getBusinessesSaga);
    });
}

export default function* BussinesesSagas() {
    yield takeEvery(GET_BUSINESSES_START, getBusinessesSaga);
    yield takeEvery(SET_BUSINESS_FILTER_START, setBusinessesFilterSaga);
}
