import { baseDarkTheme } from 'baseline-ui/base-theme';
import tooltipTheme from './tooltipTheme';

const tooltipDarkTheme = {
    tooltip: {
        ...tooltipTheme.tooltip,
        backgroundColor: baseDarkTheme.base.colors.light,
        borderColor: baseDarkTheme.base.grays.grayLighter,
        info: {
            color: baseDarkTheme.base.grays.gray,
        },
        optionList: {
            item: {
                backgroundColor: baseDarkTheme.base.colors.light,
                color: baseDarkTheme.base.colors.dark,
                colorActive: baseDarkTheme.base.colors.light,
                backgroundColorActive: baseDarkTheme.base.colors.primary,
            },
        },
    },
};

export default tooltipDarkTheme;
