import { rem, transparentize } from 'polished';
import styled from 'styled-components';

const NavigationFooterContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) =>
        transparentize(0.4, theme.site.navigationFooter.backgroundColor)};
    backdrop-filter: blur(${rem(4)});
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    display: flex;
    padding: ${rem(16)};
`;

export default NavigationFooterContainer;
