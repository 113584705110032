import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize, rem } from 'polished';
import formTheme from '../../styles/formTheme';

const CheckboxIndicator = styled.span`
    background-color: ${({ skin, theme }) =>
        get(
            theme,
            `form.checkbox.${skin}.backgroundColor`,
            formTheme.form.checkbox[skin].backgroundColor,
        )};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-color: ${({ theme, skin }) =>
        get(theme, `form.checkbox.${skin}.borderColor`, formTheme.form.checkbox[skin].borderColor)};
    border-style: solid;
    border-width: 2px;
    border-radius: 0.1875rem;
    cursor: pointer;
    display: block;
    height: ${({ theme, sizeValue }) =>
        rem(
            get(theme, `form.checkbox.size.${sizeValue}`, formTheme.form.checkbox.size[sizeValue]),
        )};
    left: 0;
    position: absolute;
    user-select: none;
    top: ${rem(2)};
    transform: translateZ(0);
    transition: all 0.3s ease;
    width: ${({ theme, sizeValue }) =>
        rem(
            get(theme, `form.checkbox.size.${sizeValue}`, formTheme.form.checkbox.size[sizeValue]),
        )};

    ${({ checked, skin, theme }) =>
        checked &&
        css`
            background-color: ${get(
                theme,
                `form.checkbox.${skin}.backgroundColorChecked`,
                formTheme.form.checkbox[skin].backgroundColorChecked,
            )};
            background-image: ${`url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2288%22%20height%3D%2288%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22${get(
                theme,
                `form.checkbox.${skin}.indicatorColor`,
                formTheme.form.checkbox[skin].indicatorColor,
            ).replace(
                '#',
                '%23',
            )}%22%20stroke-width%3D%226%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22feather%20feather-check%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%2F%3E%3C%2Fsvg%3E")`};
            border-color: ${get(
                theme,
                `form.checkbox.${skin}.borderColorChecked`,
                formTheme.form.checkbox[skin].borderColorChecked,
            )};
            box-shadow: none;
        `}

    ${({ focused, skin, theme }) =>
        focused &&
        css`
            box-shadow: 0 0 0 4px
                ${transparentize(
                    0.7,
                    get(
                        theme,
                        `form.checkbox.${skin}.colorChecked`,
                        formTheme.form.checkbox[skin].colorChecked,
                    ),
                )};
        `}

    ${({ theme, disabledValue, skin }) =>
        disabledValue &&
        css`
            background-color: ${get(
                theme,
                `form.checkbox.${skin}.colorDisabled`,
                formTheme.form.checkbox[skin].colorDisabled,
            )};

            border-color: ${get(
                theme,
                `form.checkbox.${skin}.colorDisabledChecked`,
                formTheme.form.checkbox[skin].colorDisabledChecked,
            )};
            cursor: not-allowed;
        `}

        ${({ disabledValue, checked, skin, theme }) =>
        disabledValue &&
        checked &&
        css`
            background-color: ${get(
                theme,
                `form.checkbox.${skin}.colorDisabledChecked`,
                formTheme.form.checkbox[skin].colorDisabledChecked,
            )};
            background-image: ${`url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2288%22%20height%3D%2288%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22${get(
                theme,
                `form.checkbox.${skin}.indicatorDisabledColor`,
                formTheme.form.checkbox[skin].indicatorDisabledColor,
            ).replace(
                '#',
                '%23',
            )}%22%20stroke-width%3D%226%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22feather%20feather-check%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%2F%3E%3C%2Fsvg%3E")`};
            border-color: ${get(
                theme,
                `form.checkbox.${skin}.colorDisabledChecked`,
                formTheme.form.checkbox[skin].colorDisabledChecked,
            )};
        `}
`;

export default CheckboxIndicator;
