import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormSignIn } from 'baseline-ui/auth';
import { useIntl } from 'react-intl';
import {FORGOT_PASSWORD_PATH, HOME_PATH} from 'common/pathnames';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import CommonWrapper from './CommonWrapper';
// TODO remove component
import { POST_LOGIN_PROCESS, POST_LOGIN_START } from '../redux/actions/AuthenticationActions';
import { useLoading } from '../common/useLoading';
import WelcomeMainHeader from './Welcome/WelcomeMainHeader';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import heartOnly from 'images/heart-only-empty.svg';

const LoginRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useLoading(POST_LOGIN_PROCESS);
    const errorMessage = useProcessErrorMessage({ processName: POST_LOGIN_PROCESS });

    return (
        <CommonWrapper>
            <WelcomeMainContainer
                beforeImage={heartOnly}
                afterImage={heartOnly}
            >
                <WelcomeMainHeader
                    title={intl.formatMessage(
                        {
                            id: 'welcome.login.title',
                        },
                        { sitename: intl.formatMessage({ id: 'siteName' }) },
                    )}
                />
                <FormSignIn
                    showHeading={false}
                    siteName={intl.formatMessage({ id: 'siteName' })}
                    onSubmitSignIn={(values) => {
                        const { emailAddress, password } = values;
                        dispatch({
                            type: POST_LOGIN_START,
                            payload: {
                                email: emailAddress,
                                password,
                                navigate,
                            },
                        });
                    }}
                    errorMessage={errorMessage}
                    isLoading={isLoading}
                    inProp
                    toSignUp={HOME_PATH}
                    appear={false}
                    toRecoverPassword={FORGOT_PASSWORD_PATH}
                />
            </WelcomeMainContainer>
        </CommonWrapper>
    );
};

export default LoginRoute;
