import update from 'immutability-helper';
import {
    ADD_PRODUCT_CATEGORY_SUCCESS,
    ADD_PRODUCT_SUBCATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_PHOTO_SUCCESS,
    DELETE_SELECTED_PRODUCT_CATEGORY_SUCCESS,
    DISCARD_PRODUCT_CHANGES_START,
    RESET_PRODUCT_TO_EDIT_START,
    SET_EMPTY_PRODUCT_START,
    SET_PRODUCT_CATEGORY_SUCCESS,
    SET_PRODUCT_PHOTO_SUCCESS,
    SET_PRODUCT_SECTIONS_SUCCESS,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
    SET_PRODUCT_TO_EDIT_SUCCESS,
    SET_SELECTED_PRODUCT_CATEGORY_SUCCESS,
    UNSET_PRODUCT_SECTIONS_START,
    UNSET_PRODUCT_SECTIONS_SUCCESS,
    UPDATE_PRODUCTS_TO_EDIT_SUCCESS,
    UPDATE_PRODUCT_SECTION_SUCCESS,
} from '../actions/EditProductActions';

const BASE_EMPTY_PRODUCT = {
    active: true,
    mapping: { tags: [] },
    photos: [],
    sectionsFoundIn: [],
    name: '',
    price: '',
    description: '',
    editedProducts: [],
    type: 'PRODUCT',
};

const EditProductReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case RESET_PRODUCT_TO_EDIT_START: {
            return { ...state, productToEdit: undefined, originalProduct: undefined };
        }

        case UPDATE_PRODUCTS_TO_EDIT_SUCCESS: {
            const { item } = payload;
            const newEditedProducts = [...(state.editedProducts || [])];
            const index = newEditedProducts.findIndex((p) => p._id === item._id);
            if (index === -1) {
                newEditedProducts.push(item);
            } else {
                newEditedProducts[index] = item;
            }

            return {
                ...state,
                editedProducts: newEditedProducts,
            };
        }
        case SET_EMPTY_PRODUCT_START: {
            return {
                ...state,
                productToEdit: BASE_EMPTY_PRODUCT,
                originalProduct: BASE_EMPTY_PRODUCT,
            };
        }
        case SET_PRODUCT_TO_EDIT_SUCCESS: {
            return {
                ...state,
                productToEdit: { ...payload },
                originalProduct: { ...payload },
            };
        }
        case DISCARD_PRODUCT_CHANGES_START: {
            const { originalProduct } = state;
            return {
                ...state,
                productToEdit: { ...originalProduct },
            };
        }
        case SET_SELECTED_PRODUCT_CATEGORY_SUCCESS:
        case SET_PRODUCT_CATEGORY_SUCCESS:
        case ADD_PRODUCT_SUBCATEGORY_SUCCESS:
        case DELETE_PRODUCT_CATEGORY_SUCCESS:
        case DELETE_SELECTED_PRODUCT_CATEGORY_SUCCESS:
        case ADD_PRODUCT_CATEGORY_SUCCESS: {
            const { productToEdit } = state;
            const newProduct = update(productToEdit, {
                mapping: {
                    tags: { $set: payload },
                },
            });
            return {
                ...state,
                productToEdit: newProduct,
            };
        }
        case DELETE_PRODUCT_PHOTO_SUCCESS:
        case SET_PRODUCT_PHOTO_SUCCESS: {
            const { productToEdit } = state;
            const newProduct = update(productToEdit, {
                photos: { $set: payload },
            });
            return {
                ...state,
                productToEdit: newProduct,
            };
        }
        case SET_PRODUCT_SECTIONS_SUCCESS:
        case UNSET_PRODUCT_SECTIONS_SUCCESS:
        case UPDATE_PRODUCT_SECTION_SUCCESS:
        case SET_PRODUCT_TO_EDIT_PROPERTY_START: {
            const { productToEdit } = state;
            return { ...state, productToEdit: { ...productToEdit, ...payload } };
        }
        case UNSET_PRODUCT_SECTIONS_START: {
            const newSectionsToDelete = [...(state.sectionsToDelete || []), payload];
            return { ...state, sectionsToDelete: newSectionsToDelete };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default EditProductReducer;
