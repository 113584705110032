export const GET_CLAIMS_PROCESS = "GET_CLAIMS_PROCESS";
export const GET_CLAIMS_START = "GET_CLAIMS_START";
export const GET_CLAIMS_SUCCESS = "GET_CLAIMS_SUCCESS";
export const GET_CLAIMS_ERROR = "GET_CLAIMS_ERROR";

export const SET_CLAIM_FILTERS_START = "SET_CLAIM_FILTERS_START";
export const RESET_CLAIM_FILTERS_START = "RESET_CLAIM_FILTERS_START";

export const PUT_APPROVE_CLAIM_STATE_START = "PUT_APPROVE_CLAIM_STATE_START";
export const PUT_APPROVE_CLAIM_STATE_SUCCESS =
  "PUT_APPROVE_CLAIM_STATE_SUCCESS";
export const PUT_APPROVE_CLAIM_STATE_PROCESS =
  "PUT_APPROVE_CLAIM_STATE_PROCESS";

export const PUT_REJECT_CLAIM_STATE_START = "PUT_REJECT_CLAIM_STATE_START";
export const PUT_REJECT_CLAIM_STATE_SUCCESS = "PUT_REJECT_CLAIM_STATE_SUCCESS";
export const PUT_REJECT_CLAIM_STATE_PROCESS = "PUT_REJECT_CLAIM_STATE_PROCESS";
