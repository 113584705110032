import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const Feedback = styled.span`
    display: inline-block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${rem(12)};
    padding: ${rem(4)};

    ${({ theme, feedbackType, skin }) =>
        feedbackType === 'error' &&
        css`
            background-color: ${get(
                theme,
                `form.error.${skin}.backgroundColor`,
                formTheme.form.error[skin].backgroundColor,
            )};
            color: ${get(theme, 'form.error.color', formTheme.form.error.color)};
        `}
`;

export default Feedback;
