import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetDetails = ({
    title,
    description,
    siteImage,
    siteImageAlt,
    priceCurrency,
    priceAmount,
    robots,
    keywords,
    type,
}) => {
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {title && <meta name="og:title" content={title} />}
            {description && <meta name="og:description" content={description} />}
            {siteImage && <meta property="og:image" content={siteImage} />}
            {siteImageAlt && <meta property="og:image:alt" content={siteImageAlt} />}
            {type && <meta property="og:type" content={type} />}
            {priceCurrency && <meta property="product:price:currency" content={priceCurrency} />}
            {priceAmount && <meta property="product:price:amount" content={priceAmount} />}
            {robots && <meta name="robots" content={robots} />}
            {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
    );
};

HelmetDetails.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    siteImage: PropTypes.string,
    siteImageAlt: PropTypes.string,
    priceCurrency: PropTypes.string,
    priceAmount: PropTypes.string,
    robots: PropTypes.string,
    keywords: PropTypes.string,
    type: PropTypes.string,
};

HelmetDetails.defaultProps = {
    title: undefined,
    description: undefined,
    siteImage: undefined,
    siteImageAlt: undefined,
    priceCurrency: undefined,
    priceAmount: undefined,
    robots: undefined,
    keywords: undefined,
    type: undefined,
};

export default HelmetDetails;
