import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const MobileTablet = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const maxWidth = get(theme, 'layout.media.md', layoutTheme.layout.media.md);

    return <Responsive {...props} maxWidth={parseInt(maxWidth, 10) - 1} />;
};

// displayName for storybook
MobileTablet.displayName = 'MobileTablet';

const Hoc = MobileTablet;
Hoc.displayName = MobileTablet.displayName;
export default Hoc;
