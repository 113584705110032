import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import ProcessOverlayContext from './ProcessOverlayContext';
import ProcessOverlayContainer from './ProcessOverlayContainer';

const initialState = {
    processOverlayOnStage: {
        show: false,
    },
    setProcessOverlayOnStage: () => {},
};

const actions = {
    SET_PROCESS_OVERLAY_ON_STAGE: 'SET_PROCESS_OVERLAY_ON_STAGE',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_PROCESS_OVERLAY_ON_STAGE:
            return {
                ...state,
                processOverlayOnStage: action.processOverlayOnStage,
            };
        default:
            return state;
    }
};

const ProcessOverlayProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            processOverlayOnStage: state.processOverlayOnStage,
            setProcessOverlayOnStage: (processOverlayOnStage) => {
                dispatch({ type: actions.SET_PROCESS_OVERLAY_ON_STAGE, processOverlayOnStage });
            },
        }),
        [state],
    );

    return (
        <ProcessOverlayContext.Provider value={value}>
            <ProcessOverlayContainer />
            {children}
        </ProcessOverlayContext.Provider>
    );
};

ProcessOverlayProvider.propTypes = {
    children: PropTypes.node,
};

ProcessOverlayProvider.defaultProps = {
    children: undefined,
};

export default ProcessOverlayProvider;
