import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const AdminMainFooterWrapper = styled.div`
    margin-left: ${rem(0)};
    margin-right: ${rem(0)};
    min-height: ${({ theme }) => rem(theme.site.navigationFooter.height)};
    padding-bottom: ${({ theme }) => rem(theme.site.navigationFooter.paddingOffset)};

    ${media.xxs`
        margin-left: ${rem(8)};
        margin-right: ${rem(8)};
    `}

    ${media.md`
        margin-left: ${rem(24)};
        margin-right: ${rem(24)};
        padding-bottom: ${({ theme }) => rem(theme.site.navigationFooter.paddingOffset * 2)};
    `}
`;

export default AdminMainFooterWrapper;
