import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GaIcon } from 'baseline-ui/icon';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import ModalEditProductTotalFavesContainer from './ModalEditProductTotalFavesContainer';
import ModalEditProductTotalFavesIconContainer from './ModalEditProductTotalFavesIconContainer';
import ModalEditProductTotalFavesCountLabel from './ModalEditProductTotalFavesCountLabel';

const ModalEditProductTotalFaves = ({ totalFaves }) => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();

    return (
        <ModalEditProductTotalFavesContainer>
            <ModalEditProductTotalFavesIconContainer>
                <GaIcon icon="favepage-heart-filled" size={20} color={theme.base.colors.primary} />
            </ModalEditProductTotalFavesIconContainer>
            <ModalEditProductTotalFavesCountLabel>
                {intl.formatNumber(totalFaves)}
            </ModalEditProductTotalFavesCountLabel>
        </ModalEditProductTotalFavesContainer>
    );
};

ModalEditProductTotalFaves.propTypes = {
    totalFaves: PropTypes.number.isRequired,
};

export default ModalEditProductTotalFaves;
