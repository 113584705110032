import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useSelector } from "react-redux";

const ReduxLoaderButton = ({ processName, caption, onClick, ...rest }) => {
  const processStep = useSelector(
    ({ ProcessReducer }) => ProcessReducer[processName],
  );

  const loading = processStep === "START";

  return (
    <LoadingButton
      //   loadingPosition="start"
      {...rest}
      loading={loading}
      sx={{ backgroundColor: "#e7744b" }}
      variant="contained"
      type="submit"
      onClick={onClick}
      disabled={loading}
    >
      {caption}
    </LoadingButton>
  );
};

export default ReduxLoaderButton;
