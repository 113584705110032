import { rem } from 'polished';
import styled from 'styled-components';

const MyBusinessListEmptyContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: ${rem(80)};
    padding-top: ${rem(80)};
`;

export default MyBusinessListEmptyContainer;
