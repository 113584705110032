import React from 'react';
import PropTypes from 'prop-types';
import typographyPropTypes from '../prop-types/typographyPropTypes';
import Heading5 from './Heading5';

function H5({ color, className, isUppercase, children, isBaseFont, textAlign, fontWeight, as }) {
    return (
        <Heading5
            className={className}
            isUppercase={isUppercase}
            colorValue={color}
            isBaseFont={isBaseFont}
            textAlign={textAlign}
            fontWeight={fontWeight}
            as={as}
        >
            {children}
        </Heading5>
    );
}

H5.propTypes = {
    color: typographyPropTypes.typographyColor,
    className: PropTypes.string,
    isUppercase: PropTypes.bool,
    children: PropTypes.node,
    isBaseFont: PropTypes.bool,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    as: PropTypes.string,
};

H5.defaultProps = {
    color: 'dark',
    className: null,
    isUppercase: false,
    children: null,
    isBaseFont: false,
    textAlign: undefined,
    as: undefined,
};

export default H5;
