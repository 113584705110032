import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import SortableList, { SortableItem } from 'react-easy-sort';
import sectionsPropTypes from 'custom-propTypes/itemsPropTypes';
import { H5 } from 'baseline-ui/typography';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SECTIONS_START } from 'redux/actions/SectionActions';
import update from 'immutability-helper';
import {
    allSectionsActiveProductsSelector,
    allSectionsSelector,
} from 'redux/selectors/sectionSelectors';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import ModalReoderSectionsListItem from './ModalReoderSectionsListItem';
import ModalReoderSectionsContainer from './ModalReoderSectionsContainer';

const ModalReoderSections = ({ onRequestClose, ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const sections = useSelector(allSectionsSelector);
    const sectionsToRender = useSelector(allSectionsActiveProductsSelector);

    const footerActions = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.reorderSection.footerActions.done.button.label',
            }),
            skin: 'primary',
            onClick: () => onRequestClose(),
        },
    ];

    const onSortEnd = (oldIndex, newIndex) => {
        const newSections = update(sections, {
            $splice: [
                [oldIndex, 1],
                [newIndex, 0, sections[oldIndex]],
            ],
        });
        dispatch({
            type: UPDATE_SECTIONS_START,
            payload: newSections,
        });
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: { hasUnsavedChanges: true },
        });
    };

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.reorderSection.title' })}
            size="sm"
            showCloseButton={false}
            handleOnDismiss={onRequestClose}
            footerActions={footerActions}
            {...props}
        >
            <ModalReoderSectionsContainer>
                <SortableList onSortEnd={onSortEnd} lockAxis="y">
                    {sectionsToRender.map((sectionContainer) => {
                        const { sectionId } = sectionContainer;
                        return (
                            <SortableItem key={sectionId}>
                                <ModalReoderSectionsListItem>
                                    <H5>{sectionContainer.sectionName}</H5>
                                </ModalReoderSectionsListItem>
                            </SortableItem>
                        );
                    })}
                </SortableList>
            </ModalReoderSectionsContainer>
        </Modal>
    );
};

ModalReoderSections.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    sectionContainers: sectionsPropTypes.itemsProducts.isRequired,
};

export default ModalReoderSections;
