import styled from 'styled-components';
import { cssHelpers } from 'baseline-ui/helpers';

const CheckboxInput = styled.input`
    ${cssHelpers.hiddenInput}

    left: 0;
    top: 0;
`;

export default CheckboxInput;
