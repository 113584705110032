import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem, transparentize } from 'polished';
import { baseTheme } from 'baseline-ui/base-theme';
import { typographyTheme } from 'baseline-ui/typography';
import alertTheme from '../../styles/alertTheme';

const Container = styled.div`
    align-content: center;
    align-items: flex-start;
    border: 0 solid transparent;
    border-radius: ${({ theme }) => rem(get(theme, 'alert.borderRadius', alertTheme.borderRadius))};
    display: flex;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    justify-content: space-between;
    overflow: hidden;
    padding: ${rem(16)};

    ${({ theme, alertType }) =>
        alertType &&
        css`
            background-color: ${transparentize(
                0.15,
                get(theme, `base.colors[${alertType}]`, baseTheme.base.colors[alertType]),
            )};
            border-color: ${get(
                theme,
                `base.colors[${alertType}]`,
                baseTheme.base.colors[alertType],
            )};
            color: ${get(theme, 'base.colors.light', baseTheme.base.colors.light)};
        `};

    ${({ theme, showShadow }) =>
        showShadow &&
        css`
            box-shadow: ${get(
                theme,
                'base.boxShadow.elevationSmall',
                baseTheme.base.boxShadow.elevationSmall,
            )};
        `}
`;

export default Container;
