import * as yup from 'yup';
import { fieldSchema } from 'baseline-ui/validation';

const formAddBusinessAccessRightsSchema = (users) =>
    yup.object().shape({
        accessRightsEmailAddress: fieldSchema.email
            .test(
                'alreadyExists',
                'error.addBusiness.page.sections.accessRights.user.alreadyExists.label',
                (value) => !users.find((user) => user.email === value),
            )
            .required(),
    });

export default formAddBusinessAccessRightsSchema;
