import React, { useContext } from 'react';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Responsive from 'react-responsive';
import layoutTheme from '../../styles/layoutTheme';

const Mobile = ({ ...props }) => {
    const theme = useContext(ThemeContext);
    const maxWidth = get(theme, 'layout.media.xs', layoutTheme.layout.media.xs);

    return <Responsive {...props} maxWidth={parseInt(maxWidth, 10) - 1} />;
};

// displayName for storybook
Mobile.displayName = 'Mobile';

const Hoc = Mobile;
Hoc.displayName = Mobile.displayName;
export default Hoc;
