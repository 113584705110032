import React from 'react';
import { GaIcon } from 'baseline-ui/icon';
import FavePagePreviewAppHeaderSafeAreaBatteryContainer from './FavePagePreviewAppHeaderSafeAreaBatteryContainer';
import FavePagePreviewAppHeaderSafeAreaBatteryIcon from './FavePagePreviewAppHeaderSafeAreaBatteryIcon';

const FavePagePreviewAppHeaderSafeAreaBattery = () => {
    return (
        <FavePagePreviewAppHeaderSafeAreaBatteryContainer>
            <FavePagePreviewAppHeaderSafeAreaBatteryIcon>
                <GaIcon icon="wifi" size={18} color="#000" />
            </FavePagePreviewAppHeaderSafeAreaBatteryIcon>
            <FavePagePreviewAppHeaderSafeAreaBatteryIcon>
                <GaIcon icon="battery" size={20} color="#000" />
            </FavePagePreviewAppHeaderSafeAreaBatteryIcon>
        </FavePagePreviewAppHeaderSafeAreaBatteryContainer>
    );
};

export default FavePagePreviewAppHeaderSafeAreaBattery;
