import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button, Icon, Tab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { SET_SELECTED_SECTION_START } from "../redux/actions/SectionActions";

const SectionItem = styled(Paper)((props) => {
  const { theme, variant } = props;
  const backgroundColor = variant === "selected" ? "#1976d2" : "#FFFFFF";
  const textColor = variant === "selected" ? "#FFFFFF" : "#1976d2";
  return {
    backgroundColor: backgroundColor,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    borderColor: "red",
    borderWidth: 10,
    textAlign: "center",
    color: textColor,
    marginLeft: 10,
  };
});

const SortableSectionListItem = ({
  _id,
  name,
  index,
  moveItem,
  onClick,
  variant,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [{ handlerId }, drop] = useDrop({
    accept: "section",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "section",
    item: () => {
      return { _id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <Tab
      ref={ref}
      data-handler-id={handlerId}
      variant={variant}
      onClick={() =>
        dispatch({ type: SET_SELECTED_SECTION_START, payload: name })
      }
      label={name}
    >
      {name}
      <Button onClick={onClick}>
        <EditIcon color={variant === "selected" ? "secondary" : "primary"} />
      </Button>
    </Tab>
  );
};

export default SortableSectionListItem;
