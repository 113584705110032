import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { injectIntl } from 'react-intl';
import { SrLabel } from 'baseline-ui/helpers';
import Container from './Container';
import FormInputFeedback from './feedback/FormInputFeedback';
import Select from './select/Select';
import FormSelectLabel from './select/FormSelectLabel';
import FormSelectOption from './select/FormSelectOption';
import formPropTypes from '../prop-types/formPropTypes';

class FormSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            selected: false,
        };
    }

    componentDidMount() {
        const { field } = this.props;
        this.setState({ selected: !!field.value });
    }

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
    };

    handleChange = (event) => {
        const { field, onChange } = this.props;
        this.setState({ selected: !!event.target.value });
        field.onChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    render() {
        const {
            intl,
            id,
            form,
            field,
            options,
            translateOptionsLabel,
            label,
            disabled,
            required,
            hideLabel,
            skin,
        } = this.props;
        const { focused, selected } = this.state;
        const error = getIn(form.errors, field.name);
        const value = getIn(form.values, field.name);
        const isSubmitted = form.submitCount > 0;

        const selectOptions = options.map((option) => {
            const selectLabel = translateOptionsLabel
                ? intl.formatMessage({ id: option.label })
                : option.label;

            return (
                <FormSelectOption
                    key={option.value}
                    value={option.value || ''}
                    disabled={option.disabled}
                    skin={skin}
                >
                    {selectLabel}
                </FormSelectOption>
            );
        });

        return (
            <Container>
                <SrLabel htmlFor={id}>{label}</SrLabel>
                <Select
                    value={value}
                    id={id}
                    name={field.name}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    disabled={disabled}
                    skin={skin}
                    selected={selected}
                    hideLabel={hideLabel}
                >
                    {selectOptions}
                </Select>
                {!hideLabel && (
                    <FormSelectLabel
                        required={required}
                        focused={focused}
                        selected={selected}
                        aria-hidden="true"
                        skin={skin}
                        error={error}
                        isSubmitted={isSubmitted}
                    >
                        {label}
                    </FormSelectLabel>
                )}
                {error && isSubmitted && (
                    <FormInputFeedback feedbackType="error" id={id} message={error} skin={skin} />
                )}
            </Container>
        );
    }
}

FormSelect.propTypes = {
    intl: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    options: formPropTypes.selectData.isRequired,
    translateOptionsLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    skin: formPropTypes.formInputSkin,
    onChange: PropTypes.func,
};

FormSelect.defaultProps = {
    hideLabel: false,
    required: false,
    disabled: false,
    translateOptionsLabel: true,
    skin: 'dark',
    onChange: null,
};

export default injectIntl(FormSelect);
