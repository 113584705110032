import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { FormCheckbox } from 'baseline-ui/form';
import { Small } from 'baseline-ui/typography';
import AdminBusinessAdvertisingRotateContainer from './AdminBusinessAdvertisingRotateContainer';
import AdminBusinessAdvertisingRotateDividerContainer from './AdminBusinessAdvertisingRotateDividerContainer';

const AdminBusinessAdvertisingRotate = ({ id }) => {
    const renderId = `${id}Rotate`;
    const { values, setFieldValue } = useFormikContext();

    return (
        <AdminBusinessAdvertisingRotateContainer>
            <AdminBusinessAdvertisingRotateDividerContainer>
                <Small>or</Small>
            </AdminBusinessAdvertisingRotateDividerContainer>
            <Field
                component={FormCheckbox}
                skin="dark"
                id={renderId}
                name={renderId}
                onChange={() => {
                    if (values[`${id}OrderEnabled`]) {
                        setFieldValue(`${id}OrderEnabled`, false);
                    }
                }}
            >
                Rotate
            </Field>
        </AdminBusinessAdvertisingRotateContainer>
    );
};

AdminBusinessAdvertisingRotate.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AdminBusinessAdvertisingRotate;
