import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'baseline-ui/button';
import useProcessLoader from 'utils/useProcessLoader';
import { GET_SECTION_PRODUCTS_PROCESS } from 'redux/actions/SectionActions';
import useProcessComplete from 'utils/useProcessComplete';
import { SET_EMPTY_PRODUCT_START } from 'redux/actions/EditProductActions';
import { OverlayContext } from 'baseline-ui/overlay';
import { useDispatch } from 'react-redux';
import ModalAddProduct from '../modalProduct/ModalAddProduct';
import ProductsAndServicesListEmptyContainer from './ProductsAndServicesListEmptyContainer';
import ProductsAndServicesListEmptyButtonContainer from './ProductsAndServicesListEmptyButtonContainer';

const ProductsAndServicesListEmpty = () => {
    const { showOverlay } = useContext(OverlayContext);
    const dispatch = useDispatch();
    const isLoading = useProcessLoader({ processNames: [GET_SECTION_PRODUCTS_PROCESS] });
    const isComplete = useProcessComplete({ processNames: [GET_SECTION_PRODUCTS_PROCESS] });

    if (isLoading && !isComplete) {
        return null;
    }

    return (
        <ProductsAndServicesListEmptyContainer>
            <ProductsAndServicesListEmptyButtonContainer>
                <Button
                    icon="plus"
                    btnType="link"
                    onClick={() => {
                        dispatch({ type: SET_EMPTY_PRODUCT_START });
                        showOverlay(ModalAddProduct);
                    }}
                >
                    Add Products
                </Button>
            </ProductsAndServicesListEmptyButtonContainer>
        </ProductsAndServicesListEmptyContainer>
    );
};

export default ProductsAndServicesListEmpty;
