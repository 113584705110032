import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const FormSelectLabel = styled.span`
    border: 0;
    clip: rect(0, 0, 0, 0);
    color: ${({ theme, skin }) =>
        get(theme, `theme.form.label.${skin}.color`, formTheme.form.label[skin].color)};
    display: inline-block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.label.fontSize', formTheme.form.label.fontSize))};
    height: 1px;
    left: ${rem(12)};
    margin: -1px;
    overflow: hidden;
    padding-right: ${rem(16)};
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: ${rem(18)};
    transition: 0.3s ease all;
    white-space: nowrap;
    width: 1px;

    ${({ required }) =>
        required &&
        css`
            :after {
                content: '*';
            }
        `}

    ${({ focused, selected, theme }) =>
        (focused || selected) &&
        css`
            clip: auto;
            display: inline-block;
            font-size: ${rem(
                get(theme, 'form.label.fontSizeFocus', formTheme.form.label.fontSizeFocus),
            )};
            height: auto;
            margin: 0;
            top: ${rem(6)};
            width: 100%;
        `}

    ${({ selected, theme, skin }) =>
        selected &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorFilled`,
                formTheme.form.label[skin].colorFilled,
            )};
        `}

    ${({ theme, focused, skin }) =>
        focused &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorFocus`,
                formTheme.form.label[skin].colorFocus,
            )};
        `}

    ${({ error, isSubmitted, theme }) =>
        (error || (error && isSubmitted)) &&
        css`
            color: ${get(theme, 'form.error.color', formTheme.form.error.color)};
        `}
`;

export default FormSelectLabel;
