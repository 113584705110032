import React, { useCallback, useEffect, useRef } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Row } from 'baseline-ui/layout';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { usePrevious } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import businessLinksUtils from 'utils/businessLinksUtils';
import {
    BUSINESS_LINKS_HTTPS_PROTOCOL,
    BUSINESS_LINKS_HTTP_PROTOCOL,
    BUSINESS_LINKS_REGEX_PROTOCOL,
} from 'common/consts';

const AddBusinessLinksForm = () => {
    const intl = useIntl();
    const { values, setFieldValue } = useFormikContext();
    const { links } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer);
    const prevLinks = usePrevious(links);
    const inputUrlRef = useRef();
    const { type, url } = values;
    const linkTypeDefaults = businessLinksUtils.linkTypeDefaults(intl);

    useEffect(() => {
        if (type) {
            setFieldValue(
                'caption',
                linkTypeDefaults.find((linkTypeUrl) => linkTypeUrl.value === type).caption,
            );
            inputUrlRef.current.focus();
        }
    }, [type, inputUrlRef]);

    useEffect(() => {
        if (
            !isEmpty(url) &&
            (url.startsWith(BUSINESS_LINKS_HTTPS_PROTOCOL) ||
                url.startsWith(BUSINESS_LINKS_HTTP_PROTOCOL))
        ) {
            setFieldValue('url', url.replace(BUSINESS_LINKS_REGEX_PROTOCOL, ''));
        }
    }, [values]);

    useEffect(() => {
        if (prevLinks?.length > links.length) {
            inputUrlRef.current.focus();
        }
    }, [inputUrlRef, links, prevLinks]);

    const handleOnAddLink = useCallback(() => {
        if (!businessLinksUtils.disableAddLinksByLinksCount(links.length)) {
            inputUrlRef.current.focus();
        }
    }, [inputUrlRef, links]);

    return (
        <Form noValidate>
            <Row>
                <Col xxs={30} lg={30} xl={15}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({
                                id: 'addBusiness.page.sections.links.form.url.label',
                            })}
                            name="url"
                            id="url"
                            type="url"
                            innerRef={inputUrlRef}
                            prependAddon={BUSINESS_LINKS_HTTPS_PROTOCOL}
                            disabled={businessLinksUtils.disableAddLinksByLinksCount(links.length)}
                        />
                    </FieldContainer>
                </Col>
                <Col xxs={30} lg={30} xl={15}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({
                                id: 'addBusiness.page.sections.links.form.caption.label',
                            })}
                            name="caption"
                            id="caption"
                            type="text"
                            disabled={businessLinksUtils.disableAddLinksByLinksCount(links.length)}
                        />
                    </FieldContainer>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        type="submit"
                        disabled={businessLinksUtils.disableAddLinksByLinksCount(links.length)}
                        onClick={handleOnAddLink}
                    >
                        <FormattedMessage id="addBusiness.page.sections.links.form.addNewLink.label" />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AddBusinessLinksForm;
