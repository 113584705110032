import styled from 'styled-components';

const FavePagePreviewModalCloseButtonContainer = styled.div`
    position: relative;
    height: 100%;
    margin-top: 4px;
    width: 48px;
`;

export default FavePagePreviewModalCloseButtonContainer;
