import { rem } from 'polished';
import styled from 'styled-components';

const ModalEditProductSectionsListItemContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.typography.colors.primaryMuted};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    border: 1px solid ${({ theme }) => theme.typography.colors.primaryMuted};
    display: flex;
    margin-right: ${rem(8)};
    margin-bottom: ${rem(8)};
    padding-left: ${({ theme }) => rem(theme.button.padding.default.h)};
    user-select: none;
`;

export default ModalEditProductSectionsListItemContainer;
