import React from 'react';
import PropTypes from 'prop-types';
import { MobileTablet, Desktop } from 'baseline-ui/layout';
import FavePageAsideMyBusinessMobileTablet from './FavePageAsideMyBusinessMobileTablet';
import FavePageAsideMyBusinessDesktop from './FavePageAsideMyBusinessDesktop';

const FavePageAsideMyBusiness = ({ onClick, currentSectionId }) => {
    return (
        <>
            <MobileTablet>
                <FavePageAsideMyBusinessMobileTablet
                    onClick={onClick}
                    currentSectionId={currentSectionId}
                />
            </MobileTablet>
            <Desktop>
                <FavePageAsideMyBusinessDesktop
                    onClick={onClick}
                    currentSectionId={currentSectionId}
                />
            </Desktop>
        </>
    );
};

FavePageAsideMyBusiness.propTypes = {
    onClick: PropTypes.func.isRequired,
    currentSectionId: PropTypes.string.isRequired,
};

export default FavePageAsideMyBusiness;
