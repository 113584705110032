import { rem } from 'polished';
import styled, { css } from 'styled-components';

const AdminMain = styled.main`
    ${({ theme, hasBanner, isMobile }) =>
        hasBanner &&
        !isMobile &&
        css`
            padding-top: ${rem(theme.site.page.header.height)};
        `}

    ${({ theme, hasMainFooter }) =>
        hasMainFooter &&
        css`
            padding-bottom: ${rem(
                theme.site.navigationFooter.height +
                    theme.site.navigationFooter.bottomOffset +
                    theme.site.navigationFooter.paddingOffset +
                    80,
            )};
        `}
`;

export default AdminMain;
