import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
    INQUIRY_TYPE_ADVERTISING,
    INQUIRY_TYPE_COLLABORATE,
    INQUIRY_TYPE_EMPLOYMENT,
    INQUIRY_TYPE_INVESTMENT,
    INQUIRY_TYPE_OTHER,
} from 'common/consts';
import { useDispatch } from 'react-redux';
import {
    POST_INQUIRE_PROCESS,
    POST_INQUIRE_PROCESS_RESET,
    POST_INQUIRE_START,
} from 'redux/actions/InquireActions';
import useProcessLoader from 'utils/useProcessLoader';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import { useComplete } from 'common/useComplete';
import FormInquire from 'components/FormInquiry';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import WelcomeMainHeader from './Welcome/WelcomeMainHeader';
import CommonWrapper from './CommonWrapper';
import heartOnly from 'images/heart-only-empty.svg';

const InquireRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isInquireComplete = useComplete(POST_INQUIRE_PROCESS);
    const isInquireLoading = useProcessLoader({ processNames: [POST_INQUIRE_PROCESS] });
    const inquireError = useProcessErrorMessage({ processName: POST_INQUIRE_PROCESS });

    useEffect(() => {
        return () => dispatch({ type: POST_INQUIRE_PROCESS_RESET });
    }, []);

    const inquireTypeOptions = [
        {
            value: null,
            label: 'formInquiry.form.typeOfInquire.options.label',
            disabled: true,
        },
        {
            value: INQUIRY_TYPE_INVESTMENT,
            label: 'formInquiry.form.typeOfInquire.option.investment.label',
        },
        {
            value: INQUIRY_TYPE_ADVERTISING,
            label: 'formInquiry.form.typeOfInquire.option.advertising.label',
        },
        {
            value: INQUIRY_TYPE_EMPLOYMENT,
            label: 'formInquiry.form.typeOfInquire.option.employment.label',
        },
        {
            value: INQUIRY_TYPE_COLLABORATE,
            label: 'formInquiry.form.typeOfInquire.option.collaborate.label',
        },
        {
            value: INQUIRY_TYPE_OTHER,
            label: 'formInquiry.form.typeOfInquire.option.other.label',
        },
    ];

    return (
        <CommonWrapper>
            <WelcomeMainContainer
                beforeImage={heartOnly}
                afterImage={heartOnly}
            >
                <WelcomeMainHeader
                    title={intl.formatMessage({
                        id: 'formInquiry.yourInquire.heading.title',
                    })}
                />
                <FormInquire
                    showHeading={false}
                    inquireTypeOptions={inquireTypeOptions}
                    onSubmitInquire={(values) => {
                        dispatch({
                            type: POST_INQUIRE_START,
                            payload: {
                                ...values,
                            },
                        });
                    }}
                    errorMessage={inquireError}
                    isLoading={isInquireLoading}
                    inProp={!isInquireComplete}
                    inPropFeedback={isInquireComplete}
                    feedbackType="success"
                    feedbackMessage={intl.formatMessage({
                        id: 'formInquiry.yourInquire.heading.success',
                    })}
                    appear={false}
                />
            </WelcomeMainContainer>
        </CommonWrapper>
    );
};

export default InquireRoute;
