import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { media } from 'baseline-ui/layout';

const ProductsAndServicesFilteredListOuter = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    border: 1px solid ${({ theme }) => theme.base.grays.grayLighter};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    height: ${({ theme }) => rem(theme.site.products.container.height / 1.25)};
    margin-bottom: ${rem(16)};
    margin-top: ${rem(8)};
    padding: 0;
    position: relative;

    ${({ showSearch, theme }) =>
        showSearch &&
        css`
            padding-top: ${rem(theme.site.products.search.container.height)};
        `}

    ${media.sm`
        height: ${({ theme }) => rem(theme.site.products.container.height)};
        margin-bottom: 0;
        overflow: hidden;
    `};
`;

export default ProductsAndServicesFilteredListOuter;
