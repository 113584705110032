import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FavePagePreviewAppHeaderSafeAreaTimeContainer from './FavePagePreviewAppHeaderSafeAreaTimeContainer';
import '@fontsource/inter/700.css';

const FavePagePreviewAppHeaderSafeAreaTime = () => {
    const intl = useIntl();
    const [currentTime, setCurrentTime] = useState();

    useEffect(() => {
        const renderTime = setInterval(() => {
            setCurrentTime(intl.formatTime(new Date(), { hour12: false }));
        }, 1000);
        return () => clearInterval(renderTime);
    }, [intl]);

    return (
        <FavePagePreviewAppHeaderSafeAreaTimeContainer>
            <span style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 15 }}>
                {currentTime}
            </span>
        </FavePagePreviewAppHeaderSafeAreaTimeContainer>
    );
};

export default FavePagePreviewAppHeaderSafeAreaTime;
