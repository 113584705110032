import { rem } from 'polished';
import styled from 'styled-components';

const PageHeaderRibbon = styled.div`
    align-items: center;
    background-image: linear-gradient(90deg, #fef4f3 0%, #f4f0f7 50%, #f4f7fe 100%);
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    display: flex;
    height: ${({ theme }) => rem(theme.site.page.header.height)};
    position: fixed;
    width: 100%;
    z-index: ${({ theme }) => theme.header.zIndex - 1};
`;

export default PageHeaderRibbon;
