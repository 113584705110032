import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'baseline-ui/button';
import { FormattedMessage } from 'react-intl';
import FavePageMainSectionNavigationActionContainer from './FavePageMainSectionNavigationActionContainer';
import FavePageMainSectionNavigationActionsContainer from './FavePageMainSectionNavigationActionsContainer';

const FavePageMainSectionNavigation = ({
    handleBackNavigation,
    disableBackNavigation,
    handleNextNavigation,
    disableNextNavigation,
}) => {
    return (
        <FavePageMainSectionNavigationActionsContainer>
            {handleBackNavigation && (
                <FavePageMainSectionNavigationActionContainer>
                    {!disableBackNavigation && (
                        <Button
                            skin="primary"
                            icon="arrow-left"
                            btnType="outlined"
                            onClick={handleBackNavigation}
                            disabled={disableBackNavigation}
                        >
                            <FormattedMessage id="addBusiness.page.section.navigation.button.previous.label" />
                        </Button>
                    )}
                </FavePageMainSectionNavigationActionContainer>
            )}
            {handleNextNavigation && (
                <FavePageMainSectionNavigationActionContainer>
                    {!disableNextNavigation && (
                        <Button
                            skin="primary"
                            icon="arrow-right"
                            btnType="outlined"
                            onClick={handleNextNavigation}
                            disabled={disableNextNavigation}
                            iconPostLabel
                        >
                            <FormattedMessage id="addBusiness.page.section.navigation.button.next.label" />
                        </Button>
                    )}
                </FavePageMainSectionNavigationActionContainer>
            )}
        </FavePageMainSectionNavigationActionsContainer>
    );
};

FavePageMainSectionNavigation.propTypes = {
    handleBackNavigation: PropTypes.func,
    disableBackNavigation: PropTypes.bool,
    handleNextNavigation: PropTypes.func,
    disableNextNavigation: PropTypes.bool,
};

FavePageMainSectionNavigation.defaultProps = {
    handleBackNavigation: null,
    disableBackNavigation: true,
    handleNextNavigation: null,
    disableNextNavigation: false,
};

export default FavePageMainSectionNavigation;
