import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { flatten } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FastFieldTextInput from "../../components/FastFieldTextInput";
import { GET_BUSINESS_PRODUCTS_START } from "../../redux/actions/OwnABussinessActions";
import { POST_PROMOTION_NOTIFICATION_START } from "../../redux/actions/PushActions";
import PromotionalNotificationsHistory from "../../components/PromotionalNotificationsHistory";

const ImagePickerDialog = ({
  showImagePickerModal,
  setShowImagePickerModal,
  setSelectedImage,
  businessId,
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const { business, products } = useSelector(
    ({ OwnABusinessReducer }) => OwnABusinessReducer,
  );

  useEffect(() => {
    if (!products) {
      dispatch({
        type: GET_BUSINESS_PRODUCTS_START,
      });
    }
  }, [products]);

  useEffect(() => {
    const productImages = flatten(
      products?.map((product) => {
        return product.item.photos.map((item) => {
          return { ...item, entityId: product.item._id };
        });
      }) || [],
    );
    const businessImages =
      business.photos?.map((photo) => {
        return { ...photo, entityId: businessId };
      }) || [];
    setImages(
      businessImages.concat(productImages).filter((it) => it !== undefined),
    );
  }, [businessId, business, products]);

  return (
    <Dialog
      open={showImagePickerModal}
      onClose={() => setShowImagePickerModal(false)}
    >
      <Grid xs={12} item container>
        <DialogTitle id="alert-dialog-t itle">
          Pick an image for a rich push notification
        </DialogTitle>
        <DialogContent>
          <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {images?.map((image) => {
              if (!image.url) {
                return null;
              }
              return (
                <Button
                  onClick={() => {
                    setSelectedImage(image);
                    setShowImagePickerModal(false);
                  }}
                >
                  <ImageListItem key={image?.url}>
                    <img src={`${image?.url}`} loading="lazy" />
                  </ImageListItem>
                </Button>
              );
            })}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowImagePickerModal(false)}>Cancel</Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

const BusinessPromotionalPush = ({ businessId }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showImagePickerModal, setShowImagePickerModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedAudience, setSelectedAudience] = useState();

  const { business, products } = useSelector(
    ({ OwnABusinessReducer }) => OwnABusinessReducer,
  );

  useEffect(() => {
    if (!products) {
      dispatch({
        type: GET_BUSINESS_PRODUCTS_START,
      });
    }
  }, [products]);

  useEffect(() => {
    const businessOption = { id: business._id, label: business.name };

    const productOptions =
      products?.map(({ item }) => {
        return { id: item._id, label: item.name };
      }) || [];
    setSelectOptions([businessOption, ...productOptions]);
    setSelectedOption(businessOption.id);
    setSelectedAudience(businessOption.id);
  }, [products, business]);

  useEffect(() => {
    if (selectedImage) {
      setSelectedOption(selectedImage.entityId);
    } else if (business) {
      setSelectedOption(business._id);
    }
  }, [selectedImage, business]);

  return (
    <div style={{ paddingTop: "2.5rem", paddingBottom: 240 }}>
      <Grid container justify="space-around">
        <Formik
          enableReinitialize={true}
          validateOnChange={false}
          initialValues={{}}
          //   validationSchema={schema}
          onSubmit={(values) => {
            dispatch({
              type: POST_PROMOTION_NOTIFICATION_START,
              payload: {
                ...values,
                entityId: selectedOption,
                imageUrl: selectedImage?.url,
                audienceEntityId: selectedAudience,
              },
            });
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            validateField,
            touched,
            isValid,
            errors,
            values,
            dirty,
          }) => (
            <Grid spacing={2} xs={12}>
              <Dialog
                onSubmit={handleSubmit}
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
              >
                <Grid xs={12} item container>
                  <DialogTitle id="alert-dialog-title">
                    Are you sure?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You are about to send a push notification to the audience
                      selected. This action cannot be reversed. Please click
                      'send' to send it or 'cancel' to go back to edit view.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowConfirmModal(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        handleSubmit();
                        setShowConfirmModal(false);
                      }}
                      autoFocus
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Grid>
              </Dialog>

              <ImagePickerDialog
                setSelectedImage={(img) => {
                  console.log(img);
                  setSelectedImage(img);
                }}
                showImagePickerModal={showImagePickerModal}
                setShowImagePickerModal={setShowImagePickerModal}
                businessId={businessId}
              />
              <Grid xs={12} container>
                <Grid xs={12} container>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                  >
                    <Typography variant="h5">Send notification</Typography>
                    <FormControl fullWidth>
                      <FastFieldTextInput
                        key={"1"}
                        id="title"
                        label="Message title"
                        name="pushMessageTitle"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={8} container style={{ marginTop: 20 }}>
                    <FormControl fullWidth>
                      <FastFieldTextInput
                        key={"2"}
                        id="pushMessage"
                        label="Push Message"
                        name="pushMessage"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={10}
                      />
                    </FormControl>
                  </Grid>
                  <Grid md={1} xs={12} />
                  <Grid md={3} xs={12}>
                    {!selectedImage && (
                      <div style={{ height: "100%", width: "100%" }} />
                    )}
                    {selectedImage && (
                      <img
                        src={selectedImage.url}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    )}
                    {selectedImage && (
                      <Button
                        onClick={() => {
                          setSelectedImage(undefined);
                        }}
                      >
                        Discard
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  style={{ marginTop: 20 }}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowImagePickerModal(true);
                    }}
                  >
                    Add image
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowConfirmModal(true);
                    }}
                  >
                    Send push notification
                  </Button>

                  {selectOptions && selectOptions.length > 0 && (
                    <TextField
                      select
                      value={selectedAudience}
                      label="Audience"
                      onChange={({ target }) => {
                        setSelectedAudience(target.value);
                      }}
                    >
                      {selectOptions.map((selectOption) => {
                        return (
                          <MenuItem value={selectOption.id}>
                            {selectOption.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}

                  {selectOptions && selectOptions.length > 0 && (
                    <TextField
                      select
                      value={selectedOption}
                      label="Promoted item"
                      onChange={({ target }) => {
                        setSelectedOption(target.value);
                      }}
                    >
                      {selectOptions.map((selectOption) => {
                        return (
                          <MenuItem value={selectOption.id}>
                            {selectOption.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
        <Grid xs={12}>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Notifications history
          </Typography>
          <PromotionalNotificationsHistory businessId={businessId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessPromotionalPush;
