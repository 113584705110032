import baseTheme from './baseTheme';

const baseDarkTheme = {
    base: {
        ...baseTheme.base,
        colors: {
            primary: '#90caf9',
            secondary: '#59a9ff',
            muted: '#b3b3b3',
            info: '#b3ecf5',
            success: '#a3e4aa',
            warning: '#ffcbbb',
            danger: '#e56c78',
            light: '#0c0c0c',
            dark: '#ffffff',
        },
        grays: {
            gray: '#808080',
            grayLight: '#595959',
            grayLighter: '#333333',
            grayLightest: '#262626',
            grayDark: '#a6a6a6',
            grayDarker: '#cccccc',
            grayDarkest: '#e5e5e5',
        },
        body: {
            background: 'transparent',
        },
        boxShadow: {
            elevationSmall: '0 2px 4px rgba(0,0,0,0.25)',
            elevationDefault: '0 4px 8px rgba(0,0,0,0.25)',
            elevationMedium: '0 8px 16px rgba(0,0,0,0.25)',
            elevationLarge: '0 16px 32px rgba(0,0,0,0.25)',
        },
        scrollbar: {
            onBody: false,
            width: 8,
            color: '#262626',
            marginV: 0,
            borderRadius: {
                track: 0,
                thumb: 8,
            },
        },
    },
};

export default baseDarkTheme;
