import { rem } from 'polished';
import styled, { css } from 'styled-components';

const AdminBusinessAdvertisingAddItemRowContainer = styled.div`
    border-bottom: 1px dashed lightgray;
    margin-bottom: ${rem(8)};
    padding-bottom: ${rem(8)};

    ${({ isLast }) =>
        isLast &&
        css`
            border-bottom: 0;
            margin-bottom: ${rem(16)};
        `}
`;

export default AdminBusinessAdvertisingAddItemRowContainer;
