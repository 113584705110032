import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import authTheme from '../styles/authTheme';

const AuthFormContainer = styled.div`
    margin: 0 auto;
     max-width: ${({ theme, maxWidth }) =>
        maxWidth
            ? rem(maxWidth)
            : rem(get(theme, 'auth.container.maxWidth', authTheme.auth.container.maxWidth))};
    position: relative;
    width: 100%;
`;

export default AuthFormContainer;
