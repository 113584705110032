import {
    Avatar,
    AvatarGroup,
    Badge,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import arrayMove from 'array-move';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { useDispatch, useSelector } from 'react-redux';
import {
    SET_EMPTY_PRODUCT_START,
    SET_SHOW_ACTIVE_PRODUCTS_ONLY_START,
} from '../redux/actions/EditProductActions';
import { UPDATE_SECTION_PRODUCTS_ORDER_START } from '../redux/actions/EditProductActions';
import { SET_PRODUCT_TO_EDIT_START } from '../redux/actions/EditProductActions';
import { GET_SECTION_PRODUCTS_START } from '../redux/actions/SectionActions';
import SaveProductModal from './SaveProductModal';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DndProvider } from 'react-dnd';
import SectionsList from './SectionsList';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PrimaryButton from './PrimaryButton';
import AddSectionModal from './AddSectionModal';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import productsAndServicesUtils from 'utils/productsAndServicesUtils';

const SectionProductList = () => {
    const navigate = useNavigate();
    const { _id: businessId } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer || {});
    const { sectionProducts, selectedSection, showActiveOnly } = useSelector(
        ({ SectionReducer }) => SectionReducer,
    );

    const { originalProduct, productToEdit } = useSelector(
        ({ EditProductReducer }) => EditProductReducer,
    );

    const products = get(sectionProducts, [
        productsAndServicesUtils.getSectionKey(selectedSection),
    ]);
    const [showProductSaveModal, setShowProductSaveModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (businessId)
            dispatch({
                type: GET_SECTION_PRODUCTS_START,
                payload: {
                    businessId,
                    selectedSection,
                    active: showActiveOnly,
                },
            });
    }, [businessId, selectedSection]);

    const [targetProductToEdit, setTargetProductToEdit] = useState();

    const [showAddSectionModal, setShowAddSectionModal] = useState(false);

    const onSortEnd = (oldIndex, newIndex) => {
        const newArray = arrayMove(products, oldIndex, newIndex);
        dispatch({
            type: UPDATE_SECTION_PRODUCTS_ORDER_START,
            payload: newArray,
        });
    };

    return (
        <Grid item xs={12}>
            <List>
                <SaveProductModal
                    open={showProductSaveModal}
                    onClose={() => {
                        setShowProductSaveModal(false);
                        setTargetProductToEdit(undefined);
                    }}
                    targetProductToEdit={targetProductToEdit}
                />

                <Grid container marginBottom={2}>
                    <Grid item xs={8} container justifyContent={'space-between'}>
                        <Grid item xs={4}>
                            <PrimaryButton
                                caption={'Add new product/service'}
                                onClick={() => {
                                    navigate(`/AddProduct/${encodeURIComponent(businessId)}`);
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Inactive only</Typography>
                                <Switch onChange={() => {}} checked={showActiveOnly} />
                                <Typography>Active only</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} justifyContent={'flex-end'}>
                        <AddSectionModal
                            open={showAddSectionModal}
                            onClose={() => setShowAddSectionModal(false)}
                        />
                        <Button variant={'contained'} onClick={() => setShowAddSectionModal(true)}>
                            Add new section
                        </Button>
                    </Grid>
                </Grid>

                <Divider />

                <DndProvider backend={HTML5Backend}>
                    <SectionsList />
                </DndProvider>

                <Divider />

                <SortableList
                    allowDrag={selectedSection ? true : false}
                    onSortEnd={onSortEnd}
                    className="list"
                    draggedItemClassName="dragged"
                >
                    {products &&
                        products.map((product, idx) => (
                            <SortableItem key={`sortable_item_${product._id}_${idx}`}>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            originalProduct?._id === product._id
                                                ? 'rgba(0,0,0,0.10)'
                                                : null,
                                    }}
                                    key={`product_${selectedSection}_${product._id}_${idx}`}
                                    divider
                                    onClick={() => {
                                        navigate(`/EditProduct/${encodeURIComponent(product._id)}`);
                                    }}
                                    // onClick={() => {
                                    //   setTargetProductToEdit(product._id);
                                    //   const isProductChanged =
                                    //     JSON.stringify(originalProduct) !==
                                    //     JSON.stringify(productToEdit);

                                    //   if (isProductChanged) {
                                    //     setShowProductSaveModal(true);
                                    //     return;
                                    //   }

                                    //   dispatch({
                                    //     type: SET_PRODUCT_TO_EDIT_START,
                                    //     payload: product._id,
                                    //   });
                                    // }}
                                >
                                    {selectedSection && (
                                        <DragHandleIcon
                                            color={'disabled'}
                                            sx={{ cursor: 'grab' }}
                                        />
                                    )}
                                    <Grid item xs={2}>
                                        <Typography>
                                            {product.name} {product.active ? '' : '(DEACTIVATED)'}
                                        </Typography>
                                        <Typography variant={'caption'}>
                                            {`${product.totalFaves} ${
                                                product.totalFaves === 1 ? 'fave' : 'faves'
                                            }`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            badgeContent={
                                                <>
                                                    {product?.photos?.length > 1 && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: 15,
                                                                backgroundColor: '#eaeaea',
                                                                borderWidth: 4,
                                                                boxSizing: 'content-box',
                                                                borderColor: '#2E2E2E',
                                                            }}
                                                        >
                                                            <Typography variant="caption">
                                                                +{product?.photos?.length}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Avatar src={product?.photos[0]?.url} />
                                        </Badge>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid>
                                            <Grid>
                                                <Typography variant={'caption'}>
                                                    {product.description?.substr(0, 100)}
                                                    {product.description?.length > 100 ? '...' : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} justifyContent="space-between">
                                        <Typography variant={'caption'}>{product.price}</Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                        container
                                        justifyContent={'space-between'}
                                        alignItems="center"
                                    >
                                        <Button variant={'outlined'} color={'inherit'}>
                                            Edit
                                        </Button>
                                        {/* We do not have delete product functionality */}
                                        {/* <Button variant={"contained"} sx={{ borderRadius: 28 }}>
                      <CloseIcon />
                    </Button> */}
                                    </Grid>
                                </ListItem>
                            </SortableItem>
                        ))}
                </SortableList>
            </List>
        </Grid>
    );
};

export default SectionProductList;
