import React from 'react';
import PropTypes from 'prop-types';
import Panel from './Panel';

const TabItem = ({ isOpen, itemId, children }) => {
    return (
        <Panel isOpen={isOpen} itemId={itemId}>
            {children}
        </Panel>
    );
};

TabItem.propTypes = {
    isOpen: PropTypes.bool,
    itemId: PropTypes.string,
    children: PropTypes.node.isRequired,
};

TabItem.defaultProps = {
    isOpen: false,
    itemId: null,
};

export default TabItem;
