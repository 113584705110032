import { rem } from 'polished';
import { get } from 'lodash';
import styled, { css } from 'styled-components';
import { baseScrollbarStyles, baseTheme } from 'baseline-ui/base-theme';

const NavigationListContainer = styled.div`
    ${({ isVertical }) =>
        isVertical &&
        css`
            height: 40vh;
            overflow-y: auto;
            position: relative;

            ${({ theme, themeMode, scrollbarStylesColor }) =>
                baseScrollbarStyles({
                    theme,
                    themeMode,
                    marginV: 0,
                    borderRadius: 8,
                    color: scrollbarStylesColor,
                })};
        `}

    ${({ hasScrollbar, theme, scrollbarStylesColor }) =>
        hasScrollbar &&
        css`
            border: 1px solid
                ${scrollbarStylesColor ||
                get(theme, 'base.grays.grayLightest', baseTheme.base.grays.grayLightest)};
            border-radius: ${rem(
                get(theme, 'base.borderRadius.md', baseTheme.base.borderRadius.md),
            )};
        `}
`;

export default NavigationListContainer;
